import {UeThemeProvider, ueLightTheme, ueDarkTheme} from 'epic-ue-theme';
import {UnrealLoading} from 'epic-ue-loading';
import React from 'react';
import styled from 'styled-components';
import ErrorPage from '../pages/ErrorPage';
import CmsPage from '../CmsPage';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;

export const getPreStardustPageNode = ({theme, cmsLoading, cmsLoadedEmpty, pageData}) => {
    let el;

    if (cmsLoading) {
        el = (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        el = <ErrorPage status={404} />;
    }

    if (pageData && pageData._templateName === 'blank') {
        el = <CmsPage />;
    }

    return el;
};
