import styled from 'styled-components';

export const BlogContentGridRowWrapper = styled.div`
    &.activityGridRow {
        position: relative;

        &:after {
            content: '';
            height: 2em;
            border-bottom: 1px solid #ccc;
            width: 100%;
        }

        &:last-child:after {
            border-bottom: none;
        }

        .activity-item {
            border-right: 1px solid #ccc;
            margin-top: 2em;
            margin-bottom: 1em;

            &:last-child {
                border-right: none;
            }

            .image {
                width: 100%;
                height: 200px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                margin-bottom: 1.5em;

                ${(p) => p.theme.breakpoints.between('sm', 'md')} {
                    height: 275px;
                }

                @media screen and (max-width: 414px) {
                    height: 275px;
                }
                @media screen and (max-width: 375px) {
                    height: 200px;
                }
            }

            .image.hide-category {
                margin-bottom: 0.5em;
            }

            .category {
                text-transform: uppercase;

                a {
                    font-size: 0.8em;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .headline {
                margin-top: 0;
                margin-bottom: 0.3em;
                font-size: 1.3em;

                a {
                    line-height: 1.2;
                    color: ${(p) => p.theme.palette.text.primary};
                    font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
                    font-weight: 700;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .byline {
                margin-bottom: 1em;
            }

            .short {
                line-height: 1.5;
                font-size: 14px;

                p {
                    line-height: 1.5;
                }
            }
        }

        .smallPost {
            &:first-child {
                min-height: 200px;
            }

            &:last-child {
                padding-top: 1.2em;
                border-top: 1px solid #ccc;
            }

            .row.match-heights {
                overflow: hidden;
            }

            .row.match-heights [class*='col-xs-'] {
                margin-bottom: -99999px;
                padding-bottom: 99999px;
            }

            .activityGridRow-date {
                font-size: 0.8em;
            }

            .category .activityGridRow-date:before {
                content: '/';
                padding: 0 0.5em;
            }
        }
    }

    &.activityGridRow + .headline-banner {
        margin-top: 2em !important;
    }

    &.headline-banner + .activityGridRow {
        margin-top: 2em;
        border-top: 1px solid #ccc;
    }
`;
