import React, {useCallback, useState, useEffect} from 'react';

import {getHelmetForMetaTags, RouteMatchOrUrlParse} from 'epic-ue-shared';
import {generateRoutePath, getBowserState} from '@epic-core/common';
import CourseCard from '../components/training/CourseCard';

import {useLocation, useMatch, useNavigate} from 'react-router-dom';
import {Page, Header} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {useCmsData, useCmsTreeData, InitSharedValueResponse, cmsApi} from 'epic-ue-shared';
import {
    AppSharedKeys,
    PageData,
    BackgroundProps,
    ButtonProps,
    FeedImagesProps
} from 'epic-ue-shared';
import Message from '../components/message/Message';
import SlickWrapper from 'epic-react-common/src/components/SlickWrapper';
import {Badge} from '../components/training/CourseCard';
import {Desc, Training, Filters, ShowcaseCardImg, SortLabel} from './TrainingPage.styles';
import root from 'window-or-global';
import Select from 'react-select';
import ErrorPage from './ErrorPage';

export const getCmsUrlPattern = (match: RouteMatchOrUrlParse): string => {
    const path = match?.pathname || '';
    if (path.indexOf('onlinelearning') > -1) {
        return '/onlinelearning-courses';
    } else if (path.indexOf('lesson-plans') > -1) {
        return '/lesson-plans';
    }

    return '/classroom-training';
};

function uniq(a) {
    if (a && a.length > 0) {
        return a.sort().filter((item, pos, ary) => {
            return !pos || item !== ary[pos - 1];
        });
    }
}

function flatten(a) {
    if (a && a.length > 0) {
        return Array.isArray(a) ? [].concat(...a.map(flatten)) : a;
    }
}

const defaultSortOptions = [
    {
        label: 'Title',
        value: 'title'
    },
    {
        label: 'Release',
        value: 'release'
    },
    {
        label: 'Length',
        value: 'length'
    }
];

interface SortingProps {
    sortOptions: {
        label: string;
        value: string;
    }[];
}

export interface TrainingPageData extends PageData {
    courseTitle: string;
    courseDesc: string;
    placeholder: string;
    sortPlaceholder: string;
    tagsPlaceholder: string;
    quickRegisterBtn: string;
    header: {
        suffixTitle: string;
        title: string;
        type: string;
        background: BackgroundProps;
        primaryBtn: {
            label: string;
            url: string;
        };
        secondaryBtn: {
            label: string;
            url: string;
        };
    };
    sorting: SortingProps;
}

interface ClassData extends PageData {
    class: {
        badge: string;
        content: string;
        length: number;
        author: string;
        release: Date;
        tag: string;
        id: string;
        inShowcase: boolean;
        label: string;
        short: string;
        title: string;
        url: string;
        image: FeedImagesProps;
        showcaseImg: string;
    };
}

const TrainingPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const cmsUrlPattern = getCmsUrlPattern(match);
    const navigate = useNavigate();
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<TrainingPageData>(cmsUrlPattern);
    const {cmsLoading: cmsTreeLoading, children} = useCmsTreeData<ClassData>(cmsUrlPattern);
    const [filter, setFilter] = useState('');
    const [userSorting, setUserSorting] = useState('title');
    const stringArray: string[] = [];
    const [sortTag, setSortTag] = useState(stringArray);
    const OptionStyles = {
        control: (provided, state) => ({
            ...provided,
            background: 'transparent',
            color: 'white',
            borderRadius: 'none'
        }),
        menu: (provided, state) => ({
            ...provided,
            background: '#232323'
        }),
        option: (provided, state) => ({
            ...provided,
            color: 'white',
            background: state.isFocused ? '#111111' : '#232323'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: 'white'
        }),
        multiValueRemove: (provided, state) => ({
            ...provided,
            color: 'black'
        }),
        input: (provided, state) => ({
            ...provided,
            color: 'white'
        })
    };

    const getParameterByName = (name, url) => {
        let retrievedName;
        if (name) {
            retrievedName = name.replace(/[[]]/g, '\\$&');
        }
        let retrieveURL;
        if (!url) {
            retrieveURL = root.location.href;
        } else {
            retrieveURL = url;
        }

        const regex = new RegExp(`[?&]${retrievedName}(=([^&#]*)|&|#|$)`),
            results = regex.exec(retrieveURL);
        if (!results) return '';
        if (!results[2]) return '';
        return decodeURIComponent(results[2].toLowerCase().replace(/\+/g, ' '));
    };

    useEffect(() => {
        const tagsInUrl: string = getParameterByName('tags', null);
        if (tagsInUrl) {
            setSortTag(tagsInUrl.split(','));
        }
    }, [setSortTag]);

    const onFilterChange = useCallback(
        (e) => {
            const target = e.currentTarget || {};
            setFilter(target.value || '');
        },
        [setFilter]
    );

    const constructUrl = useCallback(
        (tags) => {
            let url = cmsUrlPattern;
            if (tags && tags.length > 0) {
                const urlTags = flatten(tags);
                url += `?tags=${urlTags}`;
            }
            navigate(generateRoutePath(url));
        },
        [navigate, sortTag, cmsUrlPattern]
    );

    const onSortChange = useCallback(
        (option) => {
            setUserSorting(option && option.value ? option.value.toLowerCase() : '');
        },
        [setUserSorting]
    );

    const onTagSortChange = useCallback(
        (options) => {
            const selectedTags: string[] = [];
            (options || []).map((selectedTag) => {
                if (selectedTag) {
                    const tag = selectedTag.value;
                    if (tag) {
                        selectedTags.push(tag.toLowerCase());
                    }
                }
            });

            setSortTag(selectedTags);
            constructUrl(selectedTags);
        },
        [setSortTag]
    );

    const {mobile, tablet} = getBowserState();
    const header = pageData.header || {};
    const sliderOptions = {
        infinite: true,
        speed: 500,
        draggable: tablet || mobile,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'pagination',
        arrows: true
    };

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    let filtered: ClassData[] = [];
    let sorted: ClassData[] = [];
    let tags = [];
    const tagGrouping: string[][] = [];

    if (children) {
        if (filter || sortTag) {
            children.forEach((page) => {
                const klass = page.class;
                if (klass) {
                    const {title = '', short = '', content = '', tag = ''} = klass;
                    let lowerFilter;
                    let findTagMatch: string[] = [];
                    if (filter) {
                        lowerFilter = filter.toLowerCase().trim();
                    }

                    if (sortTag && sortTag.length > 0) {
                        findTagMatch = sortTag.filter((e) => tag.toLowerCase().indexOf(e) !== -1);
                    }

                    // Check for match in content
                    if (
                        (lowerFilter && title.toLowerCase().indexOf(lowerFilter) > -1) ||
                        (lowerFilter && short.toLowerCase().indexOf(lowerFilter) > -1) ||
                        (lowerFilter && content.toLowerCase().indexOf(lowerFilter) > -1)
                    ) {
                        // if a tag has been selected, use if to filter even further
                        if (findTagMatch.length > 0) {
                            filtered.push(page);
                        } else if (findTagMatch.length === 0 && sortTag.length === 0) {
                            filtered.push(page);
                        }

                        // If no search input has been entered, filter just by tag
                    } else if (!lowerFilter && findTagMatch.length > 0) {
                        filtered.push(page);

                        // No filter used, just show everything
                    } else if (!lowerFilter && sortTag.length === 0) {
                        filtered.push(page);
                    }
                }
            });
        } else {
            filtered = children || [];
        }

        sorted =
            filtered.sort((a, b) => {
                const classA = a.class || {};
                const classB = b.class || {};
                let itemA, itemB;
                itemA = classA.title ? classA.title.toLowerCase() : '';
                itemB = classB.title ? classB.title.toLowerCase() : '';

                if (userSorting && userSorting === 'title') {
                    itemA = classA.title ? classA.title.toLowerCase() : '';
                    itemB = classB.title ? classB.title.toLowerCase() : '';
                } else if (userSorting && userSorting === 'length') {
                    itemA = classA.length ? classA.length : '';
                    itemB = classB.length ? classB.length : '';
                } else if (userSorting && userSorting === 'release') {
                    itemA = classA.release ? classA.release : '';
                    itemB = classB.release ? classB.release : '';
                }

                if (!userSorting || (userSorting && userSorting === 'title')) {
                    return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
                } else if (userSorting && userSorting === 'release') {
                    return itemB < itemA ? -1 : itemB > itemA ? 1 : 0;
                } else {
                    return itemB - itemA;
                }
            }) || [];
    }

    const {
        courseTitle,
        courseDesc,
        sortPlaceholder,
        tagsPlaceholder,
        placeholder,
        quickRegisterBtn
    } = pageData;
    const sorting: SortingProps = pageData.sorting || {};
    const primaryBtn = header.primaryBtn || {};
    const secondaryBtn = header.secondaryBtn || {};
    const buttons: ButtonProps[] = [];
    buttons.push({
        id: 'training-primary',
        label: primaryBtn.label,
        href: primaryBtn.url
    });
    buttons.push({
        id: 'training-secondary',
        label: secondaryBtn.label,
        href: secondaryBtn.url
    });

    const sortOptions = sorting.sortOptions || defaultSortOptions;
    const showcases: (JSX.Element | undefined)[] = [];

    if (children) {
        children.forEach((page) => {
            const klass = page.class;
            if (klass) {
                const inShowcase = klass.inShowcase;
                if (inShowcase) {
                    const {title = '', badge = ''} = klass;
                    const image: FeedImagesProps = klass.image || {};
                    const urlPattern = page._urlPattern;
                    let img = image.desktopImage;
                    if (tablet && image.tabletImage) {
                        img = image.tabletImage;
                    } else if (mobile && image.mobileImage) {
                        img = image.mobileImage;
                    }

                    const showcaseImage = klass.showcaseImg;
                    if (showcaseImage) {
                        img = showcaseImage;
                    }

                    const href = generateRoutePath(urlPattern as string);

                    if (img && title) {
                        showcases.push(
                            <div key={`course-showcase-${title}`} className="course-showcase">
                                <div className="course-feature-img">
                                    <ShowcaseCardImg img={img} />
                                </div>
                                <div className="course-feature-content">
                                    <div className="course-feature-badge">
                                        {badge ? <Badge img={badge} /> : ''}
                                    </div>
                                    <a
                                        href={href}
                                        className={`course-title ${badge ? 'has-badge' : ''}`}>
                                        {title}
                                    </a>
                                </div>
                            </div>
                        );
                    }
                }

                const klassTag = klass.tag;
                if (klassTag) {
                    const splitTags: string[] = klassTag.split(',');
                    tagGrouping.push(splitTags);
                    if (tagGrouping.length > 0) {
                        tags = flatten(tagGrouping);
                    }
                }
            }
        });
    }

    const sortedSortOptions = sortOptions.sort();

    let uniqueTags = [];
    if (tags && tags.length > 0) {
        uniqueTags = uniq(tags);
    }

    return (
        <Page>
            <Training id="training">
                {getHelmetForMetaTags(pageData)}
                <Header
                    title={header.title}
                    subtitle={header.suffixTitle}
                    background={header.background}
                    type="slim"
                    center
                    buttons={buttons}
                />
                <div className="container courses-overview">
                    {courseTitle && <h2>{courseTitle}</h2>}
                    {courseDesc && <Desc dangerouslySetInnerHTML={{__html: courseDesc}} />}
                </div>

                {showcases && showcases.length && children ? (
                    <div className="container courses-showcase">
                        <SlickWrapper className="container block-talks" options={sliderOptions}>
                            {showcases}
                        </SlickWrapper>
                    </div>
                ) : (
                    ''
                )}

                <div className="course-list container">
                    <Filters>
                        <div className="select-box-wrapper">
                            {sortedSortOptions && sortedSortOptions.length ? (
                                <div className="form-group select-box">
                                    <SortLabel>{sortPlaceholder || 'Sort by:'}</SortLabel>
                                    <Select
                                        key="uol-filters"
                                        className={`filter tag-filters`}
                                        styles={OptionStyles}
                                        options={sortedSortOptions.map((option) => option)}
                                        onChange={(option) => onSortChange(option)}
                                        instanceId="sort-filtering"
                                        defaultValue={sortedSortOptions[0]}
                                        isClearable={false}
                                    />
                                </div>
                            ) : (
                                ''
                            )}

                            <div className="form-group select-box filter-by-tags">
                                <Select
                                    key="uol-tags"
                                    className={`filter tag-filters`}
                                    styles={OptionStyles}
                                    options={uniqueTags.map((value) => ({value, label: value}))}
                                    placeholder={tagsPlaceholder || 'Tags'}
                                    onChange={(option) => onTagSortChange(option)}
                                    isClearable
                                    value={sortTag.map((value) => ({value, label: value}))}
                                    isMulti
                                    instanceId="tag-filtering"
                                />
                            </div>

                            <div className="search-box">
                                <input
                                    placeholder={placeholder || 'Search'}
                                    value={filter}
                                    onChange={onFilterChange}
                                />
                            </div>
                        </div>
                    </Filters>

                    {cmsTreeLoading ? (
                        <UnrealLoading fillViewHeight={false} />
                    ) : (
                        sorted.map((page) => {
                            const klass = page.class;
                            return (
                                <CourseCard
                                    key={page._title}
                                    quickRegisterBtn={quickRegisterBtn}
                                    urlPattern={page._urlPattern || ''}
                                    {...klass}
                                />
                            );
                        })
                    )}

                    {!sorted || !sorted.length ? (
                        <h4>
                            <Message code="epic.feed.no.results.header" />
                        </h4>
                    ) : (
                        ''
                    )}
                </div>
            </Training>
        </Page>
    );
};

TrainingPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const cmsUrlPattern = getCmsUrlPattern(match);

    const responses = await Promise.all([
        await cmsApi.getPageData(cmsUrlPattern),
        await cmsApi.getTreeData(cmsUrlPattern)
    ]);

    return [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: responses[0] || {}
            }
        },
        {
            key: AppSharedKeys.CmsTreeStore,
            value: {
                [cmsUrlPattern]: responses[1] || []
            }
        }
    ];
};

export default TrainingPage;
