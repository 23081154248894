import styled from 'styled-components';

export const FilterForm = styled.div`
    input.form-control {
        border-radius: 2px;
        background: #202337;
        border: 1px solid #202337;
        border: 0;
        color: #a6a7ae;
        width: 100%;
        padding: 6px 12px;
        padding-left: 10px;
        padding-bottom: 10px;
        font-size: 1em;
        height: 36px;

        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 10px;
            height: 45px;
            margin-top: 5px;
        }
        &:hover,
        &:active,
        &:focus {
            outline: none;
            border: 0;
            box-shadow: none;
        }
    }
    .reset-tag-input {
        position: absolute;
        right: 0;
        top: 5px;
        cursor: pointer;
        display: none;
        i {
            font-size: 0.75em;
            color: #818181;
            pointer-events: none;
        }
        &:hover {
            i {
                color: #fff;
            }
        }
    }
    .tag-tooltip {
        width: 22px;
        height: 22px;
        position: absolute;
        right: 5px;
        top: 10px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            top: 19px;
        }
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 6;
        i {
            color: #818181;
            font-size: 1.1em;
            font-style: normal;
            pointer-events: none;
        }
        .desc {
            background: #1d1d1d;
            padding: 20px;
            width: 250px;
            display: block;
            position: absolute;
            left: -200px;
            top: 30px;
            color: #fff;
            display: none;
            &:after {
                bottom: 100%;
                right: 30px;
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: transparent;
                border-bottom-color: #1d1d1d;
                border-width: 10px;
                margin-left: -10px;
            }
        }
        &:hover {
            border-color: #fff;
            i {
                color: #fff;
            }
            .desc {
                display: block;
            }
        }
        &.dirty {
            .reset-tag-input {
                display: block;
            }
        }
    }

    .tag-lookup-results {
        transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-delay: 0.05s;
        transform: scaleY(0);
        transform-origin: top center;
        margin: 20px 10px 5px;
        position: absolute;
        left: -10px;
        right: 0;
        top: 16px;
        background-color: ${(p) => p.theme.palette.background.paper};
        padding: 20px;
        width: 250px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 275px;
            top: 20px;
        }
        &.open {
            display: block;
            transform: scaleY(1);
        }
        .no-match {
            color: #e5e5e5;
            opacity: 0.6;
            font-size: 0.9em;
            font-style: italic;
        }
        a {
            display: block;
            color: #fff;
            margin-bottom: 2px;
            font-size: 0.8em;
            user-select: none;
            &:hover {
                color: #222;
            }
            &.discovered-tag {
                display: inline-block;
                padding: 5px 10px;
                border-radius: 20px;
                background-color: #ccc;
                text-transform: capitalize;
                color: #333;
                cursor: pointer;
                margin-right: 5px;
                margin-bottom: 5px;
                .count {
                    opacity: 0.7;
                    left: 3px;
                }
                &:before {
                    content: none;
                }
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
`;
