import styled from 'styled-components';

export const MapOverlay = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    justify-content: flex-end;

    a {
        pointer-events: auto;
    }

    h1,
    p {
        margin-top: 0;
        margin-bottom: 0;
        color: white;
    }

    ${p => p.theme.breakpoints.down('md')} {
        justify-content: flex-start;
    }
`;
