import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import {H2} from 'epic-ue-components';
import {SectionCopy, SectionImage, SectionContent} from './Section.styles';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';

const FullTwoColSection = styled.div`
    padding: 0;
    margin: 0;
    &:not(.even) {
        background-color: #f3f3f3;
    }
    .background-video.video .overlay {
        background: rgba(0, 0, 0, 0);
    }
`;

const SectionWideContent = styled(SectionContent)`
    padding: 30px 75px 0;
    > .copy {
        max-width: 800px;
    }
    ${(p) => p.theme.breakpoints.down('lg')} {
        padding: 50px 50px 0;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        width: 100%;
        text-align: center;
        padding: 20px 2em;
    }

    ${(p) => p.theme.breakpoints.up('xl')} {
        width: 37%;
        padding: 30px 45px 25px;
    }
    ${(p) => p.theme.breakpoints.up('xxl')} {
        padding: 60px 90px 50px;
    }

    [${(p) => p.theme.breakpoints.between('sm', 'lg')}]: {
        padding: 20px 40px 40px;
        h3 {
            margin-top: 10px;
        }
    }

    h3 {
        text-align: left;
        ${(p) => p.theme.breakpoints.down('xs')} {
            text-align: center;
        }
    }

    h2 {
        font-size: 26px;
        text-align: left;
        margin-top: 0;
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 22px;
        }
    }
`;
const SectionWideImage = styled(SectionImage)`
    height: 600px;
    ${(p) => p.theme.breakpoints.up('xl')} {
        width: 63%;
        height: 700px;
    }
`;

const SectionWrapper = styled.div`
    height: 600px;
    ${(p) => p.theme.breakpoints.down('md')} {
        height: auto;
    }
    ${(p) => p.theme.breakpoints.up('xl')} {
        height: 700px;
    }
    position: relative;
    overflow: hidden;

    &&& {
        h2 {
            font-size: 2em;
        }
    }
`;

const SectionFullWidthTwoCol = ({even, title, copy, buttonData, bgType, mobile, tablet}) => {
    const sectionContent = (
        <SectionWideContent key={`fwcol-${title}`}>
            <H2>{title}</H2>
            <SectionCopy dangerouslySetInnerHTML={{__html: copy}} className="copy" />
            <CommonButtonWrapper sectionId="section-fullwidth-twocol" {...buttonData} />
        </SectionWideContent>
    );
    const sectionImage = (
        <SectionWideImage key={`fwcol-${title}-image`} className="image">
            {bgType}
        </SectionWideImage>
    );
    const contentAlignArray = [];
    if (!mobile && !tablet && !even) {
        contentAlignArray.push(sectionContent, sectionImage);
    } else if (even || mobile || tablet) {
        contentAlignArray.push(sectionImage, sectionContent);
    }
    return (
        <FullTwoColSection className={`${even ? even : ''}`}>
            <SectionWrapper>{contentAlignArray}</SectionWrapper>
        </FullTwoColSection>
    );
};

SectionFullWidthTwoCol.propTypes = {
    even: pt.bool,
    mobile: pt.bool,
    tablet: pt.bool,
    title: pt.string,
    copy: pt.string,
    buttonData: pt.object,
    bgType: pt.element
};

export default SectionFullWidthTwoCol;
