import axios, {AxiosResponse} from 'axios';
import {getLocale} from '@epic-core/common';
import {getOrigin} from 'epic-ue-shared';
import {FilterStore} from '../types';
import {isError} from '@epic-mw/error-types';

const url = () => {
    const origin = getOrigin();

    const url = {
        FEED_TAGS: locale => `${origin}/api/v2/${locale}/tags`,
        FEED_LOCALIZED_TAGS: locale => `${origin}/api/v2/${locale}/tags/localized`,
        FEED_DATA: locale => `${origin}/api/v2/${locale}/feed`,
        FEED_CONTENT_TYPES: locale => `${origin}/api/v2/${locale}/feed/content-types`,
        FEEDITEMS_DATA: locale => `${origin}/api/v2/${locale}/feeditems`,
        FEEDITEMS_LATESTSTORIES: locale => `${origin}/api/v2/${locale}/feed/latest-stories`
    };
    return url;
};

interface Tags {
    [key: string]: boolean;
}

interface Types {
    [key: string]: boolean;
}

export interface FeedItemsReq {
    tags?: Tags;
    types?: Types;
    patternsToSkip?: string[];
    numItems?: number;
}

class FeedApi {
    public async getFeedItems(req: FeedItemsReq): Promise<any> {
        const api = url().FEEDITEMS_DATA(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api, req);
            return response.data;
        } catch (e) {
            console.error('getFeedItems() failed to load', req);
            throw e;
        }
    }

    public async getLatestStories(): Promise<any> {
        const api = url().FEEDITEMS_LATESTSTORIES(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getLatestStories() failed to load');
            throw e;
        }
    }

    public async getFeedContentTypes(): Promise<any> {
        const api = url().FEED_CONTENT_TYPES(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getFeedContentTypes() failed to load');
            throw e;
        }
    }

    public async getLocalizedTags(): Promise<any> {
        const api = url().FEED_LOCALIZED_TAGS(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api);
            return response.data;
        } catch (e) {
            console.error('getLocalizedTags() failed to load');
            throw e;
        }
    }

    public async getFeedTags(): Promise<any> {
        const api = url().FEED_TAGS(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api);
            return response.data;
        } catch (e) {
            console.error('getFeedTags() failed to load');
            throw e;
        }
    }

    public async filterFeed(
        types: null | FilterStore,
        tags: null | FilterStore,
        page?: number,
        initialized?: boolean,
        patternsToSkip?: string[]
    ): Promise<any> {
        const api = url().FEED_DATA(getLocale());
        try {
            const response: AxiosResponse<any> = await axios.post(api, {
                types,
                tags,
                page,
                initialized,
                patternsToSkip
            });
            return response.data;
        } catch (e) {
            console.error('filterFeed() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const feedApi = new FeedApi();
