import styled from 'styled-components';

export const MapInfoPanel = styled.div`
    ${p => p.theme.breakpoints.down('md')} {
        display: none;
    }
    display: flex;
    flex-direction: column;
    overflow: hidden;

    h5 {
        margin: 1em 0;
        padding-left: 10%;
    }
    ul {
        overflow: auto;
        pointer-events: auto;
        list-style: none;
        height: 100%;
        padding: 0 0 5em 0;
        margin-right: 1em;
    }

    li {
        margin-bottom: 1em;
        padding-left: 10%;
    }
    li:hover {
        background: #0000007a;
    }
    li > div[role='button'] {
        outline: none;
    }
`;
