import React from 'react';
import PropTypes from 'prop-types';
import Mosaic from './Mosaic/Mosaic';

const EnterpriseHighlightsPanel = (props) => {
    const highlights = props.highlights || [];
    const eligibleItems = [...highlights];
    const accordion = props.accordion || false;

    return (
        <div>
            {props.title ? <div className="block-title">{props.title}</div> : null}

            <div className="grid-news-wrap container">
                {highlights.length > 0 ? (
                    <div>
                        {eligibleItems.length && (
                            <Mosaic
                                items={eligibleItems}
                                firstIsFeatured={props.firstFeatured}
                                locale={props.locale}
                                random={props.random}
                                accordion={accordion}
                            />
                        )}
                    </div>
                ) : (
                    <div />
                )}
            </div>
        </div>
    );
};

EnterpriseHighlightsPanel.propTypes = {
    title: PropTypes.string,
    firstFeatured: PropTypes.bool,
    highlights: PropTypes.array,
    locale: PropTypes.string,
    repeatBackgroundPattern: PropTypes.string,
    random: PropTypes.number,
    accordion: PropTypes.bool
};

export default EnterpriseHighlightsPanel;
