import styled from 'styled-components';

export const ParagonSectionWrapper = styled.div<{imgOpacity?: number}>`
    min-height: 800px;
    height: 100vh;
    width: 100%;
    color: #fff;
    transition: all ease 0.5s;
    position: relative;
    overflow: hidden;

    .background-mobile,
    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;
    }
    .container-fluid {
        padding: 0 15%;
        ${(p) => p.theme.breakpoints.down('xl')} {
            padding: 0 10%;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 0;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            padding: 0 5%;
        }
    }
    .container-fluid,
    .row,
    .row > div {
        height: 100%;
    }
    h1 {
        font-size: 5em;
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
        ${(p) => p.theme.breakpoints.down('xl')} {
            font-size: 4em;
        }
        ${(p) => p.theme.breakpoints.down('lg')} {
            font-size: 4em;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 3em;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 2em;
        }
    }
    .h2 {
        font-size: 4em;
        font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
        ${(p) => p.theme.breakpoints.down('lg')} {
            font-size: 3em;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 2em;
        }
    }
    .content {
        position: absolute;
        bottom: 0px;
        padding: 2em;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        ${(p) => p.theme.breakpoints.down('sm')} {
            bottom: 40px;
        }
        .desc,
        a {
            opacity: 0;
        }
    }

    &.inViewport {
        .background-mobile,
        .background {
            opacity: ${(p) => (p.imgOpacity !== undefined ? p.imgOpacity : 1)};
            transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            ${(p) => p.theme.breakpoints.down('lg')} {
                background-position: -200px 0;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                background-position: -400px 0;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                background-size: cover;
                background-position: 0 0;
            }
            @media (max-width: 1200px) and (orientation: landscape) {
                background-position: 0 0;
                background-size: cover;
            }
        }
        .desc,
        a {
            opacity: 1;
            transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }
    }
    &.section-top {
        background-color: #000;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 110vh;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 1000px;
        }
        @media only screen and (max-width: 767px) and (orientation: landscape) {
            height: 700px;
        }
        h1 {
            transform: translateX(-50px);
            opacity: 0;
        }
        .background-mobile,
        .background {
            left: 100px;
        }
        .content {
            left: 0;
            max-width: 800px;
            bottom: 200px;
            ${(p) => p.theme.breakpoints.down('xl')} {
                bottom: 0px;
                @media (max-height: 950px) {
                    bottom: 0px;
                }
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                bottom: 0px;
                @media (max-height: 950px) {
                    bottom: 0px;
                }
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                position: relative;
                margin: 30% auto 0;
                bottom: 0;
                text-align: center;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                position: absolute;
                bottom: 30%;
                left: 0;
                max-width: 100%;
            }
            ${(p) => p.theme.breakpoints.down('xs')} {
                bottom: initial;
                top: 0px;
            }
            @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
                margin-top: 60%;
            }
            .desc {
                margin-top: 40px;
                max-width: 700px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    max-width: 100%;
                }
            }
            a.btn {
                margin-top: 60px;
                font-size: 0.875em;
                padding: 1em;
            }
        }
        &.inViewport {
            .content {
                background-color: rgba(0, 0, 0, 0.5);
            }
            .background-mobile,
            .background {
                left: 0;
                @media only screen and (max-width: 991px) and (orientation: landscape) {
                    background-size: cover;
                    background-position: 0 0;
                }
                @media only screen and (max-width: 1199px) and (orientation: landscape) {
                    background-position: 0 0;
                    background-size: cover;
                }
                ${(p) => p.theme.breakpoints.down('sm')} {
                    background-position: 0 0;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    background-position: 0 0;
                }
            }
            h1 {
                opacity: 1;
                transform: translateX(0);
                transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
    }

    &.section-right {
        height: 75vh;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 100vh;
        }
        ${(p) => p.theme.breakpoints.down('lg')} {
            display: flex;
            flex-flow: row wrap;
            height: 1000px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 1200px;
        }
        @media only screen and (max-width: 767px) and (orientation: landscape) {
            height: 800px;
        }
        @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
            height: 150vh;
        }
        .container-fluid {
            ${(p) => p.theme.breakpoints.down('lg')} {
                order: 1;
                width: 100%;
                height: 400px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                height: 510px;
            }
            @media only screen and (max-width: 767px) and (orientation: landscape) {
                height: 400px;
            }
        }
        .background-mobile,
        .background {
            left: 100px;

            ${(p) => p.theme.breakpoints.down('lg')} {
                order: 2;
                position: relative;
                width: 100%;
                bottom: auto;
                height: 600px;
                background-position: 0 0;
            }
            @media only screen and (max-width: 767px) and (orientation: landscape) {
                height: 400px;
            }
        }
        .content {
            right: 0;
            max-width: 800px;
            color: #000;
            ${(p) => p.theme.breakpoints.down('lg')} {
                position: relative;
                width: 100%;
                max-width: 100%;
                margin-top: 150px;
            }
            @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
                bottom: auto;
                top: 40px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin-top: 75px;
            }
            .desc {
                margin-top: 40px;
                max-width: 500px;
                transform: translateY(40px);
                opacity: 0;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    max-width: 100%;
                }
            }
            .h2 {
                opacity: 0;
                transform: translateY(40px);
            }
        }
        &.inViewport {
            .background-mobile,
            .background {
                left: 0;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    left: 0;
                    bottom: -300px;
                }
                ${(p) => p.theme.breakpoints.down('md')} {
                    top: auto;
                    bottom: -50px;
                    background-position: 0 0;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                    top: auto;
                    bottom: -80px;
                    background-position: 0 0;
                }
                @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
                    background-position: 0 450px;
                }
            }
            .desc {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
            .h2 {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
    }

    &.section-form {
        div.h2 {
            ${(p) => p.theme.breakpoints.down('md')} {
                padding-top: 60px;
            }
        }
        a.btn {
            margin-top: 2em;
            margin-bottom: 5em;
        }
    }

    &.section-left {
        height: 90vh;
        .content {
            left: 0;
            max-width: 800px;
            .h2 {
                opacity: 0;
                transform: translateY(40px);
            }
            .desc {
                margin-top: 40px;
                max-width: 500px;
                opacity: 0;
                transform: translateY(40px);
            }
        }
        .background-mobile,
        .background {
            ${(p) => p.theme.breakpoints.down('lg')} {
                background-size: cover;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                background-position: -500px 0;
            }
        }
        &.inViewport {
            .content {
                background-color: rgba(0, 0, 0, 0.5);
            }
            .background-mobile,
            .background {
                background-position: 0 0;
            }
            .desc {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
            .h2 {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
    }

    &.section-form {
        height: 75vh;
        background-position: 0 -200px;
        overflow: hidden;
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 650px;
            min-height: 650px;
        }
        .h2 {
            padding-top: 100px;
            font-size: 2.5em;
            font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
            opacity: 0;
            transform: translateY(40px);
        }
        .content {
            position: relative;
            bottom: auto;
            text-align: center;
            .desc {
                margin-top: 40px;
                max-width: 100%;
                opacity: 0;
                transform: translateY(40px);
            }
            .paragon-submit-form {
                opacity: 0;
                transform: translateY(40px);
                margin: 50px 0;
                input.form-control {
                    color: #fff;
                }
                .disabled.submitted {
                    cursor: initial;
                    i {
                        display: inline-block;
                        font-size: 1em;
                        margin-right: 12px;
                        color: #fff;
                    }
                }
                ${(p) => p.theme.breakpoints.down('lg')} {
                    .container {
                        width: 100%;
                    }
                    margin: 50px auto;
                }

                ${(p) => p.theme.breakpoints.down('md')} {
                        width: 80%;              
                }

                .row {
                    margin-bottom: 15px;
                }
            }
        }
        .background-mobile,
        .background {
            ${(p) => p.theme.breakpoints.down('xl')} {
                background-position: center 0;
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                background-size: cover;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                background-position: center 0;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                background-position: center center;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                background-position: center center;
            }
        }

        &.inViewport {
            .paragon-submit-form,
            .desc {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
            .h2 {
                opacity: 1;
                transform: translateY(0);
                transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
    }
`;
