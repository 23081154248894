import {useState, useEffect} from 'react';
import {useSharedValue, createSharedValueKey} from '@epic-core/hooks';
import {enterpriseApi} from './enterprise.api';
import {useIsLoggedIn, useAccountId} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';

interface SeatResponse {
    hasAccess: boolean;
    nav: {
        title: string;
    };
}

const seatStoreKey = createSharedValueKey<SeatResponse>('SeatStore', {
    hasAccess: false,
    nav: {
        title: ''
    }
});

export const useEnterpriseSeats = (): {
    loaded: boolean;
    hasAccess: boolean;
    title: string;
    loading: boolean;
    error?: string;
} => {
    const isLoggedIn = useIsLoggedIn();
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [seats, setSeats] = useSharedValue(seatStoreKey);
    const accountId = useAccountId();

    useEffect(() => {
        if (
            loadingState.loaded ||
            loadingState.error ||
            !isLoggedIn ||
            !accountId ||
            loadingState.loading
        ) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = await enterpriseApi.getSeats(accountId);
                setSeats(Object.assign({}, data));
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch enterprise seat data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [accountId, isLoggedIn, loadingState]);

    const hasAccess = seats.hasAccess;
    const nav = seats.nav || {};
    const title = nav.title || '';

    return {
        hasAccess,
        title,
        ...loadingState
    };
};
