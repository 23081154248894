import styled from 'styled-components';

export const MegaHeaderWrapper = styled.section`
    && {
        padding: 0;
    }
    .center {
        text-align: center;
    }
    .background-type.htmlVideo {
        height: 90vh;
        ${(p) => p.theme.breakpoints.down('lg')} {
            height: 90vh;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 110vh;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            height: 100vh;
        }
        @media screen and (min-height: 600px) {
            height: 90vh;
        }
    }

    .content-wrapper {
        height: 80vh;
        position: relative;
        overflow: hidden;
        width: 100%;
        left: 0;
        ${(p) => p.theme.breakpoints.up('lg')} {
            max-width: 93vw;
        }
        ${(p) => p.theme.breakpoints.down('lg')} {
            height: 400px;
        }
        text-align: left;
        h1 {
            font-size: 66px;
            line-height: 74px;
            font-weight: 300;
            opacity: 0;
            margin-left: -100px;
            margin-top: 150px;
            font-family: ${(p) => p.theme.ue.fonts.brutal.light};
            max-width: 1500px;
            color: ${(p) => p.theme.palette.text.primary};
            text-align: left;
            ${(p) => p.theme.breakpoints.down('lg')} {
                max-width: 100%;
                margin-top: 80px;
                text-align: center;
            }
            @media only screen and (max-width: 992px) and (orientation: landscape) {
                position: absolute;
                top: 20px;
                left: 0;
                width: 100%;
                text-align: center;
                margin: 0;
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                margin-top: 90px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                position: absolute;
                top: 90px;
                left: 0;
                width: 100%;
                text-align: center;
                margin: 0;
                font-size: 48px;
                line-height: 56px;
                &.title {
                    padding: 0 15px;
                }
            }
            ${(p) => p.theme.breakpoints.up('lg')} {
                span {
                    display: block;
                }
            }
            @media screen and (max-height: 800px) and (min-width: 1200px) {
                max-width: 800px;
                margin-top: 100px;
            }
            @media only screen and (max-width: 767px) and (orientation: landscape) and (max-height: 400px) {
                top: 20px;
            }
        }

        h2 {
            font-weight: normal;
            margin-top: 20px;
            opacity: 0;
            text-align: left;
            color: ${(p) => p.theme.palette.text.primary};
            margin-left: -100px;
            width: auto;
            font-family: ${(p) => p.theme.ue.fonts.brutal.light};
            &.sub-title {
                font-size: 1.4em;
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                text-align: center;
                margin-top: -15px;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                top: 170px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                position: absolute;
                left: 0;
                width: 100%;
                text-align: center;
                margin: 0;
            }
            ${(p) => p.theme.breakpoints.down('xs')} {
                top: 220px;
            }
        }
    }

    .intro-links-wrapper,
    .intro-desc-wrapper {
        height: 200px;
        ${(p) => p.theme.breakpoints.down('lg')} {
            height: auto;
        }
    }
    .intro-desc-section {
        display: block;
        width: 100%;
        position: absolute;
        height: 40vh;
        bottom: 100px;
        ${(p) => p.theme.breakpoints.down('xl')} {
            height: 80%;
            left: 0;
        }
        ${(p) => p.theme.breakpoints.down('lg')} {
            text-align: center;
            height: 40px;
            left: 0;
            bottom: 75px;
        }

        .intro-links-wrapper {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 50%;
            z-index: 2;
            ${(p) => p.theme.breakpoints.up('lg')} {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                width: 100%;
                position: relative;
                float: none;
                margin: 0 auto;
                text-align: center;
            }

            .btn.btn-primary {
                margin-top: 2em;
            }
        }
    }

    &.runAnimation {
        h1 {
            transition: all ease 0.5s 0.3s;
            opacity: 1;
            margin-left: 0;
        }
        h2 {
            transition: all ease 0.5s 0.5s;
            opacity: 1;
            margin-left: 0;
        }
        .intro-desc-wrapper {
            opacity: 1;
            transition: all ease 0.5s 0.5s;
            left: 0;
        }
        .ue-secondary-button.button-lg {
            opacity: 1;
            right: 0;
            transition: all ease 0.5s;
        }
    }

    .credits {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 0.75em;
        color: ${(p) => p.theme.palette.text.primary};
        opacity: 0.7;
        pointer-events: none;
        ${(p) => p.theme.breakpoints.down('md')} {
            display: none;
        }
    }
`;
