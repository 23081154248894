import React from 'react';
import styled from 'styled-components';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {
    TemplateThemeProps,
    CenteredTextBlock,
    CenteredTextBlockProps,
    MultiCard,
    MultiCardProps,
    SectionIntro,
    SectionIntroProps,
    NewsletterSection,
    NewsletterSectionProps,
    DownloadSection,
    DownloadSectionProps,
    SubnavSection,
    SubnavSectionConfigProps,
    SubnavSectionProps,
    CreatorFeed,
    CreatorFeedProps,
    GridContainer,
    HomepageHeaderProps,
    HomepageHeader,
    VerticalSpacer
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {TransparentNav} from '../../Entry';
import {UnrealLoading} from 'epic-ue-loading';

const Styled = styled.div`
    position: relative;
    overflow: visible;
    margin-top: -52px;
    ${(p) => p.theme.breakpoints.down('md')} {
        overflow: hidden;
    }
`;

interface DownloadWithNewsletterSectionProps extends SubnavSectionProps {
    download?: DownloadSectionProps;
    newsletter?: NewsletterSectionProps;
}

interface HomePageData extends PageData {
    themeConfig: TemplateThemeProps;
    header: HomepageHeaderProps;
    textBlock: CenteredTextBlockProps;
    textMulticardOptions: MultiCardProps;
    imageMultiCardOptions: MultiCardProps;
    cfIntro: SectionIntroProps;
    creatorFeed: CreatorFeedProps;
    download: DownloadWithNewsletterSectionProps;
}

const urlPattern = '/home';

const HomePage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<HomePageData>(urlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        header = {} as HomepageHeaderProps,
        textBlock = {} as CenteredTextBlockProps,
        textMulticardOptions = {} as MultiCardProps,
        imageMultiCardOptions = {} as MultiCardProps,
        cfIntro = {} as SectionIntroProps,
        creatorFeed = {} as CreatorFeedProps,
        download = {} as DownloadWithNewsletterSectionProps
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const downloadSectionConfig = {
        theme: 'light',
        accentColor,
        index: 4
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <TransparentNav />
            <Styled>
                {getHelmetForMetaTags(pageData)}

                <HomepageHeader {...header} />

                <SubnavSection sectionConfig={{theme: 'light'}}>
                    <CenteredTextBlock {...textBlock} />
                    <VerticalSpacer variant="section" />
                    <MultiCard {...textMulticardOptions} />
                    <VerticalSpacer variant="section" />
                    <MultiCard {...imageMultiCardOptions} />
                </SubnavSection>

                <SectionIntro {...cfIntro} />
                <VerticalSpacer variant="heading" />

                <GridContainer>
                    <CreatorFeed {...creatorFeed} />
                </GridContainer>

                <SubnavSection
                    variant="gradient"
                    hideBottomSectionPadding={true}
                    sectionConfig={downloadSectionConfig}>
                    <DownloadSection
                        {...downloadBlockProps}
                        accentColor={accentColor}
                        variant="standard"
                    />
                    <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                    <NewsletterSection
                        variant="split"
                        {...download?.newsletter}
                        accentColor={accentColor}
                    />
                </SubnavSection>
            </Styled>
        </UeThemeProvider>
    );
};

HomePage.getInitialSharedValues = getInitialCmsPageSharedValues(urlPattern);

export default HomePage;
