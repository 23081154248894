import React from 'react';
import styled from 'styled-components';
import { Gist } from '@epic-mw/react-gist';

export const GistSectionWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;
`;

export interface GistSectionProps {
    username?: string;
    id?: string;
    file?: string;
}

export const GistSection = (props: GistSectionProps): JSX.Element => {
    return (
        <GistSectionWrapper className="gist-section">
            <>
                <Gist {...props} />
            </>
        </GistSectionWrapper>
    );
};
