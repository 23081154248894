import styled from 'styled-components';

export const EventHeaderWrapper = styled.div`
    &.enterprise-intro {
        position: relative;
        color: #fff;
        font-size: 16px;
        text-align: center;
        padding: 70px 20px;
        font-family: ${(p) => p.theme.fonts.brutal.light};
        font-weight: 300;

        ${(p) => p.theme.breakpoints.up('md')} {
            padding-top: 62px;
            position: relative;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
        }

        > * {
            position: relative;
            z-index: 2;
        }
    }

    .intro-date {
        color: #e8e8e8;
        font-size: 14px;
        padding-bottom: 12px;
        ${(p) => p.theme.breakpoints.up('sm')} {
            font-size: 18px;
        }
    }

    .intro-location {
        font-weight: 700;
        font-size: 40px;
    }

    .intro-title-enterprise {
        color: #fff;
        font-size: 48px;
        padding-top: 0;
    }

    .intro-text {
        max-width: 920px;
        text-align: center;
        margin: 40px auto;
        line-height: 1.5;

        * {
            font-size: 16px;
            ${(p) => p.theme.breakpoints.up('md')} {
                font-size: 20px;
            }
        }
    }
`;
