import styled from 'styled-components';

export const OldEvent = styled.div`
    .hightlights {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .events-tag-container {
        padding: 1em 1em 0;
        margin-top: 1em;
        text-align: center;
        .tag-item {
            margin-right: 0.5em;
        }
    }
    .block-gray {
        background: #edeff3;
        border-bottom: 1px solid #d7d7d7;
    }

    .block-dark-gray {
        background: #ececec;
        border-bottom: 1px solid #d7d7d7;
    }

    .block-white {
        border-bottom: 1px solid #d7d7d7;
    }

    .block-title {
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding: 60px 20px;
        &.schedule {
            padding-bottom: 15px;
        }
    }

    .block-subtitle {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.5;
        text-align: center;
    }

    .container {
        @media (min-width: $ent-screen-lg-min) {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .clearfix {
        &:before,
        &:after {
            content: ' ';
            // 1
            display: table;
            // 2
        }
        &:after {
            clear: both;
        }
    }
    .text-center {
        text-align: center;
    }

    .img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }

    .hide {
        display: none !important;
    }

    .hidden-xs {
        @media (max-width: $ent-screen-xs-max) {
            display: none !important;
        }
    }

    .visible-md-inline {
        display: none !important;
    }

    .visible-md-inline {
        @media (min-width: $ent-screen-md-min) and (max-width: $ent-screen-md-max) {
            display: inline !important;
        }
    }

    .visible-lg-inline {
        @media (min-width: $ent-screen-lg-min) {
            display: inline !important;
        }
    }
`;
