import {getPath} from '@epic-core/common';
import React from 'react';
import {Redirect} from './Redirect';
import {ServerRoute} from './routes';
import root from 'window-or-global';

export const getOnlineLearningRedirects = (): ServerRoute[] => {
    const path = getPath();

    const clientConfig = root.clientEnvConfig || {};
    const DEV_EPIC_COMMUNITY_BASE_URL = clientConfig && clientConfig.DEV_EPIC_COMMUNITY_BASE_URL;
    if (!DEV_EPIC_COMMUNITY_BASE_URL) {
        throw new Error('routes missing DEV_EPIC_COMMUNITY_BASE_URL');
    }
    const devEpicCommSite = DEV_EPIC_COMMUNITY_BASE_URL;

    return [
        // EN-US _________________________________________________________________________________
        {
            path: `${path}en-US/onlinelearning-courses/3d-map-navigation-in-vr`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KlJ/3d-map-navigation-in-virtual-reality`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/advanced-techniques-for-architects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/7qL/advanced-techniques-for-architects`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/aec-blueprints-by-example`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Ppq/aec-blueprints-by-example/vBw/welcome-to-blueprints-by-example`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/ambient-and-procedural-sound-design`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qR/ambient-and-procedural-sound-design`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/an-in-depth-look-at-environment-artist-based-tools`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/3G/an-in-depth-look-at-environment-artist-based-tools`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/an-in-depth-look-at-real-time-rendering`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/EGR/an-in-depth-look-at-real-time-rendering`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/animation-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/xm/animation-for-virtual-production`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/animation-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/JO/animation-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/audio-driven-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/2y/introduction-to-audio-driven-gameplay`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/balancing-blueprint-and-c-in-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/bY/balancing-blueprint-and-c-in-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/becoming-an-environment-artist-in-unreal`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Gm/becoming-an-environmental-artist-in-unreal-formerly-journey-to-becoming-an-environment-artist-in-unreal`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/blending-and-tiling-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/AJq/blending-and-tiling-textures`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/blueprint-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vW/blueprint-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/blueprint-runtime-saving-and-loading`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Do3/blueprint-runtime-saving-and-loading`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/blueprints---essential-concepts`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QGD/blueprints-essential-concepts`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/blueprints-and-gameplay-for-game-designers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/OP/blueprints-and-gameplay-for-game-designers`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/breaking-down-the-components-of-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/mo/breaking-down-the-components-of-gameplay`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/bringing-a-scene-to-life-in-twinmotion`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/PMK/bringing-your-scene-to-life-in-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/build-a-detectives-office-game-environment`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WK/build-a-detective-s-office-game-environment`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/character-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nz/character-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/comprehending-projects-and-file-structure`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qpR/comprehending-projects-and-file-structure`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/constructing-believable-environments`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/jR/constructing-believable-environments`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/converting-blueprints-to-c`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KJ/converting-blueprint-to-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-a-burning-wood-material`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/EOq/creating-a-burning-wood-material`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-a-first-person-aim-trainer`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/6R/creating-a-first-person-aim-trainer`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-a-level-blockout-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/D3/creating-a-level-blockout-for-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-a-scatter-tool-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/4mQ/creating-a-scatter-tool-with-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-a-simple-water-material`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Zd9/creating-a-simple-water-material`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-an-architectural-exterior-real-time-project`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/3mD/creating-an-architectural-exterior-real-time-project`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-an-architectural-interior-real-time-project`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/5ld/creating-an-architectural-interior-real-time-project`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-output-in-twinmotion`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/zYy/creating-output-in-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-pbr-materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/pxm/creating-pbr-materials/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-procedural-geometry-based-clouds`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/wmV/creating-procedural-geometry-based-clouds/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-two-sided-materials-with-trim-sheets`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/1aQ/creating-two-sided-materials-with-trim-sheet`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-world-aligned-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/k6N/creating-world-aligned-textures`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/dynamic-audio`,
            element: (
                <Redirect url={`${devEpicCommSite}courses/Eq/dynamic-audio`} status={301} replace />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/engine-structure-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Z9/engine-structure-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/exploring-level-design-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/rQ/exploring-level-design-for-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/generating-oddly-oscillating-globe-lenses-yourself`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/oPq/generating-oddly-oscillating-globe-lenses-yourself`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-started-in-vr-with-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Ya/getting-started-in-vr-with-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-started-with-datasmith`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/3OG/getting-started-with-datasmith`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-started-with-your-paper-2d-project`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/LwA/getting-started-with-your-paper-2d-project`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-to-know-materials-for-design-visualization`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vEZ/getting-to-know-materials-for-design-visualization`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-to-know-uvw-mapping-for-architectural-visualization`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/NLo/getting-to-know-uvw-mapping-for-architectural-visualization`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/hololens-2-mixed-reality-production-for-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/J6O/prepare-unreal-engine-for-mixed-reality-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/implementing-level-design-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/5r/implementing-level-design-with-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/improving-c-workflows-using-data`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Xp/improving-c-workflows-using-data`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/independent-filmmaking-with-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/J61/independent-filmmaking-with-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/interactive-material-swaps-using-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/VLm/interactive-material-swaps-using-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introducing-global-illumination`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/yon/introducing-global-illumination/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introducing-the-principles-of-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/9Q/introducing-the-principles-of-real-time`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introducing-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/JnO/introducing-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introduction-to-ai-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/67R/introduction-to-ai-with-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introduction-to-packaging-projects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/MlL/introduction-to-packaging-projects/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introduction-to-twinmotion`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/5Ar/introduction-to-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/introduction-to-virtual-cinematography`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/oKr/intro-to-virtual-cinematography`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/landscape-essential-concepts`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Alq/landscape-essential-concepts`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/lighting-essential-concepts-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Xwp/lighting-essential-concepts-and-effects`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/live-link-for-performance-capture`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/yn/livelink-for-performance-capture`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/making-a-blueprint-product-configurator`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Qpa/making-a-blueprint-product-configurator`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/making-the-switch-from-unity-to-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Al2/making-the-switch-from-unity-to-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/mastering-twinmotion-2020`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ePR/mastering-twinmotion-2020`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/material-editor-fundamentals-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/pm/material-editor-fundamentals-for-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/materials---exploring-essential-concepts`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/eqb/materials-exploring-essential-concepts`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/materials---understanding-the-production-workflow`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/b3Y/materials-understanding-the-production-workflow`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/materials-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/wV/materials-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/materials-master-learning`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/2dy/materials-master-learning/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/metahumans-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ML/metahumans-for-virtual-production`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/movie-render-queue-essentials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Aq/movie-render-queue-essentials`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/optimizing-geometry-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/bW7/optimizing-geometry-for-real-time`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/optimizing-projects-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/d1r/optimizing-projects-for-real-time/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/physics-based-shotviz`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Gon/physics-based-shotviz`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/pixel-streaming---how-to-work-with-interactive-video-in-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Opx/pixel-streaming-how-to-work-with-interactive-video-in-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/post-processing-essentials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/GKm/post-processing-essentials`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/preparing-design-data-for-optimal-performance`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/6Pz/preparing-design-data-for-optimal-performance`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/programming-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/mDa/programming-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/ray-traced-cinematic-lighting-for-interiors`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QD/ray-traced-cinematic-lighting-for-interiors`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/real-time-compositing-basics`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ePb/real-time-compositing-basics/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/real-time-rendering-fundamentals`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vxW/real-time-rendering-fundamentals`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/rendering-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WGq/rendering-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/revit-to-unreal-engine-fundamentals`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/xmN/revit-to-unreal-engine-fundamentals`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/sound-and-space`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kN/sound-and-space`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/studio-lighting`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kN/sound-and-space`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/stylize-renders-with-post-process-materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qyl/stylize-renders-with-post-process-materials`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/understanding-audio-mixing-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nN0/understanding-audio-mixing-and-effects`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-editor-fundamentals---actors-and-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/OyP/unreal-editor-fundamentals-actors-and-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-editor-fundamentals---animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WAK/unreal-editor-fundamentals-animation`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-editor-fundamentals---animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WAK/unreal-editor-fundamentals-animation`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-editor-fundamentals---editor-introduction`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WAK/unreal-editor-fundamentals-editor-introduction`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-editor-fundamentals---materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nDz/unreal-editor-fundamentals-materials`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-kickstart-for-developers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/Ge/unreal-engine-kickstart-for-developers`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/using-enumerations-in-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/0GQ/using-enumerations-in-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/using-magic-leap-in-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Ml7/using-magic-leap-in-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/using-tools-templates-and-packs-to-improve-your-editor-workflows`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Ra5/using-tools-templates-and-packs-to-improve-your-editor-workflows`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/welcome-to-architectural-visualization`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/Wb/welcome-to-architectural-visualization`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/virtual-production-primer`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/4M/welcome-to-film-and-television`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/welcome-to-film-and-television`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/4M/welcome-to-film-and-television`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/welcome-to-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/OR/welcome-to-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/getting-started-in-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/7a/welcome-to-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/welcome-to-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/7a/welcome-to-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/working-with-data-in-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KpM/working-with-data-in-blueprint`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/working-with-data-in-blueprint`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KpM/working-with-data-in-blueprint`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/world-building-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/1Q/world-building-kickstart`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/your-first-hour-with-animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/DZ0/your-first-hour-with-animation`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/your-first-hour-with-umg`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/l3p/your-first-hour-in-umg/6o5/introduction`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/your-first-hour-in-unreal-engine-4`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ZpX/your-first-hour-in-unreal-engine-5/`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/high-end-product-lighting-and-rendering-exterior`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/L0V/high-end-product-lighting-and-rendering-exterior`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-quartz-music-system`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/XAw/unreal-engine-quartz-music-system`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-ambient-and-procedural-sound-design`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qR/unreal-engine-ambient-and-procedural-sound-design`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-sound-and-space`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kN/unreal-engine-sound-and-space`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-dynamic-audio`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Eq/unreal-engine-dynamic-audio`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-introduction-to-audio-driven-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/2y/unreal-engine-introduction-to-audio-driven-gameplay`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-audio-driven-gameplay-non-real-time-analysis`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/LA/unreal-engine-audio-driven-gameplay-non-real-time-analysis`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-audio-driven-gameplay-real-time-analysis`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Vm/unreal-engine-audio-driven-gameplay-real-time-analysis`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/unreal-engine-understanding-audio-mixing-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nN0/unreal-engine-understanding-audio-mixing-and-effects`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}en-US/onlinelearning-courses/best-practices-for-creating-and-using-plugins`,
            element: <Redirect url={devEpicCommSite} status={301} replace />
        },
        {
            path: `${path}en-US/onlinelearning-courses/creating-photoreal-cinematics-with-quixel`,
            element: <Redirect url={devEpicCommSite} status={301} replace />
        },
        {
            path: `${path}en-US/onlinelearning-courses/vr-development-with-oculus-and-unreal-engine`,
            element: <Redirect url={devEpicCommSite} status={301} replace />
        },
        {
            path: `${path}en-US/onlinelearning-courses/*`,
            element: <Redirect url={devEpicCommSite} status={302} />
        },
        {
            path: `${path}en-US/onlinelearning`,
            element: <Redirect url={devEpicCommSite} status={301} />
        },
        {
            path: `${path}en-US/onlinelearning-courses`,
            element: <Redirect url={devEpicCommSite} status={301} />
        },
        // JA ___________________________________________________________________________________
        {
            path: `${path}ja/onlinelearning-courses/hololens-2-mixed-reality-production-for-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/M97/hololens-2-mixed-reality-production-for-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/metahuman-workflows-with-faceware-studio`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/bOW/unreal-engine-faceware-studio-metahuman`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/getting-started-with-your-paper-2d-project`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/z7x/unreal-engine-paper-2d`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/creating-pbr-materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/pxm/unreal-engine-creating-pbr-materials`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/pixel-streaming---how-to-work-with-interactive-video-in-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Opx/unreal-engine-pixel-streaming-how-to-work-with-interactive-video-in-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/quartz-music-system`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/XAw/unreal-engine-quartz-music-system`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/mastering-twinmotion-2020`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/e6q/unreal-engine-twinmotion-2020`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/upgrading-to-ue5-early-access`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}talks-and-demos/J5q/upgrading-your-project-to-ue5`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/unreal-editor-fundamentals---actors-and-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/yLm/unreal-engine-unreal-editor`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/unreal-editor-fundamentals---animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ekM/unreal-engine-unreal-editor`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/unreal-editor-fundamentals---materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QDe/unreal-engine-unreal-editor`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/unreal-engine-kickstart-for-developers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/zy/unreal-engine-kickstart-for-developers-introduction`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/welcome-to-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/7a/welcome-to-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/becoming-an-environment-artist-in-unreal`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/v1M/unreal-engine-unreal`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/unreal-editor-fundamentals---editor-introduction`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WAK/unreal-engine-unreal-editor-fundamentals-editor-introduction`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/animation-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/A32/unreal-engine-a76bce`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/ambient-and-procedural-sound-design`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/NDM/unreal-engine-ed288c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/independent-filmmaking-with-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nKo/unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/engine-structure-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/XvG/unreal-engine-71fc4d`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/understanding-audio-mixing-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/1GG/unreal-engine-7ba081`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/audio-driven-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Lnl/unreal-engine-b811a4`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/character-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/aWn/unreal-engine-36f4f8`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/blueprints-and-gameplay-for-game-designers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/4wr/unreal-engine-b8f993/Yjnn/unreal-engine-537f90`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/breaking-down-the-components-of-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ybm/unreal-engine-219254`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/creating-a-level-blockout-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/bVK/unreal-engine-ba6bdc`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/balancing-blueprint-and-c-in-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/33x/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/material-editor-fundamentals-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/NJN/unreal-engine-0ede6a`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/exploring-level-design-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ylK/unreal-engine-337390`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/welcome-to-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/OR/welcome-to-game-development`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/sound-and-space`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/dQZ/unreal-engine-a83b1d`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/dynamic-audio`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/6w5/unreal-engine-70cd98`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/blending-and-tiling-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/9dq/unreal-engine-1c0fc2`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/getting-to-know-materials-for-design-visualization`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vEZ/unreal-engine-getting-to-know-materials-for-design-visualization`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/improving-c-workflows-using-data`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/www/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/high-end-product-lighting-and-rendering-exterior`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/L0V/high-end-product-lighting-and-rendering-exterior`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/metahumans-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/WMz/unreal-engine-metahuman`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/animation-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kmy/unreal-engine-e73968`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/introduction-to-virtual-cinematography`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/oKr/unreal-engine-intro-to-virtual-cinematography`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/live-link-for-performance-capture`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/RvW/unreal-engine-live-link`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/creating-a-first-person-aim-trainer`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QLe/unreal-engine-a43a3a`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/blueprint-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/7rR/unreal-engine-6f4216`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/blueprint-runtime-saving-and-loading`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Do3/unreal-engine-blueprint-runtime-saving-and-loading`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/using-enumerations-in-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/0GQ/using-enumerations-in-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/making-a-blueprint-product-configurator`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Qpa/unreal-engine-making-a-blueprint-product-configurator`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/blueprints---essential-concepts`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/NKM/unreal-engine-30a1c8`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/converting-blueprints-to-c`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Evl/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/introduction-to-ai-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/67R/introduction-to-ai-with-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/interactive-material-swaps-using-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/VLm/interactive-material-swaps-using-blueprints`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/implementing-level-design-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/O2o/unreal-engine-30bd4c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/programming-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/0qJ/unreal-engine-aa0c08`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/comprehending-projects-and-file-structure`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qpR/comprehending-projects-and-file-structure`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/introduction-to-packaging-projects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qzG/unreal-engine-306202`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/materials-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/9Nq/unreal-engine-6f9c1d`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/materials-master-learning`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/2dy/materials-master-learning`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/materials---understanding-the-production-workflow`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/de9/unreal-engine-fedd4b`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/materials---exploring-essential-concepts`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/llQ/unreal-engine-ec1d0b`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/movie-render-queue-essentials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ryM/unreal-engine-36b451`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/generating-oddly-oscillating-globe-lenses-yourself`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vwq/unreal-engine-9998b7`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/lighting-essential-concepts-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/MmM/unreal-engine-c42720`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/real-time-rendering-fundamentals`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/P51/unreal-engine-13ca3f`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/an-in-depth-look-at-real-time-rendering`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/xbN/unreal-engine-f31453`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/optimizing-geometry-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qxG/unreal-engine-da4e06`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/optimizing-projects-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/d1r/optimizing-projects-for-real-time`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/introducing-the-principles-of-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/9Q/introducing-the-principles-of-real-time`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/rendering-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/VwG/unreal-engine-40bc54`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/world-building-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kx0/unreal-engine-6c7eb7`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/creating-world-aligned-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/k6N/creating-world-aligned-textures`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/your-first-hour-with-animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/MnM/unreal-engine-2c5580`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/ray-traced-cinematic-lighting-for-interiors`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/DPW/unreal-engine-6d2c7c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/welcome-to-architectural-visualization`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/Wb/welcome-to-architectural-visualization`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/build-a-detectives-office-game-environment`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/rp6/unreal-engine-daa397`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/welcome-to-film-and-television`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}paths/4M/welcome-to-film-and-television`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/physics-based-shotviz`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Gv9/unreal-engine-b040a3`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/advanced-skill-sets-for-environment-art`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Qwa/advanced-skill-sets-for-environment-art`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/an-in-depth-look-at-environment-artist-based-tools`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KAM/unreal-engine-c5da06`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses/*`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ja`}
                    status={302}
                />
            )
        },
        {
            path: `${path}ja/onlinelearning`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ja`}
                    status={301}
                />
            )
        },
        {
            path: `${path}ja/onlinelearning-courses`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ja`}
                    status={301}
                />
            )
        },
        // KO ___________________________________________________________________________________
        {
            path: `${path}ko/onlinelearning-courses/unreal-editor-fundamentals---actors-and-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Mne/unreal-engine-734f6a`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/unreal-editor-fundamentals---animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Gbk/unreal-engine-c812ac`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/unreal-editor-fundamentals---materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/mEK/unreal-engine-2a1ae6`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/becoming-an-environment-artist-in-unreal`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/OEo/unreal-engine-277efa`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/animation-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QLa/unreal-engine-2af592`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/ambient-and-procedural-sound-design`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/9YA/unreal-engine-3f6f25`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/independent-filmmaking-with-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KKV/unreal-engine-84296f`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/understanding-audio-mixing-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kpK/unreal-engine-352bcb`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/audio-driven-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Vpd/unreal-engine-678b03`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/blueprints-and-gameplay-for-game-designers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ayn/unreal-engine-c0c203/5na8/unreal-engine-8497e3`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/breaking-down-the-components-of-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/MLe/unreal-engine-06d0bd`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/creating-a-level-blockout-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/GVk/unreal-engine-8431e2`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/balancing-blueprint-and-c-in-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/LDb/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/sound-and-space`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/0oJ/unreal-engine-73ef0a`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/blending-and-tiling-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/xe0/unreal-engine-b42df7/9kly/unreal-engine-919b3b`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/improving-c-workflows-using-data`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/19G/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/metahumans-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/nqR/unreal-engine-70ff93`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/animation-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/3YD/unreal-engine-ecbfbe`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/live-link-for-performance-capture`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/lK7/unreal-engine-029750`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/creating-a-first-person-aim-trainer`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qk9/unreal-engine-8f6796`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/implementing-level-design-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/mkw/unreal-engine-ec7d5c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/materials---understanding-the-production-workflow`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/03p/unreal-engine-357deb`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/movie-render-queue-essentials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Gzk/unreal-engine-d65556`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/an-in-depth-look-at-real-time-rendering`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ybd/unreal-engine-fae59b`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/optimizing-geometry-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/elq/unreal-engine-304529`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/your-first-hour-with-animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/AeE/unreal-engine-7ee79a`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/ray-traced-cinematic-lighting-for-interiors`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/EDe/unreal-engine-e1203e`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses/*`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ko`}
                    status={302}
                />
            )
        },
        {
            path: `${path}ko/onlinelearning`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ko`}
                    status={301}
                />
            )
        },
        {
            path: `${path}ko/onlinelearning-courses`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=ko`}
                    status={301}
                />
            )
        },
        // ZH-CN _________________________________________________________________________________
        {
            path: `${path}zh-CN/onlinelearning-courses/unreal-editor-fundamentals---actors-and-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/AeV/unreal-engine-actor`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/unreal-editor-fundamentals---animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/NKN/unreal-engine-b40659`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/unreal-editor-fundamentals---materials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/JGX/unreal-engine-0f61ea`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/becoming-an-environment-artist-in-unreal`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/mnw/unreal-engine-8b24c1`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/animation-kickstart`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/qkl/unreal-engine-398790`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/ambient-and-procedural-sound-design`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/xba/unreal-engine-5aecd3`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/independent-filmmaking-with-unreal-engine`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Dve/unreal-engine-ada142`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/understanding-audio-mixing-and-effects`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/3Nb/unreal-engine-af0462`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/audio-driven-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/6yX/unreal-engine-78cf32`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/blueprints-and-gameplay-for-game-designers`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/oR5/unreal-engine-bac315/xZ8E/unreal-engine-ff86ca`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/breaking-down-the-components-of-gameplay`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/A3V/unreal-engine-07b192`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/creating-a-level-blockout-for-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/vqM/unreal-engine-2743c7`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/balancing-blueprint-and-c-in-game-development`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/VkG/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/sound-and-space`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/RkG/unreal-engine-7ed2ac`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/blending-and-tiling-textures`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/yLK/unreal-engine-b71b62/lMpK/unreal-engine-943e34`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/improving-c-workflows-using-data`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/kMK/unreal-engine-c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/metahumans-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/KDL/unreal-engine-metahuman`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/animation-for-virtual-production`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/1J4/unreal-engine-376af5`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/live-link-for-performance-capture`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/7d1/unreal-engine-live-link`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/creating-a-first-person-aim-trainer`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/JLX/unreal-engine-631e11`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/implementing-level-design-with-blueprints`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Wwz/unreal-engine-10d7e3`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/materials---understanding-the-production-workflow`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/lZ7/unreal-engine-a0e38c`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/movie-render-queue-essentials`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/zn2/unreal-engine-05ab54`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/an-in-depth-look-at-real-time-rendering`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/ML7/unreal-engine-24ce54`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/optimizing-geometry-for-real-time`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/bMW/unreal-engine-cfa0f7`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/your-first-hour-with-animation`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/QD3/unreal-engine-5addea`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/ray-traced-cinematic-lighting-for-interiors`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}courses/Zqo/unreal-engine-b8567e`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses/*`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=zh`}
                    status={302}
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=zh`}
                    status={301}
                />
            )
        },
        {
            path: `${path}zh-CN/onlinelearning-courses`,
            element: (
                <Redirect
                    url={`${devEpicCommSite}?application=unreal_engine&languages=zh`}
                    status={301}
                />
            )
        },
        // ELSE _________________________________________________________________________________
        {
            path: `${path}:locale/onlinelearning-courses/*`,
            element: <Redirect url={devEpicCommSite} status={302} />
        },
        {
            path: `${path}:locale/onlinelearning`,
            element: <Redirect url={devEpicCommSite} status={301} />
        },
        {
            path: `${path}:locale/onlinelearning-courses`,
            element: <Redirect url={devEpicCommSite} status={301} />
        }
    ];
};
