import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {FeedDescription} from '../../description/FeedDescription';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import {FeedImage, FeedHeading, FeedDate} from '../common';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';

export const MediumFeedCardWrapper = styled.div`
    .feed-card {
        background: ${(p) => p.theme.palette.background.default};
    }

    .image-wrapper {
        overflow: hidden;
        width: 290px;
        height: 200px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 130px;
            height: 130px;
        }
        padding: 20px;
        img.image {
            height: 170px;
            width: 290px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 130px;
                height: 130px;
            }
        }
    }

    .content {
        position: relative;
        h2 {
            margin-top: 0;
            font-size: 1.2em;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 0.875em;
            }
        }
        .short {
            line-height: 1.5em;
            overflow: hidden;
            ${(p) => p.theme.breakpoints.down('sm')} {
                display: none;
            }
        }
    }
`;

interface Properties {
    item: FeedItemProps;
    disableClick?: boolean;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const MediumFeedCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const disableClick = props.disableClick || false;
    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';
    const _feedSlug = item._feedSlug || props.title || '';
    const _feedDate = item._feedDate || '';

    return (
        <FeedItemDefaultsWrapper>
            <MediumFeedCardWrapper className="feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <FeedImage item={item} linkPath={linkPath} title={title} />
                        </Grid>
                        <Grid item xs container>
                            <div className="content">
                                <div className="inner">
                                    <ContentTypeLabel
                                        item={item}
                                        tagMatch={tagMatch}
                                        disableClick={disableClick}>
                                        <FeedDate date={_feedDate} />
                                    </ContentTypeLabel>
                                    <FeedHeading item={item} linkPath={linkPath} title={title} />
                                    <FeedDescription item={item} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </MediumFeedCardWrapper>
        </FeedItemDefaultsWrapper>
    );
};
