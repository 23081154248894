import styled from 'styled-components';

export const BulletTrain = styled.div`
    width: 100%;
    max-width: 100%;
    padding: 0;

    #bulletTrain {
        .btn,
        .links .box .caption {
            font-weight: 600;
            overflow-x: hidden;
        }
        .jumbotron {
            position: relative;
            height: 700px;
            margin-bottom: 0;
            .intro {
                min-height: 350px;
                margin-top: 85px;
                margin-left: 125px;
                background: url(https://cdn2.unrealengine.com/BulletTrain/Intro_Sequence-min-2048x814-155042035.png)
                    center no-repeat;
                background-size: contain !important;
            }
        }
        .tv {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 700px;
            overflow: hidden;
            .screen {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 0;
                transform: translateY(-25%);
                transition: opacity 0.5s;
                &.active {
                    opacity: 0.25;
                }
            }
        }
        .btn {
            font-size: 1em;
            text-transform: none;
            display: block;
            margin: 2em auto 0;
            max-width: 320px;
            white-space: normal;
            text-align: center;
            background-color: #0aaff1 !important;
            border: 1px solid #0aaff1;
            &:hover {
                background-color: #10b4f5 !important;
            }
        }
        .description {
            p {
                font-size: 1.1em;
                text-align: center;
                line-height: 2;
                letter-spacing: 0.025em;
            }
        }
        .links {
            margin: 4em auto 0;
            .box {
                min-height: 300px;
                width: 100%;
                background: #e2e3e4;
                position: relative;
                background-size: cover !important;
                box-shadow: 0 12px 18px -10px rgba(0, 0, 0, 0.5);
                overflow: hidden;
                cursor: pointer;
                display: block;
            }
            .blog .bkgrd,
            .doc .bkgrd,
            .started .bkgrd {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover !important;
                transition: transform 0.3s ease-in-out;
            }
            .blog:hover .bkgrd,
            .doc:hover .bkgrd,
            .started:hover .bkgrd {
                transform: scale(1.1);
            }
            .blog .bkgrd {
                background: url(https://cdn2.unrealengine.com/BulletTrain/BulletTrain_02-min-720x377-1042310735.jpg)
                    center no-repeat;
            }
            .doc .bkgrd {
                background: url(https://cdn2.unrealengine.com/BulletTrain/BTS-min-720x405-2038943771.jpg)
                    center no-repeat;
            }
            .started .bkgrd {
                background: url(https://cdn2.unrealengine.com/BulletTrain/LearnVR-min-720x386-243617355.jpg)
                    center no-repeat;
            }
            .box {
                .caption {
                    color: #fff;
                    background: rgba(31, 31, 31, 0.5);
                    padding: 1.25em;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: background 0.3s ease-in-out;
                }
                a {
                    color: #fff;
                }
                &:hover .caption {
                    background: rgba(31, 31, 31, 0.9);
                }
                a {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
        .textLink {
            font-size: 0.85em;
            display: block;
            margin: 1em auto 2em;
            text-align: center;
            a {
                color: #0aaff1;
            }
        }
    }

    @media (max-width: 1440px) {
        #bulletTrain .jumbotron {
            height: 400px;
            .tv {
                height: 400px;
            }
            .intro {
                margin-top: 0;
                margin-left: 70px;
                min-height: 225px;
            }
        }
    }

    @media (max-width: 970px) {
        #bulletTrain {
            .jumbotron {
                height: 350px;
                .tv {
                    height: 350px;
                }
                .intro {
                    margin-top: 0;
                    margin-left: 60px;
                    min-height: 200px;
                }
            }
            .description {
                margin: 2em auto 0;
            }
            .links {
                margin: 2em auto 0;
                .box {
                    margin: 2em 0;
                }
                .caption {
                    background-color: rgba(31, 31, 31, 1) !important;
                }
            }
        }
    }

    @media (max-width: 768px) {
        #bulletTrain {
            .jumbotron {
                height: 250px;
                background: url(https://cdn2.unrealengine.com/BulletTrain/bkgrd-mobile-min-640x360-1389514920.jpg)
                    top no-repeat;
                background-size: cover !important;
                .tv {
                    height: 250px;
                    background: url(https://cdn2.unrealengine.com/BulletTrain/bkgrd-mobile-min-640x360-1389514920.jpg)
                        top no-repeat;
                    background-size: cover !important;
                }
                .intro {
                    margin-left: 25px;
                    min-height: 150px;
                }
            }
        }
        .description p {
            font-size: 0.9em;
        }
        .btn {
            font-size: 0.75em;
            margin: 0.75em auto;
        }
        .links {
            margin-top: 0;
        }
    }
`;
