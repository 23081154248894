import React from 'react';
import {trunc} from 'epic-ue-shared';
import {getShort} from '../../utils';
import {getBowserState} from '@epic-core/common';
import {FeedItemProps} from '../../types';

interface Props {
    item: FeedItemProps;
}

export const FeedDescription = (props: Props): JSX.Element => {
    const item = props.item || {};
    const {tablet} = getBowserState();
    let short = getShort(item);
    short = !tablet ? short : trunc(short, 200);

    let shortElem: JSX.Element = <div key="no-feed-item-short" />;
    if (short) {
        shortElem = (
            <div className="short">
                <div dangerouslySetInnerHTML={{__html: short}} />
            </div>
        );
    }
    return <div>{shortElem}</div>;
};
