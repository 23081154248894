import root from 'window-or-global';
import React from 'react';
import styled from 'styled-components';
import ErrorPage from './ErrorPage';
import {VerticalSpacer, Form, FeatureUnavailable} from 'epic-ue-ui';
import {
    formApi,
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    UELHeaderProps,
    useAccountAODCPolicy,
    useAccountLoginRequired,
    useCmsData
} from 'epic-ue-shared';
import {Header, Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {
    MetaHumanEntitlementData,
    metaHumanEntitlementDataKey,
    useMetaHumanEntitlement
} from '../shared';
import {useSharedValue} from '@epic-core/hooks';
import {useLocalization} from '@epic-mw/localization';

const cmsUrlPattern = '/metahuman-creator-apply';

export const MetaHumanCreatorApply = styled.div``;

export const MetaHumanForm = styled.div`
    margin: 0 1em;
`;

export const TextContainer = styled.div`
    padding: 4em 0;
    text-align: center;
    margin: 0 auto;
    font-size: 1.2em;
`;

export const MetaHumanHeader = styled.div`
    .header h2 {
        font-size: 26px;
        line-height: 42px;
    }

    .header-bottom-content .copy {
        max-width: 800px;

        ${(p) => p.theme.breakpoints.down('md')} {
            max-width: 100%;
        }
    }
`;

export interface MetaHumanCreatorApplyPageData extends PageData {
    header?: UELHeaderProps;
    form: any;
    hideMarketoForm?: boolean;
    formUnavailableMessage?: string;
}

const MetaHumanCreatorApplyPage = (): JSX.Element => {
    const getMessage = useLocalization();
    const {cmsLoading, cmsLoadedEmpty, pageData} =
        useCmsData<MetaHumanCreatorApplyPageData>(cmsUrlPattern);
    const {
        header = {} as UELHeaderProps,
        form = {},
        hideMarketoForm = false,
        formUnavailableMessage = 'We are experiencing a high volume of applications. Please come back again soon!'
    } = pageData;
    const [, setMetaHumanEntitlement] = useSharedValue(metaHumanEntitlementDataKey);
    const {accountLoading, isLoggedIn} = useAccountLoginRequired();
    const {metaHumanEntitlement} = useMetaHumanEntitlement();
    const hasEntitlement = metaHumanEntitlement.hasStandard;
    const {result: accountPolicy, isLoading: aodcLoading} = useAccountAODCPolicy();
    const loading = cmsLoading || accountLoading || !isLoggedIn || aodcLoading;
    const aodcAccountPolicyFailed = accountPolicy?.AODCFailed;
    const failTitle = getMessage('epic.ue.signup.newsletter.not.allowed.immediate.title') || '';
    const failMessage = getMessage('epic.ue.signup.newsletter.not.allowed.immediate.message') || '';

    if (loading) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />
            </>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const metaHumanCreatorUrl =
        (root.clientEnvConfig && root.clientEnvConfig.REACT_APP_METAHUMAN_CREATOR_URL) ||
        'https://metahuman.unrealengine.com';

    const redirectToMetaHumanCreator = () => {
        setTimeout(() => {
            if (root && root.window) {
                root.window.location = metaHumanCreatorUrl;
            }
        }, 5000);
    };

    const headerElement = (
        <MetaHumanHeader>
            <Header
                title={header.title}
                height={header.height}
                subtitle={header.subtitle}
                tag={header.tag}
                desc={header.desc}
                type={header.type}
                origin="industry"
                showreel={header.showreel}
                buttons={header.buttons || []}
                vcenter={header.vcenter || false}
                background={header.background || {}}
            />
        </MetaHumanHeader>
    );

    if (hasEntitlement) {
        redirectToMetaHumanCreator();

        return (
            <Page>
                <MetaHumanCreatorApply className="metahuman-creator-apply">
                    {getHelmetForMetaTags(pageData)}
                    {headerElement}
                    <MetaHumanForm>
                        <TextContainer>
                            {form.messages.successMsg ||
                                "You're in! Redirecting you to MetaHuman Creator."}
                        </TextContainer>
                    </MetaHumanForm>
                </MetaHumanCreatorApply>
            </Page>
        );
    }

    return (
        <Page>
            <MetaHumanCreatorApply className="metahuman-creator-apply">
                {getHelmetForMetaTags(pageData)}
                {headerElement}
                <MetaHumanForm>
                    {!hideMarketoForm ? (
                        <>
                            {aodcAccountPolicyFailed ? (
                                <>
                                    <VerticalSpacer variant="section" />
                                    <FeatureUnavailable
                                        failTitle={failTitle}
                                        failMessage={failMessage}
                                    />
                                </>
                            ) : (
                                <>
                                    <Form
                                        form={form}
                                        onAfterSubmit={async () => {
                                            await formApi.metahumanApply();
                                            setMetaHumanEntitlement({} as MetaHumanEntitlementData);
                                        }}
                                    />
                                    <VerticalSpacer variant="section" />
                                </>
                            )}
                        </>
                    ) : (
                        <TextContainer dangerouslySetInnerHTML={{__html: formUnavailableMessage}} />
                    )}
                </MetaHumanForm>
            </MetaHumanCreatorApply>
        </Page>
    );
};

MetaHumanCreatorApplyPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default MetaHumanCreatorApplyPage;
