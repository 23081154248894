import styled from 'styled-components';

const AgendaWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    padding-top: 50px;
    background-color: ${(p) => p.primaryColor || p.theme.palette.background.default};
    div,
    td,
    th,
    p,
    th {
        color: ${(p) => p.textColor || p.theme.palette.text.primary};
    }
    h2 {
        text-align: center;
    }
    background-repeat: no-repeat;
    background-position: right top;
    .form {
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 90%;
            margin: 20px auto;
        }
    }
`;

const AgendaTableWrapper = styled.div`
    background-color: ${(p) => p.supportingColor || p.theme.palette.background.default};
    padding-bottom: 50px;
    table {
        position: relative;
        width: 100%;
        display: table;
        margin: 50px auto;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 90%;
            margin: 50px auto;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            width: 96%;
        }
        border-collapse: collapse;
        &.tab-list-item {
            display: none;
        }
        &.tab-list-active {
            display: table;
        }

        tr {
            > td {
                transition: height 0.3s ease-in, opacity 0.3s ease-in, background-color 0.3s ease-in;
                span {
                    display: block;
                    height: 0;
                    opacity: 0;
                    overflow: hidden;
                    transition: height 0.3s ease-in, opacity 0.3s ease-in,
                        background-color 0.3s ease-in;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 0.875em;
                    }
                }
            }
            &.open {
                td {
                    &:last-child {
                        font-weight: bold;
                    }
                    span {
                        display: block;
                        font-weight: normal;
                        opacity: 1;
                        height: auto;
                        transition: height 0.3s ease-in, opacity 0.3s ease-in,
                            background-color 0.3s ease-in;
                        margin: 20px 0;
                    }
                }
            }
        }
        th,
        td {
            padding: 20px;
            vertical-align: top;
            line-height: 1.5;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding: 10px 6px;
                font-size: 0.9em;
            }
        }
        th {
            vertical-align: bottom;
            padding-right: 18px;
            position: relative;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 0.75em;
                font-weight: normal;
            }
            span {
                &:before,
                &:after {
                    border: 6px solid transparent;
                    content: '';
                    display: block;
                    height: 0;
                    right: 5px;
                    top: 50%;
                    position: absolute;
                    width: 0;
                }

                &:before {
                    border-bottom-color: ${(p) => p.textColor || '#000000'};
                    margin-top: -12px;
                }

                &:after {
                    border-top-color: ${(p) => p.textColor || '#000000'};
                    margin-top: 3px;
                }
            }
        }
        tr {
            &.has-desc {
                cursor: pointer;
            }

            &.open {
                td {
                    background-color: ${(p) =>
                        p.primaryColor || p.theme.palette.background.default};
                }
            }

            &:not(.toggle) {
                th.active {
                    span:before {
                        border-bottom-color: ${(p) => p.supportingColor || '#ccc'};
                    }
                }
            }
            &.toggle {
                th.active {
                    span:after {
                        border-top-color: ${(p) => p.supportingColor || '#ccc'};
                    }
                }
            }

            td {
                background-color: ${(p) => p.accordionColor || p.theme.palette.background.paper};
                border-bottom: 15px solid
                    ${(p) => p.supportingColor || p.theme.palette.background.default};
                border-right: 1px solid
                    ${(p) => p.supportingColor || p.theme.palette.background.default};
                position: relative;
                &:last-child {
                    border-right: 0;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        padding-right: 40px;
                    }
                }
                &:nth-child(0) {
                    width: 30%;
                }
                i:before {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    font-size: 1em;
                }
            }
        }
    }
`;

const DayTitle = styled.li`
    display: inline-block;
    font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
    font-size: 2em;
    width: auto;
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    ${(p) => p.theme.breakpoints.down('md')} {
        font-size: 1.75em;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 1.25em;
    }
`;

const DayTitleWrapper = styled.ul`
    list-style: none;
    margin: 40px auto 0;
    text-align: center;
    padding: 0;
    li {
        display: inline-block;
        &.tab-item {
            border-bottom: 8px solid transparent;
        }
        &.tab-item.active {
            border-color: ${(p) => p.primaryColor || p.theme.ue.colors.primary};
        }
    }
`;

export {AgendaWrapper, AgendaTableWrapper, DayTitle, DayTitleWrapper};
