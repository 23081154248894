import React from 'react';
import root from 'window-or-global';
import debounce from 'lodash.debounce';

export default class Row extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rowWidth: 0
        };

        this.debounceResize = debounce(this.getRowWidth, 50, {leading: false});
    }

    componentDidMount() {
        root.addEventListener('resize', this.debounceResize);
        this.getRowWidth();
    }

    componentWillUnmount() {
        root.removeEventListener('resize', this.debounceResize);
    }

    getRowWidth = () => {
        if (this.gridRowRef) {
            const rowWidth = this.gridRowRef.clientWidth;

            if (this.state.rowWidth !== rowWidth) {
                this.setState({
                    rowWidth
                });
            }
        }
    };

    render() {
        return (
            <div
                className="grid-news-row"
                ref={c => {
                    this.gridRowRef = c;
                }}
            />
        );
    }
}
