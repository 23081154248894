import React from 'react';
import styled from 'styled-components';
import {Image, MouseHijack, TextLink, Typography, VerticalSpacer} from 'epic-ue-ui';
import Grid from '@material-ui/core/Grid';
import { ImageSearchItem } from '../types';

const Styled = styled.div`
    .image-results {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-left: -2.5%;
        &.single-image {
            justify-content: flex-start;
            margin-left: initial;
        }
    }
    .image-result {
        width: 45%;
        .paragraph .ue-rich-text {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }
`;

interface Props {
    imageItems?: ImageSearchItem[];
}

export const ImageSearchResults = ({imageItems = []}: Props): JSX.Element => {
    //max two results
    const results = imageItems.slice(0, 2);
    const singleImageClass = results.length === 1 ? 'single-image' : '';

    return (
        <Styled>
            <VerticalSpacer variant="heading" />
            <Grid item md={8}>
                <div className={`image-results ${singleImageClass}`}>
                    {results.map((result, i) => (
                        <div className="image-result" key={`${result.title}-${result.kind}-${i}`}>
                            <MouseHijack href={result.link}>
                                <Image src={result.image} fit="cover" optMaxWidth={600} />
                            </MouseHijack>
                            <VerticalSpacer variant="heading" />
                            <TextLink href={result.link}>
                                <Typography variant="h3" big={true}>
                                    {result.htmlTitle}
                                </Typography>
                            </TextLink>
                            <VerticalSpacer variant="paragraph" />
                            <Typography variant="p" >
                                {result.htmlSnippet}
                            </Typography>
                        </div>
                    ))}
                </div>
            </Grid>
            <VerticalSpacer variant="section" />
        </Styled>
    );
};

export default ImageSearchResults;
