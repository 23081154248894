import {breakpoints} from 'epic-ue-theme/dist/ueCommonTheme';

function bpMatch(d: string) {
    const b = Object.keys(breakpoints);
    for (let i = 0; i < b.length; i++) {
        if (d === b[i]) {
            return `${breakpoints[b[i]]}px`;
        }
    }
}

export const downWithOrientation = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    if (matchingKey !== undefined) {
        if (o === 'landscape' || o === 'portrait') {
            return `@media (max-width: ${matchingKey}) and (orientation: ${o})`;
        } else {
            throw new Error(`Orientation mismatch, did you misspell: ${o}?`);
        }
    }
};

export const upWithOrientation = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    if (matchingKey !== undefined) {
        if (o === 'landscape' || o === 'portrait') {
            return `@media (min-width: ${matchingKey}) and (orientation: ${o})`;
        } else {
            throw new Error(`Orientation mismatch, did you misspell: ${o}`);
        }
    }
};

export const downWithTaller = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    const matchingHeight = bpMatch(o);
    if (matchingKey !== undefined && matchingHeight !== undefined) {
        return `@media (max-width: ${matchingKey}) and (min-height: ${o})`;
    }
};

export const upWithTaller = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    const matchingHeight = bpMatch(o);
    if (matchingKey !== undefined && matchingHeight !== undefined) {
        return `@media (min-width: ${matchingKey}) and (min-height: ${o})`;
    }
};

export const downWithShorter = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    const matchingHeight = bpMatch(o);
    if (matchingKey !== undefined && matchingHeight !== undefined) {
        return `@media (max-width: ${matchingKey}) and (max-height: ${matchingHeight})`;
    }
};

export const upWithShorter = (d: string, o: string): string | undefined => {
    const matchingKey = bpMatch(d);
    const matchingHeight = bpMatch(o);
    if (matchingKey !== undefined && matchingHeight !== undefined) {
        return `@media (min-width: ${matchingKey}) and (max-height: ${matchingHeight})`;
    }
};

export const taller = (s: string): string => {
    return `@media (min-height: ${s})`;
};

export const shorter = (s: string): string => {
    return `@media (max-height: ${s})`;
};
