import React, {MouseEvent, useCallback} from 'react';
import {EndOfFeed} from './EndOfFeed';
import {FeedLoadingIndicator} from '../loading/FeedLoadingIndicator';
import parse from 'url-parse';
import root from 'window-or-global';
import styled from 'styled-components';
import {getPageFromParsedUrl} from '../../utils';
import {useNavigate} from 'react-router-dom';

const PaginationWrap = styled.div`
    .btn-wrapper {
        margin: 40px 0;
        padding: 40px 0 20px;
        text-align: center;
    }

    .ue-cta-button.button-lg {
        padding: 15px 50px;
    }

    .blog-load-indicator {
        padding: 0;
    }

    .search-wrapper {
        text-align: center;
        .filter-content-type-wrapper a.type {
            &:hover {
                transform: none;
            }
        }

        .ue-cta-button.button-lg {
            padding: 10px 20px;
            font-size: 0.9em;
            font-weight: 300;
            margin: 0 5px 20px;
            transition: opacity 300ms ease-out;
            opacity: 0.9;
            i {
                font-size: 0.8em;
                margin-right: 10px;
                margin-top: 2px;
                &:before {
                    color: ${(p) => p.theme.palette.text.primary};
                }
            }
            &:hover,
            &.selected {
                color: ${(p) => p.theme.palette.text.primary};
                opacity: 1;
                border: 1px;
                transform: none;
            }
        }
    }
    .loading-wrapper {
        position: relative;
        height: 400px;
        margin: 1em 0;
    }
    a.pagination-btn {
        padding: 6px 10px;
        text-align: center;
        border-radius: 3px;
        margin: 0.25em;
        transition: border-color 0.2s ease-out;
        border: 1px solid ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.primary};
        &:focus,
        &:active,
        &.active,
        &:hover {
            padding: 6px 10px;
            margin: 0.25em;
            border-color: #444444;
        }

        &:focus,
        &:active,
        &:hover {
            border-color: #555;
        }

        &.arrow {
            padding: 6px 8px;
            i:before {
                color: ${(p) => p.theme.palette.text.primary};
                font-size: 15px;
            }
        }
    }

    .bottom-spacer {
        margin-bottom: 50px;
    }
    .ellipsis {
        color: ${(p) => p.theme.palette.text.primary};
        padding: 6px 10px;
        margin: 0;
        opacity: 0.4;
    }
`;

const buttonsToShow = 5;

interface Props {
    hasMore?: boolean;
    currentFilters?: any;
    tags?: any;
    contentTypes?: any;
    loading?: boolean;
    loadingNext?: boolean;
    getNextPage?: () => void;
    getMessage?: () => string;
    paging?: any;
}

export const Pagination = ({
    hasMore,
    currentFilters = {},
    tags = {},
    contentTypes = {},
    loading,
    loadingNext,
    getNextPage,
    getMessage,
    paging = {}
}: Props): JSX.Element => {
    const navigate = useNavigate();
    /**
     * Initiate a call to get new data from express for the next page of data
     */
    const getPage = useCallback(
        (newUrl) =>
            (e: MouseEvent): void => {
                if (newUrl) {
                    e.preventDefault();
                    root.scrollTo({top: 0, left: 0});
                    const parsedNewUrl = parse(newUrl);
                    navigate({search: parsedNewUrl.query});
                }
            },
        [navigate]
    );

    const parsedUrl = parse(root.location.href, true);
    const currentPageNum = getPageFromParsedUrl(parsedUrl);
    const currentPageIndex = currentPageNum - 1;
    const {pageSize, length} = paging;

    const buttons: React.ReactNode[] = [];
    let showPagination = false;
    let lastButtonIndexRendered;
    if (pageSize && length) {
        const numButtons = Math.ceil(length / pageSize);
        if (numButtons > 1) {
            showPagination = true;
            for (let i = 0; i < numButtons; i++) {
                //skip if too far away from the current page
                if (currentPageIndex && currentPageIndex - buttonsToShow / 2 >= i) continue;
                //skip if already showing max number of buttons
                if (buttons.length >= buttonsToShow) break;
                parsedUrl.query.page = `${i + 1}`;
                const newUrl = parsedUrl.toString();

                buttons.push(
                    <a
                        key={`pagination-btn-${i}`}
                        className={`pagination-btn ${currentPageIndex === i ? 'active' : ''}`}
                        href={newUrl}
                        onClick={getPage(newUrl)}>
                        {i + 1}
                    </a>
                );
                lastButtonIndexRendered = i;
            }

            //when not at the end add the right arrows
            if (currentPageIndex < numButtons - 1) {
                const next = currentPageIndex + 1;
                const lastIndex = numButtons - 1;
                let arrowUrl;

                //if next button does same as last button skip it
                if (next !== lastIndex && lastButtonIndexRendered !== numButtons - 1) {
                    if (lastButtonIndexRendered !== numButtons - 2) {
                        buttons.push(
                            <span key="elip-last" className="ellipsis">
                                ...
                            </span>
                        );
                    }
                    parsedUrl.query.page = `${lastIndex + 1}`;
                    arrowUrl = parsedUrl.toString();
                    buttons.push(
                        <a
                            key="last"
                            className={`pagination-btn`}
                            href={arrowUrl}
                            onClick={getPage(arrowUrl)}>
                            {numButtons}
                        </a>
                    );
                }

                parsedUrl.query.page = `${next + 1}`;
                arrowUrl = parsedUrl.toString();
                buttons.push(
                    <a
                        key="next-arrow"
                        className={`pagination-btn arrow`}
                        href={arrowUrl}
                        onClick={getPage(arrowUrl)}>
                        <i className="icon-chevron-right" />
                    </a>
                );
            }

            //when not at the beginning add the left arrows
            if (currentPageIndex > 0) {
                let arrowUrl;
                if (currentPageIndex > 1 && buttonsToShow / 2 < currentPageIndex) {
                    parsedUrl.query.page = `1`;
                    arrowUrl = parsedUrl.toString();
                    buttons.unshift(
                        <span key="elip-first" className="ellipsis">
                            ...
                        </span>
                    );
                    buttons.unshift(
                        <a
                            key="first"
                            className={`pagination-btn`}
                            href={arrowUrl}
                            onClick={getPage(arrowUrl)}>
                            1
                        </a>
                    );
                }

                parsedUrl.query.page = `${currentPageIndex}`; //index is already 1 below the current pageNum
                arrowUrl = parsedUrl.toString();
                buttons.unshift(
                    <a
                        key="prev-arrow"
                        className={`pagination-btn arrow`}
                        href={arrowUrl}
                        onClick={getPage(arrowUrl)}>
                        <i className="icon-chevron-left" />
                    </a>
                );
            }
        }
    }

    return (
        <PaginationWrap>
            <div>
                {!hasMore && !loading && (
                    <EndOfFeed
                        currentFilters={currentFilters}
                        tags={tags}
                        contentTypes={contentTypes}
                    />
                )}
                {!loading && loadingNext && (
                    <div className="loading-wrapper">
                        <FeedLoadingIndicator />
                    </div>
                )}
                {!loading && showPagination ? (
                    <div className="btn-wrapper">{buttons}</div>
                ) : (
                    <div className="bottom-spacer" />
                )}
            </div>
        </PaginationWrap>
    );
};
