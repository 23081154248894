import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const cmsUrlPattern = '/maintenance';

const Maintenance = styled.div`
    background: url('https://cdn2.unrealengine.com/errorbg-1920x1080-744b3ec305b5.jpg') no-repeat
        ${(p) => p.theme.palette.background.default};
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    a {
        text-decoration: none;
    }

    h1,
    .desc {
        text-align: center;
    }

    .desc {
        font-size: 2em;
    }
`;

interface MaintenancePageData extends PageData {
    title: string;
    desc: string;
}

const MaintenancePage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<MaintenancePageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {title, desc} = pageData;

    return (
        <Page>
            <Maintenance>
                {getHelmetForMetaTags(pageData)}
                <div className="container">
                    <h1>{title}</h1>
                    <div className="desc" dangerouslySetInnerHTML={{__html: desc}} />
                </div>
            </Maintenance>
        </Page>
    );
};

MaintenancePage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default MaintenancePage;
