import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';
import {getCardImage} from '../../../../utils/enterpriseUtils';

export function getRow2Cards(props, state, items) {
    let types = [];
    let w66 = false;
    let shortRow = false;
    //if card has an image set to horizontal
    items.forEach((item) => {
        if (getCardImage(item)) {
            types.push('horizontal');
        } else {
            types.push('small-block');
        }
    });

    //should be a full row in this case
    if (types[0] === 'horizontal' && types[1] === 'horizontal') {
        w66 = true;
    } else if (types[0] === 'small-block' && types[1] === 'small-block') {
        shortRow = true;
    }

    if (props.firstIsFeatured) {
        w66 = false;
        types = ['featured', 'vertical'];
    }

    const cards = types.map((type, i) => {
        const item = items[i] || {};
        return (
            <Card
                key={`type-2-card-${item.title}`}
                {...props}
                {...state}
                highlight={item}
                type={type}
            />
        );
    });

    return {cards, shortRow, w66};
}

export default class RowType2 extends Row {
    static propTypes = {
        items: PropTypes.array,
        locale: PropTypes.string,
        firstIsFeatured: PropTypes.bool,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        const {cards, shortRow, w66} = getRow2Cards(this.props, this.state, this.props.items || []);

        return (
            <div
                className={`grid-news-row type-2 ${shortRow ? 'short-row' : ''}`}
                ref={(c) => {
                    this.gridRowRef = c;
                }}>
                {w66 ? (
                    <div className="grid-news-cell grid-news-cell-column w66">{cards}</div>
                ) : (
                    cards
                )}
            </div>
        );
    }
}
