import React, {useState, useEffect, useCallback} from 'react';
import {generateRoutePath} from '@epic-core/common';
import {FeedFilterWrapper} from './FeedFilter.styles';
import {FeedFilterSearch} from './FeedFilterSearch';
import {SimpleLink} from 'epic-ue-components';
import root from 'window-or-global';
import {FeedCurrentFilterProps, ContentType, TagStore} from '../../types';
import {useLocalization} from '@epic-mw/localization';
import {useNavigate} from 'react-router-dom';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

interface FilterProps {
    filters: FeedCurrentFilterProps;
    contentTypes: ContentType[];
    tags: TagStore;
    localizedTags: TagStore;
    curatedTags: string[];
    headerText?: string;
}

export interface TagSearchProps {
    tag: string;
    count: string;
}

export interface SelectedTags {
    [key: string]: boolean;
}

interface InitialStateProps {
    filters: FeedCurrentFilterProps;
    curatedTags: string[];
    contentTypes: ContentType[];
}

interface InitialStateRes {
    selectedType: string;
    activeTypeName: string;
    activeTypeColor: string;
    selectedIndustry: string;
    selectedTags: SelectedTags;
}

const getInitState = ({filters, curatedTags, contentTypes}: InitialStateProps): InitialStateRes => {
    const appliedFilters = Object.assign({}, filters.types || {});
    const appliedTags = Object.assign({}, filters.tags || {});

    const init: InitialStateRes = {
        selectedType: '',
        activeTypeName: '',
        activeTypeColor: '',
        selectedIndustry: '',
        selectedTags: {}
    };

    if (appliedFilters) {
        if (Object.keys(appliedFilters).length > 1) {
            init.selectedType = 'all';
            init.activeTypeName = '';
            init.activeTypeColor = '';
        } else if (Object.keys(appliedFilters).length === 1) {
            const firstKey = Object.keys(appliedFilters)[0];
            init.selectedType = firstKey;

            contentTypes.map((type) => {
                const slug = type.slug || '';
                if (slug === firstKey) {
                    const typeName = type.labelPlural || type.label || firstKey;
                    init.activeTypeColor = type.hexColor;
                    init.activeTypeName = typeName;
                }
            });
        } else {
            init.selectedType = 'all';
            init.activeTypeName = '';
            init.activeTypeColor = '';
        }
    }

    let matchedCuratedTag;
    if (appliedTags) {
        curatedTags.map((industry) => {
            const name = industry.toLowerCase();
            if (appliedTags[name] !== undefined && appliedTags[name]) {
                matchedCuratedTag = name;
            }
        });

        if (matchedCuratedTag) {
            delete appliedTags[matchedCuratedTag];
        }
        init.selectedIndustry = matchedCuratedTag;
        init.selectedTags = appliedTags;
    }

    return init;
};

export const FeedFilter = ({
    filters,
    contentTypes,
    tags,
    localizedTags,
    curatedTags,
    headerText
}: FilterProps): JSX.Element => {
    const init = getInitState({
        filters,
        contentTypes,
        curatedTags
    });

    const [selectedIndustry, setSelectedIndustry] = useState(init.selectedIndustry);
    const [selectedType, setSelectedType] = useState(init.selectedType);
    const [selectedTags, setSelectedTags] = useState(init.selectedTags);
    const [showTypeNav, setShowTypeNav] = useState(false);
    const [showIndustryNav, setShowIndustryNav] = useState(false);
    const [activeTypeColor, setActiveTypeColor] = useState(init.activeTypeColor);
    const [activeTypeName, setActiveTypeName] = useState(init.activeTypeName);
    const getMessage = useLocalization();
    const navigate = useNavigate();
    const [typeEl, setTypeEl] = useState<Node | null>(null);
    const [tagEl, setTagEl] = useState<Node | null>(null);
    const defaultColor = '#202337';

    const typeRef = useCallback((node) => {
        if (node !== null) {
            setTypeEl(node);
        }
    }, []);

    const tagRef = useCallback((node) => {
        if (node !== null) {
            setTagEl(node);
        }
    }, []);

    const [clientRendered, setClientRendered] = useState(false);

    useEffect(() => {
        if (!clientRendered) {
            setClientRendered(true);
        }
    }, [clientRendered, setClientRendered]);

    //close the dropdowns on escape button press
    useEffect(() => {
        const escapeKeyHandler = (e) => {
            const key = e.key;
            if (!key) return;
            if (key.toLowerCase() === 'escape' || key.toLowerCase() === 'esc') {
                setShowIndustryNav(false);
                setShowTypeNav(false);
            }
        };
        root.document.addEventListener('keyup', escapeKeyHandler);

        return () => {
            root.document.removeEventListener('keyup', escapeKeyHandler);
        };
    }, [setShowIndustryNav, setShowTypeNav]);

    //close the dropdowns on click outside
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (typeEl && !typeEl.contains(e.target)) {
                setShowTypeNav(false);
            }

            if (tagEl && !tagEl.contains(e.target)) {
                setShowIndustryNav(false);
            }
        };

        if (showIndustryNav || showTypeNav) {
            root.document.addEventListener('mousedown', handleOutsideClick, false);
        }

        return () => {
            root.document.removeEventListener('mousedown', handleOutsideClick, false);
        };
    }, [showIndustryNav, showTypeNav, setShowIndustryNav, setShowTypeNav, typeEl, tagEl]);

    useEffect(() => {
        const appliedFilters = Object.assign({}, filters.types || {});
        const appliedTags = Object.assign({}, filters.tags || {});

        if (appliedFilters) {
            if (Object.keys(appliedFilters).length > 1) {
                setSelectedType('all');
                setActiveTypeName('');
                setActiveTypeColor('');
            } else if (Object.keys(appliedFilters).length === 1) {
                const firstKey = Object.keys(appliedFilters)[0];
                setSelectedType(firstKey);

                contentTypes.map((type) => {
                    const slug = type.slug || '';
                    if (slug === firstKey) {
                        const typeName = type.labelPlural || type.label || firstKey;
                        setActiveTypeColor(type.hexColor);
                        setActiveTypeName(typeName);
                    }
                });
            } else {
                setSelectedType('all');
                setActiveTypeName('');
                setActiveTypeColor('');
            }
        }

        let matchedCuratedTag;
        if (appliedTags) {
            curatedTags.map((industry) => {
                const name = industry.toLowerCase();
                if (appliedTags[name] !== undefined && appliedTags[name]) {
                    matchedCuratedTag = name;
                }
            });

            if (matchedCuratedTag) {
                delete appliedTags[matchedCuratedTag];
            }
            setSelectedIndustry(matchedCuratedTag);
            setSelectedTags(appliedTags);
        }
    }, [
        setSelectedType,
        setActiveTypeColor,
        setActiveTypeName,
        setSelectedIndustry,
        setSelectedTags,
        filters,
        contentTypes,
        curatedTags,
        filters.types,
        filters.tags
    ]);

    const constructTagUri = useCallback(
        (industry?: string) => {
            const tagArray: string[] = [];

            if (selectedIndustry) {
                tagArray.push(selectedIndustry);
            } else if (industry) {
                tagArray.push(industry);
            }

            for (const key in selectedTags) {
                if (
                    Object.hasOwnProperty.call(selectedTags, key) &&
                    selectedTags[key] &&
                    tagArray.indexOf(key) === -1
                ) {
                    tagArray.push(encodeURIComponent(key));
                    if (curatedTags[key]) {
                        setSelectedIndustry(curatedTags[key]);
                    }
                }
            }
            return tagArray.sort().join('+');
        },
        [selectedIndustry, setSelectedIndustry, selectedTags]
    );

    const constructUrl = useCallback(
        (tags) => {
            let url = `/feed/all/${tags}`;
            if (selectedType) {
                url = `/feed/${selectedType}/${tags}`;
            }
            navigate(generateRoutePath(url));
        },
        [navigate, selectedType]
    );

    const resetIndustryUri = useCallback(
        (e) => {
            const tagArray: string[] = [];
            if (selectedIndustry) {
                delete selectedTags[selectedIndustry];
                setSelectedIndustry('');
                setSelectedTags(selectedTags);
            }
            for (const key in selectedTags) {
                if (
                    Object.hasOwnProperty.call(selectedTags, key) &&
                    selectedTags[key] &&
                    tagArray.indexOf(key) === -1
                ) {
                    tagArray.push(encodeURIComponent(key));
                }
            }
            const tags = tagArray.sort().join('+');
            constructUrl(tags);
        },
        [selectedIndustry, setSelectedIndustry, setSelectedTags]
    );

    const updatePath = useCallback(() => {
        const tags = constructTagUri();
        constructUrl(tags);
    }, [constructTagUri, constructUrl]);

    const toggleTypeNav = useCallback(
        (e) => {
            if (e) {
                const val = !showTypeNav;
                e.preventDefault();
                setShowTypeNav(val);
                setShowIndustryNav(false);
                emitEvent({
                    eventAction: 'client.feed.filter.toggleMenu',
                    eventLabel: 'category',
                    eventValue: val ? 'open' : 'close'
                });
            }
        },
        [showTypeNav, setShowTypeNav, showTypeNav, setShowTypeNav]
    );

    const closeNavs = useCallback(
        (e) => {
            setShowTypeNav(false);
            setShowIndustryNav(false);
        },
        [setShowTypeNav, setShowTypeNav]
    );

    const toggleIndustryNav = useCallback(
        (e) => {
            if (e) {
                const val = !showIndustryNav;
                e.preventDefault();
                setShowIndustryNav(val);
                setShowTypeNav(false);
                emitEvent({
                    eventAction: 'client.feed.filter.toggleMenu',
                    eventLabel: 'tag',
                    eventValue: val ? 'open' : 'close'
                });
            }
        },
        [showIndustryNav, setShowIndustryNav, showTypeNav, setShowTypeNav]
    );

    const getTagMatch = (lowercaseTag: string): string => {
        let tagMatch = '';
        for (const key in tags) {
            if (key && Object.hasOwnProperty.call(tags, key)) {
                if (key.toLowerCase() === lowercaseTag) {
                    tagMatch = key;
                    break;
                }
            }
        }
        return (localizedTags[tagMatch] || tagMatch) as string;
    };

    const resetTag = useCallback(
        (tag) => (e) => {
            const tagName = tag.toLowerCase();
            emitEvent({
                eventAction: 'client.feed.filter.deselectItem',
                eventLabel: 'tag',
                eventValue: tagName
            });
            for (const key in selectedTags) {
                if (Object.hasOwnProperty.call(selectedTags, key) && selectedTags[key]) {
                    if (key.toLowerCase() === tagName) {
                        delete selectedTags[tagName];
                    }
                }
            }
            setSelectedTags(selectedTags);
            updatePath();
        },
        [selectedTags, setSelectedTags, updatePath]
    );

    const resetType = useCallback(() => {
        emitEvent({
            eventAction: 'client.feed.filter.deselectItem',
            eventLabel: 'category',
            eventValue: selectedType
        });
        setSelectedType('all');
        setActiveTypeName('');
        setActiveTypeColor('');
        const tags = constructTagUri();
        navigate(generateRoutePath(`/feed/all/${tags}`));
    }, [setSelectedType, setActiveTypeName, setActiveTypeColor, updatePath, constructTagUri]);

    const btnStyles = {
        backgroundColor: activeTypeColor || defaultColor
    };

    let curatedTagElem;

    if (selectedIndustry) {
        curatedTagElem = (
            <li
                role="menuitem"
                className="filter-tag-item-wrapper"
                key={selectedIndustry}
                onClick={resetIndustryUri}>
                <span className="selected" role="menuitem" tabIndex={0}>
                    {selectedIndustry}
                </span>
            </li>
        );
    } else {
        curatedTagElem = (
            <li className="curated-list" ref={tagRef}>
                <button
                    className={`content-type-btn carot ${showIndustryNav ? 'open' : ''}`}
                    onClick={toggleIndustryNav}>
                    {selectedIndustry || getMessage('epic.feed.curated.default')}
                </button>
                <ul className={`${showIndustryNav ? 'open' : ''}`}>
                    {curatedTags.map((industry) => {
                        const name = industry.toLowerCase();
                        let type = 'all';
                        if (selectedType) {
                            type = selectedType;
                        }
                        const tags = constructTagUri(name);
                        let tagsUri = name;
                        if (tags) {
                            tagsUri = tags;
                        }
                        return (
                            <li className="curated-tag-item" key={industry}>
                                <SimpleLink
                                    className={`${selectedIndustry === name ? 'selected' : ''}`}
                                    href={`/feed/${type}/${tagsUri}`}
                                    rel="nofollow"
                                    id={`curated-tag-item-${industry}`}>
                                    {industry}
                                </SimpleLink>
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    return (
        <FeedFilterWrapper
            className={`${!clientRendered ? 'no-transition' : ''}${
                !headerText ? ' no-header' : ''
            }`}>
            <ul className="types" ref={typeRef}>
                <li>
                    <button
                        style={btnStyles}
                        className={`carot ${showTypeNav ? 'open' : ''}`}
                        onClick={toggleTypeNav}>
                        {activeTypeName || getMessage('epic.feed.categories.default')}
                    </button>
                    {activeTypeName && !showTypeNav ? (
                        <button style={btnStyles} className="close-type" onClick={resetType}>
                            <i className="icon-cross" />
                        </button>
                    ) : (
                        ''
                    )}
                    <ul className={`${showTypeNav ? 'open' : ''}`}>
                        {contentTypes.map((type) => {
                            if (type.disableFilter) return null;
                            const typeClass = type.label || '';
                            const styles = {
                                backgroundColor: type.hexColor || defaultColor
                            };

                            const tags = constructTagUri();
                            let curatedTag = '';
                            if (tags) {
                                curatedTag = `/${tags}`;
                            }

                            return (
                                <li
                                    className="filter-content-type-wrapper"
                                    key={type.slug}
                                    role="menuitem"
                                    tabIndex={0}>
                                    <SimpleLink
                                        className={`feed-content-type ${typeClass.toLowerCase()}`}
                                        href={`/feed/${type.slug}${curatedTag}`}
                                        style={styles}
                                        onClick={closeNavs}
                                        id={`feed-content-type-${typeClass.toLowerCase()}`}>
                                        {type.labelPlural || type.label || ''}
                                    </SimpleLink>
                                </li>
                            );
                        })}
                    </ul>
                </li>
            </ul>

            <ul className="tags">{curatedTagElem}</ul>

            <ul className="selected-tags">
                {Object.keys(selectedTags).map((tag) => {
                    if (!selectedTags[tag]) return null;
                    let decoded;
                    try {
                        decoded = decodeURIComponent(tag);
                    } catch (e) {
                        decoded = tag;
                    }
                    const tagMatch = getTagMatch(decoded);

                    if (tagMatch) {
                        return (
                            <li
                                role="menuitem"
                                className="filter-tag-item-wrapper"
                                onClick={resetTag(tagMatch)}
                                key={tagMatch}>
                                <span className="selected" role="menuitem" tabIndex={0}>
                                    {tagMatch && tagMatch.length === 2
                                        ? tagMatch.toUpperCase()
                                        : tagMatch}
                                </span>
                            </li>
                        );
                    }
                })}
            </ul>

            <div className="filter-search-box">
                <FeedFilterSearch
                    localizedTags={localizedTags}
                    updatePath={updatePath}
                    tags={tags}
                    selectedIndustry={selectedIndustry}
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                />
            </div>
        </FeedFilterWrapper>
    );
};
