import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Typography, RemoveIcon} from 'epic-ue-ui';
import {AccentColorOptions, ueGradientColors} from 'epic-ue-theme/dist';
import {generateRoutePath} from '@epic-core/common';
import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import {useHeaderNavBanner} from '../shared';
import {useLocation} from 'react-router-dom';

const homepagePattern = RegExp('/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})/?$');

const Styled = styled.div<{$to: string; $from: string; bannerHidden?: boolean}>`
    display: ${(p) => (p.bannerHidden ? 'none' : 'block')};

    cursor: pointer;

    position: relative;
    top: 0;

    height: 30px;
    width: 100%;

    text-align: center;

    color: ${(p) => p.theme.palette.common.black};

    background: radial-gradient(
        100% 185.14% at 100% 100%,
        ${(p) => p.$to} 0%,
        ${(p) => p.$from} 100%
    );

    z-index: 9;

    .banner-text {
        display: inline-block;
        vertical-align: sub;
        color: ${(p) => p.theme.palette.common.black};

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
        padding-left: 15px;

        max-width: calc(100% - 32px);
    }

    .close-banner {
        float: right;
        margin-right: 10px;
        cursor: pointer;
        margin-top: 2px;
        align-self: center;
    }
`;

export interface BannerCmsProps {
    accentColor?: AccentColorOptions;
    description?: string;
    href?: string;
    hidden?: boolean;
}

interface Props {
    navBannerEnabled: boolean;
    setNavBannerEnabled: (b: boolean) => void;
}

export const NavBannerBar = ({navBannerEnabled, setNavBannerEnabled}: Props): JSX.Element => {
    const location = useLocation();
    const pathname = location.pathname;

    const banner = useHeaderNavBanner();
    const accentColor = banner.accentColor || 'sky';
    const hideBanner = useCallback(() => {
        setNavBannerEnabled(false);
    }, [setNavBannerEnabled]);

    // Show top banner only on homepage when the cms props are valid
    useEffect(() => {
        //if cms banner isn't set then we dont want to enable
        if (!banner || !banner.description || !banner.href || banner.hidden) return;

        setNavBannerEnabled(homepagePattern.test(pathname));
    }, [banner, setNavBannerEnabled, homepagePattern, pathname]);

    const {to, from} = ueGradientColors[accentColor];

    const handleKeyPress = useCallback(
        (event) => {
            if (event.key === 'Enter') {
                hideBanner();
            }
        },
        [hideBanner]
    );

    return (
        <Styled $to={to} $from={from} bannerHidden={banner.hidden || !navBannerEnabled}>
            <Grid container direction="row">
                <Grid item xs={11}>
                    {banner.href?.startsWith('https://') ? (
                        <a href={banner.href}>
                            <Typography className="banner-text" variant="h5">
                                {banner.description}
                            </Typography>
                        </a>
                    ) : (
                        <Link to={generateRoutePath(banner.href || '')} title={banner.description}>
                            <Typography className="banner-text" variant="h5">
                                {banner.description}
                            </Typography>
                        </Link>
                    )}
                </Grid>
                <Grid className="close-banner-grid" item xs={1}>
                    <div
                        role="button"
                        tabIndex={0}
                        className="close-banner"
                        onKeyPress={handleKeyPress}
                        onClick={hideBanner}>
                        <RemoveIcon />
                    </div>
                </Grid>
            </Grid>
        </Styled>
    );
};
