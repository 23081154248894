import React from 'react';
import root from 'window-or-global';
import classNames from 'classnames';
import QRCode from 'qrcode.react';
import {ShareIconsWrapper} from './ShareIcons.styles';

/**
 * Social Shares positioned fixed in the blogDetail Page
 * @implements {Message}
 * @implements {QRCode}
 * @reactProps {object} blog
 * @reactProps {object} config
 * @reactProps {bool} topAlign
 * @reactProps {bool} fixSocialPos
 * @reactProps {bool} bottomAlign
 * @reactProps {bool} disableShareIcons
 */
interface ShareIconsProps {
    config: {
        shareConfigs: {
            type: string;
        }[];
    };
    topAlign: boolean;
    bottomAlign: boolean;
    fixSocialPos: boolean;
    disableShareIcons: boolean;
}

export default class ShareIcons extends React.Component<ShareIconsProps> {
    state = {
        shareFacebook: this.share.bind(this, 'facebook'),
        shareTwitter: this.share.bind(this, 'twitter'),
        shareGoogle: this.share.bind(this, 'google'),
        shareVK: this.share.bind(this, 'vk'),
        shareTumblr: this.share.bind(this, 'tumblr'),
        shareReddit: this.share.bind(this, 'reddit'),
        shareLinkedin: this.share.bind(this, 'linkedin'),
        shareWeibo: this.share.bind(this, 'weibo'),
        toggleQRCode: this.toggleQRCode.bind(this),
        showQRCode: false
    };

    /**
     * Generate the correct social share window when click share icons
     * @param  {String} social Type of social share, options include ['twitter', 'facebook', 'google', 'vk', linkedin], 'tumblr', 'reddit']
     */
    share(social: string): void {
        let twitterTitle = '';
        let twitterDesc = '';
        let twitterTags = '';

        const metas = root.document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === 'twitter:title') {
                twitterTitle = metas[i].getAttribute('content');
            }
            if (metas[i].getAttribute('name') === 'twitter:description') {
                twitterDesc = metas[i].getAttribute('content');
            }
            if (metas[i].getAttribute('name') === 'twitter:hashTags') {
                twitterTags = metas[i].getAttribute('content');
            }
        }

        let shareUrl = root.location.href;
        let desc = '';
        let blogTitle = '';
        if (twitterTags) {
            const hashTags = twitterTags;
            desc += `${hashTags} `;
        }
        if (twitterTitle.length > 0) {
            blogTitle = twitterTitle;
            desc += blogTitle;
        }
        if (twitterDesc.length > 0) {
            desc += `\n${twitterDesc}\n`;
        }

        switch (social) {
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    desc
                )}&url=${encodeURIComponent(shareUrl)}`;
                break;
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    shareUrl
                )}`;
                break;
            case 'google':
                shareUrl = `https://plus.google.com/share?url=${encodeURIComponent(shareUrl)}`;
                break;
            case 'vk':
                shareUrl = `http://vk.com/share.php?url=${encodeURIComponent(shareUrl)}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                    shareUrl
                )}`;
                break;
            case 'tumblr':
                shareUrl = `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
                    shareUrl
                )}`;
                break;
            case 'reddit':
                shareUrl = `https://reddit.com/submit?url=${encodeURIComponent(shareUrl)}`;
                break;
            case 'weibo':
                shareUrl = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(
                    shareUrl
                )}&title=${blogTitle}`;
                break;
            default:
                break;
        }
        root.open(shareUrl, '_blank', 'toolbar=no, resizeable=yes, width=600, height=450');
    }

    /**
     * onClick to toggleQRCode
     */
    toggleQRCode(): void {
        const showQRCode = !this.state.showQRCode;
        this.setState({showQRCode});
    }

    render(): JSX.Element {
        const {
            shareFacebook,
            shareTwitter,
            shareGoogle,
            shareVK,
            shareLinkedin,
            shareReddit,
            shareTumblr,
            shareWeibo,
            toggleQRCode
        } = this.state;
        const {config} = this.props;

        const shareContainerClass = classNames('blog-social', {
            top: this.props.topAlign,
            bottom: this.props.bottomAlign,
            fixed: this.props.fixSocialPos
        });

        /*
         * CMS - Epic Common Config/ Common Config Content Type
         * Enable share that has been specifed in CMS
         * */
        const shareConfigs = config.shareConfigs || [];
        let shareButtons: (JSX.Element | null)[] = [];
        if (!this.props.disableShareIcons) {
            shareButtons = shareConfigs.map((item) => {
                let temp: JSX.Element | null = null;
                const type = item.type;
                switch (type) {
                    case 'twitter':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-twitter twitter"
                                onClick={shareTwitter}
                                key={type}
                            />
                        );
                        break;
                    case 'facebook':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-facebook facebook"
                                onClick={shareFacebook}
                                key={type}
                            />
                        );
                        break;
                    case 'google':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-google-plus google-plus"
                                onClick={shareGoogle}
                                key={type}
                            />
                        );
                        break;
                    case 'vk':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-vk vk"
                                onClick={shareVK}
                                key={type}
                            />
                        );
                        break;
                    case 'linkedin':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-linkedin linkedin"
                                onClick={shareLinkedin}
                                key={type}
                            />
                        );
                        break;
                    case 'tumblr':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-tumblr tumblr"
                                onClick={shareTumblr}
                                key={type}
                            />
                        );
                        break;
                    case 'reddit':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-reddit reddit"
                                onClick={shareReddit}
                                key={type}
                            />
                        );
                        break;
                    case 'weibo':
                        temp = (
                            <i
                                role="presentation"
                                className="epic-blog-icon-weibo weibo"
                                onClick={shareWeibo}
                                key={type}
                            />
                        );
                        break;
                    case 'wechat':
                        temp = (
                            <div
                                role="presentation"
                                className="wechat-share"
                                onClick={toggleQRCode}
                                key={type}>
                                <i className="epic-blog-icon-wechat wechat" />
                                <div
                                    className={classNames('qrcode-block', {
                                        show: this.state.showQRCode
                                    })}>
                                    <QRCode value={root.location.href} />
                                </div>
                            </div>
                        );
                        break;
                    default:
                        break;
                }
                return temp;
            });
        }
        return (
            <>
                {!this.props.disableShareIcons && (
                    <ShareIconsWrapper>
                        <div className={shareContainerClass}>{shareButtons}</div>
                    </ShareIconsWrapper>
                )}
            </>
        );
    }
}
