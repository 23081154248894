import React from 'react';
import PropTypes from 'prop-types';
import Message from '../message/Message';

export default class DatasmithItem extends React.PureComponent {
    static propTypes = {
        label: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        extensions: PropTypes.string
    };

    render() {
        const {label, title, extensions, description} = this.props;

        let extensionMessage;
        if (extensions) {
            extensionMessage = (
                <h4>
                    <Message code="epic.studio.extension.label" />: <span>{extensions}</span>
                </h4>
            );
        }
        return (
            <div className="ribbonItem">
                {label}
                <div className="desc">
                    <h3>{title}</h3>
                    {extensionMessage}
                    <p>{description}</p>
                </div>
            </div>
        );
    }
}
