import styled from 'styled-components';

export const HeaderWrapper = styled.div<{customHeight: string}>`
    &.header {
        min-height: ${(p) => (p.customHeight ? p.customHeight : '90vh')};

        .bgMedia-wrap {
            background-color: ${(p) => p.theme.palette.common.black};
        }
    }
    .header-bottom-content .copy {
        color: ${(p) => p.theme.palette.common.white};
    }
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &.header-slim {
        .copy {
            max-width: 1024px;
        }
    }
    &.header-video {
        height: auto;
        min-height: auto;
    }

    &.header-centered,
    &.center {
        text-align: center;
        .button-wrapper,
        .container,
        .container-fluid {
            text-align: center;
        }
        .copy {
            margin: 50px auto 0;
        }
        .button-wrapper,
        .button-container {
            margin: 2em auto 0;
        }
    }

    &.header-default,
    &.header-centered {
        .icon-play {
            font-size: 4em;
            display: block;
            z-index: 88;
            cursor: pointer;
            color: ${(p) => p.theme.palette.common.white};

            ${(p) => p.theme.breakpoints.down('lg')} {
                font-size: 5em;
            }
        }
    }

    &.header-default,
    &.header-centered {
        &.vcenter {
            .headerContentWrapper,
            .title-wrap {
                min-height: ${(p) => (p.customHeight ? p.customHeight : '90vh')};
                margin: 0;
                padding: 0;
            }

            h1,
            h2 {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                margin: 0;
                padding: 0;
            }

            h2 {
                top: 70%;
                transform: translateY(-70%);
            }
        }
    }

    &.header-centered.vcenter {
        .title-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        h1,
        h2 {
            position: relative;
            transform: none;
        }
    }

    &.header-default.vcenter&.header-default.vcenter {
        .title-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        h1,
        h2 {
            position: relative;
            transform: none;
        }

        h1 {
            margin: 0;
        }
    }

    .copy {
        transition: opacity ease-in-out 0.5s, transform ease-in-out 0.4s;
        opacity: 0;
        transform: translate3d(0, 50%, 1);
    }

    .tag-item {
        transform: translate3d(-70%, 0, 1);
        transition: opacity ease-in-out 0.5s, transform ease-in-out 0.4s;
        opacity: 0;
    }

    &.inView {
        .tag-item,
        .copy {
            opacity: 1;
            transform: translate3d(0, 0, 1);
        }
    }
`;

export const ContentWrapper = styled.div`
    ${(p) => p.theme.breakpoints.up('lg')} {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .container,
    .row {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const BottomContent = styled.div`
    padding: 20px 0 25px;
    margin-bottom: 40px;
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 20px 0;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
        margin-top: 40px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    z-index: 2;
    .copy {
        position: relative;
        padding: 0;
        color: ${(p) => p.theme.palette.text.primary};
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 0 10px;
        }
    }

    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 20px 0 10px;
        text-align: center;
    }
    .button-wrapper {
        margin-top: 2em;
    }
`;

export const ButtonContainer = styled.div`
    width: 600px;
    max-width: 100%;
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
    }
    a,
    button {
        display: inline-block;
    }
    .btn-showcase {
        margin-left: 35px !important;
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
    height: auto;
    min-height: 100px;
    flex: 1;
    padding-top: 40px;

    &.title-wrap h1,
    &.title-wrap h2 {
        color: ${(p) => p.theme.palette.common.white};
    }

    @media only screen and (min-width: 1200px) and (max-width: 1300px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        padding-top: 20px;
        min-height: 300px;
    }
    h1 {
        margin-top: 0;
        margin-bottom: -25px;
        ${(p) => p.theme.breakpoints.down('md')} {
            text-align: center;
            margin-bottom: 10px;
        }
    }
    h2 {
        font-family: ${(p) => p.theme.ue.fonts.brutal.light};
        margin-top: 20px;
        text-transform: uppercase;
        font-size: 2.8em;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            margin-top: 0;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 2em;
            text-align: center;
            margin-top: 40px;
        }
    }
    img {
        margin-bottom: 60px;
    }

    .copy {
        max-width: 800px;
        padding: 20px;
        margin-top: 40px;
    }

    &.auto {
        min-height: auto;
        ${(p) => p.theme.breakpoints.down('sm')} {
            min-height: auto;
        }
    }
`;

export const Credits = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 0.7em;
    color: white;
    pointer-events: none;

    ${(p) => p.theme.breakpoints.down('md')} {
        display: none;
    }
`;

export const TitleImgWrap = styled.div`
    div {
        margin: 6em 4em 4em;
        height: 400px;
        position: relative;
    }
`;
