import React from 'react';
import {getPath} from '@epic-core/common';
import {Redirect} from './Redirect';
import {ServerRoute} from './routes';

export const getReleaseNotesRedirects = (): ServerRoute[] => {
    const path = getPath();

    return [
        {
            path: `${path}:locale/release-notes/unreal-engine-4-26-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_26/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-25-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_25/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-24-is-here`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_24/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-23-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_23/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-22-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_22/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-21-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_21/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-20-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_20/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/unreal-engine-4-19-released`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_19/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/418-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_18/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/417-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_17/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/416-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_16/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/415-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/4_15/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/414-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2016/4_14/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/413-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2016/4_13/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/412-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2016/4_12/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/411-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2016/4_11/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/410-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2015/4_10/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/49-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2015/4_9/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/48-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2015/4_8/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/47-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2015/4_7/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/46-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2014/4_6/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/45-release-notes-overview`,
            element: (
                <Redirect
                    url="https://docs.unrealengine.com/4.27/:locale/WhatsNew/Builds/ReleaseNotes/2014/4_5/"
                    status={301}
                />
            )
        },
        {
            path: `${path}:locale/release-notes/*`,
            element: <Redirect to="/updates" status={301} />
        },
        {
            path: `${path}:locale/release-notes`,
            element: <Redirect to="/updates" status={301} />
        }
    ];
};
