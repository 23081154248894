import styled from 'styled-components';
import {H4} from 'epic-ue-components';

const RecordingCard = styled.div`
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0 0 10px 0;
    padding: 0 5px;
    cursor: pointer;

    #recording-btn {
        width: 100%;
        margin: 0px;
        max-width: unset;
    }

    &.featured {
        height: 400px;

        ${(p) => p.theme.breakpoints.down('md')} {
            height: 300px;
        }
        .card-wrapper {
            height: 400px;
            ${(p) => p.theme.breakpoints.down('md')} {
                height: 300px;
            }
            .image {
                height: 320px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: 230px;
                }
                .btn-primary {
                    margin-top: 150px;
                    transition: opacity 0.3s ease;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        margin-top: 80px;
                    }
                }
            }
            .title {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    font-size: 1.2em;
                }
            }
        }
    }

    &:hover {
        .card-wrapper {
            .image {
                .btn-primary {
                    opacity: 1;
                }
            }
            .description {
                display: block;
                opacity: 1;
            }
            .background-overlay {
                opacity: 1;
                transition: opacity 0.35s ease;
            }
        }
    }

    &.active {
        #recording-btn {
            opacity: 1;
            background-color: #7ea336;
            border-color: #7ea336;
            &:focus {
                outline: #7ea336;
            }
        }
    }

    .card-wrapper {
        background: ${(p) => p.theme.palette.background.paper};

        .image {
            height: 0;
            padding-top: 56.25%;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(${(p) => p.image});
            background-position: 50% 50%;
            position: relative;
            overflow: hidden;

            .image-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .btn-primary {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                opacity: 0;
                display: block;
                transition: opacity 0.3s ease-in-out;
                margin: 50px auto 0;
                max-width: 200px;
                z-index: 3;
            }
        }

        .title {
            height: 80px;
            padding: 12px 24px 12px 12px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding-left: 24px;
            }
            font-size: 13px;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            width: 100%;
        }
        .description-wrapper {
            position: relative;
            transition: opacity 0.3s ease;
        }

        .background-overlay {
            opacity: 0;
            background: rgb(32 35 55 / 95%);
            position: absolute;
            top: 0;
            z-index: 2;
            width: calc(100% - 10px);
            height: calc(100% - 75px);
            transition: opacity 0.05s ease;
        }
        .description {
            position: absolute;
            top: 0;
            margin: 0;
            display: none;
            opacity: 0;
            padding: 16px;
            font-size: 13px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding-left: 24px;
            }
            line-height: 1.8em;
            font-family: ${(p) => p.theme.ue.fonts.opensans};
            width: 100%;
            z-index: 3;
            height: 215px;
            overflow: clip;
            transition: opacity 0.3s ease;
        }
    }
`;

const RecordingsSection = styled.div`
    position: relative;
    overflow: hidden;
    padding: 50px 0;
    background: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};

    > .container > .row > div {
        ${(p) => p.theme.breakpoints.up('md')} {
            padding: 0;
        }
    }
    .threshold {
        width: 100%;
        height: 3px;
    }

    h2 {
        font-family: Brutal_Medium;
        font-size: 3em;
        text-transform: uppercase;
        text-align: center;
    }
`;

const FeaturedRecordings = styled.div`
    &:empty {
        display: none;
    }
`;

const SeeAllButton = styled.a`
    background: none;
    border: 1px solid ${(p) => p.theme.ue.colors.primary};
    color: ${(p) => p.theme.ue.colors.primary};
    padding: 15px 35px;
    display: inline-block;
    max-width: 350px;
    margin: 1em 0 0 0;
    text-align: center;
    transition: 200ms background ease-in-out;
    text-transform: uppercase;
    cursor: pointer;

    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 10px 15px;
        max-width: 280px;
        font-size: 0.875em;
    }
    &:first-child {
        margin-right: 10px;
        ${(p) => p.theme.breakpoints.down('xs')} {
            margin-right: 0;
        }
    }
    &:focus {
        color: white;
    }
    &:active,
    &:hover {
        color: #fff;
        background: ${(p) => p.theme.ue.colors.primary};
    }
    position: absolute;
    right: -10px;
    top: -15px;
    padding: 10px 20px;
`;

const RecordingGroupHeading = styled.div`
    margin-top: 10px;
    margin-bottom: 40px;
    height: 50px;
    vertical-align: middle;

    ${(p) => p.theme.breakpoints.down('sm')} {
        height: auto;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: ${(p) => p.theme.palette.misc.outline};
        top: 24px;
        left: 0;
        z-index: 1;
        opacity: 0.25;

        ${(p) => p.theme.breakpoints.down('sm')} {
            display: none;
        }
    }

    ${H4} {
        height: 50px;
        line-height: 25px;
        vertical-align: middle;
        font-size: 1.3em;
        padding: 0 20px;
        z-index: 2;
        position: absolute;
        left: 0;
        margin-top: 40px;

        ${(p) => p.theme.breakpoints.down('sm')} {
            position: relative;
            left: auto;
            right: auto;
            padding: 0;
            text-align: center;
        }
    }

    .show-button-wrapper {
        height: 50px;
        padding: 0 0 0 20px;
        background: ${(p) => p.theme.palette.background.default};
        z-index: 2;
        width: 150px;
        position: absolute;
        right: 0;

        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0;
            position: relative;
            background: transparent;
            text-align: center;
            height: auto;

            .see-all-btn {
                display: block;
                width: 100%;
                position: relative;
                margin: 0 auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
`;

const RecordingsRow = styled.div`
    position: relative;
    margin-bottom: 20px;
    > div {
        ${(p) => p.theme.breakpoints.up('md')} {
            padding: 0;
        }
    }
    .slick-arrow {
        cursor: pointer;
        top: 40%;
        &:before,
        &:after {
            text-shadow: none;
            color: #222;
        }
        &.slick-prev {
            left: -70px;
            ${(p) => p.theme.breakpoints.down('md')} {
                left: -40px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                left: -20px;
            }
        }
        &.slick-next {
            right: -70px;
            ${(p) => p.theme.breakpoints.down('md')} {
                right: -40px;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                right: -20px;
            }
        }
        &.disabled {
            display: none;
        }
    }
`;

const RecordingItemWrapper = styled.div`
    padding: 0;
    margin-bottom: 10px;
`;

export {
    RecordingsRow,
    RecordingGroupHeading,
    SeeAllButton,
    FeaturedRecordings,
    RecordingsSection,
    RecordingCard,
    RecordingItemWrapper
};
