import React, {useState, useCallback, useEffect} from 'react';
import {getLocale} from '@epic-core/common';
import debounce from 'lodash.debounce';
import {SelectedTags, TagSearchProps} from './FeedFilter';
import {FilterForm} from './FeedFilterSearch.styles';
import root from 'window-or-global';
import {useLocalization} from '@epic-mw/localization';
import {TagStore} from '../../types';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

interface Properties {
    updatePath: () => void;
    localizedTags: TagStore;
    tags: TagStore;
    selectedIndustry?: string;
    selectedTags?: SelectedTags;
    setSelectedTags: any;
}

export const FeedFilterSearch = ({
    updatePath,
    localizedTags,
    tags,
    selectedIndustry,
    selectedTags = {},
    setSelectedTags
}: Properties): JSX.Element => {
    const [hasSearched, setHasSearched] = useState(false);
    const [discoveredTags, setDiscoveredTags] = useState<TagSearchProps[]>([]);
    const getMessage = useLocalization();
    const [el, setEl] = useState<Node | null>(null);

    const tagSearchRef = useCallback((node) => {
        if (node !== null) {
            setEl(node);
        }
    }, []);

    //close the dropdowns on escape button press
    useEffect(() => {
        const escapeKeyHandler = (e) => {
            const key = e.key;
            if (!key) return;
            if (key.toLowerCase() === 'escape' || key.toLowerCase() === 'esc') {
                setDiscoveredTags([]);
            }
        };
        root.document.addEventListener('keyup', escapeKeyHandler);

        return () => {
            root.document.removeEventListener('keyup', escapeKeyHandler);
        };
    }, [setDiscoveredTags]);

    //close the dropdowns on click outside
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (el && el.contains(e.target)) {
                return;
            }
            setDiscoveredTags([]);
        };

        if (discoveredTags.length) {
            root.document.addEventListener('mousedown', handleOutsideClick, false);
        }

        return () => {
            root.document.removeEventListener('mousedown', handleOutsideClick, false);
        };
    }, [discoveredTags, setDiscoveredTags, el]);

    const getSearchPlaceholder = () => {
        const defaultTxt = 'Search by tag';
        const tagSearchCode = 'epic.feed.tag.search';
        const tagSearchTxt = getMessage(tagSearchCode);
        const locale = getLocale();
        if (tagSearchTxt === tagSearchCode || (locale !== 'en-US' && tagSearchTxt === defaultTxt)) {
            return getMessage('epic.common.search');
        }
        return tagSearchTxt;
    };

    const getEnglishFromLocalizedTag = (localizedTag) => {
        if (!localizedTag) return '';
        let englishTag = localizedTag;
        for (const key in localizedTags) {
            if (
                Object.hasOwnProperty.call(localizedTags, key) &&
                localizedTags[key] === localizedTag
            ) {
                englishTag = key;
            }
        }
        return englishTag;
    };

    const updateSelectedTags = (tag) => {
        const lowerTag = tag.toLowerCase();
        let eventAction;

        if (selectedTags[lowerTag]) {
            delete selectedTags[lowerTag];
            eventAction = 'client.feed.filter.deselectSearchItem';
        } else {
            selectedTags[lowerTag] = true;
            eventAction = 'client.feed.filter.selectSearchItem';
        }

        emitEvent({
            eventAction,
            eventLabel: 'tag',
            eventValue: lowerTag
        });

        setSelectedTags(selectedTags);
        updatePath();
    };

    const tagLookup = (value) => {
        if (!value) {
            setDiscoveredTags([]);
            setHasSearched(false);
        }

        const typedValue = value.toLowerCase();
        const startsWithArray: TagSearchProps[] = [];
        let matchesArray: TagSearchProps[] = [];

        if (typedValue.length > 1) {
            for (const tag in tags) {
                if (Object.hasOwnProperty.call(tags, tag)) {
                    const lcTag = tag.toLowerCase();
                    const englishTag = getEnglishFromLocalizedTag(tag).toLowerCase();
                    if (lcTag === selectedIndustry || englishTag === selectedIndustry) {
                        if (
                            lcTag.indexOf(typedValue) !== -1 ||
                            englishTag.indexOf(typedValue) !== -1
                        ) {
                            continue;
                        }
                    }

                    if (lcTag.indexOf(typedValue) === 0) {
                        startsWithArray.push({
                            tag,
                            count: tags[tag]
                        });
                    } else if (lcTag.indexOf(typedValue) !== -1) {
                        matchesArray.push({
                            tag,
                            count: tags[tag]
                        });
                    }
                }
            }

            //sort by tag if the count is the same
            matchesArray = matchesArray.sort((a, b) => {
                if (b.count === a.count) {
                    const bTag = (b.tag || '').toLowerCase();
                    const aTag = (a.tag || '').toLowerCase();
                    return Number(bTag) - Number(aTag);
                }
                return Number(b.count) - Number(a.count);
            });

            const discoveredTags: TagSearchProps[] = [];
            const handlePush = (match) => {
                if (match && match.tag && discoveredTags.length < 6) {
                    discoveredTags.push(match);
                }
            };
            startsWithArray.forEach(handlePush);
            matchesArray.forEach(handlePush);

            setDiscoveredTags(discoveredTags);
            setHasSearched(true);
        } else if (typedValue.length === 0) {
            setDiscoveredTags([]);
            setHasSearched(false);
        } else if (typedValue.length === 1) {
            setDiscoveredTags([]);
            setHasSearched(true);
        }
    };

    const debouceFilterOnChange = debounce(tagLookup, 200, {leading: false});
    const debouceFilterOnChangeWrapper = (e) => {
        const currentTarget = e.currentTarget || {};
        debouceFilterOnChange(currentTarget.value);
    };

    const handleFilterKeyPress = useCallback(
        (key) => {
            if (!key) return;
            if (key === 'Enter') {
                if (discoveredTags && discoveredTags.length && discoveredTags[0]) {
                    const firstTag: TagSearchProps = discoveredTags[0] || {};
                    const firstTagValue = firstTag.tag || '';
                    selectedTags[firstTagValue] = true;
                    setSelectedTags(selectedTags);
                    discoveredTags.shift();
                    setDiscoveredTags(discoveredTags);
                    updatePath();
                }
            }
        },
        [selectedTags, setSelectedTags, discoveredTags, setDiscoveredTags]
    );
    const debouceFilterKeyPress = debounce(handleFilterKeyPress, 200, {leading: false});
    const debouceFilterKeyPressWrapper = (e) => {
        debouceFilterKeyPress(e.key);
    };

    const filterTagsOnClick = useCallback(
        (tag) => (e) => {
            const inputElem = root.document.getElementById('filter-search-input');
            if (inputElem) {
                inputElem.value = '';
                const englishTag = getEnglishFromLocalizedTag(tag);
                updateSelectedTags(englishTag);
            }
        },
        [updateSelectedTags]
    );

    const searchPlaceholder = getSearchPlaceholder();
    return (
        <FilterForm
            className={`form-group ${hasSearched ? 'dirty' : 'pristine'}`}
            ref={tagSearchRef}>
            <input
                type="text"
                id="filter-search-input"
                className="form-control"
                placeholder={searchPlaceholder}
                tabIndex={-1}
                onChange={debouceFilterOnChangeWrapper}
                onKeyUp={debouceFilterKeyPressWrapper}
                onFocus={debouceFilterOnChangeWrapper}
            />
            <div className="tag-tooltip">
                <i className="icon-help" />
                <span className="desc">{getMessage('epic.feed.filter.tag.help')}</span>
            </div>
            <div className={`tag-lookup-results ${discoveredTags.length > 0 ? 'open' : ''}`}>
                {discoveredTags &&
                    discoveredTags.map((match) => {
                        if (match.tag) {
                            return (
                                <a
                                    key={match.tag}
                                    role="menuitem"
                                    onClick={filterTagsOnClick(match.tag)}
                                    className="discovered-tag"
                                    tabIndex={0}>
                                    {match.tag.length === 2 ? match.tag.toUpperCase() : match.tag}{' '}
                                    <span className="count">({match.count})</span>
                                </a>
                            );
                        }
                    })}
            </div>
        </FilterForm>
    );
};
