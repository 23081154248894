import { getPath } from '@epic-core/common';
import React from 'react';
import { Redirect } from './Redirect';
import { ServerRoute } from './routes';
import root from 'window-or-global';

export const getTwinmotionRedirects = (): ServerRoute[] => {
    const path = getPath();

    const clientConfig = root.clientEnvConfig || {};
    const EPIC_UE_TWIN_REDIRECT_BASE_URL =
        clientConfig && clientConfig.EPIC_UE_TWIN_REDIRECT_BASE_URL;
    if (!EPIC_UE_TWIN_REDIRECT_BASE_URL) {
        throw new Error('routes missing EPIC_UE_TWIN_REDIRECT_BASE_URL');
    }
    const tmSite = EPIC_UE_TWIN_REDIRECT_BASE_URL;

    return [
        {
            path: `${path}:locale/blog/3d-architectural-design-reviews-in-the-time-it-takes-to-make-a-coffee`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/3d-architectural-design-reviews-in-the-time-it-takes-to-make-a-coffee`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/architecture-that-cares-how-12-firms-put-people-into-the-heart-of-their-design`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/architecture-that-cares-how-12-firms-put-people-into-the-heart-of-their-design`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/dezeen-wants-you-to-redesign-the-world-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/dezeen-wants-you-to-redesign-the-world-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/green-space-in-an-urban-jungle-breakdown-the-winning-entry-of-twinmotion-community-challenge-3`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/green-space-in-an-urban-jungle-breakdown-the-winning-entry-of-twinmotion-community-challenge-3`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/new-twinmotion-lesson-plans-for-secondary-educators`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/new-twinmotion-lesson-plans-for-secondary-educators`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/real-time-and-bim-tools-building-a-seamless-archviz-workflow`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/real-time-and-bim-tools-building-a-seamless-archviz-workflow`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/twinmotion-2020-2-is-here`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-2020-2-is-here`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/twinmotion-2021-1-is-here`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-2021-1-is-here`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/twinmotion-and-quixel-megascans-archviz-week-roundup`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-and-quixel-megascans-archviz-week-roundup`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/twinmotion-hotfix-2020-2-3-significantly-improves-stability-and-user-experience`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-hotfix-2020-2-3-significantly-improves-stability-and-user-experience`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-a-dive-into-the-new-features-of-twinmotion-2020-2`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-a-dive-into-the-new-features-of-twinmotion-2020-2`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-importing-twinmotion-projects-into-unreal-engine`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-importing-twinmotion-projects-into-unreal-engine`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-taking-your-project-to-the-next-level-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-taking-your-project-to-the-next-level-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-virtual-reality-for-architecture-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-virtual-reality-for-architecture-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-working-with-quixel-megascans-in-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-working-with-quixel-megascans-in-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/webinar-working-with-vectorworks-and-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/webinar-working-with-vectorworks-and-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/blog/winning-entry-breakdown-one-archicad-model-any-environment-twinmotion-community-challenge-4`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/winning-entry-breakdown-one-archicad-model-any-environment-twinmotion-community-challenge-4`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/events/twinmotion-community-challenge-4-one-archicad-model-any-environment`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-community-challenge-4-one-archicad-model-any-environment`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/events/twinmotion-community-challenge-5-capture-a-winter-wonderland-experience`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-community-challenge-5-capture-a-winter-wonderland-experience`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/events/twinmotion-community-challenge-6-blending-the-old-world-with-the-new`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-community-challenge-6-blending-the-old-world-with-the-new`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/events/twinmotion-community-challenge-7-picture-perfect-postcards-from-your-travels`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-community-challenge-7-picture-perfect-postcards-from-your-travels`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/events/winning-entry-breakdown-blending-the-old-world-with-the-new-twinmotion-community-challenge-6`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/winning-entry-breakdown-blending-the-old-world-with-the-new-twinmotion-community-challenge-6`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/a-virtual-clone-of-adelaide-for-digital-twin-and-smart-city-applications`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/a-virtual-clone-of-adelaide-for-digital-twin-and-smart-city-applications`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/a-vr-walkthrough-of-europe-s-largest-gardening-project`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/a-vr-walkthrough-of-europe-s-largest-gardening-project`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/archviz-specialists-forma6-present-anytime-anywhere-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/archviz-specialists-forma6-present-anytime-anywhere-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/kpf-builds-and-shares-design-experiences-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/kpf-builds-and-shares-design-experiences-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/plan34-delivers-urban-planning-and-infrastructure-archviz-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/plan34-delivers-urban-planning-and-infrastructure-archviz-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/rewinding-the-clock-back-to-medieval-varberg-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/rewinding-the-clock-back-to-medieval-varberg-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/shau-explores-design-concepts-for-tropical-buildings-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/shau-explores-design-concepts-for-tropical-buildings-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/urban-planning-visualization-with-twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/urban-planning-visualization-with-twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/zaha-hadid-architects-turns-to-twinmotion-for-early-design-studies`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/spotlights/zaha-hadid-architects-turns-to-twinmotion-for-early-design-studies`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/twinmotion-inspiration-week-day-five-beyond-aec`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-inspiration-week-day-five-beyond-aec`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/twinmotion-inspiration-week-day-four-urban-planning`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-inspiration-week-day-four-urban-planning`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/twinmotion-inspiration-week-day-three-landscaping`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-inspiration-week-day-three-landscaping`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/twinmotion-inspiration-week-day-two-interiors`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-inspiration-week-day-two-interiors`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/spotlights/twinmotion-inspiration-week-day-one-architecture`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/news/twinmotion-inspiration-week-day-one-architecture`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}/en-US/eula/twinmotion`,
            element: <Redirect url={`${tmSite}eula?lang=en-US`} status={301} replace />
        },
        {
            path: `${path}/de/eula/twinmotion`,
            element: <Redirect url={`${tmSite}eula?lang=en-US`} status={301} replace />
        },
        {
            path: `${path}/fr/eula/twinmotion`,
            element: <Redirect url={`${tmSite}eula?lang=en-US`} status={301} replace />
        },
        {
            path: `${path}/es-ES/eula/twinmotion`,
            element: <Redirect url={`${tmSite}eula?lang=en-US`} status={301} replace />
        },
        {
            path: `${path}/pt-BR/eula/twinmotion`,
            element: <Redirect url={`${tmSite}eula?lang=en-US`} status={301} replace />
        },
        {
            path: `${path}/ja/eula/twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-ja?lang=ja`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}/ja/twinmotion/eula`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-ja?lang=ja`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/twinmotion/eula-ja`,
            element: <Redirect url={`${tmSite}eula-reference/twinmotion-ja`} status={301} replace />
        },
        {
            path: `${path}/ko/eula/twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-ko?lang=ko`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}/ko/twinmotion/eula`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-ko?lang=ko`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/twinmotion/eula-ko`,
            element: <Redirect url={`${tmSite}eula-reference/twinmotion-ko`} status={301} replace />
        },
        {
            path: `${path}/zh-CN/eula/twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-zh-cn?lang=zh-CN`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}/zh-CN/twinmotion/eula`,
            element: (
                <Redirect
                    url={`${tmSite}eula-reference/twinmotion-zh-cn?lang=zh-CN`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/twinmotion/eula-zh-cn`,
            element: (
                <Redirect url={`${tmSite}eula-reference/twinmotion-zh-cn`} status={301} replace />
            )
        },
        {
            path: `${path}:locale/eula-change-log/twinmotion`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-change-log/twinmotion`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-fr`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-fr`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-ja`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-ja`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-ko`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-ko`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-pt-br`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-pt-br`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-ru`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-ru`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eula-reference/twinmotion-zh-cn`,
            element: (
                <Redirect
                    url={`${tmSite}:locale/eula-reference/twinmotion-zh-cn`}
                    status={301}
                    replace
                />
            )
        },
        {
            path: `${path}:locale/eulacheck/twinmotion`,
            element: <Redirect url={`${tmSite}:locale/eulacheck`} status={301} replace />
        },
        {
            path: `${path}:locale/eulaprompt/twinmotion`,
            element: <Redirect url={`${tmSite}:locale/eulaprompt`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/signup`,
            element: <Redirect url={`${tmSite}:locale/license`} status={301} replace />
        },
        {
            path: `${path}:locale/get-now/twinmotion`,
            element: <Redirect url={`${tmSite}:locale/license`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/plugins`,
            element: <Redirect url={`${tmSite}:locale/plugins`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/download/edu`,
            element: <Redirect url={`${tmSite}:locale/download`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/download/com`,
            element: <Redirect url={`${tmSite}:locale/download`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/download/try`,
            element: <Redirect url={`${tmSite}:locale/download`} status={301} replace />
        },
        {
            path: `${path}:locale/download/twinmotion`,
            element: <Redirect url={`${tmSite}:locale/download`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/download`,
            element: <Redirect url={`${tmSite}:locale/download`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/redeem/rikcad`,
            element: <Redirect url={`${tmSite}:locale/redeem/rikcad`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/redeem/lenovo`,
            element: <Redirect url={`${tmSite}:locale/redeem/lenovo`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/redeem/vectorworks`,
            element: <Redirect url={`${tmSite}:locale/redeem/vectorworks`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/rikcad`,
            element: <Redirect url={`${tmSite}:locale/promo/rikcad`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/lenovo`,
            element: <Redirect url={`${tmSite}:locale/promo/lenovo`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/vectorworks`,
            element: <Redirect url={`${tmSite}:locale/promo/vectorworks`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion/resellers`,
            element: <Redirect url={`${tmSite}:locale/resellers`} status={301} replace />
        },
        {
            path: `${path}:locale/twinmotion`,
            element: <Redirect url={`${tmSite}:locale`} status={301} replace />
        }
    ];
};
