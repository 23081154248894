import styled from 'styled-components';
import {BaseButtonStyles} from 'epic-ue-components';

export const Lightbox = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    z-index: 100000;
    padding: 50px;
    text-align: center;
    img {
        cursor: default;
        max-width: 100%;
        max-height: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    button {
        background-color: transparent;
        border: 0;
        outline: none;
        &:focus {
            outline: none;
        }
        color: #c5c5c5;
        position: absolute;
        top: calc(50% - 1.8em);
        padding: 15px 18px;
        font-size: 1.8em;
        z-index: 99;
        &:hover {
            color: #ddd;
        }
        &.prev {
            left: 0;
        }
        &.next {
            right: 0;
        }
        &.close {
            right: 5px;
            top: 5px;
            opacity: 1;
            font-size: 1.2em;
        }
    }
`;

export const SpotlightWrapper = styled.div`
    background: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};
    min-height: 1400px;
    margin-bottom: 50px;
    &.has-newsletter-card {
        margin-bottom: 0;
    }
    transition: opacity 0.2s ease;
    opacity: 0;
    &.hasLoaded {
        opacity: 1 !important;
    }

    .feed-item .short {
        max-height: 200px;
        overflow: initial;
    }

    .article-top-details,
    .related-articles-wrapper,
    .related-articles-wrapper .news.container,
    .related-articles-wrapper .container,
    .interview-wrapper .copy,
    #spotlight-article .copy,
    #spotlight-article .quote,
    #spotlight-article h1,
    #spotlight-article h2,
    #spotlight-article h3,
    #spotlight-article h4,
    #spotlight-article h5,
    #spotlight-article .contained.container.section-2col-wrapper {
        margin-right: auto;
        margin-left: auto;
        ${(p) => p.theme.breakpoints.up('md')} {
            max-width: 730px;
        }
    }

    .img-compare,
    #spotlight-article .stacked-media,
    .feature-wrapper,
    .feature-wrapper .container {
        margin: 0 auto;
        max-width: 1200px;
        display: block;
    }

    #spotlight-article .stacked-media {
        margin: 3em auto 2.5em;
        padding-top: 56.25%;
        ${(p) => p.theme.breakpoints.down('md')} {
            max-width: 100%;
            margin: 1.5em auto 0;
        }
    }

    #spotlight-article .stacked-media.video {
        height: 0;
        margin: 0 auto;
        padding-top: 56.25%;
        max-width: 1200px;
    }

    #spotlight-article .header-slim:not(.lightbox-active) .container,
    #spotlight-article .header-centered:not(.lightbox-active) .container,
    #spotlight-article .header-default:not(.lightbox-active) .container,
    #spotlight-article .header-slim:not(.lightbox-active) .container h1,
    #spotlight-article .header-centered:not(.lightbox-active) .container h1,
    #spotlight-article .header-default:not(.lightbox-active) .container h1,
    #spotlight-article .header-slim:not(.lightbox-active) .container h2,
    #spotlight-article .header-centered:not(.lightbox-active) .container h2,
    #spotlight-article .header-default:not(.lightbox-active) .container h2 {
        margin-right: auto;
        margin-left: auto;
        ${(p) => p.theme.breakpoints.up('md')} {
            max-width: 850px;
        }
    }
    #spotlight-article .header-slim:not(.lightbox-active) .container,
    #spotlight-article .header-centered:not(.lightbox-active) .container,
    #spotlight-article .header-default:not(.lightbox-active) .container {
        ${(p) => p.theme.breakpoints.up('lg')} {
            padding-left: 70px;
        }
    }

    #spotlight-article .header-slim:not(.lightbox-active) .container h1,
    #spotlight-article .header-slim:not(.lightbox-active) .container h2,
    #spotlight-article .header-default:not(.lightbox-active) .container h1,
    #spotlight-article .header-default:not(.lightbox-active) .container h2 {
        @media screen and (min-width: 700px) and (max-width: 992px) {
            font-size: 2.5em;
            text-align: left;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 2.2em;
            text-align: left;
            padding: 20px 20px 0 20px;
            margin-left: 0;
        }
    }

    #spotlight-article .header-slim:not(.lightbox-active) .container h2,
    #spotlight-article .header-default:not(.lightbox-active) .container h2 {
        @media screen and (min-width: 700px) and (max-width: 992px) {
            font-size: 2em;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 2em;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 1.5em;
        }
    }

    #spotlight-article .header-slim:not(.lightbox-active) .header-bottom-content,
    #spotlight-article .header-default:not(.lightbox-active) .header-bottom-content {
        ${(p) => p.theme.breakpoints.down('md')} {
            text-align: left;
            padding-left: 20px;
        }
    }

    #spotlight-article .header-slim:not(.lightbox-active) .container h1,
    #spotlight-article .header-default:not(.lightbox-active) .container h1 {
        margin-bottom: 0;
    }

    ${(p) => p.theme.breakpoints.down('md')} {
        #spotlight-article .title-wrap h1 {
            line-height: 38px;
        }
    }

    .feature-wrapper {
        margin-top: 50px;
    }

    .interview-copy-wrapper {
        margin-left: 0;
        padding-left: 0;
    }

    .article-top-details {
        margin: 0 auto;

        a {
            margin-right: 5px;
            display: inline-block;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                .tag-item {
                    background-color: ${(p) => p.theme.palette.background.paperpop};
                }
            }
        }
        .tag-item {
            border-radius: 200px;
            display: inline-block;
            font-size: 12px;
            height: ${(p) => p.theme.spacing(4)}px;
            letter-spacing: 1px;
            line-height: ${(p) => p.theme.spacing(4)}px;
            margin-bottom: ${(p) => p.theme.spacing(1)}px;
            background-color: ${(p) => p.theme.palette.background.paper};

            > div {
                padding: 0 ${(p) => p.theme.spacing(2)}px;
            }
        }
        .article-core {
            margin-bottom: 20px;
            width: 100%;
            padding: 0 0 10px 0;
            border-bottom: 1px solid ${(p) => p.theme.palette.background.paper};
            font-family: ${(p) => p.theme.fonts.brutal.light};
            font-size: 20px;

            div {
                display: inline-block;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                padding: 5px 20px;
            }
            span {
                font-size: 0.875em;
                color: ${(p) => p.theme.palette.text.secondary};
            }
            .pipe {
                color: ${(p) => p.theme.palette.background.paper};
                font-style: normal;
            }
        }
        .article-tags {
            margin-bottom: 20px;
            .tag-item {
                color: ${(p) => p.theme.palette.text.primary};
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding: 5px 20px 15px;
                margin-bottom: 0;
            }
        }
    }

    .header-slim,
    .header-centered,
    .header-default {
        margin-bottom: 25px;
        max-height: 70vh !important;

        ${(p) => p.theme.breakpoints.up('lg')} {
            .container {
                max-width: 850px !important;
                h1 {
                    font-size: 2.8em;
                    line-height: 1.2em;
                    text-align: left;
                }
                h2 {
                    font-size: 1.5em;
                    margin-top: 5px;
                }
            }
        }
    }

    .header-centered {
        ${(p) => p.theme.breakpoints.up('lg')} {
            .container {
                h1 {
                    font-size: 2.6em;
                    text-align: center;
                }
            }
        }
    }

    section {
        min-height: auto !important;
        padding: 0 !important;
        background: ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.secondary};
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 0 20px !important;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h5,
        p,
        .copy {
            text-align: left !important;
            padding-left: 0;
            padding-right: 0;
            color: inherit;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h5,
        h6 {
            color: ${(p) => p.theme.palette.text.primary};
        }

        .copy {
            margin-bottom: 0 !important;
            font-family: ${(p) => p.theme.fonts.brutal.regular};
            strong {
                font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
                color: ${(p) => p.theme.palette.text.primary};
            }
            ${(p) => p.theme.breakpoints.up('lg')} {
                font-size: 1.25em;
                line-height: 1.9em;
            }
            > .embed-responsive,
            > img {
                max-width: 100%;
                height: auto !important;
                margin: 2em 0;
            }

            a,
            button {
                ${BaseButtonStyles}
            }
            .btn.btn-primary {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    margin: 10px 0 !important;
                    font-size: 0.875em !important;
                }
            }
        }

        &.type-threes,
        &.type-branding,
        &.type-fifty {
            h1,
            h2,
            h3,
            h4,
            h5,
            h5,
            p,
            .copy {
                text-align: center !important;
            }
        }
        &.type-stacked {
            ${(p) => p.theme.breakpoints.down('md')} {
                .stacked-media {
                    margin: 40px auto !important;
                }
            }
            .col-xs-12 {
                padding: 0;
            }
        }
        &.type-background {
            margin-bottom: 50px !important;
            .ue-lb-wrapper div[data-image] {
                z-index: 2;
            }
            h2 {
                text-align: center !important;
            }
        }
        &.type-threes {
            height: auto !important;
            min-height: 500px !important;
        }

        &.type-branding .section-container {
            margin-bottom: 0 !important;
        }
        &.type-baackground {
            .background-section-content {
                z-index: 6;
                h2 {
                    text-align: center !important;
                }
            }
        }

        &.type-background {
            z-index: 6;
            h2 {
                text-align: center;
            }
        }

        &.type-card {
            .card {
                z-index: 6;
            }
            .copy,
            h2 {
                text-align: center !important;
            }
        }

        .section-container {
            margin-bottom: 15px;

            .copy > h1 {
                margin-top: 20px;
            }

            h1 {
                font-size: 2.5em;
                margin: 0 0 1em;
                ${(p) => p.theme.breakpoints.down('md')} {
                    font-size: 32px;
                }
            }
            h2 {
                font-size: 2.2em;
            }
            h3 {
                font-size: 2em;
                font-weight: normal;
            }
            h4 {
                font-size: 1.5em;
                font-weight: normal;
            }
            h5 {
                font-size: 1em;
                font-weight: bold;
            }
        }

        .background-video .screen {
            .html-video-embed video {
                height: 100%;
            }
        }
    }

    &.header-type-video {
        .article-details,
        .social-sharing-wrapper:not(.fixed) {
            top: 50vh;
        }
    }

    .engagement-section {
        position: relative;
        margin-top: 40px;
        padding-bottom: 10px;

        max-width: initial;
    }
`;

export const SpotlightPageContainer = styled.div`
    background: ${(p) => p.theme.palette.background.default};

    &.has-lightbox {
        .ue-lb-wrapper div[data-image],
        .ue-lb-wrapper img[data-image] {
            cursor: pointer;
            border: 1px solid transparent;
            transition: transform 0.3s ease-in-out;
            z-index: 4;
            &:hover {
                transform: scale3d(1.0015,1.0015,1.0015);
            }

            .ue-lightbox-no-click, .ue-lightbox-img-ignore {
                cursor: initial;
                border: initial;
                &:hover {
                    transform: initial;
                }
            }
        }
    }
`;

export const SpotlightContainer = styled.div`
    margin-top: 2em;

    ${(p) => p.theme.breakpoints.up('lg')} {
        margin-top: 50px;
    }
`;
