import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {BulletTrain} from './BulletTrainPage.styles';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const cmsUrlPattern = '/bullet-train';

interface BulletTrainPageData extends PageData {
    content: string;
}

const BulletTrainPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<BulletTrainPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const content = pageData.content || '';

    return (
        <Page>
            <div className="container">
                {getHelmetForMetaTags(pageData)}
                <BulletTrain dangerouslySetInnerHTML={{__html: content}} />
            </div>
        </Page>
    );
};

BulletTrainPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default BulletTrainPage;
