import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import LogoListing from './LogoListing';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';

const LeadInSection = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    background: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};
    text-align: center;
    padding: 80px 50px;
    ${(p) => p.theme.breakpoints.down('md')} {
        .container {
            width: 100%;
        }
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 40px 20px;

        .row > div > div {
            font-size: 0.875em;
        }
    }
    .container h2 {
        color: ${(p) => p.theme.palette.text.primary};
        font-family: Brutal_Light;
        font-size: 3em;
        text-transform: uppercase;
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 2em;
        }
    }
`;

const SectionCopy = styled.div`
    ${(p) => p.theme.breakpoints.down('md')} {
        font-size: 0.875em;
        max-width: 100%;
        margin: 0 auto;
    }
`;

const LeadIn = ({title, primaryBtn = {}, secondaryBtn = {}, copy, logos = []}) => {
    const buttonData = {
        primaryBtn,
        secondaryBtn
    };
    if (!title && !copy && !primaryBtn.label && !secondaryBtn.label) return <div />;

    let logoListing = '';
    if (logos !== undefined && logos.length > 0) {
        logoListing = <LogoListing logos={logos} />;
    }
    return (
        <LeadInSection>
            <div className="container">
                <div className="col-xs-12">
                    <h2>{title}</h2>
                    <SectionCopy dangerouslySetInnerHTML={{__html: copy}} />
                    <CommonButtonWrapper sectionId="layout-leadin" {...buttonData} />
                </div>
            </div>
            {logoListing}
        </LeadInSection>
    );
};

LeadIn.propTypes = {
    title: pt.string,
    copy: pt.string,
    primaryBtn: pt.object,
    secondaryBtn: pt.object,
    logos: pt.array
};

export default LeadIn;
