import React from 'react';
import {H1} from 'epic-ue-components';
import {Header, Courtesy, Suffix} from './JumboHeaderLeft.styles';
import BgvideoWrapper from './BgVideoWrapper';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';

interface BackgroundProps {
    type?: string;
    content?: string[];
    contentId?: string;
    mobileImage?: string;
    tabletImage?: string;
    embedSrc?: string;
}
interface ButtonProps {
    url?: string;
    label?: string;
}

interface Properties {
    suffixTitle?: string;
    title: string;
    type?: string;
    opacity?: number;
    height?: string;
    primaryBtn: ButtonProps;
    secondaryBtn?: ButtonProps;
    background: BackgroundProps;
    courtesy?: string;
}

export const HeaderLeft = (props: Properties): JSX.Element => {
    const primaryBtn = props.primaryBtn || ({} as ButtonProps);
    const secondaryBtn = props.secondaryBtn || ({} as ButtonProps);
    const opacity = props.opacity || 1;
    const height = props.height || '';
    const background = props.background || {};
    const title = props.title || '';
    const suffixTitle = props.suffixTitle || '';
    const courtesy = props.courtesy || '';
    const buttonData = {
        primaryBtn,
        secondaryBtn
    };
    return (
        <Header opacity={opacity} height={height}>
            <BgvideoWrapper background={background}>
                {courtesy ? <div className="gradient" /> : ''}
                <div className="container-fluid content-wrapper">
                    <H1>{title}</H1>
                    <Suffix dangerouslySetInnerHTML={{__html: suffixTitle}} />
                    <CommonButtonWrapper sectionId="event-header" {...buttonData} />
                </div>
                <Courtesy>{courtesy}</Courtesy>
            </BgvideoWrapper>
        </Header>
    );
};
