import React from 'react';
import pt from 'prop-types';
import {RecordingCard} from './Recording.styles';
import {Button} from 'epic-ue-components';
import Message from '../../message/Message';
import {VerticalSpacer} from 'epic-ue-ui';

const placeholder =
    'https://cdn2.unrealengine.com/Unreal+Engine%2FUEFeatureImage_GENERIC-1920x960-b1bd91374f4ef077e657f411440478e3be1443ce.png';

export default class Recording extends React.PureComponent {
    static propTypes = {
        recording: pt.object,
        activeVideo: pt.object,
        featured: pt.bool,
        hasClicked: pt.func
    };

    playVideo = () => {
        const recording = this.props.recording;
        this.props.hasClicked(recording);
    };

    render() {
        const {recording = {}, featured} = this.props;
        const activeVideo = this.props.activeVideo;
        const nowPlayingCopy = <Message code="epic.event.playing" />;
        const btnLabel = <Message code="epic.event.watch" />;
        let active = false;
        if (activeVideo !== undefined && activeVideo !== null && recording !== undefined) {
            const activeVideoTitle = activeVideo.title || null;
            const title = recording.title || '';
            if (activeVideoTitle === title) {
                active = true;
            }
        }

        let image = recording.thumbnail;
        if (
            !image &&
            recording.video &&
            recording.video.type === 'youtubeVideo' &&
            recording.video.contentId
        ) {
            image = `https://img.youtube.com/vi/${recording.video.contentId}/maxresdefault.jpg`;
        } else if (!image) {
            image = placeholder;
        }

        if (recording !== undefined && recording.title) {
            return (
                <RecordingCard
                    key={recording.title}
                    onClick={this.playVideo}
                    className={`${active ? 'active' : ''} ${featured ? 'featured' : ''}`}
                    image={image}>
                    <div className="card-wrapper"> 
                        {recording?.description && (
                            <>
                            <div className="background-overlay">&nbsp;</div>
                            <div className="description-wrapper">
                                <div className="description">{recording?.description}</div>
                            </div>
                            </>
                        )}
                        <div className="image">
                            <div className="image-inner"></div>
                        </div>

                        <div className="title">
                            <span>{recording.title}</span>
                        </div>
                    </div>
                    <VerticalSpacer variant="eyebrow" />
                    <Button className="image-button" id="recording-btn">
                        {active ? nowPlayingCopy : btnLabel}
                    </Button>
                </RecordingCard>
            );
        }
        return '';
    }
}
