import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import {H2} from 'epic-ue-components';
import {GridLayout, Pagination} from 'epic-ue-feed';
import {useLocalization} from '@epic-mw/localization';

const Wrap = styled.div`
    position: relative;
    background: #111;
    padding: 40px 0 20px;
    .feed-item.spotlights {
        margin: 0 15px 20px;
    }
    .feed-item-full {
        padding: 0;
        .top-feed-item {
            padding: 0;
            .container-image > div {
                transform: translateY(0) !important;
            }
        }
    }

    .title-container h2 {
        font-family: Brutal_Light;
        font-size: 3em;
        text-transform: uppercase;
        color: white;
        margin-bottom: 0.5em;
    }
`;

const EventBlog = ({feedStore, getNextPage, history, currentFilters, title, loading}) => {
    const paging = feedStore.paging || {};
    const counts = feedStore.counts || {};
    const contentTypes = feedStore.contentTypes || [];
    const total = counts.total;
    const hasMore = paging.end < total;
    const rows = feedStore.rows || [];
    const getMessage = useLocalization();

    return (
        <Wrap>
            <div className="container title-container">
                <div className="row">
                    <div className="col-xs-12">
                        <H2>{title}</H2>
                    </div>
                </div>
            </div>
            <GridLayout rows={rows} loading={loading} />

            <Pagination
                paging={paging}
                hasMore={hasMore}
                history={history}
                contentTypes={contentTypes}
                currentFilters={currentFilters}
                loading={loading}
                getNextPage={getNextPage}
                getMessage={getMessage}
            />
        </Wrap>
    );
};

EventBlog.propTypes = {
    feedStore: pt.object,
    history: pt.object,
    currentFilters: pt.object,
    title: pt.string,
    getNextPage: pt.func,
    loading: pt.bool
};

export default EventBlog;
