import React from 'react';
import styled from 'styled-components';

export const PageStyles = styled.div`
    min-height: calc(94vh - 51.8px);
    background: ${(p) => p.theme.palette.background.default};
    background-color: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.secondary};
    margin: 0;
    font-size: calc(12px + 0.174vw); /* Responsive base font size */
    line-height: calc(12px + 0.8vw); /* Responsive Vertical Rhythm */
    ${(p) => p.theme.breakpoints.up('hd')} {
        font-size: 16px;
        line-height: 30px;
    }
    position: relative;
    overflow: hidden;

    h1 {
        font-size: 4.5em;
        line-height: calc(1em + 1.6vw); /* Responsive Vertical Rhythm */
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 4.5em;
            line-height: calc(1em + 1.4vw);
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 3.8em;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 3em;
        }
    }
    h2,
    h1.h2 {
        font-size: 3em;
        line-height: 1.1em; /* Responsive Vertical Rhythm */
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 2.6em;
            line-height: calc(1em + 1vw);
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 2.2em;
            line-height: calc(1em + 1vw);
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 2em;
        }
    }
    h3 {
        font-size: 2.5em;
        line-height: calc(1em + 0.5vw); /* Responsive Vertical Rhythm */
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 1.8em;
        }
        ${(p) => p.theme.breakpoints.between('md', 'lg')} {
            font-size: 2em;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 1.6em;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 1.4em;
        }
    }
    h4 {
        font-size: 2em;
    }
    h5 {
        font-size: 1.6em;
    }
    h6 {
        font-size: 1.2em;
    }

    h4,
    h5,
    h6 {
        line-height: calc(1em + 0.2vw); /* Responsive Vertical Rhythm */
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: ${(p) => p.theme.palette.text.primary};
        margin: calc(12px + 1.05vw) 0; /* Responsive margins */
        ${(p) => p.theme.breakpoints.up('qhd')} {
            margin: 40px 0;
        }
        &.centered,
        &.center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }

    section {
        width: 100%;
        position: relative;
        overflow: hidden;

        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 0 20px;
            font-family: ${(p) => p.theme.ue.fonts.opensans};
            .row {
                margin: 0;
            }
        }
    }

    a:hover,
    a:visited,
    a:active,
    a:focus {
        text-decoration: none;
    }
    a {
        transition: 200ms color ease-out;
    }
    a:focus {
        outline: 0;
    }

    a.simple {
        font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
        color: ${(p) => p.theme.ue.colors.buttons.link.default};
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: ${(p) => p.theme.ue.colors.buttons.link.hover};
        }
    }

    .header {
        h1,
        h2,
        h3,
        h4 {
            font-family: ${(p) => p.theme.ue.fonts.brutal.light};
        }

        h1 {
            font-size: 66px;
            line-height: 74px;
        }
    }

    .bgMedia-wrap {
        background-color: ${(p) => p.theme.palette.background.default};
    }

    .feed-view.reusable-row {
        ${(p) => p.theme.breakpoints.down('md')} {
            padding-top: 2em;
        }
        background-color: ${(p: any) => (p.bg ? p.bg : p.theme.palette.background.default)};
        .feed-item {
            border-color: ${(p: any) => (p.bg ? p.bg : p.theme.palette.background.default)};
        }
    }

    .row.centered {
        text-align: center;
        position: relative;
        overflow: visible;
        .copy {
            max-width: 950px;
            margin: 0 auto 40px;
        }
    }

    .sections > section section:first-child {
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 50px 0;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            padding: 0;
        }
    }

    .copy {
        font-size: 1.15em;
        line-height: 1.7em;
        max-width: 950px;
        position: relative;
        overflow: hidden;
        color: ${(p) => p.theme.palette.text.primary};
        font-family: ${(p) => p.theme.ue.fonts.opensans};
        ${(p) => p.theme.breakpoints.down('md')} {
            margin-bottom: 24px;
        }
        a {
            color: ${(p) => p.theme.ue.colors.primary};
            &:hover,
            &:focus,
            &:active {
                color: ${(p) => p.theme.ue.colors.buttons.link.hover};
            }
        }
    }

    /* fixes for dark mosaic */
    div.enterprise {
        background: ${(p: any) => (p.bg ? p.bg : p.theme.palette.background.default)};
        .highlight-panel-section .headline-content {
            background: ${(p: any) => (p.bg ? p.bg : p.theme.palette.background.default)};
        }
        .grid-news-text {
            color: rgb(198, 198, 198);
        }
        .grid-news-title {
            transition: text-shadow 0.1s ease-out;
            &:hover {
                text-shadow: 6px 6px 6px rgba(0, 0, 0, 0.7);
            }
        }
        .grid-news-item {
            background: ${(p) => p.theme.palette.background.paper};
            color: ${(p) => p.theme.palette.text.primary};
            border: 1px solid #464646;
            &.add-hover .img-responsive:hover {
                background-color: #2a2a2a;
            }
            &:active,
            &:visited,
            &:focus {
                background: #2a2a2a;
            }
        }
    }
`;

export const GradientHandler = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    bottom: 0;
    z-index: 0;
    span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.7) 100%);
    }
    &:after,
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 150px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, transparent 100%);
    }

    &:after {
        left: auto;
        right: 0;
        background: linear-gradient(90deg, transparent 0%, rgba(0, 0, 0, 0.3) 100%);
    }
`;

interface PageProps {
    children?: React.ReactNode;
}

export const Page = ({children}: PageProps): JSX.Element => {
    return <PageStyles>{children}</PageStyles>;
};
