import React from 'react';
import {H2, SimpleGallery, CarouselGallery} from '../../elements/';
import {GalleryWrapper} from './Gallery.styles';
import {GalleryProps} from 'epic-ue-shared';

export const Gallery = ({title, type, lbClass, slides}: GalleryProps): JSX.Element => {
    let gallery: JSX.Element | undefined;
    switch (type) {
        case 'simple':
            gallery = <SimpleGallery slides={slides} />;
            break;
        case 'carousel':
            gallery = (
                <CarouselGallery slides={slides} />
            );
            break;
        default:
            gallery = (
                <CarouselGallery slides={slides} />
            );
    }
    return (
        <GalleryWrapper>
            <div className={`gallery-wrapper gallery-${type} ${lbClass ? lbClass : ''}`}>
                <div className="container">
                    <div className="row">{title ? <H2>{title}</H2> : ''}</div>
                </div>
                <div className="gallery-container">{gallery}</div>
            </div>
        </GalleryWrapper>
    );
};
