import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {Hyperlink, TextLink, Typography, VerticalSpacer} from 'epic-ue-ui';
import { SearchItem } from '../types';

const ResultWrapper = styled.div`
    .hlink-wrap,
    .ue-link-wrap-span {
        display: block;
    }
    .ue-rich-text {
        display: inline;
        & > * {
            display: inline;
        }
    }
    .ue-link-wrap,
    a.ue-link-wrap {
        white-space: normal;
        line-height: 22px;
        ${(p) => p.theme.breakpoints.down('xs')} {
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
        }
    }
`;

const PlainSearchResultsWrapper = styled.div``;

interface PlainSearchResultsProps {
    plainItems?: SearchItem[];
}

export const PlainSearchResults = ({plainItems}: PlainSearchResultsProps): JSX.Element => {
    return (
        <PlainSearchResultsWrapper>
            <Grid item md={8}>
                {plainItems?.map((item, i) => {
                    if (!item) return null;

                    return (
                        <ResultWrapper key={`${item.title}-${i}`}>
                            <TextLink href={item?.link}>
                                <Typography variant="h2">{item.htmlTitle}</Typography>
                            </TextLink>
                            <VerticalSpacer variant="paragraph" />
                            <Hyperlink href={item.link}>
                                {item.htmlFormattedUrl}
                            </Hyperlink>
                            <VerticalSpacer variant="paragraph" />
                            <Typography variant="p">{item.htmlSnippet}</Typography>
                            <VerticalSpacer variant="custom" spacing={6} downSmSpacing={4} />
                        </ResultWrapper>
                    );
                })}
            </Grid>
        </PlainSearchResultsWrapper>
    );
};

export default PlainSearchResults;
