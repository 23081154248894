import {GraphQlService} from '@epic-node-services/graphql-public-service';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';

const ENTERPRISE_QUERY = (accountId: string, locale: string) => `
{
    Enterprise {
      checkManagePeopleAccess(accountId: "${accountId}", locale: "${locale}") {
        hasAccess
        isLoggedIn
        nav {
            title
        }
      }
    }
  }
`;

interface EnterpriseQueryRes {
    data: {
        Enterprise: {
            checkManagePeopleAccess: EnterpriseRes;
        };
    };
}

export interface EnterpriseRes {
    hasAccess: boolean;
    isLoggedIn: boolean;
    nav: {
        title: string;
    };
}

class EnterpriseApi {
    public async getSeats(accountId?: string): Promise<any> {
        if (root.__server_side_render) {
            return Promise.resolve();
        }

        if (!accountId) {
            console.error('getSeats() requires an accountId');
            return;
        }

        const locale = getLocale();
        try {
            const response: EnterpriseQueryRes = await GraphQlService.query(
                ENTERPRISE_QUERY(accountId, locale)
            );
            const data = response.data || {};
            const account = data.Enterprise || {};
            const checkManagePeopleAccess = account.checkManagePeopleAccess || {};
            return checkManagePeopleAccess;
        } catch (e) {
            console.error('getSeats() failed to load');
            throw e;
        }
    }
}

export const enterpriseApi = new EnterpriseApi();
