import React from 'react';
import {generateRoutePath} from '@epic-core/common';
import {Navigate} from 'react-router';
import {UnrealLoading} from 'epic-ue-loading';
import {addForwardSlash} from '../../utils/UrlPropUtils';

interface ArticleRedirectProps {
    children: JSX.Element;
    blog: any;
    loading?: boolean;
    location: any;
    routerRootUrl: string;
}

/**
 * ArticleRedirect
 * Pre-render checks before rendering the ArticleView component
 * @implements {ArticleView}
 * @reactProps {object} location  {}
 * @reactProps {object} children
 * @reactProps {string} routerRootUrl
 * @reactProps {bool} hideLoadIndicator
 * @reactProps {bool} showLoading
 */
class ArticleRedirect extends React.Component<ArticleRedirectProps> {
    static defaultProps = {
        routerRootUrl: '',
        location: {}
    };

    render(): JSX.Element {
        let redirect: string | null = null;
        const blog = this.props.blog;
        const location = this.props.location;
        const routerRootUrl = this.props.routerRootUrl;
        const showLoading = this.props.loading;
        const children = this.props.children;
        if (blog && blog.externalLink) {
            redirect = generateRoutePath(blog.externalLink);
        } else if (location && location.query && location.query.lang) {
            // Change locale & page doesn't exist
            redirect = generateRoutePath(addForwardSlash(routerRootUrl.replace(/[^\w]/g, ''), 0));
        } else if (
            !showLoading &&
            typeof blog === 'object' &&
            (blog === null || !blog._urlPattern)
        ) {
            redirect = generateRoutePath('page-not-found');
        }
        if (redirect) {
            return (
                <div className="article-view" id="articleView">
                    <div id="redirect-link" data-redirect-path={redirect}>
                        <Navigate to={redirect} />
                    </div>
                </div>
            );
        } else if (showLoading || !blog) {
            return (
                <div className="article-view loading" id="articleView">
                    <UnrealLoading fillViewHeight={false} />
                </div>
            );
        }

        return children;
    }
}

export default ArticleRedirect;
