import React from 'react';
import styled from 'styled-components';
import { CmsButtonProps, Eyebrow, EyebrowProps, GridContainer, Hyperlink, Spotlight, Typography, VerticalSpacer } from 'epic-ue-ui';
import Grid from '@material-ui/core/Grid/Grid';
import { AccentColorOptions, useCurrentAccentColor} from 'epic-ue-theme';

const Styled = styled.div<{accentColor: AccentColorOptions}>`

    .ue-rich-text ul {
        list-style-type: none;

        li:before {
            content: "•";
            color: ${(p) =>
            p.accentColor
                ? p.theme.palette.accent[p.accentColor]
                : 'initial'};
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }
`;

export interface LinuxBlobsHeaderProps {
    eyebrow?: EyebrowProps;
    title?: string;
    description?: string;
    bulletHeading?: string;
    bullets?: string;
    bulletLink?: CmsButtonProps;
}

export const LinuxBlobsHeader = ({eyebrow, title, description, bulletHeading, bullets, bulletLink = {}}:LinuxBlobsHeaderProps):JSX.Element => {
    const currentAccentColor = useCurrentAccentColor();
    const accentColor = currentAccentColor.name || 'sky';

    return (
        <Styled className="linux-blobs-header" accentColor={accentColor} >
            <GridContainer>
                <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={12} md={5}>
                        <Eyebrow {...eyebrow} />
                        <VerticalSpacer variant="heading" />
                        <Spotlight
                            accentColor={accentColor}
                            variant="medium"
                            shape="circular"
                            offsetRatio={[-0.6, -2]}
                        >
                            <Typography variant="h1">{title}</Typography>
                        </Spotlight>
                        <VerticalSpacer variant="eyebrow" />
                        <Typography variant="p">{description}</Typography>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={12} md={4}>
                        <VerticalSpacer variant="section" />
                        <Eyebrow text={bulletHeading} />
                        <div className="description">
                            <Typography variant="p">{bullets}</Typography>
                        </div>
                        <Hyperlink href={bulletLink.href}>
                            <Typography
                                variant="p"
                                caption={true}
                                className="text-card-link">
                                {bulletLink.label}
                            </Typography>
                        </Hyperlink>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>

            </GridContainer>
        </Styled>
    )
}
