import React, {useState, useEffect} from 'react';
import {FeedFilter, GridLayout, Latest, Pagination} from '../components';
import {useLocalization} from '@epic-mw/localization';
import {
    getHelmetForMetaTags,
    getMetaTags,
    getPathnameFromRouteMatch,
    RouteMatchOrUrlParse
} from 'epic-ue-shared';
import {Helmet} from 'react-helmet';
import {feedApi, useFeed, useFeedSearchTags} from '../shared';
import {AppSharedKeys, InitSharedValueResponse} from 'epic-ue-shared';
import {
    processFeedResponse,
    getFiltersFromUrl,
    getShort,
    getImageUrl,
    getPageFromParsedUrl
} from '../utils';
import {FeedCurrentFilterProps} from '../types';
import {Header, FeedPageWrapper, FeedView} from './FeedPage.styles';
import {getLocale} from '@epic-core/common';
import {getMessageFnType} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';
import {useLocation} from 'react-router';


export const FeedPage = (): JSX.Element => {
    const {pathname, search} = useLocation();

    const {feed, loading} = useFeed({pathname, search});
    const {searchTags, localizedTags} = useFeedSearchTags();
    const getMessage = useLocalization() as getMessageFnType;
    const feedStore = feed || {};
    const [currentFilters, setCurrentFilters] = useState<FeedCurrentFilterProps>(
        feedStore.filters || {}
    );
    useEffect(() => {
        const {types, tags} = getFiltersFromUrl(pathname);
        setCurrentFilters({types, tags});
    }, [pathname]);

    const isTagFiltered = Boolean(currentFilters.tags && Object.keys(currentFilters.tags).length);
    const rows = feedStore.rows || [];
    const contentTypes = (feedStore.contentTypes || []).sort((a, b) => {
        return (a.labelPlural || a.label).localeCompare(b.labelPlural || b.label);
    });

    const curatedTags = feedStore.industryTags || [];
    const counts = feedStore.counts || {};
    const paging = feedStore.paging || {};
    const total = counts.total;
    const hasMore = paging.end < total;

    const feedItems = feedStore.items || [];
    const firstItem = feedItems[0] || {};
    const {pageModel = {}, typePageModel = {}} = feedStore;
    const heading = typePageModel ? typePageModel.heading : '';
    const title = pageModel._title as string;

    const metaTags = getMetaTags(
        getMessage,
        title,
        getShort(firstItem),
        firstItem.shareImage || getImageUrl(firstItem)
    );

    let LatestItems;
    if (!loading && Object.keys(currentFilters).length > 0 && !hasMore) {
        LatestItems = <Latest loading={loading} />;
    }

    const activeCtype = contentTypes
        .map((type) => {
            if (currentFilters && Object.keys(currentFilters).length > 0) {
                for (const key in currentFilters) {
                    if (Object.hasOwnProperty.call(currentFilters, key)) {
                        const active = currentFilters[key];
                        const activeContent =
                            active !== null && typeof active === 'object'
                                ? Object.keys(active)[0]
                                : '';
                        if (activeContent === type.slug) {
                            const label = type.label;
                            return label.toLowerCase();
                        }
                        return null;
                    }
                }
            }
            return null;
        })
        .join('')
        .toString();

    return (
        <FeedView className={`feed-view ${activeCtype}`} role="presentation">
            <Helmet meta={metaTags} title={title} />
            {getHelmetForMetaTags(feedStore.typePageModel || feedStore.pageModel)}
            {isTagFiltered && <Helmet meta={[{name: 'robots', content: 'noindex'}]} />}

            <FeedFilter
                filters={currentFilters}
                contentTypes={contentTypes}
                tags={searchTags}
                localizedTags={localizedTags}
                curatedTags={curatedTags}
                headerText={heading}
            />

            {heading && (
                <Header>
                    <h1>{heading}</h1>
                    <span className="hr" />
                </Header>
            )}

            <FeedPageWrapper className={`loc-${getLocale()}`}>
                <GridLayout rows={rows} loading={loading} />
            </FeedPageWrapper>

            {LatestItems}

            <Pagination
                paging={paging}
                hasMore={hasMore}
                tags={searchTags}
                contentTypes={contentTypes}
                currentFilters={currentFilters}
                loading={loading}
            />
        </FeedView>
    );
};

FeedPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse,
    url
): Promise<InitSharedValueResponse[]> => {
    const path = getPathnameFromRouteMatch(match);
    const pageNum = getPageFromParsedUrl(url);
    const {types, tags} = getFiltersFromUrl(path);

    let response;
    try {
        response = await feedApi.filterFeed(types, tags, pageNum);
    } catch(e) {
        console.log('feedApi.filterFeed() failed to load on the server render', isError(e) && e.message);
    }
    const processedData = processFeedResponse(response);

    return [
        {
            key: AppSharedKeys.FeedStore,
            value: processedData
        }
    ];
};
