import React, {useCallback} from 'react';
import {generateRoutePath} from '@epic-core/common';
import styled from 'styled-components';
import {NavigateFunction} from 'react-router';

interface Properties {
    tag: string;
    navigate: NavigateFunction;
}

const Tag = styled.div`
    cursor: pointer;
    padding: 0 5px;
    font-size: 0.875em;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    color: #333;
    position: relative;
    border-radius: 1px;
    transition: opacity 0.1s ease-in-out;
    &:hover {
        opacity: 0.85;
    }
    ${(p) => p.theme.breakpoints.down('lg')} {
        padding: 5px;
        font-size: 0.75em;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 4px;
        font-size: 0.7em;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 4px;
        font-size: 0.65em;
    }
`;

export const MatchingTag = ({tag, navigate}: Properties): JSX.Element => {
    const onClickTag = useCallback(
        (e) => {
            e.preventDefault();
            const lowerTag = tag ? tag.toLowerCase() : '';
            navigate(generateRoutePath(`/feed/all/${lowerTag}`));
        },
        [tag, navigate]
    );

    return (
        <Tag role="button" tabIndex={0} className="matching-tag" onClick={onClickTag}>
            {tag}
        </Tag>
    );
};
