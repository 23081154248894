import React from 'react';
import {
    AppSharedKeys,
    cmsApi,
    getHelmetForMetaTags,
    InitSharedValueResponse,
    RouteMatchOrUrlParse,
    useCmsTreeData,
    useMultiCmsData
} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {useCmsData} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {UeThemeProvider, ueDarkTheme, ueLightTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {useLocation} from 'react-router-dom';
import {
    TemplateThemeProps,
    SubnavSectionConfigProps,
    ImageHero,
    VerticalSpacer,
    SubnavSection,
    DownloadSection,
    NewsletterSection,
    CmsButtonProps,
    DividedListWithContainer,
    CardCarousel,
    CardCarouselProps,
    CmsDynamicSection,
    CmsDynamicSectionOptionsArray,
    CenteredTextBlockProps,
    CenteredTextBlock,
    WithCaptionProps
} from 'epic-ue-ui';
import {DownloadWithNewsletterSectionProps, ExplainersPageData} from './ExplainersStardustPage';

const getCmsUrlPattern = (pathname: string): string => {
    const pathArray = pathname.split('/');
    const topic = pathArray[pathArray.length - 1];
    return `/explainers/${topic}`;
};

const Styled = styled.div``;

interface ExplaiersTopicSection {
    items?: CmsDynamicSectionOptionsArray;
}

export interface ExplainersTopicPageData extends ExplainersPageData {
    sections?: ExplaiersTopicSection;
    detailHeading?: CenteredTextBlockProps;
    landing: ExplainersTopicLanding;
    childPages: {
        pages?: string[];
    };
    _activeDate: string;
    cardCarousel?: CardCarouselProps;
}

export interface ExplainersTopicLanding {
    image?: string;
    caption?: WithCaptionProps;
    heading?: string;
    eyebrow?: string;
    imageLabel?: string;
    alt?: string;
    description?: string;
    button?: CmsButtonProps;
}

const ExplainersTopicPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const cmsUrlPattern = getCmsUrlPattern(pathname);
    const {cmsLoading, cmsLoadedEmpty, pageData} =
        useCmsData<ExplainersTopicPageData>(cmsUrlPattern);
    const {cmsLoading: cmsTreeLoading, children = []} =
        useCmsTreeData<ExplainersTopicPageData>(cmsUrlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        sections = {} as ExplaiersTopicSection,
        detailHeading = {} as CenteredTextBlockProps,
        download = {} as DownloadWithNewsletterSectionProps,
        cardCarousel = {} as CardCarouselProps,
        childPages = {}
    } = pageData;
    const {pages = []} = childPages;

    const {cmsLoading: cmsMultiLoading, pageData: linkedPages = {}} =
        useMultiCmsData<ExplainersTopicPageData>([], pages);

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    if (cmsLoading || cmsTreeLoading || cmsMultiLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const {items = []} = sections;

    const expTopicItems = (children || [])
        .concat(Object.values(linkedPages) || [])
        .sort((a, b) => new Date(b._activeDate).getTime() - new Date(a._activeDate).getTime())
        .map(({_urlPattern, landing = {}}) => ({
            image: {
                src: landing.image,
                alt: landing.alt || landing.imageLabel,
                title: landing.imageLabel || landing.alt
            },
            headline: landing.heading,
            topEyebrow: {text: landing.eyebrow},
            description: landing.description,
            href: _urlPattern
        }));

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <Styled>
                <ImageHero
                    {...pageData.header}
                    showScrollIndicator
                    imgTopAlignment={pageData?.header?.imgTopAlignment || '40%'}
                    alignment="centerXY"
                />
                <VerticalSpacer variant={pageData?.header?.image ? 'heading' : 'section'} />
                <CmsDynamicSection sectionConfig={{theme, accentColor}} items={items} />
                <VerticalSpacer variant="section" />
                <SubnavSection
                    sectionConfig={{
                        index: null,
                        theme: 'light',
                        accentColor
                    }}>
                    {detailHeading && detailHeading.headline && (
                        <>
                            <CenteredTextBlock {...detailHeading} accentColor={accentColor} />
                            <VerticalSpacer variant="section" />
                        </>
                    )}
                    <DividedListWithContainer
                        items={expTopicItems}
                        accentColor={accentColor}
                        variant="offset-description"
                    />
                    <VerticalSpacer variant="section" />
                    {cardCarousel && <CardCarousel {...cardCarousel} accentColor={accentColor} />}
                </SubnavSection>
                <SubnavSection
                    variant="gradient"
                    hideBottomSectionPadding
                    sectionConfig={downloadSectionConfig}>
                    <DownloadSection
                        {...downloadBlockProps}
                        accentColor={accentColor}
                        variant="standard"
                    />
                    <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                    <NewsletterSection
                        variant="small-split"
                        {...download?.newsletter}
                        accentColor={accentColor}
                    />
                </SubnavSection>
            </Styled>
        </UeThemeProvider>
    );
};

ExplainersTopicPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const cmsUrlPattern = getCmsUrlPattern(match?.pathname as string);

    const responses = await Promise.all([
        await cmsApi.getPageData(cmsUrlPattern),
        await cmsApi.getTreeData(cmsUrlPattern)
    ]);

    const first = responses[0] || {};
    const childPages = first?.childPages || {};
    const {pages: ids} = childPages;
    let childPagesResponse = {
        ids: {}
    };

    if (ids && ids.length > 0) {
        childPagesResponse = await cmsApi.getBulkPageData({ids});
    }

    return [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                ...childPagesResponse.ids,
                [cmsUrlPattern]: first
            }
        },
        {
            key: AppSharedKeys.CmsTreeStore,
            value: {
                [cmsUrlPattern]: responses[1] || []
            }
        }
    ];
};

export default ExplainersTopicPage;
