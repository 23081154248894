import {CmsDynamicArray, HeroBasicProps, ImageProps} from 'epic-ue-ui';
import {
    FeedLogoProps,
    PageData,
    STARDUST_ARTICLE_TEMPLATE_NAME,
    STARDUST_EVENT_TEMPLATE_NAME
} from 'epic-ue-shared';
import {FeedItemProps} from '../types';

interface StardustFeedRes {
    short?: string;
    title?: string;
    imageUrl?: string;
    logo?: FeedLogoProps;
}

interface NewsLandingConfigLargeProps {
    sticky?: boolean;
    image?: ImageProps;
    title?: string;
    buttonLabel?: string;
    logo?: FeedLogoProps;
}
interface NewsLandingConfigSmallProps extends NewsLandingConfigLargeProps {
    description?: string;
}
type NewsLandingConfigOptions = NewsLandingConfigLargeProps | NewsLandingConfigSmallProps;
type NewsLandingConfigOptionsArray = CmsDynamicArray<NewsLandingConfigOptions>;

interface FirstOption {
    large?: NewsLandingConfigLargeProps;
    small?: NewsLandingConfigSmallProps;
}

interface ArticleDetailPageData extends PageData {
    header?: HeroBasicProps;
    newsLandingConfig?: {
        options?: NewsLandingConfigOptionsArray;
    };
}

export const isStardustItem = (item: FeedItemProps): boolean =>
    Boolean(
        item &&
            item._templateName &&
            [STARDUST_ARTICLE_TEMPLATE_NAME, STARDUST_EVENT_TEMPLATE_NAME].includes(
                item._templateName
            )
    );

export const getStardustItem = (item: FeedItemProps): StardustFeedRes => {
    const res: StardustFeedRes = {};
    if (!isStardustItem(item)) return res;

    const stardustItem = item as ArticleDetailPageData;
    const newsLanding = stardustItem.newsLandingConfig;
    const options = newsLanding?.options || [];
    const firstOption: FirstOption = options[0] || undefined;

    if (!firstOption) return res;

    if (firstOption.large) {
        res.title = firstOption.large.title;
        res.imageUrl = firstOption.large.image?.src;
        res.short = stardustItem?.header?.description;
        res.logo = firstOption.large.logo;

        if (!res.short && '_type' in firstOption.large) {
            const optionType = (firstOption.large as any)._type;
            if (optionType && optionType === 'Stardust - Article - News Landing Large') {
                const description = (firstOption.large as any).description;
                if (description) {
                    res.short = description;
                }
            }
        }
    } else if (firstOption.small) {
        res.title = firstOption.small.title;
        res.imageUrl = firstOption.small.image?.src;
        res.short = firstOption.small.description;
    }

    return res;
};
