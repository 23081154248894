import React from 'react';
import {FeedItemProps} from '../../types';
import Grid from '@material-ui/core/Grid';
import {FeedCard} from '../item/normal/FeedCard.container';
import {SpotlightCard} from '../item/spotlight/SpotlightCard.container';

interface Props {
    items: FeedItemProps[];
    odd?: boolean;
    disableContentTypeLabelClick?: boolean;
    isLastRow?: boolean;
}

type GridSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export const GridRow = (props: Props): JSX.Element => {
    let items = props.items || [];
    const odd = props.odd || false;
    const isLastRow = props.isLastRow || false;
    const disableClick = props.disableContentTypeLabelClick || false;
    let rowItemSize = items.length;
    let mdSize: GridSize = 12;
    let lgSize: GridSize = 12;
    let xlSize: GridSize = 12;
    let largeCard = false;

    const firstItem = items[0] || {};
    const firstItemLayout = firstItem._feedLayout || {};
    const rowSize = items.length;

    if (isLastRow && rowSize === 1 && firstItemLayout.size && firstItemLayout.size === 'normal') {
        rowItemSize = 3; //render the one extra item as normal
    }

    return (
        <Grid
            container
            spacing={4}
            className={`${firstItemLayout.size ? firstItemLayout.size : ''}`}>
            {items.map((item, i) => {
                if (rowItemSize === 3) {
                    mdSize = 12;
                    lgSize = 12;
                    xlSize = 4;
                    items = items.slice(0, 3); //ensure only 3 are rendered
                } else if (rowItemSize === 2) {
                    items = items.slice(0, 2); //ensure only 2 are rendered
                    mdSize = 6;
                    lgSize = 6;
                    xlSize = 6;
                    largeCard = true;
                    if ((odd && i === 0) || (!odd && i === 1)) {
                        mdSize = 6;
                        lgSize = 6;
                        xlSize = 6;
                    }
                }

                return (
                    <Grid item xs={12} md={mdSize} lg={lgSize} xl={xlSize} key={`feed-grid-${i}`}>
                        {rowItemSize > 1 && (
                            <FeedCard
                                item={item}
                                disableClick={disableClick}
                                largeCard={largeCard}
                            />
                        )}
                        {rowItemSize === 1 && <SpotlightCard item={item} />}
                    </Grid>
                );
            })}
        </Grid>
    );
};
