import React from 'react';
import styled from 'styled-components';
import {SectionWrapper} from 'epic-ue-components';
import {useFeedItems, GridReusableRow} from 'epic-ue-feed';
import {useLocalization} from '@epic-mw/localization';
import {UnrealLoading} from 'epic-ue-loading';

const RelatedArticlesWrapper = styled.div`
    &.related-articles-wrapper {
        .like-this {
            font-size: 2.5em;
            line-height: calc(1em + 0.5vw);
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            font-weight: 500;
            margin: calc(12px + 1.05vw) 0px;
            display: block;
            color: ${(p) => p.theme.palette.text.primary};
        }
        h3 {
            ${(p) => p.theme.breakpoints.down('md')} {
                margin: 0 20px;
                padding: 5px 20px !important;
            }
        }
        margin-top: 50px;
        h2,
        .h2 {
            font-size: 1em !important;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 0.8em !important;
            }
        }
        .container {
            padding: 0;
        }

        .feed-wrapper {
            padding: 0;
            width: 100% !important;
            ${(p) => p.theme.breakpoints.down('md')} {
                padding: 10px;
            }
            .feed-item {
                height: auto;
                ${(p) => p.theme.breakpoints.down('md')} {
                    padding: 10px;
                    margin: 0;
                }
                @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                    height: 200px;
                }
                .feed-item-details {
                    display: none !important;
                }
                &:hover {
                    .img-col {
                        overflow: hidden;
                        .image {
                            box-shadow: inset 0 0 8em 1em rgba(0, 0, 0, 0.2);
                            transform: scale3d(1.02, 1.02, 1.02);
                        }
                    }
                }
                .logo-box {
                    display: none;
                }

                .matching-tag,
                .feed-content-type {
                    font-size: 0.75em;
                    line-height: normal;
                    display: inline-block !important;
                }
                .short {
                    display: none !important;
                }
                .image {
                    height: 200px;
                    min-height: 200px;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        height: 250px;
                        min-height: 250px;
                    }
                }
                .desc-col,
                .content {
                    position: relative;
                    left: auto;
                    right: auto;
                    top: auto;
                    bottom: auto;
                    font-size: 1em !important;
                    padding: 25px !important;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        padding: 5px 0 0 20px !important;
                    }
                    min-height: 180px;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        min-height: auto;
                    }
                }
                .image-wrapper,
                .img-col,
                .content,
                .desc-col {
                    width: 60% !important;
                    float: left;
                    padding: 0;
                }

                .image-wrapper,
                .img-col {
                    width: 40% !important;
                    .image {
                        height: 100%;
                        min-height: 180px;
                        ${(p) => p.theme.breakpoints.down('xs')} {
                            min-height: 90px;
                            max-height: 90px;
                        }
                    }
                }

                .desc-col {
                    padding: 25px;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        padding: 10px 25px;
                        font-size: 0.8em;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        font-size: 1.2em;
                    }
                }
                .container {
                    > div:first-child {
                        display: none;
                    }
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .related-feed-item.even {
            ${(p) => p.theme.breakpoints.down('md')} {
                .image-wrapper,
                .img-col {
                    float: right;
                }

                .feed-item .desc-col,
                .feed-item .content {
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        padding: 5px 20px 0 0 !important;
                    }
                }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                .image-wrapper,
                .img-col {
                    float: right;
                }

                .feed-item .desc-col,
                .feed-item .content {
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        padding: 5px 20px 0 0 !important;
                    }
                }
            }
        }
    }
`;

export interface RelatedArticlesProps {
    feedTag: string;
    cmsLoading: boolean;
    contentType?: string;
    urlPattern: string;
}
export const RelatedArticles = ({
    feedTag,
    contentType,
    cmsLoading,
    urlPattern
}: RelatedArticlesProps): JSX.Element => {
    const getMessage = useLocalization();
    const tags = {};
    const types = {};

    if (feedTag) {
        const parts = feedTag.split(',');
        if (parts && parts.length) {
            parts.forEach((part) => {
                tags[part] = true;
            });
        } else {
            tags[feedTag] = true;
        }
    }

    if (contentType) {
        types[contentType] = true;
    }

    const {items: newsItems, loading: loading} = useFeedItems(
        {tags, types, patternsToSkip: [urlPattern]},
        cmsLoading
    );

    return (
        <RelatedArticlesWrapper className="related-articles-wrapper">
            <SectionWrapper>
                <div className="container">
                    {!loading && newsItems.length ? (
                        <span className="like-this">
                            {getMessage('epic.ue.spotlight.likethis')}
                        </span>
                    ) : (
                        ''
                    )}
                </div>
                <div className="news container">
                    {loading ? (
                        <UnrealLoading />
                    ) : (
                        <div>
                            <GridReusableRow items={newsItems} />
                        </div>
                    )}
                </div>
            </SectionWrapper>
        </RelatedArticlesWrapper>
    );
};
