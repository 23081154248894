import {FeedStoreProps, FeedItemProps} from '../types';

/**
 * Loop through the feeditems starting at a given index for a normal sized item which hasn't been used yet
 * @param  {Array} items
 * @param  {int} index
 * @return {Object}
 */
export function getNextNormalSize(items: FeedItemProps[], index: number): FeedItemProps | null {
    for (let i = index; i < items.length; i++) {
        const item = items[i];
        if (item && !item.__moved && item._feedLayout && item._feedLayout.size === 'normal') {
            item.__moved = true;
            return item;
        }
    }
    return null;
}

/**
 * Function used to update metadata on each item which has been accounted for within a feed row
 * @param {Array} rows
 * @param {Array} row
 */
export function addToRow(rows: FeedItemProps[][], row: FeedItemProps[]): void {
    if (!rows || !row || !row.length) return;
    row.forEach(item => {
        item.__added = true;
    });
    rows.push(row);
}

function resetRowMetaData(items: FeedItemProps[]) {
    if (!items || !items.length) return;
    items.forEach(item => {
        item.__added = undefined;
        item.__moved = undefined;
    });
}

/**
 * Group together and organize the feed items so they can be rendered without worry of blank spots. State is
 * immutable if updating the feed, but when bootstrapping it will be a plain object
 * @param  {Array} feedItems
 * @return {Object}
 */
export function aggregateRows(feedItems: FeedItemProps[]): FeedItemProps[][] {
    const items = getUniqueItems(feedItems || []);
    resetRowMetaData(items);

    const rows: FeedItemProps[][] = [];
    let tempRow: FeedItemProps[] = []; //holds the items until a full row is made
    if (items && items.length && items[0]) {
        let sixyFourty = true; //alternate 33 and 60/40 rows
        let triedNormalItem = false;

        //first row will always be the full width first item
        addToRow(rows, [items[0]]);

        for (let i = 1; i < items.length; i++) {
            const item: FeedItemProps = items[i];

            if (!item.__added && !item.__moved) {
                if (tempRow.length === 0 && item._feedLayout && item._feedLayout.size === 'full') {
                    //new row is success
                    addToRow(rows, [item]);
                } else if (
                    tempRow.length > 0 &&
                    item._feedLayout &&
                    item._feedLayout.size === 'full'
                ) {
                    //found success while building a row
                    const normalItem = getNextNormalSize(items, i);
                    if (normalItem) {
                        tempRow.push(normalItem);
                    }

                    //able to pull a normalitem so add the spotlight
                    if (tempRow.length > 1) {
                        addToRow(rows, [item]);
                    }

                    if (!triedNormalItem) {
                        i--; //repeat this until tempRow is completed
                        triedNormalItem = true;
                    }
                } else {
                    tempRow.push(item);
                }

                //check if the row is completed
                if ((sixyFourty && tempRow.length === 2) || (!sixyFourty && tempRow.length === 3)) {
                    addToRow(rows, tempRow);
                    tempRow = [];
                    sixyFourty = !sixyFourty;
                }
            }
        }
    }

    if (tempRow.length) {
        //there is some left over and not placed in a row
        addToRow(rows, tempRow);
        tempRow = [];
    }

    return rows;
}

export function getUniqueItems(items: FeedItemProps[]): FeedItemProps[] {
    const map = {};
    const unique: FeedItemProps[] = [];
    items.forEach(item => {
        if (item && item._urlPattern && !map[item._urlPattern]) {
            unique.push(item);
            map[item._urlPattern] = true;
        }
    });
    return unique;
}

export function processFeedResponse(
    feedResponse?: FeedStoreProps,
    prevFeedState?: FeedStoreProps
): FeedStoreProps {
    const res = feedResponse || {} as FeedStoreProps;
    const feedItems = res.items || [];
    const rows = aggregateRows(feedItems);
    res.rows = rows;
    const feedStore = Object.assign(prevFeedState || {}, feedResponse);
    return feedStore;
}
