import React from 'react';
import {useNavigate} from 'react-router-dom';
import {getDataAttribute} from '@epic-core/common';
import {generateRoutePath} from '@epic-core/common';
import {getBlogPost} from '../../utils/BlogDetailUtils';
import {TagsWrapper} from './Tags.styles';
import {BlogPostProps} from 'epic-ue-shared';

interface TagsProps {
    blog: any;
    localizedTags: {
        [key: string]: any;
    };
}

const Tags = ({blog, localizedTags = {}}: TagsProps): JSX.Element => {
    const navigate = useNavigate();

    const onClick = (e) => {
        e.preventDefault();
        const href = getDataAttribute(e.currentTarget, 'path');
        if (href) {
            navigate(href);
        }
    };

    const post = (getBlogPost(blog) || {}) as BlogPostProps;
    const blogTagsStr = post.tags || '';
    let tagArray = blogTagsStr.split(',');

    //sort tags alphabetically ignoring case
    tagArray = tagArray.sort((a, b) => {
        const tagA = a.replace(/^\s/, '').toLowerCase();
        const tagB = b.replace(/^\s/, '').toLowerCase();
        if (tagA < tagB) return -1;
        if (tagA > tagB) return 1;
        return 0;
    });

    const renderTags = tagArray.map((rawTag) => {
        const tag = rawTag.trim();
        if (!tag) return null;
        const localizedTag = (localizedTags && localizedTags[tag]) || tag;
        const path = generateRoutePath(`/feed/all/${encodeURIComponent(tag)}`);

        //TON-32323
        let tagStr = localizedTag;
        if (tag && tag.length === 2) {
            tagStr = tag.toUpperCase();
        }

        return (
            <a className="tag-link" href={path} data-path={path} key={tag} onClick={onClick}>
                <p className="tag">{tagStr}</p>
            </a>
        );
    });

    return <TagsWrapper className="col-xs-12">{renderTags}</TagsWrapper>;
};

export default Tags;
