import React from 'react';

import {PathMatch, useLocation, useMatch, useParams, Params} from 'react-router-dom';
import Header from '../components/events/Header/Header';
import EnterpriseHighlightsPanel from '../components/enterprise/EnterpriseHighlightsPanel';
import CustomerTalksPanel from '../components/events/customerTalks/CustomerTalksPanel';
import SpeakersPanel from '../components/events/Speakers/SpeakersPanel';
import PartnersPanel from '../components/events/Partners/PartnersPanel';
import {Helmet} from 'react-helmet';
import {
    getMetaTags,
    getHelmetForMetaTags,
    getUrlPattern,
    PROGRAM_TEMPLATE_NAME,
    AppSharedKeys,
    RouteMatchOrUrlParse
} from 'epic-ue-shared';
import {useLocalization} from '@epic-mw/localization';
import BlogPage from '../pages/BlogPage';
import {getLocale} from '@epic-core/common';

import {useFeedStoreItem, useMultiCmsData, cmsApi, HighlightPanelProps} from '../shared';
import {Page, Tag} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {convertCmsMultiToHighlights} from '../utils';
import styled from 'styled-components';
import {getMessageFnType} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const rootPageSlug = 'programs';

const getSlug = (match: PathMatch | null, params: Params) => {
    const slug = params.slug || params.title || params.typeSlug;
    const urlPattern = `/${rootPageSlug}/${slug}`;
    return {urlPattern, slug};
};

const Programview = styled.div`
    .events-tag-container {
        padding: ${(p) => p.theme.spacing(2)}px 0;
        a {
            display: inline-block;
            margin-right: ${(p) => p.theme.spacing(1)}px;
        }
        .tag-item.default {
            border-radius: 150px;
            margin-bottom: 0;

            div {
                background: ${(p) => p.theme.palette.background.paper};
                border-radius: 150px;
            }
        }
    }
    .enterprise-intro {
        background-size: cover !important;

        .intro-date,
        .intro-location,
        .intro-title-enterprise,
        .intro-text,
        .intro-action {
            z-index: 10;
        }

        &:before {
            z-index: 1;
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            background: linear-gradient(0deg, #0f1129 0%, rgba(0, 0, 0, 0) 100%);
            left: 0;
        }
    }

    &.enterprise {
        font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
        color: ${(p) => p.theme.palette.text.primary};

        .grid-news-cell .grid-news-item {
            border: none;
        }

        .blog-wrapper {
            padding-bottom: 20px;
        }
        .block-title {
            padding: 30px;
            color: ${(p) => p.theme.palette.text.primary};
        }
        .block-speakers-item {
            .block-speakers-info {
                color: ${(p) => p.theme.palette.text.primary};
            }
            .block-speakers-name,
            .block-speakers-text {
                color: ${(p) => p.theme.palette.text.secondary};
            }
        }

        .slick-dots {
            text-align: center;
            li {
                &:only-child {
                    display: none;
                }
                margin: 0;
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                padding: 0;
                cursor: pointer;
                &.slick-active {
                    button:before {
                        opacity: 0.8;
                    }
                }
                button {
                    font-size: 0;
                    line-height: 0;
                    display: block;
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                    cursor: pointer;
                    color: transparent;
                    border: 0;
                    outline: none;
                    background: transparent;
                    &:before {
                        font-family: slick;
                        font-size: 10px;
                        line-height: 20px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        content: '•';
                        text-align: center;
                        opacity: 0.25;
                        color: black;
                        font-smoothing: antialiased;
                    }
                }
            }
        }
        .slick-slider {
            .slick-arrow {
                width: 3em;
                height: 3em;
                vertical-align: middle;
                margin: 0;
                transition: opacity 0.3s ease-in-out;
                cursor: pointer;
                &:before {
                    content: '';
                    position: absolute;
                    top: 1.1em;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    display: inline-block;
                    width: 0.75em;
                    height: 0.75em;
                    transform: rotate(45deg);
                }
                &.slick-next:before {
                    border-top: 1px solid #000;
                    border-right: 1px solid #000;
                    right: -30px;
                }
                &.slick-prev:before {
                    border-left: 1px solid #000;
                    border-bottom: 1px solid #000;
                    left: -30px;
                }
            }

            .slick-prev {
                left: -3em;
                @media only screen and (max-width: 1399px) {
                    height: 50px;
                    display: block;
                    top: -18px !important;
                    left: 0;
                }
            }
            .slick-next {
                right: -3em;
                @media only screen and (max-width: 1399px) {
                    height: 50px;
                    display: block;
                    top: -18px !important;
                    right: 0;
                }
            }
        }

        .center {
            text-align: center;
        }
    }
`;

const ProgramPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const params = useParams();
    const {urlPattern} = getSlug(match, params);

    const {cmsLoading, cmsLoadedEmpty, feedItem, isBlogDetails} = useFeedStoreItem(urlPattern);
    const getMessage = useLocalization() as getMessageFnType;
    const program = feedItem || {};

    const highlightPanel = program.highlightPanel || {};
    const highlightList = highlightPanel.highlights || [];
    const highlightRefs: string[] = [];
    highlightList.forEach((highlightWrap) => {
        if (highlightWrap && highlightWrap.highlight) {
            highlightRefs.push(highlightWrap.highlight);
        }
    });
    const {pageData: allPageData, cmsLoading: highlightsLoading} = useMultiCmsData(
        [],
        highlightRefs
    );

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (isBlogDetails) {
        return <BlogPage rootPageSlug={rootPageSlug} blog={feedItem} match={match} />;
    }

    const highlights = convertCmsMultiToHighlights(allPageData as unknown as HighlightPanelProps);
    const schedule = program.schedule || {};
    const programHeader = program.header || {};
    const {startDate, endDate} = programHeader;
    const shareImage = program.shareImage || programHeader.backgroundImageURL;
    const locale = getLocale();
    const tagStr = program.tags || '';
    const tagArray = tagStr.split(',');

    const programsTitle = getMessage('epic.ue.programs.social.meta.title');
    const tags = getMetaTags(
        getMessage,
        programHeader.title ? `${programsTitle} | ${programHeader.title}` : programsTitle,
        programHeader.body,
        shareImage
    );

    return (
        <Page>
            <Programview className="enterprise">
                <Helmet meta={tags} title={programsTitle} />
                {getHelmetForMetaTags(program)}

                <Header
                    backgroundImageURL={programHeader.backgroundImageURL}
                    title={programHeader.title}
                    body={programHeader.short}
                    locationStr={programHeader.location || ''}
                    buttonText={getMessage('epic.enterprise.header.register.button')}
                    targetURL={programHeader.targetURL}
                    linkText={programHeader.linkText}
                    targetIsExternal={programHeader.targetIsExternal}
                    startDate={
                        startDate && startDate.toISOString ? startDate.toISOString() : startDate
                    }
                    endDate={endDate && endDate.toISOString ? endDate.toISOString() : endDate}
                    locale={locale}
                />

                <div className="events-tag-container container">
                    {tagArray.length &&
                        tagArray.map((tagItem, index) => {
                            if (tagItem) {
                                const lower = tagItem.toLowerCase();
                                return (
                                    <Tag
                                        key={`tag-${index}`}
                                        type="default"
                                        content={tagItem}
                                        url={`/feed/all/${lower}`}
                                        rel="nofollow"
                                    />
                                );
                            }
                        })}
                </div>

                {highlightPanel.showPanel && highlightsLoading ? (
                    <UnrealLoading fillViewHeight={false} />
                ) : (
                    ''
                )}
                {highlightPanel.showPanel && !highlightsLoading ? (
                    <EnterpriseHighlightsPanel
                        title={highlightPanel.title}
                        firstFeatured={highlightPanel.firstFeatured}
                        highlights={highlights || []}
                        locale={locale}
                        isNewsletterCard={false}
                        isNewsletterInHighlights={false}
                    />
                ) : null}
                {program.customerTalks && program.customerTalks.showPanel ? (
                    <CustomerTalksPanel
                        headline={program.customerTalks.headline}
                        talks={program.customerTalks.talks || []}
                        locale={locale}
                    />
                ) : null}
                {schedule.showPanel ? <SpeakersPanel {...schedule} /> : null}

                {program.partnerInformation && program.partnerInformation.showPanel ? (
                    <PartnersPanel {...program.partnerInformation} />
                ) : null}
            </Programview>
        </Page>
    );
};

ProgramPage.getInitialSharedValues = async (match: RouteMatchOrUrlParse): Promise<any> => {
    const urlPattern = getUrlPattern(match);
    const pageData = await cmsApi.getPageData(urlPattern);

    const responses = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [urlPattern]: pageData
            }
        }
    ];

    if (pageData._templateName === PROGRAM_TEMPLATE_NAME) {
        const highlightPanel = pageData.highlightPanel || {};
        const highlightList = highlightPanel.highlights || [];
        const highlightRefs: string[] = [];
        highlightList.forEach((highlightWrap) => {
            if (highlightWrap && highlightWrap.highlight) {
                highlightRefs.push(highlightWrap.highlight);
            }
        });

        const bulkResponse = (await cmsApi.getBulkPageData({ids: highlightRefs})) || {};
        const ids = bulkResponse.ids || {};
        for (const id in ids) {
            if (Object.prototype.hasOwnProperty.call(ids, id)) {
                const pageRes = ids[id];
                responses.push({
                    key: AppSharedKeys.CmsStore,
                    value: {
                        [id]: pageRes
                    }
                });
            }
        }
    }

    return responses;
};

export default ProgramPage;
