import {useState, useEffect} from 'react';
import {useSharedValue} from '@epic-core/hooks';
import {academicPartnersApi} from './academicPartners.api';
import {AppSharedKeys} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';

export interface Coordinates {
    lat: number;
    lng: number;
}

export interface AcademicPartner {
    BillingCountry: string;
    BillingStreet: string;
    BillingCity: string;
    Program_Name__c: string;
    Program_Type__c: string;
    ProgramTypes?: string[];
    Website: string;
    attributes?: any;
    BillingPostalCode: string;
    Id: string;
    BillingState: string;
    Name: string;
    coordinates?: Coordinates | null;
}

export interface AcademicPartners {
    elements: Array<AcademicPartner>;
    paging: {
        count: number;
        start: number;
    };
}

export interface AcademicPartnersData {
    partners: AcademicPartners;
    styles: Array<any>;
    loading?: boolean;
    loaded?: boolean;
    error?: string;
}

export const useAcademicPartners = (): AcademicPartnersData => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [academicPartners, setAcademicPartners] = useSharedValue(
        AppSharedKeys.AcademicPartnersStore
    );
    const {partners, styles}: AcademicPartnersData = academicPartners;

    useEffect(() => {
        if ((partners && partners.elements && !loadingState.error) || loadingState.loading) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = await academicPartnersApi.getData();
                setAcademicPartners(Object.assign({}, data));
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch academic partners data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    });

    return {
        partners,
        styles,
        ...loadingState
    };
};
