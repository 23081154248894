import styled from 'styled-components';

export const FeedFilterWrapper = styled.div`
    width: 100%;
    height: 50px;
    margin: 0;
    background-color: #383b50;
    position: relative;
    color: #f5f5f5;
    z-index: 88;
    padding: 4px 150px 0;
    ${(p) => p.theme.breakpoints.down('lg')} {
        padding: 0 100px;
    }
    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 0 40px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 0 15px 50px;
        height: auto;
        min-height: 130px;
        text-align: center;
    }
    @media screen and (min-width: 700px) and (max-width: 1000px) and (orientation: landscape) {
        padding: 0 55px;
    }

    &.no-header {
        margin-bottom: 50px;
    }

    .filter-content-type-wrapper {
        margin-right: 0;
    }
    .filter-search-box {
        float: right;
        margin: 5px 0 0 0;
        padding: 0;
        position: relative;
        width: 250px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            float: none;
            margin: 5px auto 0;
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            width: 275px;
        }
        @media screen and (min-width: 700px) and (max-width: 1024px) and (orientation: landscape) {
            width: 200px;
        }
    }

    &.no-transition {
        .filter-tag-item-wrapper a {
            transition: none;
        }
        li {
            .button:after,
            ul,
            ul li a {
                transition: none;
            }
        }
    }

    .filter-tag-item-wrapper {
        top: 0px;
        margin-top: 5px;
        margin-bottom: 10px;
        display: inline-block;
        margin-left: 8px;
        position: relative;
        cursor: pointer;
        &:hover {
            span {
                color: ${(p) =>
                    p.theme.ifLight(p, p.theme.palette.background.paper, 'inherit')} !important;
            }
        }
        span {
            padding: 5px 20px 5px 10px;
            cursor: pointer;
            font-size: 0.875em;
            text-transform: capitalize;
            background-color: ${(p) => p.theme.palette.background.paper};
            border-radius: 2px;
            transition: opacity 0.1s ease-in-out 0s;
            color: #fff !important;
            font-size: 1em;
            display: block;
            pointer-events: none;
            &:after {
                content: '\\e904';
                font-family: ${(p) => p.theme.ue.fonts.icomoon};
                position: absolute;
                top: 3px;
                right: 4px;
                color: #4a4a4a;
                font-size: 0.6em;
                font-weight: bold;
                pointer-events: none;
            }
        }
    }
    ul {
        display: inline-block;
        margin: 0;
        padding: 0;
        ${(p) => p.theme.breakpoints.down('sm')} {
            display: block;
            margin: 5px auto 10px;
            position: relative;
            z-index: 88;
            width: 275px;
        }
        &.tags {
            ${(p) => p.theme.breakpoints.down('sm')} {
                z-index: 89;
            }
            .curated-list {
                margin-left: 15px;
                @media screen and (min-width: 700px) and (max-width: 1024px) and (orientation: landscape) {
                    margin-left: 0;
                }
                ${(p) => p.theme.breakpoints.down('sm')} {
                    margin: 0;
                }
                a {
                    margin: 0 0 5px 5px;
                    display: block;
                }
            }
            ul {
                width: 275px;
            }
        }
        &.types {
            ${(p) => p.theme.breakpoints.down('sm')} {
                z-index: 90;
                padding-top: 15px;
            }
            ul > li {
                text-align: center;
            }
        }
        &.selected-tags {
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 5px auto 20px;
            }
        }
        li {
            margin-left: -1rem;
            margin-right: 1rem;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 0 auto;
            }
            list-style: none;
            position: relative;
            button.close-type {
                margin: 5px 0 0 -6px;
                border: 0;
                width: 25px;
                padding: 5px 5px 5px 5px;
                border-left: 1px solid #ffffff24;
                cursor: pointer;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    position: absolute;
                    right: 35px;
                    top: 0px;
                    border: 0;
                }
                i:before {
                    color: white;
                    position: relative;
                    font-size: 0.6em;
                    top: -2px;
                    right: 0px;
                }
            }
            button {
                margin: 5px 0 0 0;
                border: 0;
                color: #f5f5f5;
                padding: 5px 35px 5px 15px;
                display: inline-block;
                background: ${(p) => p.theme.palette.background.paper};
                border-radius: 5px;
                text-transform: capitalize;
                outline: none;
                position: relative;
                border-bottom: 2px solid ${(p) => p.theme.palette.background.paper};
                cursor: pointer;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    width: 275px;
                    margin: 5px auto;
                    display: block;
                    padding: 10px 35px 10px 15px;
                }
                &.carot:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid #f5f5f5;
                    transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
                    transition-delay: 0.05s;
                    position: absolute;
                    right: 8px;
                    top: 15px;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        top: 20px;
                    }
                }

                &.open {
                    background: ${(p) => p.theme.palette.background.paper};!important;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        z-index: 90;
                    }
                    &.carot:after {
                        transform: rotate(180deg);
                    }
                }
            }
            ul {
                position: absolute;
                left: 0;
                right: 0;
                top: 39px;
                background: ${(p) => p.theme.palette.background.paper};
                padding: 20px;
                width: 250px;
                transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition-delay: 0.05s;
                transform: scaleY(0);
                transform-origin: top center;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    width: 275px;
                }
                &.open {
                    display: block;
                    transform: scaleY(1);
                }
                li {
                    list-style: none;
                    a {
                        color: #e0e0e0;
                        text-decoration: none;
                        transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
                        display: block;
                        &:hover {
                            color: #fff;
                        }
                        &.feed-content-type {
                            width: 150px;
                            text-align: center;
                            margin: 0 auto 10px;
                            padding: 5px 0;
                            border-radius: 20px;
                        }
                    }

                    &.heading {
                        color: #848282;
                        border-bottom: 1px solid #848282;
                        margin-bottom: 15px;
                        h3 {
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
`;
