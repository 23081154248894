import {createGlobalStyle} from 'styled-components';

export const BackgroundVideoStyles = createGlobalStyle`
    .video,
    .background-video {
        position: relative;

        &.video {
            position: relative;
            z-index: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: black;
            background-size: cover;

            &.fixed {
                top: 0;
                left: 0;
                position: fixed;
            }

            &.absolute {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }

            .overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(black, 0.25);
                z-index: 1;
            }

            .screen {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                z-index: 0;
                margin: auto;

                @media only screen and (min-width: 768px) {
                    top: 0 !important;
                    min-height: 100%;
                }

                .bkImage {
                    background-size: cover !important;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .play-pause-overlay {
        height: 100%;
        position: absolute;
        z-index: 1;
        background: rgba(black, 0.25);
        opacity: 0;
        text-align: center;
        width: 100%;
        top: 0;
        cursor: pointer;
        bottom: 0;
        pointer-events: none;

        &.show {
            opacity: 1 !important;
            pointer-events: auto;
        }

        .play-pause-btn {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.85;
            color: #ffffff;
            z-index: 2;
            letter-spacing: 2px;
            text-transform: uppercase;
            transition: opacity 0.3s ease-in-out;

            h4 {
                margin: -1.75em 0 0 0;
                transition: color 0.3s ease-in-out;
                text-shadow: 0 2px 20px #333333;
            }

            a {
                font-size: 6em;
                color: #ffffff;
                display: block;
                text-shadow: 0 2px 20px #333333;
            }
        }

        &:hover {
            .play-pause-btn {
                opacity: 1;
            }

            a,
            h4 {
                color: #7EC0EE;
            }
        }
    }
`;
