import styled from 'styled-components';

export const ParagonCardsWrapper = styled.div`
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: auto;
        padding-bottom: 50px;
    }
    &.section-cards.paragon-section {
        color: #000;
        text-align: center;
        min-height: 500px;
        height: auto;
        padding-bottom: 100px;
        .content {
            position: relative;
            bottom: 0;
            max-width: 1000px;
            margin: 50px auto 0;
            ${(p) => p.theme.breakpoints.down('xl')} {
                max-width: 1600px;
            }
            .h2 {
                margin: 40px 0;
                font-size: 2.5em;
                font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
            }
            .paragon-card-item {
                margin: 50px auto;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    margin: 20px;
                }
                display: inline-block;
                .paragon-card {
                    margin: 0 15px;
                    border-radius: 20px;
                    transform-style: preserve-3d;
                    -webkit-tap-highlight-color: rgba(#000, 0);
                    position: relative;
                    width: 275px;
                    height: 400px;
                    &:focus,
                    &:visited,
                    &:active {
                        outline: none;
                    }
                    &.anim-disabled {
                        .paragon-card-container {
                            transform: none !important;
                        }
                        .paragon-card-shine,
                        .paragon-card-shadow {
                            display: none;
                        }
                    }

                    @media only screen and (max-width: 1300px) {
                        width: 200px;
                        height: 300px;
                    }
                    ${(p) => p.theme.breakpoints.down('xl')} {
                        width: 275px;
                        height: 400px;
                    }
                    ${(p) => p.theme.breakpoints.down('md')} {
                        width: 275px;
                        height: 350px;
                        margin: 0 0 10px 0;
                    }
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        width: 200px;
                        height: 300px;
                    }
                    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
                        width: 275px;
                        height: 350px;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        width: 175px;
                        height: 275px;
                    }
                    .paragon-card-container {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        transition: all 0.2s ease-out;
                    }
                    .paragon-card-shadow {
                        position: absolute;
                        top: 5%;
                        left: 5%;
                        width: 90%;
                        height: 90%;
                        transition: all 0.2s ease-out;
                        box-shadow: 0 8px 30px rgba(14, 21, 47, 0.6);
                        z-index: 0;
                    }
                    .paragon-card-layers {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        overflow: hidden;
                        transform-style: preserve-3d;
                        z-index: 2;
                    }
                    .paragon-card-rendered-layer {
                        position: absolute;
                        width: 104%;
                        height: 104%;
                        top: -2%;
                        left: -2%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: transparent;
                        background-size: cover;
                        transition: all 0.1s ease-out;
                        z-index: 4;
                    }
                    .paragon-card-shine {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        border-radius: 20px;
                        background: linear-gradient(
                            135deg,
                            rgba(255, 255, 255, 0.25) 0%,
                            rgba(255, 255, 255, 0) 60%
                        );
                        z-index: 8;
                    }
                    &:hover {
                        z-index: 9;
                        .paragon-card-shadow {
                            box-shadow: 0 45px 100px rgba(14, 21, 47, 0.4),
                                0 16px 40px rgba(14, 21, 47, 0.4);
                        }
                    }
                }
            }
        }
        &.inViewport {
            .desc {
                opacity: 1;
                transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
            li {
                opacity: 1;
                transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            }
        }
    }
`;
