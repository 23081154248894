import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import BgVideoWrapper from '../Header/BgVideoWrapper';
import {H2} from 'epic-ue-components';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';

const SectionCopy = styled.div`
    margin: 0 auto;
    ${p => p.theme.breakpoints.down('sm')} {
        font-size: 0.875em;
        max-width: 85%;
        margin: 0 auto;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 0.875em;
        max-width: 85%;
        margin: 0 auto;
    }
`;

const BgWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
    .screen {
        z-index: 1 !important;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        && {
            .screen {
                margin-top: 0;
            }
        }
    }
`;

const SectionWithBackground = styled.div`
    min-height: 50vh;
    text-align: center;
    ${BgWrapper},
    ${BgWrapper} > div {
        opacity: ${p => (p.opacity ? p.opacity : 1)};
    }

    ${BgWrapper} {
        .overlay {
            display: none;
        }
    }
    * {
        color: #fff;
    }
    ${p => p.theme.breakpoints.down('xxl')} {
        height: 400px;
    }
    ${p => p.theme.breakpoints.down('lg')} {
        height: 450px;
    }
    ${p => p.theme.breakpoints.down('sm')} {
        height: 350px;
    }
    ${p => p.theme.breakpoints.down('xs')} {
        .row > div > div > a {
            width: 260px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        height: 400px;
    }
    > div {
        min-height: 50vh;
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            height: 400px;
        }
    }

    h2 {
        font-family: Brutal_Light;
        font-size: 2em;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    /* Animations */
    > div:not(.inView) {
        h2,
        .col-xs-12 > div,
        .btn-wrapper {
            opacity: 0;
            transform: translateY(50px);
        }
    }

    > div.inView {
        h2,
        .btn-wrapper,
        > div {
            opacity: 1;
            transform: translateY(0);
            transition: opacity ease 0.4s 0.4s, transform ease 0.3s 0.3s;
        }
        .col-xs-12 > div {
            transition-delay: 0.5s;
        }
        .btn-wrapper {
            transition-delay: 0.6s;
        }
    }
`;

const BgContent = styled.div`
    padding: 40px 0;
`;

const SectionBackground = ({title, copy, opacity, buttonData, media = {}}) => {
    let copyElem = '';
    let buttons = '';

    let bgOpacity = 1;
    if (opacity !== undefined && opacity) {
        bgOpacity = opacity;
    }

    if (copy) {
        copyElem = <SectionCopy dangerouslySetInnerHTML={{__html: copy}} className="copy" />;
    }

    if (buttonData) {
        buttons = <CommonButtonWrapper sectionId="section-background" {...buttonData} />;
    }

    const textContentRow = (
        <div className="container">
            <div className="row">
                <BgContent className="col-xs-12">
                    {title ? <H2>{title}</H2> : ''}
                    {copyElem}
                    {buttons}
                </BgContent>
            </div>
        </div>
    );

    if (!media.type && !media.content && !title && !copy) return <div />;

    return (
        <SectionWithBackground opacity={bgOpacity}>
            <BgVideoWrapper background={media}>{textContentRow}</BgVideoWrapper>
        </SectionWithBackground>
    );
};

SectionBackground.propTypes = {
    title: pt.string,
    opacity: pt.oneOfType([pt.number, pt.string]),
    copy: pt.string,
    buttonData: pt.object,
    media: pt.object
};

export default SectionBackground;
