import React, {useEffect} from 'react';
import {Bio, H2} from '../../elements';
import {StaggerWrapper} from '../../animations';
import {getBowserState} from '@epic-core/common';
import VisibilitySensor from 'react-visibility-sensor';
import {BiosProps} from 'epic-ue-shared';

export const Bios = ({bios = [], title}: BiosProps): JSX.Element => {
    const bioAmount = bios.length;
    const {mobile} = getBowserState();
    const [inView, setInView] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const getGridClasses = React.useMemo(() => {
        const n = bioAmount;
        if (!n || n === 1) return 'col-xs-6';
        let ratio = Math.floor(12 / n);
        if (n === 4) {
            ratio = 6;
        }
        if (n === 6) {
            ratio = 4;
        }
        return `col-xs-6 col-sm-6 col-md-${Math.max(ratio, 4)}`;
    }, [bioAmount]);

    useEffect(() => {
        setHasLoaded(true);
    }, [hasLoaded, setHasLoaded]);

    const visChange = React.useCallback(
        isVisible => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    let biosList: (JSX.Element | undefined)[] = [<div key="bioList" />];

    if (bios) {
        biosList = bios.map((bio, index) => {
            return (
                <Bio
                    key={`bio-${bio.title}-${index}`}
                    title={bio.title || ''}
                    name={bio.name || ''}
                    position={bio.position || ''}
                    company={bio.company || ''}
                    avatar={bio.avatar || ''}
                    desc={bio.desc || ''}
                    url={bio.btnUrl || ''}
                    gridClasses={getGridClasses}
                    label={bio.btnLabel || ''}
                />
            );
        });
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <div className="bios-wrapper">
                <div className="title-container">
                    {title ? (
                        <div className="features-title">
                            <H2>{title}</H2>
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className="container">
                    <StaggerWrapper
                        childLength={biosList.length}
                        className={`equal ${hasLoaded ? 'hasLoaded' : ''} ${
                            inView ? 'inView' : ''
                        } ${mobile ? 'handheld' : ''}`}>
                        {biosList}
                    </StaggerWrapper>
                </div>
            </div>
        </VisibilitySensor>
    );
};
