import React, {Fragment} from 'react';
import {TopFeedItem} from '../item/top/TopFeedItem';
import {GridRow} from './GridRow';
import {FeedLoadingIndicator} from '../loading/FeedLoadingIndicator';
import root from 'window-or-global';
import {FeedItemProps} from '../../types';
import styled from 'styled-components';
import {VerticalSpacer} from 'epic-ue-ui';

export const GridLayoutWrapper = styled.div`
    .top-row {
        ${(p) => p.theme.breakpoints.up('xl')} {
            padding: 0 15px;
        }
        .feed-wrapper:first-child {
            transform: translateY(0);
            opacity: 1;
        }
    }
`;

interface Props {
    loading: boolean;
    rows: FeedItemProps[][];
}

export const GridLayout = (props: Props): JSX.Element => {
    const rows = props.rows || [];
    const firstRow = rows[0] || [];
    const topItem = firstRow[0] || {};
    let odd = true;

    if (!root.__server_side_render && props.loading) {
        return <FeedLoadingIndicator />;
    }

    return (
        <GridLayoutWrapper className="container">
            <div className="row top-row">
                <TopFeedItem item={topItem} />
            </div>
            {rows.slice(1, rows.length).map((row, i) => {
                const rowSize = row.length;
                const isLastRow = rowSize - 2 === i;

                if (rowSize === 2) {
                    odd = !odd; //alternate the 40 60 rows
                }

                return (
                    <Fragment key={`grid-row-${i}`}>
                        <GridRow
                            items={row}
                            odd={odd}
                            key={`feed-layout-${i}`}
                            isLastRow={isLastRow}
                        />
                        <VerticalSpacer variant="custom" spacing={3} />
                    </Fragment>
                );
            })}
        </GridLayoutWrapper>
    );
};
