import React, {useState, useEffect} from 'react';
import {H4} from 'epic-ue-components';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import {
    VideoPlayerContainer,
    VideoThumbnail,
    CloseButton,
    PopOutButton
} from './VideoPlayer.styles';
import {UnsupportedWatchButton} from 'epic-ue-ui';
import styled from 'styled-components';

const StyledUnsupported = styled.div`
    .unsupported-watch-btn {
        margin: 24px auto;
        display: block;

        a {
            display: flex;
            height: 80px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            button {
                padding: 20px 24px;
                width: 300px;
                display: block;
                text-align: center;

                div.container {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
`;

interface Properties {
    dirty: boolean;
    pinLabel: string;
    mute: boolean;
    mobile: boolean;
    tablet: boolean;
    pinPlayer: boolean;
    footerVisible: boolean;
    mainVideo: any;
}

export const VideoPlayer = ({
    dirty,
    pinLabel,
    mute,
    pinPlayer,
    footerVisible,
    mobile,
    tablet,
    mainVideo
}: Properties): JSX.Element => {
    const videoWrap = mainVideo;
    const [enabled, setEnabled] = useState(true);

    let disableAutoplay = false;

    if (videoWrap.video) {
        disableAutoplay = videoWrap.video.disableAutoplay;
    }

    useEffect(() => {
        if (videoWrap.video.disableAutoplay) {
            setEnabled(false);
        } else {
            setEnabled(true);
        }
    }, [videoWrap, setEnabled]);

    const unsupported = /(bilibili\.com|twitch\.tv)/g.test(videoWrap?.video?.embedSrc);
    if (unsupported) {
        return (
            <StyledUnsupported>
                <UnsupportedWatchButton src={videoWrap?.video?.embedSrc} />
            </StyledUnsupported>
        );
    }

    return (
        <VideoPlayerContainer
            className={`videoPlayerContainer ${mobile || tablet ? 'handheld' : ''} ${
                pinPlayer && enabled ? 'pinned' : ''
            } ${footerVisible ? 'footerOffset' : ''}`}
            key={`videoPlayer-${videoWrap.title}`}
            enabled={enabled}>
            <VideoThumbnail image={videoWrap.thumbnail} />
            <div className="video-player-wrapper">
                <BackgroundVideo
                    key={`mainVideo-${videoWrap.title}`}
                    background={videoWrap.video || {}}
                    play={!disableAutoplay}
                    mute={mute}
                    dirty={dirty}
                    controls={true}
                    ytControls={true}
                    ytAutohide={false}
                    playsInline={true}
                />
                {enabled && pinPlayer ? (
                    <CloseButton onClick={() => setEnabled(false)}>
                        <i className="icon-cross" />
                    </CloseButton>
                ) : (
                    ''
                )}
            </div>
            <div
                className={`video-player-title ${
                    mainVideo?.description ? 'hasVideoDescription' : ''
                }`}>
                <H4>{videoWrap.title}</H4>
                <div className="description">{videoWrap.description}</div>
                {!enabled ? (
                    <PopOutButton title={pinLabel} onClick={() => setEnabled(true)}>
                        <i className="icon-new-tab" />
                    </PopOutButton>
                ) : (
                    ''
                )}
            </div>
        </VideoPlayerContainer>
    );
};
