import React from 'react';
import {TagContainer, TagContent} from './Tag.styles';
import {getBowserState} from '@epic-core/common';
import {TagProps} from 'epic-ue-shared';

export const Tag = ({content, rel, type, url}: TagProps): JSX.Element | null => {
    const {mobile, tablet} = getBowserState();

    if (!content) return null;

    if (content && content.length === 2) {
        content.toUpperCase();
    }

    const tagContainer = (
        <TagContainer
            className={`tag-item ${type}
            ${mobile || tablet ? 'handheld' : ''} ${url ? 'has-link' : ''}`}>
            <TagContent>
                <div dangerouslySetInnerHTML={{__html: content}} />
            </TagContent>
        </TagContainer>
    );

    if (url) {
        return (
            <a href={url} rel={rel}>
                {tagContainer}
            </a>
        );
    }

    return tagContainer;
};
