import styled from 'styled-components';
import {
    ACCENT_COLOR,
    ACCENT_COLOR_HOVER,
    BLOG_TEXT,
    LOC_AR_FONT_BOLD,
    LOC_AR_FONT_REGULAR,
    LOC_ZH_CN_FONT,
    BACKGROUND_COLOR
} from '../../constants';

export const BlogDetailWrapper = styled.div`
    background: ${BACKGROUND_COLOR};

    &.blog-container {
        overflow-x: hidden;

        .container {
            padding: 0;
        }

        a {
            text-decoration: none;
            color: ${ACCENT_COLOR};

            &:hover {
                color: ${ACCENT_COLOR_HOVER};
            }
        }

        .blog-translate-message {
            width: 100%;
            background-color: #e5a300;
            color: #fff;
            padding: 5px;
            font-size: 16px;
        }

        .blog-header {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            height: 400px;
            position: relative;
            overflow: hidden;

            &.no-image {
                display: none;
            }

            &.image {
                @media (min-width: 480px) and (max-width: 1100px) {
                    height: 450px;
                }
                @media screen and (max-width: 480px) {
                    height: 300px !important;
                }
            }

            img.invisible-image {
                width: 100%;
                opacity: 0;
                position: absolute;
                pointer-events: none;

                ${(p) => p.theme.breakpoints.down('xs')} {
                    & {
                        height: 200px;
                        background-position: top;
                    }
                }

                .fallback {
                    display: none !important;
                }
            }
        }

        .lightbox-enabled {
            img {
                cursor: pointer;
                border: 1px solid transparent;
                transition: border 0.3s ease-in-out;

                &:hover {
                    border: 1px solid black;
                    transition: border 0.3s ease-in-out;
                }
            }
        }
        .lightbox {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.9);
            top: 0;
            left: 0;
            z-index: 100000;
            padding: 50px;
            text-align: center;

            &:before {
                display: inline-block;
                height: 100%;
                content: '';
                vertical-align: middle;
            }

            img {
                cursor: default;
                max-width: 100%;
                max-height: 100%;
            }

            button {
                background-color: transparent;
                border: 0;
                outline: none;

                &:focus {
                    outline: none;
                }

                color: #c5c5c5;
                position: absolute;
                top: calc(50% - 1.8em);
                padding: 15px 18px;
                font-size: 1.8em;

                &:hover {
                    color: #ddd;
                }

                &.prev {
                    left: 0;
                }

                &.next {
                    right: 0;
                }

                &.close {
                    right: 5px;
                    top: 5px;
                    opacity: 1;
                }
            }
        }
    }

    &.blog-container.no-image {
        overflow: hidden;
        background-position-y: 0;

        .blog-header {
            height: auto;

            .blog-header-info {
                text-shadow: none;
                color: ${BLOG_TEXT};
            }
        }
    }

    &.loc-ar.blog-container {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: ${LOC_AR_FONT_BOLD};
        }

        p,
        span {
            font-family: ${LOC_AR_FONT_REGULAR};
        }

        .blog-article {
            .blog-social {
                margin-left: 0;
                margin-right: -6em;

                ${(p) => p.theme.breakpoints.down('sm')} {
                    margin-right: 0;
                }
            }

            h1,
            .author {
                text-align: right;
            }

            .cms {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                span,
                img,
                li {
                    text-align: right;
                }

                li {
                    direction: rtl;
                }

                .footer {
                    clear: both;
                }
            }
        }
    }

    &.loc-zh_CN.blog-container .blog-article .blog-content {
        .blog-header-info {
            .blog-header-date,
            .blog-header-title,
            .blog-header-author {
                font-family: ${LOC_ZH_CN_FONT};
            }
        }

        .category-section .category,
        .cms,
        .cms p {
            font-family: ${LOC_ZH_CN_FONT};
        }
    }
    .blog-wrapper .btn,
    .blog-wrapper .btn-primary,
    .ReactModalPortal .btn,
    .ReactModalPortal .btn-primary § {
        font-family: ${LOC_ZH_CN_FONT};
    }

    footer {
        clear: both;
    }

    .hidden-scroll {
        overflow: hidden;
    }
`;

export const BlogArticleWrapper = styled.section`
    padding-top: 4em;
    @media only screen and (max-width: 767px) {
        padding-top: 2em;
        font-size: 0.9em;
    }
    .pull-quote {
        margin-bottom: 0;

        p {
            font-weight: 300;
            font-style: normal;
            font-size: 32px;
            margin: 0.8em 0 !important;
            line-height: 50px;
            text-transform: uppercase;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium} !important;
        }

        ${(p) => p.theme.breakpoints.down('xs')} {
            padding-top: 2em;
            font-size: 0.9em;
        }
    }
    .blog-content {
        transform: none;
        color: ${BLOG_TEXT};
        max-width: 1000px;
        margin: 0 auto 50px;
        min-height: 50vh;
        padding: 10px;

        .content-grid-row {
            clear: both;
            width: 100%;
            @media screen and (max-width: 1600px) {
                width: auto;
            }

            .content-toss-container {
                color: #000;

                a {
                    color: #000;
                }

                .content-toss {
                    margin: 0 auto;
                    @media screen and (max-width: 1300px) {
                        .sticky-post {
                            float: right;
                        }
                    }
                }
            }
        }

        .blog-header-info {
            margin-bottom: 0.75em;

            hr {
                border-color: ${(p) => p.theme.palette.background.defaultShade(0.8)};
                opacity: 1;
                margin: 0.5em 0;
            }

            .blog-header-title {
                font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
                font-size: 2.8em;
                line-height: 1;
                margin: 20px auto;

                ${(p) => p.theme.breakpoints.down('xs')} {
                    font-size: 2em;
                }
            }

            .blog-header-subtitle {
                font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
                padding-right: 0.5em;
                margin-right: 0.5em;
                font-size: 1em;
                border-right: 1px solid ${BLOG_TEXT};
            }

            .blog-header-date {
                font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
                font-size: 1em;
            }

            .blog-header-author {
                font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
                font-size: 1em;
                margin: 1em 0 1.25em 0;
            }
        }

        .category-section {
            margin: 15px auto;

            .section-title {
                font-size: 18px;
                margin-bottom: 0;
            }

            .category {
                margin-bottom: 0;
                font-size: 18px;

                &:hover {
                    color: #10b4f5 !important;
                }
            }
        }

        p {
            word-wrap: break-word;
        }

        article {
            clear: both;

            .author {
                .post-author {
                    font-style: italic;
                }
            }

            strong {
                font-family: OpenSans, sans-serif;
            }
        }
    }
    ${(p) => p.theme.breakpoints.down('md')} {
        margin-top: 0;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        h1.post-title {
            margin-top: 100px;
        }

        .content {
            padding: 0 20px;

            .socialLink {
                display: block;
                margin-top: 10px;
            }

            .cms {
                p:first-child {
                    margin-top: 10px;
                }
            }
        }
    }
    .recentPostContainer {
        margin: 5em auto 0;

        .recent-title {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.75em;
            font-weight: 700;
        }

        .content-toss-item {
            border: none;

            ${(p) => p.theme.breakpoints.down('sm')} {
                .image {
                    height: 200px;
                }
            }
        }

        .byline,
        .short {
            display: none;
        }
    }
    .content-grid-row {
        .content-toss-container {
            .content-toss {
                .content-toss-item {
                    .image {
                        margin-bottom: 1em;
                    }

                    .category a:hover {
                        color: darken(#10b4f5, 10%);
                    }

                    .headline a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .blog-content {
        .cmsContainer {
            position: relative;
        }
    }

    .cms {
        padding-top: 0.5em;
        font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
        font-size: 17px;
        margin: 17.5px auto;
        clear: both;

        .juxtapose {
            img {
                max-width: none;
                height: 100% !important;
                margin: 0;
            }

            .jx-knightlab {
                display: none;
            }
        }

        .embed-responsive {
            margin: 2em 0;
        }

        .cms-quote {
            text-transform: uppercase;
            float: right;
            width: 40%;
            padding: 0 0 5% 5%;
            margin: 0;
            color: #236394;
            font-size: 2em;
            clear: both;

            &:before {
                content: '"';
            }

            &:after {
                content: '"';
            }

            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 1.4em;
                padding: 0 0 5% 5%;
            }

            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 1em;
            }
        }

        .caption {
            margin: 0 0 2em !important;
            color: darken(${BLOG_TEXT}, 40%);
            font-size: 0.75em;
            background: #000;
            padding: 1em;
        }

        h1,
        h2 {
            font-weight: normal;
        }

        h1 {
            font-size: 2em;
            color: ${BLOG_TEXT};
            margin-bottom: 1em;

            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 32px;
            }
        }

        h2 {
            font-size: 1.4em;
        }

        h3 {
            font-size: 1em;
            font-weight: normal;
            font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
        }

        h4 {
            font-size: 1em;
            font-weight: normal;
        }

        img {
            max-width: 100%;
            height: auto !important;
            margin: 2em 0;
        }

        p {
            margin: 1em 0;
            line-height: 2;
            font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
            font-size: 17px;
            margin: 17.5px auto;
            @media screen and (max-width: 1000px) {
                text-align: center;
            }

            ${(p) => p.theme.breakpoints.down('xs')} {
                text-align: left;
            }

            a {
                color: ${ACCENT_COLOR} !important;
            }

            iframe {
                height: 40vh;
                width: 100%;
                @media screen and (max-width: 480px) {
                    height: 30vh;
                }
            }
        }

        ul,
        ol {
            padding-left: 20px;
        }

        li {
            list-style-type: disc;
            line-height: 2;
            font-size: 1em;

            ul {
                padding-left: 40px;
            }
        }

        a {
            text-decoration: none;
            color: ${ACCENT_COLOR};
            transition: color 0.3s ease-in-out;

            &:hover {
                color: ${ACCENT_COLOR}-hover;
            }
        }

        hr {
            opacity: 0.8;
            margin: 1em 0;
            border-top: 2px solid ${(p) => p.theme.palette.background.defaultShade(0.05)};
        }

        table {
            border: none;
            width: 100%;

            tr:first-child {
                text-align: center;

                &:first-child {
                    background-color: #ffffff;
                    color: #000;
                }
            }

            td {
                text-align: center;

                p {
                    margin: 0 !important;
                }
            }
        }

        .textile-preview {
            ul {
                margin-left: 1.5em;

                li {
                    list-style: disc;
                }
            }

            &.selected.selected {
                border: 1px solid ${ACCENT_COLOR};
            }
        }
    }

    ul {
        padding-left: 0;
    }

    .blog-content {
        .cmsContainer {
            position: relative;
        }

        .cms > p {
            color: ${BLOG_TEXT};

            * {
                color: ${BLOG_TEXT};
            }

            a {
                line-height: 1em;
                color: ${ACCENT_COLOR};

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }

    .ReactModalPortal {
        .delete-modal {
            .btn.btn-primary {
                display: inline-block;
                margin: 0.1em auto;
            }
        }
    }
`;
