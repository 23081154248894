import styled from 'styled-components';

export const MosaicAccordionWrapper = styled.div`
    @media (max-width: 992px) {
        .grid-news-item {
            margin: 0 13px 30px;
        }
        .grid-news-item.horizontal .grid-news-image {
            padding-right: 35px;
        }

        .grid-news-item:not(.horizontal):not(.vertical) {
            height: auto !important;
            padding-bottom: 20px;
        }
    }

    @media (min-width: 992px) {
        .grid-news-item.horizontal .grid-news-image {
            padding-right: unset;
        }
    }

    @media (min-width: 991px) and (max-width: 1068px) {
        .grid-news-item.vertical {
            height: 100% !important;
        }
    }

    @media (min-width: 992px) {
        .grid-news-item.vertical .grid-news-image {
            border-bottom: 1px solid #d7d7d7;
        }
    }
`;
