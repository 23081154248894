import React from 'react';
import styled from 'styled-components';

import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import IndustryPage from './IndustryPage';

const cmsUrlPattern = '/xr';

interface Properties {}

const Styled = styled.div`
    ${(p) => p.theme.breakpoints.down('sm')} {
        .card.handheld {
            margin-bottom: unset;
        }
    }
`;

const XrPage = (props: Properties): JSX.Element => {
    return <Styled><IndustryPage {...props} cmsUrlPattern={cmsUrlPattern} /></Styled>;
};

XrPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default XrPage;
