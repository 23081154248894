import React from 'react';
import PropTypes from 'prop-types';
import {VideoClassWrapper} from './VideoClass.styles';

export default class VideoClass extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        description: PropTypes.string,
        author: PropTypes.string,
        subCategory: PropTypes.string,
        image: PropTypes.string,
        length: PropTypes.string,
        hoverLabel: PropTypes.string,
        vimeoLink: PropTypes.string,
        downloadLink: PropTypes.string,
        downloadTxt: PropTypes.string,
        downloadDefaultTxt: PropTypes.string
    };

    render() {
        const {
            name,
            description,
            author,
            subCategory,
            image,
            length,
            vimeoLink,
            hoverLabel,
            downloadLink,
            downloadTxt,
            downloadDefaultTxt
        } = this.props;

        const imgStyle = {
            background: `url(${image}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '100%',
            width: '100%'
        };

        return (
            <VideoClassWrapper className="video-class col-md-4 col-xs-12">
                <a
                    href={vimeoLink}
                    className="video-class-img"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="image-bg" style={imgStyle} />
                    <span>{hoverLabel}</span>
                </a>
                <div className="video-class-content">
                    <a className="h2" href={vimeoLink} target="_blank" rel="noopener noreferrer">
                        {name}
                    </a>

                    {downloadLink && (
                        <a
                            className="download-link ue-cta-button"
                            href={downloadLink}
                            title={downloadLink}
                            target="_blank"
                            rel="noopener noreferrer">
                            <i className="icon-download" />
                            <span>{downloadTxt || downloadDefaultTxt}</span>
                        </a>
                    )}

                    <div className="desc" dangerouslySetInnerHTML={{__html: description}} />

                    <div className="video-class-details">
                        <div className="video-author">
                            <span>{author}</span>
                        </div>
                        <div className="video-length">
                            {length && (
                                <div className="inline">
                                    <i className="clock" /> {length} -{' '}
                                </div>
                            )}
                            <div className="video-category">{subCategory}</div>
                        </div>
                    </div>
                </div>
            </VideoClassWrapper>
        );
    }
}
