import root from 'window-or-global';
import {generateRoutePath, getBowserState} from '@epic-core/common';
import {
    FeedCurrentFilterProps,
    FeedItemProgramTemplateLeadIn,
    FeedItemProps,
    FilterStore
} from '../types';
import {AltLocPageData, FeedLogoProps, getMessageFnType} from 'epic-ue-shared';
import {MultiCardOptionsArray} from 'epic-ue-ui';
import {getStardustItem} from './stardustUtils';

const placeholder =
    'https://cdn2.unrealengine.com/unrealengine-blog-unrealengine-dark-logo-375x275-375x275-d6ab05a72057.jpg';
const largePlaceholder =
    'https://cdn2.unrealengine.com/unreal-engine-uefeatureimage-generic-1920x960-1920x960-1b94247774ca.png';

/**
 * Takes a url like /en-US/feed/all/enterprise+events and converts it into 2 separate maps for the filters
 * @param  {String} url
 * @return {Object}
 */

export function getFiltersFromUrl(url: string): FeedCurrentFilterProps {
    const types: null | FilterStore = {};
    const tags: null | FilterStore = {};

    if (!url || url.indexOf('/feed') === -1) return {types, tags};

    const parts = url.split('/feed/');

    if (parts.length > 1 && parts[1]) {
        const filter = parts[1];
        const filterParts = filter.split('/');
        if (filterParts[0]) {
            const typeParts = filterParts[0].split('+');
            typeParts.forEach((part) => {
                const cleanedType = decodeURIComponent(part)
                    .replace(/^\s+|\s+$|"/g, '')
                    .toLowerCase();
                types[cleanedType] = true;
            });
        }
        if (filterParts[1]) {
            const tagParts = filterParts[1].split('+');
            tagParts.forEach((part) => {
                const cleanedTag = decodeURIComponent(part)
                    .replace(/^\s+|\s+$|"/g, '')
                    .toLowerCase();
                tags[cleanedTag] = true;
            });
        }
    }

    delete types.all;
    delete tags.all;

    return {types, tags};
}

export function getSlugPath(item: FeedItemProps): string {
    const {_slug, _feedSlug} = item;
    return generateRoutePath(`${_feedSlug}/${_slug}`);
}

export function objectsDiffer(
    currentObj: {[key: string]: any} | null,
    newObj: {[key: string]: any} | null
): boolean {
    let parsedCurrent: string[] = [];
    let parsedNew: string[] = [];

    if (currentObj) {
        for (const tag in currentObj) {
            if (Object.hasOwnProperty.call(currentObj, tag) && currentObj[tag]) {
                parsedCurrent.push(tag);
            }
        }
    }

    if (newObj) {
        for (const tag in newObj) {
            if (Object.hasOwnProperty.call(newObj, tag) && newObj[tag]) {
                parsedNew.push(tag);
            }
        }
    }

    if (parsedCurrent.length !== parsedNew.length) return true;

    parsedCurrent = parsedCurrent.sort();
    parsedNew = parsedNew.sort();

    for (let i = 0; i < parsedNew.length; i++) {
        if (parsedNew[i].toLowerCase() !== parsedCurrent[i].toLowerCase()) {
            return true;
        }
    }

    return false;
}

export function filtersDiffer(
    currentFiltersProps: FeedCurrentFilterProps,
    newFiltersProps: FeedCurrentFilterProps
): boolean {
    const currentFilters = currentFiltersProps || ({} as FeedCurrentFilterProps);
    const newFilters = newFiltersProps || ({} as FeedCurrentFilterProps);
    const currentTags = currentFilters.tags;
    const newTags = newFilters.tags;
    const currentTypes = currentFilters.types;
    const newTypes = newFilters.types;
    return objectsDiffer(currentTags, newTags) || objectsDiffer(currentTypes, newTypes);
}

interface FeedImage {
    mobileImage: string;
    tabletImage: string;
    desktopImage: string;
}

const getFeedImage = (feedImages: FeedImage): string | null => {
    if (!feedImages) return null;
    const {mobile, tablet} = getBowserState();
    if (mobile) {
        return feedImages.mobileImage;
    }
    if (tablet) {
        return feedImages.tabletImage;
    }
    return feedImages.desktopImage;
};

export function getImageUrl(item: FeedItemProps, needLarge?: boolean): string {
    const {imageUrl: stardustImageUrl} = getStardustItem(item);
    if (stardustImageUrl) return stardustImageUrl;

    const {_feedSlug} = item;

    if (_feedSlug === 'trending') return '';

    const cmsImages = item._images_ || [];
    const firstCmsImage = cmsImages.length > 0 && cmsImages[0];
    const feedImage = getFeedImage(item.feedImages as FeedImage);

    if (feedImage) {
        return feedImage;
    }

    const article = item.article;
    if (article && typeof article === 'object') {
        if (article.feedImg) return article.feedImg;
    }

    const post = item.post;
    if (post && typeof post === 'object' && (post.image || post.trendingImage)) {
        if (_feedSlug === 'spotlights' || needLarge) {
            return post.trendingImage || post.image || firstCmsImage || largePlaceholder;
        }
        return post.image || post.trendingImage || firstCmsImage || placeholder;
    }

    const {mobile} = getBowserState();
    if (mobile && item.mobileImage) return item.mobileImage;
    if (item.image) return item.image;

    if (_feedSlug === 'events' || _feedSlug === 'programs') {
        const header = item.header || {};
        const bg = header.background || {};
        if (bg.type === 'image' && bg.content && bg.content.length) {
            return bg.content[0];
        }
        return feedImage || header.backgroundImageURL || firstCmsImage || placeholder;
    }

    if (firstCmsImage) return firstCmsImage;
    if (needLarge) return largePlaceholder;

    return placeholder;
}

export function getTitle(item: FeedItemProps, plainStringRequired?: boolean): string {
    const {title: stardustTitle} = getStardustItem(item);
    if (stardustTitle) return stardustTitle;

    const post = item.post;
    if (post && typeof post === 'object' && post.title) {
        return post.title;
    }

    if (plainStringRequired) {
        return (item.title || item._title) as string;
    }

    //feedTitle can be html
    const article = item.article;
    if (article && typeof article === 'object') {
        if (article.feedTitle) return article.feedTitle;
    }

    return (item.feedTitle || item.title || item._title) as string;
}

export function getShort(item: FeedItemProps): string {
    const {short: stardustShort} = getStardustItem(item);
    if (stardustShort) return stardustShort;

    const {_feedSlug} = item;

    const article = item.article;
    if (article && typeof article === 'object') {
        if (article.short) return article.short;
    }

    const post = item.post;
    if (post && typeof post === 'object') {
        if (post.feedDesc) return post.feedDesc;
        if (post.short) return post.short;
    }

    if (item.short) return item.short;

    if (_feedSlug === 'events' || _feedSlug === 'programs') {
        //new event template
        const leadIn = item.leadIn || ({} as FeedItemProgramTemplateLeadIn);
        if (leadIn.copy) return leadIn.copy;

        //old event template
        const header = item.header || {};
        return header.short as string;
    }

    return '';
}

export function getLogo(item: FeedItemProps) {
    const {logo: stardustLogo} = getStardustItem(item);
    if (stardustLogo) return stardustLogo;

    if (item.article && item.article.logo) {
        return item.article.logo || ({} as FeedLogoProps);
    }

    return item.logo || ({} as FeedLogoProps);
}

export function getTagMatch(item: FeedItemProps): string[] | boolean {
    const {_feedTagMatch, _feedTags} = item;
    if (!_feedTagMatch) return false;

    const tagMatchList = _feedTagMatch as string[];

    const tagMatches: string[] = [];
    for (let i = 0; i < _feedTags.length; i++) {
        const tag = _feedTags[i].replace(/^\s+|\s+$|"/g, '');
        const lowerTag = tag && tag.toLowerCase();
        if (tagMatchList.length && tagMatchList.indexOf(lowerTag) > -1) {
            tagMatches.push(tag);
        }
    }

    return tagMatches;
}

interface ParsedUrl {
    query: {
        page?: number | string;
    };
    pathname: string;
}

export function getPageFromParsedUrl(parsedUrl: ParsedUrl): number {
    const query = parsedUrl.query || {};
    return +(query.page || 1);
}

export function getAltLocPageDataCards(
    feedItem: AltLocPageData,
    pageSlug: string,
    getMessage: getMessageFnType
): MultiCardOptionsArray {
    const {altLocPageData} = feedItem;
    const altLocPageCards: MultiCardOptionsArray = [];
    if (!altLocPageData) return altLocPageCards;

    for (let i = 0; i < altLocPageData.length; i++) {
        const data = altLocPageData[i];
        if (!data) continue;

        const handleClick = (e) => {
            e.preventDefault();
            root.location.href = generateRoutePath(
                `${pageSlug}?lang=${data && data._locale}`,
                true
            );
        };

        altLocPageCards.push({
            text: {
                eyebrow: {
                    text: getMessage('epic.enterprise.landing.news.text')
                },
                title: data._title,
                link: {
                    href: generateRoutePath(`${pageSlug}?lang=${data._locale}`, true),
                    label: getMessage(`epic.error.alt.404.read.locale.${data._locale}`)
                },
                onClick: handleClick
            }
        });
    }
    return altLocPageCards;
}
