import React, {useCallback} from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Button, SimpleLink} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import {EpicLogo} from '../components/logo/EpicLogo';
import root from 'window-or-global';
import parse from 'url-parse';
import ErrorPage from './ErrorPage';

const cmsUrlPattern = '/auth';

const Auth = styled.div`
    background-color: ${(p) => p.theme.palette.background.primary};
    margin: 0;
    padding: 6em;

    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 2em;
    }
`;

const Box = styled.div`
    max-width: 500px;

    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 1em;
    }

    margin: 0 auto;
    min-height: 450px;
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.text.primary};
    padding: 2em;
    text-align: center;

    h2 {
        font-family: ${(p) => p.theme.ue.fonts.brutal.light};
        font-weight: 600;
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 16px;
    }

    h2 + p {
        margin-top: 0;
    }

    p {
        color: ${(p) => p.theme.palette.text.secondary};
        font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
        font-size: 14px;
    }

    &&& {
        a {
            color: ${(p) => p.theme.palette.accent.sky};
            font-size: 14px;
        }
        a.btn.btn-primary {
            color: #000;
            background-color: ${(p) => p.theme.palette.accent.sky};
            border-color: ${(p) => p.theme.palette.accent.sky};
            font-size: 13px;
            line-height: 16px;
            font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
            text-transform: uppercase;
            transition: background 0.3s ease-out;

            &:hover {
            }

            margin: 3em 0;
            margin-bottom: 1em;
        }
    }
`;

const loginHref = '/login';
const signupHref = '/register-account';

interface AuthPageData extends PageData {
    title: string;
    desc: string;
    btnLabel: string;
    signUpDesc: string;
    signUpLink: string;
    goBackLabel: string;
    logo: string;
}

const AuthPage = (): JSX.Element => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<AuthPageData>(cmsUrlPattern);

    const parsedUrl = parse(root.location.href, true);
    const origin = parsedUrl.origin || root.location.origin;
    const query = parsedUrl.query || {};
    const state = query.state || '';

    const onGoBackClick = useCallback(
        (e) => {
            e.preventDefault();
            if (state) {
                navigate(-2);
            } else {
                root.location.href = origin;
            }
        },
        [navigate, state, origin]
    );

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {title, desc, btnLabel, signUpLink, signUpDesc, goBackLabel, logo} = pageData;

    return (
        <Auth>
            {getHelmetForMetaTags(pageData)}
            <Box>
                <EpicLogo logo={logo} />
                <h2>{title}</h2>
                <p>{desc}</p>

                <Button
                    id="auth-login"
                    type="primary"
                    label={btnLabel}
                    as="a"
                    href={`${origin}${loginHref}${search}`}
                />

                <p>
                    <span>{signUpDesc}</span>{' '}
                    <span>
                        {' '}
                        <a href={`${origin}${signupHref}${search}`}>{signUpLink}</a>
                    </span>
                </p>

                <SimpleLink
                    id="go-back-link"
                    href={state}
                    onClick={onGoBackClick}
                    title={goBackLabel}
                />
            </Box>
        </Auth>
    );
};

AuthPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default AuthPage;
