export const isPathDisabled = (currentPath: string, disablePaths?: string[]) => {
    let disable = false;
    if (disablePaths && disablePaths?.length) {
        for (let i = 0; i < disablePaths?.length; i++) {
            if (currentPath.includes(disablePaths[i])) {
                disable = true;
            }
        }
    }
    return disable;
}
