import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useAccountStore,
    useCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import { UeThemeProvider, ueDarkTheme } from 'epic-ue-theme';
import { UnrealLoading } from 'epic-ue-loading';
import { BLOBS_LINUX_LIMIT, useLinuxBlobs } from '../../shared/blobs.hooks';
import { LinuxBlobsHeader, LinuxBlobsHeaderProps } from '../../components/linux/LinuxBlobsHeader';
import {
    Button,
    GridContainer,
    NewsletterSection,
    NewsletterSectionProps,
    VerticalSpacer
} from 'epic-ue-ui';
import { LinuxBlobsTable, LinuxTableLabels } from '../../components/linux/LinuxBlobsTable';

import { LinuxAccountCheck } from '../../components/linux/LinuxAccountCheck';
import { eulaKeyUnreal } from '../../constants';
import { useEulaAccepted } from '../../shared';
import { getBowserState } from '@epic-core/common';
import { ContextMessage } from '@epic-mw/localization';

const Styled = styled.div`
    min-height: 80vh;

    .show-more {
        text-align: center;
        width: 100%;
    }

    .content {
        min-height: 30vh;
    }
`;

const cmsUrlPattern = '/linux';

interface LinuxPageData extends PageData {
    header?: LinuxBlobsHeaderProps;
    showMore?: string;
    login?: string;
    requireLogin?: string;
    requireEula?: string;
    viewAccept?: string;
    labels?: LinuxTableLabels;
    newsletter?: NewsletterSectionProps;
}

const LinuxPage = (): JSX.Element => {
    const { account } = useAccountStore();
    const isLoggedIn = account.isLoggedIn || false;
    const { accepted } = useEulaAccepted(eulaKeyUnreal);

    const { cmsLoading, cmsLoadedEmpty, pageData } = useCmsData<LinuxPageData>(cmsUrlPattern);

    const { mobile, tablet } = getBowserState();

    const [limit, setLimit] = useState(BLOBS_LINUX_LIMIT);
    const { blobs, isLoading, error } = useLinuxBlobs({ start: 0, limit, isLoggedIn });
    if (error) {
        console.log('LinuxPage error returned from fetching linux blobs', error);
    }

    const showMore = useCallback(() => {
        setLimit(limit + BLOBS_LINUX_LIMIT);
    }, [limit, setLimit]);

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    let allLoaded = false;
    if (blobs && !isLoading && limit > blobs.length) {
        allLoaded = true;
    }

    const hideDownload = mobile || tablet;

    return (
        <UeThemeProvider theme={ueDarkTheme}>
            <Styled>
                {getHelmetForMetaTags(pageData)}
                <VerticalSpacer variant="layout" />
                <LinuxBlobsHeader {...pageData.header} />
                <VerticalSpacer variant="layout" />
                <GridContainer>
                    <div className="content">
                        {isLoggedIn && accepted ? (
                            <>
                                {isLoading ? (
                                    <UnrealLoading fillViewHeight={false} />
                                ) : (
                                    <>
                                        {hideDownload && (
                                            <div className="mobile-warning">
                                                <ContextMessage code="epic.launcher.mobile.subtext" />
                                            </div>
                                        )
                                        }
                                        <VerticalSpacer variant="heading" />
                                        <LinuxBlobsTable
                                            blobs={blobs}
                                            labels={pageData.labels}
                                            hideDownload={mobile || tablet ? true : false}
                                        />

                                        {!allLoaded && blobs?.length && (
                                            <>
                                                <VerticalSpacer variant="heading" />
                                                <div className="show-more">
                                                    <Button onClick={showMore}>
                                                        {pageData?.showMore ||
                                                            'Show Prior Releases'}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <LinuxAccountCheck
                                requireLogin={pageData.requireLogin}
                                login={pageData.login}
                                requireEula={pageData.requireEula}
                                viewAccept={pageData.viewAccept}
                            />
                        )}
                    </div>
                </GridContainer>
                <VerticalSpacer variant="layout" />
                <NewsletterSection {...pageData.newsletter} />
            </Styled>
        </UeThemeProvider>
    );
};

LinuxPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default LinuxPage;
