import styled from 'styled-components';

export const SpeakerWrapper = styled.div`
    .block-speakers {
        padding: 33px 20px 0;
        ${(p) => p.theme.breakpoints.up('lg')} {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }

    .block-speakers-item {
        margin-bottom: 88px;
        ${(p) => p.theme.breakpoints.up('md')} {
            flex-basis: 50%;
            display: flex;
        }
    }

    .block-speakers-image {
        flex-basis: 150px;
        flex-shrink: 0;
        img {
            border-radius: 50%;
            border: 1px solid #d4d4d4;
            width: 150px;
            height: 150px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin-bottom: 20px;
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .block-speakers-info {
        flex-grow: 1;
        padding: 0 10px;
        font-size: 16px;
        ${(p) => p.theme.breakpoints.up('md')} {
            padding: 0 50px;
        }
    }

    .block-speakers-title {
        font-size: 20px;
        margin-bottom: 7px;
        ${(p) => p.theme.breakpoints.up('md')} {
            font-weight: 500;
        }
    }

    .block-speakers-name {
        color: #777;
        font-weight: 300;
        margin-bottom: 12px;
    }

    .block-speakers-text {
        margin-top: 15px;
        line-height: 1.5;
        font-size: 14px;
        color: #777;
    }

    .block-speakers-room:not(:empty) {
        padding: 0 0 10px 0;
    }

    .block-speakers-action {
        ${(p) => p.theme.breakpoints.down('sm')} {
            text-align: center;
        }
    }
    a.button {
        padding: 17px 28px;
        font-size: 14px;
        font-weight: 600;
        &:hover {
            color: #fff;
        }
    }
`;
