import React from 'react';
import { H1, H2, Tag, Button, BgMedia, ButtonWrapper, LightboxMedia } from '../../elements';
import VisibilitySensor from 'react-visibility-sensor';
import { VideoHeader } from './VideoHeader';
import { getBowserState } from '@epic-core/common';
import {
    HeaderWrapper,
    TitleWrapper,
    BottomContent,
    ButtonContainer,
    ContentWrapper,
    Credits
} from './Header.styles';
import { UELHeaderProps } from 'epic-ue-shared';
import { sanitizeCopy } from '@epic-mw/common-utils/dist/contentUtils';

export const Header = ({
    title,
    subtitle,
    desc,
    tag,
    type = '',
    buttons,
    showreel,
    background = {} as any,
    credits,
    origin,
    center,
    vcenter,
    height
}: UELHeaderProps): JSX.Element => {
    let h = '90vh';
    if (type === 'slim') {
        h = '50vh';
    } else if (type === 'video' || type === 'auto') {
        h = 'auto';
    }
    if (height) {
        h = height;
    }

    const { mobile, tablet } = getBowserState();
    const [showShowReel, setShowShowReel] = React.useState(false);
    const toggleLightboxVideo = React.useCallback(() => {
        setShowShowReel(!showShowReel);
    }, [setShowShowReel, showShowReel]);

    const [inView, setInView] = React.useState(false);
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            } else if (!isVisible) {
                setInView(false);
            }
        },
        [inView, setInView]
    );

    const icon: {
        position: 'before' | 'after';
        className: string;
    } = {
        position: 'after',
        className: 'icon-play2'
    };

    let showreelButton;
    if (showreel && showreel.title) {
        showreelButton = (
            <Button
                key={`showreelbtn-${showreel.title}`}
                type="showcase"
                as="button"
                icon={icon}
                onClick={toggleLightboxVideo}>
                {showreel.title}
            </Button>
        );
    }

    let tagType = '';
    let tagContent = '';
    let tagUrl = '';

    if (tag) {
        tagType = tag.type || '';
        tagContent = tag.content || '';
        tagUrl = tag.url || '';
    }

    let bottomContent;
    const validBottomContent =
        (tag && tagContent) || desc || (buttons && buttons.length) || showreelButton;

    if (validBottomContent) {
        bottomContent = (
            <BottomContent className="header-bottom-content">
                {tag && tagContent ? (
                    <Tag type={tagType} content={tagContent} url={tagUrl} />
                ) : (
                    <div />
                )}
                {desc ? (
                    <div className="copy" dangerouslySetInnerHTML={{ __html: desc || '' }} />
                ) : (
                    ''
                )}
                {(buttons && buttons.length) || showreelButton ? (
                    <ButtonContainer className="button-container">
                        <ButtonWrapper buttons={buttons}>{showreelButton}</ButtonWrapper>
                    </ButtonContainer>
                ) : (
                    ''
                )}
            </BottomContent>
        );
    }

    let centerShowreel: JSX.Element | undefined = <div key="noShowReel" />;
    if (
        type === 'centered' &&
        showreel &&
        showreel.video &&
        showreel.video.contentId !== undefined
    ) {
        centerShowreel = (
            <div>
                <i className="icon-play" role="presentation" onClick={toggleLightboxVideo} />
            </div>
        );
    }

    let showreelVideo = {};
    if (showreel) {
        showreelVideo = showreel.video || {};
    }

    let innerHeader: JSX.Element | undefined;

    if (type === 'video' && !mobile && !tablet) {
        innerHeader = (
            <div>
                <VideoHeader
                    title={title}
                    subtitle={subtitle}
                    desc={desc}
                    type={type}
                    ytVideo={showreelVideo}
                    background={background}
                    credits={credits}
                />
            </div>
        );
    } else {
        innerHeader = (
            <div>
                {showreel ? (
                    <LightboxMedia
                        title={title}
                        show={showShowReel}
                        clickHandler={toggleLightboxVideo}
                        video={showreelVideo}
                    />
                ) : (
                    <div />
                )}
                <BgMedia autoplay={true} background={background} />
                <ContentWrapper className="headerContentWrapper">
                    <div className="container">
                        <div className="row">
                            <TitleWrapper className={`title-wrap ${h === 'auto' ? 'auto' : ''}`}>
                                {title ? (
                                    <H1>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeCopy(title) || ''
                                            }}
                                        />
                                    </H1>
                                ) : (
                                    ''
                                )}
                                {subtitle ? <H2>{subtitle}</H2> : ''}
                                {centerShowreel}
                                {bottomContent}
                            </TitleWrapper>

                        </div>
                    </div>
                </ContentWrapper>
                <Credits>{credits || ''}</Credits>
            </div>
        );
    }

    let headerClass = type || '';
    if (mobile || tablet) {
        headerClass = 'default';
    }

    let originClass = 'default';
    if (origin) {
        originClass = origin;
    }
    // eslint-disable-next-line no-use-before-define
    const headerType = headerClass ? `header-${headerClass}` : '';
    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <HeaderWrapper
                customHeight={h}
                className={`header ${headerType} header-${originClass} ${vcenter ? 'vcenter' : ''
                    } ${showShowReel ? 'lightbox-active' : ''} ${center || type === 'centered' ? 'center' : ''
                    } ${inView ? 'inView' : ''}`}>
                {innerHeader}
            </HeaderWrapper>
        </VisibilitySensor>
    );
};
