import {blobsApi, CleanedBlobRes} from './blobs.fetch';
import useSWR from 'swr';

export interface LinuxBlobsRes {
    blobs?: CleanedBlobRes[];
    isLoading: boolean;
    error?: string;
}

interface BlobRequest {
    start?: number;
    limit?: number;
    isLoggedIn: boolean;
}

export const BLOBS_LINUX_LIMIT = 5;

export const createKey = (request?: BlobRequest): string => {
    if (!request) return '';
    return JSON.stringify(request);
};

const defaultRequest = {start: 0, limit: BLOBS_LINUX_LIMIT};

export const useLinuxBlobs = (request: BlobRequest): LinuxBlobsRes => {
    const isLoggedIn = request.isLoggedIn;

    const {data, error} = useSWR(isLoggedIn ? createKey(request || defaultRequest) : null, blobsApi.getLinuxBlobs, {
        errorRetryCount: 1,
        revalidateOnFocus: false
    });

    const responseError = data?.error;
    const blobs = data?.blobs || [];
    return {
        blobs,
        isLoading: !data && !error,
        error: error || responseError
    };
}

