import styled from 'styled-components';

export const MapPanel = styled.div`
    padding: 3em;
    overflow: hidden;
    font-size: 14px;
    display: flex;

    &.gradient {
        background: linear-gradient(180deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    }

    &.left,
    &.right {
        width: 450px;
        flex: 1;
        padding-bottom: 0;
    }

    &.left {
        align-self: flex-start;
    }

    &.right {
        align-self: flex-end;
    }

    &.bottom {
        width: 100%;
        padding-top: 0;
    }

    ${p => p.theme.breakpoints.down('md')} {
        &.left,
        &.right {
            display: none;
        }

        &.bottom {
            background: none;
            padding: 1em;
            flex: 1;
        }

        &.gradient {
            background: linear-gradient(0deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        }
    }
`;

export const MapPanelInner = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &.contained {
        border-radius: 12px;
        background-color: rgba(0, 0, 0, 0.68);
    }
`;
