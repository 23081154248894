import React, {useContext} from 'react';
import {useLocalization} from '@epic-mw/localization';
import styled from 'styled-components';
import {
    GridContainer,
    MultiCard,
    MultiCardOptionsArray,
    Spotlight,
    Typography,
    VerticalSpacer
} from 'epic-ue-ui';
import {getOptImage} from 'epic-ue-shared';
import {AccentColorOptions} from 'epic-ue-theme';
import {HttpContext} from '../../server/utils/httpContext';

const bgImg404 = 'https://cdn2.unrealengine.com/errorbg-1920x1080-744b3ec305b5.jpg';

const Styled = styled.div`
    overflow: hidden;
    ${(p) => p.theme.breakpoints.up('md')} {
        background: url('${getOptImage({url: bgImg404, width: 1920})}') no-repeat;
        background-size: contain;
        background-position: center ${(p) => p.theme.spacing(10)}px;
        background-size: 1300px;
        height: calc(100vh - 52px);
    }
`;

const ErrorPageWrapper = styled.div<{accentColor: AccentColorOptions}>`
    && {
        min-height: 100vh;
        margin: 0 auto;

        ${(p) => p.theme.breakpoints.down('sm')} {
            background: url('${getOptImage({url: bgImg404, width: 800})}') no-repeat;
            position: relative;
            background-position: center 30px;
            background-size: cover;
        }

        ${(p) => p.theme.breakpoints.down('xs')} {
            background-size: 233vw;
        }

        .container {
            width: auto;
            padding: 0;
        }

        .error-box {
            margin: 0 auto;
            text-align: center;
            max-width: ${(p) => p.theme.spacing(80)}px;
            .paragraph {
                color: ${(p) => p.theme.palette.neutral.stone};
                margin: 0 auto;
                max-width: ${(p) => p.theme.spacing(52)}px;
            }
        }
        .loc-ja,
        .loc-ko,
        .loc-zh-CN {
            p b,
            p strong {
                font-weight: 700;
            }
        }
        h1 {
            font-family: ${(p) => p.theme.fonts.brutal.light};
            font-weight: 700;
            font-size: 110px;
            line-height: 120px;
            letter-spacing: 2px;

            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 48px;
                line-height: 56px;
            }
        }

        h2 {
            font-family: ${(p) => p.theme.fonts.brutal.light};
            font-weight: 300;
            font-size: 34px;
            line-height: 42px;
            letter-spacing: 0;

            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 30px;
                line-height: 38px;
            }
        }

        h3 {
            font-family: ${(p) => p.theme.fonts.brutal.regular};
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0;
            transition: color 0.25s ${(p) => p.theme.easing};

            &:hover {
                color: ${(p) => p.theme.palette.accent[p.accentColor]};
            }
        }

        h4 {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 3px;
            text-transform: uppercase;

            &.loc-en-US {
                font-family: ${(p) => p.theme.fonts.fira.medium};
            }
            &.loc-ja {
                font-family: ${(p) => p.theme.fonts.notosans.japanese};
            }
            &.loc-ko {
                font-family: ${(p) => p.theme.fonts.notosans.korean};
            }
            &.loc-zh-CN {
                font-family: ${(p) => p.theme.fonts.notosans.chinese};
            }
            &.loc-ja,
            &.loc-zh-CN,
            &.loc-ko {
                font-size: 16px;
            }
        }

        h1,
        h2,
        h3,
        h4 {
            color: rgb(255, 255, 255);
        }

        .multicard-grid-item {
            ${(p) => p.theme.breakpoints.up('lg')} {
                margin: 0 auto;
                .multi-card-wrap {
                    max-width: ${(p) => p.theme.spacing(52)}px;
                }
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                button.primary {
                    white-space: normal;
                }
            }
        }
    }
`;
interface ErrorLocPageProps {
    accentColor?: AccentColorOptions;
    altLocPageCards?: MultiCardOptionsArray;
}
const ErrorLocPage = (props: ErrorLocPageProps): JSX.Element => {
    const {accentColor = 'forest', altLocPageCards} = props;

    const getMessage = useLocalization();
    const headline = getMessage('epic.error.alt.404.headline');
    const description = getMessage('epic.error.alt.404.description');

    const context = useContext(HttpContext);
    const error = 404;

    if (context) {
        context.status = error;
    }

    return (
        <Styled>
            <ErrorPageWrapper accentColor={accentColor}>
                <Spotlight
                    customColors={['#C776F9', '#9C55F5']}
                    height={749}
                    width={749}
                    offsetRatio={[-0.15, -0.1]}>
                    <GridContainer>
                        <div className="error-box">
                            <VerticalSpacer variant="custom" spacing={17} downSmSpacing={8} />
                            <Typography accentColor={accentColor} variant="h1">{error}</Typography>
                            <VerticalSpacer variant="custom" spacing={3} downSmSpacing={2} />
                            <Typography variant="h2">{headline}</Typography>
                            {description && (
                                <>
                                    <VerticalSpacer variant="paragraph" />
                                    <Typography accentColor={accentColor} variant="p">
                                        {description}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </GridContainer>
                </Spotlight>
                <Spotlight
                    customColors={['#34C3FF', '#338EEE']}
                    height={1014}
                    width={1014}
                    offsetRatio={[0.5, 0.6]}>
                    <VerticalSpacer variant="layout" />
                    <MultiCard accentColor={accentColor} cards={altLocPageCards} />
                </Spotlight>
            </ErrorPageWrapper>
        </Styled>
    );
};

export default ErrorLocPage;
