import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div<{logo?: string}>`
    text-align: center;
    width: 100%;

    .epic-logo {
        background-repeat: no-repeat;
        background-image: url(${(p) => p.logo});
        height: 50px;
        width: 50px;
        background-size: contain;
        background-position: center;
        margin: 0 auto;
    }
`;

export const EpicLogo = ({ logo }: {logo?: string}): JSX.Element => {
    return (
        <Wrap logo={logo}>
            <div className="epic-logo" />
        </Wrap>
    );
};
