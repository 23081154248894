import React, {useCallback, useState} from 'react';
import {GridTable, Cell, CellContent, CellHeader, GridWrapper} from './Grid.styles';
import {H2} from '../../elements/heading/Heading.styles';
import {Button} from '../../elements/buttons/Button';
import {convertCSV} from '../../../utils/csvUtils';
import {getDataAttribute} from '@epic-core/common';
import {getBowserState} from '@epic-core/common';

interface GridItem {
    title?: string;
    csv: string;
    hasHeaders?: boolean;
    gridTemplateColumns?: string;
}

interface Properties {
    title?: string;
    desc?: string;
    btnLabel?: string;
    btnUrl?: string;
    gap?: number;
    items: GridItem[];
}

export const Grid = ({title, desc, btnLabel, gap, btnUrl, items}: Properties): JSX.Element => {
    const [activeTab, setAciveTab] = useState(0);
    const gridTabs: (JSX.Element | undefined)[] = [<div key="gridTabs" />];
    const gridItems: (JSX.Element | undefined)[] = [<div key="gridItems" />];
    const {mobile} = getBowserState();

    const onTabClick = useCallback(
        e => {
            if (e) {
                e.preventDefault();
                const newIndex = getDataAttribute(e.currentTarget, 'index');
                if (newIndex) {
                    setAciveTab(parseInt(newIndex));
                }
            }
        },
        [activeTab, setAciveTab]
    );

    if (items && items.length > 0) {
        items.map((item, index) => {
            const content = item.csv || '';
            const title = item.title || '';
            const hasHeaders = item.hasHeaders;
            const gridTemplateColumns = item.gridTemplateColumns || '';
            if (content) {
                const {data, columnLength} = convertCSV(content, false) || [];

                if (title) {
                    gridTabs.push(
                        <li
                            key={`grid-tab-${index}`}
                            className={`tab-${index} ${activeTab === index ? 'active' : ' '}`}>
                            <span onClick={onTabClick} data-index={index} role="presentation">
                                {title}
                            </span>
                        </li>
                    );
                }

                gridItems.push(
                    <div
                        key={`grid-table-${index}`}
                        className={`table-${index} grid-item-table ${
                            activeTab === index ? 'active' : 'hidden'
                        }`}>
                        <GridTable
                            columnLength={columnLength}
                            gap={gap || 2}
                            gridTemplateColumns={gridTemplateColumns}>
                            {data.map((rows, i) =>
                                rows.map((cell, j) => (
                                    <Cell
                                        row={i + 1}
                                        col={j + 1}
                                        className={`${
                                            hasHeaders && i === 0 ? 'isHeader' : 'isContent'
                                        }`}
                                        key={`UEC-grid-cell-${j}`}>
                                        {hasHeaders && i === 0 ? (
                                            <CellHeader
                                                className="cellHeader"
                                                dangerouslySetInnerHTML={{__html: cell || ''}}
                                            />
                                        ) : (
                                            <CellContent
                                                className="cellContent"
                                                dangerouslySetInnerHTML={{__html: cell || ''}}
                                            />
                                        )}
                                    </Cell>
                                ))
                            )}
                        </GridTable>
                    </div>
                );
            }
        });
    }

    let button = <div />;
    if (btnUrl && btnLabel) {
        button = (
            <div className="btn-wrapper">
                <Button id={`grid-bottom-button`} href={btnUrl} type="primary">
                    {btnLabel}
                </Button>
            </div>
        );
    }

    return (
        <GridWrapper id="eventGrid">
            {title && <H2>{title}</H2>}
            {desc && <div className="copy" dangerouslySetInnerHTML={{__html: desc || ''}} />}
            <ul className="grid-tabs">{gridTabs}</ul>
            <div className="grid-item-list">
                <div className="grid-container">{gridItems}</div>

                <div className={`gesture-helper ${mobile ? '' : 'hidden'}`}>
                    <i className="icon-touch"/>
                </div>
            </div>
            {button}
        </GridWrapper>
    );
};
