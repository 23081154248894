import axios, {AxiosResponse} from 'axios';
import {getOrigin} from 'epic-ue-shared';

const url = () => {
    const origin = getOrigin();

    const url = {
        RELEASE_DATA: (locale) => `${origin}/api/v2/${locale}/data/releases`
    };

    return url;
};

class ReleaseNotesApi {
    public async getReleaseData(locale: string): Promise<any> {
        const api = url().RELEASE_DATA(locale);
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getReleaseData() failed to load');
            throw e;
        }
    }
}

export const releaseNotesApi = new ReleaseNotesApi();
