import React from 'react';
import {default as MenuItem, MenuItemProps} from '@material-ui/core/MenuItem';

export const TextFieldSelectItem = React.forwardRef((props: MenuItemProps, ref: any) => (
    <MenuItem ref={ref} {...props as any}>
        {props.children}
    </MenuItem>
));

TextFieldSelectItem.displayName = 'TextFieldSelectItem';
