import styled from 'styled-components';

export const SectionWrapper = styled.section<{paddingOverride?: string}>`
    padding: 50px 0 25px;
    min-height: 30vh;
    .contained.section-2col-wrapper {
        .twocol-section {
            .image {
                margin-top: 75px;
            }
            .twocol-content {
                padding: 30px 35px 50px;
            }
        }
    }
    .twocol-section {
        h3 {
            margin-bottom: 15px;
        }
    }
    ${p => p.theme.breakpoints.down('xl')} {
        min-height: 30vh;
    }
    ${p => p.theme.breakpoints.down('md')} {
        padding: 50px 50px 0;
    }
    ${p => p.theme.breakpoints.down('sm')} {
        padding: 20px;
    }
    .bgMedia {
        z-index: 0;
    }
    .section-container {
        z-index: 2;
        margin-bottom: ${p =>
            p.paddingOverride ? `${p.paddingOverride}px !important` : 'inherit'};
        .container {
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                width: auto;
            }
        }
    }
    &:empty {
        display: none;
    }
    .gradient {
        border-top: 1px solid #444546;
        background: linear-gradient(180deg, #151617 0%, rgba(21, 22, 23, 0.0001) 100%);
    }
    h1,
    h2 {
        text-align: center;
        position: relative;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    &.visibility-check {
        .section-heading,
        .copy,
        .marks,
        .button-wrapper,
        .section-heading,
        .quote *,
        .pull-quote *,
        .author,
        .quote .icon-quote-open,
        .type-image,
        .btn {
            transition: opacity ease-in-out 0.5s, transform ease-in-out 0.4s;
            opacity: 0;
            transform: translate3d(0, 50%, 1);
        }
        &.inView {
            .section-heading,
            .copy,
            .button-wrapper,
            .section-heading,
            .quote *,
            .pull-quote *,
            .author,
            .quote .icon-quote-open,
            .marks,
            .type-image,
            .btn {
                opacity: 1;
                transform: translate3d(0, 0, 1);
            }
        }
    }

    &.type-contained {
        min-height: 30vh;
        padding: 50px 0;
        ${p => p.theme.breakpoints.between('sm', 'xxl')} {
            padding: 25px 0;
        }
        .imageBg,
        .image {
            max-height: 550px;
        }
    }

    &.type-card {
        padding-top: 0;
        padding-bottom: 0;
    }

    &.type-background {
        margin-top: 50px;
    }

    &.type-branding {
        padding: 0;
    }

    &.type-stacked {
        .section-container .container {
            ${p => p.theme.breakpoints.down('md')} {
                width: 100%;
                padding: 0;
            }
        }
        .button-wrapper {
            text-align: center;
        }
    }

    &.type-fifty {
        width: 50%;
        ${p => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
        &.has-quote {
            ${p => p.theme.breakpoints.down('md')} {
                height: 250px;
            }
        }
        float: left;
        height: 600px;
        position: relative;
        overflow: hidden;
        * {
            text-align: center;
        }
        .bgmedia-content {
            .container {
                padding: 10%;
            }
        }
    }

    &.type-threes {
        width: 30%;
        margin: 20px;
        @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
            margin: 17px;
        }
        float: left;
        height: 600px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            margin: 10px;
        }
        ${p => p.theme.breakpoints.down('md')} {
            width: 100%;
            margin: 0 0 30px 0;
            height: 400px;
        }
        &.has-quote {
            ${p => p.theme.breakpoints.down('md')} {
                height: 250px;
            }
        }
        ${p => p.theme.breakpoints.up('xxl')} {
            height: 850px;
        }
        position: relative;
        overflow: hidden;
        * {
            text-align: center;
        }
        .bgmedia-content {
            .container {
                padding: 10%;
            }
        }
    }

    .feature-wrapper {
        margin-top: 40px;
    }

    ${p => p.theme.breakpoints.up('md')} {
        &.type-fullWidth2Col .left-margin {
            margin-left: 3vw;
            margin-right: 3vw;
        }
    }

    ${p => p.theme.breakpoints.only('md')} {
        &.type-fullWidth2Col .left-margin {
            max-width: 94vw;
        }
    }

    &.type-fullWidth2Col,
    &.type-contained {
        ${p => p.theme.breakpoints.up('xl')} {
            padding: 0;
        }
        height: auto;
        video {
            width: 100% !important;
        }
        ${p => p.theme.breakpoints.up('sm')} {
            padding-bottom: 20px;
        }

        @media screen and (max-height: 800px) and (min-width: 1025px) {
            .twocol-content {
                padding-top: 0;
            }
            h3 {
                padding-top: 0;
                margin-top: 0;
            }
        }

        ${p => p.theme.breakpoints.up('xl')} {
            &:not(.even) {
                padding: 0;
            }
        }

        .section-2col-wrapper:not(.even) {
            .twocol-section {
                .image {
                    transition: opacity ease-in-out 0.5s, right 0.3s ease-in-out 0.5s;
                    opacity: 0;
                    right: -100px;
                    ${p => p.theme.breakpoints.down('md')} {
                        transition: opacity ease-in-out 0.5s, bottom 0.3s ease-in-out 0.5s;
                        opacity: 0;
                        bottom: -100px;
                    }
                    ${p => p.theme.breakpoints.up('lg')} {
                        .bgMedia {
                            width: calc(100% - 30px);
                        }
                    }
                }
            }
        }
        .section-2col-wrapper.even {
            .twocol-section {
                .image {
                    transition: opacity ease-in-out 0.5s, left 0.3s ease-in-out 0.5s;
                    opacity: 0;
                    left: -100px;
                    ${p => p.theme.breakpoints.down('md')} {
                        transition: opacity ease-in-out 0.5s, bottom 0.3s ease-in-out 0.5s;
                        opacity: 0;
                        bottom: -100px;
                    }
                }
            }
        }

        &.inView {
            .section-2col-wrapper:not(.even) {
                .twocol-section {
                    .image {
                        opacity: 1;
                        right: 0;
                        ${p => p.theme.breakpoints.down('md')} {
                            opacity: 1;
                            bottom: 0;
                        }
                    }
                }
            }
            .section-2col-wrapper.even {
                .twocol-section {
                    .image {
                        opacity: 1;
                        left: 0;
                        ${p => p.theme.breakpoints.down('md')} {
                            opacity: 1;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .container > h3 {
        ${p => p.theme.breakpoints.down('md')} {
            text-align: center;
        }
    }

    &.has-caption {
        overflow: initial;
        .stacked-media {
            overflow: initial;
        }
        margin-bottom: 3em;
        ${p => p.theme.breakpoints.down('sm')} {
            margin-bottom: 7em;
        }
    }
`;

export const StackedMedia = styled.div`
    margin: 20px auto;
    width: 100%;
    height: 0;
    padding-top: 56.25% !important;
    display: inline-block;
    position: relative;
    overflow: hidden;
    .imageBg {
        background-size: contain;
    }
    ${p => p.theme.breakpoints.down('xl')} {
        height: 500px;
    }
    ${p => p.theme.breakpoints.down('md')} {
        height: 330px;
        margin: 20px auto;
    }
    ${p => p.theme.breakpoints.down('sm')} {
        height: 190px;
    }
`;

export const FullWidth = styled.div`
    margin: 20px 0 32px;
    width: 100%;
    height: auto;
    min-height: 50vh;
    display: inline-block;
    position: relative;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    ${p => p.theme.breakpoints.down('xl')} {
        min-height: auto;
    }
    ${p => p.theme.breakpoints.between('sm', 'md')} {
        margin: 10px 0 18px;
    }
    .col-content-wrapper {
        max-width: 500px;
        ${p => p.theme.breakpoints.down('md')} {
            max-width: 100%;
        }
    }
    .even {
        .col-content-wrapper {
            margin-left: 40px;
        }
    }
    &:not(.even) {
        .col-content-wrapper {
            ${p => p.theme.breakpoints.up('sm')} {
                float: right;
            }
            ${p => p.theme.breakpoints.up('md')} {
                margin-right: 40px;
            }
        }
    }
`;

export const StackedWrapper = styled.div`
    .type-image {
        background: none;
    }
`;

export const StackedColumn = styled.div`
    width: 100%;
`;
