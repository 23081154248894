import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';
import Academia from '../components/resources/Academia';

const cmsUrlPattern = '/guides-and-white-papers';

const Resources = styled.div`
    .articles {
        border: 0;
        position: relative;
        float: left;
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;
        padding: 0;
    }
    .partner {
        .partner-logo {
            max-height: none;
        }
        .fade-in-link {
            .title {
                font-size: 12px;
                font-weight: bold;
                line-height: 20px;
                color: #0aaff1;
            }
            .sub-title {
                font-size: 16px;
            }
        }
    }

    .resource-unordered-list {
        margin: 0;
        padding: 0;
    }

    #academia {
        max-width: 1240px;
        margin: 0 auto;
        height: 100%;
    }

    #academia #academiaUni .filter-btns {
        text-align: center;
        padding: 20px 0;
        border-width: 1px 0 0;
        border-style: solid;
        border-image: linear-gradient(
                90deg,
                rgba(230, 230, 230, 0),
                #333 50%,
                rgba(230, 230, 230, 0) 100%
            )
            5%;
        margin: 20px auto;
        display: block;
        .btn {
            background: transparent;
            margin: 10px;
            border: 0;
            border-bottom: 1px solid ${(p) => p.theme.ue.colors.buttons.tertiary.border.default};
            ${(p) => p.theme.breakpoints.down('md')} {
                width: 80%;
            }
            &.category-presentations {
                border-bottom-color: #eb4d55;
            }
            &.category-books-and-guides {
                border-bottom-color: #8cba51;
            }
            &.category-documentations {
                border-bottom-color: #333366;
            }

            &.active,
            &:hover {
                background-color: ${(p) => p.theme.ue.colors.buttons.secondary.border.default};
                border-color: ${(p) => p.theme.ue.colors.buttons.secondary.border.default};

                &.category-presentations {
                    border-color: #eb4d55;
                    background-color: #eb4d55;
                }
                &.category-books-and-guides {
                    border-color: #8cba51;
                    background-color: #8cba51;
                }
                &.category-documentations {
                    border-color: #333366;
                    background-color: #333366;
                }
            }
        }
    }

    ${(p) => p.theme.breakpoints.up('xl')} {
        .resource-unordered-list li .text-container {
            line-height: 25px;
        }
    }
`;

const ResourcesPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<PageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <Page>
            <Resources>
                {getHelmetForMetaTags(pageData)}
                <Academia academyData={pageData} />
            </Resources>
        </Page>
    );
};

ResourcesPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default ResourcesPage;
