import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';
import {getCardImage} from '../../../../utils/enterpriseUtils';
import {getRow2Cards} from './RowType2';

export default class RowType3b extends Row {
    constructor(props) {
        super(props);
        this.orderedItems = props.bestFitToRow([2, 2, 2], props.items);
    }

    static propTypes = {
        items: PropTypes.array,
        bestFitToRow: PropTypes.func,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        if (this.props.items.length === 3) {
            const {cards, shortRow} = getRow2Cards(this.props, this.state, [
                this.orderedItems[0],
                this.orderedItems[1]
            ]);

            let thirdCardType = getCardImage(this.orderedItems[2]) ? 'vertical' : 'small-block';
            if (shortRow) {
                thirdCardType = 'horizontal';
            }

            return (
                <div
                    className="grid-news-row type-3b"
                    ref={(c) => {
                        this.gridRowRef = c;
                    }}>
                    <div className="grid-news-cell grid-news-cell-column w66">{cards}</div>
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.orderedItems[2]}
                        type={thirdCardType}
                    />
                </div>
            );
        }
        return null;
    }
}
