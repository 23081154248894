import React, {useState} from 'react';
import {useLocalization} from '@epic-mw/localization';
import {AgreeWrap} from '../../pages/EulaCheckPage.styles';
import {Button} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';

interface Properties {
    eulaCheckbox: boolean;
    setEulaCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
    newsletterCheckbox: boolean;
    setNewsletterCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
    agreeLabel: string;
    submitFn?: () => void;
    accepting: boolean;
    acceptCallback: () => void;
    acceptError?: string;
    epicOptIn?: boolean;
}

export const AgreementWrap = (props: Properties): JSX.Element => {
    const {
        eulaCheckbox,
        setEulaCheckbox,
        newsletterCheckbox,
        agreeLabel,
        submitFn,
        accepting,
        acceptCallback,
        acceptError,
    } = props;
    const [showError, setShowError] = useState(false);
    const getMessage = useLocalization();
    const eulaCheckboxChange = React.useCallback(
        (e) => {
            const target = e.target || {};
            const tagName = target.tagName;
            if (tagName === 'INPUT') {
                //prevent hitting this onClick twice when click the span
                setEulaCheckbox(!eulaCheckbox);
                setShowError(!showError && eulaCheckbox);
            }
        },
        [eulaCheckbox, showError]
    );
    const acceptCallbackValidator = React.useCallback(() => {
        if (eulaCheckbox) acceptCallback();
        if (submitFn && typeof submitFn === 'function') {
            submitFn();
        }
    }, [eulaCheckbox, acceptCallback, newsletterCheckbox]);

    return (
        <AgreeWrap>
            <label htmlFor="eulaAccept" className="terms-check" onClick={eulaCheckboxChange}>
                <input
                    id="eulaAccept"
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={eulaCheckbox}
                />
                <span className="input-label">{agreeLabel}</span>
            </label>

            {showError && !eulaCheckbox ? (
                <small className="error-text">
                    {getMessage('errors.com.epicgames.validation.end_user_license_agreement')}
                </small>
            ) : (
                ''
            )}

            {acceptError && <small className="error-text">{acceptError}</small>}

            {accepting ? (
                <UnrealLoading fillViewHeight={false} />
            ) : (
                <Button
                    id="eula-acceptance"
                    className={`btn ${eulaCheckbox ? 'active' : 'disabled'}`}
                    type="primary"
                    onClick={acceptCallbackValidator}>
                    {getMessage('epic.ue.eula.check.accept')}
                </Button>
            )}
        </AgreeWrap>
    );
};
