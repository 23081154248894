import {useState, useEffect} from 'react';
import {useSharedValue, createSharedValueKey} from '@epic-core/hooks';
import {feedApi} from './feed.api';
import {TagStore} from '../types';
import {isError} from '@epic-mw/error-types';

export const localizedTagsKey = createSharedValueKey<TagStore>('localizedTagsKey', {});
export const feedTagsKey = createSharedValueKey<TagStore>('feedTagsKey', {});

export const useFeedTags = (): {
    localizedTags: TagStore;
    tags: TagStore;
} => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded?: boolean;
        error?: string;
    }>({loading: false, error: ''});
    const [tagStore, setTagStore] = useSharedValue(feedTagsKey);
    const [localizedTagStore, setLocalizedTagStore] = useSharedValue(localizedTagsKey);

    const tags = tagStore || {};
    const localizedTags = localizedTagStore || {};

    useEffect(() => {
        if (
            (Object.keys(tags).length && !loadingState.error) ||
            loadingState.loading ||
            loadingState.loaded
        ) {
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true});
                const fetchedData: {
                    tags: TagStore;
                    localizedTags: TagStore;
                } = await feedApi.getFeedTags();
                setTagStore(fetchedData.tags);
                setLocalizedTagStore(fetchedData.localizedTags);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [tags, localizedTags, loadingState]);

    return {
        tags,
        localizedTags
    };
};

export const useFeedSearchTags = (): {
    localizedTags: TagStore;
    searchTags: TagStore;
    tags: TagStore;
} => {
    const {tags, localizedTags} = useFeedTags();
    const localizedMatches = {};
    for (const tag in tags) {
        if (
            Object.hasOwnProperty.call(tags, tag) &&
            Object.hasOwnProperty.call(localizedTags, tag) &&
            tag !== localizedTags[tag]
        ) {
            localizedMatches[localizedTags[tag]] = tags[tag];
        }
    }

    const searchTags = Object.assign({}, tags, localizedMatches);

    return {
        tags,
        localizedTags,
        searchTags
    };
};

export const useLocalizedTags = (): TagStore => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded?: boolean;
        error?: string;
    }>({loading: false, error: ''});
    const [localizedTagStore, setLocalizedTagStore] = useSharedValue(localizedTagsKey);

    const localizedTags = localizedTagStore || {};

    useEffect(() => {
        if (
            (Object.keys(localizedTags).length && !loadingState.error) ||
            loadingState.loading ||
            loadingState.loaded
        ) {
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true});
                const fetchedData: {[key: string]: any} = await feedApi.getLocalizedTags();
                setLocalizedTagStore(fetchedData);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [localizedTags, loadingState]);

    return localizedTags;
};
