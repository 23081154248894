import React, {MouseEvent} from 'react';
import root from 'window-or-global';
import {Helmet} from 'react-helmet';
import classNames from 'classnames';
import Message from '../message/Message';
import Tags from './Tags';
import RecentPosts from './recentPosts/index';
import {getLocale} from '@epic-core/common';
import ShareIcons from './ShareIcons';
import LinkNavigator from '../common/LinkNavigator';
import {getMetaTags, getUniqueMetaData, generateTitle, BlogTag} from '../../utils/metaUtils';
import getLatestBlogs, {getBlogPost, getBlogField} from '../../utils/BlogDetailUtils';
import {BlogDetailProps, BlogPostProps, titleAppendUnrealEngine} from 'epic-ue-shared';
import parse from 'url-parse';
import {BlogArticleWrapper, BlogDetailWrapper} from './BlogDetail.styles';
import {JuxtaposeStyles} from './styles/juxtapose';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

/**
 * Return scrollTop position of the 'document' (launcher handler)
 * @param  {Boolean} isLauncher
 * @return {number} scrollTop/pageYoffset
 */
const getScrollTop = function getScrollTop(isLauncher) {
    if (typeof root.document === 'undefined') {
        return 0;
    } else if (isLauncher) {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const documentElement = root.document.getElementById(wrapperId);
        return documentElement ? documentElement.scrollTop : 0;
    }
    return root.pageYOffset || root.document.documentElement.scrollTop;
};

interface BlogDetailProperties {
    blog: BlogDetailProps;
    recent: [];
    routerRootUrl: string;
    config: {
        twitterDefaultTags: string;
        shareConfigs: {
            type: string;
        }[];
    };
    rootPageSlug: string;
    disableShareIcons: boolean;
    overrideMetaTags: BlogTag[];
    overrideMetaTitle?: string;
    linkNavigatorRootPageSlug?: boolean;
    isLauncher?: boolean;
    localizedTags: any;
}

interface BlogDetailState {
    fixSocialPos: boolean;
    bottomAlign: boolean;
    topAlign: boolean;
    showComments: boolean;
    current: number;
}

export default class BlogDetail extends React.Component<BlogDetailProperties, BlogDetailState> {
    constructor(props: BlogDetailProperties) {
        super(props);
        this.state = {
            current: -1,
            fixSocialPos: false,
            topAlign: false,
            bottomAlign: false,
            showComments: false
        };
    }

    juxtaposeScript: HTMLScriptElement | null = null;
    images: HTMLImageElement[];
    blogHeader: HTMLDivElement | null;
    cmsContainer: HTMLDivElement | null;

    /**
     * Add scroll eventListener
     */
    componentDidMount(): void {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const isLauncher = this.props.isLauncher && root.document.getElementById(wrapperId);
        const enableJuxtapose = root.document.getElementsByClassName('juxtapose');
        if (isLauncher) {
            root.document
                .getElementById(wrapperId)
                .addEventListener('scroll', this.handleScroll, true);
        } else {
            root.addEventListener('scroll', this.handleScroll);
            this.initLightbox(this.props.blog || {});
        }
        if (enableJuxtapose.length) {
            this.juxtaposeScript = root.document.createElement('script');
            (this.juxtaposeScript as HTMLScriptElement).src =
                'https://cdn3.unrealengine.com/static/js/juxtapose.min.js';
            root.document.body.appendChild(this.juxtaposeScript);
        }
    }

    /**
     * Remove scroll eventListener
     */
    componentWillUnmount(): void {
        const wrapperId = root.__reactWrapper || 'paragonReactWrapper';
        const isLauncher = this.props.isLauncher && root.document.getElementById(wrapperId);
        if (isLauncher) {
            root.document
                .getElementById(wrapperId)
                .removeEventListener('scroll', this.handleScroll);
        } else {
            root.removeEventListener('scroll', this.handleScroll);
            if (this.images && this.images.length) {
                for (let i = 0; i < this.images.length; i++) {
                    const image = this.images[i];
                    image.removeEventListener('click', this.showLightbox);
                }
            }
        }
        if (this.juxtaposeScript) {
            try {
                this.juxtaposeScript.parentNode?.removeChild(this.juxtaposeScript);
            } catch (e) {
                console.error('failed to remove the child node of juxtaposeScript');
            }
        }
    }

    /**
     * Scroller event handler
     * Make social icons fixed position while scrolling
     * @param  {object} event scroll event
     */
    handleScroll = (event: MouseEvent): void => {
        if (!event) {
            return;
        }
        const headerRef = this.blogHeader;
        const cmsRef = this.cmsContainer;
        const scrollTop = getScrollTop(this.props.isLauncher);
        const headerHeight = headerRef ? headerRef.offsetHeight + 52.8 : 0;
        const cmsHeight = cmsRef ? cmsRef.offsetHeight + 154 : 0;
        const removeFix = scrollTop >= cmsHeight;
        const fixPosition = scrollTop > headerHeight && !removeFix;
        if (fixPosition && !this.state.fixSocialPos) {
            this.setState({
                fixSocialPos: fixPosition,
                topAlign: fixPosition,
                bottomAlign: false
            });
        } else if (!fixPosition && this.state.fixSocialPos) {
            this.setState({
                fixSocialPos: fixPosition,
                topAlign: fixPosition,
                bottomAlign: false
            });
        }
        if (removeFix && !fixPosition && !this.state.bottomAlign) {
            this.setState({
                bottomAlign: removeFix
            });
        }
        if (scrollTop === 0 && !removeFix) {
            this.setState({
                bottomAlign: removeFix
            });
        }
    };

    componentDidUpdate(prevProps: BlogDetailProperties): void {
        const prevBlog = prevProps.blog || {};
        const blog = this.props.blog || {};
        if (blog._id && blog._id !== prevBlog._id) {
            this.initLightbox(blog);
        }
    }

    initLightbox(blog = {} as BlogDetailProps): void {
        if (!getBlogField(blog, 'enableLightbox')) return;

        const section = root.document.getElementById('cmsSection');
        this.images = section.getElementsByTagName('img') || [];
        if (this.images && this.images.length) {
            for (let i = 0; i < this.images.length; i++) {
                const item = this.images[i];
                item.setAttribute('data-idx', i.toString());
                item.addEventListener('click', this.showLightbox);
            }
        }
    }

    showLightbox = (e: Event): void => {
        root.document.body.className += ' hidden-scroll';
        const idx =
            parseInt((e.target as unknown as HTMLOrSVGElement).dataset.idx as string, 10) || 0;
        this.setState({current: idx});
    };

    hideLightbox = (e: MouseEvent): void => {
        root.document.body.className = root.document.body.className.replace(' hidden-scroll', '');
        e.preventDefault();
        this.setState({current: -1});
    };

    prevImage = (e: MouseEvent): void => {
        e.stopPropagation();
        let current = this.state.current - 1;
        if (current < 0) current = this.images.length - 1;
        this.setState({current});
    };

    nextImage = (e: MouseEvent): void => {
        e.stopPropagation();
        let current = this.state.current + 1;
        if (current >= this.images.length) current = 0;
        this.setState({current});
    };

    /**
     * Generated Meta Tags for sharing
     * @param  {object} blog     post data
     * @param  {config} hashTags example: "ue4, epicGames", optional
     * @return {array}  meta
     */
    generateMetaTags(blog: BlogDetailProps, config: {twitterDefaultTags: string}): BlogTag[] {
        let meta = getMetaTags(
            getLocale(),
            blog,
            config.twitterDefaultTags,
            this.props.overrideMetaTitle
        );
        meta = getUniqueMetaData(meta);
        return meta.concat(this.props.overrideMetaTags || []);
    }

    showComments = (e: Event): void => {
        this.setState({
            showComments: true
        });
    };

    render(): JSX.Element {
        const config = this.props.config || {};
        const blog = this.props.blog || {};
        const post = getBlogPost(blog) as BlogPostProps;
        const alt = post.trendingImageAlt || '';
        const noTopImage = post.noTopImage === true || !post.trendingImage;
        const showTranslateMessage = blog.showTranslateMessage;
        const html = sanitizeCopy(post.content || post.short);
        const meta = this.generateMetaTags(blog, config);
        const title = titleAppendUnrealEngine(generateTitle(blog, this.props.overrideMetaTitle));
        const style = !noTopImage
            ? {
                  backgroundImage: `url("${post.trendingImage}")`,
                  backgroundPosition: 'top'
              }
            : {};
        const containerClass = classNames('blog-container', {'no-image': noTopImage});
        const recent = this.props.recent || [];
        const enableLightbox = getBlogField(blog, 'enableLightbox') || false;

        let date: Date | string = '';
        if (blog._activeDate) {
            if (typeof blog._activeDate === 'string') {
                date = new Date(blog._activeDate);
                if (date) {
                    date = date.toLocaleDateString(getLocale(), {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                    });
                }
            } else if ((blog._activeDate as any) instanceof Date) {
                date = (blog._activeDate as unknown as Date).toLocaleDateString(getLocale(), {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        }

        const recentList = getLatestBlogs(recent, blog, 3);

        const currentUrl = parse(root.location.href);
        currentUrl.set('hash', '');
        currentUrl.set('query', '');

        const links = [
            {
                rel: 'canonical',
                href: currentUrl.toString()
            }
        ];

        meta.push({
            property: 'og:url',
            content: currentUrl.toString()
        });

        return (
            <BlogDetailWrapper className={classNames(containerClass, `loc-${getLocale()}`)}>
                <JuxtaposeStyles />
                <Helmet link={links} meta={meta} title={title} />
                {showTranslateMessage ? (
                    <div className="blog-translate-message">
                        <Message code="epic.blog.translateMessage" />
                    </div>
                ) : (
                    ''
                )}
                <div
                    ref={(c) => {
                        this.blogHeader = c;
                    }}
                    className={`blog-header ${noTopImage ? 'no-image' : 'image'}`}
                    style={style}>
                    {!noTopImage ? (
                        <img className="invisible-image" alt={alt} src={post.trendingImage} />
                    ) : null}
                </div>
                <BlogArticleWrapper className="blog-article">
                    <div className="blog-content container-fluid">
                        <article>
                            <div
                                ref={(c) => {
                                    this.cmsContainer = c;
                                }}
                                className="cmsContainer">
                                <ShareIcons
                                    config={this.props.config}
                                    disableShareIcons={this.props.disableShareIcons}
                                    topAlign={this.state.topAlign}
                                    fixSocialPos={this.state.fixSocialPos}
                                    bottomAlign={this.state.bottomAlign}
                                />
                                <div className="blog-header-info">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            {post.subtitle && (
                                                <span className="blog-header-subtitle">
                                                    {post.subtitle}
                                                </span>
                                            )}
                                            <span className="blog-header-date">{date}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <h1 className="blog-header-title">
                                                {post.title || blog._title}
                                            </h1>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Tags
                                            {...this.props}
                                            localizedTags={this.props.localizedTags}
                                        />
                                    </div>
                                    <div className="row">
                                        {post.author && (
                                            <div className="blog-header-author col-xs-12">
                                                <Message
                                                    code="epic.blog.article.author"
                                                    args={[post.author]}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <hr />
                                </div>
                                <section
                                    id="cmsSection"
                                    className={`cms ${enableLightbox && 'lightbox-enabled'}`}
                                    dangerouslySetInnerHTML={{__html: html}}
                                />
                            </div>
                            <RecentPosts.ThreeColumn
                                blogs={recentList}
                                routerRootUrl={this.props.routerRootUrl}
                            />
                        </article>
                    </div>
                    <LinkNavigator
                        linkNavigatorRootPageSlug={this.props.linkNavigatorRootPageSlug}
                        rootPageSlug={this.props.rootPageSlug}
                    />
                </BlogArticleWrapper>
                {
                    // eslint-disable-next-line
                }
                {this.state.current >= 0 && (
                    <div className="lightbox" onClick={this.hideLightbox} role="presentation">
                        <img src={this.images[this.state.current].src} alt="" />
                        <button
                            title="Close"
                            className="close epic-blog-icon-close"
                            onClick={this.hideLightbox}
                        />
                        <button
                            title="Previous image"
                            className="prev epic-blog-icon-leftArrow"
                            onClick={this.prevImage}
                        />
                        <button
                            title="Next image"
                            className="next epic-blog-icon-rightArrow"
                            onClick={this.nextImage}
                        />
                    </div>
                )}
            </BlogDetailWrapper>
        );
    }
}
