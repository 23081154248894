import styled from 'styled-components';
import {AccentColorOptions} from 'epic-ue-theme';

const getRadialGradient = (from, to) =>
    `radial-gradient(100% 185.14% at 100% 100%, ${from} 0%, ${to} 100%)`;

export const SalesforceSimpleFormWrapper = styled.div<{accentColor: AccentColorOptions}>`
    && {
        .marketo-simple-form {
            margin: ${(p) => p.theme.spacing(5)}px 0 0;
            .field-wrap {
                div > label span {
                    margin-left: 0;
                }
                &.input > div > div input {
                    background: ${(p) => p.theme.palette.neutral.steel};
                    color: ${(p) => p.theme.palette.common.white};
                    font-family: ${(p) => p.theme.fonts.brutal.regular};
                    font-weight: 400;
                    letter-spacing: 0;
                    font-size: 16px;
                    line-height: 30px;
                    &:-webkit-autofill {
                        -webkit-box-shadow: 0 0 0 100px ${(p) => p.theme.palette.neutral.steel}
                            inset;
                    }
                }
                & > div > div > div {
                    height: 60px;
                    min-height: 60px;
                    background: ${(p) => p.theme.palette.neutral.steel};
                    input {
                        background: ${(p) => p.theme.palette.neutral.steel};
                        color: ${(p) => p.theme.palette.common.white};
                        font-family: ${(p) => p.theme.fonts.brutal.regular};
                        font-weight: 400;
                        letter-spacing: 0;
                        font-size: 16px;
                        line-height: 30px;
                        height: 60px;
                        padding-top: 20px;
                        padding-left: 16px;
                        padding-right: 16px;
                        padding-bottom: 0px;
                    }
                    & > div {
                        right: 0;
                        margin: 0 16px 0 0;
                    }
                    button {
                        border-radius: 0;
                        margin-right: 0;
                        padding: 4px;
                        svg {
                            width: 20px;
                            height: 20px;
                            path {
                                fill: ${(p) => p.theme.palette.accent.bright[p.accentColor]};
                            }
                        }
                    }
                    button:last-child {
                        path {
                            d: path('m 4 8 l 8 8 l 8 -8');
                            fill: none;
                            stroke: ${(p) => p.theme.palette.accent.bright[p.accentColor]};
                            stroke-width: 2px;
                        }
                    }
                }
                label {
                    color: ${(p) => p.theme.palette.common.white};
                    font-family: ${(p) => p.theme.fonts.brutal.regular};
                    font-weight: 400;
                    letter-spacing: 0;
                    font-size: 16px;
                    span {
                        margin-left: 0;
                    }
                }
            }
            .checkbox {
                label {
                    align-items: flex-start;
                    margin-left: 0;
                }

                label > span:nth-of-type(1) {
                    margin: 0 ${(p) => p.theme.spacing(2)}px 0 0;
                }
                svg {
                    width: 24px;
                    height: 24px;
                    padding: 0;
                    stroke: ${(p) => p.theme.palette.neutral.stone};
                    box-sizing: initial;
                    path:first-child {
                        d: path('M 0,0 h 24 v 24 h -24 v -24');
                    }
                }
                span {
                    font-family: ${(p) => p.theme.fonts.brutal.regular};
                    font-weight: 400;
                    letter-spacing: 0;
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            .group-wrap {
                margin: 0 0 ${(p) => p.theme.spacing(7)}px;
            }
            .textarea > div > div {
                background: ${(p) => p.theme.palette.neutral.steel};
                textarea:first-child {
                    padding-top: 0;
                    min-height: 120px;
                    margin-top: 32px;
                    background: ${(p) => p.theme.palette.neutral.steel};
                    color: ${(p) => p.theme.palette.common.white};
                    font-family: ${(p) => p.theme.fonts.brutal.regular};
                    font-weight: 400;
                    letter-spacing: 0;
                    font-size: 16px;
                    line-height: 30px;
                }
            }
            .button-wrap {
                display: flex;
                flex-flow: row-reverse wrap;
                margin: ${(p) => p.theme.spacing(7)}px 0 0 0;

                ${(p) => p.theme.breakpoints.down('xs')} {
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                }

                button {
                    transition: box-shadow 0.25s ${(p) => p.theme.easing};
                    margin: 0 0 0 ${(p) => p.theme.spacing(2)}px;
                    height: ${(p) => p.theme.spacing(6)}px;
                    font-family: ${(p) => p.theme.fonts.brutal.bold};
                    font-weight: 500;
                    padding: 16px 28px;
                    letter-spacing: 2px;
                    font-size: 13px;
                    line-height: 16px;
                    text-transform: uppercase;
                    &.mkt-pri-btn,
                    &.mkt-pri-btn[disabled] {
                        color: ${(p) => p.theme.palette.common.black};
                        background: ${(p) =>
                            getRadialGradient(
                                p.theme.palette.gradient[p.accentColor].from,
                                p.theme.palette.gradient[p.accentColor].to
                            )};
                        border: unset;
                        &:hover:not([disabled]) {
                            color: ${(p) => p.theme.palette.common.black};
                            box-shadow: 0 0 18px ${(p) => p.theme.palette.accent[p.accentColor]};
                            background-color: transparent;
                            background: ${(p) =>
                                getRadialGradient(
                                    p.theme.palette.gradient[p.accentColor].from,
                                    p.theme.palette.gradient[p.accentColor].to
                                )};
                        }
                    }
                    &.mkt-sec-btn {
                        color: ${(p) => p.theme.palette.common.white};
                        border: 2px solid;
                        border-image: ${(p) =>
                                getRadialGradient(
                                    p.theme.palette.gradient[p.accentColor].from,
                                    p.theme.palette.gradient[p.accentColor].to
                                )}
                            5;
                        border-image-width: 2px;
                        &:hover:not([disabled]) {
                            color: ${(p) => p.theme.palette.common.white};
                            box-shadow: 0 0 18px ${(p) => p.theme.palette.accent[p.accentColor]};
                            background-color: transparent;
                            border-color: ${(p) => p.theme.palette.accent[p.accentColor]};
                            border-image: ${(p) =>
                                    getRadialGradient(
                                        p.theme.palette.gradient[p.accentColor].from,
                                        p.theme.palette.gradient[p.accentColor].to
                                    )}
                                5;
                        }
                    }
                }
                > div:first-child:not(.privacy-link):not(.privacy-link):not(.loading-wrap):not(.lottie-wrap) {
                    width: 100%;
                    margin: 16px 10%;
                }
                .privacy-link {
                    font-family: ${(p) => p.theme.fonts.brutal.regular};
                    flex: auto;
                    align-self: flex-end;
                    text-align: left;
                    font-size: 14px;
                    line-height: 22px;
                    letter-spacing: 0.2px;
                    margin-bottom: ${(p) => p.theme.spacing(4)}px;
                    a {
                        color: ${(p) => p.theme.palette.accent[p.accentColor]};
                        text-decoration: none;
                        transition: color 200ms ease-out 0s;
                        &:hover {
                            color: ${(p) => p.theme.palette.accent.dark[p.accentColor]};
                            text-decoration: none;
                        }
                    }

                    ${(p) => p.theme.breakpoints.down('xs')} {
                        width: 100%;
                        text-align: center;
                    }
                }
                .loadingWrap,
                .successWrap {
                    flex-basis: 100%;
                    margin: 0 auto;

                    width: 100%;
                    word-break: break-word;
                }
            }
            h3 {
                color: ${(p) => p.theme.palette.text.secondary};
                font-family: ${(p) => p.theme.fonts.brutal.regular};
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0;
                ${(p) => p.theme.breakpoints.down('md')} {
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            h4 {
                font-family: ${(p) => p.theme.fonts.fira.bold};
                font-weight: 700;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 4px;
                text-transform: uppercase;
                text-align: center;
                margin: 0 0 ${(p) => p.theme.spacing(4)}px;
            }
            p {
                font-family: ${(p) => p.theme.fonts.brutal.regular};
            }
        }
    }
`;
