import axios, {AxiosResponse} from 'axios';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';
import {cacheBusterHeaders} from 'epic-ue-shared';

import {getOrigin} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';

const url = () => {
    const origin = getOrigin();

    return {
        HAS_TWIN_ENTITLEMENT: (locale) => `${origin}/api/v2/${locale}/entitlement/account/twin`,
        SUBMIT_TWIN_ORDER: (locale) => `${origin}/api/v2/${locale}/twin/signup`
    };
};

class TwinmotionApi {
    public async submitOrder(data): Promise<any> {
        const locale = getLocale();
        const api = url().SUBMIT_TWIN_ORDER(locale);
        try {
            const response: AxiosResponse = await axios.post(api, data);
            return response.data;
        } catch (e) {
            console.error('submitOrder() failed to load', isError(e) && e.message);
            throw e;
        }
    }

    public async getTwinEntitlement(type?: string): Promise<any> {
        if (root.__server_side_render) {
            return Promise.resolve();
        }

        const locale = getLocale();
        const api = url().HAS_TWIN_ENTITLEMENT(locale);
        try {
            const response: AxiosResponse = await axios.post(
                api,
                {type},
                {
                    headers: cacheBusterHeaders
                }
            );
            return response.data;
        } catch (e) {
            console.error('getTwinEntitlement() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const twinmotionApi = new TwinmotionApi();
