import React from 'react';
import BlogPage from '../../pages/BlogPage';
import ErrorPage from '../../pages/ErrorPage';
import ErrorLocPage from '../../pages/ErrorLocPage';
import {UnrealLoading} from 'epic-ue-loading';
import {useLocation, useParams, Params} from 'react-router';
import {getAltLocPageDataCards, useFeedStoreItem} from 'epic-ue-feed';
import {extractSlugLocale, useLocalization} from '@epic-mw/localization';
import {getMessageFnType} from 'epic-ue-shared';
import {getLocale} from '@epic-core/common';

const getSlug = (pathname: string, params: Params, props: FeedDetailsInnerProperties) => {
    const slug = params.slug || params.title || params.typeSlug;
    let pageSlug;
    let rootPageSlug;

    if (pathname) {
        const extracted = extractSlugLocale(pathname, getLocale()); // blog/foo
        pageSlug = extracted.pageSlug;
        rootPageSlug = pageSlug.replace(`/${slug}`, ''); // blog
        return {slug, pageSlug, rootPageSlug};
    } else {
        rootPageSlug = props.rootPageSlug; // blog
        pageSlug = `${rootPageSlug}/${slug}`; // blog/foo
    }

    return {slug, pageSlug, rootPageSlug};
};

export interface FeedDetailsInnerProperties {
    rootPageSlug: string;
}

const FeedDetailsInner = (props: FeedDetailsInnerProperties): JSX.Element => {
    const {pathname} = useLocation();
    const params = useParams();
    const {pageSlug, rootPageSlug} = getSlug(pathname, params, props);
    const {cmsLoading, cmsLoadedEmpty, feedItem} = useFeedStoreItem(pageSlug);
    const getMessage = useLocalization();

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    // if cmsLoadedEmpty, then page is a true 404, render 404 page
    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    // if alternate locale page data exists, render alternate 404 page
    if (feedItem?._hasAltLocPageData) {
        const altLocPageCards = getAltLocPageDataCards(
            feedItem,
            pageSlug,
            getMessage as getMessageFnType
        );
        return <ErrorLocPage altLocPageCards={altLocPageCards} />;
    }

    return <BlogPage rootPageSlug={rootPageSlug} blog={feedItem} />;
};

export const FeedDetails = FeedDetailsInner;
