import React, {useEffect} from 'react';
import {Card, SimpleLink, Button, H2} from '../../elements';
import {StaggerWrapper} from '../../animations';
import styled from 'styled-components';
import {getBowserState} from '@epic-core/common';
import VisibilitySensor from 'react-visibility-sensor';
import {FeaturesProps, ButtonProps} from 'epic-ue-shared';

const FeaturesGrid = styled.div`
    div.equal {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;
        ${p => p.theme.breakpoints.down('sm')} {
            margin-bottom: 10px;
        }
    }
    .equal > div[class*='col-'] {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 0;
    }
    p {
        margin-bottom: 2em;
    }
    h3 {
        text-align: center;
    }
    h4 {
        ${p => p.theme.breakpoints.up('lg')} {
            font-size: 2.2em;
        }
    }
    .container {
        padding-left: 0;
        padding-right: 0;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            width: auto;
        }
    }

    .title-container {
        padding: 0 1em;
    }
`;

interface FeaturesPageProps {
    features: FeaturesProps[];
    title?: string;
    desc?: string;
    type?: 'inverted' | undefined;
    center?: boolean;
}

export const Features = ({features = [], type, title, desc, center}: FeaturesPageProps): JSX.Element => {
    const featureAmount = features.length;
    const {mobile} = getBowserState();
    const [inView, setInView] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const getGridClasses = React.useMemo(() => {
        const n = featureAmount;
        if (!n || n === 1) return 'col-xs-12';
        let ratio = Math.floor(12 / n);
        if (n === 4) {
            ratio = 6;
        }
        if (n === 6) {
            ratio = 4;
        }
        return `col-xs-12 col-sm-6 col-md-${Math.max(ratio, 4)}`;
    }, [featureAmount]);

    useEffect(() => {
        setHasLoaded(true);
    }, [hasLoaded, setHasLoaded]);

    const visChange = React.useCallback(
        isVisible => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    let featureList: (JSX.Element | undefined)[] = [<div key="featureList" />];

    if (features) {
        featureList = features.map((feature, index) => {
            let linkList: (JSX.Element | undefined)[] = [<div key="linkList" />];
            let linkListing;
            const links = feature.links;
            if (links) {
                linkList = links.map(link => {
                    if (link && link.title && link.href) {
                        return (
                            <li key={link.title}>
                                <SimpleLink
                                    id={link.title}
                                    title={link.title}
                                    href={link.href}/>
                            </li>
                        );
                    }
                });
            

                linkListing = <div />;
                if (linkList && linkList.length > 0) {
                    linkListing = <ul>{linkList}</ul>;
                }
            }

            const buttons: ButtonProps[] = feature.buttons || [];
            let buttonWrapper;
            if (buttons && buttons.length) {
                buttonWrapper = (
                    <>
                        {buttons.map(button => (
                            <Button key={button.label || button.title} {...button} />
                        ))}
                    </>
                );
            }

            return (
                <div
                    key={`featurecard-${feature.key || feature.title}-${index}`}
                    className={getGridClasses}>
                    <Card center={center} type={type} copy={feature.desc} {...feature} noPaddingBetweenTitles={true} >
                        {linkListing}
                        {buttonWrapper}
                        {feature.children}
                    </Card>
                </div>
            );
        });
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <FeaturesGrid className="feature-wrapper">
                <div className="title-container">
                    {title ? (
                        <div className="features-title">
                            <H2>{title}</H2>
                        </div>
                    ) : (
                        ''
                    )}
                    {desc ? (
                        <div className="features-copy centered">
                            <div className="copy" dangerouslySetInnerHTML={{__html: desc}} />
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className="container">
                    <StaggerWrapper
                        childLength={featureList.length}
                        className={`equal ${hasLoaded ? 'hasLoaded' : ''} ${
                            inView ? 'inView' : ''
                        } ${mobile ? 'handheld' : ''}`}>
                        {featureList}
                    </StaggerWrapper>
                </div>
            </FeaturesGrid>
        </VisibilitySensor>
    );
};
