import {useState, useEffect} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {isError} from '@epic-mw/error-types';
import {metaHumanApi} from './metaHuman.api';

export const metaHumanEntitlementDataKey = createSharedValueKey<MetaHumanEntitlementData>(
    'metaHumanEntitlementData',
    {} as MetaHumanEntitlementData
);

export interface MetaHumanEntitlementData {
    hasStandard: boolean;
}

export interface MetaHumanEntitlementResponse {
    metaHumanEntitlement: MetaHumanEntitlementData;
    loading?: boolean;
    loaded?: boolean;
    error?: string;
}

export const useMetaHumanEntitlement = (): MetaHumanEntitlementResponse => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [metaHumanEntitlement, setMetaHumanEntitlement] = useSharedValue(metaHumanEntitlementDataKey);
    const {hasStandard}: MetaHumanEntitlementData = metaHumanEntitlement;

    useEffect(() => {
        if ((typeof hasStandard === 'boolean' && !loadingState.error) || loadingState.loading) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = await metaHumanApi.getMetaHumanEntitlement();
                setMetaHumanEntitlement(Object.assign({}, data));
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch MetaHuman entitlement data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    });

    return {
        metaHumanEntitlement,
        ...loadingState
    };
};
