import React from 'react';
import PropTypes from 'prop-types';
import EnterpriseHighlightsPanel from '../../components/enterprise/EnterpriseHighlightsPanel';
import {getLocale} from '@epic-core/common';
import {HighlightPanelSectionWrapper} from './HighlightPanelSection.styles';

export default class HighlightPanelSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggle: props.defaultCollapsed || false
        };
    }
    static propTypes = {
        panel: PropTypes.object,
        defaultCollapsed: PropTypes.bool
    };
    toggleItem = (event) => {
        this.setState({
            toggle: !this.state.toggle
        });
    };
    render() {
        const panel = this.props.panel || {};
        const desc = panel.desc;

        return (
            <HighlightPanelSectionWrapper className="highlight-panel-section">
                <div className="headline container text-center">
                    <div className="headline-content" onClick={this.toggleItem} role="presentation">
                        <span>{panel.headline}</span>
                        <i
                            className={this.state.toggle ? 'icon-chevron-up' : 'icon-chevron-down'}
                        />
                    </div>
                    <div className="line" />
                    {desc && !this.state.toggle ? (
                        <div className="headline-desc" dangerouslySetInnerHTML={{__html: desc}} />
                    ) : (
                        ''
                    )}
                </div>

                {panel.showPanel && panel.highlights.length ? (
                    <div className={`accordion ${this.state.toggle ? 'close' : 'open'}`}>
                        <EnterpriseHighlightsPanel
                            firstFeatured={panel.firstFeatured}
                            highlights={panel.highlights || []}
                            locale={getLocale()}
                            isNewsletterCard={false}
                            isNewsletterInHighlights={false}
                        />
                    </div>
                ) : null}
            </HighlightPanelSectionWrapper>
        );
    }
}
