import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {default as Tooltip, TooltipProps} from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: '#2a2a2a'
    },
    tooltip: {
        backgroundColor: '#2a2a2a',
        color: '#ffffff',
        fontSize: theme.typography.pxToRem(14),
        padding: theme.typography.pxToRem(18)
    }
}));

export const TooltipHtml = React.forwardRef((props: TooltipProps, ref) => {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} ref={ref} {...props} />;
});

TooltipHtml.displayName = 'TooltipHtml';
