import React from 'react';
import {
    EmbeddedVideo,
    CmsComponentMapper,
    CmsDynamicSectionOptionsArray,
    UeImageCompare,
    VerticalSpacer,
    OverviewGridSection,
    PromoSection,
    LogoList,
    FeaturesGrid,
    FeaturesCarouselSection,
    ImageAndTextCard,
    LeftAlignedTextBlock,
    CenteredTextBlock,
    LineTextBlockWithContainer,
    SubnavSectionConfigProps,
    LargeQuote,
    SectionIntroWithCards,
    MultiCard,
    SplitListSection,
    DividedList,
    SubnavSection,
    SubnavSectionVariantOptions,
    determineSectionNameSupport,
    FAQSection
} from 'epic-ue-ui';
import styled from 'styled-components';
import FeedWrap from '../feed/FeedWrap';

const Styled = styled.div`
    .paragraph {
        color: ${(p) => p.theme.palette.text.primary};
    }
`;
export interface HubSectionSelectionProps {
    hidden?: boolean;
    items?: CmsDynamicSectionOptionsArray;
    sectionConfig: SubnavSectionConfigProps;
    hideTopSectionPadding?: boolean;
    variant?: SubnavSectionVariantOptions;
    needsThemeVerticalSpacer?: boolean;
    hideItemSeperator?: boolean;
}

export const HubSectionSelection = (props: HubSectionSelectionProps): JSX.Element | null => {
    const {
        hidden,
        items = [],
        sectionConfig,
        hideTopSectionPadding,
        variant = '',
        needsThemeVerticalSpacer,
        hideItemSeperator = false
    } = props;
    const {accentColor = 'sky'} = sectionConfig;

    if (hidden) return null;

    const additionalProps = {accentColor, sectionConfig, dynamicSection: true};
    const firstComponentCanHandleSectionName = determineSectionNameSupport(items);

    return (
        <Styled>
            <SubnavSection
                sectionConfig={sectionConfig}
                hideTopSectionPadding={hideTopSectionPadding}
                hideSectionName={firstComponentCanHandleSectionName}
                hideBottomSectionPadding={!needsThemeVerticalSpacer}
                variant={variant}>
                <CmsComponentMapper
                    itemSeparator={
                        hideItemSeperator ? undefined : <VerticalSpacer variant="section" />
                    }
                    dynamicPageDataArray={items}
                    elementMapping={{
                        overviewGrid: <OverviewGridSection {...additionalProps} />,
                        promo: <PromoSection {...additionalProps} />,
                        logoList: <LogoList />,
                        featuresCarousel: <FeaturesCarouselSection {...additionalProps} />,
                        featuresGrid: <FeaturesGrid {...additionalProps} />,
                        embeddedVideo: <EmbeddedVideo {...additionalProps} />,
                        eventsAndNewsConfig: <SplitListSection {...additionalProps} />,
                        imageAndTextCard: <ImageAndTextCard {...additionalProps} />,
                        imageCompare: <UeImageCompare />,
                        introWithCards: <SectionIntroWithCards {...additionalProps} />,
                        largeQuote: <LargeQuote {...additionalProps} />,
                        multiCard: <MultiCard {...additionalProps} />,
                        mediaListConfig: <DividedList {...additionalProps} />,
                        splitList: <SplitListSection {...additionalProps} />,
                        textBlockLeftAligned: <LeftAlignedTextBlock {...additionalProps} />,
                        textBlockCenterAligned: <CenteredTextBlock {...additionalProps} />,
                        textBlockLine: <LineTextBlockWithContainer {...additionalProps} />,
                        feed: <FeedWrap cmsLoading={false} {...additionalProps} />,
                        faq: <FAQSection {...additionalProps} />,
                    }}
                />
            </SubnavSection>
        </Styled>
    );
};
