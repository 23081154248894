import axios, {AxiosResponse} from 'axios';
import {getLocale} from '@epic-core/common';
import {getOrigin} from 'epic-ue-shared';

const url = () => {
    const origin = getOrigin();

    return {
        ACADEMIC_PARTNERS_DATA: (locale) => `${origin}/api/v2/${locale}/education/academicpartners`
    };
};

class AcademicPartnersApi {
    public async getData(): Promise<any> {
        const locale = getLocale();
        const api = url().ACADEMIC_PARTNERS_DATA(locale);
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getAcademicPartners() failed to load');
            throw e;
        }
    }
}

export const academicPartnersApi = new AcademicPartnersApi();
