import React from 'react';
import {
    Header,
    Sections,
    CaseStudies,
    FeatureList,
    CollapseList,
    Interview,
    Gallery
} from '../../layout';
import {HighlightPanel, ButtonWrapper} from '..';
import {HighlightProps} from 'epic-ue-shared';

interface Properties {
    [x: string]: any;
}

export const DelegateComponent = (delegateItem: Properties): JSX.Element => {
    let type: JSX.Element | undefined;
    const id = delegateItem._type || '';
    const sections = delegateItem.sections || [];
    const panels = delegateItem.panels || [];
    const showcases = delegateItem.showcases || [];
    const buttons = delegateItem.buttons || [];
    const faqItems = delegateItem.faqList || [];

    switch (id) {
        case 'UEC Header':
            type = (
                <div>
                    <Header
                        title={delegateItem.title || ''}
                        height={delegateItem.height || ''}
                        subtitle={delegateItem.subtitle || ''}
                        tag={delegateItem.tag || ''}
                        desc={delegateItem.desc || ''}
                        type={delegateItem.type || ''}
                        showreel={delegateItem.showreel || {}}
                        buttons={delegateItem.buttons || []}
                        background={delegateItem.background || {}}
                    />
                </div>
            );
            break;
        case 'UEL Sections':
            type = (
                <div className="UEL-sections-wrapper">
                    {sections && <Sections sections={sections} />}
                </div>
            );
            break;
        case 'UEL Showcases':
            type = (
                <CaseStudies
                    title={delegateItem.title || ''}
                    label={delegateItem.btnLabel || ''}
                    url={delegateItem.btnUrl || ''}
                    showcases={showcases}
                />
            );
            break;
        case 'UEL Interview':
            type = (
                <Interview
                    title={delegateItem.title || ''}
                    interviewee={delegateItem.interviewee || {}}
                    questions={delegateItem.questions || []}
                />
            );
            break;
        case 'UEL Gallery':
            type = (
                <Gallery
                    title={delegateItem.title || ''}
                    type={delegateItem.type || 'simple'}
                    slides={delegateItem.slides || []}
                />
            );
            break;
        case 'UEL Features':
            type = (
                <FeatureList
                    title={delegateItem.title || ''}
                    desc={delegateItem.desc || ''}
                    label={delegateItem.label || ''}
                    href={delegateItem.href || ''}
                    features={delegateItem.features || []}
                />
            );
            break;
        case 'UEL Collapse List':
            type = <CollapseList title={delegateItem.title || ''} items={faqItems} />;
            break;
        case 'UEL Buttonwrapper':
            type = <ButtonWrapper buttons={buttons} />;
            break;
        case 'UEL Highligh panels':
            type = panels.map((panel: HighlightProps, i: number) => {
                return <HighlightPanel {...panel} key={`highlight-${i}-${panel.title}`} />;
            });
            break;
        default:
            type = <div />;
    }
    return <div>{type}</div>;
};
