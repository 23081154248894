import React from 'react';

import {FeedDetails, FeedDetailsInnerProperties} from '../components/feed/FeedDetails';
import {InitSharedValueResponse, AppSharedKeys, cmsApi, RouteMatchOrUrlParse} from 'epic-ue-shared';
import {getUrlPattern} from 'epic-ue-shared';
import {feedApi} from 'epic-ue-feed';
import {AltLocPageData} from 'epic-ue-shared';

export const TechBlogPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="tech-blog" />;
};

export const SpotlightPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="spotlights" />;
};

export const DeveloperInterviewPage = (props: FeedDetailsInnerProperties): JSX.Element => {
    return <FeedDetails {...props} rootPageSlug="developer-interviews" />;
};

export const feedPreloadSharedValues = async <T extends AltLocPageData>(
    urlPattern: string,
    pageData: T & {feedTag?: string; feedType?: string},
    preloadData: InitSharedValueResponse[]
): Promise<Array<InitSharedValueResponse>> => {
    const patternsToSkip = [urlPattern];
    const latestItems = (await feedApi.getFeedItems({patternsToSkip})) || {};

    preloadData.push({
        key: AppSharedKeys.FeedItemsLatestStore,
        value: {
            ...latestItems,
            tags: {},
            types: {},
            patternsToSkip
        }
    });

    const feedTag = pageData.feedTag || undefined;
    const feedType = pageData.feedType || 'all';
    if (feedTag) {
        const tags = {};
        const types = {};

        if (feedTag) {
            const parts = feedTag.split(',');
            if (parts && parts.length) {
                parts.forEach((part) => {
                    tags[part] = true;
                });
            } else {
                tags[feedTag] = true;
            }
        }

        if (feedType) {
            types[feedType] = true;
        }
        const feedItems = (await feedApi.getFeedItems({tags, types, patternsToSkip})) || {};
        preloadData.push({
            key: AppSharedKeys.FeedItemsStore,
            value: {
                ...feedItems,
                tags,
                types,
                patternsToSkip
            }
        });
    }

    return preloadData;
};

const initialFeedPageSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const urlPattern = getUrlPattern(match);
    const pageData = await cmsApi.getPageData(urlPattern);
    delete pageData.pageSlugParam;
    delete pageData.cmsPageMap;

    const valid = !!(urlPattern && pageData && Object.keys(pageData).length !== 0);
    let altLocPageData;
    if (!valid) {
        altLocPageData = await cmsApi.getAltLocPageData(urlPattern);
    }

    const preloadData = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [urlPattern]: altLocPageData || pageData || {}
            }
        }
    ];

    return feedPreloadSharedValues(urlPattern, pageData, preloadData);
};

TechBlogPage.getInitialSharedValues = initialFeedPageSharedValues;
SpotlightPage.getInitialSharedValues = initialFeedPageSharedValues;
DeveloperInterviewPage.getInitialSharedValues = initialFeedPageSharedValues;
