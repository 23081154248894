import styled from 'styled-components';

export const FeedItemDefaultsWrapper = styled.div`
    .feed-item {
        margin-bottom: 32px;
        background: ${(p) => p.theme.palette.background.default};
        h2 {
            font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
            color: ${(p) => p.theme.palette.text.primary};
            font-weight: 400;
            font-size: 26px;
            line-height: 34px;

            *,
            a {
                font-family: inherit;
                color: inherit;
            }

            &:hover,
            &:focus,
            &:active {
                color: ${(p) => p.theme.palette.text.secondary};
                *,
                a {
                    color: ${(p) => p.theme.palette.text.secondary};
                    text-decoration: none;
                }
            }
        }

        p {
            color: ${(p) => p.theme.palette.text.secondary};
        }

        .content {
            padding: 20px 20px 0 0;
            background: ${(p) => p.theme.palette.background.default};
            h2 {
                font-weight: 400;
                font-size: 26px;
                line-height: 34px;
                margin-top: 5px;
                padding: 0;
                text-align: left;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 1.1em;
                    line-height: 1.4em;
                }
            }

            .short {
                color: #dbdee3;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    font-size: 0.875em;
                }
            }
        }
    }
`;
