import React from 'react';
import {getLocale} from '@epic-core/common';

interface Properties {
    date: string;
}
import styled from 'styled-components';

export const DateWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
        display: inline-block;
        color: #cacdd1;
        font-size: 0.75em;
        margin-left: 15px;
        position: relative;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin-top: -5px;
        }
        &:before {
            content: '';
            background-color: #545556;
            position: absolute;
            left: -12px;
            top: 0;
            height: 28px;
            width: 1px;
        }
    }
`;

export const FeedDate = (props: Properties): JSX.Element => {
    const _feedDate = props.date || {};
    let date;
    if (typeof _feedDate === 'string') {
        date = new Date(_feedDate);
        if (date && Date.parse(date) && !isNaN(date)) {
            date = date.toLocaleDateString(getLocale(), {
                month: 'long',
                day: 'numeric'
            });
        }
    }

    return <DateWrapper className="feed-date">{date && <span>{date}</span>}</DateWrapper>;
};
