import React from 'react';
import {FeedItemProps} from '../../types';
import {GridRow} from './GridRow';

import styled from 'styled-components';

export const ReusableRowWrapper = styled.div``;
interface Props {
    items: FeedItemProps[];
    disableContentTypeLabelClick?: boolean;
    capSize?: number; //Allow for custom sizing
}

export const GridReusableRow = (props: Props): JSX.Element => {
    const items = props.items || [];
    const capSize = props.capSize || 3;
    const rowItems = items.slice(0, capSize);

    return (
        <ReusableRowWrapper className="container">
            <GridRow items={rowItems} />
        </ReusableRowWrapper>
    );
};
