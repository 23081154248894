import React, {ReactElement} from 'react';
import {Button} from './Button';
import styled from 'styled-components';
import {ButtonProps} from 'epic-ue-shared';

const Wrapper = styled.div`
    margin-top: 1em;
    position: relative;
    overflow: hidden;
    min-height: 80px;
    > a,
    > button {
        display: inline-block;
    }

    > button:first-child {
        &.btn-showcase {
            margin-left: 0 !important;
        }
    }

    a.simple {
        color: ${p => p.theme.ue.colors.buttons.tertiary.text.default};
        padding: 0 20px 0 40px;
        ${p => p.theme.breakpoints.down('sm')} {
            padding: 1em;
        }
        text-transform: uppercase;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: ${p => p.theme.ue.colors.buttons.tertiary.text.hover};
        }
    }

    .btn:first-child {
        margin-left: 0;
    }
`;

interface Properties {
    children?: ReactElement | ReactElement[];
    buttons?: ButtonProps[];
}

export const ButtonWrapper = ({children, buttons}: Properties): JSX.Element => {
    const buttonsList: ButtonProps[] = [];
    let data: ButtonProps[] = [];
    let childrenValid = false;

    if (buttons && buttons.length) {
        data = buttons;
    } else if (children) {
        childrenValid = true;
    }

    data.forEach(button => {
        if (button) {
            const label = button.label || '';
            const as = button.as || 'a';
            const href = button.href || undefined;
            const type = button.type || 'primary';
            const onClick = button.onClick || undefined;
            const additionalClass = button.className || '';
            if (label && (href || onClick)) {
                buttonsList.push(
                    <Button
                        key={`button-${type}-${label}`}
                        {...button}
                        as={as}
                        href={href}
                        type={type}
                        onClick={onClick}
                        className={additionalClass}
                        label={label}
                        id={button.id}>
                        {label}
                    </Button>
                );
            }
        }
    });

    if (buttonsList.length === 0 && !childrenValid) {
        return <div />;
    }

    return (
        <Wrapper className="button-wrapper">
            {buttonsList}
            {children}
        </Wrapper>
    );
};
