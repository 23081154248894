import React from 'react';
import PropTypes from 'prop-types';
import PartnerCard from './PartnerCard';
import styled from 'styled-components';

export const PartnerPanelWrapper = styled.div`
    .block-partners {
        padding: 0 20px 60px;
        text-align: center;
    }
    .block-partner-item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 20px 20px;
    }
    .partner-footer {
        font-size: 12px;
        margin: 2em auto 0;
        color: #797979;
        max-width: 80%;
    }
`;

/**
 * Partner Panel
 *
 * This Panel will loop through all of the partners in the partners array and
 * return the /events/partnerCard for each
 */
const PartnersPanel = (props) => {
    const partners = props.partners || [];
    return (
        <PartnerPanelWrapper className="block-white">
            <div className="block-title">{props.headline || ''}</div>
            <div className="container block-partners">
                {partners.length > 0 ? (
                    partners.map((partner, index) => (
                        <PartnerCard
                            key={partner.imageURL}
                            image={partner.imageURL}
                            alt={partner.alt}
                            targetURL={partner.targetURL}
                        />
                    ))
                ) : (
                    <div />
                )}

                <div className="partner-footer">
                    <div dangerouslySetInnerHTML={{__html: props.footerText || ''}} />
                </div>
            </div>
        </PartnerPanelWrapper>
    );
};
PartnersPanel.propTypes = {
    headline: PropTypes.string,
    partners: PropTypes.array,
    footerText: PropTypes.string
};

export default PartnersPanel;
