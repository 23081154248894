import React from 'react';
import styled from 'styled-components';
import {getOptImage} from 'epic-ue-shared';

interface Props {
    img: string;
    w?: number
    alt?: string;
    title?: string;
    disableLightbox?: boolean;
}

const Styled = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
`;

export const BgImg = ({img, w = 1920, alt = '', title = '', disableLightbox}: Props):JSX.Element => {
    const src = getOptImage({url: img, width: w});


    return (
        <Styled
            className={`imageBg ${disableLightbox ? 'ue-lightbox-img-ignore ue-lightbox-no-click' : ''}`}
            alt={alt}
            title={title}
            src={src}
            loading="lazy" />
    );
};

