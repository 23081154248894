import React from 'react';
import pt from 'prop-types';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import {getBowserState} from '@epic-core/common';
import styled from 'styled-components';

const BgImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transition: opacity ease 0.5s, transform ease 0.5s;
    background-image: url(${(p) => p.imageUrl || ''});
    cursor: ${(p) => (p.validVideo ? 'pointer' : 'default')};
`;

export const Credits = styled.div`
    position: absolute;
    bottom: 20px;
    left: ${(p) => (p.even ? 'initial' : '20px')};
    right: ${(p) => (!p.even ? 'initial' : '20px')};
    font-size: 0.7em;
    color: hsla(0, 0%, 100%, 0.6);
    pointer-events: none;

    ${(p) => p.theme.breakpoints.down('md')} {
        display: none;
    }
`;

const noop = () => {};

export default class SectionImage extends React.PureComponent {
    static propTypes = {
        title: pt.string,
        layout: pt.string,
        useOverlay: pt.bool,
        media: pt.object,
        passClick: pt.func,
        credits: pt.string,
        even: pt.bool
    };

    state = {
        inView: false
    };

    onClick = () => {
        const {media = {}, useOverlay, title} = this.props;
        const validVideo = useOverlay && this.isValidVideo(media);
        if (!validVideo) return;
        this.props.passClick(true, media, title);
    };

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                inView: isVisible
            });
        } else if (!isVisible && this.state.inView) {
            this.setState({inView: isVisible});
        }
    };

    isValidVideo(media) {
        if (!media) return false;
        if (media.type === 'youtubeVideo' && media.contentId) return true;
        if (media.type === 'htmlVideo' && media.content && media.content.length) return true;
        return false;
    }

    render() {
        const {useOverlay, layout, media = {}, credits, even} = this.props;
        const {mobile, tablet} = getBowserState();
        const type = media.type || '';
        const validVideo = useOverlay && this.isValidVideo(media);
        let imageUrl = '';

        if (!type) return <div />;

        if (
            (type === 'youtubeVideo' || type === 'image') &&
            media.content &&
            media.content.length
        ) {
            imageUrl = media.content[0];
        } else if (type === 'htmlVideo' && media.tabletImage) {
            imageUrl = media.tabletImage;
        }

        if (tablet && media.tabletImage) imageUrl = media.tabletImage;
        if (mobile) {
            if (media.mobileImage) {
                imageUrl = media.mobileImage;
            } else if (media.tabletImage) {
                imageUrl = media.tabletImage;
            }
        }

        if (type === 'image' && layout !== 'stacked') {
            return (
                <>
                    <BackgroundVideo background={media} />
                    <Credits className="credits" even={even}>
                        {credits}
                    </Credits>
                </>
            );
        }
        if (type === 'image' && media.content && media.content.length && layout === 'stacked') {
            return (
                <>
                    <img src={media.content[0]} alt="" loading="lazy" />
                    <Credits className="credits" even={even}>
                        {credits}
                    </Credits>
                </>
            );
        }
        if (type !== 'image' && imageUrl) {
            return (
                <>
                    <BgImage
                        imageUrl={imageUrl}
                        validVideo={validVideo}
                        role="presentation"
                        onClick={useOverlay ? this.onClick : noop}
                    />
                    <Credits className="credits" even={even}>
                        {credits}
                    </Credits>
                </>
            );
        }

        return null;
    }
}
