import React from 'react';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import {extractSlugLocale} from '@epic-mw/localization';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, Header, CopySection, SimpleLink, Card} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {getLocale} from '@epic-core/common';
import {useLocation} from 'react-router-dom';
import {PluginDownload} from '../components/plugins/PluginDownload';
import {BackgroundProps} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';


const Plugins = styled.div`
    h1 {
        text-align: center;
    }
    .copy {
        margin-top: 2em;
    }
    div.copy,
    div.copy p {
        text-align: left;
    }
    .denied-copy {
        padding: 150px 0;
    }
    .studio-plugin-list {
        display: flex;
        flex-direction: row;
        padding: 50px 0;
        justify-content: center;
        flex-wrap: wrap;
    }
    .studio-downloads-links {
        .card > div {
            text-align: center;
        }
    }
    .card {
        max-width: 400px;
        margin: 30px auto 80px;
    }
    &&& {
        .btn {
            min-width: initial;
            padding: 15px 23px;
        }
    }

    .plugin-container.container {
        @media screen and (min-width: 1600px) {
            max-width: 1600px;
            width: 1600px;
        }
    }
    .plugin-container .plugin-select div[role='button'] {
        padding-right: 60px;
    }
`;
interface Items {
    items: {
        title: string;
    }[];
}

interface Links {
    linkList: {
        targetURL: string;
        title: string;
    }[];
}

interface PluginsPageData extends PageData {
    archiveLabel?: string;
    badgeLabel?: string;
    latestLabel?: string;
    bgImg: string;
    credits: string;
    downloadButtonCaption: string;
    intro: string;
    items: Items;
    links: Links;
    linkTitle: string;
    title: string;
    releaseNoteLabel?: string;
    tpsNoticesLabel?: string;
}

const PluginsPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const {pageSlug} = extractSlugLocale(pathname, getLocale());
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<PluginsPageData>(pageSlug);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const itemsMap = pageData.items || {};
    const linksMap = pageData.links || {};
    const downloads = itemsMap.items || [];
    const links = linksMap.linkList || [];

    const background: BackgroundProps = {
        type: 'image',
        image: pageData.bgImg,
        opacity: 0.6
    };

    return (
        <Page>
            <Plugins>
                {getHelmetForMetaTags(pageData)}

                <Header
                    type="slim"
                    title={pageData.title}
                    background={background}
                    credits={pageData.credits}
                />

                <CopySection title="" copy={pageData.intro} />

                <div className="container plugin-container">
                    <div className="studio-plugin-list">
                        {downloads.map((download) => (
                            <PluginDownload
                                key={download.title}
                                download={download}
                                n={downloads.length}
                                archiveLabel={pageData.archiveLabel}
                                latestLabel={pageData.latestLabel}
                                badgeLabel={pageData.badgeLabel}
                                releaseNoteLabel={pageData.releaseNoteLabel}
                                tpsNoticesLabel={pageData.tpsNoticesLabel}
                            />
                        ))}
                    </div>

                    {pageData.linkTitle && links && links.length ? (
                        <div className="studio-downloads-links">
                            <Card title={pageData.linkTitle}>
                                {links.map((link) => (
                                    <div key={link.title}>
                                        <SimpleLink title={link.title} href={link.targetURL} />
                                    </div>
                                ))}
                            </Card>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Plugins>
        </Page>
    );
};

PluginsPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default PluginsPage;
