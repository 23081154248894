import {BlogDetailProps, BlogPostProps, FeedImagesProps, FeedLogoProps} from 'epic-ue-shared';
import {ImmutableObject} from 'seamless-immutable';

export default function getLatestBlogs(
    blogList: BlogDetailProps[],
    blog: BlogDetailProps,
    number = 3
): BlogDetailProps[] {
    if (!blog || !blogList || blogList.length === 0) return [];
    let blogListMutable: BlogDetailProps[] = [];
    if (blogList) {
        blogListMutable = (typeof ((blogList as unknown) as ImmutableObject<BlogDetailProps>)
            .asMutable === 'function'
            ? ((blogList as unknown) as ImmutableObject<BlogDetailProps>).asMutable()
            : blogList) as BlogDetailProps[];
    }
    // Filter out current blog
    blogListMutable = blogListMutable.filter((item) => {
        const itemSlug = item._slug || item.slug;
        const blogSlug = blog._slug || blog.slug;
        return itemSlug !== blogSlug;
    });
    blogListMutable.sort((a, b) => {
        const dateA = new Date((a.date || a._activeDate) as string);
        const dateB = new Date((b.date || b._activeDate) as string);
        const aSlug = a._slug || a.slug;
        const blogSlug = blog._slug || blog.slug;

        if (aSlug === blogSlug) return 0;
        if (dateA < dateB) return 1;
        if (dateA > dateB || aSlug === blogSlug) return -1;
        return 0;
    });
    return blogListMutable.slice(0, number);
}

export function getBlogField(
    blog: BlogDetailProps,
    field: keyof BlogDetailProps
): string | boolean | undefined | string[] | BlogPostProps | FeedLogoProps | FeedImagesProps {
    if (!blog || typeof blog !== 'object') return;
    const post = blog.post;
    if (post && typeof post === 'object' && post.title) {
        return post[field];
    }
    return blog[field];
}

export function getBlogPost(blog: BlogDetailProps): BlogDetailProps | BlogPostProps | null {
    if (!blog || typeof blog !== 'object') return null;
    const post = blog.post;
    if (post && typeof post === 'object' && (post.content || post.short)) {
        return post;
    }
    return blog;
}
