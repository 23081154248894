import React, { useState } from 'react';

import { UEMap, UEMapPanel, UEMapOverlay, UEMapInfoPanel, UEMapSearchPanel } from 'epic-ue-map';
import { BottomPanel, LeftPanel, RightPanel } from './AcademicPartnersPage.styles';
import { generateRoutePath } from '@epic-core/common';
import { Navigate } from 'react-router-dom';
import { Page, H1, Button } from 'epic-ue-components';
import { UnrealLoading } from 'epic-ue-loading';
import { getHelmetForMetaTags } from 'epic-ue-shared';
import { useAcademicPartners, academicPartnersApi, AcademicPartners } from '../shared';
import { PageData, useCmsData, cmsApi } from 'epic-ue-shared';
import { AppSharedKeys } from 'epic-ue-shared';
import UE_logo_with_circle from '../../../images/ue5-logo-with-circle-map.png';
import UE_logo_30 from '../../../images/ue5-logo-white-30x30.png';
import { useSharedValue } from '@epic-core/hooks';
import { clickedItemInViewKey, MarkerProps } from 'epic-ue-map';

const cmsUrlPattern = '/academic-partners';
export interface AcademicPartnersPageData extends PageData {
    content1: string;
    title: string;
    link: string;
    linkText: string;
    partnersInViewText: string;
    seeAllText: string;
    searchPlaceholder: string;
    countryOptionText: string;
    programOptionText: string;
    noSearchResultText: string;
    programTypeText: string;
}

const AcademicPartnersPage = (): JSX.Element => {
    const { partners = {} as AcademicPartners, loading: apiLoading, error } = useAcademicPartners();
    const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
    const [, setClickedItemInView] = useSharedValue<MarkerProps | null>(clickedItemInViewKey);
    const { cmsLoading, cmsLoadedEmpty, pageData } = useCmsData<AcademicPartnersPageData>(
        cmsUrlPattern
    );

    if (apiLoading || cmsLoading) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />;
            </>
        );
    }

    if (cmsLoadedEmpty || error) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <Navigate to={generateRoutePath('/academic-partners-apply')} />
            </>
        );
    }

    const {
        content1,
        link,
        linkText,
        title,
        partnersInViewText,
        countryOptionText,
        seeAllText,
        programOptionText,
        programTypeText = 'Filter on program type',
        noSearchResultText,
        searchPlaceholder
    } = pageData;

    const items = (partners.elements || []).map(({ coordinates, ..._meta }) => {
        return {
            coordinates,
            _meta
        } as MarkerProps;
    });

    return (
        <Page>
            <>
                {getHelmetForMetaTags(pageData)}
                <UEMap
                    id="academic-map"
                    markerIcon={UE_logo_30}
                    clusterMarkers={true}
                    clusterIcon={UE_logo_with_circle}
                    minZoom={2}
                    maxZoom={12}
                    clusterThreshold={5}
                    items={items}
                    markerTooltipConfig={{
                        properties: [
                            { property: 'Name', url: 'Website', variant: 'primary' },
                            { property: 'Program_Name__c' },
                            { property: 'BillingStreet' },
                            { property: 'BillingCity' },
                            { property: 'BillingState' },
                            { property: 'BillingPostalCode' },
                            { property: 'BillingCountry' }
                        ]
                    }}>
                    <UEMapSearchPanel
                        data={items}
                        displayConfig={{
                            properties: [
                                { property: 'Name', url: 'Website' },
                                { property: 'Program_Name__c' },
                                { property: 'ProgramTypes' },
                                { property: 'BillingStreet' },
                                { property: 'BillingCity' },
                                { property: 'BillingState' },
                                { property: 'BillingPostalCode' },
                                { property: 'BillingCountry' }
                            ]
                        }}
                        dropdownFilters={[
                            { placeholder: programOptionText, property: 'Program_Name__c' },
                            { placeholder: programTypeText, property: 'ProgramTypes' },
                            { placeholder: countryOptionText, property: 'BillingCountry' }
                        ]}
                        noSearchResultText={noSearchResultText}
                        onClose={() => setOpenSearchModal(false)}
                        searchPlaceholder={searchPlaceholder}
                        show={openSearchModal}
                        textFilters={['Name', 'Program_Name__c']}
                        title={title}
                        onClickSearchItem={(item) => {
                            setOpenSearchModal(false);
                            setClickedItemInView(item);
                        }}
                    />
                    <UEMapOverlay>
                        <UEMapInfoPanel
                            displayConfig={{
                                properties: [
                                    { property: 'Name', url: 'Website' },
                                    { property: 'Program_Name__c' },
                                    { property: 'BillingStreet' },
                                    { property: 'BillingCity' },
                                    { property: 'BillingState' },
                                    { property: 'BillingPostalCode' },
                                    { property: 'BillingCountry' }
                                ]
                            }}
                            title={partnersInViewText}
                            maxItemsToDisplay={40}
                        />
                        <UEMapPanel variant="gradient" align="bottom">
                            <BottomPanel>
                                <LeftPanel>
                                    <H1>{title}</H1>
                                    {content1 ? (
                                        <div dangerouslySetInnerHTML={{ __html: content1 }} />
                                    ) : (
                                        ''
                                    )}
                                </LeftPanel>
                                <RightPanel>
                                    <Button
                                        id="seeAllPartners"
                                        type="black"
                                        onClick={() => setOpenSearchModal(true)}
                                        title={seeAllText}
                                    />
                                    <Button
                                        id="academicApplyButton"
                                        type="primary"
                                        href={link}
                                        icon={{ position: 'before', className: 'icon-plus' }}
                                        title={linkText}
                                    />
                                </RightPanel>
                            </BottomPanel>
                        </UEMapPanel>
                    </UEMapOverlay>
                </UEMap>
            </>
        </Page>
    );
};

AcademicPartnersPage.getInitialSharedValues = async (): Promise<any> => {
    const responses = await Promise.all([
        academicPartnersApi.getData(),
        cmsApi.getPageData(cmsUrlPattern)
    ]);
    return [
        {
            key: AppSharedKeys.AcademicPartnersStore,
            value: {
                ...responses[0]
            }
        },
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: responses[1] || {}
            }
        }
    ];
};

export default AcademicPartnersPage;
