import {getLocale} from '@epic-core/common';
import {Typography, UeLink, VerticalSpacer} from 'epic-ue-ui';
import React from 'react';
import styled from 'styled-components';
import {CleanedBlobRes} from '../../shared/blobs.fetch';

export interface LinuxTableLabels {
    fileName?: string;
    size?: string;
    dateUploaded?: string;
    link?: string;
    download?: string;
    gB?: string;
    sorry?: string;
    hideDownload?: boolean;
}

export interface LinuxBlobsTableProps extends LinuxTableLabels {
    blobs?: CleanedBlobRes[];
    labels?: LinuxTableLabels;
}

const Styled = styled.div`
    ${(p) => p.theme.breakpoints.down('sm')} {
        max-width: 100vw;
        overflow-x: scroll;
    }

    // scrollbar styles
    ::-webkit-scrollbar {
        height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #a7a7ae;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #0057d9;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    thead {
        text-align: left;
        background-color: ${(p) => p.theme.palette.neutral.concrete};
    }

    tbody {
        td {
            border-bottom: 1px solid ${(p) => p.theme.palette.neutral.steel};
        }
    }

    th,
    td {
        padding: 20px;
        &.center {
            text-align: center;
        }
    }

    .sorry {
        text-align: center;
    }
`;

export const LinuxBlobsTable = ({
    blobs = [],
    labels = {},
    hideDownload = false
}: LinuxBlobsTableProps): JSX.Element => {
    const {
        fileName = 'File name',
        size = 'Size',
        dateUploaded = 'Date Uploaded',
        link = 'link',
        download = 'download',
        gB = 'GB',
        sorry = 'No builds uploaded yet. Stay tuned!'
    } = labels;

    if (!blobs || !blobs.length)
        return (
            <Styled>
                <VerticalSpacer variant="heading" />
                <Typography className="sorry" variant="h3">
                    {sorry}
                </Typography>
            </Styled>
        );

    const locale = getLocale();

    return (
        <Styled>
            <table>
                <thead>
                    <tr>
                        <th>
                            <Typography variant="button">{fileName}</Typography>
                        </th>
                        <th className="center">
                            <Typography variant="button">{size}</Typography>
                        </th>
                        <th className="center">
                            <Typography variant="button">{dateUploaded}</Typography>
                        </th>
                        {!hideDownload && (
                            <th className="center">
                                <Typography variant="button">{link}</Typography>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {blobs.map((blob, i) => {
                        const dateStr = new Date(blob.createdAt).toLocaleDateString(locale, {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        });
                        const gb = Math.round((blob.size / 1024 / 1024 / 1024) * 100) / 100;
                        return (
                            <tr key={`linux-table-row-${i}`}>
                                <td>
                                    <Typography variant="p">{blob.name}</Typography>
                                </td>
                                <td className="center">
                                    <Typography variant="p">
                                        {gb} {gB}
                                    </Typography>
                                </td>
                                <td className="center">
                                    <Typography variant="p">{dateStr}</Typography>
                                </td>
                                {!hideDownload && (
                                    <td className="center">
                                        <Typography variant="button">
                                            <UeLink href={blob.url}>{download}</UeLink>
                                        </Typography>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Styled>
    );
};
