import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {truncateByLength} from '@epic-core/common';
import CUSTOM_CLASSES from '../../constants/CustomClasses';
import {getLocale} from '@epic-core/common';
import {BlogPostProps, SpotlightPageData} from 'epic-ue-shared';

const getData = (post: BlogPostProps | SpotlightPageData) => {
    if (post._templateName === 'spotlight') {
        const article = (post as SpotlightPageData).article || {};
        return {
            slug: post._slug,
            title: post._title,
            short: article.short,
            date: post._activeDate
        };
    }
    return {...post};
};

interface Properties {
    post: BlogPostProps;
    postRouteId?: string;
    appendClassToParent: string;
    enableAutoShortDescription: boolean;
    descriptionLimit: number;
    includeImage: boolean;
    slotStyle: {
        backgroundImage: string;
    };
    linkUrlPrefix?: string;
    routerRootUrl: string;
}

export const ContentContainer = (props: Properties): JSX.Element => {
    const post = props.post || {};
    const {customDate, sticky, externalLink} = post;
    const {slug, short, title, date} = getData(post);
    const alt = post.alt || '';
    const {
        appendClassToParent,
        postRouteId,
        descriptionLimit,
        enableAutoShortDescription,
        includeImage,
        slotStyle,
        linkUrlPrefix
    } = props;
    const locale = getLocale();
    let dateShown;
    if (customDate) {
        dateShown = customDate;
    } else {
        dateShown = !date
            ? ''
            : new Date(date).toLocaleDateString(locale.replace(/_/g, '-'), {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric'
              });
    }
    const routeUrl = props.routerRootUrl;
    const pageLink = externalLink || `${linkUrlPrefix || ''}${routeUrl}/${slug}`;
    const slugRoutePath = generateRoutePath(pageLink);
    const summary = short;
    const description =
        enableAutoShortDescription && summary && summary.length > descriptionLimit
            ? truncateByLength(summary, descriptionLimit)
            : summary;

    const categorySpanAttributes = {
        className: `activityGridRow-date ${CUSTOM_CLASSES.date}`,
        // Add '_' as rendering will create issue with inner html
        'data-date_': dateShown
    };

    return (
        <div
            id={`qa-${slug}`}
            className={classNames(
                appendClassToParent,
                {'sticky-post': sticky},
                CUSTOM_CLASSES.root
            )}
            key={`post-${slug}`}>
            {includeImage && (
                <Link to={slugRoutePath} id={`news-sm_img-${slug}`} className="image-link">
                    <div
                        className={classNames('image', {'hide-category': true})}
                        style={slotStyle}
                        title={alt}
                    />
                </Link>
            )}
            <div className={`hide-category ${CUSTOM_CLASSES.category}`}>
                <span {...categorySpanAttributes}>{dateShown}</span>
            </div>
            <h2 className={classNames('headline', CUSTOM_CLASSES.title, {'hide-category': true})}>
                <Link
                    className="accent-hover-color title-case"
                    id={`${postRouteId}${slug}`}
                    to={slugRoutePath}>
                    {title}
                </Link>
            </h2>
            <div
                className={classNames('short', CUSTOM_CLASSES.short)}
                dangerouslySetInnerHTML={{__html: description}}
            />
        </div>
    );
};
