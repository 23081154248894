import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';

export default class RowType6 extends Row {
    static propTypes = {
        items: PropTypes.array,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        return (
            <div>
                <div
                    className="grid-news-row type-6"
                    ref={(c) => {
                        this.gridRowRef = c;
                    }}>
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[0]}
                        type="small-block"
                    />
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[1]}
                        type="small-block"
                    />
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[2]}
                        type="small-block"
                    />
                </div>
                <div className="grid-news-row type-6a">
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[3]}
                        type="small-block"
                    />
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[4]}
                        type="small-block"
                    />
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.props.items[5]}
                        type="small-block"
                    />
                </div>
            </div>
        );
    }
}
