import React from 'react';
import {H2, ButtonWrapper} from '../../../elements';
import styled from 'styled-components';
import {ButtonProps} from 'epic-ue-shared';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

const CopySectionWrapper = styled.div`
    width: 100%;
    a:hover,
    a:visited,
    a:active,
    a:focus {
        text-decoration: none;
    }
    a:focus {
        outline: 0;
    }

    * {
        text-align: center;
    }
    h2 {
        max-width: 950px;
        line-height: 1.2em;
        margin: 20px auto 40px;
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
    }
    .icon-quote-open {
        display: inline;
        font-size: 3em;
        padding: 20px;
    }
    .copy {
        margin: 0 auto 40px;
        padding: 0 20px;
        font-size: 1.15em;
        line-height: 1.7em;
        max-width: 950px;
        position: relative;
        overflow: hidden;
        ${(p) => p.theme.breakpoints.down('md')} {
            margin-bottom: 24px;
        }
        &.quote {
            display: inline;
        }
        &.pull-quote {
            font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
            font-size: 1.8em;
            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 1.1em;
            }
            color: ${(p) => p.theme.ue.colors.primary};
            text-transform: uppercase;
            font-style: italic;
        }

        ol,
        ul {
            text-align: left;
            margin-block-start: 4px;
            margin-block-end: 4px;

            li {
                text-align: inherit;
                margin-bottom: 0.95em;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

const CopyWrapper = styled.div`
    text-align: center;
    position: relative;
    overflow: visible;
    &.pull-quote,
    &.quote {
        max-width: 800px;
        margin: 0 auto;
    }

    &.pull-quote {
        border-left: 2px solid ${(p) => p.theme.ue.colors.cards.default.background};
        border-top: 2px solid ${(p) => p.theme.ue.colors.cards.default.background};
        padding-top: 20px;
        padding-left: 20px;

        span {
            display: none;
        }
    }

    &.pull-quote.quote {
        border: 0;
        padding: 0;
    }
`;

export const AuthorWrapper = styled.div`
    text-align: center;
    font-style: italic;
    padding: 20px 0;
    margin: 0 auto;
    font-size: 0.875em;
    position: relative;
    vertical-align: middle;
`;

export interface CopySectionProps {
    title?: string;
    copy?: string;
    lbClass?: string;
    quote?: boolean;
    pullQuote?: boolean;
    author?: string;
    buttons?: ButtonProps[];
    id?: string;
}
export const CopySection = ({
    title,
    copy = '',
    lbClass,
    buttons,
    quote,
    pullQuote,
    author,
    id
}: CopySectionProps): JSX.Element => {
    return (
        <CopySectionWrapper id={id} className={`copy-section ${lbClass ? lbClass : ''}`}>
            {title ? (
                <H2 className="section-heading center" dangerouslySetInnerHTML={{__html: title}} />
            ) : (
                ''
            )}
            {copy ? (
                <CopyWrapper className={`${quote ? 'quote' : ''} ${pullQuote ? 'pull-quote' : ''}`}>
                    {quote ? <i className="icon-quote-open" /> : ''}
                    <div
                        className={`copy ${quote ? 'quote' : ''} ${pullQuote ? 'pull-quote' : ''}`}
                        dangerouslySetInnerHTML={{__html: sanitizeCopy(copy)}}
                    />
                    <span>{quote || (pullQuote && quote) ? '”' : ''}</span>
                </CopyWrapper>
            ) : (
                <div />
            )}
            {quote && author ? <AuthorWrapper className="author">- {author}</AuthorWrapper> : ''}
            {buttons ? <ButtonWrapper buttons={buttons} /> : <div />}
        </CopySectionWrapper>
    );
};
