import styled from 'styled-components';

export const BottomPanel = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 12px;

    ${p => p.theme.breakpoints.down('md')} {
        flex-direction: column;
        flex: 1;
    }

    p {
        max-width: 80%;
    }
`;

export const LeftPanel = styled.div`
    flex: 2;
`;

export const RightPanel = styled.div`
    flex: 1;
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: auto;
    font-size: 12px;

    button#seeAllPartners {
        border: none;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        &:hover {
            background: rgba(0, 0, 0, 0.6);
        }
    }

    #seeAllPartners,
    #academicApplyButton {
        pointer-events: auto;
        padding: 2em 1.5em;
        font-weight: bold;
    }

    ${p => p.theme.breakpoints.down('md')} {
        flex: none;
    }

    ${p => p.theme.breakpoints.down('sm')} {
        font-size: 10px;
    }
`;
