import React from 'react';
import pt from 'prop-types';
import Message from '../../message/Message';

export default class Day extends React.PureComponent {
    static propTypes = {
        day: pt.string,
        events: pt.array,
        active: pt.string,
        mobile: pt.bool,
        tablet: pt.bool
    };

    state = {
        data: this.props.events,
        toggle: false,
        sortKey: '',
        showDesc: null
    };

    handleClick = e => {
        if (e) {
            const data = this.state.data;
            const toggle = this.state.toggle;
            const target = e.currentTarget;
            const id = target.dataset.sort;
            const arrayCopy = [...data];
            if (toggle) {
                arrayCopy.sort(this.compareBy(id)).reverse();
            } else {
                arrayCopy.sort(this.compareBy(id));
            }

            this.setState({
                data: arrayCopy,
                toggle: !toggle,
                sortKey: id
            });
        }
    };

    compareBy(key) {
        return function(a, b) {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
        };
    }

    showDesc(title, desc) {
        if (!desc || !desc.length) return;

        let showDesc = title;
        if (this.state.showDesc === title) {
            showDesc = '';
        }
        this.setState({
            showDesc
        });
    }

    render() {
        const {day} = this.props;
        const data = this.state.data;
        const sortKey = this.state.sortKey;
        const showDesc = this.state.showDesc;
        const toggle = this.state.toggle;
        const mobile = this.props.mobile;
        const tablet = this.props.tablet;
        const tableData = [];
        const tableHeader = [];
        const activeTab = this.props.active;

        let className = 'tab-list-item';
        if (activeTab === day) {
            className += ' tab-list-active';
        }

        if (data !== undefined && data !== null) {
            data.map((event, key) => {
                let time = '';
                let location = '';
                let room = '';
                let title = '';
                let desc = '';

                Object.keys(event).map((entry, key) => {
                    if (entry !== '_type') {
                        switch (entry) {
                            case undefined:
                                event[entry] = '';
                                break;
                            case 'time':
                                time = event[entry];
                                break;
                            case 'location':
                                location = event[entry];
                                break;
                            case 'room':
                                room = event[entry];
                                break;
                            case 'desc':
                                desc = event[entry];
                                break;
                            case 'title':
                                title = event[entry];
                                break;
                            default:
                        }
                    }
                });

                if (key === 0) {
                    tableHeader.push(
                        <tr
                            key={key}
                            className={`${showDesc === title ? 'open' : ''} ${
                                toggle ? 'toggle' : ''
                            }`}>
                            <th
                                data-sort="startTime"
                                className={sortKey === 'startTime' ? 'active' : ''}
                                onClick={this.handleClick}>
                                <Message code="epic.event.time" /> <span>&nbsp;</span>
                            </th>
                            <th
                                data-sort="location"
                                className={sortKey === 'location' ? 'active' : ''}
                                onClick={this.handleClick}>
                                <Message code="epic.event.location" /> <span>&nbsp;</span>
                            </th>
                            <th
                                data-sort="title"
                                className={sortKey === 'title' ? 'active' : ''}
                                onClick={this.handleClick}>
                                <Message code="epic.event.description" /> <span>&nbsp;</span>
                            </th>
                        </tr>
                    );
                }

                tableData.push(
                    <tr
                        key={key}
                        className={`${
                            title && this.state.showDesc === title ? 'open' : ''
                        } ${desc && 'has-desc'}`}
                        onClick={() => this.showDesc(title, desc)}>
                        <td>{time}</td>
                        <td>
                            {location}
                            {location && location.length && room && room.length ? ', ' : ''}
                            {room}
                        </td>
                        <td>
                            {title}
                            <span dangerouslySetInnerHTML={{__html: desc}} />
                            {desc && desc.length && (
                                <i
                                    className={
                                        this.state.showDesc === title
                                            ? 'icon-chevron-up'
                                            : 'icon-chevron-down'
                                    }
                                />
                            )}
                        </td>
                    </tr>
                );
            });
        }

        return (
            <table className={className}>
                {mobile || tablet ? (
                    <colgroup>
                        <col style={{width: '25%'}} />
                        <col style={{width: '25%'}} />
                        <col style={{width: '50%'}} />
                    </colgroup>
                ) : (
                    <colgroup>
                        <col style={{width: '20%'}} />
                        <col style={{width: '20%'}} />
                        <col style={{width: '60%'}} />
                    </colgroup>
                )}
                <thead>{tableHeader}</thead>
                <tbody>{tableData}</tbody>
            </table>
        );
    }
}
