import React from 'react';
import PropTypes from 'prop-types';
import RowType1 from './rows/RowType1';
import RowType2 from './rows/RowType2';
import RowType3a from './rows/RowType3a';
import RowType3b from './rows/RowType3b';
import RowType4a from './rows/RowType4a';
import RowType4b from './rows/RowType4b';
import RowType5 from './rows/RowType5';
import RowType6 from './rows/RowType6';
import {getCardImage} from '../../../utils/enterpriseUtils';

export default class MosaicRow extends React.PureComponent {
    static propTypes = {
        firstIsFeatured: PropTypes.bool,
        items: PropTypes.array,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        forceLayout: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    };

    extractBestFit(desiredWeight, items) {
        const itemWeights = items.map(item => item.weight);

        let mutableDesiredWeight = desiredWeight; // to satisfy eslint no Param reasignment

        // find the index of a item of a matching item
        let index = itemWeights.indexOf(mutableDesiredWeight);
        while (index < 0 && mutableDesiredWeight > 0) {
            mutableDesiredWeight -= 1;
            index = itemWeights.indexOf(mutableDesiredWeight);
        }

        if (mutableDesiredWeight === 0) {
            return null;
        }

        const bestFitItem = items[index];
        // mutate the array by removing it
        items.splice(index, 1);
        return bestFitItem;
    }

    bestFitToRow = (desiredWeightArray, items) => {
        // Make a clone of the array as the proceeding method will mutate it
        const mutableItemArray = [...items];

        const bestFitRowArray = desiredWeightArray.map(desiredWeight =>
            this.extractBestFit(desiredWeight, mutableItemArray)
        );
        return bestFitRowArray;
    };

    render() {
        const items = this.props.items || [];

        const props = {
            firstIsFeatured: this.props.firstIsFeatured,
            items,
            locale: this.props.locale,
            bestFitToRow: this.bestFitToRow,
            titleFitText: this.props.titleFitText,
            textFitText: this.props.textFitText,
            forceLayout: this.props.forceLayout
        };

        const i = props.items.length;

        if (i === 1) {
            return <RowType1 {...props} />;
        } else if (i === 2) {
            return <RowType2 {...props} />;
        } else if (i === 3) {
            let allSmallBlocks = true;
            props.items.forEach(item => {
                if (getCardImage(item)) {
                    allSmallBlocks = false;
                }
            });
            if (allSmallBlocks) return <RowType3a type="small-block" {...props} />;
            return <RowType3b {...props} />;
        } else if (i === 4) {
            let allSmallBlocks = true;
            props.items.forEach(item => {
                if (getCardImage(item)) {
                    allSmallBlocks = false;
                }
            });
            if (allSmallBlocks) return <RowType4a {...props} />;
            return <RowType4b {...props} />;
        } else if (i === 5) {
            return <RowType5 {...props} />;
        } else if (i === 6) {
            return <RowType6 {...props} />;
        }

        return <RowType3a {...props} />;
    }
}
