import React from 'react';
import {AccentColorOptions} from 'epic-ue-theme';
import {HubSectionSelection} from './HubSectionSelection';
import {
    CmsDynamicSectionProps,
    functionalKeysAlwaysTopPadding,
    functionalKeysNeverBottomPadding,
    SubnavSectionConfigProps
} from 'epic-ue-ui';

type Theme = 'light' | 'dark';

interface DynamicHeaderProps {
    items: CmsDynamicSectionProps[];
    accentColor: AccentColorOptions;
    theme: Theme;
}

const getNeedsThemeVerticalSpacer = (
    theme: Theme,
    sectionConfig: SubnavSectionConfigProps,
    dynamicSections: CmsDynamicSectionProps[],
    index: number
) => {
    if (!dynamicSections || !dynamicSections.length) return false;

    //if the last item in the current section matches key, never add the vertical spacer
    const currentSection = dynamicSections[index] || {};
    const currentSectionItems = currentSection.items || [];
    if (currentSectionItems.length && currentSectionItems[currentSectionItems.length - 1]) {
        const lastItemCurrentSection = currentSectionItems[currentSectionItems.length - 1] || {};
        const keys = Object.keys(lastItemCurrentSection) || [];
        if (keys && keys.length) {
            if (functionalKeysNeverBottomPadding.includes(keys[0])) {
                return false;
            }
        }
    }

    //look at the next section and see if we need vertical spacer
    if (index + 1 < dynamicSections.length) {
        const nextSection = dynamicSections[index + 1];
        if (nextSection && Object.keys(nextSection).length) {
            //if the first item in the next section matches key, always add vertical spacer
            const nextSectionItems = nextSection.items || [];
            if (nextSectionItems.length && nextSectionItems[0]) {
                const firstItemNextSection = nextSectionItems[0];
                const keys = Object.keys(firstItemNextSection) || [];
                if (keys && keys.length) {
                    if (functionalKeysAlwaysTopPadding.includes(keys[0])) {
                        return true;
                    }
                }
            }

            //if the current theme color is different than the next section we need the vertical spacer
            const currentThemeColor =
                sectionConfig.theme && sectionConfig.theme !== 'inherit'
                    ? sectionConfig.theme
                    : theme;
            let nextThemeColor = '';

            const nextSectionConfig = nextSection.sectionConfig || {};
            if (nextSectionConfig.theme) {
                nextThemeColor =
                    nextSectionConfig.theme === 'inherit' ? theme : nextSectionConfig.theme;
            }

            return Boolean(nextThemeColor && nextThemeColor !== currentThemeColor);
        }
    }
};

export const DynamicHubSections = (props: DynamicHeaderProps): JSX.Element | null => {
    const {items: dynamicSections = [] as CmsDynamicSectionProps[], accentColor, theme} = props;
    let dynamicSectionIndex = 0;

    if (!dynamicSections || !dynamicSections.length) return null;

    const sections = dynamicSections.map((dynamicSectionData, index) => {
        const {sectionConfig = {}} = dynamicSectionData;
        const extendedSectionConfig = {
            ...sectionConfig,
            accentColor,
            index: sectionConfig.index || dynamicSectionIndex
        };
        const hideTopSectionPadding = sectionConfig.hideTopSectionPadding;
        const hideItemSeperator = sectionConfig.hideItemSeperator;

        if (sectionConfig.id) {
            ++dynamicSectionIndex;
        } else {
            //console.warn('DynamicSections detected a section without an id', sectionConfig, index);
        }

        const needsThemeVerticalSpacer = getNeedsThemeVerticalSpacer(
            theme,
            sectionConfig,
            dynamicSections,
            index
        );

        return (
            <HubSectionSelection
                key={index}
                {...dynamicSectionData}
                sectionConfig={extendedSectionConfig}
                needsThemeVerticalSpacer={needsThemeVerticalSpacer}
                hideTopSectionPadding={hideTopSectionPadding}
                hideItemSeperator={hideItemSeperator}
            />
        );
    });

    return <>{sections}</>;
};
