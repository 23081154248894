import React from 'react';
import {CopySection, TwoColSection, BackgroundSection, CardSection, GistSection} from './type/';
import {
    Logos,
    BgMedia,
    ImageCompare,
    ButtonWrapper,
    isUnsupportedBackgroundVideo,
    Button
} from '../../elements';
import {Features, Gallery} from '../../layout';
import {
    FullWidth,
    SectionWrapper,
    StackedColumn,
    StackedMedia,
    StackedWrapper
} from './Section.styles';
import {getBowserState} from '@epic-core/common';
import VisibilitySensor from 'react-visibility-sensor';
import {AnimatedSection} from './type';
import {SectionProps} from 'epic-ue-shared';
import {useLocalization} from '@epic-mw/localization';

const addToLightboxClass = 'ue-lb-wrapper';

const isValidMedia = (media) => {
    if (!media) return false;
    if (media.type === 'image' && media.image) return true;
    if (media.type === 'youtubeVideo' && media.contentId) return true;
    if (media.type === 'htmlVideo' && media.content && media.content[0]) return true;
    if (media.type === 'embedVideo' && media.embedSrc) return true;
};

export const Section = ({
    type,
    title,
    desc,
    quote,
    pullQuote,
    author,
    media,
    logos,
    features,
    gallery,
    compare,
    paddingOverride,
    buttons,
    even,
    sectionBg,
    githubGist
}: SectionProps): JSX.Element => {
    const {mobile, tablet} = getBowserState();
    const mediaType = (media && media.type) || '';
    const hasCaption = (media && media.caption) || '';
    const isVideo = mediaType.toLowerCase().indexOf('video') > -1;
    let section: JSX.Element | undefined;
    const [inView, setInView] = React.useState(false);
    const getMessage = useLocalization();
    const visChange = React.useCallback(
        (isVisible) => {
            // the animation cause link require double click on mobile device
            if (mobile || tablet || isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    const unsupportedVideo = isUnsupportedBackgroundVideo(media);

    const stackedType = (
        <StackedWrapper>
            <div className="container">
                <div className="row">
                    <CopySection
                        title={title}
                        lbClass={addToLightboxClass}
                        copy={desc}
                        quote={quote}
                        pullQuote={pullQuote}
                        author={author}
                    />
                    {media && media !== undefined && (
                        <StackedColumn>
                            {unsupportedVideo && (
                                <div style={{textAlign: 'center'}}>
                                    <Button type="primary" href={media.embedSrc}>
                                        {getMessage('epic.watch.video')}
                                    </Button>
                                </div>
                            )}

                            {!unsupportedVideo && isValidMedia(media) ? (
                                <StackedMedia
                                    className={`stacked-media ${addToLightboxClass}${
                                        isVideo ? ' video' : ''
                                    }`}>
                                    <BgMedia
                                        background={media}
                                        autoplay={!media?.disableAutoplay}
                                        controls={media?.disableAutoplay}
                                        mute={!media?.disableAutoplay}
                                        forceVideoMobile={media?.forceVideoMobile}
                                        enableRatioHelper={false}
                                    />
                                </StackedMedia>
                            ) : (
                                ''
                            )}
                            {buttons ? <ButtonWrapper buttons={buttons} /> : <div />}
                        </StackedColumn>
                    )}
                </div>
            </div>
            {features ? <Features features={features} /> : ''}
            {logos ? <Logos logos={logos} inView={inView} /> : ''}
            {compare && compare.leftImage ? (
                <div className="img-compare">
                    <ImageCompare
                        leftImage={compare.leftImage}
                        rightImage={compare.rightImage}
                        caption={compare.caption}
                    />
                </div>
            ) : (
                ''
            )}
        </StackedWrapper>
    );

    switch (type) {
        case 'githubGist':
            section = (
                <div>
                    <GistSection {...githubGist} />
                </div>
            );
            break;
        case 'copy':
            section = (
                <div>
                    <CopySection
                        title={title}
                        lbClass={addToLightboxClass}
                        copy={desc}
                        quote={quote}
                        pullQuote={pullQuote}
                        author={author}
                        buttons={buttons}
                    />
                    {features ? <Features features={features} /> : ''}
                    {logos ? <Logos logos={logos} inView={inView} /> : ''}
                    {compare && compare.leftImage ? (
                        <div className="img-compare">
                            <ImageCompare
                                leftImage={compare.leftImage}
                                rightImage={compare.rightImage}
                                caption={compare.caption}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            );
            break;
        case 'stacked':
            section = stackedType;
            break;
        case 'fullWidth2Col':
            section = (
                <FullWidth
                    className={`fw section-2col-wrapper ${even ? 'even' : ''} ${
                        isVideo ? 'left-margin' : ''
                    }`}>
                    <TwoColSection
                        title={title}
                        mobile={mobile}
                        tablet={tablet}
                        copy={desc}
                        lbClass={addToLightboxClass}
                        buttons={buttons}
                        media={media}
                        even={even}
                    />
                </FullWidth>
            );
            break;
        case 'contained':
            section = (
                <div className={`contained container section-2col-wrapper ${even ? 'even' : ''}`}>
                    <div className="row">
                        <TwoColSection
                            title={title}
                            mobile={mobile}
                            tablet={tablet}
                            copy={desc}
                            lbClass={addToLightboxClass}
                            buttons={buttons}
                            media={media}
                            even={even}
                        />
                    </div>
                    {features ? <Features features={features} /> : ''}
                    {logos ? <Logos logos={logos} inView={inView} /> : ''}
                    {compare && compare.leftImage ? (
                        <div className="img-compare">
                            <ImageCompare
                                leftImage={compare.leftImage}
                                rightImage={compare.rightImage}
                                caption={compare.caption}
                                imgWidth={compare.width}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            );
            break;
        case 'fifty':
        case 'threes':
        case 'background':
            section = (
                <BackgroundSection
                    lbClass={addToLightboxClass}
                    title={title}
                    quote={quote}
                    pullQuote={pullQuote}
                    author={author}
                    copy={desc}
                    buttons={buttons}
                    media={media}
                />
            );
            break;
        case 'card':
            section = (
                <CardSection
                    title={title}
                    copy={desc}
                    lbClass={addToLightboxClass}
                    buttons={buttons}
                    media={media}
                />
            );
            break;
        case 'branding':
            section = <AnimatedSection title={title} copy={desc} buttons={buttons} />;
            break;
        default:
            section = stackedType;
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <SectionWrapper
                paddingOverride={paddingOverride}
                className={`visibility-check type-${type || 'default'} ${
                    quote ? 'has-quote' : ''
                } ${pullQuote ? 'has-pull-quote' : ''} ${inView ? 'inView' : ''} ${
                    even ? 'even' : ''
                } ${hasCaption ? 'has-caption' : ''}`}>
                <BgMedia background={sectionBg} />
                <div className="section-container">{section}</div>
                {gallery && gallery.slides && gallery.slides.length > 0 ? (
                    <div className="gallery-container">
                        <Gallery
                            type={gallery.type || 'simple'}
                            title={gallery.title || ''}
                            lbClass={addToLightboxClass}
                            slides={gallery.slides || []}
                        />
                    </div>
                ) : (
                    ''
                )}
            </SectionWrapper>
        </VisibilitySensor>
    );
};
