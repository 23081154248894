import styled from 'styled-components';

export const Content = styled.div`
    padding: 1em 1em 3em;
    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 1em 2em 3em;
    }

    .mainContent.mainContent {
        h1 {
            font-size: 2.2em;
            margin-bottom: 0em;
            padding-bottom: 0em;
        }
        h2,
        h3,
        h4 {
            margin-bottom: 10px;
        }
        h1,
        h2,
        h3,
        strong {
            a {
                color: #fff;
            }
        }
    }

    .warning {
        margin-top: 10px;
        background-color: ${(p) => p.theme.palette.background.paper};
        border-left: 5px solid ${(p) => p.theme.ue.colors.buttons.link.default};
        margin-bottom: 30px;
        font-family: Brutal, Brutal_Medium, Arial, sans-serif;
        padding: 15px;
        .message {
            margin-left: 15px;
            color: #fff;
            h3 {
                margin-top: 0;
                &:before {
                    content: '';
                    width: 42px;
                    height: 35px;
                    background-image: url(https://cdn2.unrealengine.com/Product/OlderVersions/warning-42x35-1809175040.png);
                    background-size: 30px 25px;
                    background-repeat: no-repeat;
                    background-position: 2px;
                    float: left;
                    margin-right: 5px;
                    transform: translate3d(0, 0, 0);
                    filter: invert(56%) sepia(32%) saturate(1729%) hue-rotate(162deg)
                        brightness(94%) contrast(102%);
                }
                a {
                    color: ${(p) => p.theme.ue.colors.buttons.link.default};
                }
            }
        }
    }

    .top-header {
        h1 {
            text-align: center;
            margin-bottom: 10px;
        }
        margin-bottom: 40px;
    }

    .list-block.list-block {
        margin-top: 0.5em;
        margin-bottom: 1.2em;
        padding: 20px 0;
        background-color: ${(p) => p.theme.palette.background.paper};
        h2 {
            font-size: 18px;
            text-transform: uppercase;
            padding: 0.625em;
            padding-bottom: 0.5em;
            margin: 0.25em 0;
            text-align: center;
        }
        p {
            position: relative;
            margin: 0;
            padding: 0 1em;
        }
        ul {
            position: relative;
            list-style: none;
            padding: 0 30px;
            li {
                color: #fff;
                a {
                    text-transform: capitalize;
                    font-size: 16px;
                    cursor: pointer;
                    color: #fff;
                    &:hover {
                        text-decoration: none;
                    }
                }
                &:before {
                    content: '\\E902';
                    font-family: 'ue-icomoon' !important;
                    display: inline-block;
                    position: relative;
                    font-size: 10px;
                    margin-right: 10px;
                }
            }
        }
    }

    hr {
        border-top: 1px solid #222222;
    }
    #accordion {
        strong {
            color: #fff;
        }
        li {
            margin-top: 20px;
        }
    }
`;

export const Branding = styled.div`
    padding: 1em 0 3em;
    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 1em 1.5em 3em;
    }

    .brandMain {
        max-width: 65%;
        min-width: 320px;
        float: left;
        margin-right: 20px;
        ${(p) => p.theme.breakpoints.down('md')} {
            max-width: 100%;
            float: none;
            min-width: initial;
            margin: 0;
            img {
                width: 90%;
            }
        }
        img {
            padding: 2em;
            width: 50%;
            transform: translateX(-50%);
            left: 50%;
            position: relative;
        }
    }

    .dlContent {
        max-width: 29%;
        min-width: 316px;
        float: right;
        position: relative;
        background: ${(p) => p.theme.palette.background.default};
        padding: 0.25em;
        ${(p) => p.theme.breakpoints.down('md')} {
            float: left;
            max-width: 100%;
            width: 100%;
            padding: 1em;
        }

        h3 {
            font-size: 1.4em;
            border-bottom: 1px solid #333333;
            padding-top: 10px;
            padding-bottom: 0.25em;
            margin-bottom: 0;
            margin-top: 5px;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        ul .dItem {
            font-size: 14px;
            list-style: none;
            border-bottom: 1px solid #333333;
            padding: 0.2em 0;
            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 12px;
            }
        }
    }

    .brandMain h1 {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 14px;
    }

    .dItem:after {
        content: '';
        display: block;
        clear: both;
    }

    .dItem span {
        color: #939597;
        font-family: 'OpenSans', sans-serif;
        font-size: 11px;
        padding-top: 3px;
        font-weight: normal;
        display: inline-block;
    }
`;
