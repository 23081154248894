import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';

export default class RowType3a extends Row {
    static propTypes = {
        items: PropTypes.array,
        bestFitToRow: PropTypes.func,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        type: PropTypes.string,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        const type = this.props.type || 'vertical';

        return (
            <div
                className="grid-news-row type-3a"
                ref={(c) => {
                    this.gridRowRef = c;
                }}>
                {this.props.items.map((item, index) => (
                    <Card
                        {...this.props}
                        {...this.state}
                        key={item.title}
                        highlight={item}
                        type={type}
                    />
                ))}
            </div>
        );
    }
}
