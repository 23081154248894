import React, {useState} from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import {MarkerProps} from './UEMap';

export const SearchPanel = styled.div<{show: boolean}>`
    display: ${props => (props.show ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #00000096;
    pointer-events: auto;
    z-index: 10;
    * {
        outline: none;
    }

    .main {
        display: flex;
        border-radius: 12px;
        position: absolute;
        top: 2%;
        right: 2%;
        left: 2%;
        bottom: 2%;
        background: ${p => p.theme.ue.colors.cards.default.background};
        padding: 3%;
        flex-direction: column;
        overflow: hidden;

        .top-bar {
            display: flex;
            margin-bottom: 1em;
            h3 {
                margin: 0;
                flex: 20;
            }
            i {
                flex: 1;
                align-items: center;
                justify-content: center;
                display: flex;
                font-size: 1em;
                &:focus {
                    background: ${p => p.theme.ue.colors.cards.overlay.background};
                }
            }
        }

        .filters {
            display: flex;
            flex-direction: row;

            ${p => p.theme.breakpoints.down('md')} {
                display: block;
            }

            .filter,
            .searchText {
                height: 3em;
                flex: 1;
                margin-right: 1em;

                ${p => p.theme.breakpoints.down('md')} {
                    flex: none;
                    margin-bottom: 1em;
                    margin-right: 0;
                }
            }

            .searchText {
                padding-left: 1em;
                border: 1px solid hsl(0, 0%, 80%);
                background: transparent;
                &:focus {
                    background: ${p => p.theme.ue.colors.cards.overlay.background};
                }

                ${p => p.theme.breakpoints.down('md')} {
                    width: 100%;
                }
            }

            .filter {
                > * {
                    height: 3em;
                }
            }
        }

        .lists {
            margin-top: 1em;
            overflow-y: auto;
            flex: 1;

            ul {
                padding-right: 2em;
                list-style: none;
                padding: 0;
                padding-right: 2em;
                height: 100%;
                pointer-events: auto;
                display: flex;
                flex-wrap: wrap;

                ${p => p.theme.breakpoints.down('md')} {
                    padding-right: 0;
                }

                li {
                    list-style: none;
                    width: 25%;
                    min-width: 260px;
                    padding: 10px;
                    &:hover {
                        background: ${p => p.theme.ue.colors.cards.overlay.background};
                    }
                    &:focus-within {
                        background: ${p => p.theme.ue.colors.cards.overlay.background};
                    }

                    ${p => p.theme.breakpoints.down('md')} {
                        width: 100%;
                        min-width: 100%;
                    }

                    span {
                        font-size: 0.7em;
                    }
                }
            }
        }
    }
`;

const SearchItemBadge = styled.span`
    display: inline-block;
    background-color: ${p => p.theme.ue.colors.buttons.secondary.text.disabled};
    padding: 0.25em 0.5em;
    margin: 0.5em;
    margin-left: 0;
`;

interface DropdownFilterProps {
    placeholder: string;
    property: string;
}

interface SearchItemDisplayProps {
    property: string;
    url?: string;
}

interface SearchItemDisplayConfig {
    properties: SearchItemDisplayProps[];
}

interface UEMapSearchPanelProps {
    data?: MarkerProps[];
    displayConfig: SearchItemDisplayConfig;
    dropdownFilters?: DropdownFilterProps[];
    noSearchResultText: string;
    onClickSearchItem: (item: MarkerProps | null) => void;
    onClose: () => void;
    searchPlaceholder: string;
    show: boolean;
    textFilters?: string[];
    title: string;
}

const OptionStyles = {
    control: (provided, state) => ({
        ...provided,
        background: 'transparent',
        color: 'white',
        borderRadius: 'none'
    }),
    menu: (provided, state) => ({
        ...provided,
        background: '#232323'
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isFocused ? '#111111' : '#232323'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: 'white'
    })
};

export const UEMapSearchPanel = ({
    data = [],
    displayConfig = {properties: []},
    dropdownFilters = [],
    show,
    noSearchResultText,
    onClickSearchItem,
    onClose,
    searchPlaceholder,
    textFilters = [],
    title
}: UEMapSearchPanelProps): JSX.Element | null => {
    const [textFilter, setTextFilter] = useState<string>('');
    const [activeDropdownFilters, setActiveDropdownFilters] = useState({});

    if (!data.length) return null;

    const list = data
        // Filter on the search textbox
        .filter(({_meta}) => {
            if (!textFilter) {
                return true;
            }
            const textFilterResult = textFilters.some((key: string) => {
                const comparisonValue = (_meta[key] || '').toLowerCase();
                return comparisonValue.indexOf(textFilter.toLowerCase()) > -1;
            });
            return textFilterResult;
        })
        // Additionally, filter on any active dropdown filters
        .filter(({_meta}) => {
            return Object.keys(activeDropdownFilters).reduce(
                (filterAccumulator: boolean, key: string) => {
                    // Short-circuit filtering if we've already determined the item is not a match
                    if (!filterAccumulator) {
                        return filterAccumulator;
                    }

                    const filterValue = activeDropdownFilters[key]
                        ? activeDropdownFilters[key].toLowerCase()
                        : '';

                    if (!filterValue) {
                        return true;
                    }

                    if (Array.isArray(_meta[key])) {
                        return _meta[key].some(x => x.toLowerCase() === filterValue);
                    } else if (typeof _meta[key] === 'string') {
                        const comparisonValue = (_meta[key] || '').toLowerCase();
                        if (comparisonValue !== filterValue) {
                            return false;
                        }
                    }

                    return filterAccumulator;
                },
                true
            );
        });

    const filterObj = dropdownFilters.reduce((acc, {property, placeholder}) => {
        acc[property] = {
            property,
            placeholder,
            options: []
        };

        return acc;
    }, {});

    for (const item of data) {
        for (const filterKey of Object.keys(filterObj)) {
            const {options} = filterObj[filterKey];
            const filterValue = item._meta[filterKey];

            if (Array.isArray(filterValue)) {
                filterValue.forEach(v => {
                    if (v && !options.includes(v)) {
                        options.push(v);
                    }
                });
            } else if (filterValue && !options.includes(filterValue)) {
                options.push(filterValue);
            }
        }
    }

    const onTextChange = e => {
        setTextFilter(e.target.value || '');
    };

    const onFilterChange = (filterName, option) => {
        setActiveDropdownFilters({
            ...activeDropdownFilters,
            [filterName]: option && option.value ? option.value : ''
        });
    };

    const onClickSearchItemFn = item => {
        if (typeof onClickSearchItem === 'function') {
            onClickSearchItem(item);
        }
    };

    return (
        <SearchPanel show={show}>
            <div className="main">
                <div className="top-bar">
                    <h3>{title}</h3>
                    <i className="icon-cross" onClick={onClose} role="button" tabIndex={0} />
                </div>
                <div className="filters">
                    {textFilters.length && (
                        <input
                            type="text"
                            className="searchText"
                            onChange={onTextChange}
                            value={textFilter}
                            placeholder={searchPlaceholder}
                        />
                    )}
                    {Object.keys(filterObj).map(filterKey => {
                        const {property, placeholder, options} = filterObj[filterKey];
                        return (
                            <Select
                                key={filterKey}
                                className={`filter filter-${property}`}
                                styles={OptionStyles}
                                options={options.map(value => ({value, label: value}))}
                                placeholder={placeholder}
                                onChange={option => onFilterChange(property, option)}
                                isClearable
                            />
                        );
                    })}
                </div>
                <div className="lists">
                    {list.length > 0 ? (
                        <ul>
                            {list.map((el, index) => {
                                const {_meta} = el;
                                return (
                                    <li key={index}>
                                        <div
                                            onClick={() => onClickSearchItemFn(el)}
                                            role="button"
                                            tabIndex={index}>
                                            {displayConfig.properties.map(
                                                ({property = '', url = ''}, subIndex) => {
                                                    if (url) {
                                                        return (
                                                            <div key={subIndex}>
                                                                <a
                                                                    href={_meta[url]}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">
                                                                    {_meta[property]}
                                                                </a>
                                                            </div>
                                                        );
                                                    }

                                                    if (Array.isArray(_meta[property])) {
                                                        return (
                                                            <div key={subIndex}>
                                                                {_meta[property].map((val, i) => {
                                                                    return (
                                                                        <SearchItemBadge key={i}>
                                                                            {val}
                                                                        </SearchItemBadge>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div key={subIndex}>{_meta[property]}</div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div>{noSearchResultText}</div>
                    )}
                </div>
            </div>
        </SearchPanel>
    );
};
