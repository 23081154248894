import React from 'react';
import { getTitle, getTagMatch, getSlugPath } from '../../../utils';
import { FeedItemProps } from '../../../types';
import VisibilitySensor from 'react-visibility-sensor';
import Hidden from '@material-ui/core/Hidden';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { LargeFeedCard } from './LargeFeedCard';
import { SmallFeedCard } from './SmallFeedCard';
import { MediumFeedCard } from './MediumFeedCard';

interface Properties {
    item: FeedItemProps;
    disableClick?: boolean;
    largeCard?: boolean;
}

export const FeedCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const largeCard = props.largeCard || false;
    const disableClick = props.disableClick || false;
    const title = getTitle(item);
    const matchTag = getTagMatch(item);
    const linkPath = getSlugPath(item);
    const [inView, setInView] = React.useState(false);
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    let regularCardBreakpoint: Breakpoint[] = ['xs', 'sm'];
    if (!largeCard) {
        regularCardBreakpoint = ['xs', 'sm', 'md', 'lg'];
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <>
                {largeCard ? (
                    <Hidden only={['md', 'lg', 'xl']} implementation="css">
                        <SmallFeedCard
                            item={item}
                            title={title}
                            tagMatch={matchTag}
                            linkPath={linkPath}
                            disableClick={disableClick}
                        />
                    </Hidden>
                ) : (
                    <Hidden only={['xl']} implementation="css">
                        <MediumFeedCard
                            item={item}
                            title={title}
                            tagMatch={matchTag}
                            linkPath={linkPath}
                            disableClick={disableClick}
                        />
                    </Hidden>
                )}

                <Hidden only={regularCardBreakpoint} implementation="css">
                    <LargeFeedCard
                        item={item}
                        title={title}
                        tagMatch={matchTag}
                        linkPath={linkPath}
                        disableClick={disableClick}
                    />
                </Hidden>
            </>
        </VisibilitySensor>
    );
};
