import React from 'react';
import {UEMapPanel} from './UEMapPanel';
import {MapInfoPanel} from './UEMapInfoPanel.styles';
import {UEMapStateContext, clickedItemInViewKey} from './UEMap';
import {useSharedValue} from '@epic-core/hooks';

interface InfoPanelItemConfig {
    property: string;
    url?: string;
}

interface UEMapInfoPanelDisplayConfig {
    properties: InfoPanelItemConfig[];
}
interface UEMapInfoPanelProps {
    displayConfig: UEMapInfoPanelDisplayConfig;
    maxItemsToDisplay?: number;
    mapInfoItemRenderFn?(): void;
    title?: string;
}

export const UEMapInfoPanel = ({
    displayConfig = {properties: []},
    maxItemsToDisplay = 20,
    title
}: UEMapInfoPanelProps): JSX.Element => {
    const [, setClickedItemInView] = useSharedValue(clickedItemInViewKey);

    return (
        <UEMapStateContext.Consumer>
            {context => {
                const {itemsInView = []} = context;
                if (!itemsInView || !itemsInView.length || itemsInView.length > maxItemsToDisplay) {
                    return null;
                }
                return (
                    <UEMapPanel variant="contained" align="right">
                        <MapInfoPanel>
                            {title && <h5>{title}</h5>}
                            <ul>
                                {itemsInView.map((item, index) => {
                                    const {_meta = {}} = item;
                                    return (
                                        <li
                                            key={index}
                                            onClick={() => setClickedItemInView(item)}
                                            role="presentation">
                                            {displayConfig.properties.map(
                                                ({property, url = ''}, propIndex) => {
                                                    return (
                                                        <div
                                                            role="button"
                                                            key={propIndex}
                                                            tabIndex={index}>
                                                            <div>
                                                                {url ? (
                                                                    <a
                                                                        href={_meta[url]}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">
                                                                        {_meta[property]}
                                                                    </a>
                                                                ) : (
                                                                    <span>{_meta[property]}</span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </MapInfoPanel>
                    </UEMapPanel>
                );
            }}
        </UEMapStateContext.Consumer>
    );
};
