import React, {useRef, useEffect, useState, useCallback} from 'react';
import {getDataAttribute} from '@epic-core/common';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import {useClipboard} from 'use-clipboard-copy';
import {useLocalization} from '@epic-mw/localization';
import {getBowserState} from '@epic-core/common';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

const SocialSharing = styled.div<{offsetHeight?: string}>`
    width: 45px;
    position: absolute;
    right: 100px;
    top: ${(p) => (p.offsetHeight ? p.offsetHeight : '70vh')};
    ${(p) => p.theme.breakpoints.up('hd')} {
        right: 200px;
    }
    z-index: 999;
    &.fixed {
        position: fixed;
        top: 200px;
    }

    ${(p) => p.theme.breakpoints.down('lg')} {
        display: none;
        position: fixed !important;
        width: 100%;
        height: 55px;
        padding: 5px 0;
        bottom: 0 !important;
        top: auto !important;
        left: 0;
        border-top: 1px solid #424242;
        background: ${(p) => p.theme.palette.background.paper};
        text-align: center;
        ul {
            margin: 0 auto;
            li {
                display: inline-block;
                margin-right: 2px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 55px;
    }

    &.fixed {
        position: fixed;
        ${(p) => p.theme.breakpoints.down('lg')} {
            display: block;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            background: ${(p) => p.theme.palette.background.paper};
            margin-bottom: 2px;
            width: 45px;
            height: 45px;
            ${(p) => p.theme.breakpoints.down('lg')} {
                width: 50px;
                height: 45px;
                padding: 15px 0;
                background: ${(p) => p.theme.palette.background.paperpop};
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 40px;
                height: 30px;
                padding: 8px 0;
                font-size: 1.3em;
            }
            text-align: center;
            color: ${(p) => p.theme.palette.text.primary};
            padding: 15px 0;
            cursor: pointer;
            &.icon-link {
                font-size: 1.25em;
                vertical-align: middle;

                ${(p) => p.theme.breakpoints.down('lg')} {
                    vertical-align: top;
                }
            }
            &:hover {
                background-color: ${(p) => p.theme.palette.background.paperpop};
            }
        }
    }

    .qrcode-block {
        position: fixed;
        width: 500px;
        height: 200px;
        margin: 5% auto;
        left: 0;
        right: 0;
    }

    #copyLink {
        display: none;
        pointer-events: none;
    }
`;

const getDocumentHeight = () => {
    const doc = root.document;
    return Math.max(
        doc.body.scrollHeight,
        doc.documentElement.scrollHeight,
        doc.body.offsetHeight,
        doc.documentElement.offsetHeight,
        doc.body.clientHeight,
        doc.documentElement.clientHeight
    );
};

export interface SimpleSocialShareProps {
    title: string;
    tweet: string;
    offsetHeight?: string;
    fixedScrollDisplayOffsetPx?: number;
    copyMessageStr?: string;
}

export const SimpleSocialSharing = ({
    title,
    tweet,
    offsetHeight,
    fixedScrollDisplayOffsetPx = 800,
    copyMessageStr
}: SimpleSocialShareProps): JSX.Element => {
    const SocialSharingElem = useRef<HTMLDivElement>(null);
    const clipboard = useClipboard();
    const getMessage = useLocalization();
    const [fixSocial, setFixSocial] = useState(false);
    const [showWeChatQr, setShowWeChatQr] = useState(false);
    const scrollListener = useCallback(
        (e) => {
            const sse = SocialSharingElem;
            if (sse && sse.current) {
                let newFixSocial = false;
                const sseCurrent = sse.current;
                const bounds = sseCurrent.getBoundingClientRect();
                const scrollTop = root.pageYOffset || root.document.documentElement.scrollTop;
                const socialTop = Math.ceil(bounds.top);

                if (scrollTop > fixedScrollDisplayOffsetPx) {
                    newFixSocial = true;
                } else if (socialTop < fixedScrollDisplayOffsetPx) {
                    newFixSocial = false;
                }

                const docHeight = getDocumentHeight();
                const pixelsFromBottom = docHeight - root.innerHeight - root.scrollY;
                const footer = root.document.getElementById('egf');
                if (footer) {
                    const footerBounds = footer.getBoundingClientRect();

                    if (pixelsFromBottom < footerBounds.height + 10) {
                        newFixSocial = false;
                    }
                    setFixSocial(newFixSocial);
                }
            }
        },
        [setFixSocial, fixSocial, SocialSharingElem]
    );

    const hideQr = useCallback(
        (e) => {
            setShowWeChatQr(false);
        },
        [setShowWeChatQr]
    );

    const clipboardHandle = useCallback(
        (e) => {
            const shareUrl = root.location.href;
            clipboard.copy(shareUrl);
        },
        [clipboard.copy]
    );

    const socialClicked = useCallback(
        (e) => {
            e.preventDefault();
            const socialPlatform = getDataAttribute(e.currentTarget, 'platform');
            emitEvent({
                eventAction: 'social.icon.click',
                eventLabel: 'SimpleSocialShare',
                eventValue: socialPlatform
            });
            let shareUrl = root.location.href;

            switch (socialPlatform) {
                case 'twitter':
                    shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        tweet
                    )}&url=${encodeURIComponent(shareUrl)}`;
                    break;
                case 'facebook':
                    shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                        shareUrl
                    )}`;
                    break;
                case 'vk':
                    shareUrl = `http://vk.com/share.php?url=${encodeURIComponent(shareUrl)}`;
                    break;
                case 'linkedin':
                    shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                        shareUrl
                    )}`;
                    break;
                case 'tumblr':
                    shareUrl = `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${encodeURIComponent(
                        shareUrl
                    )}`;
                    break;
                case 'reddit':
                    shareUrl = `https://reddit.com/submit?url=${encodeURIComponent(shareUrl)}`;
                    break;
                case 'weibo':
                    shareUrl = `http://service.weibo.com/share/share.php?url=${encodeURIComponent(
                        shareUrl
                    )}&title=${title}`;
                    break;
                case 'wechat':
                    setShowWeChatQr(true);
                    break;
                default:
                    break;
            }
            if (socialPlatform !== 'wechat' && socialPlatform !== 'link') {
                root.open(shareUrl, '_blank', 'toolbar=no, resizeable=yes, width=600, height=450');
            }
        },
        [title, tweet, setShowWeChatQr]
    );

    useEffect(() => {
        root.document.addEventListener('scroll', scrollListener, false);
        return () => {
            root.document.removeEventListener('scroll', scrollListener, false);
        };
    }, [scrollListener, root]);

    const socialIconList: (JSX.Element | undefined)[] = [<div key="socialList" />];
    const language = root.navigator.language;
    const locale = getLocale();
    const {mobile} = getBowserState();

    let platformList = ['facebook', 'twitter', 'linkedin', 'reddit', 'tumblr', 'link'];
    if (locale === 'zh-cn' || language === 'zh-cn' || language === 'zh') {
        platformList = ['weibo', 'wechat'];
    } else if (language === 'ru') {
        platformList = ['vk', 'facebook', 'twitter', 'linkedin', 'reddit'];
    }

    platformList.map((platform, index) => {
        if (platform && platform !== 'link') {
            const platformCapitalized =
                platform.charAt(0).toUpperCase() + platform.slice(1) || platform;
            socialIconList.push(
                <li
                    role="presentation"
                    data-platform={platform}
                    title={platformCapitalized}
                    className={`icon-${platform} ${platform}`}
                    onClick={socialClicked}
                    key={`social-${platform}`}
                />
            );
        } else if (platform && platform === 'link') {
            socialIconList.push(
                <li
                    role="presentation"
                    data-platform={platform}
                    title={copyMessageStr || getMessage('epic.social.copy')}
                    className={`icon-${platform} ${platform}`}
                    onClick={clipboardHandle}
                    key={`social-${platform}`}
                />
            );
        }
    });

    return (
        <SocialSharing
            ref={SocialSharingElem}
            offsetHeight={offsetHeight}
            className={`social-sharing-wrapper ${fixSocial ? ' fixed' : ''} ${
                mobile ? ' isMobile' : ''
            }`}>
            <ul>{socialIconList}</ul>
            {showWeChatQr ? (
                <div role="presentation" className="qrcode-block" onClick={hideQr}>
                    <QRCode value={root.location.href} />
                </div>
            ) : (
                ''
            )}
        </SocialSharing>
    );
};
