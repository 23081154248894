import React from 'react';
import root from 'window-or-global';
import styled from 'styled-components';
import Pagination from 'rc-pagination';
import {ArrowLeftAlt, ArrowRightAlt, Typography, VerticalSpacer} from 'epic-ue-ui';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';
import {AccentColorOptions} from 'epic-ue-theme';

const Styled = styled.div<{accentColor?: AccentColorOptions; isTwinmotion?: boolean}>`
    .rc-pagination {
        list-style: none;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        ${(p) => p.theme.breakpoints.down('sm')} {
            justify-content: center;
        }
        & > li {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-item {
            cursor: pointer;
            width: ${(p) => p.theme.spacing(5)}px;
            height: ${(p) => p.theme.spacing(5)}px;
            ${(p) => p.theme.breakpoints.down('xs')} {
                width: ${(p) => p.theme.spacing(4)}px;
                height: ${(p) => p.theme.spacing(4)}px;
            }
            transition: color 0.25s ${(p) => p.theme.easing};

            //UE style
            ${(p) => (!p.isTwinmotion ? `font-size: 16px;` : '')}

            &:hover {
                color: ${(p) =>
                    p.accentColor
                        ? p.theme.palette.accent[p.accentColor]
                        : p.theme.palette.accent.dark.orchid};
                cursor: pointer;

                //UE style
                ${(p) =>
                    !p.isTwinmotion
                        ? `border: 1px solid rgb(85, 85, 85);
                         border-radius: 3px;`
                        : ''}
            }
            &-link {
                display: none;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 0 4px;
            }
        }
        &-item-active {
            cursor: default;
            user-select: none;

            /*Twinmotion vs UE styles*/
            ${(p) =>
                p.isTwinmotion
                    ? ` border: 2px solid ${p.theme.palette.accent.dark.orchid};
                border-radius: 50px;
                &:hover {
                    color: unset;
                    cursor: default;
                }
            `
                    : `
                padding: 6px 10px;
                margin: 0.25em;
                border: 1px solid rgb(68, 68, 68);
                border-radius: 3px;
                box-sizing: border-box;
            `}
        }
        &-jump-prev,
        &-jump-next {
            width: ${(p) => p.theme.spacing(5)}px;
            height: ${(p) => p.theme.spacing(5)}px;
            ${(p) => p.theme.breakpoints.down('xs')} {
                width: ${(p) => p.theme.spacing(3)}px;
                height: ${(p) => p.theme.spacing(3)}px;
            }
            transition: color 0.25s ${(p) => p.theme.easing};
            &:hover {
                cursor: pointer;

                /*Twinmotion vs UE styles*/
                ${(p) =>
                    p.isTwinmotion
                        ? `
                    color: ${(p) => p.theme.palette.accent.dark.orchid};
                `
                        : `
                    ${(p) =>
                        !p.isTwinmotion
                            ? `border: 1px solid rgb(85, 85, 85);
                                border-radius: 3px;`
                            : ''}
                `}
            }
            &:after {
                content: '...';
                display: flex;
                justify-content: center;
                align-items: center;
                width: ${(p) => p.theme.spacing(5)}px;
                height: ${(p) => p.theme.spacing(5)}px;
                ${(p) => p.theme.breakpoints.down('xs')} {
                    width: ${(p) => p.theme.spacing(3)}px;
                    height: ${(p) => p.theme.spacing(3)}px;
                }
                transition: color 0.25s ${(p) => p.theme.easing};
            }
        }
        &-prev,
        &-next {
            //UE style
            ${(p) =>
                p.isTwinmotion
                    ? `font-size: 26px;`
                    : `
                font-size: 16px;
            `}

            align-self: center;
            transition: color 0.25s ${(p) => p.theme.easing};
            &:hover {
                /*Twinmotion vs UE styles*/
                ${(p) =>
                    p.isTwinmotion
                        ? `
                    color: ${(p) => p.theme.palette.accent.dark.orchid};
                `
                        : `
                    ${(p) =>
                        !p.isTwinmotion
                            ? `border: 1px solid rgb(85, 85, 85);
                                border-radius: 3px;`
                            : ''}
                `}
                cursor: pointer;
            }
        }
        &-prev {
            margin-right: ${(p) => p.theme.spacing(3)}px;
        }
        &-next {
            margin-left: ${(p) => p.theme.spacing(3)}px;
        }
        &-disabled {
            cursor: default;
            user-select: none;
            color: ${(p) => p.theme.palette.neutral.iron};
            &:hover {
                color: ${(p) => p.theme.palette.neutral.iron};
                cursor: default;
            }
        }
    }
`;

export interface PaginationProps {
    accentColor?: AccentColorOptions;
    total?: number;
    queryOffset?: string;
    filter?: string;
    keyword?: string;
    setPageNum: (page: number) => void;
    isTwinmotion?: boolean;
}

export const Paginator = ({
    total = 0,
    setPageNum,
    accentColor,
    isTwinmotion
}: PaginationProps): JSX.Element => {
    const rightArrow = isTwinmotion ? ArrowRightAlt : <i className="icon-chevron-right" />;
    const leftArrow = isTwinmotion ? ArrowLeftAlt : <i className="icon-chevron-left" />;

    const pageSize = 10;

    const handlePageChange = (current): void => {
        //convert to page index number so subtract one
        const pageNum = current - 1;
        setPageNum(pageNum);
        emitEvent({
            eventAction: 'client.search',
            eventLabel: 'pagination',
            eventValue: pageNum
        });
        root.scrollTo({
            top: 0
        });
    };

    return (
        <Styled accentColor={accentColor} isTwinmotion={isTwinmotion}>
            <Typography variant="h3">
                <Pagination
                    showQuickJumper={false}
                    hideOnSinglePage
                    showTitle={false}
                    defaultPageSize={pageSize}
                    total={total}
                    onChange={handlePageChange}
                    prevIcon={leftArrow}
                    nextIcon={rightArrow}
                />
            </Typography>
            <VerticalSpacer variant="custom" spacing={10} downSmSpacing={5} />
        </Styled>
    );
};
