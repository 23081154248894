import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {twinmotionApi} from './twinmotion.api';
import {useState, useEffect, useCallback} from 'react';
import {usePurchase} from './purchase.hooks';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';
import {isError} from '@epic-mw/error-types';

export interface DownloadUrls {
    winUrl: string;
    macUrl: string;
}

export interface TwinEntitlementData {
    entitlements: {
        id: string;
        entitlementName: string;
        namespace: string;
        catalogItemId: string;
        accountId: string;
        identityId: string;
        entitlementType: string;
        grantDate: string;
        consumable: boolean;
        status: string;
        active: boolean;
        useCount: number;
        originalUseCount: number;
        platformType: string;
        created: string;
        updated: string;
        groupEntitlement: boolean;
        country: string;
        operator: string;
    }[];
    downloadUrls: DownloadUrls;
    offers: TwinEntitlementDataOffers;
    hasType?: {
        com: boolean;
        try: boolean;
        edu: boolean;
    };
    reset?: boolean;
}

interface TwinEntitlementDataOffers {
    try: string;
    edu: string;
    com: string;
}

interface SignUpResponse {
    submission: boolean;
    skipEulaAccept: boolean;
    eula: boolean;
    eulaLocale: string;
    fulfillment: boolean;
    downloadUrls: DownloadUrls;
}

interface TwinEntitlementLoadingState {
    loading?: boolean;
    loaded?: boolean;
    purchasing?: boolean;
    error?: string;
}

interface TwinmotionEntitlementResponse extends TwinEntitlementLoadingState {
    twinEntitlement: TwinEntitlementData;
}

interface TwinmotionSubmitResponse extends TwinEntitlementLoadingState {
    submit(): any;
    purchase(): any;
    response: SignUpResponse;
}

export const twinEntitlementDataKey = createSharedValueKey<TwinEntitlementData>(
    'twinEntitlementData',
    {} as TwinEntitlementData
);

export const twinEntitlementLoadingKey = createSharedValueKey<TwinEntitlementLoadingState>(
    'twinEntitlementLoadingState',
    {loading: false, loaded: false, error: ''} as TwinEntitlementLoadingState
);

export const twinmotionSubmissionKey = createSharedValueKey<SignUpResponse>(
    'twinmotionSubmission',
    {} as SignUpResponse
);

let loadingEntitlement = false; //prevent multiple hooks for calling simultaneously
export const useTwinmotionEntitlement = (): TwinmotionEntitlementResponse => {
    const [loadingState, setLoadingState] = useSharedValue(twinEntitlementLoadingKey);
    const [twinEntitlement, setTwinEntitlement] = useSharedValue(twinEntitlementDataKey);
    const entitlements = twinEntitlement.entitlements || [];
    if (loadingState.loaded && !loadingState.error && twinEntitlement.reset) {
        setLoadingState({loading: false, loaded: false});
    }

    useEffect(() => {
        if (
            (entitlements && entitlements.length && !loadingState.error) ||
            loadingEntitlement ||
            loadingState.loaded
        ) {
            return;
        }
        async function fetchData() {
            try {
                loadingEntitlement = true;
                setLoadingState({loading: true, loaded: false});
                const data = await twinmotionApi.getTwinEntitlement();
                setTwinEntitlement(data);
                setLoadingState({loading: false, loaded: true});
                loadingEntitlement = false;
            } catch (ex) {
                console.error('Failed to fetch twin entitlement data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
                loadingEntitlement = false;
            }
        }
        fetchData();
    }, [entitlements, loadingState]);

    return {
        twinEntitlement,
        loading: loadingState.loading || Object.keys(twinEntitlement).length <= 0,
        loaded: loadingState.loaded,
        error: loadingState.error
    };
};

export const useTwinmotionOffers = (): TwinEntitlementDataOffers => {
    const {twinEntitlement} = useTwinmotionEntitlement();
    if (!twinEntitlement) return {} as TwinEntitlementDataOffers;

    return twinEntitlement.offers || ({} as TwinEntitlementDataOffers);
};

export const useTwinmotionDownloadUrls = (): DownloadUrls => {
    const {twinEntitlement} = useTwinmotionEntitlement();
    if (!twinEntitlement) return {} as DownloadUrls;

    return twinEntitlement.downloadUrls || ({} as DownloadUrls);
};

export const useTwinmotionSubmit = (
    type: string,
    orderParams?: {[key: string]: any},
    rejectedCallback?: () => void
): TwinmotionSubmitResponse => {
    const [twinmotionSubmission, setTwinmotionSubmission] = useSharedValue(twinmotionSubmissionKey);
    const [, setTwinEntitlement] = useSharedValue(twinEntitlementDataKey);
    const [loadingState, setLoadingState] = useState<TwinEntitlementLoadingState>({
        loading: false,
        loaded: false,
        purchasing: false,
        error: ''
    });
    const offers = useTwinmotionOffers();
    const offerId = offers[type] || '';
    const params = Object.assign({}, orderParams || {}, {type});

    const onSuccess = useCallback(async () => {
        const res: SignUpResponse = (await twinmotionApi.submitOrder(params)) || {};
        setTwinmotionSubmission(res);
        setLoadingState({loading: false, loaded: true, purchasing: false});
        setTwinEntitlement({reset: true} as TwinEntitlementData);
        emitEvent({
            eventAction: 'client.twinmotion.purchase.success',
            eventLabel: type,
            eventValue: 'twinmotion'
        });
    }, [setTwinmotionSubmission, setLoadingState, twinmotionSubmission, type, params]);

    const onPurchaseRejected = useCallback(() => {
        setTwinmotionSubmission({} as SignUpResponse);
        setLoadingState({
            loading: false,
            loaded: true,
            error: 'Twinmotion purchase failed',
            purchasing: false
        });
        emitEvent({
            eventAction: 'client.twinmotion.purchase.closed',
            eventLabel: type,
            eventValue: 'twinmotion'
        });
        if (rejectedCallback && typeof rejectedCallback === 'function') {
            rejectedCallback();
        }
    }, [setTwinmotionSubmission, setLoadingState, rejectedCallback]);

    const {purchase} = usePurchase('poodle', offerId, onSuccess, onPurchaseRejected);

    const purchaseFn = useCallback(() => {
        setLoadingState({purchasing: true});
        purchase();
        emitEvent({
            eventAction: 'client.twinmotion.purchase.start',
            eventLabel: type,
            eventValue: 'twinmotion'
        });
    }, [purchase]);

    const submit = useCallback(async () => {
        try {
            setLoadingState({loading: true, loaded: false});
            if (type === 'com') {
                purchaseFn();
            } else {
                const res: SignUpResponse = (await twinmotionApi.submitOrder(params)) || {};
                setTwinmotionSubmission(res);
                setLoadingState({loading: false, loaded: true});
                setTwinEntitlement({reset: true} as TwinEntitlementData);
                emitEvent({
                    eventAction: 'client.twinmotion.fulfill',
                    eventLabel: type,
                    eventValue: 'twinmotion'
                });
            }
        } catch (ex) {
            console.error('Failed to submit order', ex);
            const error = (isError(ex) && ex.message) || '';
            setLoadingState({loading: false, loaded: true, error});
        }
    }, [purchase, twinmotionSubmission, loadingState, params]);

    return {
        response: twinmotionSubmission,
        ...loadingState,
        submit,
        purchase: purchaseFn
    };
};
