import React from 'react';
import {EpicGamesFooter} from 'epic-games-footer/src/EpicGamesFooter';
import {useAddOnClick, AddOnClickFn} from 'epic-games-nav-shared';
import {
    useAccountStore
} from 'epic-ue-shared';
import {useFooterStore} from '../shared';
import styled from 'styled-components';
import {ThemeProvider} from '@material-ui/core/styles';
import {ueLightTheme} from 'epic-ue-theme';
import {FooterWrapper} from './Footer.styles';
import {useNavTheme} from './nav-theme';
import type {MessageContext, LocalizedMessages} from '@epic-core/config/src/types';
import {useLocation, useNavigate} from 'react-router';
import {isPathDisabled} from '../shared/navUtils';

/**
 * Prepares the array of links for the footer
 * @param {array} links
 */
const setLinks = (links, addOnClick: AddOnClickFn) => {
    for (let i = 0; i < links.length; i++) {
        let link = links[i];

        let external = true;
        if (link.useCustomCallback) {
            external = false;
        }
        link = addOnClick(link, external);
    }

    return links;
};
interface Properties {
    locale: string;
    messages: MessageContext;
    disablePaths?: string[];
}

export const Footer = (props: Properties): JSX.Element | null => {
    const config = useFooterStore() || {};
    const location = useLocation();
    const navigate = useNavigate();
    const pushable = {
        push: navigate
    };
    const {locale} = props;
    const messages = props.messages as LocalizedMessages;
    const {account} = useAccountStore();
    const navTheme = useNavTheme(location.pathname, true);
    navTheme.fonts.default = 'Brutal_Regular,Open Sans,arial';

    const addOnClick = useAddOnClick(pushable, locale);
    const links = setLinks(config.links || [], addOnClick);
    const moreLegalLinks = setLinks(config.moreLegalLinks || [], addOnClick);
    const linkColumns = (config.linkColumns || []).map((linkColumn) => {
        const links = setLinks(linkColumn.links || [], addOnClick);
        linkColumn.links = links;
        return linkColumn;
    });

    const disable = isPathDisabled(location.pathname, props?.disablePaths);
    if (disable) return null;

    return (
        <FooterWrapper>
            <ThemeProvider theme={ueLightTheme}>
                <EpicGamesFooter
                    i18n={{
                        locale,
                        messages
                    }}
                    theme={navTheme}
                    locale={locale}
                    links={links}
                    linkColumns={linkColumns}
                    socialLinks={config.socialLinks || []}
                    copyrights={config.copyrights || []}
                    legalTags={config.legalTags || []}
                    logos={config.logos || []}
                    messages={config.messages || {}}
                    hideDefaultTermsPolicy={config.hideDefaultTermsPolicy}
                    moreLegalLinks={moreLegalLinks}
                    newLineCopyrights={config.newLineCopyrights}
                    newsLetterConfigs={config.newsLetterConfigs || {}}
                    enableNewsLetter={false}
                    isLoggedIn={account.isLoggedIn}
                    epicPropertyClasses="epic-games-footer"
                />
            </ThemeProvider>
        </FooterWrapper>
    );
};
