import React, {useState, useEffect} from 'react';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import VisibilitySensor from 'react-visibility-sensor';
import {getBowserState} from '@epic-core/common';
import {Wrapper, BgContainer, ChildrenWrap, ScreenWrap, BgSolid, Caption} from './BgMedia.styles';
import {Credits} from '../../layout/headers/Header.styles';
import {GradientHandler, BgImg, Button} from '../../elements';
import {AnimatedSection} from '../../layout';
import {BackgroundProps} from 'epic-ue-shared';
import LazyLoad from 'react-lazy-load';
import {vimeoEmbedUrlNoCookie} from '@epic-mw/common-utils';
import {useLocalization} from '@epic-mw/localization';

export interface BgMediaProps {
    children?: React.ReactNode;
    classes?: string;
    imgClass?: string;
    autoplay?: boolean | true;
    enableRatioHelper?: boolean;
    mute?: boolean;
    background?: BackgroundProps;
    imgMaxWidth?: number;
    forceVideoMobile?: boolean;
    controls?: boolean;
    disableLightbox?: boolean;
}

export const isValidVideo = (background?: BackgroundProps): boolean => {
    if (!background) return false;
    if (background.type === 'youtubeVideo' && background.contentId) return true;
    if (background.type === 'htmlVideo' && background.content && background.content[0]) return true;
    if (background.type === 'embedVideo' && background.embedSrc) return true;
    return false;
};

const sanitizeVideoUrls = (background?: BackgroundProps) => {
    if (background && background.embedSrc) {
        background.embedSrc = background.embedSrc.replace(/youtube\.com/gi, 'youtube-nocookie.com');
        background.embedSrc = vimeoEmbedUrlNoCookie(background.embedSrc);
    }
};

export const isUnsupportedBackgroundVideo = (background?: BackgroundProps) => {
    if (background && background.embedSrc) {
        return /(bilibili\.com|twitch\.tv)/g.test(background.embedSrc);
    }
    return false;
};

export const isValidImage = (background?: BackgroundProps): boolean => {
    let bgContentImg = '';

    const content = background?.content;
    const firstContent = content && content.length ? content[0] : '';
    const firstContentIsImage = /\.(jpg|png|gif|jpeg)$/.test(firstContent);

    if (firstContent && firstContentIsImage) {
        bgContentImg = firstContent;
    }

    //check for older background content type images as a fallback
    const image =
        background?.image ||
        background?.desktopImage ||
        background?.tabletImage ||
        background?.mobileImage ||
        bgContentImg;

    const validImage = !!image;
    return validImage;
};

export const BgMedia = ({
    children,
    classes,
    imgClass,
    autoplay,
    enableRatioHelper = true,
    mute,
    background,
    imgMaxWidth,
    forceVideoMobile,
    controls,
    disableLightbox,
    ...rest
}: BgMediaProps): JSX.Element | null => {
    const [inView, setInView] = React.useState(false);
    const {mobile, tablet} = getBowserState();
    const [dirty, setDirty] = useState(false);

    let opacity = 1;
    let offset = 0;
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            } else if (!isVisible) {
                setInView(false);
            }
        },
        [inView, setInView]
    );

    let gradientElem;
    let BgMediaElem;
    let bgContentImg = '';
    let credits = '';
    let caption = '';
    let image = '';
    let imgHeight = '';
    let alt = '';

    let shouldPlay = true;
    if (!inView) {
        shouldPlay = false;
    } else if (inView) {
        shouldPlay = true;
    }

    if (inView && !autoplay) {
        shouldPlay = false;
    }

    //with perfect SSR the client doesn't re-render and adjust the size of the video. this helps.
    useEffect(() => {
        if (!dirty) {
            setTimeout(() => {
                setDirty(true);
            }, 50);
        }
    }, [dirty, setDirty]);

    const getMessage = useLocalization();

    if (background) {
        if (isUnsupportedBackgroundVideo(background)) {
            return (
                <div style={{textAlign: 'center'}}>
                    <Button type="primary" href={background.embedSrc}>
                        {getMessage('epic.watch.video') || 'Watch video'}
                    </Button>
                </div>
            );
        }

        opacity = background.opacity || 1;
        offset = background.offset || 0;
        credits = background.credits || '';
        caption = background.caption || '';
        imgHeight = background.height || '';
        alt = background.alt || '';
        const gradient = background.gradient || false;
        if (gradient) {
            gradientElem = (
                <GradientHandler className="bg-gradient">
                    <span />
                </GradientHandler>
            );
        }

        if (background?.content && isValidImage(background)) {
            bgContentImg = background.content[0];
        }

        //check for older background content type images as a fallback
        image =
            background.image ||
            background.desktopImage ||
            background.tabletImage ||
            background.mobileImage ||
            bgContentImg;

        const type = background.type || '';
        const validImage = !!image;
        const validVideo = isValidVideo(background);
        if (validVideo) {
            sanitizeVideoUrls(background);
        }
        const hexValue = background.hex;
        if (validImage && (type === 'image' || (!forceVideoMobile && (mobile || tablet)))) {
            BgMediaElem = (
                <BgImg img={image} w={imgMaxWidth} alt={alt} disableLightbox={disableLightbox} />
            );
        } else if (type === 'solid') {
            BgMediaElem = <BgSolid className="solidBg" hexValue={hexValue} />;
        } else if (type === 'animated') {
            BgMediaElem = <AnimatedSection isBackground={true} />;
        } else if (validVideo) {
            BgMediaElem = (
                <BackgroundVideo
                    background={background}
                    enableRatioHelper={enableRatioHelper}
                    play={shouldPlay}
                    mute={mute === undefined ? true : mute}
                    noOverlay={true}
                    mobile={mobile}
                    tablet={tablet}
                    forceVideoMobile={forceVideoMobile}
                    controls={controls}
                    dirty={dirty}
                    playsInline={true}
                    {...rest}
                />
            );
            if (background.type === 'youtubeVideo') {
                BgMediaElem = <LazyLoad>{BgMediaElem}</LazyLoad>;
            }
        } else {
            return null;
        }
    } else {
        return null;
    }

    const attrs: Record<string, unknown> = {};
    if (image && background.type === 'image' && !disableLightbox) {
        attrs['data-image'] = image;
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <>
                <BgContainer
                    {...attrs}
                    className={`bgMedia type-${
                        background && background.type ? background.type : ''
                    } ${classes ? classes : ''}`}
                    offset={offset}
                    opacity={opacity}
                    imgHeight={imgHeight}>
                    <Wrapper className={`bgMedia-wrap ${inView ? 'inView' : ''}`}>
                        {background && background.type === 'animated' ? (
                            <div>{BgMediaElem}</div>
                        ) : (
                            <ScreenWrap>{BgMediaElem}</ScreenWrap>
                        )}
                        {children ? (
                            <ChildrenWrap className="bgmedia-content">{children}</ChildrenWrap>
                        ) : (
                            ''
                        )}
                    </Wrapper>
                    {gradientElem}
                    {credits && <Credits className="media-credits">{credits}</Credits>}
                    {caption && <Caption>{caption}</Caption>}
                </BgContainer>
            </>
        </VisibilitySensor>
    );
};
