import React from 'react';
import {
    CardContainer,
    CardContent,
    Title,
    Copy,
    CardTagLabel,
    CardImageContainer
} from './Card.styles';
import {getBowserState} from '@epic-core/common';
import {BgImg, TagLabel} from '../';
import {CardProps} from 'epic-ue-shared';

export const Card = ({
    title,
    titleSecond,
    copy,
    children,
    type,
    img,
    center,
    label,
    noPaddingBetweenTitles
}: CardProps): JSX.Element => {
    const {mobile, tablet} = getBowserState();
    return (
        <CardContainer
            type={type}
            center={center}
            className={`card ${type || ''} ${mobile || tablet ? 'handheld' : ''}`}>
            {img ? (
                <CardImageContainer>
                    <div className="embed-responsive embed-responsive-16by9">
                        <BgImg img={img} w={1200} />
                    </div>
                </CardImageContainer>
            ) : (
                ''
            )}
            <CardContent className="card-content" type={type} noPaddingBetweenTitles={noPaddingBetweenTitles}>
                {title && (
                    <Title className="card-title" type={type}>
                        <div
                            className="title"
                            dangerouslySetInnerHTML={{__html: title || ''}}
                        />
                        {titleSecond ? <div
                            className="title-second"
                            dangerouslySetInnerHTML={{__html: titleSecond || ''}}
                        /> : ''}
                    </Title>
                )}
                {label && (
                    <CardTagLabel>
                        <TagLabel>{label}</TagLabel>
                    </CardTagLabel>
                )}
                {copy && (
                    <Copy
                        className="card-copy"
                        type={type}
                        dangerouslySetInnerHTML={{__html: copy || ''}}
                    />
                )}

                {children}
            </CardContent>
        </CardContainer>
    );
};
