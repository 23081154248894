import React from 'react';
import PropTypes from 'prop-types';
import DatasmithItem from './DatasmithItem';
import {getDataAttribute} from '@epic-core/common';
import Message from '../message/Message';
import VisibilitySensor from 'react-visibility-sensor';
import {H1} from 'epic-ue-components';
import {DSWrapper} from './Datasmith.styles';

let ribbonLoop;
export default class Datasmith extends React.PureComponent {
    static propTypes = {
        datasmithData: PropTypes.object,
        mobile: PropTypes.bool,
        tablet: PropTypes.bool
    };

    state = {
        showInfo: false,
        activeRibbon: null,
        isVisible: false,
        zipRibbon: 0
    };

    showInfo = (event) => {
        if (event) {
            const ribbonCount = getDataAttribute(event.currentTarget, 'ribbon');
            this.setState({
                showInfo: true,
                activeRibbon: ribbonCount
            });
        }
    };

    closeInfo = (event) => {
        this.setState({
            showInfo: false,
            activeRibbon: null
        });
    };

    componentWillUnmount() {
        clearInterval(ribbonLoop);
    }

    datasmithIntro = (isVisible) => {
        if (isVisible && !this.state.isVisible) {
            this.setState({isVisible: true});

            const data = this.props.datasmithData || {};
            const items = data.items || [];

            ribbonLoop = setInterval(() => {
                const datasmithItemCount = items.length || 0;
                const ribbonNumber = Math.floor(Math.random() * datasmithItemCount) + 1;
                this.setState({zipRibbon: ribbonNumber});
            }, 10000);
        }
    };

    render() {
        const {datasmithData} = this.props;
        const {subTitle, title, visual, quote, quoteAuthor} = datasmithData;
        const items = datasmithData.items || [];
        const activeRibbon = this.state.activeRibbon;
        const showInfo = this.state.showInfo;
        const ribbonItemLength = items.length || 0;
        return (
            <VisibilitySensor
                onChange={this.datasmithIntro}
                minTopValue={500}
                partialVisibility
                scrollCheck={true}
                intervalDelay={250}
                scrollThrottle={10}>
                <DSWrapper
                    id="datasmithOverview"
                    className={`datasmith-overview ${this.state.isVisible ? 'runAnimation' : ''}`}
                    data-ribboncount={ribbonItemLength}>
                    <div className="ds-header">
                        <H1>{title}</H1>
                        <div className="h2">{subTitle}</div>
                    </div>
                    <div
                        className="hidden-xs hidden-sm ribbon"
                        data-ribbonanimate={this.state.zipRibbon}>
                        <div className="hidden-xs hidden-sm full-logo">
                            <i className="icon-ue-full" />
                        </div>
                        <div className="hidden-md hidden-lg small-logo">
                            <i className="icon-ue" />
                        </div>

                        <div className="datasmith-container">
                            <div className="datasmith-visual-bg" />
                            <span>{visual}</span>
                        </div>
                        <div className="container3">
                            {items !== undefined && items.length > 0 ? (
                                items.map((item, index) => {
                                    const ribbonCount = index + 1;
                                    const i = index;
                                    return (
                                        <div
                                            key={`bgRibbon${i}`}
                                            className={`ribbon${ribbonCount}`}
                                        />
                                    );
                                })
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className="container1">
                            {items !== undefined && items.length > 0 ? (
                                items.map((item, index) => {
                                    const ribbonCount = index + 1;
                                    const i = index;
                                    return (
                                        <div key={`item${i}`} className={`ribbon${ribbonCount}`}>
                                            <DatasmithItem
                                                label={item.label}
                                                title={item.title}
                                                description={item.description}
                                                extensions={item.extensions}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <div />
                            )}
                        </div>
                        <div className="container2">
                            {items !== undefined && items.length > 0 ? (
                                items.map((item, index) => {
                                    const ribbonCount = index + 1;
                                    const i = index;
                                    return (
                                        <div
                                            key={`angledRibbon${i}`}
                                            className={`ribbon${ribbonCount}`}
                                        />
                                    );
                                })
                            ) : (
                                <div />
                            )}
                        </div>
                    </div>
                    <div className="hidden-md hidden-lg ribbon">
                        <div className="container1">
                            <span className="datasmith-title">{visual}</span>
                            {items !== undefined && items.length > 0 ? (
                                items.map((item, index) => {
                                    const ribbonCount = index + 1;
                                    return (
                                        <div key={item.title} className={`ribbon${ribbonCount}`}>
                                            <div
                                                className="ribbonItem"
                                                role="presentation"
                                                data-ribbon={ribbonCount}
                                                onClick={this.showInfo}>
                                                {item.label}
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div />
                            )}
                            <div
                                className={`ribbon-info ${
                                    activeRibbon !== null ? 'has-active-ribbon' : ''
                                }`}>
                                {items !== undefined && items.length > 0 ? (
                                    items.map((item, index) => {
                                        const ribbonCount = index + 1;
                                        let ribbonIsActive = false;
                                        if (
                                            Number(activeRibbon) === Number(ribbonCount) &&
                                            showInfo
                                        ) {
                                            ribbonIsActive = true;
                                        }
                                        return (
                                            <div
                                                key={item.title}
                                                className={`ribbon${ribbonCount} ${
                                                    ribbonIsActive ? 'active-ribbon' : ''
                                                }`}>
                                                <div className="desc">
                                                    <i
                                                        className="icon-cross"
                                                        role="presentation"
                                                        onClick={this.closeInfo}
                                                    />
                                                    <h3>{item.title}</h3>
                                                    <h4>
                                                        <Message code="epic.studio.extension.label" />
                                                        : <span>{item.extensions}</span>
                                                    </h4>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div />
                                )}
                            </div>
                        </div>
                        <div className="ue-payoff">
                            <i className="icon-ue" />
                        </div>
                    </div>
                    <div className="datasmith-quote">
                        <div className="h2">
                            {quote}
                            <span>{quoteAuthor}</span>
                        </div>
                    </div>
                </DSWrapper>
            </VisibilitySensor>
        );
    }
}
