import {useState, useEffect, useMemo} from 'react';
import {blogApi} from './blog.api';
import {isError} from '@epic-mw/error-types';

export interface RecentBlogsResponse {
    recentBlogs: any;
    loading?: boolean;
    loaded: boolean;
    error?: string;
}

export const useRecentBlogs = (locale: string, num: number): RecentBlogsResponse => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [recentBlogs, setrecnetBlogs] = useState(null);
    useEffect(() => {
        if ((recentBlogs && !loadingState.error) || loadingState.loading || loadingState.loaded) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData = (await blogApi.getRecent(locale, num)) || {};
                const recentData = fetchedData.recent || fetchedData.items || [];
                setrecnetBlogs(recentData);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch blog recent post', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [recentBlogs, loadingState.loading, loadingState.loaded]);

    return useMemo(() => {
        return {
            recentBlogs,
            ...loadingState
        };
    }, [recentBlogs, loadingState.loading, loadingState.loaded]);
};
