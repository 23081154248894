import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import {FeedImage, FeedHeading, FeedDate} from '../common/';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';
import styled from 'styled-components';

export const SmallFeedCardWrapper = styled.div`
    .feed-card {
        background: ${(p) => p.theme.palette.background.default};
    }

    .image-container {
        padding: 10px 0 0;
        justify-content: center;
        align-items: center;
        .image-wrapper {
            height: 200px;
            overflow: hidden;
            @media screen and (min-width: 550px) and (max-width: 768px) {
                height: 275px;
            }
            img.image {
                height: 200px;
                @media screen and (min-width: 550px) and (max-width: 768px) {
                    height: 275px;
                }
            }
        }
    }

    .feed-item-mobile-bar {
        display: flex;
        flex-direction: row;
        padding: 10px 20px 10px 0;
    }
`;

interface Properties {
    item: FeedItemProps;
    disableClick?: boolean;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const SmallFeedCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const disableClick = props.disableClick || false;
    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';
    const _feedSlug = item._feedSlug || props.title || '';
    const _feedDate = item._feedDate || '';

    return (
        <FeedItemDefaultsWrapper>
            <SmallFeedCardWrapper className="feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <div className="image-container">
                        <FeedImage item={item} linkPath={linkPath} title={title} />
                    </div>

                    <div className="content">
                        <FeedHeading item={item} linkPath={linkPath} title={title} />
                    </div>

                    <div className="feed-item-mobile-bar">
                        <ContentTypeLabel
                            item={item}
                            tagMatch={tagMatch}
                            disableClick={disableClick}
                        />
                        <FeedDate date={_feedDate} />
                    </div>
                </Paper>
            </SmallFeedCardWrapper>
        </FeedItemDefaultsWrapper>
    );
};
