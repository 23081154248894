import React from 'react';
import {ArticleDetailsWrapper} from './ArticleDetails.styles';

export interface ArticleDetailsProps {
    logo?: string;
    offsetHeight?: string;
    content: string;
    isBio: boolean;
}
export const ArticleDetails = ({content, offsetHeight, logo, isBio}: ArticleDetailsProps): JSX.Element => {
    return (
        <ArticleDetailsWrapper
            className={`article-details ${isBio ? 'isBio' : ''}`}
            offsetHeight={offsetHeight}>
            {content || logo ? (
                <div className="badge-wrapper">
                    {logo ? (
                        <div className="badge-logo">
                            <img src={logo} alt="" />
                        </div>
                    ) : (
                        ''
                    )}
                    {content ? (
                        <div
                            className="badge-content"
                            dangerouslySetInnerHTML={{__html: content}}
                        />
                    ) : (
                        ''
                    )}
                </div>
            ) : (
                ''
            )}
        </ArticleDetailsWrapper>
    );
};
