import React from 'react';
import PropTypes from 'prop-types';
import EnterpriseHighlightsPanel from '../../enterprise/EnterpriseHighlightsPanel';
import {getLocale} from '@epic-core/common';
import {Typography} from 'epic-ue-ui/dist/components/elements/typography';
import {MinusIcon, PlusIcon} from 'epic-ue-ui/dist/components/elements/icons';
import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const AccordionWrap = styled(MuiAccordion)`
    :last-child {
        border-bottom: ${(p) =>
                p.theme.ifLight(p, p.theme.palette.neutral.steel, p.theme.palette.neutral.silver)}
            1px solid;
    }

    && {
        background-color: unset;
        box-shadow: unset;
        padding: 0;
        position: unset;
        border-top: ${(p) =>
                p.theme.ifLight(p, p.theme.palette.neutral.steel, p.theme.palette.neutral.silver)}
            1px solid;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        &.expanded {
            margin: 0;
        }
        .headline {
            text-align: left;
        }
    }
`;

const AccordionSummaryWrap = styled(MuiAccordionSummary)`
    &&& {
        min-height: 0;
    }
    && {
        margin: 32px 0;
        padding: 0;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin: 16px 0;
        }
        .content {
            margin: 0 24px 0 0;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 0 16px 0 0;
            }
        }
        .edge-end {
            padding: 0;
            margin-right: 13px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin-right: 8px;
            }
            transform: rotate(0deg);
            svg {
                position: absolute;
                font-size: 1.7145em;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 1.143em;
                }
            }
        }
        &:focus-visible {
            outline: 1px solid ${(p) => p.theme.palette.misc.outline};
            outline-offset: 2px;
            background: unset;
        }
    }
`;

const AccordionDetails = styled.div`
    .accordion {
        transition-property: all;

        transition-timing-function: ease-in-out cubic-bezier(0, 1, 0.5, 1);
    }
    .open {
        overflow-y: hidden;
        max-height: 3000px;
        transition-duration: 0.2s;
    }
    .closed {
        max-height: 0;
        overflow-y: hidden;
        transition-duration: 0.15s;
    }
`;

export default class HighlightPanelSection extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggle: props.defaultCollapsed || false
        };
    }
    static propTypes = {
        panel: PropTypes.object,
        panelId: PropTypes.number,
        defaultCollapsed: PropTypes.bool,
        accentColor: PropTypes.string,
        expandedItem: PropTypes.number,
        toggleAccordionSections: PropTypes.func
    };

    render() {
        const panel = this.props.panel || {};
        const panelId = this.props.panelId;
        const desc = panel.desc;
        const accentColor = this.props.accentColor;
        const expandedItem = this.props.expandedItem;
        const toggleAccordionSections = this.props.toggleAccordionSections;

        return (
            <>
                <AccordionWrap className="highlight-panel-section">
                    <div className={`headline container text-center panel-${panelId}`}>
                        <AccordionSummaryWrap
                            onClick={(e) => {
                                toggleAccordionSections(e, panelId);
                            }}
                            role="presentation"
                            classes={{content: 'content'}}
                            expandIcon={
                                expandedItem === panelId ? (
                                    <MinusIcon color={accentColor} />
                                ) : (
                                    <PlusIcon color={accentColor} />
                                )
                            }
                            IconButtonProps={{classes: {edgeEnd: 'edge-end'}}}>
                            <Typography variant="h3" big>
                                {panel.headline}
                            </Typography>
                        </AccordionSummaryWrap>
                        {desc && expandedItem === panelId ? (
                            <div
                                className="headline-desc"
                                dangerouslySetInnerHTML={{__html: desc}}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </AccordionWrap>
                <AccordionDetails>
                    {panel.showPanel && panel.highlights.length ? (
                        <div
                            className={`accordion ${expandedItem === panelId ? 'open' : 'closed'}`}>
                            <EnterpriseHighlightsPanel
                                firstFeatured={panel.firstFeatured}
                                highlights={panel.highlights || []}
                                locale={getLocale()}
                                isNewsletterCard={false}
                                isNewsletterInHighlights={false}
                                accordion={true}
                            />
                        </div>
                    ) : null}
                </AccordionDetails>
            </>
        );
    }
}
