import React from 'react';
import root from 'window-or-global';
import pureRender from 'pure-render-decorator';
import BlogContentGridRow from '../BlogContentGridRow';
import Message from '../../message/Message';
import BreakPoints from './BreakPoints';
import {getBowserState} from '@epic-core/common';

const DEFAULT_MAX_ROW_SIZE = 3;
const MOBILE_ROW_SIZE = 1;
const TABLET_ROW_SIZE = 2;

const {MOBILE_WIDTH_MAX, TABLET_WIDTH_MIN, TABLET_WIDTH_MAX} = BreakPoints;

/**
 * Content Toss, show N most recent post
 * @implements {BlogContentGridRow}
 * @reactProps {array} blogs
 * @reactProps {string} locale
 * @reactProps {string} routerRootUrl
 * @reactProps {string} linkUrlPrefix
 * @reactProps {bool} useTitleCase
 */

interface RecentPostsProps {
    blogs: any;
    routerRootUrl: string;
    linkUrlPrefix?: string;
    maxRowSize: number;
}

interface RecentPostsState {
    maxRowSize?: number;
}

@pureRender
class RecentPosts extends React.Component<RecentPostsProps, RecentPostsState> {
    constructor(props: RecentPostsProps) {
        super(props);
        this.setMaxRowSize = this.setMaxRowSize.bind(this);
        if (root.addEventListener) {
            root.addEventListener('resize', this.setMaxRowSize);
        }
    }

    state: RecentPostsState = {};

    static defaultProps = {
        maxRowSize: DEFAULT_MAX_ROW_SIZE
    };

    componentDidMount(): void {
        this.setMaxRowSize();
    }

    /**
     * Remove 'resize' eventListenr
     * @return {[type]} [description]
     */
    componentWillUnmount(): void {
        const isServerRender = root.__server_side_render;
        if (!isServerRender) {
            this.setMaxRowSize();
        }
        if (root.removeEventListener) {
            root.removeEventListener('resize', this.setMaxRowSize);
        }
    }

    /**
     * 'resize' event handler, responsive handler
     *  Handls four column by default
     */
    setMaxRowSize(): void {
        const maxRowSize = this.props.maxRowSize;
        if (!root.document) {
            return;
        }
        const calcWidth =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        switch (true) {
            case calcWidth > TABLET_WIDTH_MIN && calcWidth < TABLET_WIDTH_MAX:
                this.setState({maxRowSize: TABLET_ROW_SIZE});
                break;
            case calcWidth < TABLET_WIDTH_MIN:
                this.setState({maxRowSize: MOBILE_ROW_SIZE});
                break;
            case calcWidth <= MOBILE_WIDTH_MAX:
                this.setState({maxRowSize: MOBILE_ROW_SIZE});
                break;
            default:
                this.setState({maxRowSize});
                break;
        }
    }

    getMaxRowSize = (): number => {
        const isServerRender = root.__server_side_render;
        const maxRowSizeInState = this.state.maxRowSize;
        const maxRowSizeInProps = this.props.maxRowSize;

        if (maxRowSizeInState) {
            return maxRowSizeInState;
        }

        if (isServerRender && maxRowSizeInProps) {
            return maxRowSizeInProps;
        }

        return DEFAULT_MAX_ROW_SIZE;
    };

    render(): JSX.Element {
        const {blogs, routerRootUrl, linkUrlPrefix} = this.props;
        const {mobile, tablet} = getBowserState();

        let maxRowSize = this.getMaxRowSize();
        // Force max row size on mobile (for server render)
        if (mobile || tablet) {
            maxRowSize = MOBILE_ROW_SIZE;
        }
        if (blogs.length === 0) {
            return <div />;
        }

        return (
            <div className="recentPostContainer">
                <h2 className="recent-title">
                    <Message code="epic.blog.recentPosts" />
                </h2>

                <BlogContentGridRow
                    posts={blogs.slice(0, 3)}
                    maxRowSize={maxRowSize}
                    routerRootUrl={routerRootUrl}
                    linkUrlPrefix={linkUrlPrefix}
                />
            </div>
        );
    }
}

export default RecentPosts;
