import React from 'react';
import {Button, ButtonWrapper} from 'epic-ue-components';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

interface Button {
    label?: string;
    url?: string;
}
interface Properties {
    primaryBtn: Button;
    secondaryBtn: Button;
    sectionId?: string;
}

interface ClickArgs {
    accountId?: string;
    sectionId?: string;
    label: string;
    url: string;
    isPrimary?: boolean;
}

const onClick = ({accountId = '', sectionId = '', url, isPrimary}: ClickArgs) => {
    emitEvent({
        accountId,
        eventCategory: url,
        eventAction: `${isPrimary ? 'primary' : 'secondary'}_btn_click`,
        eventLabel: sectionId
    });
};

const CommonButtonWrapper = ({
    primaryBtn = {},
    secondaryBtn = {},
    sectionId
}: Properties): JSX.Element => {
    const primaryBtnLabel = primaryBtn.label || undefined;
    const primaryBtnUrl = primaryBtn.url || undefined;
    const secondaryBtnLabel = secondaryBtn.label || undefined;
    const secondaryBtnUrl = secondaryBtn.url || undefined;

    let secondaryBtnElem;
    let primaryBtnElem;

    if (primaryBtnLabel && primaryBtnUrl) {
        primaryBtnElem = (
            <Button
                type="primary"
                href={primaryBtnUrl}
                label={primaryBtnLabel}
                onClick={() => {
                    onClick({
                        sectionId,
                        label: primaryBtnLabel,
                        url: primaryBtnUrl,
                        isPrimary: true
                    });
                }}
            />
        );
    }

    if (secondaryBtnLabel && secondaryBtnUrl) {
        secondaryBtnElem = (
            <Button
                type="secondary"
                href={secondaryBtnUrl}
                label={secondaryBtnLabel}
                onClick={() => {
                    onClick({sectionId, label: secondaryBtnLabel, url: secondaryBtnUrl});
                }}
            />
        );
    }

    if (!primaryBtnElem && !secondaryBtnElem) return <div />;

    return (
        <ButtonWrapper>
            {primaryBtnElem}
            {secondaryBtnElem}
        </ButtonWrapper>
    );
};

export default CommonButtonWrapper;
