import React from 'react';
import pt from 'prop-types';
import Slider from 'epic-react-slick';
import Recording from './Recording';
import styled from 'styled-components';
import {useBreakpointDown} from 'epic-ue-theme';
import {RecordingItemWrapper} from './Recording.styles';

const SlickStylingWrapper = styled.div`
    .slick-dots {
        text-align: center;
        margin: 20px auto;
        padding: 0;
        li {
            display: inline-block;
            list-style: none;
            margin: 0 5px 0 0;
            &:last-child {
                margin-right: 0;
            }
            &.slick-active {
                button {
                    opacity: 1;
                }
            }
            button {
                text-indent: -9999px;
                background: #fff;
                border: 0;
                border-radius: 200px;
                width: 15px;
                height: 15px;
                padding: 0;
                opacity: 0.5;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 40%;
        height: 50px;
        width: 50px;
        background: none;
        border-radius: 9999px;
        left: -70px;
        border: 0;
        font-size: 1px;
        outline: none;
        color: rgba(0, 0, 0, 0);
        cursor: pointer;
        ${(p) => p.theme.breakpoints.down('sm')} {
            left: 5px;
            top: 50%;
            height: 35px;
            width: 35px;
        }
        &:before {
            content: '\\e910';
            font-family: ${(p) => p.theme.ue.fonts.icomoon};
            font-size: 30em;
            color: rgb(34, 34, 34);
            text-shadow: none;
            position: absolute;
            top: 3px;
            left: 10px;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 20em;
                left: 6px;
            }
        }
        &.slick-next {
            left: auto;
            right: -70;
            &:before {
                content: '\\e90d';
                ${(p) => p.theme.breakpoints.down('sm')} {
                    right: 5px;
                }
            }
        }
        &.slick-disabled {
            display: none !important;
        }
    }
`;

const RecordingRow = (props) => {
    const {
        recordings,
        index,
        nowPlayingCopy,
        btnLabel,
        clicked,
        activeVideo,
        tablet,
        mobile,
        showAll
    } = props;
    const isMd = useBreakpointDown('md');
    const isSm = useBreakpointDown('sm');

    const rowSettings = {
        autoPlay: false,
        slidesToShow: isMd && !isSm ? 2 : isSm ? 1 : 3,
        dots: false,
        arrows: true,
        slidesToScroll: 1,
        infinite: false
    };
    let recordingItems = '';
    if (recordings !== undefined && recordings.length > 0) {
        recordingItems = recordings.map((recording, i) => {
            return (
                <RecordingItemWrapper
                    className="recording-item-wrapper col-xs-12 col-sm-6 col-md-4"
                    key={`recording-${i}-${recording.title}`}>
                    <Recording
                        nowPlayingCopy={nowPlayingCopy}
                        btnLabel={btnLabel}
                        activeVideo={activeVideo}
                        recording={recording}
                        hasClicked={props.hasClicked}
                    />
                </RecordingItemWrapper>
            );
        });
    }
    let recordingsWrapper = '';
    let showAllToggle = false;
    if (clicked && showAll === index) {
        showAllToggle = true;
    }

    if ((recordings !== undefined && recordings.length) || mobile || tablet) {
        recordingsWrapper = (
            <SlickStylingWrapper>
                <Slider {...rowSettings}>{recordingItems}</Slider>
            </SlickStylingWrapper>
        );
    } else {
        recordingsWrapper = recordingItems;
    }

    if (showAllToggle) {
        recordingsWrapper = recordingItems;
    }

    return <div>{recordingsWrapper}</div>;
};

RecordingRow.propTypes = {
    recordings: pt.array,
    clicked: pt.bool,
    mobile: pt.bool,
    tablet: pt.bool,
    index: pt.number,
    activeVideo: pt.oneOfType([pt.object, pt.array]),
    nowPlayingCopy: pt.string,
    btnLabel: pt.string,
    hasClicked: pt.func,
    showAll: pt.number
};

export default RecordingRow;
