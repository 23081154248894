import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {FeedDescription} from '../../description/FeedDescription';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import {FeedImage, FeedHeading} from '../common';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';
import styled from 'styled-components';

export const LargeFeedCardWrapper = styled.div`
    .image-wrapper {
        height: 320px;
        overflow: hidden;
        img.image {
            height: 100%;
            transition: transform 350ms ease-in-out, box-shadow 350ms ease-in-out;
        }
    }

    h2 {
        @media screen and (min-width: 1800px) {
            font-size: 1.5em;
        }
    }

    .content {
        min-height: 250px;
        position: relative;
        .inner {
            height: auto;
            min-height: 190px;
            background: ${(p) => p.theme.palette.background.default};
            padding: 20px 0;
        }
    }

    .short {
        opacity: 1;
        max-height: 123px;
        overflow: hidden;
        line-height: 1.5em;
        transition: max-height 1.5s ease-in, opacity 1s ease-in;
        overflow: hidden;

        div {
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }

    &:hover {
        .image {
            box-shadow: inset 0 0 8em 1em rgba(0, 0, 0, 0.2);
            transform: scale3d(1.02, 1.02, 1.02);
        }
        .short {
            transition: max-height 350ms ease-out, opacity 250ms ease-out;
        }
    }
`;

interface Properties {
    item: FeedItemProps;
    disableClick?: boolean;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const LargeFeedCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const disableClick = props.disableClick || false;
    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';
    const _feedSlug = item._feedSlug || props.title || '';

    return (
        <FeedItemDefaultsWrapper>
            <LargeFeedCardWrapper className="feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <FeedImage item={item} linkPath={linkPath} title={title} />
                    <div className="content">
                        <div className="inner">
                            <ContentTypeLabel
                                item={item}
                                tagMatch={tagMatch}
                                disableClick={disableClick}
                            />
                            <FeedHeading item={item} linkPath={linkPath} title={title} />
                            <FeedDescription item={item} />
                        </div>
                    </div>
                </Paper>
            </LargeFeedCardWrapper>
        </FeedItemDefaultsWrapper>
    );
};
