import React from 'react';
import {MultiCard, SimilarBlogsWrap} from 'epic-ue-ui';
import {FeedItemProps, getImageUrl, getTitle, useFeedItems} from 'epic-ue-feed';
import {getLocale} from '@epic-core/common';
import {extractSlugLocale} from '@epic-mw/localization';
import styled from 'styled-components';
import root from 'window-or-global';
import parse from 'url-parse';

export const Styled = styled.div``;

export interface SimilarBlogsLoadingProps {
    cmsLoading?: boolean;
}
export interface SimilarBlogsProps {
    headline?: string;
    seeAllLabel?: string;
    seeAllHref?: string;
    tags?: string;
    disable?: boolean;
}

const SimilarBlogs = ({
    cmsLoading,
    headline = 'Similar blogs',
    seeAllLabel = 'See All',
    seeAllHref = '#',
    tags
}: SimilarBlogsProps & SimilarBlogsLoadingProps): JSX.Element => {
    const tagParts = tags?.split(',') || [];
    const tagsObject: {[key: string]: boolean} = {};
    tagParts.forEach((part) => {
        if (typeof part === 'string' && part.length) {
            const lower = part.toLowerCase();
            tagsObject[lower] = true;
        }
    });

    const {items: loadedFeedItems = [] as FeedItemProps[], loading: loading} = useFeedItems(
        {tags: tagsObject},
        cmsLoading
    );

    const currentUrl = parse(root.location.href);
    const currentUrlPath = extractSlugLocale(currentUrl.pathname, getLocale()).pageSlug;
    const feedItems = loadedFeedItems.filter((item: FeedItemProps) => {
        if (item._urlPattern) {
            return item._urlPattern.endsWith(currentUrlPath) ? false : true;
        }
        return true;
    });

    const top3FeedItems = feedItems.slice(0, 3);

    const top3FeedImages = top3FeedItems.map((feedItem) => {
        let eyebrow = tagParts[0];
        for (let i = 0; i < feedItem._feedTags.length; i++) {
            const feedTag = feedItem._feedTags[i];
            if (tagParts.includes(feedTag)) {
                eyebrow = feedTag;
                break;
            }
        }

        return {
            image: {
                title: getTitle(feedItem),
                link: {
                    href: feedItem._urlPattern
                },
                description: '',
                eyebrow: {
                    text: eyebrow
                },
                bottomEyebrow: {
                    text: ''
                },
                image: {
                    src: getImageUrl(feedItem),
                    alt: feedItem._slug
                }
            }
        };
    });

    const seeAllButton = seeAllHref
        ? {
              label: seeAllLabel,
              href: seeAllHref
          }
        : {};

    return (
        <Styled>
            {!loading && top3FeedImages?.length > 0 && (
                <SimilarBlogsWrap>
                    <MultiCard
                        cards={[...top3FeedImages]}
                        lineTextBlock={{
                            headline,
                            button: seeAllButton
                        }}
                    />
                </SimilarBlogsWrap>
            )}
        </Styled>
    );
};

export default SimilarBlogs;
