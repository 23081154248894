import React from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    getUrlPattern,
    useCmsData
} from 'epic-ue-shared';
import {
    CmsComponentMapper,
    DynamicSections,
    ImageHero,
    ImageHeroProps,
    ImmersiveHeaderSection,
    Subnav,
    SubnavProps,
    TemplateThemeProps,
    VerticalSpacer,
    DynamicHeaderProps,
    GeneralLandingHeaderProps,
    GeneralLandingPageData,
    GeneralLandingPageSections
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {getPreStardustPageNode} from '../../utils/stardustPageUtils';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;
interface Properties {
    cmsUrlPattern?: string;
}

const GeneralLandingPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<GeneralLandingPageData>(urlPattern);

    const {
        header = {} as GeneralLandingHeaderProps,
        subnav = {} as SubnavProps,
        themeConfig = {} as TemplateThemeProps,
        sections = {} as GeneralLandingPageSections,
        bottomPageVerticalSpacer,
        newsletterProgramName
    } = pageData;

    const headerImageProps = (header as ImageHeroProps) || {};
    const dynamicHeader = (header as DynamicHeaderProps) || {};
    const isDynamicHeader = Boolean(
        dynamicHeader._type === 'Stardust - Element - General Landing Header'
    );
    const {headerOptions} = dynamicHeader;
    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const node = getPreStardustPageNode({
        theme,
        cmsLoading,
        cmsLoadedEmpty,
        pageData
    });

    if (node) {
        return node;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled className={theme}>
                {getHelmetForMetaTags(pageData)}
                {isDynamicHeader ? (
                    <CmsComponentMapper
                        dynamicPageDataArray={headerOptions}
                        elementMapping={{
                            imageHero: <ImageHero accentColor={accentColor} />,
                            immersiveHeader: <ImmersiveHeaderSection accentColor={accentColor} />
                        }}
                    />
                ) : (
                    <ImageHero
                        {...(headerImageProps as ImageHeroProps)}
                        accentColor={accentColor}
                    />
                )}
                <Subnav {...subnav} accentColor={accentColor} />
                <DynamicSections
                    theme={theme}
                    items={items}
                    accentColor={accentColor}
                    templateParams={{newsletterProgramName}}
                />
                {bottomPageVerticalSpacer && <VerticalSpacer variant="section" />}
            </Styled>
        </UeThemeProvider>
    );
};

GeneralLandingPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default GeneralLandingPage;
