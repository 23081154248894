import React, {useState} from 'react';
import {
    useCmsData,
    useEulaContentAndAccepted,
    EulaContentResponse,
    useIsLoggedIn,
    useEulaAcceptance
} from '../shared';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, SimpleLink} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {determineEulaType} from '../utils';
import {generateRoutePath} from '@epic-core/common';
import {EulaCheck, InnerWrap, EulaWrap, Content, LinkWrap} from './EulaCheckPage.styles';
import {getInitialCmsPageSharedValues, SimpleLinkProps, PageData} from 'epic-ue-shared';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import {AgreementWrap} from '../components/eulacheck/AgreementWrap';
import ErrorPage from './ErrorPage';
import root from 'window-or-global';
import parse from 'url-parse';

interface EulaCheckPageData extends PageData {
    agreeLabel: string;
    continueLink: SimpleLinkProps;
    desc: string;
    faqLink: SimpleLinkProps;
    title: string;
    bgImg: string;
}


const EulaCheckPage = (): JSX.Element => {
    const {search} = useLocation();
    const isLoggedIn = useIsLoggedIn();
    const params = useParams();
    const {cmsUrlPattern, eulaKey} = determineEulaType(params, 'eulacheck');
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<EulaCheckPageData>(cmsUrlPattern);
    const {eulaContent, eulaAccepted} = useEulaContentAndAccepted(eulaKey);
    const version = eulaAccepted.version;
    const eulaAcceptance = useEulaAcceptance(eulaKey, version);
    const {accepted, loadingAccepted} = eulaAccepted;
    const [eulaCheckbox, setEulaCheckbox] = useState(false);
    const [newsletterCheckbox, setNewsletterCheckbox] = useState(false);

    const generateRoutePathWithQueryParams = (baseUrl = '') => {
        return generateRoutePath(`${baseUrl}${search || ''}`);
    };

    const parsedUrl = parse(root.location.href, true);
    const query = parsedUrl.query || {};
    let redirectState = query?.state || '';
    if (typeof redirectState === 'string' && redirectState.startsWith('/')) {
        //remove double slashes
        redirectState = redirectState.replace(/^[/]{2,}/g, '');
    }
    const {loading: eulaLoading, eula = {} as EulaContentResponse} = eulaContent;

    if (!eulaKey || !cmsUrlPattern) {
        return <ErrorPage status={404} />;
    }

    if (
        !isLoggedIn ||
        eulaLoading ||
        cmsLoading ||
        loadingAccepted ||
        !eula.key ||
        accepted === undefined
    ) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />
            </>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (accepted) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                {redirectState ?
                    <Navigate to={generateRoutePath(redirectState)} />
                :
                    <Navigate to={generateRoutePathWithQueryParams(`/download/${eulaKey}`)} />
                }
            </>
        );
    }

    const {body} = eula;
    const {title, desc, agreeLabel, continueLink, faqLink, bgImg} = pageData;

    return (
        <Page>
            <EulaCheck bgImg={bgImg}>
                {getHelmetForMetaTags(pageData)}

                <div className="container">
                    <h3>{title}</h3>
                    <h5>
                        <div dangerouslySetInnerHTML={{__html: desc}} />
                    </h5>

                    <EulaWrap>
                        <InnerWrap>
                            <Content dangerouslySetInnerHTML={{__html: body}} />
                        </InnerWrap>
                    </EulaWrap>

                    <AgreementWrap
                        eulaCheckbox={eulaCheckbox}
                        setEulaCheckbox={setEulaCheckbox}
                        newsletterCheckbox={newsletterCheckbox}
                        setNewsletterCheckbox={setNewsletterCheckbox}
                        agreeLabel={agreeLabel}
                        {...eulaAcceptance}
                    />

                    <LinkWrap>
                        <SimpleLink {...continueLink} />
                        <SimpleLink {...faqLink} />
                    </LinkWrap>
                </div>
            </EulaCheck>
        </Page>
    );
};

EulaCheckPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default EulaCheckPage;
