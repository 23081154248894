import React from 'react';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {LinkNavigatorWrapper} from './LinkNavigator.styles';

export default class LinkNavigator extends React.PureComponent<{
    linkNavigatorRootPageSlug?: boolean;
    rootPageSlug?: string;
}> {
    render(): JSX.Element {
        const {linkNavigatorRootPageSlug, rootPageSlug} = this.props;
        let path = generateRoutePath('feed');
        if (linkNavigatorRootPageSlug && rootPageSlug) {
            path = generateRoutePath(rootPageSlug);
        }

        return (
            <LinkNavigatorWrapper>
                <Link className="slug-link accent-color" to={path}>
                    <i className="epic-blog-icon-grid" />
                </Link>
            </LinkNavigatorWrapper>
        );
    }
}
