import React from 'react';
import styled from 'styled-components';

const LabelWrap = styled.span`
    text-transform: uppercase;
    background: rgb(60, 60, 60);
    padding: 8px 12px;
    border-radius: 4px;
    font-family: ${p => p.theme.ue.fonts.brutal.normal};
    font-size: 12px;
`;

interface Properties {
    label?: string;
    children?: React.ReactNode;
}

export const TagLabel = (props: Properties): JSX.Element => {
    const {label, children} = props;
    if (!label && !children) return <span />;

    return <LabelWrap className="tag-label">{children ? children : label}</LabelWrap>;
};
