import React from 'react';
import pt from 'prop-types';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import VisibilitySensor from 'react-visibility-sensor';
import {getBowserState} from '@epic-core/common';
import styled from 'styled-components';

const BgContainer = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    background: #000;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BgWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.7;
    .screen {
        z-index: 1 !important;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        && {
            .screen {
                margin-top: 0;
            }
        }
    }
`;

export default class BgvideoWrapper extends React.PureComponent {
    static propTypes = {
        children: pt.oneOfType([pt.node, pt.array]),
        background: pt.object
    };

    state = {
        videoInView: false
    };

    onVideoChange = isVisible => {
        if (isVisible) {
            this.setState({
                videoInView: isVisible
            });
        } else if (!isVisible && this.state.videoInView) {
            this.setState({videoInView: isVisible});
        }
    };

    render() {
        const {mobile, tablet} = getBowserState();
        const {background = {}} = this.props;
        return (
            <VisibilitySensor
                onChange={this.onVideoChange}
                partialVisibility
                scrollCheck={true}
                intervalDelay={250}
                scrollThrottle={100}>
                <BgContainer className={`${this.state.videoInView ? 'inView' : ''}`}>
                    <BgWrapper className="bg-wrapper">
                        <BackgroundVideo
                            background={background}
                            enableRatioHelper={true}
                            play={this.state.videoInView}
                            noOverlay={true}
                            playsInline={true}
                            mute={true}
                            mobile={mobile}
                            tablet={tablet}
                        />
                    </BgWrapper>
                    {this.props.children}
                </BgContainer>
            </VisibilitySensor>
        );
    }
}
