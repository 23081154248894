import React from 'react';
import {getBowserState} from '@epic-core/common';
import {
    CaseStudyWrapper,
    ContentWrapper,
    CardPosition,
    CaseStudyLogo,
    BottomContent,
    TitleWrapper,
    ButtonContainer,
    TypeWrapper
} from './CaseStudy.styles';
import {CaseStudyDouble} from './CaseStudy.double.styles';
import {getOptImage} from 'epic-ue-shared';
import {CaseStudyProps, ShowreelProps} from 'epic-ue-shared';
import {Card, Button, SimpleLink, LightboxMedia, BgMedia, H2, H4, H3} from '../../elements';

const showreelVideoValid = ({video}: ShowreelProps) => {
    return !!(video && video.type === 'youtubeVideo' && video.contentId);
};

export const CaseStudy = ({
    title,
    summary,
    logo,
    paddingTopBottom,
    paddingLeftRight,
    href,
    showreel,
    background = {} as any,
    backgroundTwo = {} as any,
    type,
    detailPageBtnLabel,
    showreelBtnLabel
}: CaseStudyProps): JSX.Element => {
    const {mobile, tablet} = getBowserState();
    let caseTitle = <H2>{title}</H2>;
    if (type === 'double') {
        caseTitle = <H3 style={{letterSpacing: '-3px'}}>{title}</H3>;
    }
    if (logo && !mobile) {
        caseTitle = (
            <CaseStudyLogo
                paddingTopBottom={paddingTopBottom}
                paddingLeftRight={paddingLeftRight}
                src={getOptImage({url: logo, width: type === 'double' ? 500 : 200})}
                alt={title}
            />
        );
    }

    let button = <div />;
    if (href && detailPageBtnLabel) {
        button = (
            <Button id={`see-more-${title}`} href={href} type="secondary">
                {detailPageBtnLabel}
            </Button>
        );
    }

    const height = 90;
    const [activeImg, setActiveImg] = React.useState(0);
    const [showReel, setShowReel] = React.useState(false);
    const toggleLightboxVideo = React.useCallback(
        () => {
            setShowReel(!showReel);
        },
        [setShowReel, showReel]
    );

    const onHandheldImagePrevClick = React.useCallback(
        () => {
            setActiveImg(activeImg - 1);
        },
        [activeImg, setActiveImg]
    );
    const onHandheldImageNextClick = React.useCallback(
        () => {
            setActiveImg(activeImg + 1);
        },
        [activeImg, setActiveImg]
    );

    let showreelButton = <div />;
    if (showreel && showreelBtnLabel && showreelVideoValid(showreel) && !mobile) {
        showreelButton = (
            <Button
                id={`showreel-${title}`}
                type="showcase"
                as={'button'}
                onClick={toggleLightboxVideo}>
                {showreelBtnLabel} {showreelBtnLabel ? <i className="icon-play2"/> : ''}
            </Button>
        );
    }

    const sumTitle = summary.title;
    const sumCopy = summary.desc;

    let study: JSX.Element | undefined;

    let caseHeading = (
        <H4 className="section-heading">
            <div
                className="title"
                dangerouslySetInnerHTML={{__html: title || ''}}
            />
        </H4>
    );
    if (type === 'fifty' || (type === 'threes' && href)) {
        caseHeading = (
            <H4 className="section-heading">
                <SimpleLink id={`case-threes-${title}`} href={href}>
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{__html: title || ''}}
                    />
                </SimpleLink>
            </H4>
        );
    }

    if (type === 'fifty' || type === 'threes' || type === 'row') {
        let imgMaxWidth;
        switch (type) {
            case 'fifty':
                imgMaxWidth = 800;
                break;
            case 'threes':
                imgMaxWidth = 700;
                break;
            default:
                break;
        }
        study = (
            <div className={`type-${type}`}>
                <div className="wrapper">
                    <BgMedia background={background} autoplay={true} imgMaxWidth={imgMaxWidth} />
                    <BottomContent className="bottom-content">
                        {caseHeading}
                        {sumCopy && (
                            <div
                                className="copy"
                                dangerouslySetInnerHTML={{__html: sumCopy || ''}}
                            />
                        )}
                        <ButtonContainer>
                            {button}
                            {showreelButton}
                        </ButtonContainer>
                    </BottomContent>
                </div>
            </div>
        );
    } else if (type === 'fullWidth' || type === 'contained') {
        study = (
            <div className={`type-${type}`}>
                <BgMedia
                    background={background}
                    autoplay={true}
                    imgMaxWidth={type === 'contained' ? 1200 : undefined}
                />
                <div className="container">
                    <div className="row">
                        {type === 'fullWidth' && sumTitle ? (
                            <ContentWrapper className="overlay-content">
                                <CardPosition>
                                    <Card type="overlay" title={sumTitle} copy={sumCopy} />
                                </CardPosition>
                            </ContentWrapper>
                        ) : (
                            <div />
                        )}
                        <BottomContent className="bottom-content">
                            <TitleWrapper>
                                <SimpleLink id={`case-threes-${title}`} href={href}>
                                    {caseTitle}
                                </SimpleLink>
                            </TitleWrapper>
                            {sumCopy && (
                                <div
                                    className="copy"
                                    dangerouslySetInnerHTML={{__html: sumCopy || ''}}
                                />
                            )}
                            <ButtonContainer>
                                {button}
                                {showreelButton}
                            </ButtonContainer>
                        </BottomContent>
                    </div>
                </div>
            </div>
        );
    } else if (type === 'double') {
        if (!mobile && !tablet) {
            study = (
                <CaseStudyDouble className={`type-${type}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <BgMedia
                                    background={background}
                                    autoplay={true}
                                    imgMaxWidth={800}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="content">
                                    {sumTitle && <div
                                            className="title"
                                            dangerouslySetInnerHTML={{__html: sumTitle || ''}}
                                        />}
                                    {sumCopy && (
                                        <div
                                            className="copy"
                                            dangerouslySetInnerHTML={{__html: sumCopy || ''}}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="content">
                                    <BottomContent className="bottom-content">
                                        <TitleWrapper>{caseTitle}</TitleWrapper>
                                        <ButtonContainer className="btn-container">
                                            {button}
                                            {showreelButton}
                                        </ButtonContainer>
                                    </BottomContent>
                                </div>
                            </div>
                            <div className="hidden-xs hidden-sm col-md-6">
                                <BgMedia
                                    background={backgroundTwo}
                                    autoplay={true}
                                    imgMaxWidth={800}
                                />
                            </div>
                        </div>
                    </div>
                </CaseStudyDouble>
            );
        } else if (mobile || tablet) {
            const hasTwoImages = background.image && backgroundTwo.image;
            study = (
                <CaseStudyDouble className={`type-${type} handheld`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div
                                    className={`handheld-image-wrapper ${
                                        !background.image && !backgroundTwo.image ? 'hidden' : ''
                                    }`}>
                                    {background.image ? (
                                        <BgMedia
                                            background={background}
                                            autoplay={true}
                                            imgMaxWidth={600}
                                            classes={`${activeImg % 2 === 0 ? 'active' : ''}`}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {backgroundTwo.image ? (
                                        <BgMedia
                                            background={backgroundTwo}
                                            autoplay={true}
                                            imgMaxWidth={600}
                                            classes={`${
                                                activeImg % 2 !== 0 || !background.image
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {hasTwoImages ? (
                                        <div className="image-nav">
                                            <i
                                                role="presentation"
                                                className="icon-chevron-up prev"
                                                onClick={onHandheldImagePrevClick}/>
                                            <i
                                                role="presentation"
                                                className="icon-chevron-up next"
                                                onClick={onHandheldImageNextClick}/>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <div className="content">
                                    <H4>
                                        <div
                                            className="title"
                                            dangerouslySetInnerHTML={{__html: title || ''}}
                                        />
                                    </H4>
                                    {sumCopy && (
                                        <div
                                            className="copy"
                                            dangerouslySetInnerHTML={{__html: sumCopy || ''}}
                                        />
                                    )}
                                    <BottomContent className="bottom-content">
                                        <ButtonContainer className="button-container">
                                            {button}
                                            {showreelButton}
                                        </ButtonContainer>
                                    </BottomContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </CaseStudyDouble>
            );
        }
    }

    return (
        <CaseStudyWrapper
            height={height}
            className={`casestudy ${mobile ? 'mobile' : ''} type-${type}`}>
            {showreel && showreelVideoValid(showreel) ? (
                <LightboxMedia
                    title={title}
                    show={showReel}
                    clickHandler={toggleLightboxVideo}
                    video={showreel}
                />
            ) : (
                <div />
            )}
            <TypeWrapper>{study}</TypeWrapper>
        </CaseStudyWrapper>
    );
};
