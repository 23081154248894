import styled from 'styled-components';

export const CustomerTalksWrapper = styled.div`
    .slick-slider {
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 0;
        }
    }
    .slick-list {
        width: 100%;
    }
    .slick-arrow {
        top: 50% !important;
        transform: translateY(-100%);
        &:before {
            border: initial !important;
        }
    }
    .slick-control i {
        font-size: 2em;
    }
    .block-talks {
        margin-bottom: 80px;
    }
    .slick-initialized .slick-slide {
        display: flex !important;
        height: auto;
    }
    .slick-track {
        display: flex;
    }
    a.block-talks-item {
        width: 100%;
        padding: 0 30px 0 0;
        height: 100%;
        min-height: 730px;
        border: 0;
        ${(p) => p.theme.breakpoints.down('lg')} {
            min-height: 715px;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 0;
            min-height: 715px;
        }
    }
    &:hover {
        .block-talks-inner {
            background: #f8f8f8;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        }
        .block-talks-title {
            color: #222;
        }
        .block-talks-category,
        .block-talks-text {
            color: #333;
        }
    }
    .block-talks-item {
        margin: 10px 0;
        color: #4a4a4a;
        text-decoration: none;
        position: relative;
        display: block;
        transition: background 0.3s, box-shadow 0.3s;
        ${(p) => p.theme.breakpoints.down('sm')} {
            + .block-talks-item {
                margin-top: 30px;
            }
        }
    }

    img {
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 350px;
            margin: 0 auto;
        }
    }

    .block-talks-inner {
        padding: 20px;
        border: 1px solid #d7d7d7;
        min-height: 350px;
        height: auto;
        max-width: 500px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 120px;
            margin: 0 auto;
        }
    }

    .block-talks-category {
        font-size: 12px;
        padding: 4px 10px;
        border-radius: 11px;
        background: #ebebeb;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        + .block-talks-category {
            margin-left: 10px;
        }
        display: none !important;
    }

    .block-talks-title {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 3px;
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
    }

    .block-talks-date {
        font-size: 14px;
        color: #8b8b8b;
        margin-bottom: 13px;
    }

    .block-talks-text {
        margin-top: 15px;
        line-height: 1.5;
        font-size: 13px;
        color: #777;
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
    }
`;
