import {HighlightPanelProps, HighlightProps} from "../shared";

export const convertCmsMultiToHighlights = (allPageData: HighlightPanelProps): HighlightProps[]  => {
    const highlights: HighlightProps[] = [];
    for (const id in allPageData) {
        if (id && Object.hasOwnProperty.call(allPageData, id) && allPageData[id]) {
            let highlight = allPageData[id].highlight;
            if (highlight) {
                highlight = Object.assign(
                    {
                        slug: allPageData[id]._slug,
                        title: allPageData[id]._title,
                        urlPattern: allPageData[id]._urlPattern,
                        activeDate: allPageData[id]._activeDate
                    },
                    highlight
                );
                highlights.push(highlight);
            }
        }
    }

    return highlights;
};
