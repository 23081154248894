import React from 'react';
import {H2, ButtonWrapper} from '../../../elements';
import {
    AnimatedBgwrapper,
    Decals,
    AnimatedContent,
    GradientOverlay
} from './AnimatedSection.styles';
import {ButtonProps} from 'epic-ue-shared';

export interface AnimatedSectionProps {
    title?: string;
    isBackground?: boolean;
    copy?: string;
    buttons?: ButtonProps[];
}
export const AnimatedSection = ({
    title,
    isBackground,
    copy,
    buttons
}: AnimatedSectionProps): JSX.Element => {
    return (
        <AnimatedBgwrapper className={`ue-anim-bg ${isBackground ? 'is-bg-media' : ''}`}>
            <Decals>
                <div className="circle" />
                <div className="circle" />
                <div className="circle blur" />
                <div className="circle-border" />
                <div className="circle-border" />
                <div className="circle-border" />
                <div className="cross" />
                <div className="cross" />
                <div className="cross" />
                <div className="dots">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                </div>
                <div className="dots">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                </div>
                <div className="dots">
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                    <i />
                </div>
                <div className="circle blur" />
            </Decals>
            <GradientOverlay>
                <span />
            </GradientOverlay>
            <AnimatedContent className={`ue-anim-content`}>
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <H2 className="section-heading">
                                <div dangerouslySetInnerHTML={{__html: title || ''}} />
                            </H2>
                            <div
                                className="copy"
                                dangerouslySetInnerHTML={{__html: copy as string}}
                            />
                            <ButtonWrapper buttons={buttons} />
                        </div>
                    </div>
                </div>
            </AnimatedContent>
        </AnimatedBgwrapper>
    );
};
