import React, {useCallback} from 'react';
import {generateRoutePath} from '@epic-core/common';
import {Link, useNavigate} from 'react-router-dom';
import {useLocalization} from '@epic-mw/localization';
import {EOF} from './EndOfFeed.styles';

interface Props {
    currentFilters: any;
    tags: any;
    contentTypes: any[];
}

export const EndOfFeed = (props: Props): JSX.Element => {
    const navigate = useNavigate();
    const tags = props.tags || {};
    const contentTypes = props.contentTypes || [];
    const currentFilters = props.currentFilters || {};
    const currentTagFilters = currentFilters.tags || {};
    const currentTypeFilters = currentFilters.types || {};
    const getMessage = useLocalization();

    const removeTagFilter = useCallback(
        (tag) => {
            return () => {
                const typeArray: string[] = [];
                const tagArray: string[] = [];

                for (const key in currentTagFilters) {
                    if (Object.hasOwnProperty.call(currentTagFilters, tag) && key !== tag) {
                        tagArray.push(encodeURIComponent(key));
                    }
                }

                for (const key in currentTypeFilters) {
                    if (
                        Object.hasOwnProperty.call(currentTypeFilters, key) &&
                        currentTypeFilters[key]
                    ) {
                        typeArray.push(key);
                    }
                }

                const types = typeArray.sort().join('+') || 'all'; //all disables the filter
                const tags = tagArray.sort().join('+');

                navigate(generateRoutePath(`/feed/${types}/${tags}`));
            };
        },
        [navigate, currentFilters]
    );

    const getTagMatch = useCallback(
        (lowercaseTag) => {
            let tagMatch = '';
            for (const key in tags) {
                if (key && Object.hasOwnProperty.call(tags, key)) {
                    if (key.toLowerCase() === lowercaseTag) {
                        tagMatch = key;
                        break;
                    }
                }
            }
            return tagMatch;
        },
        [tags]
    );

    const activeTagElements: React.ReactNode[] = [];
    for (const key in currentTagFilters) {
        if (Object.hasOwnProperty.call(currentTagFilters, key)) {
            const tagStr = getTagMatch(key.toLowerCase());
            if (tagStr) {
                activeTagElements.push(
                    <li key={key}>
                        <a
                            role="menuitem"
                            tabIndex={0}
                            className="discovered-tag clickable"
                            key={key}
                            onClick={removeTagFilter(key)}>
                            <i className="icon-cross" />
                            {tagStr}
                        </a>
                    </li>
                );
            }
        }
    }
    return (
        <div className="search-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <EOF className="end-of-feed-container">
                            <ul className="tag-lookup-results">
                                {activeTagElements}
                                {activeTagElements.length ? <hr /> : ''}
                            </ul>

                            <ul className="filter-content-types">
                                {contentTypes.map((type) => {
                                    if (type.disableFilter) return null;
                                    const label = type.labelPlural || type.label || '';

                                    const styles = {
                                        backgroundColor: type.hexColor || '#10b4f5'
                                    };

                                    let selected = false;
                                    if (currentTypeFilters[type.slug]) {
                                        selected = true;
                                    }
                                    return (
                                        <li className="filter-content-type-wrapper" key={type.slug}>
                                            <a
                                                className={`type ${
                                                    selected ? 'selected' : ''
                                                } ${label.toLowerCase()}`}
                                                role="menuitem"
                                                style={styles}
                                                href={generateRoutePath(`/feed/${type.slug}/`)}
                                                tabIndex={0}>
                                                {label}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>

                            <hr />

                            <Link to={generateRoutePath('feed')} className="ue-button">
                                <i className="icon-cross" />
                                {getMessage('epic.feed.reset.filters')}
                            </Link>
                            <Link
                                to={generateRoutePath('search')}
                                className="ue-button button-search">
                                {getMessage('epic.feed.site.search')}
                            </Link>
                        </EOF>
                    </div>
                </div>
            </div>
        </div>
    );
};
