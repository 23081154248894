import styled from 'styled-components';

export const EOF = styled.div`
    position: relative;
    overflow: hidden;
    padding-bottom: 60px;
    margin: 0 180px 0;
    background-color: ${(p) => p.theme.palette.background.paper};
    ${(p) => p.theme.breakpoints.down('sm')} {
        margin: 0;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        margin: 0 15px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        margin: 0 80px;
    }

    .ue-button {
        white-space: nowrap;
        padding: 12px 24px;
        color: #fff;
        border: none;
        border-radius: unset;
        min-height: 48px;
        min-width: 155px;
        background: none;
        margin: 0 20px;
        border: 2px solid #34c3ff;
        transition: box-shadow 0.25s cubic-bezier(0, 0.42, 0.2, 0.99),
            margin 0.25s cubic-bezier(0, 0.42, 0.2, 0.99);
        i {
            margin-right: 10px;
        }
        &:hover,
        &:focus {
            box-shadow: 0 0 18px #00abf5;
            background-color: transparent;
            border-color: #34c3ff;
            color: #fff;
        }

        ${(p) => p.theme.breakpoints.down('xs')} {
            &.button-search {
                display: table;
                margin: 0 auto;
                margin-top: 25px;
            }
        }
    }

    ul {
        padding: 0;
        list-style: none;
        li.filter-content-type-wrapper {
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 25px;
            &:last-child {
                margin-right: 0;
            }
            a {
                cursor: pointer;
                padding: 6px 10px;
                border-radius: 20px;
                user-select: none;
                color: white;
            }
        }
    }
    h3 {
        .tag {
            display: inline-block;
            padding: 0;
            color: #333;
            &:first-child {
                padding-left: 5px;
            }
            &:not(:only-child) {
                padding-left: 5px;
                &:after {
                    content: '/';
                    padding-left: 5px;
                }
            }
            &:last-child {
                padding-right: 5px;
                &:after {
                    display: none;
                }
            }
        }
    }

    .tag-lookup-results {
        height: auto;
        margin: 10px 0 5px;
        padding: 20px 0 5px;
        .no-match {
            color: #e5e5e5;
            opacity: 0.6;
            font-size: 0.9em;
            font-style: italic;
        }
        .discovered-tag {
            opacity: 0.9;
            transition: opacity 200ms ease-out;
            display: inline-block;
            padding: 4px 8px;
            background-color: ${(p) => p.theme.palette.background.default};
            color: #333;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            text-transform: capitalize;
            color: #fff;
            margin: 5px;
            border-radius: 20px;
            &:hover {
                opacity: 1;
            }
            .icon-cross {
                font-size: 0.5em;
                padding: 0 4px;
                position: relative;
                top: -1px;
            }

            &:before {
                content: none;
            }
        }
    }

    hr {
        border: 0;
        border-top: 1px solid ${(p) => p.theme.palette.background.paper};
        width: 40%;
        margin: 15px auto;
    }
`;
