import styled from 'styled-components';

export const FooterWrapper = styled.div`
    .newsletter-modal {
        height: 450px;
    }

    .up-button span {
        font-family: Brutal_Bold, sans-serif;
        font-weight: 500;
    }

    div.footer-news-letter-modal .footer-news-letter-content .modal-btn {
        font-size: 12px;
        padding: 6px 28px;
        height: auto;
        color: #fff;
        vertical-align: top;
        border-radius: 2px;
        text-transform: uppercase;
        transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
        cursor: pointer;
        text-decoration: none;
    }

    .ReactModal__Overlay.ReactModal__Overlay--after-open.news-letter-overlay {
        background-color: rgba(29, 29, 29, 0.75) !important;

        .newsletter-form {
            padding-top: 2em;

            .email.errorMsg {
                margin: 0;
            }
        }

        h1 {
            font-family: Brutal_Light, sans-serif;
        }
    }

    .footer-news-letter-modal .footer-news-letter-content {
        .newsletter-form .sign-up-form-field {
            &.invalid {
                input.input-field {
                    border: none;
                }
            }

            .email-label {
                font-family: Brutal_Regular;
                text-transform: capitalize;
                font-size: 13px;
                top: 20px;
                padding-left: 15px;
                color: rgba(11, 11, 11, 0.6);
            }

            input.input-field {
                border: 1px solid rgba(0, 0, 0, 0.12);
                background-color: white;
                &:focus {
                    border-color: rgba(11, 11, 11, 0.6);
                }
                padding-left: 4em;
                font-size: 16px;
                border-radius: 3px;
                padding-top: 15px;
                padding-bottom: 15px;
                height: 60px;
            }
        }

        .successView {
            h2 {
                line-height: 1.2em;
                font-size: 21px;
                font-family: Brutal_Regular;
            }
        }
    }
`;
