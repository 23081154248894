import React from 'react';
import styled from 'styled-components';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {
    CmsDynamicSectionProps,
    Subnav,
    SubnavProps,
    TemplateThemeProps,
    MaskedHeader,
    MaskedHeaderProps,
    DynamicSections
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {UnrealLoading} from 'epic-ue-loading';

const urlPattern = '/unreal-engine-5';

const Styled = styled.div`
    position: relative;
    overflow: hidden;

    ${(p) => p.theme.breakpoints.up('lg')} {
        .ue-image-and-text-card .ue-image-and-text-card-img {
            padding-top: 96px;
        }
    }
`;

interface Ue5LandingPageSections {
    items?: CmsDynamicSectionProps[];
}

interface Ue5LandingPageData extends PageData {
    subnav: SubnavProps;
    header: MaskedHeaderProps;
    themeConfig: TemplateThemeProps;
    sections?: Ue5LandingPageSections;
}

const Ue5LandingPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<Ue5LandingPageData>(urlPattern);

    const {
        subnav = {} as SubnavProps,
        header = {} as MaskedHeaderProps,
        themeConfig = {} as TemplateThemeProps,
        sections = {} as Ue5LandingPageSections
    } = pageData;

    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled>
                {getHelmetForMetaTags(pageData)}
                {header && <MaskedHeader {...header} accentColor={accentColor} />}
                <Subnav {...subnav} accentColor={accentColor} />
                <DynamicSections theme={theme} items={items} accentColor={accentColor} />
            </Styled>
        </UeThemeProvider>
    );
};

Ue5LandingPage.getInitialSharedValues = getInitialCmsPageSharedValues(urlPattern);

export default Ue5LandingPage;
