import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';

import {ParagonPageWrapper} from './ParagonPage.styles';
import ParagonSection from '../components/paragon/ParagonSection';
import ParagonCards from '../components/paragon/ParagonCards';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';

import {UnrealLoading} from 'epic-ue-loading';
import ErrorPage from './ErrorPage';
import styled from 'styled-components';
import {ParagonCardSectionProps, ParagonSectionProps} from './ParagonPage';

const cmsUrlPattern = '/uesponsoredcontent';

const SponsoredContent = styled.div`
    .paragon-section.section-cards .container-fluid {
        padding: 0 10px;

        ${(p) => p.theme.breakpoints.up('md')} {
            padding: 0 50px;
        }
    }

    .paragon-section.section-cards .content {
        .paragon-card-item-container {
            margin: 20px auto;

            ${(p) => p.theme.breakpoints.up('xxl')} {
                max-width: inherit;
                margin: 0 auto;
            }
        }

        .paragon-card-item {
            margin: 10px 0;

            &:last-child {
                margin: 20px 0;
            }

            ${(p) => p.theme.breakpoints.up('lg')} {
                margin: 0;
            }

            ${(p) => p.theme.breakpoints.up('xxl')} {
                margin: 25px 0;
            }

            .paragon-card {
                width: 200px;
                height: 350px;
                margin: 0 20px;
            }
        }
    }

    .paragon-section {
        &.inViewport {
            .background-mobile,
            .background {
                background-position: 50% 50%;
            }
        }
    }

    .paragon-section.section-right {
        ${(p) => p.theme.breakpoints.down('md')} {
            display: block;
            .background-mobile,
            .background {
                position: absolute;
                height: 100%;
            }
            .container-fluid {
                height: 100%;
            }
            .content {
                position: absolute;
                bottom: 50%;
                max-width: 564px;
                right: 0;
            }
        }

        &.inViewport {
            .content {
                background-color: rgba(0, 0, 0, 0.7);
            }
        }
        .content {
            color: white;
        }
    }

    .paragon-section.section-form {
        padding-top: 20px;
        &.inViewport {
            .content {
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            .content {
                .desc {
                    font-size: 0.9em;
                    margin-top: 20px;
                }
                .h2 {
                    font-size: 1.5em;
                }
            }
        }
    }
`;

interface SCPageData extends PageData {
    sectionOne: ParagonSectionProps;
    sectionCards: ParagonCardSectionProps;
    sectionRight: ParagonSectionProps;
    sectionLeft: ParagonSectionProps;
    formSection: ParagonSectionProps;
    cardSection: ParagonCardSectionProps;
    isActive: boolean;
}

const SponsoredContentPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<SCPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (pageData && pageData.isActive) {
        const sectionOne = pageData.sectionOne || {};
        const sectionLeft = pageData.sectionLeft || {};
        const sectionRight = pageData.sectionRight || {};
        const sectionCards = pageData.cardSection || {};
        const formSection = pageData.formSection || {};

        return (
            <ParagonPageWrapper>
                <SponsoredContent>
                    {getHelmetForMetaTags(pageData)}

                    <ParagonSection
                        title={sectionOne.title}
                        desc={sectionOne.desc}
                        image={sectionOne.image}
                        mobileImage={sectionOne.mobileImg}
                        imgOpacity={sectionOne.imgOpacity}
                        btnLabel={sectionOne.btnLabel}
                        btnUrl={sectionOne.btnUrl}
                        key="sponsored-content-section1"
                        offset={0}
                        sectionName="top"
                    />

                    <ParagonCards
                        title={sectionCards.title}
                        desc={sectionCards.desc}
                        tweetDesc={sectionCards.tweetDesc}
                        cards={sectionCards.cards}
                        key="sponsored-content-section4"
                        sectionName="cards"
                    />

                    <ParagonSection
                        title={sectionRight.title}
                        desc={sectionRight.desc}
                        image={sectionRight.image}
                        mobileImage={sectionRight.mobileImg}
                        imgOpacity={sectionRight.imgOpacity}
                        btnLabel={sectionRight.btnLabel}
                        btnUrl={sectionRight.btnUrl}
                        key="sponsored-content-section2"
                        offset={100}
                        sectionName="right"
                    />

                    <ParagonSection
                        title={sectionLeft.title}
                        desc={sectionLeft.desc}
                        image={sectionLeft.image}
                        mobileImage={sectionLeft.mobileImg}
                        imgOpacity={sectionLeft.imgOpacity}
                        btnLabel={sectionLeft.btnLabel}
                        btnUrl={sectionLeft.btnUrl}
                        key="sponsored-content-section3"
                        offset={100}
                        sectionName="left"
                    />

                    <ParagonSection
                        title={formSection.title}
                        desc={formSection.desc}
                        image={formSection.image}
                        mobileImage={formSection.mobileImg}
                        imgOpacity={formSection.imgOpacity}
                        btnLabel={formSection.btnLabel}
                        btnUrl={formSection.btnUrl}
                        key="sponsored-content-section5"
                        showSignup={false}
                        offset={100}
                        sectionName="form"
                    />
                </SponsoredContent>
            </ParagonPageWrapper>
        );
    }
    return <div />;
};

SponsoredContentPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default SponsoredContentPage;
