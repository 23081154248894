import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {FeedDescription} from '../../description/FeedDescription';
import {FeedImage, FeedHeading} from '../common';
import {LogoBox} from './elements/LogoBox';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';
import {Spotlight} from 'epic-ue-ui';
import {getImageUrl} from '../../../utils';

export const LargeSpotlightWrapper = styled.div`
    > div {
        background: none;
    }

    .content {
        height: 255px;
        background: none;
        .inner {
            background: none;
        }
    }
    .image-wrapper {
        padding-bottom: 57.6%;
        img {
            max-height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
            height: 100%;
            border: 0px;
            outline: none;
        }
    }
    .image-content {
        height: 100%;
        position: relative;
        overflow: hidden;
        .image-wrapper {
            background: ${(p) => p.theme.palette.background.paper};
            padding-bottom: 57.6%;
            .image {
                transition: transform 350ms ease-in-out, box-shadow 350ms ease-in-out;
            }
        }

        .short {
            pointer-events: none;
            height: 0;
            opacity: 0;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            vertical-align: bottom;
            z-index: 4;
            background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.65) 90%);

            > div {
                padding: 40px 20px;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 0;
                opacity: 0;
            }
        }
    }

    &:hover {
        .short {
            height: 250px;
            opacity: 1;
            transition: height 0.35s ease-out, opacity 0.25s ease-out;
            > div {
                height: 150px;
                opacity: 1;
                transition: height 0.35s ease-out, opacity 0.25s ease-out;
            }
        }
        .image {
            box-shadow: none;
            transform: scale3d(1.02, 1.02, 1.02);
        }
    }
`;

interface Properties {
    item: FeedItemProps;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const LargeSpotlightCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const _feedSlug = item._feedSlug || props.title || '';

    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';
    const imageUrl = getImageUrl(item);

    return (
        <FeedItemDefaultsWrapper>
            <LargeSpotlightWrapper className="large-spotlight-card feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <Spotlight image={imageUrl} offsetRatio={[0.1, -0.1]} variant="small">
                        <Grid container spacing={0}>
                            <Grid lg={4} item>
                                <div className="content">
                                    <ContentTypeLabel item={item} tagMatch={tagMatch} />
                                    <FeedHeading item={item} linkPath={linkPath} title={title} />
                                    <FeedDescription item={item} />
                                </div>
                            </Grid>
                            <Grid item xs>
                                <div className="image-content">
                                    <FeedImage item={item} linkPath={linkPath} title={title} />
                                    <LogoBox item={item} />
                                </div>
                            </Grid>
                        </Grid>
                    </Spotlight>
                </Paper>
            </LargeSpotlightWrapper>
        </FeedItemDefaultsWrapper>
    );
};
