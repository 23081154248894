import React, {useCallback} from 'react';
import {ButtonStyles} from './Button.styles';
import {SimpleLink} from '../link/SimpleLink';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {ButtonProps, determineExternal} from 'epic-ue-shared';
import {useAnalyticsCallback} from 'epic-ue-shared/dist/utils/analyticsUtils';
import root from 'window-or-global';

export const Button = ({
    children,
    className,
    type = 'primary',
    href,
    target,
    as,
    id,
    onClick,
    icon,
    ...props
}: ButtonProps): JSX.Element => {
    let element = as || (href ? 'a' : 'button');

    const clickCallback = useAnalyticsCallback(onClick, id, href, 'button.click');
    const validAnchor = Boolean(href && href?.indexOf('#') === 0);

    const clickAnchor = useCallback(
        (e) => {
            if (validAnchor && href) {
                e.preventDefault();
                const el = root.document.getElementById(href?.replace(/#/g, ''));
                if (el) {
                    el.scrollIntoView();
                }
            }
            clickCallback(e);
        },
        [validAnchor, href, clickCallback]
    );

    let typeClass = '';
    const types = [
        'primary',
        'secondary',
        'black',
        'tertiary',
        'link',
        'showcase',
        'blank',
        'submit'
    ];

    for (let i = 0; i < types.length; i++) {
        if (type === types[i]) {
            typeClass = type;
        }
    }

    const label = props.label || props.title;
    if (type === 'link') {
        return <SimpleLink title={label} href={href} id={id} onClick={onClick} />;
    }

    let btnTarget = '_self';
    let rel = '';
    let hrefAttr = '';
    let to;
    if (element === 'a') {
        const {isExternal, sameOrigin, isMailTo, isLauncher} = determineExternal(href);
        hrefAttr = href || '';
        btnTarget = target || '';

        if (isExternal && !sameOrigin && !target) {
            btnTarget = '_blank';
        }
        rel = isExternal && !sameOrigin ? 'noopener noreferrer' : '';
        if (!isExternal && !isMailTo && !isLauncher && href) {
            element = Link;
            to = generateRoutePath(href);
        }
    }

    return (
        <ButtonStyles
            id={id}
            className={`${className ? className : ''} btn btn-${typeClass}`}
            as={element}
            href={hrefAttr}
            target={btnTarget}
            rel={rel}
            to={to}
            onClick={validAnchor ? clickAnchor : clickCallback}
            {...props}>
            {icon && icon.position === 'before' ? <i className={icon.className} /> : ''}
            <span>{label ? label : children}</span>
            {icon && icon.position === 'after' ? <i className={icon.className} /> : ''}

            {type === 'showcase' && label ? <i className="icon-play2" /> : ''}
        </ButtonStyles>
    );
};
