import React from 'react';
import pt from 'prop-types';
import {
    Card,
    Company,
    CardDescription,
    BottomButtonWrapper,
    Position,
    CardTitle,
    CardContent,
    CardSpeakerImg,
    CardSpeakerImgWrapper,
    CardImgWrapperCircular
} from './Speaker.styles';
import {Button} from 'epic-ue-components';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

class SpeakerCard extends React.PureComponent {
    static propTypes = {
        name: pt.string,
        company: pt.string,
        position: pt.string,
        desc: pt.string,
        avatar: pt.string,
        btnLabel: pt.string,
        btnUrl: pt.string,
        isCircular: pt.bool,
        popDesc: pt.bool
    };

    onClick = (e) => {
        const accountId = this.context.id || '';
        const {name} = this.props;
        emitEvent({
            accountId,
            eventCategory: 'UE4_LMS_LP',
            eventAction: 'FEATURED_INSTRUCTORS',
            eventLabel: name
        });
    };

    render() {
        const {name, company, desc, avatar, btnLabel, btnUrl, isCircular, position} = this.props;
        let bottomLink = '';

        const SpeakerInfo = (
            <div className="speaker-info">
                <CardTitle>{name}</CardTitle>
                <Position>{position}</Position>
                <Company>{company}</Company>
            </div>
        );

        if (btnUrl) {
            bottomLink = (
                <BottomButtonWrapper>
                    <Button
                        href={btnUrl}
                        type={'link'}
                        id="speaker-info"
                        label={btnLabel}
                        onClick={this.onClick}
                    />
                </BottomButtonWrapper>
            );
        }

        let avatarElem = (
            <CardSpeakerImgWrapper className={`${btnUrl ? '' : 'noLink'}`}>
                <CardSpeakerImg style={{backgroundImage: `url(${avatar})`}} />
            </CardSpeakerImgWrapper>
        );

        if (isCircular !== undefined && isCircular) {
            avatarElem = (
                <CardImgWrapperCircular className={`${btnUrl ? '' : 'noLink'}`}>
                    <img src={avatar} alt={name} loading="lazy" />
                </CardImgWrapperCircular>
            );
        }
        return (
            <Card isCircular={isCircular} className={`${isCircular}`}>
                {avatarElem}
                <CardContent>
                    {SpeakerInfo}
                    <CardDescription dangerouslySetInnerHTML={{__html: desc}} />
                </CardContent>
                {bottomLink}
            </Card>
        );
    }
}

export default SpeakerCard;
