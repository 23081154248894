import React from 'react';
import PropTypes from 'prop-types';
import Html from '../../common/Html';

/**
 * Speaker Card
 *
 * Rendered when called from /events/speakerPanel
 *
 * This will render the speaker head shot, bio, register button
 */
export default class SpeakerCard extends React.Component {
    static propTypes = {
        presenterName: PropTypes.string,
        headline: PropTypes.string,
        brief: PropTypes.string,
        room: PropTypes.string,
        speakerImageURL: PropTypes.string,
        alt: PropTypes.string
    };

    render() {
        const {speakerImageURL, alt, presenterName, headline, brief, room} = this.props;
        return (
            <div className="block-speakers-item">
                <div className="block-speakers-image">
                    <img
                        alt={alt}
                        className="img-responsive"
                        height="240"
                        src={speakerImageURL}
                        width="240"
                    />
                </div>
                <div className="block-speakers-info">
                    <div className="block-speakers-title">{headline}</div>
                    <div className="block-speakers-room">{room !== undefined ? room : ''}</div>
                    <div className="block-speakers-name">{presenterName}</div>
                    <div className="block-speakers-text">
                        <Html html={brief} />
                    </div>
                </div>
            </div>
        );
    }
}
