import React from 'react';
import {H2, ButtonWrapper, BgMedia} from '../../../elements';
import styled from 'styled-components';
import {AuthorWrapper} from './CopySection';
import {BackgroundProps, ButtonProps} from 'epic-ue-shared';

const Bgwrapper = styled.div`
    width: 100%;
    height: 50vh;
    * {
        text-align: center;
    }
    .bgmedia-content {
        display: flex;
        align-items: center;
        justify-content: center;
        ${(p) => p.theme.breakpoints.down('sm')} {
            text-align: center;
        }
    }
    &.has-pull-quote,
    &.has-quote {
        text-align: center;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: auto;
        }
        .background-content {
            max-width: 500px;
            ${(p) => p.theme.breakpoints.down('md')} {
                max-width: 100%;
            }
            margin: 0 auto;
        }
    }
    &.has-pull-quote {
        border-left: 2px solid ${(p) => p.theme.ue.colors.cards.default.background};
        border-top: 2px solid ${(p) => p.theme.ue.colors.cards.default.background};
        padding-top: 20px;
        padding-left: 20px;
    }
    .pull-quote {
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
        font-size: 1.8em;
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 1.1em;
        }
        color: ${(p) => p.theme.ue.colors.primary};
        text-transform: uppercase;
        font-style: italic;
    }
    .icon-quote-open {
        display: inline;
        font-size: 3em;
        padding: 20px;
    }

    .copy {
        display: inline;
    }
`;

const Content = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 2em;
    h2.section-heading {
        margin-top: 12px;
    }
    .copy {
        margin-left: auto;
        margin-right: auto;
    }
`;
const BgContent = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .row,
    .col-xs-12 {
        height: 100%;
    }
`;

export interface BackgroundSectionProps {
    title: string;
    copy: string;
    lbClass?: string;
    author?: string;
    pullQuote?: boolean;
    quote?: boolean;
    media: BackgroundProps;
    buttons?: ButtonProps[];
}

export const BackgroundSection = ({
    title,
    quote,
    pullQuote,
    author,
    lbClass,
    copy,
    buttons,
    media
}: BackgroundSectionProps): JSX.Element => {
    let heightOverride = '';
    if (media && media.height) {
        heightOverride = media.height;
    }
    return (
        <Bgwrapper
            className={`background-section ${lbClass ? lbClass : ''} ${quote ? 'has-quote' : ''}${
                pullQuote ? 'has-pull-quote' : ''
            }`}
            style={{height: heightOverride}}>
            <BgMedia autoplay={true} background={media} imgMaxWidth={1600} />
            <BgContent className="background-section-content">
                <div className="row">
                    <div className="col-xs-12">
                        <Content className="background-content">
                            <H2 className="section-heading">
                                <div dangerouslySetInnerHTML={{__html: title}} />
                            </H2>
                            <div
                                className={`${quote ? 'quote' : ''} ${
                                    pullQuote ? 'pull-quote' : ''
                                }`}>
                                {quote ? <i className="icon-quote-open" /> : ''}
                                <div className="copy" dangerouslySetInnerHTML={{__html: copy}} />
                                <span>{quote ? '”' : ''}</span>
                            </div>
                            {quote && author ? (
                                <AuthorWrapper className="author">- {author}</AuthorWrapper>
                            ) : (
                                ''
                            )}
                            <ButtonWrapper buttons={buttons} />
                        </Content>
                    </div>
                </div>
            </BgContent>
        </Bgwrapper>
    );
};
