import React from 'react';
import styled, {keyframes} from 'styled-components';

const tileIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.1) rotate(10deg);
        background-position: 0 0;
    }
    75% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
    100% {
        opacity: 0;
        transform: scale(.8) rotate(-10deg);
    }
`;

const shiftShime = keyframes`
    0% {
        left: -50px;
    }
    100% {
        left: 800px;
    }
`;

export const LoadingContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 350px;
    height: 400px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        width: 260px;
    }
    .mini-grid {
        width: 350px;
        height: 400px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 260px;
        }
        position: absolute;
        & > div {
            display: inline-block;
            background-color: ${(p) => p.theme.palette.background.paper};
            margin-bottom: 5px;
            height: 100px;
            ${(p) => p.theme.breakpoints.down('md')} {
                height: 80px;
            }
            opacity: 0;
            animation: ${tileIn} 2s ease infinite 0s;

            @media (prefers-reduced-motion: reduce) {
                animation: none;
            }

            overflow: hidden;
            &:after {
                content: '';
                position: absolute;
                left: -50px;
                width: 75px;
                background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.4) 0%,
                    rgba(255, 255, 255, 0) 100%
                );
                top: 0;
                height: 100px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: 80px;
                }

                animation: ${shiftShime} 2s ease infinite 0.2s;

                @media (prefers-reduced-motion: reduce) {
                    animation: none;
                }
            }
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
                margin-right: 10px;
            }
            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.4s;
            }
            &:nth-child(4) {
                animation-delay: 0.6s;
            }
            &:nth-child(5) {
                animation-delay: 0.8s;
            }
            &:nth-child(6) {
                animation-delay: 1s;
            }
        }
        .wide {
            width: 100%;
        }
        .fifthy {
            width: 48%;
        }
        .three {
            width: 31%;
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 30%;
            }
        }
    }
`;

export class FeedLoadingIndicator extends React.PureComponent {
    render(): JSX.Element {
        return (
            <LoadingContainer className="loading-container">
                <div className="mini-grid">
                    <div className="wide" />
                    <div className="fifthy" />
                    <div className="fifthy" />
                    <div className="three" />
                    <div className="three" />
                    <div className="three" />
                </div>
            </LoadingContainer>
        );
    }
}
