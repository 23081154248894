import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import ParagonSection from '../components/paragon/ParagonSection';
import ParagonCards from '../components/paragon/ParagonCards';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import {ParagonPageWrapper} from './ParagonPage.styles';
import {UnrealLoading} from 'epic-ue-loading';
import ErrorPage from './ErrorPage';

export interface ParagonSectionProps {
    title: string;
    desc: string;
    image: string;
    mobileImg: string;
    imgOpacity?: number;
    btnLabel: string;
    btnUrl: string;
}

export interface ParagonCardSectionProps {
    title: string;
    desc: string;
    tweetDesc: string;
    image: string;
    cards: any[];
}

interface ParagonPageData extends PageData {
    sectionOne: ParagonSectionProps;
    sectionCards: ParagonCardSectionProps;
    sectionTwo: ParagonSectionProps;
    sectionThree: ParagonSectionProps;
    sectionFour: ParagonCardSectionProps;
    sectionFive: ParagonSectionProps;
    form: ParagonSectionProps;
    isActive;
}

const cmsUrlPattern = '/paragon';

const ParagonPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<ParagonPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (pageData && pageData.isActive) {
        const sectionOne = pageData.sectionOne || {};
        const sectionTwo = pageData.sectionTwo || {};
        const sectionThree = pageData.sectionThree || {};
        const sectionFour = pageData.sectionFour || {};

        return (
            <ParagonPageWrapper id="Paragon" className="Paragon-wrapper">
                {getHelmetForMetaTags(pageData)}
                <ParagonSection
                    title={sectionOne.title}
                    desc={sectionOne.desc}
                    btnLabel={sectionOne.btnLabel}
                    btnUrl={sectionOne.btnUrl}
                    image={sectionOne.image}
                    mobileImage={sectionOne.mobileImg}
                    key="section1"
                    offset={0}
                    sectionName="top"
                />

                <ParagonSection
                    title={sectionTwo.title}
                    desc={sectionTwo.desc}
                    image={sectionTwo.image}
                    mobileImage={sectionTwo.mobileImg}
                    key="section2"
                    offset={100}
                    sectionName="right"
                />

                <ParagonSection
                    title={sectionThree.title}
                    desc={sectionThree.desc}
                    image={sectionThree.image}
                    key="section3"
                    offset={500}
                    sectionName="left"
                />

                <ParagonCards
                    title={sectionFour.title}
                    desc={sectionFour.desc}
                    tweetDesc={sectionFour.tweetDesc}
                    image={sectionFour.image}
                    cards={sectionFour.cards}
                    key="section4"
                    sectionName="cards"
                />

            </ParagonPageWrapper>
        );
    }
    return <div />;
};

ParagonPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default ParagonPage;
