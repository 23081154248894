import styled from 'styled-components';

export const Header = styled.div`
    width: auto;
    max-width: 1456px;
    margin: 0 auto;

    h1 {
        color: ${(p) => p.theme.palette.text.secondary};
        font-size: 1.5em;
        padding: 0;
        margin: 40px 10px 0;
        font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
    }

    .hr {
        padding: 0.75em;
        border-width: 1px 0 0;
        border-style: solid;
        border-image: linear-gradient(
                90deg,
                rgba(230, 230, 230, 0),
                #333 100%,
                rgba(230, 230, 230, 0) 100%
            )
            5%;
        transform: rotate(180deg);
        max-width: 71.25rem;
        display: block;
        margin-bottom: 40px;
        margin-left: 10px;
    }
`;

export const FeedView = styled.div`
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: ${(p) => p.theme.palette.background.default};
    min-height: 100vh;

    a:visited,
    a:active,
    a:focus {
        outline: none;
        padding: 0;
        margin: 0;
    }
`;

export const FeedPageWrapper = styled.div`
    > div.container {
        @media (min-width: 1200px) {
            width: auto;
            max-width: 1456px;
        }
    }

    .large-spotlight-card.feed-item .content {
        background: initial;
    }

    &.loc-ja {
        .matching-tag,
        .feed-item {
            h2,
            .date {
                font-family: 'Noto Sans JP', Noto-Regular, sans-serif;
            }
        }
    }

    &.loc-ko {
        .matching-tag,
        .feed-item {
            h2,
            .date {
                font-family: 'Noto Sans KR', Noto-Regular, sans-serif;
            }
        }
    }

    &.loc-zh_CN {
        .matching-tag,
        .feed-item {
            h2,
            .date {
                font-family: 'Noto Sans SC', Noto-Regular, sans-serif;
            }
        }
    }
`;
