import React from 'react';
import {UeThemeProvider, ueDarkTheme} from 'epic-ue-theme';
import {GlobalStyle, ClassicAppWrapper} from './ClassicApp.styles';
import {ConnectedPage} from '../routes/routes';
import {LocalizationContext} from '@epic-mw/localization';

interface ChildrenProps {
    children?: React.ReactNode;
    ignoreClassicApp?: boolean;
}

export const ClassicRouteChildren = ({
    children,
    ignoreClassicApp
}: ChildrenProps): JSX.Element | null => {
    const child = (
        <LocalizationContext.Consumer>
            {(i18nContext) => {
                return React.cloneElement(<>{children}</>, i18nContext);
            }}
        </LocalizationContext.Consumer>
    );

    return (
        <>
            {!ignoreClassicApp && <GlobalStyle />}
            <UeThemeProvider theme={ueDarkTheme}>
                {ignoreClassicApp ? (
                    child
                ) : (
                    <ClassicAppWrapper className="ue-bs">{child}</ClassicAppWrapper>
                )}
            </UeThemeProvider>
        </>
    );
};

interface Props {
    element: ConnectedPage;
}

const ClassicRoute = ({element}: Props): JSX.Element | null => {
    return (
        <>
            <GlobalStyle />
            <UeThemeProvider theme={ueDarkTheme}>
                <ClassicAppWrapper className="ue-bs">
                    <LocalizationContext.Consumer>
                        {(i18nContext) => {
                            return React.createElement(element, i18nContext);
                        }}
                    </LocalizationContext.Consumer>
                </ClassicAppWrapper>
            </UeThemeProvider>
        </>
    );
};

export default ClassicRoute;
