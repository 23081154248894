import React from 'react';

import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import {CMSMap} from '../components/cms/CMSMap';

const cmsUrlPattern = '/twinmotion/resellers';

const TwinmotionResellersPage = (): JSX.Element => (
    <CMSMap
        cmsUrlPattern={cmsUrlPattern}
        markerTooltipConfig={{
            properties: [
                {property: 'name', url: 'url', variant: 'primary'},
                {property: 'phone'},
                {property: 'email'},
                {property: 'street'},
                {property: 'postcode'},
                {property: 'city'}
            ]
        }}
    />
);

TwinmotionResellersPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default TwinmotionResellersPage;
