import React, {useEffect} from 'react';
import root from 'window-or-global';
import styled from 'styled-components';
import {useSearchParams} from 'react-router-dom';
import {getBowserState} from '@epic-core/common';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData,
    useEpicSid
} from 'epic-ue-shared';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';
import ErrorPage from '../ErrorPage';
import {
    CmsDynamicArray,
    ImageHeroProps,
    ImmersiveHeaderSectionProps,
    TemplateThemeProps,
    VerticalSpacer,
    DownloadHeroUE5,
    DownloadSteps,
    DownloadHeroUE5Props,
    DownloadStepsProps,
    DownloadCardUE5,
    DynamicSections,
    Spotlight
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {UnrealLoading} from 'epic-ue-loading';
import {GeneralLandingPageSections} from 'epic-ue-ui';
import {useSharedValue} from '@epic-core/hooks';
import {IndustrySelected} from '../../shared';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;

const cmsUrlPattern = '/download';

type DynamicHeaderOptions = ImageHeroProps | ImmersiveHeaderSectionProps;
type DynamicHeaderOptionsArray = CmsDynamicArray<DynamicHeaderOptions>;
export interface DynamicHeaderProps {
    headerOptions?: DynamicHeaderOptionsArray;
}
export type GeneralLandingHeaderProps = ImageHeroProps | DynamicHeaderProps;

interface DownloadUE5PageData extends PageData {
    themeConfig: TemplateThemeProps;
    downloadHero?: DownloadHeroUE5Props;
    downloadSteps?: DownloadStepsProps;
    downloadCard?: DownloadStepsProps;
    sections?: GeneralLandingPageSections;
}

const DownloadUE5Page = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<DownloadUE5PageData>(cmsUrlPattern);
    const [industrySelected] = useSharedValue(IndustrySelected);
    const [searchParams, setSearchParams] = useSearchParams();
    const autoStart = searchParams.get('start') || '';
    const epicSid = useEpicSid();

    const {
        themeConfig = {} as TemplateThemeProps,
        downloadHero = {} as DownloadHeroUE5Props,
        downloadSteps = {} as DownloadStepsProps,
        sections = {} as GeneralLandingPageSections
    } = pageData;

    const {items = []} = sections;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    useEffect(() => {
        if (autoStart && !cmsLoading) {
            const {mobile, tablet} = getBowserState();
            const iPad =
                /iPad/.test(root.navigator?.platform) ||
                (root.navigator?.platform === 'MacIntel' && root.navigator?.maxTouchPoints > 1);
            const enableDownload = !(mobile || tablet || iPad);
            if (enableDownload) {
                let href = `https://launcher-public-service-prod06.ol.epicgames.com/launcher/api/installer/download/EpicGamesLauncherInstaller.msi?productName=games`;
                const mac = root._epicGamesNav?.browser?.mac;
                if (mac) {
                    href = `https://launcher-public-service-prod06.ol.epicgames.com/launcher/api/installer/download/EpicGamesLauncher.dmg?productName=games`;
                }
                if (epicSid) {
                    href += `&trackingId=${epicSid}`;
                }
                root.location.href = href;
                emitEvent({
                    eventAction: 'client.download',
                    eventLabel: 'games',
                    eventValue: 'unrealEngine'
                });

                setSearchParams({}, {replace: true});
            }
        }
    }, [autoStart, cmsLoading]);

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const downloadCards: React.ReactNode[] = [];

    const downloadCardProps = downloadSteps?.steps?.[0]?.downloadOptions?.cards;

    downloadCards.push(
        <DownloadCardUE5
            {...downloadCardProps?.[0]}
            industrySelected={industrySelected}
            variant="download"
            accentColor={accentColor}
        />
    );
    downloadCards.push(
        <DownloadCardUE5
            {...downloadCardProps?.[1]}
            industrySelected={industrySelected}
            variant="open"
            accentColor={accentColor}
        />
    );

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled className={theme}>
                {getHelmetForMetaTags(pageData)}
                <div className="download-header-bg">
                    <Spotlight
                        accentColor={accentColor}
                        variant="medium"
                        shape="circular"
                        offsetRatio={[0.05, -0.1]}
                        optMaxWidth={1600}>
                        <DownloadSteps
                            {...downloadSteps}
                            backgroundColorInitial={true}
                            downloadCards={downloadCards}
                        />
                    </Spotlight>
                    <VerticalSpacer variant="custom" spacing={3} downSmSpacing={0} />
                    <DownloadHeroUE5
                        {...downloadHero}
                        accentColor={accentColor}
                        backgroundColorInitial={true}
                    />
                </div>

                <VerticalSpacer variant="section" />
                <DynamicSections theme={theme} items={items} accentColor={accentColor} />
            </Styled>
        </UeThemeProvider>
    );
};

DownloadUE5Page.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default DownloadUE5Page;
