import {generateRoutePath} from '@epic-core/common';

export const generateSearchURL = (
    queryUrlJSON?: {filter?: string; keyword?: string},
    filterName?: string,
    keyword?: string
): string => {
    if (queryUrlJSON) {
        if (filterName !== undefined) {
            queryUrlJSON.filter = filterName;
        }
        if (keyword !== undefined) {
            queryUrlJSON.keyword = keyword;
        }
    }

    let searchQueryParams;
    if (queryUrlJSON) {
        searchQueryParams = `?${Object.keys(queryUrlJSON)
            .map((k) => {
                return `${encodeURIComponent(k)}=${encodeURIComponent(queryUrlJSON[k])}`;
            })
            .join('&')}`;
    }

    return generateRoutePath(`search${searchQueryParams}`);
};
