import styled from 'styled-components';

export const Map = styled.div`
    min-height: calc(94vh - 51.8px);
    position: relative;
    background: rgb(42, 42, 42);
    background: linear-gradient(
        180deg,
        rgba(42, 42, 42, 1) 0%,
        rgba(33, 33, 33, 1) 5%,
        rgba(33, 33, 33, 1) 95%,
        rgba(42, 42, 42, 1) 100%
    );

    ${p => p.theme.breakpoints.down('md')} {
        flex: none;
    }
`;
export const GoogleMap = styled.div<{description?: string;}>`
    height: calc(94vh - 51.8px);
    width: 100vw;
    position: relative;
    overflow: hidden;
`;

export const MapWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
`;
