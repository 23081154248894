import React from 'react';
import PropTypes from 'prop-types';
import Html from '../../common/Html';
import root from 'window-or-global';
import {EventHeaderWrapper} from './Header.styles';

class Header extends React.Component {
    static propTypes = {
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        title: PropTypes.string,
        locationStr: PropTypes.string,
        backgroundImageURL: PropTypes.string,
        body: PropTypes.string,
        targetURL: PropTypes.string,
        linkText: PropTypes.string,
        locale: PropTypes.string,
        targetIsExternal: PropTypes.bool
    };

    handleClick = (e) => {
        const targetUrl = this.props.targetURL || '';
        const targetIsExternal = this.props.targetIsExternal;
        if (targetIsExternal) {
            return;
        }

        const internalLongUrl = root.location.origin.indexOf('unrealengine') >= 0;

        if (internalLongUrl) {
            root.open(targetUrl);
        } else {
            root.open(targetUrl, '_blank');
        }
    };

    render() {
        const image = this.props.backgroundImageURL || '';
        const headerStyle = {
            background: `url(${image}) center center no-repeat #2f3356`,
            backgroundSize: 'cover'
        };

        const {
            targetURL: targetUrl,
            linkText,
            targetIsExternal,
            locale,
            startDate,
            endDate
        } = this.props;

        const isYearFirst = locale === 'ko';
        const withoutYear = {month: 'short', day: 'numeric'};
        const withYear = {month: 'short', day: 'numeric', year: 'numeric'};
        let dateStr;
        if (startDate && endDate) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            const startStr = start.toLocaleString(locale, isYearFirst ? withYear : withoutYear);
            const endStr = end.toLocaleString(locale, isYearFirst ? withoutYear : withYear);
            dateStr = `${startStr} - ${endStr}`;
        } else if (startDate) {
            const start = new Date(startDate);
            dateStr = start.toLocaleString(locale, withYear);
        } else if (endDate) {
            const end = new Date(endDate);
            dateStr = end.toLocaleString(locale, withYear);
        }

        return (
            <EventHeaderWrapper className="enterprise-intro" style={headerStyle}>
                <div className="intro-date">{dateStr}</div>
                <div className="intro-location">{this.props.locationStr}</div>
                <h1 className="intro-title-enterprise">{this.props.title}</h1>
                <div className="intro-text">
                    <Html html={this.props.body} />
                </div>
                <div className="intro-action">
                    {targetUrl ? (
                        <a
                            role="menuitem"
                            onClick={this.handleClick}
                            href={targetIsExternal ? targetUrl : null}
                            className="ue-cta-button button-lg button-hover">
                            {linkText}
                        </a>
                    ) : null}
                </div>
            </EventHeaderWrapper>
        );
    }
}
export default Header;
