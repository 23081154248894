import React from 'react';
import {
    EmbeddedVideo,
    EmbeddedVideoProps,
    LargeQuote,
    LargeQuoteProps,
    ImageGallery,
    ImageGalleryProps,
    CmsComponentMapper,
    DownloadSection,
    DownloadSectionProps,
    NewsletterSectionProps,
    VideoGallery,
    VideoGalleryProps,
    ArticleCalloutCard,
    ArticleCalloutCardProps,
    ArticleCarousel,
    ArticleCarouselProps,
    ArticleCopy,
    ArticleCopyProps,
    ArticleImage,
    ArticleImageProps,
    ArticleMetaCopy,
    ArticleMetaCopyProps,
    ArticleQandA,
    CmsDynamicSectionOptionsArray,
    MultiCard,
    MultiCardProps,
    UeImageCompare,
    UeImageCompareProps,
    VerticalSpacer
} from 'epic-ue-ui';
import styled from 'styled-components';
import {useBreakpointDown} from 'epic-ue-theme';
import {
    CenteredRichTextBlock,
    CenteredRichTextBlockProps
} from 'epic-ue-ui/dist/components/elements/text/CenteredRichTextBlock';

const Styled = styled.div`
    .paragraph {
        color: ${(p) => p.theme.palette.text.primary};
    }
    .ue-icon-quote-rounded {
        /* Override element inline style for color */
        color: ${(p) =>
            p.theme.ifLight(
                p,
                'theme.palette.common.black',
                'theme.palette.common.white'
            )} !important;
    }
`;
export interface ExplainersSectionSelectionProps {
    items?: CmsDynamicSectionOptionsArray;
}

export type CmsDynamicExplainersSectionOptions =
    | ArticleCopyProps
    | ArticleMetaCopyProps
    | ArticleImageProps
    | ArticleCarouselProps
    | ArticleCalloutCardProps
    | EmbeddedVideoProps
    | MultiCardProps
    | LargeQuoteProps
    | DownloadSectionProps
    | NewsletterSectionProps
    | ImageGalleryProps
    | UeImageCompareProps
    | VideoGalleryProps
    | CenteredRichTextBlockProps;

export const ExplainersSectionSelection = (
    props: ExplainersSectionSelectionProps
): JSX.Element | null => {
    const {items = []} = props;

    const isSm = useBreakpointDown('sm');
    const forceCaptionBelow = isSm ? true : false;

    return (
        <Styled>
            <CmsComponentMapper
                dynamicPageDataArray={items}
                elementMapping={{
                    articleCopy: <ArticleCopy />,
                    articleMetaCopy: <ArticleMetaCopy />,
                    articleImage: <ArticleImage forceCaptionBelow={forceCaptionBelow} />,
                    articleQandA: <ArticleQandA />,
                    articleCarousel: <ArticleCarousel />,
                    articleCalloutCard: <ArticleCalloutCard />,
                    embeddedVideo: <EmbeddedVideo />,
                    multiCard: <MultiCard />,
                    largeQuote: <LargeQuote />,
                    download: <DownloadSection />,
                    imageGallery: <ImageGallery />,
                    imageCompare: <UeImageCompare />,
                    videoGallery: <VideoGallery />,
                    centeredRichText: <CenteredRichTextBlock />
                }}
                itemSeparator={<VerticalSpacer variant="feature" />}
            />
        </Styled>
    );
};
