import React from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {UnrealLoading} from 'epic-ue-loading';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    getUrlPattern,
    PageData,
    useCmsData
} from 'epic-ue-shared';
import {UeThemeProvider, ueDarkTheme} from 'epic-ue-theme';
import {
    ArticleCopy,
    ArticleCopyProps,
    ContrastHeadlines,
    ContrastHeadlinesProps,
    GridContainer,
    LineTextBlockProps,
    MultiCard,
    MultiCardProps,
    OffsetTitlesVideoHero,
    OffsetTitlesVideoHeroProps,
    VerticalSpacer
} from 'epic-ue-ui';
import ErrorPage from '../ErrorPage';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
    background-color: #000;

    h3 {
        font-family: 'Inter', sans-serif;
        font-size: 26px;
        line-height: 32.4px;
        font-weight: 500;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 22px;
            line-height: 30px;
            font-weight: 400;
        }
    }
    h3.big {
        font-family: 'Inter Tight', sans-serif;
        font-weight: 900!important;
        letter-spacing: 1px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-weight: 700!important;
        }
    }

    .image-card-content {
        text-align: center;
        a.ue-link-wrap:hover {
            color: #F2C867;
        }
        .paragraph {
            font-family: 'Inter', sans-serif;
        }
        button {
            border-image: radial-gradient(100% 185.14% at 100% 100%,#F2C867 0%,#F2C867 100%) 5!important;
            &:hover {
                box-shadow: 0 0 18px #F2C867;
            }
            .typography-rich-text {
                font-family: 'Inter', sans-serif;
                font-size: 16px;
                line-height: 23.52px;
                font-weight: 700;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 13px;
                    line-height: 30px;
                }
            }
        }
    }

    .article-copy .raw-copy .paragraph {
        font-family: 'Inter', sans-serif!important;
        font-size: 16px!important;
        line-height: 28.8px!important;
        opacity: 70%;
        text-underline-offset: 2px;
        strong {
            font-family: 'Inter Tight', sans-serif!important;
            font-size: 16px!important;
            line-height: 28.8px!important;
            letter-spacing: 2px!important;
        }
        a {
            text-decoration: underline;
            color: unset;
            &:hover {
                color: #F2C867;
                transition: color 0.25s ${(p) => p.theme.easing};
                animation: none;
            }
        }
    }
`;

interface BlackFridaySalePageData extends PageData {
    videoHero?: OffsetTitlesVideoHeroProps;
    contrastHeadlines?: ContrastHeadlinesProps;
    multicard?: MultiCardProps;
    textBlockLine?: LineTextBlockProps;
    attributionsCopy?: ArticleCopyProps;
}

const BlackFridaySalePage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<BlackFridaySalePageData>(urlPattern);

    const {
        videoHero = {} as OffsetTitlesVideoHeroProps,
        contrastHeadlines = {} as ContrastHeadlinesProps,
        multicard = {} as MultiCardProps,
        attributionsCopy = {} as ArticleCopyProps
    } = pageData;

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <UeThemeProvider theme={ueDarkTheme}>
            <link rel="preconnect" href="https://cdn.fonts.net" />
            <link href="https://cdn.fonts.net/kit/475aa871-191c-4042-b6c7-9c1a71dd4ce4/475aa871-191c-4042-b6c7-9c1a71dd4ce4.css" rel="stylesheet" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Inter+Tight:wght@900&family=Inter:wght@500&display=swap" rel="stylesheet" />
            <Styled>
                {getHelmetForMetaTags(pageData)}
                <OffsetTitlesVideoHero {...videoHero} />
                <VerticalSpacer variant="custom" spacing={5} downSmSpacing={3} />
                <GridContainer>
                    <ContrastHeadlines {...contrastHeadlines} isCentered />
                </GridContainer>
                <VerticalSpacer variant="custom" spacing={14} downSmSpacing={10} />
                <MultiCard {...multicard} />
                <VerticalSpacer variant="custom" spacing={14} downSmSpacing={10} />
                <div className="article-copy">
                    <ArticleCopy {...attributionsCopy} />
                </div>
                <VerticalSpacer variant="custom" spacing={15} downSmSpacing={15} />
            </Styled>
        </UeThemeProvider>
    );
};

BlackFridaySalePage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default BlackFridaySalePage;
