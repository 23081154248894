import React, {useEffect} from 'react';
import {PathMatch, useLocation, useParams} from 'react-router-dom';
import {getLocale} from '@epic-core/common';
import ArticleRedirect from '../components/redirect/ArticleRedirect';
import BlogDetail from '../components/blog/BlogDetail';
import {useRecentBlogs} from '../shared/blog.hooks';
import {BlogTag} from '../utils/metaUtils';
import {ArticleViewWrapper} from './ArticleView.styles';

const getBlog = (blog, slug) => {
    if (!blog || !slug || !blog._slug || blog._slug !== slug) return null; //invalid or the wrong blog
    return blog;
};

export interface ArticleViewParams {
    locale: string;
    title: string;
}

interface Properties {
    blog?: any;
    slug?: string;
    config?: any;
    enableComments?: boolean;
    rootPageSlug?: string;
    pageSlug?: string;
    routerRootUrl?: string;

    enableLinkNavigator?: boolean;
    isLauncher?: boolean;
    disableShareIcons?: boolean;
    customConfigFunc?: (param: any) => void; // customConfigFunc (blog) => return {config}
    //customShareFunc(blogParams; URL().BLOG.SOCIALSHARE) => return [url1, url2, url2]
    customShareFunc?: () => void;
    overrideMetaTags?: BlogTag[];
    enableLightBox?: boolean;
    blogContentFlattened?: boolean;
    linkNavigatorRootPageSlug?: boolean;
    localizedTags?: {[key: string]: string};
    cmsLoading?: boolean;
    setArticleViewLoading: (arg: boolean) => void;
    match?: PathMatch | null;
}

const ArticleView = ({
    blog,
    slug,
    config,
    rootPageSlug = 'blog',
    routerRootUrl = 'blog',
    enableLinkNavigator = true,
    disableShareIcons = false,
    customConfigFunc,
    overrideMetaTags = [],
    linkNavigatorRootPageSlug,
    localizedTags,
    cmsLoading,
    setArticleViewLoading
}: Properties): JSX.Element => {
    const location = useLocation();
    const params = useParams();
    const validBlog = getBlog(blog, slug) || {};
    const {recentBlogs} = useRecentBlogs(getLocale(), 4);

    const loading = Boolean(cmsLoading || false);

    useEffect(() => {
        setArticleViewLoading(loading);
    }, [loading]);

    const locale = getLocale();

    const detailProps = {
        blog: validBlog,
        recent: recentBlogs,
        locale,
        slug: params.title,
        routerRootUrl,
        enableLinkNavigator,
        disableShareIcons,
        config,
        rootPageSlug,
        overrideMetaTags,
        linkNavigatorRootPageSlug,
        localizedTags
    };

    // // customConfigFunc is a prop that can be used to pass configs for blogDetails from outside of the plugin
    // // check UE for example
    if (customConfigFunc) {
        Object.assign(detailProps, customConfigFunc(validBlog));
    }

    return (
        <ArticleViewWrapper className={`loc-${locale}`} id="articleView">
            <ArticleRedirect
                blog={blog}
                loading={loading}
                location={location}
                routerRootUrl={routerRootUrl}>
                <BlogDetail {...detailProps} />
            </ArticleRedirect>
        </ArticleViewWrapper>
    );
};

export default ArticleView;
