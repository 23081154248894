import React from 'react';
import {H2, ButtonWrapper, BgMedia, Card} from '../../../elements';
import styled from 'styled-components';
import {BackgroundProps, ButtonProps} from 'epic-ue-shared';

export const CardSectionWrapper = styled.div`
    width: 100%;
    height: 60vh;
    position: relative;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    .card {
        max-width: 1200px;
        min-height: 350px ${(p) => p.theme.breakpoints.down('sm')} {
            max-width: 400px;
        }
        ${(p) => p.theme.breakpoints.down('xs')} {
            max-width: 300px;
        }
        margin: 0 auto;
        height: auto;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        .copy {
            margin: 1em auto 2em;
        }
    }
`;

export const CardSectionContent = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
`;

export interface CardSectionProps {
    title: string;
    copy: string;
    lbClass?: string;
    media: BackgroundProps;
    buttons?: ButtonProps[];
}
export const CardSection = ({
    title,
    lbClass,
    copy,
    buttons,
    media
}: CardSectionProps): JSX.Element => {
    return (
        <CardSectionWrapper className={`card-section ${lbClass ? lbClass : ''}`}>
            <BgMedia autoplay={true} background={media} imgMaxWidth={1400} />
            <CardSectionContent>
                <Card type="overlay">
                    <H2 className="section-heading">
                        <div dangerouslySetInnerHTML={{__html: title}} />
                    </H2>
                    <div className="copy" dangerouslySetInnerHTML={{__html: copy}} />
                    <ButtonWrapper buttons={buttons} />
                </Card>
            </CardSectionContent>
        </CardSectionWrapper>
    );
};
