import React from 'react';
import PropTypes from 'prop-types';
import FitText from '../../../common/FitText';
import {RichTextSpan} from 'epic-ue-ui/dist/components/elements/text/RichTextSpan';
import {startAndEndTimeFormatter} from 'epic-ue-shared';
import {getInnerWidth, getBowserState, truncateByWords, getLocale} from '@epic-core/common';
import root from 'window-or-global';
import {getOptImage} from 'epic-ue-shared';

const placeholder =
    'https://cdn2.unrealengine.com/unrealengine-blog-unrealengine-dark-logo-375x275-375x275-d6ab05a72057.jpg';
const gapSize = 26;
const originalOrientation = {}; //used to track previous orientation

class Card extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: props.type === 'vertical' ? 754 : 364,
            width: props.type === 'horizontal' ? 780 : 0,
            orientation: props.type,
            heightAdjusted: false
        };
    }

    static propTypes = {
        highlight: PropTypes.object,
        type: PropTypes.string,
        rowWidth: PropTypes.number,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    componentDidMount() {
        this.checkSize(this.props, this.state);
    }

    componentDidUpdate() {
        this.checkSize(this.props, this.state);
    }

    static getDerivedStateFromProps(props, state) {
        let newHeight;
        let newOrientation;
        //if the window was resized try and flip some vertical components to horizontal or flip back
        const {type, rowWidth} = props;

        const adjustBreakpoints = props.adjustBreakpoints || false;
        const highlight = props.highlight || {};
        const slug = highlight.slug;

        let orientation = type;
        if (
            slug &&
            type === 'vertical' &&
            rowWidth > 0 &&
            ((!adjustBreakpoints && rowWidth < 940) || (adjustBreakpoints && rowWidth < 875))
        ) {
            orientation = 'horizontal';
            originalOrientation[slug] = 'vertical';
            newHeight = rowWidth / 2 - gapSize;
        } else if (slug && originalOrientation[slug] === 'vertical' && rowWidth >= 940) {
            orientation = 'vertical';
            originalOrientation[slug] = '';
            newHeight = props.rowWidth * 0.6666 - gapSize * 2;
        }

        if (orientation !== state.orientation) {
            newOrientation = orientation;
        }
        if (!newHeight && !newOrientation) return state;
        return {
            height: newHeight || state.height,
            orientation: newOrientation || state.orientation
        };
    }

    checkSize(props) {
        const element = this.newsItemRef;
        if (element) {
            let newHeight;
            if (this.state.orientation === 'block') {
                newHeight = getInnerWidth(element) - gapSize / 2;
            } else if (this.state.orientation === 'featured') {
                newHeight = getInnerWidth(element) - gapSize;
            } else if (this.state.orientation === 'horizontal') {
                newHeight = getInnerWidth(element) / 2 - gapSize;
            } else if (this.state.orientation === 'small-block') {
                newHeight = getInnerWidth(element) / 2 - gapSize;
            } else if (this.state.orientation === 'vertical') {
                if (props.rowWidth) {
                    newHeight = props.rowWidth * 0.6666 - gapSize * 2;
                }
            }
            if (newHeight && newHeight !== this.state.height) {
                this.setState({height: newHeight, heightAdjusted: true});
            }
        }
    }

    onClick = (e) => {
        //check if click on anchor within the text
        if (e.target && e.target.tagName === 'A' && e.target.href) {
            e.preventDefault();
            e.nativeEvent.preventDefault();
            root.open(e.target.href, '_blank');
            return;
        }

        const ctrlKey = e.ctrlKey || false;
        const {href} = this.getHref();

        const highlight = this.props.highlight || {};
        if (href) {
            if (highlight.targetBlank || ctrlKey) {
                root.open(href, '_blank');
            } else {
                root.location.href = href;
            }
        }
        e.preventDefault();
        e.nativeEvent.preventDefault();
    };

    getHref() {
        const highlight = this.props.highlight || {};
        const {targetURL, linkToSelf, urlPattern} = highlight;
        const _type = highlight.type || highlight._type; //eslint-disable-line

        let href = targetURL;
        let routeOk = false;
        if (
            linkToSelf ||
            (urlPattern &&
                _type &&
                (_type === 'Enterprise Event Header' || _type === 'Common Post'))
        ) {
            href = urlPattern;
            routeOk = true;
        }

        //remove the locale from the path, we will use generateRoutePath() which will add it in if needed
        const locale = getLocale();
        if (href && href.indexOf(`/${locale}`) === 0) {
            href = href.replace(`/${locale}`, '');
        }

        return {href, routeOk};
    }

    getInnerRef = () => this.gridInnerRef;

    render() {
        const {rowWidth} = this.props;
        const locale = getLocale();
        const highlight = this.props.highlight || {};
        const textFitText = this.props.textFitText || {};
        const titleFitText = this.props.titleFitText || {};
        const {image, highlightImage, alt, title, date, dateEnd, short, location, targetBlank} =
            highlight;
        let tags = highlight.tags || [];
        if (typeof tags === 'string') {
            tags = [];
        }
        const {orientation} = this.state;
        const adjustBreakpoints = this.props.adjustBreakpoints || false;

        const img = highlightImage || image;
        const {href} = this.getHref();
        const {mobile} = getBowserState();
        let widthPercent;
        const newsItemStyles = {};
        let truncateLength = 80;
        let mode = 'card-lg';
        if (adjustBreakpoints && rowWidth <= 1150 && rowWidth > 730) {
            mode = 'card-md';
            truncateLength = 35;
        } else if (rowWidth <= 950 && rowWidth > 730) {
            mode = 'card-md';
            truncateLength = 40;
        } else if (rowWidth >= 375 && rowWidth <= 730) {
            mode = 'card-sm';
            truncateLength = 80;
        } else if (rowWidth < 375) {
            mode = 'card-xs';
            truncateLength = 80;
        }

        if (orientation === 'horizontal') {
            newsItemStyles.height = this.state.height;
            widthPercent = 66;
        } else if (orientation === 'vertical' || orientation === 'small-block') {
            widthPercent = 33;
            if (this.state.height > 0) newsItemStyles.height = this.state.height;
        } else if (orientation === 'featured') {
            truncateLength = 30;
            newsItemStyles.height = this.state.height;
            widthPercent = 66;
        } else if (orientation === 'block') {
            widthPercent = 50;
            if (this.state.height > 0) newsItemStyles.height = this.state.height;
        } else {
            console.warn('unknown orientation type used!');
        }

        let hover = '';
        if (href) {
            hover = 'add-hover';
        }

        const smallerText = mobile || locale !== 'en-US';

        return (
            <div
                className={`grid-news-cell ${
                    widthPercent ? `w${widthPercent} ${mode}` : ''
                } ${orientation}`}
                data-title={title}>
                <div
                    ref={(c) => {
                        this.newsItemRef = c;
                    }}
                    className={`grid-news-item ${hover} ${orientation}`}
                    style={newsItemStyles}
                    target={targetBlank ? '_blank' : '_self'}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={this.onClick}
                        title={title}
                        className={`grid-news-image ${
                            orientation === 'block' || orientation === 'small-block' ? 'hidden' : ''
                        }`}>
                        <div
                            className={`img-responsive ${!img ? 'ue-placeholder' : ''}`}
                            style={{
                                backgroundImage: `url(${getOptImage({
                                    url: img || placeholder,
                                    width: 700
                                })})`
                            }}
                            alt={alt}
                        />
                    </div>
                    <div
                        className={`grid-news-inner ${
                            orientation === 'block' ? 'block-inner' : ''
                        }`}
                        ref={(c) => {
                            this.gridInnerRef = c;
                        }}>
                        <div
                            className={`grid-news-category ${
                                !tags || tags.length === 0 ? 'hidden' : ''
                            }`}>
                            {tags
                                ? tags.map((tag, index) => {
                                      const text = tag.text;
                                      if (text) {
                                          return <span key={text}>{text}</span>;
                                      }
                                      return null;
                                  })
                                : null}
                        </div>
                        <a
                            role="button"
                            tabIndex={0}
                            onClick={this.onClick}
                            href={href}
                            title={title}
                            className={`grid-news-title ${!img ? 'fallback-title' : ''}`}>
                            <FitText
                                compressor={titleFitText.compressor || smallerText ? 2.4 : 1.8}
                                minFontSize={titleFitText.minFontSize || smallerText ? 12 : 14}
                                maxFontSize={titleFitText.maxFontSize || 20}
                                getElement={this.getInnerRef}>
                                <h2>{title}</h2>
                            </FitText>
                        </a>
                        {date ? (
                            <div className="grid-news-date">{`${startAndEndTimeFormatter(
                                locale,
                                date,
                                dateEnd
                            )}${location !== undefined ? `, ${location}` : ''}`}</div>
                        ) : (
                            ''
                        )}
                        {!date && location ? (
                            <div className="grid-news-date">{`${location}`}</div>
                        ) : (
                            ''
                        )}
                        <div className="grid-news-text">
                            <FitText
                                compressor={textFitText.compressor || smallerText ? 3 : 2.3}
                                minFontSize={textFitText.minFontSize || smallerText ? 11.5 : 12.2}
                                maxFontSize={textFitText.maxFontSize || 14}
                                getElement={this.getInnerRef}>
                                <RichTextSpan
                                    className="typography-rich-text"
                                    richText={
                                        mobile ? short : truncateByWords(short, truncateLength)
                                    }
                                />
                            </FitText>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Card;
