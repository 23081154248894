import {AxiosContainer} from '@epic-node-services/public-axios';
import {checkConfig} from '@epic-node-services/common';
import {registerPublicService} from '@epic-node-services/public-service-configs';
import root from 'window-or-global';

const axios = AxiosContainer.getAxiosInstance();

export interface EpicGraphQlRequest {
    query: string;
    variables?: any;
}

export interface EpicGraphQlResponse {
    data: any;
    errors: any;
}

class GraphQlServiceDef {
    private endpoint: string = '';
    private withCredentials = null;

    public configure(envConfig) {
        checkConfig('GraphQlService', envConfig, 'EPIC_SERVICE_GRAPHQL_URL');
        this.endpoint = envConfig.EPIC_SERVICE_GRAPHQL_URL;
        this.withCredentials =
            envConfig.EPIC_SERVICE_GRAPHQL_WITHCREDENTIALS !== undefined
                ? envConfig.EPIC_SERVICE_GRAPHQL_WITHCREDENTIALS
                : true;
    }

    /**
     * Query GraphQL for data from the provided query schema. Allows support for EpicSSO with the
     * withCredentials flag.
     *
     * @param querySchema the query to run against GraphQL
     * @param variables
     * @returns {Promise.<TResult>}
     */
    public query(querySchema: string, variables: any = {}, options = {}): Promise<EpicGraphQlResponse> {
        return axios
            .post(
                this.endpoint,
                {
                    query: querySchema,
                    variables
                },
                {
                    ...options,
                    //@ts-ignore
                    includeOAuthAccountToken: true,
                    withCredentials: Boolean(this.withCredentials)
                }
            )
            .then(res => res && res.data)
            .catch(error => {
                console.error('Failed to query Graphql %s', error.message);
                console.error(error);
                throw error;
            });
    }

    public batchQuery(requests: EpicGraphQlRequest[] = [], options = {}): Promise<EpicGraphQlResponse[]> {
        return axios
            .post(this.endpoint, requests, {
                ...options,
                //@ts-ignore
                includeOAuthAccountToken: true,
                withCredentials: Boolean(this.withCredentials)
            })
            .then(res => res && res.data)
            .catch(error => {
                console.error('Failed to query Graphql %s', error.message);
                console.error(error);
                throw error;
            });
    }
}

// Enforce singleton even if multiple versions of this module are loaded
if (!root.__GraphQlService) {
    root.__GraphQlService = new GraphQlServiceDef();
    registerPublicService(root.__GraphQlService);
}
export const GraphQlService: GraphQlServiceDef = root.__GraphQlService;
