import React from 'react';
import pt from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import {getBowserState} from '@epic-core/common';
import SectionBackground from './SectionBackground';
import SectionFullWidthTwoCol from './SectionFullWidthTwoCol';
import SectionTwoCol from './SectionTwoCol';
import SectionStacked from './SectionStacked';
import SectionImage from './SectionImage';
import styled from 'styled-components';
import {CopySection} from 'epic-ue-components';

const AnimationWrapper = styled.div`
    &:not(.inView) {
        .wrapper {
            h2,
            h3,
            .image {
                opacity: 0;
                transform: translateX(-100px);
            }
            .copy,
            .btn-wrapper,
            .button-wrapper {
                opacity: 0;
                transform: translateY(50px);
            }
        }
    }
    .inView {
        .wrapper {
            h2,
            h3,
            .image {
                opacity: 1;
                transform: translateX(0);
                transition: opacity ease 0.5s 0.5s, transform ease 0.5s 0.5s;
            }
            .copy {
                opacity: 1;
                transform: translateY(0);
                transition: opacity ease 0.4s 0.4s, transform ease 0.3s 0.3s;
            }
            .btn-wrapper,
            .button-wrapper {
                transition-delay: 0.5s;
            }
        }
    }
`;

const SectionWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: ${(p) =>
        p.bgColor !== undefined ? p.bgColor : p.theme.palette.background.default};

    div,
    p,
    h1,
    h2,
    h3,
    h4,
    span {
        color: ${(p) => (p.textColor !== undefined ? p.textColor : p.theme.palette.text.primary)};
    }

    h2 {
        font-family: Brutal_Light;
        font-size: 3em;
        text-transform: uppercase;
    }

    .button-wrapper a.btn.btn-secondary {
        background-color: transparent;
        color: ${(p) => p.theme.ue.colors.buttons.link.default};
        margin-left: 0;
    }

    .copy-section h2 {
        margin-top: 40px;
    }
`;

export default class Section extends React.PureComponent {
    static propTypes = {
        title: pt.string,
        copy: pt.string,
        even: pt.bool,
        opacity: pt.oneOfType([pt.number, pt.string]),
        bgColor: pt.string,
        textColor: pt.string,
        layout: pt.string,
        useOverlay: pt.bool,
        primaryBtn: pt.object,
        secondaryBtn: pt.object,
        media: pt.object,
        passClick: pt.func,
        credits: pt.string
    };

    state = {
        inView: false
    };

    onChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                inView: isVisible
            });
        } else if (!isVisible && this.state.inView) {
            this.setState({inView: isVisible});
        }
    };

    render() {
        const {
            title,
            copy,
            layout,
            opacity,
            bgColor,
            textColor,
            primaryBtn = {},
            secondaryBtn = {},
            media
        } = this.props;
        const bowserState = getBowserState();
        const inView = this.state.inView || false;
        const buttonData = {primaryBtn, secondaryBtn};
        const bgType = <SectionImage {...this.props} />;

        let bgOpacity = 1;
        if (opacity !== undefined && opacity) {
            bgOpacity = opacity;
        }

        let sectionElem = '';
        if (layout === 'background') {
            return (
                <SectionBackground
                    title={title}
                    opacity={bgOpacity}
                    copy={copy}
                    buttonData={buttonData}
                    media={media}
                />
            );
        } else if (layout === 'fullWidthTwoCol') {
            sectionElem = (
                <SectionFullWidthTwoCol
                    {...this.props}
                    {...bowserState}
                    bgType={bgType}
                    buttonData={buttonData}
                />
            );
        } else if (layout === 'stacked') {
            sectionElem = (
                <SectionStacked
                    {...this.props}
                    {...bowserState}
                    bgType={bgType}
                    buttonData={buttonData}
                />
            );
        } else if (layout === 'copy') {
            sectionElem = <CopySection {...this.props} />;
        } else {
            sectionElem = (
                <SectionTwoCol
                    {...this.props}
                    {...bowserState}
                    bgType={bgType}
                    buttonData={buttonData}
                />
            );
        }

        return (
            <SectionWrapper bgColor={bgColor} textColor={textColor}>
                <VisibilitySensor
                    onChange={this.onChange}
                    partialVisibility
                    scrollCheck={true}
                    intervalDelay={250}
                    scrollThrottle={100}>
                    <AnimationWrapper className={`${inView ? 'inView' : ''}`}>
                        {sectionElem}
                    </AnimationWrapper>
                </VisibilitySensor>
            </SectionWrapper>
        );
    }
}
