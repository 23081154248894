import React from 'react';
import {H2, Button} from '../../elements';
import {CaseStudyList} from './CaseStudyList';
import styled from 'styled-components';

export interface Showcase {
    title: string;
    btnLabel: string;
    btnUrl: string;
    showcases: Showcase[];
}

export interface CaseStudyProps {
    showcases: Showcase[];
    title?: string;
    url?: string;
    label?: string;
    children?: React.ReactNode;
}

const CaseStudiesWrapper = styled.div`
    .studyTitle > h2 {
        text-align: center;
        margin-bottom: 50px;
    }
    > div:first-child {
        margin: 0 0 50px;
    }

    .see-more-wrapper {
        text-align: center;
        .btn {
            margin: 40px auto 0;
        }
        #see-more-casestudies.btn {
            ${(p) => p.theme.breakpoints.down('md')} {
                margin: 2em auto 1em 0;
            }
        }
    }
`;

export const CaseStudies = ({showcases, title, label, url}: CaseStudyProps): JSX.Element | null => {
    const showcaseItems = showcases || [];

    if (showcaseItems.length === 0) {
        return null;
    }

    let seeMoreButton = <div />;
    if (showcaseItems && label && url) {
        seeMoreButton = (
            <div className="container">
                <div className="row see-more-wrapper">
                    <Button
                        as="a"
                        target={'_blank'}
                        id={`see-more-casestudies`}
                        href={url}
                        type="secondary">
                        {label}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <CaseStudiesWrapper className="caseStudies">
            <div className="border-top">
                <span className="hr" />
                {title ? (
                    <div className="container">
                        <div className="row studyTitle">
                            <H2>{title}</H2>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                <CaseStudyList showcases={showcases} />
                {seeMoreButton}
            </div>
        </CaseStudiesWrapper>
    );
};
