import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    FilterTabs,
    GridContainer,
    SearchField,
    Typography,
    VerticalSpacer,
    NoSearchResultsBlock,
    NoSearchResultsBlockProps
} from 'epic-ue-ui';
import {EpicSearchFilter} from 'epic-ue-ui';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

const Heading = styled.div`
    text-align: center;
`;

interface SearchHeaderProps {
    enableCustomFilter?: boolean;
    routeNavigate?: any;
    searchFilters?: EpicSearchFilter[];
    queryUrl: {filter?: string; keyword?: string};
    total?: number;
    pageTitle?: string;
    description?: string;
    resultsLabel?: string;
    searchPlaceholder?: string;
    noResultsBlock?: NoSearchResultsBlockProps;
    isLoading?: boolean;
}

export const SearchHeader = ({
    enableCustomFilter,
    routeNavigate,
    searchFilters,
    queryUrl,
    total,
    pageTitle = 'Search',
    resultsLabel = 'results',
    description,
    searchPlaceholder,
    noResultsBlock,
    isLoading
}: SearchHeaderProps): JSX.Element => {
    const [searchKeyword, setSearchKeyword] = useState(`${queryUrl?.keyword || ''}`);

    useEffect(() => {
        setSearchKeyword(queryUrl?.keyword || '');
    }, [queryUrl?.keyword]);

    const [filterName, setFilterName] = useState(queryUrl?.filter?.toString() || 'all');

    const updateFilterRoute = (e, filterName): void => {
        e.preventDefault();

        routeNavigate(queryUrl, searchKeyword, filterName);

        setFilterName(filterName);
    };

    const clearSearch = useCallback(() => {
        routeNavigate(queryUrl, '', filterName);
        setSearchKeyword('');
    }, [routeNavigate, setSearchKeyword]);

    const newSearchResults = useCallback(
        (e, keyword) => {
            let keywordUpdate = keyword;
            if (e.key === 'Escape') {
                keywordUpdate = '';
            }
            if (e.key === 'Enter' || e.key === 'Escape') {
                if (queryUrl) {
                    queryUrl.filter = filterName;
                    queryUrl.keyword = keywordUpdate;
                }
                routeNavigate(queryUrl, keywordUpdate, filterName);

                emitEvent({
                    eventAction: 'client.search',
                    eventLabel: 'search',
                    eventValue: keywordUpdate
                });
                setSearchKeyword(keywordUpdate || '');
            }
        },
        [routeNavigate, setSearchKeyword, filterName]
    );

    return (
        <>
            <GridContainer>
                <VerticalSpacer variant="section" />
                <Heading>
                    <Typography variant="h1" petit={true}>
                        {pageTitle}
                    </Typography>
                    <VerticalSpacer variant="eyebrow" />
                    <Typography variant="h3">{description}</Typography>
                    <VerticalSpacer variant="heading" />
                </Heading>

                <VerticalSpacer variant="eyebrow" />
                <SearchField
                    accentColor="sky"
                    searchLabelText={searchPlaceholder}
                    searchKeyword={searchKeyword}
                    onKeyUp={(e, searchKeyword) => {
                        newSearchResults(e, searchKeyword);
                    }}
                    clearSearch={clearSearch}
                />
                <VerticalSpacer variant="eyebrow" />
                {enableCustomFilter && (
                    <FilterTabs
                        tabsList={searchFilters}
                        updateFilterRoute={updateFilterRoute}
                        selected={queryUrl?.filter?.toString()}
                    />
                )}
                {total ? (
                    <>
                        <VerticalSpacer variant="eyebrow" />
                        <span>
                            {total} {resultsLabel}
                        </span>
                    </>
                ) : (
                    ''
                )}
                <VerticalSpacer variant="heading" />

                {!isLoading && searchKeyword && total === 0 && (
                    <NoSearchResultsBlock searchKeyword={searchKeyword} {...noResultsBlock} />
                )}
            </GridContainer>
        </>
    );
};

export default SearchHeader;
