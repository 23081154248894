import React from 'react';
import {MapOverlay} from './UEMapOverlay.styles';

interface UEMapProps {
    children: any;
}

export const UEMapOverlay = ({children}: UEMapProps): JSX.Element => {
    return <MapOverlay>{children}</MapOverlay>;
};
