import React, {useCallback} from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useAccountStore,
    useCmsData,
    getUrlPattern
} from 'epic-ue-shared';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import ErrorPage from '../ErrorPage';
import {
    CmsDynamicSectionProps,
    DynamicSections,
    ImageHero,
    ImageHeroProps,
    TemplateThemeProps,
    VerticalSpacer,
    Button,
    GridContainer,
    Subnav,
    SubnavProps,
    NewsletterSection,
    NewsletterSectionProps
} from 'epic-ue-ui';
import {fieldAdjustments} from '../../utils/partnerUtils';
import root from 'window-or-global';
import {UnrealLoading} from 'epic-ue-loading';
import {SalesforceSimpleForm, UEFormProps} from 'epic-ue-saleforce-form';

const Styled = styled.div`
    .login-btn-wrap {
        text-align: center;
        margin-top: 16px;
    }

    ul li {
        text-align: left;
    }
`;

interface GeneralLandingPageSections {
    items?: CmsDynamicSectionProps[];
}

interface PartnerApplyPageData extends PageData {
    programName?: string;
    submissionDateField?: string;
    formType?: string;
    lockMsg?: string;
    lockDays?: number;
    loginMsg?: string;
    themeConfig: TemplateThemeProps;
    header?: ImageHeroProps;
    subnav: SubnavProps;
    sections?: GeneralLandingPageSections;
    form?: UEFormProps;
    newsletter?: NewsletterSectionProps;
    showFormWithoutLogin?: boolean;
}

const PartnerApplyPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<PartnerApplyPageData>(urlPattern);

    const {
        loginMsg,
        programName,
        formType,
        header = {} as ImageHeroProps,
        themeConfig = {} as TemplateThemeProps,
        subnav = {} as SubnavProps,
        sections = {} as GeneralLandingPageSections,
        form = {} as UEFormProps,
        newsletter = {} as NewsletterSectionProps,
        showFormWithoutLogin
    } = pageData;

    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const {account} = useAccountStore();

    const redirect = useCallback((e) => {
        e.preventDefault();
        root.location.href = `/login?state=${root.location.href}`;
    }, []);

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const initalValues = {
        Email: account?.email,
        Epic_Account_ID__c: account?.id,
        Program__c: programName
    };

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <Styled>
                <ImageHero {...header} accentColor={accentColor} />
                <Subnav {...subnav} accentColor={accentColor} />
                <DynamicSections theme={theme} items={items} accentColor={accentColor} />
                {showFormWithoutLogin || account.isLoggedIn ? (
                    <SalesforceSimpleForm
                        formType={formType}
                        form={form}
                        formId={(form && form.formId) || 'Application__c'}
                        initalValues={initalValues}
                        fieldAdjustments={fieldAdjustments}
                    />
                ) : (
                    <GridContainer>
                        <div className="login-btn-wrap">
                            <Button type="primary" onClick={redirect} href="/login">
                                {loginMsg}
                            </Button>
                        </div>
                    </GridContainer>
                )}

                <VerticalSpacer variant="section" />
                {Object.keys(newsletter).length > 0 && <NewsletterSection {...newsletter} />}
            </Styled>
        </UeThemeProvider>
    );
};

PartnerApplyPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default PartnerApplyPage;
