import axios, {AxiosResponse} from 'axios';
import root from 'window-or-global';

import {getOrigin} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';

const url = () => {
    const origin = getOrigin();

    return {
        HAS_METAHUMAN_ENTITLEMENT: () => `${origin}/api/entitlement/metahuman`
    };
};

class MetaHumanApi {
    public async getMetaHumanEntitlement(): Promise<any> {
        if (root.__server_side_render) {
            return Promise.resolve();
        }
        const api = url().HAS_METAHUMAN_ENTITLEMENT();
        try {
            const response: AxiosResponse = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getMetaHumanEntitlement() failed to load', isError(e) && e.message);
            throw e;
        }
    }
}

export const metaHumanApi = new MetaHumanApi();
