import styled from 'styled-components';

const Suffix = styled.div`
    margin: 40px 0;
    font-family: ${p => p.theme.ue.fonts.brutal.medium};
    font-size: 2em;
    ${p => p.theme.breakpoints.down('md')} {
        font-size: 1em;
    }
`;
const Header = styled.div<{opacity: number; height: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: ${p => (p.height ? p.height : '80vh')};
    min-height: 450px;
    * {
        backface-visibility: hidden;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        height: 450px;
    }
    background: #000;
    a.btn.btn-secondary {
        margin-left: 0;
    }
    .container {
        padding-bottom: 50px;
    }
    .gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        z-index: 0;
    }
    h1 {
        max-width: 800px;
        line-height: 1em;
        font-size: 5em;
        margin-top: 80px;
        ${p => p.theme.breakpoints.down('xxl')} {
            font-size: 4em;
        }
        ${p => p.theme.breakpoints.down('md')} {
            font-size: 3.5em;
        }
        ${p => p.theme.breakpoints.down('xs')} {
            font-size: 2.5em;
        }
        ${p => p.theme.breakpoints.up('sm')} {
            margin-left: -4px;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            font-size: 2em;
        }
    }
    .content-wrapper {
        height: ${p => (p.height ? p.height : '80vh')};
        min-height: 450px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            height: 450px;
        }
        ${p => p.theme.breakpoints.down('md')} {
            height: 100%;
        }
        position: relative;
        overflow: hidden;
        width: 100%;
        left: 0;
        text-align: left;
        max-width: 93vw;
        * {
            color: #fff;
        }
    }
    .bg-wrapper {
        opacity: ${p => (p.opacity ? p.opacity : 1)};
        & > div {
            opacity: ${p => (p.opacity ? p.opacity : 1)};
        }
        .screen {
            z-index: 1 !important;
        }
        .overlay {
            display: none;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            && {
                .screen {
                    margin-top: 0;
                }
            }
        }
    }

    /* Animations */
    > div:not(.inView) {
        h1,
        h6,
        .btn-wrapper,
        ${Suffix} {
            opacity: 0;
            backface-visibility: hidden;
            outline: 1px solid transparent;
        }
        h6,
        h1 {
            transform: translateX(-100px);
        }
        ${Suffix},
        .btn-wrapper {
            transform: translateY(50px);
        }
    }
    > .inView {
        h1,
        h6,
        .btn-wrapper,
        ${Suffix} {
            opacity: 1;
            backface-visibility: hidden;
            outline: 1px solid transparent;
        }
        h1,
        h6 {
            transform: translateX(0);
            transition: opacity ease 0.5s 0.5s, transform ease 0.5s 0.5s;
        }
        h6 {
            transition: opacity ease 0.3s 0.3s, transform ease 0.3s 0.3s;
        }
        .btn-wrapper,
        ${Suffix} {
            transform: translateY(0);
            transition: opacity ease 0.6s 0.6s, transform ease 0.6s 0.6s;
        }
        ${Suffix} {
            transition: opacity ease 0.3s 0.3s, transform ease 0.3s 0.3s;
        }
    }
`;

const HeaderContent = styled.div`
    position: relative;
    overflow: hidden;
    z-index: 2;
    * {
        color: #fff;
    }
`;

const Courtesy = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.7);
    pointer-events: none;
    ${p => p.theme.breakpoints.down('sm')} {
        display: none;
    }
`;

export {Header, HeaderContent, Courtesy, Suffix};
