import axios, {AxiosResponse} from 'axios';
import {getOrigin} from 'epic-ue-shared';

export interface CleanedBlobRes {
    name: string;
    createdAt: string;
    url: string;
    size: number;
}

interface ControllerRes {
    error?: string;
    blobs?: CleanedBlobRes[];
}

const url = () => {
    const origin = getOrigin();

    const url = {
        LINUX: `${origin}/api/blobs/linux`
    };

    return url;
};

class BlobsApi {
    public async getLinuxBlobs(requestStr?: string): Promise<ControllerRes> {
        const params = JSON.parse(requestStr || '{}');
        const api = url().LINUX;
        try {
            const response: AxiosResponse<ControllerRes> = await axios.get(api, {
                params
            });
            return response.data;
        } catch (e) {
            console.error('getLinuxBlobs() failed to load linux blobs');
            throw e;
        }
    }
}

export const blobsApi = new BlobsApi();
