import { Feed, FeedProps, GridContainer } from 'epic-ue-ui';
import { FeedItemProps, getImageUrl, getShort, getTitle, useFeedItems } from 'epic-ue-feed';
import React from 'react';

export interface FeedLoadingProps {
    cmsLoading: boolean;
}

export interface FeedWrapProps {
    feedTag?: string;
    contentType?: string;
    urlPattern?: string;
}

const FeedWrap = ({
    cmsLoading,
    showCarousel,
    button,
    feedTag,
    contentType,
    urlPattern = ''
}: FeedProps & FeedWrapProps & FeedLoadingProps): JSX.Element => {
    const tags = {};
    const types = {};

    if (feedTag) {
        const parts = feedTag.split(',');
        if (parts && parts.length) {
            parts.forEach((part) => {
                tags[part] = true;
            });
        } else {
            tags[feedTag] = true;
        }
    }

    if (contentType) {
        types[contentType] = true;
    }

    const { items: feedItems = [] as FeedItemProps[], loading: loading } = useFeedItems(
        { tags, types, patternsToSkip: [urlPattern], numItems: 12 },
        cmsLoading
    );
    const topFeedItems = feedItems.slice(0, 12);

    const topFeedImages = topFeedItems.map((feedItem) => {
        return {
            image: {
                src: getImageUrl(feedItem),
                alt: feedItem._slug
            },
            title: getTitle(feedItem),
            caption: { text: '' },
            eyebrow: { text: feedItem._feedTags[0] },
            description: getShort(feedItem),
            link: {
                href: feedItem._urlPattern
            }
        };
    });

    return (
        <>
            <GridContainer>
                {!loading && topFeedImages?.length > 0 && (
                    <Feed showCarousel={showCarousel} cards={topFeedImages} button={button} />
                )}
            </GridContainer>
        </>
    );
};

export default FeedWrap;
