import styled from 'styled-components';

export const LogoWrapper = styled.ul`
    list-style: none;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    ul {
        margin: 0 auto;
        text-align: center;
        padding: 0;
        li {
        }
    }
`;

export const LogoItem = styled.li<{paddingTopBottom?: string; paddingLeftRight?: string}>`
    display: inline-block;
    height: 85px;
    opacity: 1;
    margin: 6px 0 20px;
    ${p => p.theme.breakpoints.down('sm')} {
        margin-bottom: 0;
    }
    padding: 0 5px;

    img {
        height: 100%;
        padding-top: ${p => (p.paddingTopBottom ? `${p.paddingTopBottom}px` : '14px')};
        padding-bottom: ${p => (p.paddingTopBottom ? `${p.paddingTopBottom}px` : '14px')};
        padding-left: ${p => (p.paddingLeftRight ? `${p.paddingLeftRight}px` : '25px')};
        padding-right: ${p => (p.paddingLeftRight ? `${p.paddingLeftRight}px` : '25px')};
    }
    &.square img {
        padding: 5px 30px;
        ${p => p.theme.breakpoints.down('sm')} {
            padding: 2px 20px;
        }
    }
`;
