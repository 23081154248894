import styled from 'styled-components';

export const BgContainer = styled.div<{offset?: number; opacity?: number; imgHeight?: string}>`
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    &.type-youtubeVideo {
        background: rgb(0, 0, 0);
    }
    .screen {
        top: -${(p) => p.offset}px!important;
    }
    .background-video .screen {
        opacity: ${(p) => (p.opacity ? p.opacity : 0.7)};
    }
    .imageBg {
        height: 100%;
        width: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgb(0, 0, 0, 0);
        opacity: ${(p) => (p.opacity ? p.opacity : 0.7)};
        background-size: cover;
        ${(p) => (p.imgHeight ? `background-size: auto ${p.imgHeight};` : '')}
        background-position: center center;
    }
`;

export const BgSolid = styled.div<{hexValue?: string}>`
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(p) => (p.hexValue ? p.hexValue : p.theme.palette.background.default)};
`;

export const ScreenWrap = styled.div`
    z-index: 0;
`;

export const ChildrenWrap = styled.div`
    z-index: 1;
`;

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    > div {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 1;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        position: relative;
        overflow: hidden;
    }
`;

export const Caption = styled.div`
    font-size: 14px;
    font-style: italic;
    color: white;
    opacity: 0.9;
    position: absolute;
    bottom: 0;
    transform: translateY(150%);
    line-height: 20px;
    text-align: center;
    pointer-events: none;
    width: 100%;

    ${(p) => p.theme.breakpoints.down('sm')} {
        font-size: 13px;
        position: relative;
        overflow: hidden;
        transform: none;
        margin: 20px 0;
        line-height: auto;
    }
`;
