import React from 'react';

import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import IndustryPage from './IndustryPage';

const cmsUrlPattern = '/digital-twins';

interface Properties {}

const DigitalTwinsPage = (props: Properties): JSX.Element => {
    return <IndustryPage {...props} cmsUrlPattern={cmsUrlPattern} />;
};

DigitalTwinsPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default DigitalTwinsPage;
