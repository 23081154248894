import React from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData,
    getUrlPattern,
} from 'epic-ue-shared';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {
    CmsDynamicSectionProps,
    DynamicSections,
    ImageHero,
    ImageHeroProps,
    Subnav,
    SubnavProps,
    TemplateThemeProps,
    VerticalSpacer,
} from 'epic-ue-ui';
import {getPreStardustPageNode} from '../../utils/stardustPageUtils';

const Styled = styled.div`
    overflow-x: clip;

    ul li {
        text-align: left;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        .ue-image-and-text-card .paragraph {
            margin-bottom: 20px;
        }
    }

    .login-btn-wrap {
        text-align: center;
        margin-top: 16px;
    }
`;

interface GeneralLandingPageSections {
    items?: CmsDynamicSectionProps[];
}

interface MegagrantsStardustPageData extends PageData {
    programName?: string;
    themeConfig: TemplateThemeProps;
    header?: ImageHeroProps;
    subnav?: SubnavProps;
    sections?: GeneralLandingPageSections;
    loginMsg?: string;
}

const MegagrantsStardustPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} =
        useCmsData<MegagrantsStardustPageData>(urlPattern);

    const {
        header = {} as ImageHeroProps,
        themeConfig = {} as TemplateThemeProps,
        subnav = {} as SubnavProps,
        sections = {} as GeneralLandingPageSections
    } = pageData;

    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const node = getPreStardustPageNode({
        theme,
        cmsLoading,
        cmsLoadedEmpty,
        pageData
    });

    if (node) {
        return node;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <Styled>
                <ImageHero {...header} accentColor={accentColor} />
                <Subnav {...subnav} accentColor={accentColor} />

                <DynamicSections theme={theme} items={items} accentColor={accentColor} />

                <VerticalSpacer variant="section" />
            </Styled>
        </UeThemeProvider>
    );
};

MegagrantsStardustPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default MegagrantsStardustPage;
