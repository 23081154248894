import React from 'react';
import {getTitle, getTagMatch, getSlugPath} from '../../../utils';
import VisibilitySensor from 'react-visibility-sensor';
import {FeedItemProps} from '../../../types';
import Hidden from '@material-ui/core/Hidden';
import {SmallSpotlightCard} from './SmallSpotlightCard';
import {LargeSpotlightCard} from './LargeSpotlightCard';

import styled from 'styled-components';

export const SpotlightCardWrapper = styled.div``;

interface Properties {
    item: FeedItemProps;
}

export const SpotlightCard = (props: Properties): JSX.Element => {
    const item = props.item || {};

    const title = getTitle(item);
    const linkPath = getSlugPath(item);
    const matchTag = getTagMatch(item);

    const [inView, setInView] = React.useState(false);
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <SpotlightCardWrapper>
                <Hidden only={['md', 'lg', 'xl']} implementation="css">
                    <SmallSpotlightCard
                        item={item}
                        title={title}
                        tagMatch={matchTag}
                        linkPath={linkPath}
                    />
                </Hidden>
                <Hidden only={['xs', 'sm']} implementation="css">
                    <LargeSpotlightCard
                        item={item}
                        title={title}
                        tagMatch={matchTag}
                        linkPath={linkPath}
                    />
                </Hidden>
            </SpotlightCardWrapper>
        </VisibilitySensor>
    );
};
