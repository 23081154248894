import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {generateRoutePath, getLocale} from '@epic-core/common';
import {PageData} from '@epic-mw/types';
import {useReleaseNotes, releaseNotesApi, InitSharedValueResponse} from '../../shared';
import {UnrealLoading} from 'epic-ue-loading';
import {useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';
import {
    AppSharedKeys,
    cmsApi,
    getHelmetForMetaTags,
    getUrlPattern,
    useCmsData,
    RouteMatchOrUrlParse
} from 'epic-ue-shared';
import {extractSlugLocale} from 'epic-games-nav-shared';
import {UeThemeProvider, ueDarkTheme, ueLightTheme, useBreakpointDown} from 'epic-ue-theme';
import {TemplateThemeProps} from 'epic-ue-ui';
import {
    CmsDynamicSectionProps,
    DynamicSections,
    ImageHero,
    ImageHeroProps,
    MultiCard,
    MultiCardProps
} from 'epic-ue-ui';
import {
    CenteredTextBlock,
    CenteredTextBlockProps,
    DownloadVideoAndNewsletterSplit,
    DownloadVideoAndNewsletterSplitProps,
    SelectDropdownWithContainer,
    SubnavSection,
    VerticalSpacer
} from 'epic-ue-ui';
import {Spotlight} from 'epic-ue-ui';
import ErrorPage from '../ErrorPage';

export const releaseNotesBaseCmsPattern = '/updates';

export interface ReleaseNotesPageDynamicSections {
    items?: CmsDynamicSectionProps[];
}
export interface ReleaseNotesPageBottomSection {
    centeredText?: CenteredTextBlockProps;
    multicard?: MultiCardProps;
    downloadVideoNewsletter: DownloadVideoAndNewsletterSplitProps;
}
export interface ReleaseNotesPageData extends PageData {
    themeConfig?: TemplateThemeProps;
    releaseDropdownOrder: number;
    releaseLabel: string;
    header?: ImageHeroProps;
    sections?: ReleaseNotesPageDynamicSections;
    bottomSection?: ReleaseNotesPageBottomSection;
}

const Styled = styled.div`
    background-color: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};
    overflow: hidden;
    .ue-image-hero {
        min-height: unset;
        background-color: unset;
        .hero-copy {
            padding-top: ${(p) => p.theme.spacing(16)}px;
        }
    }
`;

const ReleaseNotesStardustPage = (): JSX.Element => {
    const [releaseId, setReleaseId] = useState(null);
    const [latestReleaseCmsPattern, setLatestReleaseCmsPattern] = useState('');
    const locale = getLocale();
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const navigate = useNavigate();
    const params = useParams();
    const slug = params.slug;
    const urlPattern = getUrlPattern(match);
    const isBasePattern = urlPattern === releaseNotesBaseCmsPattern;

    const {
        releaseNotes: {releaseVersions},
        loading: notesLoading
    } = useReleaseNotes(locale);

    const determineRelease = useCallback((): void => {
        if (releaseVersions && releaseVersions.length) {
            for (let i = 0; i < releaseVersions.length; i++) {
                const item = releaseVersions[i];
                const {pageSlug} = extractSlugLocale(item._urlPattern as string, locale);
                const {pageSlug: pathnameSlug} = extractSlugLocale(pathname as string, locale);
                if (pageSlug === pathnameSlug) {
                    setReleaseId(item.releaseDropdownOrder);
                    break;
                }
            }
            if (!slug) {
                setReleaseId(releaseVersions[0].releaseDropdownOrder);
                setLatestReleaseCmsPattern(releaseVersions[0]._urlPattern);
            }
        }
    }, [pathname, releaseId, setReleaseId, releaseVersions, setLatestReleaseCmsPattern]);

    useEffect(() => {
        determineRelease();
    });
    if (!releaseId) determineRelease();

    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<ReleaseNotesPageData>(isBasePattern ? latestReleaseCmsPattern : urlPattern);
    const {
        themeConfig = {} as TemplateThemeProps,
        header = {} as ImageHeroProps,
        sections = {} as ReleaseNotesPageDynamicSections,
        bottomSection = {} as ReleaseNotesPageBottomSection
    } = pageData as ReleaseNotesPageData;
    const {accentColor = 'forest', theme = 'dark'} = themeConfig;
    const {items = []} = sections;
    const {centeredText, multicard, downloadVideoNewsletter} = bottomSection;
    const isSm = useBreakpointDown('sm');

    const goToRelease = (id: number | null): void => {
        releaseVersions.forEach((item) => {
            if (item.releaseDropdownOrder === id && item._urlPattern) {
                const path = generateRoutePath(item._urlPattern);
                navigate(path);
                setReleaseId(item.releaseDropdownOrder);
            }
        });
    };

    const onSelect = useCallback(
        (id: number) => {
            goToRelease(id);
        },
        [releaseId, setReleaseId, navigate, goToRelease]
    );

    if (cmsLoading || notesLoading) {
        return (
            <UeThemeProvider theme={theme === 'dark' ? ueDarkTheme : ueLightTheme}>
                <Styled>
                    <UnrealLoading fillViewHeight={false} />
                </Styled>
            </UeThemeProvider>
        );
    }

    if ((cmsLoadedEmpty && urlPattern !== releaseNotesBaseCmsPattern) || !releaseVersions?.length) {
        return <ErrorPage status={404} />;
    }

    const dropdownItems = releaseVersions?.map(({releaseDropdownOrder, releaseLabel}) => ({
        id: releaseDropdownOrder,
        label: releaseLabel
    }));
    const singleReleaseNote = dropdownItems?.length < 2;

    return (
        <>
            {getHelmetForMetaTags(pageData)}
            <UeThemeProvider theme={theme === 'dark' ? ueDarkTheme : ueLightTheme}>
                <Styled>
                    <Spotlight
                        customColors={['#2AE5B3', '#00705C']}
                        height={1176}
                        width={1176}
                        offsetRatio={[0.45, -0.5]}
                        disable={!!header?.image || isSm}>
                        <ImageHero
                            {...header}
                            disableSpotlight
                            accentColor={accentColor}
                            showScrollIndicator
                        />
                    </Spotlight>
                    <VerticalSpacer
                        variant="custom"
                        spacing={singleReleaseNote ? 20 : 14}
                        downSmSpacing={7}
                    />
                    <SelectDropdownWithContainer
                        headerAnim
                        hidden={singleReleaseNote}
                        itemSelected={releaseId}
                        items={dropdownItems}
                        onChange={onSelect}
                        accentColor={accentColor}
                    />
                    <DynamicSections theme={theme} items={items} accentColor={accentColor} />
                    <VerticalSpacer variant="section" />
                    <SubnavSection
                        sectionConfig={{theme: 'light'}}
                        hideBottomSectionPadding
                        hideSectionName={false}>
                        {centeredText && (
                            <>
                                <CenteredTextBlock {...centeredText} accentColor={accentColor} />
                                <VerticalSpacer variant="section" />
                            </>
                        )}
                        {multicard?.cards?.length && (
                            <>
                                <MultiCard {...multicard} accentColor={accentColor} />
                                <VerticalSpacer variant="section" />
                            </>
                        )}
                        {downloadVideoNewsletter && (
                            <DownloadVideoAndNewsletterSplit
                                downloadSectionVariant="gradient"
                                footerColor="#202337"
                                {...downloadVideoNewsletter}
                                accentColor={accentColor}
                            />
                        )}
                    </SubnavSection>
                </Styled>
            </UeThemeProvider>
        </>
    );
};

ReleaseNotesStardustPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const urlPattern = getUrlPattern(match);
    const {pageSlug, pageLocale} = extractSlugLocale(urlPattern as string);
    const isBasePattern = pageSlug === releaseNotesBaseCmsPattern;

    const releaseData = await releaseNotesApi.getReleaseData(pageLocale);
    const latestReleaseCmsPattern =
        releaseData?.releaseVersions[0]?._urlPattern || releaseNotesBaseCmsPattern;

    const pageData = await cmsApi.getPageData(isBasePattern ? latestReleaseCmsPattern : urlPattern);
    return [
        {
            key: AppSharedKeys.ReleaseStore,
            value: {
                ...releaseData
            }
        },
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [isBasePattern ? latestReleaseCmsPattern : urlPattern]: pageData || {}
            }
        }
    ];
};

export default ReleaseNotesStardustPage;
