import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, ButtonWrapper} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import Datasmith from '../components/datasmith/Datasmith';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';
import {getBowserState} from '@epic-core/common';
import styled from 'styled-components';
import {ButtonProps} from 'epic-ue-shared';

const cmsUrlPattern = '/datasmith';

const DSWrapper = styled.div`
    .h2,
    p,
    .container1 {
        color: #f5f5f5;
    }

    h3 {
        font-size: 1.3em;
        margin: 5px 0 5px 0;
    }

    .button-container {
        width: 100%;
        text-align: center;
        margin: -20px 0 100px;
    }
`;

interface RibbonItem {
    label: string;
    title: string;
    extensions: string;
    description: string;
}

interface DatasmithProps extends PageData {
    title: string;
    subTitle: string;
    visual: string;
    quote: string;
    quoteAuthor: string;
    items: RibbonItem[];
}

interface Buttons {
    buttons: ButtonProps[];
}

interface DatasmithPageData extends PageData {
    ds: DatasmithProps;
    buttonslist?: Buttons;
}

const DatasmithPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<DatasmithPageData>(cmsUrlPattern);
    const {mobile, tablet} = getBowserState();

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const datasmithData = pageData.ds || {};
    const buttonList = pageData.buttonslist || ({} as Buttons);
    const buttons = buttonList.buttons || {};

    return (
        <Page>
            <DSWrapper>
                {getHelmetForMetaTags(pageData)}
                <Datasmith datasmithData={datasmithData} mobile={mobile} tablet={tablet} />
                <div className="button-container">
                    <ButtonWrapper buttons={buttons} />
                </div>
            </DSWrapper>
        </Page>
    );
};

DatasmithPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default DatasmithPage;
