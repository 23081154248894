import root from 'window-or-global';
import RecentPostsDefault from './RecentPostsDefault';
import BreakPoints from './BreakPoints';

const {MOBILE_WIDTH_MAX, TABLET_WIDTH_MIN} = BreakPoints;

/* Four column recent posts */
class FourColumn extends RecentPostsDefault {}

/* Three column recent posts */
class ThreeColumn extends RecentPostsDefault {
    setMaxRowSize(): void {
        if (!root.document) {
            return;
        }
        const calcWidth =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        switch (true) {
            case calcWidth > TABLET_WIDTH_MIN:
                this.setState({maxRowSize: 3});
                break;
            case calcWidth <= MOBILE_WIDTH_MAX:
                this.setState({maxRowSize: 1});
                break;
            default:
                break;
        }
    }
}

export default {
    FourColumn,
    ThreeColumn
};
