import React from 'react';
import PropTypes from 'prop-types';
import MosaicRowDelegator from './MosaicRowDelegator';
import MosaicRowDelegatorAccorion from '../../mosaicaccordion/enterprise/Mosaic/MosaicRowDelegator';
import Immutable from 'seamless-immutable';
import {MosaicWrapper} from './Mosaic.styles';
import {MosaicAccordionWrapper} from './MosaicAccordion.styles';

/*
 * This determins the TYPE of row that will be used
 * an Item with no photo hase weight 1
 * an Item with a photo has weight 2
 * a Featured Item has a weight 4
 */
const maxWeightPerRow = 6;

const getItems = (props) => {
    return Immutable(props.items).asMutable({deep: true});
};

const itemHasImage = (item) => {
    if (!item) return false;
    return item.image && item.image !== '';
};

const itemWeight = (item) => {
    if (!item) return 1;
    if (itemHasImage(item)) {
        return 2;
    }
    return 1;
};

/*
 * fabricateRow is recursively called to build out an array of rows. Each sub array contains all elements that have a combined weight of weight
 * under the max weightPerRow.  Presently only the first row is allowed a featured item.
 */
const fabricateRow = (maxRowWeight, originalItems, rows, firstIsFeatured, initialRun) => {
    const eligibleItems = [];
    let weightRemaining = maxRowWeight;
    let featuredItemAdded = false;
    const items = originalItems.slice(0);

    while (items.length > 0 && weightRemaining >= 1) {
        if (weightRemaining >= 2) {
            const candidateItem = items.shift();
            if (candidateItem) {
                if (initialRun && firstIsFeatured && !featuredItemAdded) {
                    candidateItem.weight = 4;
                } else if (initialRun && firstIsFeatured && featuredItemAdded) {
                    candidateItem.weight = 2;
                } else {
                    candidateItem.weight = itemWeight(candidateItem);
                }

                if (candidateItem.weight === 4) {
                    featuredItemAdded = true;
                }
                weightRemaining -= candidateItem.weight;
                eligibleItems.push(candidateItem);
            }
        } else {
            // (weightRemaining == 1)
            const candidateItem = items.shift();
            if (candidateItem) {
                candidateItem.weight = itemWeight(candidateItem);

                if (candidateItem.weight === 1) {
                    weightRemaining -= candidateItem.weight;
                    eligibleItems.push(candidateItem);
                } else {
                    items.unshift(candidateItem);
                    break;
                }
            }
        }
    }
    rows.push(eligibleItems);
    if (items.length > 0) {
        return fabricateRow(maxRowWeight, items, rows, false);
    }
    return {rows};
};

export default class Mosaic extends React.PureComponent {
    constructor(props) {
        super(props);

        const rows = fabricateRow(
            maxWeightPerRow,
            getItems(this.props),
            [],
            this.props.firstIsFeatured,
            true
        );

        this.state = {
            rows
        };
    }

    static propTypes = {
        firstIsFeatured: PropTypes.bool,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        forceLayout: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
        accordion: PropTypes.bool
    };

    static getDerivedStateFromProps(props, state) {
        return fabricateRow(maxWeightPerRow, getItems(props), [], props.firstIsFeatured, true);
    }

    render() {
        const rows = this.state.rows || [];
        const accordion = this.props.accordion || false;
        return accordion ? (
            <MosaicAccordionWrapper>
                <MosaicWrapper className="grid-news" id="grid">
                    {rows.length &&
                        rows.map((row, index) => {
                            if (row[0] && row[0].slug) {
                                return (
                                    <MosaicRowDelegatorAccorion
                                        key={row.length > 0 ? row[0].slug : 'empty'}
                                        firstIsFeatured={this.props.firstIsFeatured && index === 0}
                                        type={row.length}
                                        items={row}
                                        locale={this.props.locale}
                                        titleFitText={this.props.titleFitText}
                                        textFitText={this.props.textFitText}
                                        forceLayout={this.props.forceLayout}
                                    />
                                );
                            }
                            return null;
                        })}
                </MosaicWrapper>
            </MosaicAccordionWrapper>
        ) : (
            <MosaicWrapper className="grid-news" id="grid">
                {rows.length &&
                    rows.map((row, index) => {
                        if (row[0] && row[0].slug) {
                            return (
                                <MosaicRowDelegator
                                    key={row.length > 0 ? row[0].slug : 'empty'}
                                    firstIsFeatured={this.props.firstIsFeatured && index === 0}
                                    type={row.length}
                                    items={row}
                                    locale={this.props.locale}
                                    titleFitText={this.props.titleFitText}
                                    textFitText={this.props.textFitText}
                                    forceLayout={this.props.forceLayout}
                                />
                            );
                        }
                        return null;
                    })}
            </MosaicWrapper>
        );
    }
}
