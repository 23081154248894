import {UeTheme} from 'epic-ue-theme/dist/ueTheme.types';

interface StyleProps {
    theme: UeTheme;
}

export const ACCENT_COLOR = '#10b4f5';
export const ACCENT_COLOR_HOVER = `lighten(${ACCENT_COLOR}, 5%)`;
export const ACCENT_COLOR_DARKEN = `darken(${ACCENT_COLOR}, 5%)`;
export const BLOG_TEXT = '#000';
export const BACKGROUND_COLOR = '#fff';
export const BACKGROUND = (p: StyleProps): string | undefined =>
    p.theme.ue.colors.cards?.overlay?.background;
export const TEXT_COLOR = (p: StyleProps): string => p.theme.palette.text.primary;
export const LOC_ZH_CN_FONT =
    "'Lucida Grande', 'Lucida Sans Unicode', Tahoma, Helvetica, Arial, 'Microsoft Yahei', '微软雅黑', STXihei, '华文细黑', Verdana, sans-serif";
export const LOC_AR_FONT_REGULAR = 'Noto-Regular, sans-serif';
export const LOC_AR_FONT_BOLD = 'Noto-Bold, sans-serif';
