import React, {useState, useEffect, useCallback} from 'react';
import {getBowserState} from '@epic-core/common';
import Message from '../message/Message';
import {Plugin, PluginToggle} from './PluginDownload.styles';
import {Button, SimpleLink} from 'epic-ue-components';
import {TextFieldSelect, TextFieldSelectItem} from 'epic-ue-components';

interface Properties {
    download: any;
    n: number;
    archiveLabel?: string;
    latestLabel?: string;
    badgeLabel?: string;
    tpsNoticesLabel?: string;
    releaseNoteLabel?: string;
}

interface ActiveDownload {
    content?: string;
    tpsNotices?: string;
    tpsNoticesLabelOverride?: string;
    date?: string;
    releaseNoteUrl?: string;
    releaseNoteLabelOverride?: string;
    downloadLabel?: string;
    windowsDownloadURL?: string;
}

const getActiveDownload = (link, list): ActiveDownload | null => {
    if (!link) {
        return list[0];
    }
    let active = null;
    list.forEach((l) => {
        if (l && l.windowsDownloadURL && l.windowsDownloadURL === link) {
            active = l;
        }
    });
    return active;
};

export const PluginDownload = (props: Properties): JSX.Element => {
    const [activeVersion, setActiveVersion] = useState(undefined);
    const [showArchivedVersions, setShowArchivedVersions] = useState(false);
    const [hasArchived, sethasArchived] = useState(false);
    const getDownloadLink = useCallback(
        (e) => {
            setActiveVersion(e.target.value);
        },
        [setActiveVersion]
    );

    const {download = {}, n = 3} = props;
    const {engineVersions = []} = download;
    let downloadList = engineVersions.filter((x) => x.isRecentVersion);
    const archivedDownloadList = engineVersions.filter((x) => !x.isRecentVersion);
    const title = download.title || '';
    const archiveLabel = props.archiveLabel || 'View archived versions';
    const latestLabel = props.latestLabel || 'View latest version';
    const badgeLabel = props.badgeLabel || 'Archived versions';
    const releaseNotesLabel = props.releaseNoteLabel || 'Release Notes';
    const tpsNoticesLabel = props.tpsNoticesLabel || 'TPS Notices';
    const {mobile, tablet} = getBowserState();

    // ignore recent version if none is specified
    if (downloadList.length === 0) {
        downloadList = archivedDownloadList;
    }

    let activeDownload: ActiveDownload = {};
    if (showArchivedVersions) {
        activeDownload = getActiveDownload(activeVersion, archivedDownloadList) || {};
    } else if (!showArchivedVersions) {
        activeDownload = getActiveDownload(activeVersion, downloadList) || {};
    }

    const content = activeDownload.content || '';
    const TPSNotices = activeDownload.tpsNotices || '';
    const updateDate = activeDownload.date || '';
    const releaseNoteUrl = activeDownload.releaseNoteUrl || '';
    const downloadLabel = activeDownload.downloadLabel || 'Download Now';
    const activeValue = activeDownload.windowsDownloadURL || '';
    const releaseNoteLabelOverride = activeDownload.releaseNoteLabelOverride;
    const tpsNoticesLabelOverride = activeDownload.tpsNoticesLabelOverride;

    const flipCard = useCallback(() => {
        if (!showArchivedVersions && archivedDownloadList && archivedDownloadList[0]) {
            setActiveVersion(archivedDownloadList[0].windowsDownloadURL);
        }
        if (showArchivedVersions && downloadList && downloadList[0]) {
            setActiveVersion(downloadList[0].windowsDownloadURL);
        }
        setShowArchivedVersions(!showArchivedVersions);
    }, [
        setActiveVersion,
        activeVersion,
        setShowArchivedVersions,
        showArchivedVersions,
        archivedDownloadList,
        downloadList
    ]);

    let TPSLink;
    let releaseLink;
    let spacer;

    if (TPSNotices) {
        TPSLink = (
            <SimpleLink href={TPSNotices} title={tpsNoticesLabelOverride || tpsNoticesLabel} />
        );
    }
    if (releaseNoteUrl) {
        releaseLink = (
            <SimpleLink
                href={releaseNoteUrl}
                title={releaseNoteLabelOverride || releaseNotesLabel}
            />
        );
    }

    if (TPSNotices && releaseNoteUrl) {
        spacer = <div className="spacer">|</div>;
    }

    useEffect(() => {
        if (!activeVersion && downloadList && downloadList[0]) {
            setActiveVersion(downloadList[0].windowsDownloadURL);

            let hasArchived = false;
            const downloadLength = downloadList.length;
            const archivedDownloadListLength = archivedDownloadList.length;
            if (archivedDownloadListLength > 0) {
                hasArchived = true;
            } else if (downloadLength === 0) {
                hasArchived = false;
            }
            sethasArchived(hasArchived);
        }
    }, [activeVersion, setActiveVersion, downloadList, sethasArchived]);

    const gridClasses = React.useMemo(() => {
        if (!n || n === 1) return 'col-1';
        //e.g. 6 items on 2 rows of 3
        if (n === 3) {
            return `col-3`;
        }
        //dont have a block by itself on a row
        if (n >= 4) {
            return `col-4`;
        }
        return `col-3`;
    }, [n]);

    return (
        <Plugin className={`plugin plugin-${gridClasses} ${showArchivedVersions ? 'flip' : ''}`}>
            <div className="inner">
                <div className="front">
                    <h3>{title}</h3>
                    <div
                        className="text-center plugin-desc"
                        dangerouslySetInnerHTML={{__html: content || ''}}
                    />
                    <TextFieldSelect
                        className="plugin-select"
                        fullWidth
                        id={`${title}-plugin-selector`}
                        onBlur={getDownloadLink}
                        onChange={getDownloadLink}
                        value={activeValue}>
                        {downloadList.map((version) => {
                            const selectOption = version.label;
                            const selectOptionLink = version.windowsDownloadURL;
                            if (selectOption) {
                                return (
                                    <TextFieldSelectItem
                                        key={selectOption}
                                        value={selectOptionLink}>
                                        {selectOption}
                                    </TextFieldSelectItem>
                                );
                            }
                            return null;
                        })}
                    </TextFieldSelect>
                    <div className="btn-wrapper center">
                        {mobile || tablet ? (
                            <div className="mobile-warning">
                                <Message code="epic.launcher.mobile.subtext" />
                            </div>
                        ) : (
                            <Button as="a" href={activeVersion}>
                                {downloadLabel || <Message code="epic.ue.products.download" />}
                                <span> {!downloadLabel && downloadLabel}</span>
                            </Button>
                        )}
                    </div>
                    <div className="plugin-documents center">
                        {TPSLink}
                        {spacer}
                        {releaseLink}
                    </div>
                    <div className="plugin-update-date">
                        {updateDate ? <Message code="epic.ue.products.updated" /> : ''} {updateDate}
                    </div>
                    {hasArchived && (
                        <PluginToggle onClick={flipCard}>
                            <span>{archiveLabel}</span>
                        </PluginToggle>
                    )}
                </div>
                {hasArchived && (
                    <div className="back">
                        <div className="badge-promo">
                            <span className="badge-promo-content">{badgeLabel}</span>
                        </div>
                        <h3>{title}</h3>
                        <div
                            className="text-center plugin-desc"
                            dangerouslySetInnerHTML={{__html: content || ''}}
                        />
                        <TextFieldSelect
                            className="plugin-select"
                            fullWidth
                            id={`${title}-archived`}
                            onBlur={getDownloadLink}
                            onChange={getDownloadLink}
                            value={activeValue}>
                            {archivedDownloadList.map((archivedVersion) => {
                                const archivedSelectOption = archivedVersion.label;
                                const archivedSelectOptionLink = archivedVersion.windowsDownloadURL;
                                if (archivedSelectOption) {
                                    return (
                                        <TextFieldSelectItem
                                            key={archivedSelectOption}
                                            value={archivedSelectOptionLink}>
                                            {archivedSelectOption}
                                        </TextFieldSelectItem>
                                    );
                                }
                                return null;
                            })}
                        </TextFieldSelect>
                        <div className="btn-wrapper center">
                            {mobile || tablet ? (
                                <div className="mobile-warning">
                                    <Message code="epic.launcher.mobile.subtext" />
                                </div>
                            ) : (
                                <Button as="a" href={activeVersion}>
                                    {downloadLabel || <Message code="epic.ue.products.download" />}
                                    <span> {!downloadLabel && downloadLabel}</span>
                                </Button>
                            )}
                        </div>
                        <div className="plugin-documents center">
                            {TPSLink}
                            {spacer}
                            {releaseLink}
                        </div>
                        <div className="plugin-update-date">
                            {updateDate ? <Message code="epic.ue.products.updated" /> : ''}{' '}
                            {updateDate}
                        </div>
                        <PluginToggle onClick={flipCard}>
                            <span>{latestLabel}</span>
                        </PluginToggle>
                    </div>
                )}
            </div>
        </Plugin>
    );
};
