import React from 'react';
import {getNoTrailingSlash, getPageNoLeadingSlash} from '@epic-mw/common-utils';
import {extractSlugLocale} from '@epic-mw/localization';
import BlogPage from './BlogPage';
import {useLocation, useMatch, useParams, PathMatch, Params} from 'react-router-dom';
import styled from 'styled-components';
import {UnrealLoading} from 'epic-ue-loading';
import {HighlightProps, useCmsData} from '../shared';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import {PageData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const getSlug = (match: PathMatch | null, params: Params) => {
    const title = params.title;
    const typeSlug = params.typeSlug || '';
    const url = match?.pathname || '';
    const {pageSlug} = extractSlugLocale(url, '');
    const urlPattern = pageSlug;
    let rootPageSlug = pageSlug.replace(new RegExp(`${title}$`), '');
    rootPageSlug = getNoTrailingSlash(rootPageSlug);
    if (typeSlug) {
        rootPageSlug = rootPageSlug.replace(new RegExp(`${typeSlug}$`), '');
    }
    rootPageSlug = getNoTrailingSlash(rootPageSlug);
    rootPageSlug = getPageNoLeadingSlash(rootPageSlug);
    return {rootPageSlug, urlPattern};
};

interface HighlightPagePageData extends PageData {
    highlight: HighlightProps;
}

const Styled = styled.div`
    background: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};

    .blog-container,
    .blog-content,
    .blog-content .content-grid-row .content-toss-container,
    .blog-content .content-grid-row .content-toss-container a {
        background: ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.primary};
    }

    .content-grid-row {
        .content-toss-container {
            .content-toss {
                .content-toss-item {
                    .headline a {
                        color: white;
                    }
                }
            }
        }
    }
`;

const HighlightPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const params = useParams();
    const {rootPageSlug, urlPattern} = getSlug(match, params);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<HighlightPagePageData>(urlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const post = pageData.highlight;
    if (post) {
        const tags: string[] = [];
        const postTags =
            typeof post?.tags === 'string'
                ? (post?.tags || '').split(',')
                : post?.tags || ([] as {text: string}[]);
        postTags.forEach((tag) => {
            if (tag && tag.text) {
                tags.push(tag.text);
            }
        });
        if (tags.length) {
            post.tags = tags.join(',');
        }
    }

    const blog = post ? Object.assign(pageData, {post}) : null;
    if (blog) {
        //don't show the date
        blog._activeDate = '';
    }

    return (
        <Styled>
            <BlogPage
                blog={post ? Object.assign(pageData, {post}) : null}
                linkNavigatorRootPageSlug={true}
                match={match}
                {...{
                    enableComments: false,
                    disableShareIcons: true,
                    rootPageSlug
                }}
            />
        </Styled>
    );
};

HighlightPage.getInitialSharedValues = getInitialCmsPageSharedValues();
export default HighlightPage;
