import React from 'react';
import PropTypes from 'prop-types';

/**
 * PartnerCard
 *
 * Rendered by parent /events/partnerPanel
 *
 * Displays the logo and link to each partner
 */
const PartnerCard = props => {
    const {image, alt, targetURL} = props;
    return (
        <div className="block-partner-item">
            <a href={targetURL}>
                {image && (
                    <img
                        alt={alt}
                        className="img-responsive"
                        height="100"
                        src={image}
                        width="100"
                    />
                )}
            </a>
        </div>
    );
};

PartnerCard.propTypes = {
    image: PropTypes.string,
    alt: PropTypes.string,
    targetURL: PropTypes.string
};

export default PartnerCard;
