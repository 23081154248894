import React from 'react';
import pt from 'prop-types';
import {H2} from 'epic-ue-components';
import SpeakerCard from './Speakers/speaker/Speaker';
import styled from 'styled-components';

const SpeakerWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    ${(p) => p.theme.breakpoints.down('xl')} {
        margin-top: 40px;
    }
    h2 {
        padding: 40px 0;
        font-family: Brutal_Light;
        font-size: 3em;
        text-transform: uppercase;
        margin-bottom: 0;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 2em;
        }
    }

    .mobileDevice .col-xs-6 {
        padding-left: 5px;
        padding-right: 5px;
    }
`;

const getGridClasses = (n) => {
    if (!n || n === 1) return 'col-xs-12';

    const ratio = Math.floor(12 / n);

    return `col-xs-6 col-md-${Math.max(ratio, 3)}`;
};

const Speakers = ({speakers = [], title, circular, popDesc, passClick}) => {
    let speakersElem = <div />;
    const staggerItems = [];
    if (speakers && speakers.length) {
        speakersElem = speakers.map((speaker, index) => {
            const i = index;
            return (
                <div
                    ref={(c) => {
                        staggerItems[i] = c;
                    }}
                    key={`s-${i}-${speaker.name}`}
                    className={getGridClasses(speakers.length)}>
                    <SpeakerCard
                        {...speaker}
                        isCircular={circular}
                        popDesc={popDesc}
                        handleClick={passClick}
                    />
                </div>
            );
        });
    }
    if (!title && !speakersElem && !staggerItems.length) return <div />;

    return (
        <SpeakerWrapper>
            {title ? <H2>{title}</H2> : ''}
            <div className="container">
                <div className="row">{speakersElem}</div>
            </div>
        </SpeakerWrapper>
    );
};

Speakers.propTypes = {
    speakers: pt.array,
    title: pt.string,
    circular: pt.bool,
    popDesc: pt.bool,
    passClick: pt.func
};

export default Speakers;
