import React from 'react';
import {FeedLogoProps, getOptImage} from 'epic-ue-shared';
import {FeedItemProps} from '../../../../types';
import styled from 'styled-components';
import {getLogo} from '../../../../utils';

export const LogoBoxWrapper = styled.div`
    height: 150px;
    width: 150px;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 90%;
    }
`;

interface Properties {
    item: FeedItemProps;
}
const placeholderLogoImg =
    'https://cdn2.unrealengine.com/ue-logo-icon-only-white-337x337-064dc4a9225a.png';

const getLogoColor = (logoData: FeedLogoProps): string => {
    const grey = '#f3f1f3';
    const black = '#000';

    if (logoData && logoData.logoImage) {
        return logoData.color || grey; //use color if specified, fallback to grey
    }
    return black; //black background for the UE placeholder image
};

export const LogoBox = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const logoData = getLogo(item);
    const logoColor = getLogoColor(logoData);
    const logoIsSquare = logoData.isSquare || false;
    const logoImg = logoData.logoImage || placeholderLogoImg;
    const logoText = logoData.logoText || '';
    const logoColorBg = {backgroundColor: logoColor};

    return (
        <LogoBoxWrapper className={`logo-box ${logoIsSquare ? 'square' : ''}`} style={logoColorBg}>
            <img
                src={`${getOptImage({url: logoImg, width: 290})}`}
                alt={logoText}
                className="logo-image"
                loading="lazy"
            />
        </LogoBoxWrapper>
    );
};
