import React from 'react';
import {useRoutesElement} from './routes/routes';
import {useClientData} from './app.initializers';
import {useAccountStore} from 'epic-ue-shared';
import {useNewsletterAccount} from 'epic-ue-shared';
import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

const App = (): JSX.Element => {
    const routes = useRoutesElement();
    useClientData();

    //init the account for all newsletter components
    const {account} = useAccountStore();
    const {account: newsletterAccount, setAccount} = useNewsletterAccount();
    useEffect(() => {
        if (
            account &&
            account.id &&
            (!newsletterAccount || !newsletterAccount.id) &&
            newsletterAccount?.id !== account.id
        ) {
            setAccount(account);
        }
    }, [account, setAccount, newsletterAccount]);

    //MW-1504 remove some query params on client to clean URL for SEO
    const [searchParams, setSearchParams] = useSearchParams();
    const lang = searchParams.get('lang');
    const sessionInvalidated = searchParams.get('sessionInvalidated');
    useEffect(() => {
        let dirty = false;
        if (lang) {
            searchParams.delete('lang');
            dirty = true;
        }
        if (sessionInvalidated) {
            searchParams.delete('sessionInvalidated');
            dirty = true;
        }
        if (dirty) {
            setSearchParams(searchParams, {replace: true});
        }
    }, [lang, sessionInvalidated, setSearchParams]);

    return (
        <div id="ue-app" className="ue-app">
            {routes}
        </div>
    );
};

export default App;
