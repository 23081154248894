import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import root from 'window-or-global';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const cmsUrlPattern = '/vpfieldguide';
const validUrl = /^https:\/\/cdn(2|3).unrealengine.com/;

interface VPFieldGuidePageData extends PageData {
    content: string;
}

const VPFieldGuidePage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<VPFieldGuidePageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const pdfToRedirectTo = pageData.content || '';
    if (validUrl.test(pdfToRedirectTo)) {
        root.location.href = pdfToRedirectTo;
    }

    return (
        <div id="vpFieldGuide">
            {getHelmetForMetaTags(pageData)}
            <UnrealLoading />
        </div>
    );
};

VPFieldGuidePage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default VPFieldGuidePage;
