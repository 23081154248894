import React from 'react';
import {Link} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';
import {SimpleLinkProps, SimpleLinkListProps, determineExternal} from 'epic-ue-shared';
import {useAnalyticsCallback} from 'epic-ue-shared/dist/utils/analyticsUtils';
import styled from 'styled-components';

const SimpleLinkWrap = styled.span`
    a,
    button {
        text-decoration: none;
    }
`;

export const SimpleLink = ({
    title = '',
    href = '',
    onClick,
    id,
    style,
    rel,
    className = '',
    children
}: SimpleLinkProps &
    React.HTMLProps<HTMLButtonElement> &
    React.HTMLProps<HTMLLinkElement>): JSX.Element => {
    const clickCallback = useAnalyticsCallback(onClick, id, href);
    const {isExternal, sameOrigin, isMailTo, isLauncher} = determineExternal(href);

    let content: string | React.ReactNode | undefined = '';
    if (children) {
        content = children;
    } else if (title) {
        content = title;
    }

    let linkElement = (
        <Link
            id={id}
            className={`simple ${className}`}
            style={style}
            rel={rel}
            to={generateRoutePath(href)}
            onClick={clickCallback}>
            {content}
        </Link>
    );

    if (isExternal && !isMailTo && !isLauncher) {
        if (!sameOrigin) {
            linkElement = (
                <a
                    id={id}
                    className={`simple ${className}`}
                    style={style}
                    key={title}
                    href={href}
                    target="_blank"
                    onClick={clickCallback}
                    rel="noopener noreferrer">
                    {content}
                </a>
            );
        }
        linkElement = (
            <a
                id={id}
                className={`simple ${className}`}
                key={title}
                style={style}
                href={href}
                rel={rel}
                onClick={clickCallback}>
                {content}
            </a>
        );
    } else if (!href && !onClick) {
        return <span className="simple-non-link-wrap">{content}</span>;
    }

    return <SimpleLinkWrap className="simple-link-wrap">{linkElement}</SimpleLinkWrap>;
};

export const SimpleLinkList = (props: SimpleLinkListProps): JSX.Element[] => {
    const simpleLinkList = props.simpleLinkList || ({} as any);
    const simpleLinks = simpleLinkList.simpleLinks || ([] as SimpleLinkProps[]);
    return simpleLinks.map((link) => (
        <SimpleLink key={link.title || 'simple-link-key'} {...link} />
    ));
};
