import styled from 'styled-components';

export const HighlightPanelSectionWrapper = styled.div`
    .headline {
        width: 100%;
        padding-top: 2em;
        position: relative;
        ${(p) => p.theme.breakpoints.down('md')} {
            text-align: left;
            padding-left: 0;
            span {
                word-wrap: wrap-word;
                display: block;
                width: 80%;
                line-height: 2em;
            }
        }
    }
    .headline-content {
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        z-index: 1;
        background: #edeff3;
        padding: 0 2em;
        cursor: pointer;
        ${(p) => p.theme.breakpoints.down('md')} {
            font-size: 14px;
            text-align: left;
            width: 100%;
            padding-top: 10px;
        }
        i {
            padding: 3px 0 0 20px;
            ${(p) => p.theme.breakpoints.down('md')} {
                position: absolute;
                right: 10px;
                top: 15px;
            }
        }
    }
    .headline-desc {
        padding: 1em;
    }
    .line {
        content: '';
        position: absolute;
        top: 2.5em;
        left: 2em;
        right: 2em;
        height: 1px;
        background: #b1b1b1;
        z-index: 0;
        ${(p) => p.theme.breakpoints.down('md')} {
            top: 20px;
            bottom: auto;
            right: auto;
            width: 100%;
            left: auto;
        }
    }
    .accordion {
        transition: height 0.3s ease-in, opacity 0.3s ease-in;
        &.close {
            height: 0;
            opacity: 0;
            overflow: hidden;
            position: relative;
        }
    }
    &:last-child {
        margin-bottom: 4em;
    }
`;
