import React from 'react';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import GeneralLandingStardustPage from './GeneralLandingStardustPage';

const cmsUrlPattern = '/education/students';

interface Properties {}

const StudentsStardustPage = (props: Properties): JSX.Element => {
    return <GeneralLandingStardustPage {...props} cmsUrlPattern={cmsUrlPattern} />;
};

StudentsStardustPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default StudentsStardustPage;
