import styled from 'styled-components';
import { H4 } from '../heading/Heading.styles';

interface TypeProps {
    type?: string;
    center?: boolean;
    noPaddingBetweenTitles?: boolean;
}

export const CardTagLabel = styled.div`
    display: block;
    margin: 1em 0;
`;

export const CardContent = styled.div<TypeProps>`

    .title-second {
        margin-top: ${(p) =>p.noPaddingBetweenTitles ? 'unset' : '16px'}
    }
    
    color: ${(p) =>
        p.type === 'inverted' ? p.theme.palette.grey['900'] : p.theme.palette.text.secondary};
    padding: 45px;
    font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
    background: ${(p) =>
        p.type === 'paper' ? p.theme.palette.background.paper : p.theme.palette.background.primary};

    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 26px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin-top: 20px;
        li {
            margin-bottom: 5px;
        }
    }
`;

export const CardContainer = styled.div<TypeProps>`
    margin-bottom: 30px;
    height: 100%;
    text-align: ${(p) => (p.center ? 'center' : 'left')};
    position: relative;
    .btn-wrapper {
        margin: 0 auto;
    }
    &.overlay {
        &:not(.handheld) {
            backdrop-filter: blur(20px);
        }
    }
`;

export const CardImageContainer = styled.div<TypeProps>`
    position: relative;
    ${CardContent} {
        position: absolute;
        z-index: 1;
        bottom: 0;
        padding: 10px;
        color: white;
        background-color: #00000070;
        width: 100%;
        padding: 1.5em 1.5em 0.5em;
        transition: background-color 300ms ease-out;
    }
    .imageBg {
        border-radius: 12px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.8;
        transition: transform 300ms ease-out;
    }

    &:hover {
        ${CardContent} {
            background-color: #000000b0;
        }
        .imageBg {
            transform: scale(1.01);
        }
    }
`;

export const Title = styled(H4) <TypeProps>`
    &&& {
        margin: 0 0 0.5em;
        color: ${(p) =>
        p.type === 'inverted' ? p.theme.palette.common.black : p.theme.palette.text.primary};
    }
`;

export const Copy = styled.div<TypeProps>`
    color: ${(p) =>
        p.type === 'inverted' ? p.theme.palette.grey['900'] : p.theme.palette.text.secondary};
    font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 5px;
    }
    a {
        color: ${(p) => p.theme.ue.colors.primary};
        &:hover,
        &:active,
        &:focus {
            color: ${(p) => p.theme.ue.colors.buttons.link.hover};
        }
    }
`;
