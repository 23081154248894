import React from 'react';
import pt from 'prop-types';
import {H2} from 'epic-ue-components';
import Day from './Day';
import {getLocale, getBowserState} from '@epic-core/common';
import {AgendaWrapper, AgendaTableWrapper, DayTitle, DayTitleWrapper} from './Agenda.styles';

const isInt = value => {
    if (isNaN(value)) {
        return false;
    }
    const x = parseFloat(value);
    return (x | 0) === x;
};
export default class Agenda extends React.PureComponent {
    static propTypes = {
        image: pt.string,
        accordionColor: pt.string,
        primaryColor: pt.string,
        textColor: pt.string,
        title: pt.string,
        supportingColor: pt.string,
        btnPrimaryColor: pt.string,
        query: pt.object,
        days: pt.array
    };

    state = {
        toggle: false,
        activeTab: null
    };

    componentDidMount() {
        const days = this.props.days;
        const query = this.props.query;
        let agendaQuery = '';
        const activeTab = this.state.activeTab;
        const daysLength = days.length;
        let hasDefaultDay = false;
        let title = null;
        let count = 0;
        if (activeTab === null && query === undefined) {
            days.map((day, index) => {
                const defaultDay = day.defaultDay;
                if (index === 0) {
                    const dayTitle = day.day;
                    title = dayTitle;
                }
                if (defaultDay) {
                    hasDefaultDay = true;
                }
                count++;
            });

            if (!hasDefaultDay && count === daysLength) {
                this.setState({
                    activeTab: title
                });
            }
        }

        if (query !== undefined && query.agenda !== undefined) {
            agendaQuery = query.agenda;
            if (isInt(agendaQuery)) {
                days.map((day, index) => {
                    const count = index + 1;
                    if (parseFloat(count) === parseFloat(agendaQuery)) {
                        const title = day.day;
                        this.setState({
                            activeTab: title
                        });
                    }
                });
            }
        }
    }

    tabHandler(title) {
        this.setState({
            activeTab: title
        });
    }

    selectHandler = event => {
        if (event) {
            const target = event.target || {};
            const {value} = target;
            this.setState({
                activeTab: value
            });
        }
    };

    render() {
        const {
            image,
            btnPrimaryColor,
            accordionColor,
            primaryColor,
            textColor,
            title,
            supportingColor,
            days
        } = this.props;
        const daysTitles = [];
        const daysList = [];
        const {mobile, tablet} = getBowserState();
        const activeTab = this.state.activeTab;
        let dateOptions = {weekday: 'long', month: 'short', day: 'numeric'};
        if (days.length > 4) {
            dateOptions = {weekday: 'short', month: 'short', day: 'numeric'};
        } else if (days.length > 5) {
            dateOptions = {month: 'short', day: 'numeric'};
        }

        if (mobile || tablet) {
            dateOptions = {weekday: 'long', month: 'short', day: 'numeric'};
        }

        let bgImage = '';
        if (image) {
            bgImage = image;
        }

        if (days !== undefined && days.length > 0) {
            days.map((day, index) => {
                const i = index;
                const dayTitle = day.day;
                const defaultDay = day.defaultDay;
                const convertedDate = new Date(day.date);
                const fullDate = new Date(
                    convertedDate.getUTCFullYear(),
                    convertedDate.getUTCMonth(),
                    convertedDate.getUTCDate()
                );
                const title = day.day;
                const locale = getLocale() || 'en_US';

                let isActive = '';
                let className = 'tab-item';
                if (activeTab === null && defaultDay) {
                    className += ' active';
                    isActive = title;
                } else if (activeTab !== null && activeTab === title) {
                    className += ' active';
                    isActive = title;
                }

                if (!mobile && !tablet) {
                    daysTitles.push(
                        <DayTitle
                            className={className}
                            key={`title-${title}-${i}`}
                            onClick={() => this.tabHandler(title)}>
                            {fullDate.toLocaleDateString(locale, dateOptions)}
                        </DayTitle>
                    );
                } else if (mobile || tablet) {
                    daysTitles.push(
                        <option key={`opt-${title}-${i}`} defaultValue={isActive} value={title}>
                            {fullDate.toLocaleDateString(locale, dateOptions)}
                        </option>
                    );
                }
                daysList.push(
                    <Day
                        key={`i-${dayTitle}-${i}`}
                        {...day}
                        mobile={mobile}
                        tablet={tablet}
                        active={isActive}
                    />
                );
            });
        }

        let daySelector = <DayTitleWrapper>{daysTitles}</DayTitleWrapper>;
        if (mobile || tablet) {
            daySelector = (
                <form className="form" id="form">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="form-group">
                                <div className="select-div filled">
                                    <select
                                        id="day-select"
                                        name="day-select"
                                        className="form-control"
                                        onChange={this.selectHandler}
                                        onBlur={this.selectHandler}
                                        value={this.state.activeTab || ''}>
                                        {daysTitles}
                                    </select>
                                    <label htmlFor="day-select" className="control-label">
                                        Select Day
                                    </label>
                                    <span className="chevron" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            );
        }

        return (
            <AgendaWrapper
                primaryColor={primaryColor}
                textColor={textColor}
                style={{backgroundImage: `url(${bgImage})`}}>
                <div className="container">
                    <div className="row">
                        <H2>{title}</H2>
                        {daySelector}
                    </div>
                </div>

                <AgendaTableWrapper
                    btnPrimaryColor={btnPrimaryColor}
                    primaryColor={primaryColor}
                    textColor={textColor}
                    accordionColor={accordionColor}
                    supportingColor={supportingColor}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12">
                                <div>{daysList}</div>
                            </div>
                        </div>
                    </div>
                </AgendaTableWrapper>
            </AgendaWrapper>
        );
    }
}
