import styled from 'styled-components';

export const Plugin = styled.div`
    perspective: 1000;
    width: 350px;
    height: 550px;
    margin: 20px 0;

    &.plugin-col-3 {
        flex-basis: 100%;

        ${(p) => p.theme.breakpoints.up('lg')} {
            flex-basis: 50%;
        }
        @media screen and (min-width: 1600px) {
            flex-basis: 30%;
        }
    }

    &.plugin-col-4 {
        flex-basis: 100%;

        ${(p) => p.theme.breakpoints.up('lg')} {
            flex-basis: 50%;
        }
        ${(p) => p.theme.breakpoints.up('xl')} {
            flex-basis: 25%;
            font-size: 0.9em;
            .btn-wrapper {
                font-size: 0.8em;
            }
        }

        @media screen and (min-width: 1600px) {
            .btn-wrapper {
                font-size: 1em;
            }
        }
    }

    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
        transform-style: preserve-3d;
    }

    .MuiOutlinedInput-notchedOutline,
    .MuiInputBase-input {
        transition: border-color 0.5s, color 0.5s;
    }

    &.flip {
        .inner {
            transform: rotateY(180deg);
        }
        .front {
            pointer-events: none;

            .MuiOutlinedInput-notchedOutline {
                border-color: transparent;
            }
            .MuiInputBase-input {
                color: transparent;
            }
        }
    }

    .front,
    .back {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        backface-visibility: hidden;
        padding: 20px;
        border: 1px solid ${(p) => p.theme.palette.misc.outline};
    }

    .front {
        background-color: ${(p) => p.theme.palette.background.default};
    }

    .back {
        background-color: ${(p) => p.theme.palette.background.default};
        transform: rotateY(180deg);

        .badge-promo {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
            max-height: 60px;
            margin-bottom: -8px;
            z-index: 1;
            padding: 6px 14px 10px;
            border-radius: 2px 2px 0 0;
            color: #fff;
            text-align: center;
            background: ${(p) => p.theme.palette.background.paper};
        }

        .badge-promo-content {
            position: relative;
            z-index: 1;
            font-family: sans-serif;
            font-size: 18px;
        }
    }

    .plugin-select {
        text-align: left;
        margin: 15px 0;
    }

    ${(p) => p.theme.breakpoints.down('lg')} {
        padding: 10px;
    }

    h3 {
        text-transform: uppercase;
        margin: 65px 0 15px;
        font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
        color: #fff;
        text-align: center;
        font-size: 1.2em;
        height: 50px;
        line-height: 1.4em;
        ${(p) => p.theme.breakpoints.down('xs')} {
            height: auto;
        }
    }
    .plugin-desc {
        height: 60px;
        ${(p) => p.theme.breakpoints.down('xs')} {
            height: auto;
        }
        color: #e1e1e1;
    }
    .button-spacer {
        height: 49px;
    }

    .mobile-warning {
        color: #f07171;
        margin: 20px 0;
    }

    .studio-cta-button {
        padding: 10px 28px;
        width: 100%;
        margin-bottom: 20px;
    }

    .plugin-documents {
        line-height: 30px;

        div.spacer {
            display: inline-block;
            color: #ccc;
            margin: 0 5px;
        }
    }

    .plugin-update-date {
        width: 90%;
        margin: 3em 5% 0;
        text-align: center;
        color: #8a8a8a;
        font-size: 0.8em;
    }

    .plugin-item .btn-wrapper .btn.btn-primary {
        font-size: 0.9em;
        padding: 10px 14px;
    }
`;

export const PluginToggle = styled.a`
    cursor: pointer;
    margin: 15px 0;
    display: block;
    font-size: 0.875em;
`;
