import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import {H2} from 'epic-ue-components';
import {SectionCopy, SectionImage} from './Section.styles';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';
import {sanitizeCopy} from '@epic-mw/common-utils/dist/contentUtils';

const SectionStackedWrapper = styled.div`
    padding: 40px 2em;
    * {
        text-align: center;
    }
    ${(p) => p.theme.breakpoints.down('md')} {
        margin: 0;
        width: 100%;
    }
`;

const StackedImage = styled(SectionImage)`
    &&& {
        margin: 20px 0;
        width: 100%;
        height: auto;
        img {
            padding: 20px;
            max-width: 100%;
            ${(p) => p.theme.breakpoints.down('md')} {
                padding: 20px 40px;
            }
        }
    }
`;

const SectionStacked = ({title, copy, buttonData, bgType}) => {
    let copyElem;
    let buttons;

    if (copy) {
        copyElem = (
            <SectionCopy dangerouslySetInnerHTML={{__html: sanitizeCopy(copy)}} className="copy" />
        );
    }

    if (buttonData) {
        buttons = <CommonButtonWrapper sectionId="section-stacked" {...buttonData} />;
    }

    return (
        <SectionStackedWrapper className="container">
            <div className="row">
                <div className="col-xs-12">
                    <H2>{title}</H2>
                    {copyElem}
                    {buttons}
                </div>
                <div className="col-xs-12">
                    <StackedImage className="image">{bgType}</StackedImage>
                </div>
                {buttons}
            </div>
        </SectionStackedWrapper>
    );
};

SectionStacked.propTypes = {
    title: pt.string,
    copy: pt.string,
    buttonData: pt.object,
    bgType: pt.element
};

export default SectionStacked;
