import React, {useState, useCallback} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {getSlugPath, getTitle, getTagMatch} from '../../utils';
import {useFeedContentTypes, useFeedLatestStories} from '../../shared';
import {useLocalization} from '@epic-mw/localization';
import {LatestListWrapper} from './Latest.styles';
import {MediumFeedCard} from '../item/normal/';

interface Properties {
    loading: string | boolean;
}

export const Latest = (props: Properties): JSX.Element => {
    const loading = props.loading;
    const {contentTypes} = useFeedContentTypes();
    const {latestStories} = useFeedLatestStories();
    const getMessage = useLocalization();
    const [inView, setInView] = useState(false);
    const onChange = useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            }
        },
        [setInView]
    );

    return (
        <VisibilitySensor onChange={onChange} active={!inView} partialVisibility>
            <LatestListWrapper className={`container latest-list ${loading ? 'hidden' : ''}`}>
                <div className="row">
                    <div className="latest-item">
                        <div className="header-wrapper">
                            {latestStories && latestStories.length ? (
                                <div className="h1">
                                    {getMessage('epic.feed.latest.stories.title')}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <ul className={`latest-list-wrapper ${inView ? 'latest-inView' : ''}`}>
                            {latestStories.map((item) => {
                                const title = getTitle(item);
                                const matchTag = getTagMatch(item);
                                const linkPath = getSlugPath(item);
                                const _feedSlug = item._feedSlug || title || '';

                                let show = true;
                                contentTypes.forEach((type) => {
                                    if (type && type.slug === _feedSlug && type.disableFilter) {
                                        show = false;
                                    }
                                });

                                if (!show) return null;

                                return (
                                    <li className="latest-type events col-xs-12" key={title}>
                                        <MediumFeedCard
                                            item={item}
                                            title={title}
                                            tagMatch={matchTag}
                                            linkPath={linkPath}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </LatestListWrapper>
        </VisibilitySensor>
    );
};
