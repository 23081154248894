import styled from 'styled-components';
import {H4} from 'epic-ue-components';

const VideoPlayerContainer = styled.div`
    width: 100%;
    overflow: hidden;
    background: ${(p) => p.theme.palette.background.default};
    color: ${(p) => p.theme.palette.text.primary};
    z-index: 8;
    margin: 20px 0 40px;
    padding-bottom: 15px;
    .background-video.video {
        height: 0;
        overflow: visible;
    }
    .video-player-wrapper {
        max-height: 658px;
        min-height: 440px;
        height: 53.8vw;
        ${(p) => p.theme.breakpoints.down('sm')} {
            min-height: 240px;
            max-height: 480px;
            height: 53.8vw;
        }
        position: relative;
        z-index: 9;
        .background-video {
            .screen {
                max-height: 658px;
                min-height: 440px;
                height: 53.8vw;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    min-height: 240px;
                    max-height: 480px;
                    height: 53.8vw;
                }
                background: #f5f5f5;
                position: relative;
            }
            .twitch-video-embed iframe,
            .youtube-video-embed {
                width: 100%;
                height: 100%;
            }
        }
        .overlay {
            display: none;
        }
    }
    .video-player-title {
        padding: 40px 20px 40px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 12px;
            height: 60px;
            &.hasVideoDescription {
                height: fit-content;
            }
        }
        ${(p) => p.theme.breakpoints.up('xl')} {
            &.hasVideoDescription {
                height: 135px;
            }
        }

        z-index: 2;
        position: relative;
        bottom: 0;
        width: 100%;
        height: 125px;
        background: ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.primary};
        span {
            color: ${(p) => p.theme.palette.text.secondary};
        }
        ${H4} {
            font-size: 1.5em;
            margin: 0;
            padding: 10px;
            text-align: left;
            max-width: 100% !important;
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 0.92em;
            }
        }
        .description {
            padding-left: 10px;
        }
    }

    &.pinned:not(.handheld) {
        height: 750px;
        .video-player-wrapper {
            max-height: 324px;
            min-height: 324px;
            height: 324px;
            width: 576px;
            ${(p) => p.theme.breakpoints.down('lg')} {
                height: 288px;
                max-height: 288px;
                min-height: 288px;
                width: 512px;
            }
            position: fixed;
            right: 20px;
            bottom: 80px;
            z-index: 99999;
            box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.5);
            transition: opacity 0.3s ease;
            margin: 0;
            padding: 0;
            background: #000;
            .background-video {
                .screen {
                    position: absolute;
                    width: 100%;
                    max-height: 100%;
                    min-height: 100%;
                    height: 100%;
                    background: #000;
                }
            }
        }
    }
    &.footerOffset:not(.handheld) {
        .video-player-wrapper {
            top: 40px;
            bottom: auto;
            ${(p) => p.theme.breakpoints.down('xs')} {
                top: 5px;
                width: 225px;
                height: 125px;
            }
        }
    }
`;

const VideoThumbnail = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => p.image});
    opacity: 0.4;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const CloseButton = styled.button`
    position: absolute;
    top: -12px;
    right: -12px;
    border-radius: 50%;
    background: ${(p) => p.theme.palette.background.default};
    border: none;
    opacity: 0.9;
    transition: opacity 300ms ease-out;
    outline: none;
    width: 30px;
    height: 30px;
    transition: background 0.3s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i {
        font-size: 0.875em;
        &:before {
            color: ${(p) => p.theme.palette.text.primary};
        }
    }

    &:hover {
        opacity: 1;
        background: #0aaff1;
    }
`;

const PopOutButton = styled.button`
    position: absolute;
    right: 25px;
    padding: 2px 6px;
    bottom: 30px;
    opacity: 0.6;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 1.2em;
    background: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.text.primary};

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-out;
    cursor: pointer;

    &:hover {
        opacity: 1;
        background: ${(p) => p.theme.palette.background.paperpop};
    }

    i {
        font-size: 18px;
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        display: none;
    }
`;

export {VideoPlayerContainer, VideoThumbnail, CloseButton, PopOutButton};
