import React, {useCallback, useEffect} from 'react';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import {LightboxMediaWrapper, LightboxMediaVideoWrapper} from './LightboxMedia.styles';
import root from 'window-or-global';
import {BackgroundProps} from 'epic-ue-shared';

type ClickEventHandler = (event: React.MouseEvent) => void;

export interface LightboxMediaProps {
    title: string;
    video: {video?: BackgroundProps} & BackgroundProps;
    show: boolean;
    clickHandler?: ClickEventHandler;
}

const setBodyFixed = (fixed?: boolean) => {
    if (root.document) {
        let className = root.document.body.className;
        if (fixed) {
            className += ' fixed';
        } else {
            className = className.replace(' fixed', '');
        }
        root.document.body.className = className;
    }
};

export const LightboxMedia = ({
    title,
    video,
    show,
    clickHandler
}: LightboxMediaProps): JSX.Element => {
    const youtubeVid = video.video || (video as BackgroundProps) || ({} as BackgroundProps);

    const onCloseClick = useCallback(
        (e) => {
            setBodyFixed();
            if (clickHandler && typeof clickHandler === 'function') {
                clickHandler(e);
            }
        },
        [clickHandler]
    );

    useEffect(() => {
        setBodyFixed(show);
    }, [show]);

    if (!show) return <div />;

    return (
        <div>
            {show ? (
                <LightboxMediaWrapper>
                    <div className={`background-type ${youtubeVid.type}`}>
                        <div className="container-fluid">
                            <LightboxMediaVideoWrapper
                                role="presentation"
                                className="trailer-video play">
                                <div className="container vid">
                                    <div
                                        role="presentation"
                                        className="close-btn"
                                        onClick={onCloseClick}>
                                        <span className="lines" />
                                    </div>
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <BackgroundVideo
                                            key={`lightbox-${title}`}
                                            background={youtubeVid}
                                            type={youtubeVid.type}
                                            enableRatioHelper={true}
                                            play={true}
                                            mute={false}
                                            controls={true}
                                            ytControls={true}
                                            playsInline={true}
                                        />
                                    </div>
                                </div>
                            </LightboxMediaVideoWrapper>
                        </div>
                    </div>
                </LightboxMediaWrapper>
            ) : (
                ''
            )}
        </div>
    );
};
