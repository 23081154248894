import {AxiosError} from 'axios';

interface ReadAxiosErrorResult {
    status: number;
    statusText: string;
    message: string;
    errorCode: string;
    messageVars: string[];
    numericErrorCode: number;
    originatingService: string;
    intent: string;
}

const defaultResult: ReadAxiosErrorResult = {
    /**
     * 5xx server errors
     * @see https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
     */
    status: 500,
    statusText: 'Internal Server Error',
    /**
     * From epic-messages-common
     * @see https://github.ol.epicgames.net/online-web/epic-messages-common/blob/master/i18n/common/common-messages_en-US.json
     */
    message: 'Unknown error.',
    errorCode: 'errors.com.epicgames.unknown_error',
    messageVars: [],
    numericErrorCode: -1,
    originatingService: '',
    intent: ''
};

export const readAxiosError = (error?: AxiosError | Error | null | unknown): ReadAxiosErrorResult => {
    const response = (error as AxiosError | undefined | null)?.response;
    const status = response?.status ?? defaultResult.status;
    const statusText = response?.statusText ?? defaultResult.statusText;
    const message =
        (response?.data as {errorMessage})?.errorMessage ?? (error as Error)?.message ?? defaultResult.message;
    // ensure the correct message code is given when the default message is returned
    const errorCode = message === defaultResult.message ? defaultResult.errorCode : '';

    return {
        ...defaultResult,
        errorCode,
        ...((response && (response?.data as Record<string, any>)) || {}),
        message,
        status,
        statusText
    };
};

export function axiosErrorFormatter(err: AxiosError, customMessage?: string) {
    const {status, statusText, errorCode, message: errorMessage} = readAxiosError(err);
    let appMessage = customMessage ?? '';
    return `Service Error:  Status - (${status}) Message - (${statusText}) ErrorCode - (${errorCode}) ErrorMessage - (${errorMessage}) Custom Message (${appMessage})`;
}
