import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import ParagonSection from '../components/paragon/ParagonSection';
import ParagonCards from '../components/paragon/ParagonCards';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';
import {ParagonPageWrapper} from './ParagonPage.styles';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {ParagonSectionProps, ParagonCardSectionProps} from './ParagonPage';

const cmsUrlPattern = '/globalgamejam';

const GameJam = styled.div`
    .h2 {
        font-size: 1.6em;
    }

    .section-left {
        .content p {
            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 0.8em;
            }
        }
    }
    .section-right {
        .content {
            p {
                ${(p) => p.theme.breakpoints.down('md')} {
                    font-size: 0.9em;
                }
            }
            color: white !important;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
`;

interface GameJamPageData extends PageData {
    sectionOne: ParagonSectionProps;
    sectionCards: ParagonCardSectionProps;
    sectionRight: ParagonSectionProps;
    sectionLeft: ParagonSectionProps;
    formSection: ParagonSectionProps;
    cardSection: ParagonCardSectionProps;
    isActive: boolean;
}

const GameJamPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<GameJamPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    if (pageData && pageData.isActive) {
        const sectionOne = pageData.sectionOne || {};
        const sectionLeft = pageData.sectionLeft || {};
        const sectionRight = pageData.sectionRight || {};
        const sectionCards = pageData.cardSection || {};
        const formSection = pageData.formSection || {};

        return (
            <ParagonPageWrapper>
                <GameJam className="game-jam-view sponsored-content-view">
                    {getHelmetForMetaTags(pageData)}

                    <ParagonSection
                        title={sectionOne.title}
                        desc={sectionOne.desc}
                        image={sectionOne.image}
                        mobileImage={sectionOne.mobileImg}
                        btnLabel={sectionOne.btnLabel}
                        btnUrl={sectionOne.btnUrl}
                        key="sponsored-content-section1"
                        offset={0}
                        sectionName="top"
                    />

                    <ParagonCards
                        title={sectionCards.title}
                        desc={sectionCards.desc}
                        tweetDesc={sectionCards.tweetDesc}
                        cards={sectionCards.cards}
                        key="sponsored-content-section4"
                        sectionName="cards"
                    />

                    <ParagonSection
                        title={sectionRight.title}
                        desc={sectionRight.desc}
                        image={sectionRight.image}
                        mobileImage={sectionRight.mobileImg}
                        btnLabel={sectionRight.btnLabel}
                        btnUrl={sectionRight.btnUrl}
                        key="sponsored-content-section2"
                        offset={100}
                        sectionName="right"
                    />

                    <ParagonSection
                        title={sectionLeft.title}
                        desc={sectionLeft.desc}
                        image={sectionLeft.image}
                        mobileImage={sectionLeft.mobileImg}
                        btnLabel={sectionLeft.btnLabel}
                        btnUrl={sectionLeft.btnUrl}
                        key="sponsored-content-section3"
                        offset={100}
                        sectionName="left"
                    />

                    <ParagonSection
                        title={formSection.title}
                        desc={formSection.desc}
                        image={formSection.image}
                        mobileImage={formSection.mobileImg}
                        btnLabel={formSection.btnLabel}
                        btnUrl={formSection.btnUrl}
                        key="sponsored-content-section5"
                        showSignup={false}
                        offset={100}
                        sectionName="form"
                    />
                </GameJam>
            </ParagonPageWrapper>
        );
    }
    return <div />;
};

GameJamPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default GameJamPage;
