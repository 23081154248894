import React from 'react';
import PropTypes from 'prop-types';
import {withLocalization} from '@epic-mw/localization';
import {ResourceItem} from '../resources/Resource';
import styled from 'styled-components';
import {H1} from 'epic-ue-components';

export const HighlightPanelSectionWrapper = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    height: 100%;

    .main-section.main-section {
        padding: 0;
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    section {
        padding: 0;
        width: 100%;
        margin: 0 auto;
        padding: 0 1%;
        margin-top: 40px;
        .section-title {
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            font-size: 22px;
            text-transform: uppercase;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding: 0 10px;
            }
        }

        .articles > .link-btn,
        .more-btn {
            width: auto;
            height: auto;
            border-radius: 0;
            background: #0aaff1;
            color: #fff;
            border-radius: 2px;
            font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
            text-transform: uppercase;
            padding: 12.5px 25px;
            transition: background-color 0.2s ease-in-out;
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 100%;
                font-size: 18px;
                padding: 8px 20px;
            }
        }

        .articles > .link-btn:hover,
        .more-btn:hover {
            background: #0e9ad2;
        }

        .articles > .link-btn {
            float: right;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin-top: 50px;
            }
        }

        .more-btn {
            float: none;
        }

        .more-btn-section {
            margin: 20px auto;
        }

        .articles {
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 100%;
            }
            .shared {
                p,
                a,
                .btn,
                .link-btn,
                .more-btn {
                    font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
                }
            }
        }
    }

    .partner {
        list-style-type: none;
        position: relative;
        float: left;
        margin-right: 3%;
        width: 100%;
        height: 215px;
        padding-bottom: 10px;
        text-align: center;
        background-color: #383838;
        border: 1px solid #e2e3e4;
        border: 0;
        border-radius: 0;
        background-color: whitesmoke;
        box-shadow: 0;
        margin-bottom: 30px;
        cursor: default;
        ${(p) => p.theme.breakpoints.down('sm')} {
            border: 1px solid #ebebeb;
            width: 90%;
            height: auto;
            padding-bottom: 50px;
            margin: 15px auto;
            float: none;
        }
        .partner-logo {
            max-height: 100px;
            width: auto;
            margin-top: 45px;
        }
        .fade-in-link {
            transition: opacity 0.353s ease-in-out;
            transition-delay: 0.05s;
            text-align: left;
            position: absolute;
            text-decoration: none;
            width: 100%;
            height: 100%;
            background-color: #222;
            bottom: 0;
            opacity: 0;
            color: #0aaff1;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            padding: 40px 12px 12px 12px;
            border-radius: 0;
            display: block;
            font-size: 17px;
            .title,
            .sub-title {
                text-align: center;
                color: #0aaff1;
            }
            .title {
                font-size: 15px;
                font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
                color: whitesmoke;
            }
            .sub-title {
                padding-top: 5px;
                font-size: 14px;
                font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            }
        }
        .fade-in-link:hover {
            border-radius: 0;
            border: 0;
            opacity: 0.88;
        }
    }

    #academiaUni {
        margin: 0;
        .filter-btns {
            margin: 25px auto;
            padding: 0;
            ${(p) => p.theme.breakpoints.down('sm')} {
                padding-left: 15px;
                padding-right: 15px;
            }
            .btn {
                font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
                background: #0aaff1;
                border: 1px solid #f2f2f2;
                font-weight: 500;
                border-radius: 0;
                color: #fff;
                width: auto;
                height: auto;
                outline: 0;
                margin-right: 5px;
                padding: 10px 20px;
                transition: background-color 0.2s ease-in-out;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    margin: 5px;
                }
                &:hover {
                    transition: background-color 0.2s ease-in-out;
                }
            }
            .btn:hover,
            .btn.active {
                background: #0e9ad2;
                color: ${(p) => p.theme.palette.text.primary};
            }
        }
    }
`;

const ALL_CATEGORY = 'All';
const INVALID_INDEX = -1;

class Academia extends React.Component {
    constructor(props) {
        super(props);

        const academyData = this.props.academyData;
        const partnerData = academyData.uni || {};
        const initLimit = partnerData.init || 200; // Set 200 as default init amount
        const partners = partnerData.uni || [];

        this.state = {
            displayLimit: initLimit,
            partnersLength: partners.length,
            category: ALL_CATEGORY,
            // Init ES6 Map for caching results
            cacheResults: new Map()
        };
    }

    static propTypes = {
        academyData: PropTypes.object,
        getMessage: PropTypes.func
    };

    updateCategory = (category) => () => {
        this.setState({
            category
        });
    };

    trimAndSplit = (str) => {
        if (!str) return '';

        return str.replace(/\s/g, '').split(',');
    };

    generateFilterOptions() {
        const academyData = this.props.academyData;
        const filterOptions = [];

        const uniMap = academyData.uni || {};
        const uni = uniMap.uni || [];

        uni.forEach((u) => {
            if (u && u.region) {
                const regions = u.region.split(',');
                regions.forEach((r) => {
                    const region = r.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
                    if (region && filterOptions.indexOf(region) === -1) {
                        filterOptions.push(region);
                    }
                });
            }
        });

        return filterOptions;
    }

    createCategoryFilter = (category) => (partner) => {
        const region = partner.region || '';
        const regions = this.trimAndSplit(region);
        return region && regions.indexOf(category) !== INVALID_INDEX;
    };

    filterByCategory = (partners, category = ALL_CATEGORY) => {
        const cacheResults = this.state.cacheResults;
        const categoryFilter = this.createCategoryFilter(category);
        if (cacheResults && cacheResults.has(category)) {
            return cacheResults.get(category);
        }
        const results = partners.filter(categoryFilter) || [];
        if (cacheResults && results.length > 1) {
            cacheResults.set(category, results);
        }
        return results;
    };

    randomUniqueKey = () => Math.random().toString(36).substring(7);

    loadMore = () => {
        const academyData = this.props.academyData;
        const initLimit = (academyData.uni || {}).init;
        this.setState({
            displayLimit: this.state.displayLimit + initLimit
        });
    };

    render() {
        const category = this.state.category;
        const academyData = this.props.academyData || {};
        let filterOptions = this.trimAndSplit(academyData.filterOptions) || [];
        if (filterOptions.length === 0) {
            filterOptions = this.generateFilterOptions();
        }
        if (filterOptions.indexOf(ALL_CATEGORY) === INVALID_INDEX) {
            filterOptions = [ALL_CATEGORY].concat(filterOptions);
        }
        const title = academyData.title;
        const content1 = academyData.content1;
        const link = academyData.link;
        const linkText = academyData.linkText;
        const partners = (academyData.uni || {}).uni || [];
        const includeAllResult = category === ALL_CATEGORY;
        const filteredPartners = includeAllResult
            ? partners
            : this.filterByCategory(partners, category);
        const displayLimit = this.state.displayLimit; // partners.init
        const partnersLength = this.state.partnersLength || partners.length;
        const hasMore = displayLimit < partnersLength;
        const hasPartners = filteredPartners.length > 0;

        return (
            <HighlightPanelSectionWrapper id="academia" className="partners-academia container">
                <section>
                    <div className="col-md-12 main-section">
                        <H1 className="section-title">{title}</H1>
                        <div className="shared" dangerouslySetInnerHTML={{__html: content1}} />
                        {link && linkText && (
                            <a className="btn link-btn" href={link}>
                                {linkText}
                            </a>
                        )}
                    </div>
                </section>
                <section id="academiaUni">
                    <div className="filter-btns col-md-12">
                        {filterOptions &&
                            filterOptions.map((_category) => {
                                const categoryNoSpaces = _category.replace(/\s/g, '');
                                const useActiveClass = categoryNoSpaces === category;
                                const resourceType = _category.replace(/\s+/g, '-').toLowerCase();
                                const label = _category === ALL_CATEGORY ? academyData?.allCategoryLabel : _category;
                                return (
                                    <button
                                        className={`btn ${
                                            useActiveClass ? 'active' : ''
                                        } category-${resourceType}`}
                                        onClick={this.updateCategory(categoryNoSpaces)}
                                        data-cat={_category}
                                        key={this.randomUniqueKey()}>
                                        {label || _category}
                                    </button>
                                );
                            })}
                    </div>
                    <ul className="row clearfix resource-unordered-list">
                        {hasPartners &&
                            filteredPartners.map((partner, index) => {
                                const uniqueId = this.randomUniqueKey();
                                const withinLimit = index + 1 <= displayLimit;
                                return withinLimit ? (
                                    <ResourceItem
                                        partner={partner}
                                        key={`p.name-${uniqueId}`}
                                        index={index}
                                        category={category}
                                    />
                                ) : (
                                    ''
                                );
                            })}
                    </ul>
                    <div className="row more-btn-section text-center">
                        {hasMore && (
                            <button className="btn more-btn" onClick={this.loadMore}>
                                {this.props.getMessage('epic.ue.common.more')}
                            </button>
                        )}
                    </div>
                </section>
            </HighlightPanelSectionWrapper>
        );
    }
}

export default withLocalization(Academia);
