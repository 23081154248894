import React from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    getOptImage,
    getUrlPattern,
    useCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {
    CmsComponentMapper,
    CmsDynamicSection,
    CmsDynamicSectionProps,
    GridContainer,
    ImageHero,
    ImageHeroProps,
    ImmersiveHeaderSection,
    TemplateThemeProps,
    VerticalSpacer
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme} from 'epic-ue-theme';
import {MosaicPanels} from '../../components/enterprise/Mosaic/MosaicLayout';
import {HighlightPanelProps, useFeaturesData} from '../../shared';
import {UnrealLoading} from 'epic-ue-loading';
import {
    GeneralLandingHeaderProps,
    DynamicHeaderProps,
    isDynamicHeaderType,
    GeneralLandingPageData,
    GeneralLandingPageSections
} from 'epic-ue-ui/dist/types/general-landing.types';

const Styled = styled.div`
    position: relative;
    overflow: hidden;

    .attribution-caption .caption span {
        font-size: 16px;
        line-height: 30px;
    }
    ${(p) => p.theme.breakpoints.up('md')} {
        .attribution-caption .caption {
            justify-content: space-around;
            display: flex;
        }
        .attribution-caption .caption span {
            width: 65%;
        }
    }
    .Mui-disabled {
        color: rgba(0, 0, 0, 0.38);
    }
    .ue-newsletter-section input {
        color: initial;
    }
`;

const ueCharcoalImg = getOptImage({
    url: 'https://cdn2.unrealengine.com/ue-loading-charcoal-50b21f079938.gif',
    width: 200
});

const ueStoneImg = getOptImage({
    url: 'https://cdn2.unrealengine.com/ue-loading-stone-d933aaf017c8.gif',
    width: 200
});

const Features = styled.div<{loadingImg: string}>`
    color: white;

    .loading-icon {
        background-image: url(${(p) => p.loadingImg || ''});
    }
    .grid-news-title:hover .fit-text {
        text-shadow: 6px 6px 6px rgb(0 0 0 / 70%);
    }
    .grid-news-item:not(.featured):hover .grid-news-title,
    .grid-news-item:not(.featured):focus .grid-news-title {
        color: white;
    }
    .grid-news-category.hidden {
        display: none !important;
    }

    .highlight-panel-section {
        text-align: center;
    }

    .grid-news-item {
        border: 0px;
        background-color: #202337;
    }
    .grid-news-inner.block-inner,
    .vertical .grid-news-inner,
    .grid-news-inner {
        text-align: left;
    }
    .grid-news-item.horizontal .grid-news-inner {
        text-align: left;
    }
    .grid-news-item > *,
    .grid-news-text .html > *,
    .grid-news-inner .fill-text > * {
        background-color: #202337;
    }
    .grid-news-title {
        text-decoration: none;
        color: white;
    }

    .grid-news-text a {
        color: white;
    }

    h1 {
        text-align: center;
    }

    .copy {
        text-align: left;
        ${(p) => p.theme.breakpoints.down('sm')} {
            text-align: center;
            font-size: 18px;
        }
        margin: 4em auto;
        font-size: 20px;
        font-family: ${(p) => p.theme.ue.fonts.opensans};
    }
    .btn-primary {
        max-width: 400px;
    }
`;

interface Properties {
    cmsUrlPattern?: string;
}

const FeaturesStardustPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<GeneralLandingPageData>(urlPattern);
    const {featuresData} = useFeaturesData(urlPattern);

    const {
        header = {} as GeneralLandingHeaderProps,
        themeConfig = {} as TemplateThemeProps,
        sections = {} as GeneralLandingPageSections
    } = pageData;

    const isDynamicHeader = isDynamicHeaderType(header);
    const {headerOptions} = isDynamicHeader ? (header as DynamicHeaderProps) : {headerOptions: []};

    const {items: dynamicSections = [] as CmsDynamicSectionProps[]} = sections;

    const {accentColor, theme} = themeConfig;

    const loadingImg = theme === 'dark' ? ueCharcoalImg : ueStoneImg;

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const highlightPanels: HighlightPanelProps[] = featuresData.highlightPanelList || [];
    const visiblePanels: HighlightPanelProps[] = [];
    highlightPanels.forEach((panel) => {
        if (panel && panel.showPanel) {
            visiblePanels.push(panel);
        }
    });

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled>
                {getHelmetForMetaTags(pageData)}
                {isDynamicHeader ? (
                    <CmsComponentMapper
                        dynamicPageDataArray={headerOptions}
                        elementMapping={{
                            imageHero: <ImageHero accentColor={accentColor} />,
                            immersiveHeader: <ImmersiveHeaderSection />
                        }}
                    />
                ) : (
                    <ImageHero {...(header as ImageHeroProps)} />
                )}

                <GridContainer>
                    <Features className="studio-features enterprise" loadingImg={loadingImg}>
                        <MosaicPanels panels={visiblePanels} accordion={true} />
                    </Features>
                </GridContainer>

                <VerticalSpacer variant="heading" />

                {dynamicSections &&
                    dynamicSections.length &&
                    dynamicSections.map((dynamicSectionData, index) => {
                        const {sectionConfig = {}} = dynamicSectionData;
                        const extendedSectionConfig = {
                            ...sectionConfig,
                            accentColor,
                            index
                        };

                        return (
                            <CmsDynamicSection
                                key={index}
                                {...dynamicSectionData}
                                sectionConfig={extendedSectionConfig}
                            />
                        );
                    })}
            </Styled>
        </UeThemeProvider>
    );
};

FeaturesStardustPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default FeaturesStardustPage;
