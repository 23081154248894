import React from 'react';
import {
    getHelmetForMetaTags,
    useCmsTreeData,
    PageData,
    useCmsData,
    InitSharedValueResponse,
    cmsApi,
    AppSharedKeys
} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import ErrorPage from '../ErrorPage';
import {UeThemeProvider, ueDarkTheme, useSetCurrentAccentColor, ueLightTheme} from 'epic-ue-theme';
import {
    DownloadSection,
    DownloadSectionProps,
    ImageAndTextCard,
    ImageHero,
    ImageHeroProps,
    MultiCard,
    NewsletterSection,
    NewsletterSectionProps,
    SubnavSection,
    SubnavSectionConfigProps,
    SubnavSectionProps,
    TemplateThemeProps,
    VerticalSpacer,
    Spotlight
} from 'epic-ue-ui';
import {ExplainersTopicPageData} from './ExplainersTopicStardustPage';

const cmsUrlPattern = '/explainers';

const Styled = styled.div`
    max-width: 100vw;
    overflow-x: clip;
`;

// eslint-disable-next-line no-unused-vars
enum LayoutEnum {
    // eslint-disable-next-line no-unused-vars
    LargeCard = 'LARGE_CARD',
    // eslint-disable-next-line no-unused-vars
    SmallCard = 'SMALL_CARD'
}

export interface DownloadWithNewsletterSectionProps extends SubnavSectionProps {
    download?: DownloadSectionProps;
    newsletter?: NewsletterSectionProps;
}

export interface ExplainersPageData extends PageData {
    themeConfig?: TemplateThemeProps;
    header?: ImageHeroProps;
    download: DownloadWithNewsletterSectionProps;
    layout: LayoutEnum;
}

const ExplainersPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<ExplainersPageData>(cmsUrlPattern);
    const {cmsLoading: cmsTreeLoading, children = []} =
        useCmsTreeData<ExplainersTopicPageData>(cmsUrlPattern);
    const {
        themeConfig = {} as TemplateThemeProps,
        download = {} as DownloadWithNewsletterSectionProps,
        layout = LayoutEnum.LargeCard
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    if (cmsLoading || cmsTreeLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    let innerChildren;

    switch (layout) {
        case LayoutEnum.SmallCard:
            innerChildren = (
                <>
                    <MultiCard
                        cards={children.map(({_urlPattern, landing}) => ({
                            image: {
                                image: {src: landing.image, alt: landing.alt || landing.imageLabel},
                                title: landing.imageLabel || landing.alt,
                                eyebrow: {text: landing.eyebrow},
                                description: landing.description,
                                link: {
                                    href: _urlPattern,
                                    label: landing.button?.label || 'See more',
                                    type: landing.button?.type
                                },
                                showButton: false
                            }
                        }))}
                        accentColor={accentColor}
                        maxItemsInRowFromMd={3}
                    />
                    <VerticalSpacer variant="section" />
                </>
            );
            break;
        case LayoutEnum.LargeCard:
        default:
            innerChildren = children.map((child, key) => {
                const {_urlPattern} = child;
                const {image, imageLabel, heading, eyebrow, description, button, caption} =
                    child?.landing || {};
                return (
                    <Spotlight
                        key={`exp-${key}`}
                        showImage={false}
                        offsetRatio={[0.2, 0.1]}
                        offsetRatioDown={[0, 0.5]}
                        opacity={1}
                        image={image}
                        invertHorizontalRatio={!(key % 2)}>
                        <ImageAndTextCard
                            accentColor={accentColor}
                            image={{src: image, alt: imageLabel}}
                            caption={caption}
                            heading={heading}
                            eyebrow={eyebrow}
                            description={description}
                            button={{
                                href: _urlPattern,
                                label: button?.label || 'See more',
                                type: 'secondary'
                            }}
                            alignment={key % 2 ? 'left' : 'right'}
                        />
                        <VerticalSpacer variant="feature" />
                    </Spotlight>
                );
            });
            break;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <Styled>
                <ImageHero {...pageData.header} showScrollIndicator />
                <VerticalSpacer variant={pageData?.header?.image ? 'heading' : 'section'} />
                <SubnavSection
                    sectionConfig={{
                        index: null,
                        theme,
                        accentColor
                    }}
                    hideBottomSectionPadding>
                    {innerChildren}
                </SubnavSection>
                <SubnavSection
                    variant="gradient"
                    hideBottomSectionPadding
                    sectionConfig={downloadSectionConfig}>
                    <DownloadSection
                        {...downloadBlockProps}
                        accentColor={accentColor}
                        variant="standard"
                    />
                    <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                    <NewsletterSection
                        variant="small-split"
                        {...download?.newsletter}
                        accentColor={accentColor}
                    />
                </SubnavSection>
            </Styled>
        </UeThemeProvider>
    );
};
ExplainersPage.getInitialSharedValues = async (): Promise<Array<InitSharedValueResponse>> => {
    const responses = await Promise.all([
        await cmsApi.getPageData(cmsUrlPattern),
        await cmsApi.getTreeData(cmsUrlPattern)
    ]);

    return [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [cmsUrlPattern]: responses[0] || {}
            }
        },
        {
            key: AppSharedKeys.CmsTreeStore,
            value: {
                [cmsUrlPattern]: responses[1] || []
            }
        }
    ];
};

export default ExplainersPage;
