import styled from 'styled-components';
import {getOptImage} from 'epic-ue-shared';

export const Desc = styled.div`
    text-align: center;
    margin: 0 auto;
    max-width: 60%;
    ${(p) => p.theme.breakpoints.down('md')} {
        max-width: 90%;
    }
`;

export const Training = styled.div`
    background: ${(p) => p.theme.palette.background.default};
    color: #e2e2e2;
    h2 {
        margin-top: 1em;
        text-align: center;
    }
    .header {
        ${(p) => p.theme.breakpoints.up('md')} {
            min-height: 40vh !important;
            height: auto !important;
            max-height: 80vh !important;
        }

        @media screen and (min-height: 400px) and (min-width: 700px) {
            min-height: 500px !important;
            max-height: 500px !important;
            height: 500px;
        }
    }
    .course-list {
        margin-top: 2.5em;
    }
    padding-bottom: 5em;

    .courses-showcase {
        margin: 40px auto 0;
        height: 500px;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 275px;
        }
        width: 100%;
        > div {
            height: 100%;
        }
        .slick-slider {
            width: 100%;
            height: 100%;
            .pagination {
                margin-top: 20px;
                padding: 0;
                ${(p) => p.theme.breakpoints.down('md')} {
                    margin-top: 6px;
                }
                button {
                    outline: none;
                    margin: 0.5em;
                    padding: 0;
                }
                li.slick-active {
                    button {
                        background: #f5f5f5 !important;
                    }
                }
            }

            .slick-arrow {
                cursor: pointer;
                &.slick-disabled {
                    display: block !important;
                }
                top: 45%;
                height: 50px;
                width: 50px;
                background: none;
                border-radius: 9999px;
                border: 0;
                font-size: 1px;
                color: #fff;
                outline: none;
                color: rgba(0, 0, 0, 0);
                padding: 0;

                ${(p) => p.theme.breakpoints.down('sm')} {
                    top: 50%;
                    height: 35px;
                    width: 35px;
                }

                &:before {
                    content: '\\e910';
                    font-family: ${(p) => p.theme.ue.fonts.icomoon};
                    font-size: 30em;
                    color: #fff;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    text-shadow: 0 0 5px #333;

                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 20em;
                        left: 6px;
                    }
                }

                &.slick-prev {
                    left: 15px;

                    &:before {
                        left: 10px;

                        ${(p) => p.theme.breakpoints.down('sm')} {
                            left: 8px;
                        }
                    }
                }

                &.slick-next {
                    right: 15px;

                    &:before {
                        content: '\\e90d';
                        left: 15px;
                        ${(p) => p.theme.breakpoints.down('sm')} {
                            left: 8px;
                        }
                    }
                }
            }
            .course-showcase {
                height: 500px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: 275px;
                }
            }
        }
        .course-showcase {
            height: 100%;
            width: 100%;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40%;
                z-index: 0;
                background: linear-gradient(
                    180deg,
                    rgba(0, 212, 255, 0) 0%,
                    rgba(0, 0, 0, 0.7) 100%
                );
            }

            .course-feature-content {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 10px 20px;

                .course-title {
                    font-size: 2em;
                    font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
                    display: block;
                    color: #fff;
                    z-index: 3;
                    position: absolute;
                    bottom: 50px;
                    left: 20px;
                    width: 80%;
                    &.has-badge {
                        padding-left: 100px;
                    }
                    ${(p) => p.theme.breakpoints.down('md')} {
                        font-size: 1.5em;
                        left: 20px;
                        bottom: 20px;
                        padding: 0;
                        line-height: 1.5em;
                        width: 85%;
                        &.has-badge {
                            padding-left: 60px;
                        }
                    }
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 1.25em;
                        bottom: 23px;
                    }
                }

                .course-feature-badge {
                    position: absolute;
                    bottom: 40px;
                    left: 60px;
                    z-index: 3;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        bottom: 25px;
                        left: 40px;
                        > div {
                            height: 50px;
                            width: 50px;
                        }
                    }
                }
            }
        }
        .slick-slider {
            position: relative;
            .pagination {
                margin-top: 20px;
                padding: 0px;
                text-align: center;
                li {
                    display: inline-block;
                    &.slick-active button {
                        background: rgb(245, 245, 245) !important;
                    }
                    button {
                        outline: none;
                        margin: 0.5em;
                        padding: 0px;
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        border: 1px solid #333;
                        margin: 1em;
                        border-radius: 50%;
                        background: transparent;
                        color: transparent;
                    }
                }
            }
            .slick-arrow {
                cursor: pointer;
                top: 45%;
                height: 50px;
                width: 50px;
                background: none;
                border-radius: 9999px;
                border: 0px;
                font-size: 1px;
                outline: none;
                color: rgba(0, 0, 0, 0);
                padding: 0px;
                position: absolute;
                z-index: 99;

                &:before {
                    font-size: 30em;
                    color: rgb(255, 255, 255);
                    position: absolute;
                    top: 10px;
                    left: 0px;
                    text-shadow: rgb(51, 51, 51) 0px 0px 5px;
                }

                &.slick-next {
                    &:before {
                        left: 15px;
                    }
                }
            }
            .slick-list {
                width: 100%;
                .slick-slide {
                    float: left;
                    height: 100%;
                    min-height: 1px;

                    .course-showcase {
                        height: 500px;
                        width: 100%;
                        position: relative;
                        ${(p) => p.theme.breakpoints.down('md')} {
                            height: 275px;
                        }
                        .course-feature-img {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            inset: 0px;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .courses-overview {
        h2 {
            font-family: Brutal_Light, Open Sans, arial;
            text-transform: uppercase;
        }
    }
    .title-wrap {
        min-height: 200px;
    }
    &&& {
        .header {
            @media screen and (max-height: 1000px) and (min-width: 1000px) {
                min-height: 600px;
                height: 600px;
            }
            @media screen and (min-height: 1200px) and (max-width: 1100px) {
                min-height: 600px;
                height: 600px;
            }
            h1 {
                margin-top: 1em;
            }
            h2 {
                text-transform: initial;
                font-size: 2em;
                margin: 1em 0.3em;
            }
        }
    }
`;

export const Filters = styled.div`
    padding: 0.5em;
    color: black;
    text-align: left;
    margin: 50px 0 0;

    input {
        background: #111;
        color: white;
        outline: 0;
        border: 0;
        padding: 10px 8px;
        height: 3em;
        border: 1px solid #f5f5f5;
        height: 100%;
        &:focus {
            background: #000;
        }

        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
    }

    .search-box,
    .select-box {
        display: inline-block;
        width: 25%;
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
        margin-right: 20px;
    }

    .search-box {
        text-align: right;
        margin-right: 0;
        width: 30%;
        vertical-align: top;

        ${(p) => p.theme.breakpoints.down('md')} {
            width: 100%;
        }

        input {
            width: 100%;
            height: 40px;
        }
    }

    .select-box-wrapper {
        .select-box.filter-by-tags {
            width: 40%;
            ${(p) => p.theme.breakpoints.down('md')} {
                width: 100%;
            }
        }
    }
`;

export const ShowcaseCardImg = styled.div<{img: string}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => getOptImage({url: p.img, width: 1300, quality: 'high'})});
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
`;

export const SortLabel = styled.div`
    float: left;
    color: white;
    vertical-align: bottom;
    padding: 0.4em 0.5em;
    opacity: 0.4;
`;
