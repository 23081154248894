export const fieldAdjustments = {
    Affiliated_with_Training_Center__c: {
        onChange(e, {input, setFieldData, setValue}) {
            const newValue = !input.value;
            setFieldData('Training_Centers__c', {hidden: !newValue});
            if (!newValue) {
                setValue('Training_Centers__c', '');
            }
            setValue('Affiliated_with_Training_Center__c', newValue); //must use setValue after setFieldData for validations to run
        }
    },
    Country: {
        onSelectChange({id, selection, setValue}) {
            setValue('country', (selection && selection.label) || '');
        }
    },
    Teaches_Unreal_Engine__c: {
        onChange(e, {input, setFieldData, setValue}) {
            const newValue = !input.value;
            setFieldData('Game_Development_or_Enterprise__c', {hidden: !newValue});
            if (!newValue) {
                setValue('Game_Development_or_Enterprise__c', '');
                setFieldData('Game_Development_or_Enterprise_Other__c', {hidden: true});
                setValue('Game_Development_or_Enterprise_Other__c', '');
            }
            setValue('Teaches_Unreal_Engine__c', newValue); //must use setValue after setFieldData for validations to run
        }
    },

    Game_Development_or_Enterprise__c: {
        onSelectChange({id, selection, setFieldData, setValue}) {
            if (selection.value.toLowerCase() === 'other') {
                setFieldData('Game_Development_or_Enterprise_Other__c', {hidden: false});
            } else {
                setFieldData('Game_Development_or_Enterprise_Other__c', {hidden: true});
                setValue('Game_Development_or_Enterprise_Other__c', '');
            }
            setValue('Game_Development_or_Enterprise__c', selection); //must use setValue after setFieldData for validations to run
        }
    },

    Number_of_Unreal_Engine_Instructors__c: {
        onSelectChange({id, selection, setFieldData, setValue}) {
            const selectionVal = selection.value.split(/\s+\-\s+/); // eslint-disable-line
            if (selectionVal[0] === '0') {
                setFieldData('Are_any_instructors_Authorized_Trainers__c', {hidden: true});
            } else {
                setFieldData('Are_any_instructors_Authorized_Trainers__c', {hidden: false});
            }
            setValue('Number_of_Unreal_Engine_Instructors__c', selection); //must use setValue after setFieldData for validations to run
        }
    }
};
