import {useState, useEffect, useMemo} from 'react';
import {useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys} from 'epic-ue-shared';
import {feedApi, FeedItemsReq} from './feed.api';
import {FeedItemProps} from '../types';
import {isError} from '@epic-mw/error-types';

export interface FeedItemsResponse {
    items: any[];
    tags: {[key: string]: boolean};
    types: {[key: string]: boolean};
    latestStories: any[];
    loading?: boolean;
    loaded: boolean;
    error?: string;
}

export const useFeedItems = (req: FeedItemsReq, contentLoading?: boolean): FeedItemsResponse => {
    const patternsToSkip = req.patternsToSkip || [];
    const tags = req.tags || {};
    const types = req.types || {};
    const numItems = req.numItems || 10;

    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [feedItemsStore, setFeedItemsStore] = useSharedValue(AppSharedKeys.FeedItemsStore);
    const feedItemTags = feedItemsStore.tags || {};
    const feedItemTypes = feedItemsStore.types || {};
    const feedItems = feedItemsStore.items || [];
    const feedItemPatternsToSkip = feedItemsStore.patternsToSkip || [];

    //the order of these keys must match to correctly compare the stringified object
    const stringifiedCurrent = JSON.stringify({tags, types, patternsToSkip});
    const stringifiedStore = JSON.stringify({
        tags: feedItemTags,
        types: feedItemTypes,
        patternsToSkip: feedItemPatternsToSkip
    });

    //reset
    if (stringifiedCurrent !== stringifiedStore && loadingState.loaded && !loadingState.error) {
        setFeedItemsStore(
            Object.assign({}, feedItemsStore, {
                items: [],
                tags: {},
                types: {},
                patternsToSkip: []
            })
        );
        setLoadingState({loading: false, loaded: false, error: ''});
    }

    useEffect(() => {
        if (
            (Object.keys(tags).length !== 0 &&
                Object.keys(types).length !== 0 &&
                stringifiedCurrent === stringifiedStore) ||
            loadingState.loading ||
            loadingState.loaded ||
            contentLoading ||
            loadingState.error
        ) {
            return;
        }
        if (
            Object.keys(tags).length === 0 &&
            Object.keys(types).length === 0 &&
            loadingState.loaded &&
            !loadingState.error
        ) {
            setFeedItemsStore(
                Object.assign({}, feedItemsStore, {
                    items: [],
                    tags: {},
                    types: {},
                    patternsToSkip: []
                })
            );
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData =
                    (await feedApi.getFeedItems({tags, types, patternsToSkip, numItems})) || {};
                setFeedItemsStore(
                    Object.assign({}, feedItemsStore, {
                        ...fetchedData,
                        tags,
                        types,
                        patternsToSkip
                    })
                );
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch data for', tags, types, ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [feedItemTags, loadingState.loading, loadingState.loaded, contentLoading]);

    return useMemo(() => {
        return {
            ...feedItemsStore,
            loading: loadingState.loading || (!feedItems.length && !loadingState.loaded),
            loaded: loadingState.loaded,
            error: loadingState.error
        };
    }, [
        stringifiedStore,
        stringifiedCurrent,
        loadingState.loading,
        loadingState.loaded,
        contentLoading
    ]);
};

export const useLatestFeedItems = (
    req: FeedItemsReq,
    contentLoading?: boolean
): {
    loaded: boolean;
    loading: boolean;
    error?: string;
    items: FeedItemProps[];
} => {
    const patternsToSkip = req.patternsToSkip;
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [latestFeedItemsStore, setLatestFeedItemsStore] = useSharedValue(
        AppSharedKeys.FeedItemsLatestStore
    );
    const feedItems = latestFeedItemsStore.items || [];

    useEffect(() => {
        if (
            feedItems.length ||
            loadingState.loading ||
            loadingState.loaded ||
            contentLoading ||
            loadingState.error
        ) {
            return;
        }

        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData = (await feedApi.getFeedItems({})) || {};
                setLatestFeedItemsStore(
                    Object.assign({}, latestFeedItemsStore, {
                        ...fetchedData
                    })
                );
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [feedItems, loadingState.loading, loadingState.loaded, contentLoading]);

    const uniqueItems: FeedItemProps[] = [];
    feedItems.forEach((item) => {
        if (item?._urlPattern && patternsToSkip?.indexOf(item._urlPattern) === -1) {
            uniqueItems.push(item);
        }
    });

    return {
        items: uniqueItems,
        loading: loadingState.loading || (!feedItems.length && !loadingState.loaded),
        loaded: loadingState.loaded,
        error: loadingState.error
    };
};
