import React, {useRef} from 'react';
import styled from 'styled-components';
import {generateSearchURL} from '../utils/search.uils';

const Styled = styled.div`
    background-color: ${(p) => p.theme.palette.accent['sky']};
    height: 45px;
    border-radius: 5px;
    padding-left: 2px;
    overflow: hidden;
    margin: 0;
    position: relative;
    input {
        display: block;
        border: none;
        padding: 0 0 0 1em;
        width: calc(100% - 108px);
        height: 41px;
        margin: 2px 0;
        outline: none;
        ${(p) => p.theme.breakpoints.down('xs')} {
            width: calc(100% - 70px);
        }
    }
    button {
        position: absolute;
        cursor: pointer;
        padding: 0;
        border: 0;
        height: 100%;
        width: 100px;
        right: 0;
        top: 0;
        font-size: 0.8em;
        font-weight: 500;
        text-align: center;
        transition: background 0.2s ease-in-out;
        outline: none;
        background: transparent;
        color: white;
        font-size: 1.1em;
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 0.6em;
            width: 60px;
        }
    }
`;

interface SearchBarProps {
    searchKeyword?: string;
    queryUrl?: {filter?: string; keyword?: string};
    setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
    searchLabel?: string;
    navigate: any;
}

export const SearchBar = ({
    searchKeyword = '',
    queryUrl,
    setSearchKeyword,
    searchLabel = 'Search',
    navigate
}: SearchBarProps): JSX.Element => {
    const keyword = useRef(null);

    const newSearchResults = (keyword): void => {
        navigate(generateSearchURL(queryUrl, undefined, keyword));
    };

    return (
        <Styled>
            <input
                ref={keyword}
                type="text"
                defaultValue={searchKeyword}
                onChange={(e) => {
                    e.preventDefault();
                    setSearchKeyword(e.target.value);
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        setSearchKeyword(keyword?.current || '');
                        newSearchResults(keyword?.current);
                    }
                }}
            />
            <button
                onClick={() => {
                    setSearchKeyword(keyword?.current || '');
                    newSearchResults(keyword?.current);
                }}>
                {searchLabel}
            </button>
        </Styled>
    );
};

export default SearchBar;
