import React, {useState} from 'react';
import styled from 'styled-components';
import {Paginator, SearchPages, ImageSearchResults, PlainSearchResults} from '.';
import {GridContainer} from 'epic-ue-ui';
import { AccentColorOptions } from 'epic-ue-theme';

const SearchResultsWrapper = styled.div``;

interface SearchResultsProps {
    accentColor?: AccentColorOptions;
    isLoading?: boolean;
    searchKeyword?: string;
    total?: number;
    filter?: string;
    pages?: SearchPages;
    loadingIcon?: React.ReactNode;
    isTwinmotion?: boolean;
}

export const SearchResults = ({
    accentColor,
    pages = {},
    isLoading,
    searchKeyword,
    total,
    filter,
    loadingIcon = null,
    isTwinmotion
}: SearchResultsProps): JSX.Element => {
    const [pageNum, setPageNum] = useState<number>(0);
    const page = pages[pageNum] || {};
    const {imageItems = [], plainItems = []} = page;
    const contentfulPlainItems = plainItems.filter((item) => item !== undefined);
    const isMissingTwoPlainItems = contentfulPlainItems.length < 2;

    const mutableImageItems = imageItems.concat();
    const mutablePlainItems = isMissingTwoPlainItems
        ? contentfulPlainItems.concat(mutableImageItems.splice(0, 2))
        : plainItems.concat();

    const firstPlainItems = mutablePlainItems.splice(0, 2);
    const firstImageItems = mutableImageItems.splice(0, 2);
    const secondImageItems = mutableImageItems.splice(0, 2);

    const remainingItems = mutablePlainItems.concat(mutableImageItems);
    const secondPlainItems = remainingItems.splice(0, 2);

    return (
        <SearchResultsWrapper>
            {isLoading ? (
                <div>{loadingIcon}</div>
            ) : (
                <GridContainer>
                    {firstPlainItems.length ? (
                        <PlainSearchResults plainItems={firstPlainItems} />
                    ) : (
                        ''
                    )}
                    {firstImageItems.length ? (
                        <ImageSearchResults imageItems={firstImageItems} />
                    ) : (
                        ''
                    )}
                    {secondPlainItems.length ? (
                        <PlainSearchResults plainItems={secondPlainItems} />
                    ) : (
                        ''
                    )}
                    {secondImageItems.length ? (
                        <ImageSearchResults imageItems={secondImageItems} />
                    ) : (
                        ''
                    )}
                    {remainingItems.length ? (
                        <PlainSearchResults plainItems={remainingItems} />
                    ) : (
                        ''
                    )}
                    <Paginator
                        isTwinmotion={isTwinmotion}
                        accentColor={accentColor}
                        keyword={searchKeyword}
                        filter={filter}
                        total={total}
                        setPageNum={setPageNum}
                    />
                </GridContainer>
            )}
        </SearchResultsWrapper>
    );
};

export default SearchResults;
