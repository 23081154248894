import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {getDataAttribute} from '@epic-core/common';
import root from 'window-or-global';
import {BgMedia} from '../media/BgMedia';
import {getOptImage} from 'epic-ue-shared';
import {BackgroundProps, SlideProps} from 'epic-ue-shared';
import { SimpleLink } from '../link/SimpleLink';

export interface SimpleGalleryProps {
    slides?: SlideProps[];
}

const SimpleGalleryWrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 40px auto;

    .simple {
        cursor: default;
        &.pointer {
            cursor: pointer;
        }
    }
`;

const ActiveImage = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    height: 0;
    padding-top: 56.25%;
    text-align: center;
    ${(p) => p.theme.breakpoints.down('md')} {
        height: 400px;
        padding: 20px;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 240px;
        padding: 20px;
    }
    > div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
    }
    .image-title {
        position: absolute;
        top: 25px;
        right: 0;
        width: 100%;
        text-align: center;
        font-size: 1.1em;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 0.75em;
        }
        color: #fff;
        pointer-events: none;
        z-index: 34;
    }
    .image-gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(0deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        z-index: 32;
    }
    .media-credits {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 20px;
        height: 15%;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        ${(p) => p.theme.breakpoints.down('md')} {
            display: block !important;
        }
    }
`;

const ThumbnailList = styled.div`
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    text-align: center;
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    ul {
        margin: 0 auto;
        text-align: center;
    }

    li {
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 120px;
        height: 70px;
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 75px;
            height: 45px;
        }
        margin-right: 5px;
        &.active {
            cursor: default;
            opacity: 0.5;
        }
        &:last-child {
            margin-right: 0;
        }
        &:empty {
            display: none;
        }
        &:not(:empty) {
            cursor: pointer;
        }
        img {
            height: 100%;
            margin: 0 auto;
        }
    }
`;

export const SimpleGallery = ({slides}: SimpleGalleryProps): JSX.Element => {
    const thumbnailList: (JSX.Element | undefined)[] = [];
    const [activeIndex, setActiveIndex] = useState(0);

    const changeImage = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
                const imageIndex = getDataAttribute(e.currentTarget, 'index');
                const children = root.document.querySelectorAll('.sg-thumb');
                children.forEach((elem) => {
                    elem.classList.remove('active');
                });
                e.currentTarget.className += ' active';
                setActiveIndex(imageIndex);
            }
        },
        [setActiveIndex]
    );

    useEffect(() => {
        if (slides && activeIndex === 0) {
            slides.map((image, index) => {
                const active = image.active;
                if (active) {
                    setActiveIndex(index);
                }
            });
        }
    }, [activeIndex, setActiveIndex, slides]);

    let mainImg = '';
    let mainTitle = '';
    let mainCredits = '';
    let href = '';

    if (slides) {
        slides.map((slide, index) => {
            thumbnailList.push(
                <li
                    role="presentation"
                    data-index={index}
                    title={slide.title || ''}
                    className={`sg-thumb`}
                    onClick={changeImage}
                    key={`sg-thumb-${index}`}>
                    <img
                        src={getOptImage({url: slide.image, width: 150})}
                        className="ue-lightbox-no-click"
                        alt={slide.title || ''}
                    />
                </li>
            );
        });

        const activeSlide = slides[activeIndex];
        if (activeSlide) {
            mainImg = activeSlide.image || '';
            mainTitle = activeSlide.title || '';
            mainCredits = activeSlide.credits || '';
            href = activeSlide.href || '';
        }
    }

    const activeImageData: BackgroundProps = {
        type: 'image',
        image: mainImg,
        credits: mainCredits
    };

    return (
        <SimpleGalleryWrapper>
            <SimpleLink href={href} className={href ? 'pointer' : ''}>
                <ActiveImage className="ue-lb-wrapper">
                    {mainTitle ? <span className="image-title">{mainTitle}</span> : ''}
                    {mainTitle ? <span className="image-gradient" /> : ''}
                    <BgMedia background={activeImageData} imgMaxWidth={1200} disableLightbox />
                </ActiveImage>
            </SimpleLink>

            <ThumbnailList>
                <ul>{thumbnailList}</ul>
            </ThumbnailList>
        </SimpleGalleryWrapper>
    );
};
