import styled from 'styled-components';

export const ProjectSlideWrapper = styled.div`
    padding: 25px 0;
    .row {
        & > div {
            &:nth-child(1) {
                opacity: 0;
                transform: translateX(-300px);
            }
            &:nth-child(2) {
                opacity: 0;
                transform: translateX(300px);
            }
        }
    }
    .slide-content {
        padding: 15% 0 0 100px;
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 10px 20px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            .paragraph {
                line-height: 1.7em;
            }
        }
    }
    .img-wrapper {
        width: 550px;
        height: 450px;
        position: relative;
        overflow: hidden;
        text-align: center;
        ${(p) => p.theme.breakpoints.down('lg')} {
            width: 450px;
            height: 400px;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            width: 350px;
            height: 300px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 100%;
            height: 250px;
        }
        .credit {
            position: absolute;
            bottom: 5px;
            right: 5px;
            color: #fff;
        }
        .imageBg {
            height: 100%;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    ${(p) => p.theme.breakpoints.up('sm')} {
        &:nth-child(odd) {
            .row {
                display: flex;
                & > div {
                    &:nth-child(1) {
                        order: 2;
                        ${(p) => p.theme.breakpoints.down('md')} {
                            margin: -15px 0 0 130px;
                        }
                        transform: translateX(300px);
                    }
                    &:nth-child(2) {
                        order: 1;
                        transform: translateX(-300px);
                    }
                }

                .slide-content {
                    padding-left: 200px;
                    ${(p) => p.theme.breakpoints.down('lg')} {
                        padding-left: 150px;
                    }
                    ${(p) => p.theme.breakpoints.down('md')} {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        .row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            & > div {
                flex: 1 0 100%;
            }
            .slide-content {
                padding: 20px;
            }
        }
    }

    &.runAnimation {
        .row {
            & > div {
                &:nth-child(1) {
                    opacity: 1;
                    transform: translateX(0);
                    transition: all ease 1s;
                }
                &:nth-child(2) {
                    opacity: 1;
                    transform: translateX(0);
                    transition: all ease 1s;
                }
            }
        }
    }
`;
