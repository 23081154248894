import React, {useEffect} from 'react';
import {getInitialCmsPageSharedValues, useCmsData, PageData} from 'epic-ue-shared';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {getLocale} from '@epic-core/common';
import root from 'window-or-global';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import {GlobalStyle, ClassicAppWrapper} from './classic/ClassicApp.styles';
import {BackgroundVideoStyles} from './components/video/BackgroundVideo.styles';
import {extractSlugLocale} from '@epic-mw/localization';
import {BlankContent} from './components/blank/BlankContent';
import ErrorPage from './pages/ErrorPage';

const scrollIntoView = () => {
    setTimeout(() => {
        const id = root.location && root.location.hash && root.location.hash.replace('#', '');
        if (id) {
            const element = root.document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, 100);
};

const Blank = styled.div`
    &.cms-page {
        h1 {
            font-size: 2.4em;
            padding-bottom: 0.5em;
            font-weight: bold;
            text-transform: uppercase;
        }

        h2 {
            font-size: 1.6em;
        }

        h3 {
            font-size: 1.4em;
        }

        h4 {
            font-size: 1.2em;
        }

        h1,
        h2,
        h3,
        h4 {
            color: rgb(255, 255, 255);
            margin: calc(12px + 1.05vw) 0px;
        }
    }
`;

interface BlankPageData extends PageData {
    content: string;
}

const CmsPage = (): JSX.Element => {
    const location = useLocation();
    const {pageSlug} = extractSlugLocale(location.pathname, getLocale());
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<BlankPageData>(pageSlug);

    useEffect(() => {
        scrollIntoView();
    }, []);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    const content = pageData.content;

    if (cmsLoadedEmpty || !content) {
        return <ErrorPage status={404} />;
    }

    return (
        <ClassicAppWrapper className="ue-bs">
            <GlobalStyle />
            <BackgroundVideoStyles />
            <Page>
                {getHelmetForMetaTags(pageData)}
                <Blank className="cms-page">
                    <BlankContent content={content} slug={pageData._slug || ''} />
                </Blank>
            </Page>
        </ClassicAppWrapper>
    );
};

CmsPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default CmsPage;
