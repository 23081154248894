import styled from 'styled-components';

export const CardTitle = styled.h4`
    height: auto;
    padding-bottom: 5px;
    color: #333;
    text-align: left;
    font-size: 1.2em;
    margin: 5px 0 10px 0;
    ${(p) => p.theme.breakpoints.down('md')} {
        height: 40px;
        font-size: 1.1em;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 1em;
        height: auto;
        padding-bottom: 20px;
    }
`;

export const CardContent = styled.div`
    padding: 20px 20px 80px 20px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 10px 10px 60px 20px;
    }
    height: auto;
    position: relative;
    overflow: hidden;
    bottom: 0;
    left: 0;
    background: #fff;
    height: 209px;
    width: 100%;
    transition: height 0.2s ease-in, transform 0.2s ease-in, opacity 0.1s ease-in;
`;

export const CardDescription = styled.div`
    max-width: 84%;
    word-break: break-word;
    opacity: 0;
    color: #000;
    line-height: 1.5em;
    font-size: 0.82em;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 0.7em;
    }
    transition: transform 0.2s ease-in, opacity 0.1s ease-in;
    overflow: hidden;
    margin-top: 10px;
    margin-right: 20px;
    height: 100%;
    position: absolute;
    transform: translateY(100px);
`;

export const CardImgWrapperCircular = styled.div`
    overflow: hidden;
    height: 200px;
    width: 200px;
    display: block;
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 160px;
        width: 160px;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        margin-top: 40px;
    }
    &:not(.noLink) {
        ${(p) => p.theme.breakpoints.up('md')} {
            cursor: pointer;
        }
    }
    margin: 20px auto 0;
    border-radius: 800px;
    img {
        transition: all ease 0.5s;
        margin: 0 auto 0;
        display: block;
        height: 100%;
        filter: grayscale(100%);
    }
    position: relative;
    transform: translateY(0px);
    transition: transform 0.4s ease-in;
`;

export const CardSpeakerImg = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 250px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 200px;
    }
    transition: all ease 0.5s;
`;

export const CardSpeakerImgWrapper = styled.div`
    height: 250px;
    position: relative;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 200px;
    }
    width: 100%;
    transition: all ease 0.5s;
    &:not(.noLink) {
        ${(p) => p.theme.breakpoints.up('md')} {
            cursor: pointer;
            &:hover {
                ${CardSpeakerImg} {
                    box-shadow: inset 0 0 8em 1em rgba(0, 0, 0, 0.2);
                    transform: scale3d(1.05, 1.05, 1.05);
                }
            }
        }
    }
`;

export const CardVideoImgWrapper = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    height: 175px;
    ${(p) => p.theme.breakpoints.down('xs')} {
        height: 225px;
    }
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    &:not(.noLink) {
        cursor: pointer;
        &:hover {
            box-shadow: inset 0 0 8em 1em rgba(0, 0, 0, 0.2);
            transform: scale3d(1.05, 1.05, 1.05);
        }
    }
    i {
        position: relative;
        width: 65px;
        height: 65px;
        &:before {
            content: '';
            background: #000;
            font-size: 4em;
            opacity: 0.8;
            border-radius: 200px;
            position: absolute;
            width: 65px;
            height: 65px;
            top: 2px;
            left: 2px;
        }
        &:after {
            content: '';
            position: absolute;
            width: 0;
            top: 20px;
            left: 25px;
            height: 0;
            border-style: solid;
            border-width: 15px 0 15px 30px;
            border-color: transparent transparent transparent ${(p) => p.theme.ue.colors.primary};
        }
    }
`;

export const BottomButtonWrapper = styled.div`
    z-index: 2;
    * {
        display: inline-block;
    }
    position: absolute;
    bottom: 0;
    left: 20px;
    background: #fff;
    width: 100%;
    padding: 5px 0 20px;
    ${(p) => p.theme.breakpoints.down('xs')} {
        text-align: center;
        width: 100%;
        left: 0;
        bottom: 5px;
        padding: 20px 0;
        a {
            font-size: 0.875em;
        }
    }
`;

export const Company = styled.div`
    text-align: left;
    font-style: italic;
    margin-bottom: 0.5em;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 0.7em;
    }
`;

export const Position = styled.div`
    text-align: left;
    font-size: 0.9em;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 0.7em;
    }
`;

export const Description = styled.div`
    color: #999;
    font-size: 0.875em;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 0.7em;
    }
`;

export const Card = styled.div`
    background: #fff;
    border-bottom: 1px solid #e3e3e3;
    margin: 20px 0;
    min-height: auto;
    min-height: auto;
    height: 430px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 330px;
    }
    &:not(.isCircular) {
        ${(p) => p.theme.breakpoints.down('sm')} {
            height: 450px;
        }
    }
    padding: 0;
    position: relative;
    overflow: hidden;
    &:hover {
        ${CardSpeakerImgWrapper} {
            opacity: 0;
            visibility: none;
            transform: translateY(-150px);
            transition: transform 0.3s ease-out 0.3s, opacity 0.4s ease-out;
            img {
                transition: opacity 0.2s ease-out;
                opacity: 0;
                visibility: none;
            }
        }
        ${CardImgWrapperCircular} {
            visibility: none;
            transform: translateY(-150px);
            transition: transform 0.4s ease-out;
            img {
                transition: opacity 0.2s ease-out;
                opacity: 0;
                visibility: none;
            }
        }
        ${CardContent} {
            height: 100%;
            transform: translateY(${(p) => (p.isCircular ? '-220' : '-260')}px);
            ${(p) => p.theme.breakpoints.down('xs')} {
                transform: translateY(-210px);
            }
            transition: height 0.4s ease-in, transform 0.3s ease-out, opacity 0.3s ease-out;
            ${CardDescription} {
                transform: translateY(0px);
                transition: transform 0.3s ease-out, opacity 0.3s ease-out;
                opacity: 1;
            }
            ${CardTitle} {
                height: auto;
                transition: height 0.5s ease-out;
            }
        }
    }
`;
