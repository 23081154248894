import styled from 'styled-components';
import {downWithOrientation} from '../../../utils';

export const CaseStudyWrapper = styled.div<{height: number}>`
    height: ${(p) => (p.height ? p.height : 90)}vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.down('sm')} {
        height: 50vh;
    }
    &.mobile {
        height: auto !important;
        min-height: 250px;

        .overlay-content {
            display: none;
        }
    }

    video {
        width: 100% !important;
    }

    &.type-double {
        height: auto;
        margin-bottom: 40px;
    }
    &.type-threes,
    &.type-fifty {
        height: 50vh;
        width: 50%;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 100%;
        }
        float: left;
        .imageBg {
            transition: transform 350ms ease-in-out;
        }
        &:hover {
            .imageBg {
                transform: scale3d(1.02, 1.02, 1.02);
            }
        }
        h3 {
            font-size: 3em;
            margin-bottom: 10px;
        }
        h4 {
            a {
                color: ${(p) => p.theme.palette.text.primary};
                &:hover {
                    color: ${(p) => p.theme.palette.text.primary};
                }
                &:focus {
                    color: ${(p) => p.theme.palette.text.primary};
                }
            }
        }
        .bg-gradient {
            span {
                height: 80%;
                background: linear-gradient(
                    180deg,
                    rgba(0, 212, 255, 0) 0%,
                    rgba(0, 0, 0, 0.3) 60%
                );
            }
        }
        .bottom-content {
            left: 50px;
            bottom: 50px;
            width: 80%;
            ${(p) => p.theme.breakpoints.down('sm')} {
                width: 100%;
            }
            h3 {
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 2em;
                }
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                left: 0;
                bottom: 0;
                position: absolute;
            }
        }
    }
    &.type-threes {
        height: 40vh;
        width: 33%;
        ${(p) => p.theme.breakpoints.down('sm')} {
            width: 100%;
        }
        h4 {
            font-size: 1.75em;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                font-size: 1.5em;
                max-width: 95%;
            }
            ${(p) => p.theme.breakpoints.down('lg')} {
                font-size: 1.5em;
            }
            ${(p) => p.theme.breakpoints.down('sm')} {
                font-size: 1.736em;
            }
            a.simple:hover,
            a.simple:visited,
            a.simple:active,
            a.simple:focus {
                color: ${(p) => p.theme.palette.text.secondary};
            }
        }
        .btn:not(.btn-blank):not(.btn-showcase) {
            min-width: 170px;
            padding: 10px 18px;
            margin-left: 0;
        }
        .bottom-content {
            ${downWithOrientation('lg', 'landscape')} {
                left: 35px;
                bottom: 30px;
                .btn-showcase {
                    margin-left: 0 !important;
                }
            }
        }
    }
    &.type-contained {
        width: 75%;
        height: 750px;
        margin: 50px auto 100px;
    }

    &.type-row {
        height: 550px;
        width: 700px;
    }
`;

export const ContentWrapper = styled.div`
    ${(p) => p.theme.breakpoints.up('lg')} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 100px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        position: relative;
        padding: 20px;
        text-align: center;
    }
`;

export const CardPosition = styled.div`
    width: 450px;
    max-width: 100%;
    ${(p) => p.theme.breakpoints.down('sm')} {
        margin: 0 auto;
    }
`;

export const BottomContent = styled.div`
    position: absolute;
    left: 100px;
    bottom: 75px;
    width: 100%;
    z-index: 2;

    ${(p) => p.theme.breakpoints.down('sm')} {
        position: relative;
        left: initial;
        bottom: initial;
        padding: 20px;
        text-align: center;
    }

    ${downWithOrientation('lg', 'landscape')} {
        left: auto;
        bottom: 120%;
    }
`;

export const ButtonContainer = styled.div`
    width: 600px;
    max-width: 100%;
    .btn-showcase {
        margin-left: 35px !important;
        ${(p) => p.theme.breakpoints.down('sm')} {
            display: none;
        }
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        margin: 0 auto;
    }
    .btn:first-child {
        margin-left: 0;
    }
`;

export const TitleWrapper = styled.div`
    position: relative;
    overflow: hidden;
    h2 {
        font-family: ${(p) => p.theme.ue.fonts.brutal.bold};
    }
`;

export const TypeWrapper = styled.div``;
export const CaseStudyLogo = styled.img<{paddingTopBottom?: string; paddingLeftRight?: string}>`
    margin-bottom: 40px;
    max-height: 250px;
    ${(p) => p.theme.breakpoints.down('sm')} {
        margin-bottom: 20px;
    }
    padding-top: ${(p) => (p.paddingTopBottom ? `${p.paddingTopBottom}px` : '0')};
    padding-bottom: ${(p) => (p.paddingTopBottom ? `${p.paddingTopBottom}px` : '0')};
    padding-left: ${(p) => (p.paddingLeftRight ? `${p.paddingLeftRight}px` : '0')};
    padding-right: ${(p) => (p.paddingLeftRight ? `${p.paddingLeftRight}px` : '0')};
`;
