import {useState, useEffect} from 'react';
import {useSharedValue} from '@epic-core/hooks';
import {releaseNotesApi} from './releaseNotes.api';
import {AppSharedKeys} from 'epic-ue-shared';
import {isError} from '@epic-mw/error-types';

export const useReleaseNotes = (
    locale: string
): {
    loaded: boolean;
    releaseNotes: any;
    loading: boolean;
    error?: string;
} => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [releaseNotes, setReleaseNotes] = useSharedValue(AppSharedKeys.ReleaseStore);
    const releaseVersions = releaseNotes.releaseVersions || [];

    useEffect(() => {
        if (
            (releaseVersions && releaseVersions.length && !loadingState.error) ||
            loadingState.loading ||
            loadingState.loaded
        ) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = await releaseNotesApi.getReleaseData(locale);
                setReleaseNotes(data);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch release notes data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [releaseVersions, loadingState, setLoadingState, setReleaseNotes, locale]);

    return {
        releaseNotes,
        ...loadingState
    };
};
