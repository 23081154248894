import React from 'react';
import pt from 'prop-types';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';

export default class VideoOverlay extends React.PureComponent {
    static propTypes = {
        title: pt.string,
        video: pt.object,
        play: pt.bool,
        toggleVideo: pt.func
    };

    closeVideo = event => {
        this.props.toggleVideo(false, '', '');
    };

    render() {
        const {video = {}, title} = this.props;
        const type = video.type || '';
        if (!type) {
            return null;
        }
        return (
            <div>
                {this.props.play && video !== undefined ? (
                    <div className={`background-type trailer ${type}`}>
                        <div className="container-fluid">
                            <div role="presentation" className="trailer-video play">
                                <div className="container vid">
                                    <div
                                        role="presentation"
                                        className="close-btn"
                                        onClick={this.closeVideo}>
                                        <span className="lines" />
                                    </div>
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <BackgroundVideo
                                            key={`lightbox-${title}`}
                                            background={video}
                                            enableRatioHelper={true}
                                            play={true}
                                            mute={false}
                                            controls={true}
                                            ytControls={true}
                                            playsInline={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
