import axios, {AxiosResponse} from 'axios';
import {getPageNoLeadingSlash} from '@epic-mw/common-utils';
import {getLocale} from '@epic-core/common';
import {getOrigin} from 'epic-ue-shared';

const url = () => {
    const origin = getOrigin();

    const url = {
        FEATURES: (locale, page) => `${origin}/api/v2/${locale}/features/${page}`
    };

    return url;
};

class FeaturesApi {
    public async getFeatures(urlPattern): Promise<any> {
        const api = url().FEATURES(getLocale(), getPageNoLeadingSlash(urlPattern));
        try {
            const response: AxiosResponse<any> = await axios.get(api);
            return response.data;
        } catch (e) {
            console.error('getFeatures() failed to load Features page', urlPattern);
            throw e;
        }
    }
}

export const featuresApi = new FeaturesApi();
