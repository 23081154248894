import {useState, useEffect} from 'react';
import {useSharedValue} from '@epic-core/hooks';
import {AppSharedKeys, PageData, BackgroundProps} from 'epic-ue-shared';
import {featuresApi} from './features.api';
import {isError} from '@epic-mw/error-types';

export interface HighlightProps {
    highlight?: string;
    activeDate: string;
    content: string;
    image: string;
    linkToSelf: boolean;
    short: string;
    slug: string;
    tags: Array<{text: string}> | string;
    title: string;
    urlPattern: string;
}

export interface HighlightPanelProps {
    firstFeatured: boolean;
    headline: string;
    highlights: HighlightProps[];
    isNewsletterInHighlights: boolean;
    showPanel: boolean;
}

export interface FeaturesProps extends PageData {
    background: BackgroundProps;
    description: string;
    highlightPanelList: HighlightPanelProps[];
    title: string;
    buttonExternalLink: string;
    buttonText: string;
}

export interface FeaturesResponse {
    featuresData: FeaturesProps;
    loading?: boolean;
    loaded: boolean;
    loadedEmpty?: boolean;
    error?: string;
}

export const useFeaturesData = (urlPattern: string): FeaturesResponse => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        emptyData?: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});
    const [featuresStore, setFeaturesStore] = useSharedValue(AppSharedKeys.FeaturesStore);
    const featuresData = featuresStore[urlPattern] || {};

    useEffect(() => {
        if (
            (featuresData && Object.keys(featuresData).length !== 0 && !loadingState.error) ||
            loadingState.loading ||
            loadingState.loaded
        ) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const fetchedData = (await featuresApi.getFeatures(urlPattern)) || {};
                delete fetchedData.pageSlugParam;
                delete fetchedData.cmsPageMap;
                const emptyData = Object.keys(fetchedData).length === 0;
                setFeaturesStore(
                    Object.assign({}, featuresStore, {
                        [urlPattern]: fetchedData || {}
                    })
                );
                setLoadingState({loading: false, loaded: true, emptyData});
            } catch (ex) {
                console.error('Failed to fetch features data for page', urlPattern, ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [featuresData, loadingState.loading, loadingState.loaded]);

    return {
        featuresData,
        loading: loadingState.loading || (!featuresData && !loadingState.loaded),
        loaded: loadingState.loaded,
        loadedEmpty: loadingState.loaded && loadingState.emptyData,
        error: loadingState.error
    };
};
