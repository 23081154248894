import React, {useState} from 'react';
import HighlightPanelSection from '../../studio/HighlightPanelSection';
import HighlightPanelSectionAccordion from '../../mosaicaccordion/accordionpanels/HighlightPanelSection';
import {HighlightPanelProps, HighlightProps} from '../../../shared';
import {UnrealLoading} from 'epic-ue-loading';
import Mosaic from './Mosaic';
import styled from 'styled-components';
import {AccentColorOptions} from 'epic-ue-theme';
import root from 'window-or-global';

const subNavHeight = 52;

const MosaicWrap = styled.div<{accordion: boolean}>`
    .headline-content {
        background: ${(p) => p.theme.palette.background.default};
    }
    .grid-news-item.vertical .grid-news-image {
        border-color: ${(p) => p.theme.palette.background.paper};
    }
    .grid-news-title h2 {
        line-height: initial;
        font-size: 20px;
    }
    .grid-news-text {
        color: #c6c6c6;
    }
    .block-white {
        border-bottom: none;
    }
    .highlight-panel-section .line {
        background: #464646;
    }
    .grid-news-wrap.container {
        padding: 50px 10px;
        max-width: 1620px;
        margin-left: auto;
        margin-right: auto;

        ${(p) => p.theme.breakpoints.up('xl')} {
            width: 1230px;
            max-width: 1500px;
        }
    }
    .highlight-panel-section.Mui-expanded {
        margin: unset;
    }
    }
    :last-child {
        border-bottom: ${(p) =>
            p.accordion
                ? `1px solid${p.theme.ifLight(
                      p,
                      p.theme.palette.neutral.steel,
                      p.theme.palette.neutral.silver
                  )}`
                : 'initial'};
    }
`;

export const MosaicPanels = (props: {
    panels: HighlightPanelProps[];
    accordion?: boolean;
    accentColor?: AccentColorOptions;
}): JSX.Element => {
    const panels: HighlightPanelProps[] = props.panels || [];
    const accordion: boolean = props.accordion || false;
    const accentColor: AccentColorOptions = props.accentColor || 'sky';

    const [expandedItem, setExpandedItem] = useState(null);
    const [defaultExpand, setDefaultExpand] = useState(true);
    const scrollToSection = (e, sectionId) => {
        e.preventDefault();
        if (root.__server_side_render) return;
        const el = root.document.getElementsByClassName(sectionId);
        if (el) {
            root.scrollTopSkip = location.pathname; //prevent ScrollToTop from resetting position for this page
            root.scrollTo({left: 0, top: el[0].offsetTop - subNavHeight, behavior: 'smooth'});
        }
    };

    const toggleAccordionSections = (e, newExpandedItem) => {
        //if same section click multiple times , toggle it
        expandedItem === newExpandedItem ? setExpandedItem(null) : setExpandedItem(newExpandedItem);
        setDefaultExpand(false);
        //after css transitons done
        setTimeout(() => {
            scrollToSection(e, `panel-${newExpandedItem}`);
        }, 300);
    };

    if (!panels.length) return <UnrealLoading />;

    return (
        <MosaicWrap accordion={accordion}>
            {panels.map((panel, index) =>
                accordion ? (
                    <HighlightPanelSectionAccordion
                        key={panel.headline}
                        panelId={index}
                        panel={panel}
                        defaultCollapsed={defaultExpand}
                        accentColor={accentColor}
                        expandedItem={expandedItem}
                        toggleAccordionSections={toggleAccordionSections}
                    />
                ) : (
                    <HighlightPanelSection
                        key={panel.headline}
                        panel={panel}
                        defaultCollapsed={true}
                    />
                )
            )}
        </MosaicWrap>
    );
};

export const MosaicHighlights = (props: {
    highlights: HighlightProps[];
    accordion?: boolean;
    accentColor?: AccentColorOptions;
}): JSX.Element => {
    const highlights: HighlightProps[] = props.highlights || [];
    const accordion: boolean = props.accordion || false;

    if (!highlights.length) return <UnrealLoading />;

    return (
        <MosaicWrap accordion={accordion}>
            <Mosaic items={highlights} accordion={accordion} />
        </MosaicWrap>
    );
};
