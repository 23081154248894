import React from 'react';
import {BgMedia, ButtonWrapper, H3} from '../';
import styled from 'styled-components';
import {generateRoutePath} from '@epic-core/common';
import {HighlightProps, determineExternal} from 'epic-ue-shared';
import {SimpleLink} from '../link/SimpleLink';
import root from 'window-or-global';
import {useNavigate} from 'react-router-dom';

const HighlightPanelWrapper = styled.div`
    position: relative;
    overflow: hidden;

    &.content-right,
    &.content-left {
        .highlight-content-container {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            left: 250px;
            width: auto;
            height: auto;
            h3,
            .copy {
                > div,
                a > div {
                    text-align: left;
                }
                text-align: left;
            }

            ${(p) => p.theme.breakpoints.down('md')} {
                left: 50%;
                text-align: center;
                width: 85%;
                h3,
                .button-wrapper,
                .copy {
                    > div,
                    a > div {
                        text-align: center;
                    }
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &.content-right {
        .highlight-content-container {
            .button-wrapper {
                text-align: right;
            }

            left: auto;
            right: 250px;

            h3,
            .copy {
                > div,
                a > div {
                    text-align: right;
                }
                text-align: right;
            }
        }
    }

    &.content-left {
        .highlight-content-container {
            .button-wrapper {
                text-align: left;
            }

            ${(p) => p.theme.breakpoints.up('lg')} {
                width: 100%;
                left: 0;
                padding-left: 130px;
                transform: translateY(-50%);
                text-align: left;
            }
        }

        h3,
        .copy {
            ${(p) => p.theme.breakpoints.down('md')} {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &.content-right.fifty {
        .highlight-content-container {
            left: auto;
            right: 10px;
            transform: translateY(-50%);

            ${(p) => p.theme.breakpoints.down('md')} {
                left: 0;
                right: 0;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
            }
        }
    }

    &.content-left.fifty {
        .highlight-content-container {
            padding-left: 0;
            left: 30px;
            right: auto;
            transform: translateY(-50%);

            ${(p) => p.theme.breakpoints.down('md')} {
                left: 0;
                right: 0;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
            }
        }
    }

    &.content-left.fifty,
    &.content-right.fifty {
        ${(p) => p.theme.breakpoints.down('md')} {
            .highlight-content-container {
                text-align: center;

                h3,
                .button-wrapper,
                .copy,
                > div,
                a > div {
                    text-align: center;
                }
            }
        }
    }
`;

const HighlightPanelContent = styled.div`
    position: relative;
    overflow: hidden;
    min-height: 400px;
    margin: 20px 0;
    ${(p) => p.theme.breakpoints.up('md')} {
        min-height: 500px;
    }
    &.type-full {
        margin: 10px 0 0 0;
        max-width: 1200px;
    }
    * {
        text-align: center;
    }
    &&& {
        h3 {
            line-height: 1.2em;
            font-size: 2em;
            ${(p) => p.theme.breakpoints.down('lg')} {
                font-size: 1.75em;
            }

            a.simple {
                color: ${(p) => p.theme.palette.common.white};
                opacity: 0.9;
                transition: opacity 0.2s ease-out;
                display: block;
                &:hover,
                &:active,
                &:focus {
                    color: ${(p) => p.theme.palette.common.white};
                    opacity: 1;
                }
            }
        }
    }
`;

const Content = styled.div`
    position: relative;
    overflow: hidden;
    padding: 20px;
    ${(p) => p.theme.breakpoints.down('md')} {
        padding: 20px 0;
    }
    width: 100%;
    height: 100%;
`;

const ClickableContainer = styled.div<ClickableContainerProps>`
    cursor: pointer;
    width: 100%;
    height: 100%;
`;

interface ClickableContainerProps {
    href: string;
}

export const HighlightPanel = ({
    title,
    desc,
    type,
    href,
    buttons = [],
    align,
    background
}: HighlightProps): JSX.Element => {
    const navigate = useNavigate();
    let finalHref = href;
    if (!href && buttons.length) {
        const firstButton = buttons[0] || {};
        finalHref = firstButton.href;
    }

    const panelClick = React.useCallback(
        (e) => {
            if (finalHref) {
                e.preventDefault();
                const {isExternal} = determineExternal(finalHref);
                if (isExternal) {
                    root.location.href = finalHref;
                } else {
                    navigate(generateRoutePath(finalHref));
                }
            }
        },
        [finalHref]
    );

    const preventDefault = React.useCallback((e) => {
        e.preventDefault();
    }, []);

    buttons.forEach((btn) => {
        btn.onClick = (e) => {
            e.preventDefault();
            if (btn.href && btn.href !== finalHref) {
                e.stopPropagation();
                const {isExternal} = determineExternal(btn.href);
                if (isExternal) {
                    root.location.href = btn.href;
                } else {
                    navigate(generateRoutePath(btn.href));
                }
            }
        };
    });

    if (finalHref) {
        return (
            <HighlightPanelWrapper
                className={`highlight-panel col-xs-12 ${
                    type === 'fifty' ? 'col-md-6 fifty' : ''
                } content-${align ? align : 'top'}`}>
                <HighlightPanelContent className={`highlight-content type-${type}`}>
                    <ClickableContainer
                        onClick={panelClick}
                        id={`highlight-panel-${title}`}
                        href={finalHref}>
                        <BgMedia autoplay={true} background={background} imgMaxWidth={1300} />
                        <Content className="highlight-content-container">
                            <H3 className="section-heading">
                                <SimpleLink
                                    href={finalHref}
                                    id={`highlight-${title}`}
                                    onClick={preventDefault}>
                                    <div dangerouslySetInnerHTML={{__html: title}} />
                                </SimpleLink>
                            </H3>
                            <div className="copy" dangerouslySetInnerHTML={{__html: desc || ''}} />
                            <ButtonWrapper buttons={buttons} />
                        </Content>
                    </ClickableContainer>
                </HighlightPanelContent>
            </HighlightPanelWrapper>
        );
    }

    return (
        <HighlightPanelWrapper
            className={`highlight-panel col-xs-12 ${type === 'fifty' ? 'col-md-6 fifty' : ''}`}>
            <HighlightPanelContent className={`highlight-content type-${type}`}>
                <BgMedia autoplay={true} background={background} imgMaxWidth={1300} />
                <Content>
                    <H3 className="section-heading">
                        <div dangerouslySetInnerHTML={{__html: title}} />
                    </H3>
                    <div className="copy" dangerouslySetInnerHTML={{__html: desc || ''}} />
                    <ButtonWrapper buttons={buttons} />
                </Content>
            </HighlightPanelContent>
        </HighlightPanelWrapper>
    );
};
