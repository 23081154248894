import styled from 'styled-components';
import {ACCENT_COLOR, ACCENT_COLOR_HOVER} from '../../constants';

export const LinkNavigatorWrapper = styled.div`
    background-color: #1e1e1e;
    clear: both;
    font-size: 20px;
    overflow: hidden;
    padding: 15px 30px;
    min-height: 60px;
    ${(p) => p.theme.breakpoints.down('xs')} {
        font-size: 14px;
    }
    .prev-link,
    .next-link {
        i {
            font-size: 1.5em;
            float: right;
            margin-top: 0.2em;
        }

        span {
            text-transform: uppercase;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            ${(p) => p.theme.breakpoints.down('xs')} {
                display: none;
            }
        }

        &:hover {
            color: lighten(${ACCENT_COLOR}, 10%);
            cursor: pointer;
        }
    }

    .prev-link {
        float: left;

        i {
            float: left;
        }

        span {
            margin-left: 2em;
        }
    }

    .next-link {
        float: right;
        text-align: right;

        span {
            margin-right: 2em;
        }
    }

    .slug-link {
        position: absolute;
        z-index: 0;
        width: auto;
        left: 50%;
        margin-top: 0.2em;
        transform: translateX(-50%);
    }

    .prev-icon {
        &:hover {
            &:before {
                border-color: transparent transparent ${ACCENT_COLOR_HOVER} transparent;
            }

            &:after {
                border-color: ${ACCENT_COLOR_HOVER} transparent transparent transparent;
            }
        }

        &:before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0.5em 1em 0.5em;
            border-color: transparent transparent ${ACCENT_COLOR} transparent;
            transform: skewX(-45deg);
        }

        &:after {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1em 0.5em 0 0.5em;
            border-color: ${ACCENT_COLOR} transparent transparent transparent;
            transform: skewX(45deg);
        }
    }

    .next-icon {
        &:hover {
            &:before {
                border-color: transparent transparent ${ACCENT_COLOR_HOVER} transparent;
            }

            &:after {
                border-color: ${ACCENT_COLOR_HOVER} transparent transparent transparent;
            }
        }

        &:before {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0.5em 1em 0.5em;
            border-color: transparent transparent ${ACCENT_COLOR} transparent;
            transform: skewX(45deg);
        }

        &:after {
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1em 0.5em 0 0.5em;
            border-color: ${ACCENT_COLOR} transparent transparent transparent;
            transform: skewX(-45deg);
        }
    }
`;
