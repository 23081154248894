import React from 'react';
import styled from 'styled-components';
import MarketoForm from 'epic-node-marketo/src/client/MarketoForm';
import {ButtonWrap} from 'epic-node-marketo/src/client/MarketoForm.styles';
import {FeatureUnavailable} from 'epic-ue-ui';
import {useLocalization} from '@epic-mw/localization';
import {useAccountAODCPolicy, useNewsletterAccount} from 'epic-ue-shared';

export interface FieldGroupProps {
    id: string;
    label: string;
    type: string;
    fields: {id: string}[];
}

export interface GenericMap {
    [key: string]: string | boolean | number | undefined;
}

export interface UEFormProps {
    fieldGroups?: FieldGroupProps;
    initalValues?: GenericMap;
    account?: GenericMap;
    preSubmitMutateFn?: (fieldGroup: FieldGroupProps, values: GenericMap) => GenericMap;
    fieldAdjustments?: any;
    formId: string;
    messages?: GenericMap;
    loadingImg?: string;
    loadingNode?: React.ReactNode;
    formType?: string;
    postSubmitFn?: (response: GenericMap) => void;
    form?: any;
    autoComplete?: string;
    customEndpointUrl?: string;
}

export const UEFormWrapper = styled.div`
    ${ButtonWrap} {
        button.mkt-btn {
            border-radius: 0;
            padding: 8px 16px;
            margin: 16px;
            max-width: 300px;
            text-align: center;
            font-size: 1.1em;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
            font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
            border: 2px solid #fff;
            display: inline-block;
            min-height: 48px;
        }

        button.mkt-btn.mkt-pri-btn {
            background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.default};
            border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.default};
            color: ${(p) => p.theme.ue.colors.buttons.primary.text.default};
            &:hover,
            &:focus,
            &:active {
                &:not([disabled]) {
                    background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.hover};
                    border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.hover};
                    color: ${(p) => p.theme.ue.colors.buttons.primary.text.hover};
                }
            }
            &[disabled] {
                color: ${(p) => p.theme.ue.colors.buttons.primary.text.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.primary.border.disabled};
                background-color: ${(p) => p.theme.ue.colors.buttons.primary.background.disabled};
            }
        }

        button.mkt-btn.mkt-sec-btn {
            background-color: transparent;
            border-color: white;
            color: white;

            &:hover,
            &:active {
                &:not([disabled]) {
                    background-color: white;
                    border-color: white;
                    color: black;
                }
            }

            &[disabled] {
                background-color: ${(p) => p.theme.ue.colors.buttons.tertiary.background.disabled};
                border-color: ${(p) => p.theme.ue.colors.buttons.tertiary.border.disabled};
                color: ${(p) => p.theme.ue.colors.buttons.tertiary.text.disabled};
            }
        }
    }

    .successWrap h3 {
        font-size: 1.7em;
    }

    .checkbox {
        text-align: left;
    }

    label {
        max-width: calc(100% - 32px);
    }
    textarea {
        min-height: 50px;
    }
`;

export const UEForm = (props: UEFormProps): JSX.Element => {
    const getMessage = useLocalization();
    const {account} = useNewsletterAccount();
    const isLoggedIn = account?.isLoggedIn;
    const cabinedMode = account?.cabinedMode;
    const {result: accountPolicy} = useAccountAODCPolicy();
    const aodcAccountPolicyFailed = accountPolicy?.AODCFailed;
    const failTitle = getMessage('epic.ue.signup.newsletter.not.allowed.immediate.title') || '';
    const failMessage = getMessage('epic.ue.signup.newsletter.not.allowed.immediate.message') || '';
    const preSubmitMutateFn = props.preSubmitMutateFn;

    const finalPreSubmitMutateFn = preSubmitMutateFn
        ? (fieldGroups, finalValues) => preSubmitMutateFn(fieldGroups, finalValues)
        : null;

    return (
        <UEFormWrapper>
            {aodcAccountPolicyFailed || cabinedMode || !isLoggedIn ? (
                <FeatureUnavailable failTitle={failTitle} failMessage={failMessage} />
            ) : (
                <MarketoForm
                    autoComplete="off"
                    {...props}
                    preSubmitMutateFn={finalPreSubmitMutateFn}
                />
            )}
        </UEFormWrapper>
    );
};
