import styled from 'styled-components';

export const TagContainer = styled.div`
    font-size: 16px;
    border-radius: 8px;
    background-color: ${(p) =>
        p.theme.ifLight(
            p,
            p.theme.palette.background.defaultShade(0.05),
            p.theme.palette.background.defaultShade(0.1)
        )};
    margin-bottom: 20px;
    height: 45px;
    color: ${(p) => p.theme.ifLight(p, p.theme.palette.text.primary, p.theme.palette.text.hint)};
    line-height: 45px;
    max-width: 400px;
    text-align: center;
    display: inline-block;
    &.has-link {
        cursor: pointer;
    }
    &.inverted {
        background-color: ${(p) => p.theme.ue.colors.cards.inverted.background};
        color: ${(p) => p.theme.palette.grey['900']};
    }
    &.overlay {
        &:not(.handheld) {
            backdrop-filter: blur(20px);
        }
        background-color: ${(p) => p.theme.ue.colors.cards.overlay.background};
    }
`;

export const TagContent = styled.div`
    padding: 0 20px;
    p,
    span {
        color: ${(p) =>
            p.theme.ifLight(p, 'theme.palette.text.primary', 'theme.palette.text.secondary')};
    }
`;
