import {useState, useMemo, useCallback} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import LauncherIntegrationUtils from 'epic-launcher-integration/app/scripts/utils/LauncherIntegrationUtils';
import root from 'window-or-global';
import {isError} from '@epic-mw/error-types';

export const purchaseSubmissionKey = createSharedValueKey('purchaseSubmission', {});

export const usePurchase = (
    namespace: string,
    offerId: string,
    onSuccess: () => void,
    onPurchaseRejected: () => void
): {
    loaded: boolean;
    response: any;
    purchase: () => Promise<void>;
    loading: boolean;
    error?: string;
} => {
    const [purchaseSubmission, setPurchaseSubmission] = useSharedValue(purchaseSubmissionKey);

    if (!root.__server_side_render) {
        LauncherIntegrationUtils.initWebIntegration();
    }

    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const purchase = useCallback(async () => {
        if (!namespace) {
            throw new Error('Invalid namespace. Purchase will fail');
        }
        if (!offerId) {
            throw new Error('Invalid offerId. Purchase will fail');
        }

        try {
            setLoadingState({loading: true, loaded: false});

            LauncherIntegrationUtils.requestPurchase(
                namespace,
                offerId,
                1,
                onSuccess,
                onPurchaseRejected,
                null,
                '0aaff1'
            );

            setPurchaseSubmission({});

            setLoadingState({loading: false, loaded: true});
        } catch (ex) {
            console.error('Error hit opening purchase flow', ex);
            const error = (isError(ex) && ex.message) || '';
            setLoadingState({loading: false, loaded: true, error});
        }
    }, [namespace, offerId, purchaseSubmission, loadingState]);

    return useMemo(() => {
        return {
            response: purchaseSubmission,
            ...loadingState,
            purchase
        };
    }, [namespace, offerId, purchaseSubmission, loadingState.loading, loadingState.loaded]);
};
