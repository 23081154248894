import React from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import {getInitialCmsPageSharedValues, PageData, useCmsData} from 'epic-ue-shared';
import {Branding} from '../components/blank/BlankContent.styles';
import ErrorPage from './ErrorPage';

const cmsUrlPattern = '/branding';

interface BrandingPageData extends PageData {
    content: string;
}

const BrandingPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<BrandingPageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const content = pageData.content || '';

    return (
        <Page>
            <div className="container">
                {getHelmetForMetaTags(pageData)}
                <Branding
                    className={`cms-page-content branding container`}
                    dangerouslySetInnerHTML={{__html: content}}
                />
            </div>
        </Page>
    );
};

BrandingPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default BrandingPage;
