import React, {useEffect, useRef, useState} from 'react';
import root from 'window-or-global';
import {extractSlugLocale} from '@epic-core/localization';
import {generateRoutePath} from '@epic-core/common';
import {useNavigate, useLocation} from 'react-router-dom';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';

interface Props {
    children?: React.ReactNode;
}

export const ScrollToTop = ({children}: Props): JSX.Element | null => {
    const {pathname} = useLocation();
    const [prevPathname, setPrevPathname] = useState('');

    const navigate = useNavigate();
    const {pageSlug} = extractSlugLocale(pathname, '');

    useEffect(() => {
        if (root.unrealEngine_redirects && root.unrealEngine_redirects[pageSlug]) {
            //CMS redirect
            navigate(generateRoutePath(root.unrealEngine_redirects[pageSlug]));
        }
    }, [pageSlug, navigate]);

    const mounted = useRef<boolean>();
    useEffect(() => {
        if (!mounted.current) {
            // componentDidMount
            mounted.current = true;
        } else {
            // componentDidUpdate

            if (prevPathname !== pathname) {
                //ROUTE CHANGE HAS OCCURRED
                emitEvent({
                    eventAction: 'client.route',
                    interactionType: 'route',
                    eventLabel: pageSlug
                });

                setPrevPathname(pathname);
                root.scrollTo(0, 0);
            }
        }
    }, [prevPathname, pathname]);

    return <>{children}</>;
};

export default ScrollToTop;
