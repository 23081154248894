import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import {H3} from 'epic-ue-components';
import {SectionCopy, SectionImage, SectionContent} from './Section.styles';
import CommonButtonWrapper from '../../global/CommonButtonWrapper';

const SectionWrapper = styled.div`
    margin: 20px 0 20px;
    min-height: 450px;
    position: relative;
    overflow: hidden;

    ${p => p.theme.breakpoints.down('sm')} {
        margin: 0;
    }

    .background-video.video .overlay {
        background: rgba(0, 0, 0, 0);
    }
`;

const TwoColSection = styled.div`
    margin-bottom: 40px;
    ${p => p.theme.breakpoints.down('md')} {
        margin: 0;
        padding: 0;
        width: 100%;
    }
    ${p => p.theme.breakpoints.down('lg')} {
        padding: 0;
    }
`;

const TwoColContent = styled(SectionContent)`

    padding: 30px 50px 50px;
    ${p => p.theme.breakpoints.down('sm')} {
        > div,
        h3 {
            text-align: center;
        }
    }
    [${p => p.theme.breakpoints.between('sm', 'lg')}]: {
        padding: 20px 40px 40px;
        h3 {
            margin-top: 10px;
        }
    }
    `;

const SectionTwoCol = ({even, title, copy, buttonData, bgType, mobile, tablet}) => {
    const sectionContent = (
        <TwoColContent key={`2col-${title}`}>
            <H3>{title}</H3>
            <SectionCopy dangerouslySetInnerHTML={{__html: copy}} className="copy" />
            <CommonButtonWrapper sectionId="section-two-col" {...buttonData} />
        </TwoColContent>
    );
    const sectionImage = (
        <SectionImage key={`2col-${title}-image`} className="image">
            {bgType}
        </SectionImage>
    );
    const contentAlignArray = [];
    if (!mobile && !tablet && !even) {
        contentAlignArray.push(sectionContent, sectionImage);
    } else if (even || mobile || tablet) {
        contentAlignArray.push(sectionImage, sectionContent);
    }
    return (
        <TwoColSection className={`container ${even ? 'even' : ''}`}>
            <div className="row">
                <SectionWrapper>{contentAlignArray}</SectionWrapper>
            </div>
        </TwoColSection>
    );
};

SectionTwoCol.propTypes = {
    even: pt.bool,
    mobile: pt.bool,
    tablet: pt.bool,
    title: pt.string,
    copy: pt.string,
    buttonData: pt.object,
    bgType: pt.element
};

export default SectionTwoCol;
