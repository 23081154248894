import styled from 'styled-components';

export const DSWrapper = styled.section`
    min-height: 800px !important;

    @media only screen and (max-width: 991px) {
        min-height: auto !important;
        padding: 5px;
    }

    .ds-header {
        margin: 80px auto 40px;
        text-align: center;
        width: 100%;
    }

    .ds-header .h2 {
        padding-bottom: 40px;
    }

    .datasmith-quote {
        width: 100%;
        padding: 40px 0;
        clear: both;
        margin: 0 auto;
        text-align: center;
        max-width: 500px;
        opacity: 0;
        height: auto;
        min-height: 180px;
    }

    .datasmith-quote .h2 {
        font-family: OpenSans, sans-serif;
        font-size: 1em;
        line-height: 1.3em;
        font-weight: 400;
    }

    .datasmith-quote .h2 span {
        font-size: 12px;
        display: block;
        margin-top: 15px;
    }

    @media only screen and (max-width: 991px) {
        .datasmith-quote {
            max-width: 500px;
            margin: 0 auto;
        }
    }

    .datasmith-quote .h2 {
        margin-top: -200px;
        max-width: 500px;
    }

    .datasmith-quote .h2 span {
        font-size: 12px;
        display: block;
        margin-top: 15px;
    }

    .ribbon {
        width: 80vw;
        height: 800px;
        position: relative;
        overflow: visible;
        float: right;
        margin-top: 0;
    }

    @media only screen and (max-width: 991px) {
        .ribbon {
            height: 525px;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon {
            margin: 0 0 80px 0;
        }
    }

    @media only screen and (max-width: 991px) {
        .ribbon {
            float: none;
            margin: 0 auto;
            width: 100%;
        }
    }

    .ribbon:after {
        content: '';
        position: absolute;
        left: 300px;
        top: 266px;
        height: 197px;
        background: #ccc;
        width: 0;
    }

    @media only screen and (max-width: 991px) {
        .ribbon:after {
            display: none;
        }
    }

    .ribbon .container1,
    .ribbon .container2,
    .ribbon .container3 {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .ribbon .container1 {
        width: 100%;
        left: -100px;
        top: -36px;
        position: relative;
        z-index: 6;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 {
            background: #b0effe;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            padding: 20px;
            width: 10em;
            height: 31em;
        }

        .ribbon .container1:before,
        .ribbon .container1:after {
            content: '';
            left: 50px;
            position: absolute;
            bottom: -82px;
            transform: rotate(-63deg);
            z-index: 1;
            background: #b0effe;
            width: 8em;
            height: 180px;
        }
    }

    @media only screen and (max-width: 991px) and (max-width: 767px) {
        .ribbon .container1:before,
        .ribbon .container1:after {
            left: 52px;
            transform: rotate(-64deg);
        }
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1:after {
            left: auto;
            right: 51px;
            transform: rotate(63deg);
        }
    }

    @media only screen and (max-width: 991px) and (max-width: 767px) {
        .ribbon .container1:after {
            transform: rotate(64deg);
        }
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 .ribbonItem {
            font-size: 0.85em;
            height: 100%;
            box-shadow: 3px 4px 9px 0 #5881b4;
            line-height: 1em;
            padding: 25% 1em;
        }
    }

    .ribbon .container1 .datasmith-title {
        display: block;
        width: 100%;
        color: #26c9ff;
        text-align: center;
        font-family: Brutal_Bold, sans-serif;
        font-size: 1.5em;
        text-transform: uppercase;
        padding-bottom: 20px;
    }

    .ribbon .container1 .ribbon-info {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
    }

    .ribbon .container1 .ribbon-info.has-active-ribbon {
        pointer-events: all;
    }

    .ribbon .container1 .ribbon-info > div {
        display: none;
    }

    .ribbon .container1 .ribbon-info > .active-ribbon {
        display: block;
        visibility: visible;
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        right: auto;
        left: 0;
        top: 0;
        width: 100%;
        height: 31em;
        z-index: 4;
    }

    .ribbon .container1 .ribbon-info > .active-ribbon:before,
    .ribbon .container1 .ribbon-info > .active-ribbon:after {
        content: '';
        left: 46px;
        position: absolute;
        bottom: -4.6em;
        transform: rotate(-64deg);
        z-index: 1;
        width: 8em;
        height: 11.2em;
    }

    .ribbon .container1 .ribbon-info > .active-ribbon:after {
        left: auto;
        right: 46px;
        transform: rotate(64deg);
    }

    .ribbon .container1 .ribbon-info > .active-ribbon > .desc {
        opacity: 1;
        z-index: 6;
    }

    @media only screen and (max-width: 767px) {
        .ribbon .container1 .ribbon-info > .active-ribbon > .desc * {
            max-width: 240px;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon-info > .active-ribbon > .desc {
            width: 100%;
            visibility: visible;
            left: 0;
            top: 0;
        }
    }

    .ribbon .container1 .ribbon-info > .active-ribbon > .desc i {
        visibility: visible;
        position: absolute;
        pointer-events: all;
        right: 20px;
        font-size: 1em;
        color: #fff;
        top: 20px;
        width: 25px;
        height: 25px;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 {
            width: 320px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .ribbon .container1 > div {
        height: 100px;
        line-height: 100px;
        font-family: Brutal_Medium, sans-serif;
        z-index: 4;
        position: relative;
        left: 38%;
        cursor: pointer;
        text-align: center;
        width: 200px;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 > div {
            float: left;
            width: 49%;
            height: 100px;
            left: 0;
        }
    }

    @media screen and (max-width: 320px) {
        .ribbon .container1 > div {
            width: 123px;
        }
    }

    .ribbon .container1 > div .desc {
        position: absolute;
        left: 200px;
        width: 0;
        height: 300px;
        z-index: 5;
        top: 0;
        visibility: hidden;
        opacity: 0;
        padding: 17px 40px;
        text-align: left;
    }

    .ribbon .container1 > div .desc h3 {
        width: 425px;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 > div .desc h3 {
            width: 300px;
        }
    }

    .ribbon .container1 > div .desc h4 {
        margin: 20px 0;
        font-size: 14px;
        opacity: 0;
        width: 425px;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 > div .desc h4 {
            width: 300px;
        }
    }

    .ribbon .container1 > div .desc h4 span {
        font-weight: normal;
        font-family: OpenSans, sans-serif;
    }

    .ribbon .container1 > div .desc p {
        opacity: 0;
        font-size: 14px;
        font-family: OpenSans, sans-serif;
        line-height: 24px;
        width: 425px;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .container1 > div .desc p {
            width: 300px;
        }
    }

    .ribbon .container1 .ribbon1 {
        background: #e9292f;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon1 .desc,
        .ribbon .container1 .ribbon1 .desc:before,
        .ribbon .container1 .ribbon1 .desc:after {
            background: #e9292f;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon1.active-ribbon,
        .ribbon .container1 .ribbon1.active-ribbon:before,
        .ribbon .container1 .ribbon1.active-ribbon:after {
            background: #e9292f;
        }
    }

    .ribbon .container1 .ribbon2 {
        background: #151c24;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon2 .desc,
        .ribbon .container1 .ribbon2 .desc:before,
        .ribbon .container1 .ribbon2 .desc:after {
            background: #151c24;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon2.active-ribbon,
        .ribbon .container1 .ribbon2.active-ribbon:before,
        .ribbon .container1 .ribbon2.active-ribbon:after {
            background: #151c24;
        }
    }

    .ribbon .container1 .ribbon3 {
        background: #676767;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon3 .desc,
        .ribbon .container1 .ribbon3 .desc:before,
        .ribbon .container1 .ribbon3 .desc:after {
            background: #676767;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon3.active-ribbon,
        .ribbon .container1 .ribbon3.active-ribbon:before,
        .ribbon .container1 .ribbon3.active-ribbon:after {
            background: #676767;
        }
    }

    .ribbon .container1 .ribbon4 {
        background: #004c66;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon4 .desc,
        .ribbon .container1 .ribbon4 .desc:before,
        .ribbon .container1 .ribbon4 .desc:after {
            background: #004c66;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon4.active-ribbon,
        .ribbon .container1 .ribbon4.active-ribbon:before,
        .ribbon .container1 .ribbon4.active-ribbon:after {
            background: #004c66;
        }
    }

    .ribbon .container1 .ribbon5 {
        background: #fa9104;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon5 .desc,
        .ribbon .container1 .ribbon5 .desc:before,
        .ribbon .container1 .ribbon5 .desc:after {
            background: #fa9104;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon5.active-ribbon,
        .ribbon .container1 .ribbon5.active-ribbon:before,
        .ribbon .container1 .ribbon5.active-ribbon:after {
            background: #fa9104;
        }
    }

    .ribbon .container1 .ribbon6 {
        background: #9882f0;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon6 .desc,
        .ribbon .container1 .ribbon6 .desc:before,
        .ribbon .container1 .ribbon6 .desc:after {
            background: #9882f0;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon6.active-ribbon,
        .ribbon .container1 .ribbon6.active-ribbon:before,
        .ribbon .container1 .ribbon6.active-ribbon:after {
            background: #9882f0;
        }
    }

    .ribbon .container1 .ribbon7 {
        background: #00b2b2;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon7 .desc,
        .ribbon .container1 .ribbon7 .desc:before,
        .ribbon .container1 .ribbon7 .desc:after {
            background: #00b2b2;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon7.active-ribbon,
        .ribbon .container1 .ribbon7.active-ribbon:before,
        .ribbon .container1 .ribbon7.active-ribbon:after {
            background: #00b2b2;
        }
    }

    .ribbon .container1 .ribbon8 {
        background: #a3b200;
    }

    @media only screen and (min-width: 992px) {
        .ribbon .container1 .ribbon8 .desc,
        .ribbon .container1 .ribbon8 .desc:before,
        .ribbon .container1 .ribbon8 .desc:after {
            background: #a3b200;
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .container1 .ribbon8.active-ribbon,
        .ribbon .container1 .ribbon8.active-ribbon:before,
        .ribbon .container1 .ribbon8.active-ribbon:after {
            background: #a3b200;
        }

        .ribbon .container1 .ribbon8 .ribbonItem {
            line-height: 1em;
            padding-top: 25%;
        }
    }

    .ribbon .container2 {
        width: 100%;
        left: 100px;
        top: -36px;
        transform-origin: left center;
        transform: matrix3d(0.8, 0, 0, 0.005, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
        z-index: 0;
    }

    .ribbon .container2 > div {
        height: 100px;
        width: 0;
        position: relative;
    }

    .ribbon .container2 > div:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
    }

    .ribbon .container3 {
        width: 300px;
        left: 220px;
        overflow: hidden;
        top: 263px;
    }

    @media only screen and (min-width: 1440px) {
        .ribbon .container3 {
            width: 350px;
        }
    }

    @media screen and (min-width: 1600px) {
        .ribbon .container3 {
            width: 500px;
        }
    }

    @media screen and (min-width: 1700px) and (max-width: 2200px) {
        .ribbon .container3 {
            width: 400px;
        }
    }

    @media screen and (min-width: 1250px) and (max-width: 1350px) {
        .ribbon .container3 {
            left: 216px;
            top: 255px;
        }
    }

    .ribbon .container3 > div {
        height: 25px;
        width: 0;
        position: relative;
    }

    @media screen and (min-width: 1250px) and (max-width: 1350px) {
        .ribbon .container3 > div {
            height: 27px;
        }
    }

    .ribbon .container3 > div:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
    }

    .ribbon .container2 .ribbon1 {
        background: #fce2e3;
    }

    .ribbon .container2 .ribbon1:after {
        background: #e9292f;
    }

    .ribbon .container2 .ribbon2 {
        background: #6280a3;
    }

    .ribbon .container2 .ribbon2:after {
        background: #151c24;
    }

    .ribbon .container2 .ribbon3 {
        background: #cdcdcd;
    }

    .ribbon .container2 .ribbon3:after {
        background: #676767;
    }

    .ribbon .container2 .ribbon4 {
        background: #33cbff;
    }

    .ribbon .container2 .ribbon4:after {
        background: #004c66;
    }

    .ribbon .container2 .ribbon5 {
        background: #fee9cc;
    }

    .ribbon .container2 .ribbon5:after {
        background: #fa9104;
    }

    .ribbon .container2 .ribbon6 {
        background: #e3ddfb;
    }

    .ribbon .container2 .ribbon6:after {
        background: #9882f0;
    }

    .ribbon .container2 .ribbon7 {
        background: #7fffff;
    }

    .ribbon .container2 .ribbon7:after {
        background: #00b2b2;
    }

    .ribbon .container2 .ribbon8 {
        background: #f4ff7f;
    }

    .ribbon .container2 .ribbon8:after {
        background: #a3b200;
    }

    .ribbon .container3 .ribbon1 {
        background: #f38589;
        background: linear-gradient(to right, #f7b4b6 0%, white 100%);
    }

    .ribbon .container3 .ribbon1:after {
        background: #e9292f;
    }

    .ribbon .container3 .ribbon2 {
        background: #3b4e64;
        background: linear-gradient(to right, #4d6785 0%, #e3e8ee 100%);
    }

    .ribbon .container3 .ribbon2:after {
        background: #151c24;
    }

    .ribbon .container3 .ribbon3 {
        background: #9a9a9a;
        background: linear-gradient(to right, #b3b3b3 0%, white 100%);
    }

    .ribbon .container3 .ribbon3:after {
        background: #676767;
    }

    .ribbon .container3 .ribbon4 {
        background: #0098cc;
        background: linear-gradient(to right, #00beff 0%, white 100%);
    }

    .ribbon .container3 .ribbon4:after {
        background: #004c66;
    }

    .ribbon .container3 .ribbon5 {
        background: #fdbd67;
        background: linear-gradient(to right, #fdd39a 0%, white 100%);
    }

    .ribbon .container3 .ribbon5:after {
        background: #fa9104;
    }

    .ribbon .container3 .ribbon6 {
        background: #e3ddfb;
        background: linear-gradient(to right, #e3ddfb 0%, white 100%);
    }

    .ribbon .container3 .ribbon6:after {
        background: #9882f0;
    }

    .ribbon .container3 .ribbon7 {
        background: #19ffff;
        background: linear-gradient(to right, #4cffff 0%, white 100%);
    }

    .ribbon .container3 .ribbon7:after {
        background: #00b2b2;
    }

    .ribbon .container3 .ribbon8 {
        background: #ecff19;
        background: linear-gradient(to right, #f0ff4c 0%, white 100%);
    }

    .ribbon .container3 .ribbon8:after {
        background: #a3b200;
    }

    .ribbon .datasmith-container {
        height: 250px;
        width: 0;
        opacity: 0;
        z-index: 2;
        position: absolute;
        top: calc(300px - 60px);
        left: calc(17.5% + 300px);
        color: #26c9ff;
        font-family: Brutal_Bold, sans-serif;
        font-size: 1.5em;
        text-align: center;
        line-height: 250px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 1439px) {
        .ribbon .datasmith-container {
            left: 275px;
        }
    }

    @media only screen and (min-width: 1440px) {
        .ribbon .datasmith-container {
            left: 350px;
        }
    }

    @media screen and (min-width: 1600px) {
        .ribbon .datasmith-container {
            left: calc(17.5% + 300px);
        }
    }

    @media screen and (min-width: 1700px) and (max-width: 2200px) {
        .ribbon .datasmith-container {
            left: 453px;
        }
    }

    .ribbon .datasmith-container .datasmith-visual-bg {
        background: #bbf8ff;
        height: 100%;
        width: 100%;
    }

    .ribbon .datasmith-container span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }

    .ribbon .ue-payoff {
        position: relative;
        width: 100%;
        text-align: center;
        margin: 90px auto 0;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .ue-payoff {
            margin-top: 30px;
        }
    }

    .ribbon .ue-payoff i {
        position: relative;
        right: auto;
        top: 10px;
        visibility: visible;
        font-size: 6em;
        color: #333;
    }

    .ribbon .small-logo,
    .ribbon .full-logo {
        width: 400px;
        height: 100px;
        position: absolute;
        right: 100px;
        top: 320px;
    }

    @media screen and (min-width: 1900px) {
        .ribbon .small-logo,
        .ribbon .full-logo {
            left: calc(20% + 600px);
        }
    }

    @media only screen and (max-width: 1199px) {
        .ribbon .small-logo,
        .ribbon .full-logo {
            right: -80px;
        }
    }

    @media only screen and (max-width: 991px) {
        .ribbon .small-logo,
        .ribbon .full-logo {
            right: -120px;
        }
    }

    @media only screen and (max-width: 767px) {
        .ribbon .small-logo,
        .ribbon .full-logo {
            visibility: hidden;
        }
    }

    .ribbon .small-logo i,
    .ribbon .full-logo i {
        opacity: 0;
        font-size: 7em;
        color: #333;
        z-index: 2;
        position: absolute;
        top: 0;
    }

    @media only screen and (max-width: 991px) {
        .ribbon .small-logo i,
        .ribbon .full-logo i {
            opacity: 1;
        }
    }

    .ribbon .small-logo i:after,
    .ribbon .full-logo i:after {
        content: '';
        background-color: rgba(204, 204, 204, 0.6);
        width: 100px;
        height: 30px;
        position: absolute;
        left: -20%;
        top: 180px;
        transform: rotateZ(45deg);
        opacity: 0;
    }

    @media screen and (min-width: 1000px) and (max-width: 1400px) {
        .ribbon .small-logo i:after,
        .ribbon .full-logo i:after {
            top: 20px;
            width: 100px;
            left: -150%;
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1400px) {
        .ribbon .small-logo {
            display: block !important;
        }
    }

    @media screen and (max-width: 1300px) {
        .ribbon .small-logo i {
            left: 50px;
        }
    }

    .ribbon .full-logo i {
        font-size: 25em;
        top: -150px;
        left: 0;
    }

    @media screen and (min-width: 900px) and (max-width: 1400px) {
        .ribbon .full-logo {
            display: none !important;
        }
    }

    &.runAnimation .container1 > div:hover .desc {
        width: 500px;
        visibility: visible;
        opacity: 1;
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container1 > div:hover .desc {
            transition: all ease 0.5s;
        }
    }

    @media only screen and (max-width: 991px) {
        &.runAnimation .container1 > div:hover .desc {
            width: 375px;
        }
    }

    &.runAnimation .container1 > div:hover .desc h4,
    &.runAnimation .container1 > div:hover .desc p {
        opacity: 1;
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container1 > div:hover .desc h4,
        &.runAnimation .container1 > div:hover .desc p {
            transition: all ease 1s;
        }
    }

    @media only screen and (max-width: 991px) {
        &.runAnimation .container1 .active-ribbon {
            z-index: 8;
        }

        &.runAnimation .container1 .active-ribbon .desc,
        &.runAnimation .container1 .active-ribbon h4,
        &.runAnimation .container1 .active-ribbon p {
            opacity: 1 !important;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .datasmith-quote {
            opacity: 1;
            transition: opacity ease 1s 2.5s;
        }

        &.runAnimation .ribbon:after {
            width: 100%;
            transition: width ease 0.5s 2s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='1'] .container2 .ribbon1:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='1'] .container3 .ribbon1:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='2'] .container2 .ribbon2:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='2'] .container3 .ribbon2:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='3'] .container2 .ribbon3:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='3'] .container3 .ribbon3:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='4'] .container2 .ribbon4:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='4'] .container3 .ribbon4:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='5'] .container2 .ribbon5:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='5'] .container3 .ribbon5:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='6'] .container2 .ribbon6:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='6'] .container3 .ribbon6:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='7'] .container2 .ribbon7:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='7'] .container3 .ribbon7:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='8'] .container2 .ribbon8:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 1s 0.5s;
        }

        &.runAnimation .ribbon[data-ribbonanimate='8'] .container3 .ribbon8:after {
            animation: zip cubic-bezier(1, 0, 0, 1) 0.5s 1s;
        }

        &.runAnimation .small-logo,
        &.runAnimation .full-logo {
            transition: all 0.3s ease;
        }

        &.runAnimation .small-logo i,
        &.runAnimation .full-logo i {
            opacity: 1;
            transition: opacity ease 1s 2.2s;
        }

        &.runAnimation .small-logo i:after,
        &.runAnimation .full-logo i:after {
            animation: shimmer 10s 11s infinite;
        }
    }

    @media only screen and (min-width: 992px) and (min-width: 1000px) and (max-width: 1400px) {
        &.runAnimation .small-logo i:after,
        &.runAnimation .full-logo i:after {
            animation: shimmerSmall 10s 11s infinite;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .datasmith-container {
            height: 250px;
            width: 250px;
            opacity: 1;
            transition: all ease 0.5s 1.3s;
        }

        &.runAnimation .datasmith-container .datasmith-visual-bg {
            animation: pulse 2s 2s infinite;
        }

        &.runAnimation .container1 {
            left: -100px;
        }

        &.runAnimation .container1 > div:nth-child(0) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.2s forwards;
        }

        &.runAnimation .container1 > div:nth-child(1) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.4s forwards;
        }

        &.runAnimation .container1 > div:nth-child(2) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.6s forwards;
        }

        &.runAnimation .container1 > div:nth-child(3) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.7s forwards;
        }

        &.runAnimation .container1 > div:nth-child(4) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.8s forwards;
        }

        &.runAnimation .container1 > div:nth-child(5) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 0.9s forwards;
        }

        &.runAnimation .container1 > div:nth-child(6) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 1.1s forwards;
        }

        &.runAnimation .container1 > div:nth-child(7) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 1.2s forwards;
        }

        &.runAnimation .container1 > div:nth-child(8) {
            animation: slideInRibbon cubic-bezier(1, 0, 0, 1) 0.5s 1.4s forwards;
        }

        &.runAnimation .container2 > div:nth-child(0) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 0.7s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(0) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 0.8s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(1) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 0.8s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(1) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 0.9s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(2) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 0.9s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(2) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(3) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.1s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(3) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.2s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(4) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.2s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(4) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.3s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(5) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.3s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(5) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.4s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(6) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.4s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(6) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.5s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(7) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.5s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(7) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.6s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container2 > div:nth-child(8) {
            animation: growAngle cubic-bezier(1, 0, 0, 1) 0.5s 1.8s forwards;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        &.runAnimation .container2 > div:nth-child(8) {
            animation: growAngleSmall cubic-bezier(1, 0, 0, 1) 0.5s 1.8s forwards;
        }
    }

    @media only screen and (min-width: 992px) {
        &.runAnimation .container3 > div:nth-child(0) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 0.9s forwards;
        }

        &.runAnimation .container3 > div:nth-child(1) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1s forwards;
        }

        &.runAnimation .container3 > div:nth-child(2) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.1s forwards;
        }

        &.runAnimation .container3 > div:nth-child(3) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.2s forwards;
        }

        &.runAnimation .container3 > div:nth-child(4) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.3s forwards;
        }

        &.runAnimation .container3 > div:nth-child(5) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.4s forwards;
        }

        &.runAnimation .container3 > div:nth-child(6) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.5s forwards;
        }

        &.runAnimation .container3 > div:nth-child(7) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.6s forwards;
        }

        &.runAnimation .container3 > div:nth-child(8) {
            animation: grow cubic-bezier(1, 0, 0, 1) 0.5s 1.8s forwards;
        }
    }

    &[data-ribboncount='4'] .ribbon:after {
        height: 100px;
    }

    &[data-ribboncount='4'] .small-logo,
    &[data-ribboncount='4'] .full-logo {
        top: 270px;
    }

    &[data-ribboncount='4'] .datasmith-container {
        top: 190px;
    }

    &[data-ribboncount='4'] .container1,
    &[data-ribboncount='4'] .container2 {
        top: 113px;
    }

    &[data-ribboncount='4'] .container1 > div:last-child .desc,
    &[data-ribboncount='4'] .container1 > div:nth-child(3) .desc {
        top: auto;
        bottom: 0;
    }

    &[data-ribboncount='4'] .container1 > div:nth-child(3) .desc {
        bottom: -100px;
    }

    @media only screen and (max-width: 991px) {
        &[data-ribboncount='4'] .container1 {
            height: calc(100px * 3);
        }
    }

    &[data-ribboncount='4'] .container1 .ribbon-info > .active-ribbon > .desc {
        height: calc(100px * 3);
    }

    &[data-ribboncount='5'] .ribbon:after {
        height: 120px;
    }

    &[data-ribboncount='5'] .small-logo,
    &[data-ribboncount='5'] .full-logo {
        top: 280px;
    }

    &[data-ribboncount='5'] .datasmith-container {
        top: 200px;
    }

    &[data-ribboncount='5'] .container1,
    &[data-ribboncount='5'] .container2 {
        top: 76px;
    }

    &[data-ribboncount='5'] .container1 > div:last-child .desc,
    &[data-ribboncount='5'] .container1 > div:nth-child(4) .desc {
        top: auto;
        bottom: 0;
    }

    &[data-ribboncount='5'] .container1 > div:nth-child(4) .desc {
        bottom: -100px;
    }

    @media only screen and (max-width: 991px) {
        &[data-ribboncount='5'] .container1 {
            height: calc(100px * 4);
        }
    }

    &[data-ribboncount='5'] .container1 .ribbon-info > .active-ribbon > .desc {
        height: calc(100px * 4);
    }

    &[data-ribboncount='6'] .ribbon:after {
        height: 147px;
    }

    &[data-ribboncount='6'] .small-logo,
    &[data-ribboncount='6'] .full-logo {
        top: 300px;
    }

    @media all and (max-width: 2000px) {
        &[data-ribboncount='6'] .small-logo,
        &[data-ribboncount='6'] .full-logo {
            top: 290px;
        }
    }

    @media only screen and (max-width: 1199px) {
        &[data-ribboncount='6'] .small-logo,
        &[data-ribboncount='6'] .full-logo {
            top: 280px;
        }
    }

    &[data-ribboncount='6'] .datasmith-container {
        top: 225px;
    }

    @media all and (max-width: 2000px) {
        &[data-ribboncount='6'] .datasmith-container {
            top: 220px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1300px) {
        &[data-ribboncount='6'] .datasmith-container {
            width: 200px;
            height: 200px;
            top: 240px;
        }
    }

    @media only screen and (max-width: 1199px) {
        &[data-ribboncount='6'] .datasmith-container {
            top: 210px;
        }
    }

    &[data-ribboncount='6'] .container1,
    &[data-ribboncount='6'] .container2 {
        top: 39px;
    }

    @media all and (min-width: 1300px) and (max-width: 1400px) {
        &[data-ribboncount='6'] .container3 {
            left: 217px;
        }
    }

    @media all and (min-width: 1200px) and (max-width: 1300px) {
        &[data-ribboncount='6'] .container3 {
            left: 213px;
            width: 80px;
        }
    }

    @media only screen and (max-width: 1199px) {
        &[data-ribboncount='6'] .container3 {
            left: 219px;
        }
    }

    &[data-ribboncount='6'] .container1 > div:last-child .desc,
    &[data-ribboncount='6'] .container1 > div:nth-child(5) .desc {
        top: auto;
        bottom: 0;
    }

    &[data-ribboncount='6'] .container1 > div:nth-child(5) .desc {
        bottom: -100px;
    }

    @media only screen and (max-width: 991px) {
        &[data-ribboncount='6'] .container1 {
            height: calc(100px * 4);
        }
    }

    &[data-ribboncount='6'] .container1 .ribbon-info > .active-ribbon {
        height: calc(100px * 4);
    }

    &[data-ribboncount='7'] .ribbon:after {
        height: 172px;
    }

    &[data-ribboncount='7'] .small-logo,
    &[data-ribboncount='7'] .full-logo {
        top: 300px;
    }

    &[data-ribboncount='7'] .datasmith-container {
        top: 225px;
    }

    &[data-ribboncount='7'] .container1,
    &[data-ribboncount='7'] .container2 {
        top: 0;
    }

    &[data-ribboncount='7'] .container1 > div:last-child .desc,
    &[data-ribboncount='7'] .container1 > div:nth-child(6) .desc {
        top: auto;
        bottom: 0;
    }

    &[data-ribboncount='7'] .container1 > div:nth-child(6) .desc {
        bottom: -100px;
    }

    @media only screen and (max-width: 991px) {
        &[data-ribboncount='7'] .container1 {
            height: calc(100px * 5);
        }
    }

    &[data-ribboncount='7'] .container1 .ribbon-info > .active-ribbon > .desc {
        height: calc(100px * 5);
    }

    &[data-ribboncount='8'] .ribbon:after {
        height: 197px;
    }

    &[data-ribboncount='8'] .small-logo,
    &[data-ribboncount='8'] .full-logo {
        top: 320px;
    }

    &[data-ribboncount='8'] .datasmith-container {
        top: 240px;
    }

    &[data-ribboncount='8'] .container1,
    &[data-ribboncount='8'] .container2 {
        top: -35px;
    }

    &[data-ribboncount='8'] .container1 > div:last-child .desc,
    &[data-ribboncount='8'] .container1 > div:nth-child(7) .desc {
        top: auto;
        bottom: 0;
    }

    &[data-ribboncount='8'] .container1 > div:nth-child(7) .desc {
        bottom: -100px;
    }

    @media only screen and (max-width: 991px) {
        &[data-ribboncount='8'] .container1 {
            height: calc(100px * 5);
        }
    }

    &[data-ribboncount='8'] .container1 .ribbon-info > .active-ribbon {
        height: calc(100px * 5);
    }

    @keyframes slideInRibbon {
        100% {
            left: 0;
        }
    }

    @keyframes growAngle {
        100% {
            width: 52%;
        }
    }

    @keyframes growAngleSmall {
        100% {
            width: 72%;
        }
    }

    @keyframes grow {
        100% {
            width: 100%;
        }
    }

    @keyframes zip {
        0% {
            width: 0;
        }

        70% {
            width: 100%;
        }

        100% {
            left: 2000px;
            opacity: 0;
        }
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(187, 248, 255, 0.8);
            transform: scale(1);
        }

        70% {
            box-shadow: 0 0 0 20px rgba(187, 248, 255, 0);
            transform: scale(1.1);
        }

        100% {
            box-shadow: 0 0 0 0 rgba(187, 248, 255, 0);
            transform: scale(1);
        }
    }

    @keyframes shimmer {
        0% {
            left: -20%;
        }

        5% {
            opacity: 1;
        }

        10% {
            left: 100%;
        }

        10.5% {
            opacity: 0;
        }

        11% {
            left: -20%;
        }

        99% {
            opacity: 0;
        }

        99.5% {
            left: -20%;
        }

        100% {
            opacity: 1;
            left: 100%;
        }
    }

    @keyframes shimmerSmall {
        0% {
            left: -150%;
        }

        5% {
            opacity: 1;
        }

        10% {
            left: 100%;
        }

        10.5% {
            opacity: 0;
            left: -150%;
        }

        99% {
            opacity: 0;
            left: -150%;
        }

        100% {
            opacity: 1;
            left: 100%;
        }
    }
`;
