import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';

const LogoWrapper = styled.div`
    ul {
        list-style: none;
        margin: 40px 0;
        ${(p) => p.theme.breakpoints.down('md')} {
            margin: 20px 0;
        }
        padding: 0;
        li {
            display: inline-block;
            height: 100px;
            opacity: 1;
            margin: 6px 0;
            ${(p) => p.theme.breakpoints.down('lg')} {
                height: 75px;
            }
            ${(p) => p.theme.breakpoints.down('md')} {
                height: 60px;
            }
            img {
                height: 100%;
                padding: 14px 30px;
                filter: grayscale(100%);
                ${(p) => p.theme.breakpoints.down('sm')} {
                    padding: 10px 20px;
                }
            }
            &.square img {
                padding: 5px 30px;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    padding: 2px 20px;
                }
            }
        }
    }
`;

const LogoListing = ({logos = []}) => {
    let logoListing = <li />;
    const staggerItems = [];
    if (logos !== undefined && logos.length) {
        logoListing = logos.map((logo, index) => {
            const i = index;
            return (
                <li
                    ref={(c) => {
                        staggerItems[i] = c;
                    }}
                    key={`s-${i}-${logo.title}`}
                    className={logo.isSquare ? 'square' : ''}>
                    <img src={logo.logoImage} alt={logo.title} />
                </li>
            );
        });
    }
    if (!logos.length) return <div />;

    return (
        <LogoWrapper>
            <ul>{logoListing}</ul>
        </LogoWrapper>
    );
};

LogoListing.propTypes = {
    logos: pt.array
};

export default LogoListing;
