import React from 'react';
import {H3, ButtonWrapper, BgMedia, isValidImage, isValidVideo} from '../../../elements';
import styled from 'styled-components';
import {BackgroundProps, ButtonProps} from 'epic-ue-shared';

const TwoColContent = styled.div`
    width: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
        padding: 20px 20px 0 20px;
        display: block;
    }
    padding: 30px 75px 50px;
    ${(p) => p.theme.breakpoints.down('md')} {
        height: auto;
        > div,
        h3 {
            text-align: center;
        }
    }

    ${(p) => p.theme.breakpoints.between('lg', 'xxl')} {
        &&& {
            padding: 0 40px;
            .col-content-wrapper h3.section-heading {
                margin-top: 0px;
            }
        }
    }

    .button-wrapper .btn {
        margin-left: 0px;
    }
`;

const SectionImage = styled.div`
    display: inline-block;
    position: relative;
    width: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    height: 0;
    padding-top: 28.12%;
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        margin: 0;
        display: block;
    }
    .type-image {
        margin-left: 15px;
        margin-right: 15px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin: 0;
        }
    }
`;

export interface TwoColSectionProps {
    title: string;
    copy: string;
    lbClass?: string;
    media: BackgroundProps;
    even: boolean;
    buttons?: ButtonProps[];
    mobile: boolean;
    tablet: boolean;
}

export const TwoColSection = ({
    title,
    copy,
    buttons,
    lbClass,
    media,
    mobile,
    tablet,
    even
}: TwoColSectionProps): JSX.Element => {
    const sectionContent: JSX.Element = (
        <TwoColContent key={`2col-${title}`} className="twocol-content">
            <div className="col-content-wrapper">
                {title ? (
                    <H3 className="section-heading" dangerouslySetInnerHTML={{__html: title}} />
                ) : (
                    ''
                )}
                {copy ? <div dangerouslySetInnerHTML={{__html: copy}} className="copy" /> : ''}
                <ButtonWrapper buttons={buttons} />
            </div>
        </TwoColContent>
    );

    const validMedia = isValidImage(media) || isValidVideo(media);

    let sectionImage: JSX.Element = <React.Fragment key={`2col-${title}-image`} />;
    if (validMedia) {
        sectionImage = (
            <SectionImage key={`2col-${title}-image`} className="image">
                <BgMedia autoplay={!media?.disableAutoplay} background={media} imgMaxWidth={1000} />
            </SectionImage>
        );
    }

    const contentAlignArray: JSX.Element[] = [];
    if (!mobile && !tablet && !even) {
        contentAlignArray.push(sectionContent, sectionImage);
    } else if (even || mobile || tablet) {
        contentAlignArray.push(sectionImage, sectionContent);
    }

    return <div className={`twocol-section ${lbClass ? lbClass : ''}`}>{contentAlignArray}</div>;
};
