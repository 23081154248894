import React from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';
import debounce from 'lodash.debounce';
import {getInnerWidth} from '@epic-core/common';

export default class FitText extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: null
        };

        this.debounceResize = debounce(this.onBodyResize, (300 + Math.random()) * 100);
    }

    static propTypes = {
        children: PropTypes.element.isRequired,
        getElement: PropTypes.func,
        compressor: PropTypes.number,
        minFontSize: PropTypes.number,
        maxFontSize: PropTypes.number,
        maxHeight: PropTypes.number
    };

    static defaultProps = {
        compressor: 1.0,
        minFontSize: 12,
        maxFontSize: 36
    };

    componentDidMount() {
        root.addEventListener('resize', this.debounceResize);
        setTimeout(() => {
            this.onBodyResize();
        }, 50);
    }

    componentWillUnmount() {
        clearTimeout(this._timeoutId);
        root.removeEventListener('resize', this.debounceResize);
    }

    onBodyResize = () => {
        const elementFn = this.props.getElement || null;
        if (elementFn) {
            const element = elementFn();
            if (element) {
                const width = getInnerWidth(element);

                const height = this.props.maxHeight || Number.POSITIVE_INFINITY;
                const maxDemension = height < width ? height : width;

                let fontSize = null;
                if (element.style) {
                    const size = Math.min(
                        maxDemension / (this.props.compressor * 10),
                        parseFloat(this.props.maxFontSize)
                    );
                    fontSize = Math.max(size, parseFloat(this.props.minFontSize));
                }

                if (this.state.fontSize !== fontSize) {
                    this.setState({fontSize});
                }
            }
        }
    };

    render() {
        const styles = {};
        if (this.state.fontSize) {
            styles.fontSize = this.state.fontSize;
        }

        return (
            <div className="fit-text" style={styles}>
                {React.cloneElement(this.props.children, {
                    style: styles
                })}
            </div>
        );
    }
}
