import React from 'react';
import PropTypes from 'prop-types';
import {draggingMotion} from '@epic-core/common';

let mouseDown = {};

const onMouseDown = e => {
    if (e) {
        mouseDown = {
            x: e.clientX,
            y: e.clientY
        };
    }
};

const onClick = e => {
    if (draggingMotion(e, mouseDown)) {
        e.preventDefault();
    }
};

export default class CustomerTalkCard extends React.PureComponent {
    static propTypes = {
        image: PropTypes.string,
        alt: PropTypes.string,
        date: PropTypes.string,
        tags: PropTypes.array,
        headline: PropTypes.string,
        presenterName: PropTypes.string,
        body: PropTypes.string,
        richBody: PropTypes.string,
        targetURL: PropTypes.string,
        room: PropTypes.string,
        locale: PropTypes.string
    };

    render() {
        const {
            alt,
            headline,
            body,
            targetURL,
            date,
            presenterName,
            tags,
            locale,
            room,
            richBody
        } = this.props;
        const image = this.props.image || '';
        const dateTime = date ? new Date(date) : null;

        let name = presenterName;
        if (name) {
            name = `${name}`;
        }

        return (
            <a
                className="block-talks-item"
                href={targetURL}
                onMouseDown={onMouseDown}
                onClick={onClick}>
                {image && <img alt={alt} className="img-responsive" src={image} />}
                <div className="block-talks-inner">
                    <div className="block-talks-title">{headline}</div>
                    <div className="block-talks-date">
                        {name && (
                            <div>
                                {name}
                                <br />
                            </div>
                        )}
                        {dateTime && (
                            <div>
                                {dateTime.toLocaleString(locale, {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric'
                                })}
                                <br />
                            </div>
                        )}
                        {room !== undefined ? `${room}` : ''}
                    </div>

                    {tags
                        ? tags.map((tag, index) => (
                              <div key={tag.text} className="block-talks-category">
                                  {tag.text}{' '}
                              </div>
                          ))
                        : null}
                    <div className="block-talks-text">
                        {richBody ? <div dangerouslySetInnerHTML={{__html: richBody}} /> : body}
                    </div>
                </div>
            </a>
        );
    }
}
