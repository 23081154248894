import React from 'react';
import {FeedItemProps} from '../../../types';
import {SimpleLink, H2} from 'epic-ue-components';

interface Properties {
    item: FeedItemProps;
    title: string;
    linkPath: string;
}

export const FeedHeading = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const {_feedSlug} = item;
    const linkPath = props.linkPath || '';
    const title = props.title || '';

    return (
        <H2>
            <SimpleLink href={linkPath} id={`feed-${_feedSlug}`} title={title}>
                <div dangerouslySetInnerHTML={{__html: title || ''}} />
            </SimpleLink>
        </H2>
    );
};
