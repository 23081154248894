import {NavTheme} from 'epic-games-nav-shared';
import {getOptImage} from 'epic-ue-shared';
import {useCurrentAccentColor} from 'epic-ue-theme';
import {getLocale} from '@epic-core/common';

export const getFrosty = (pathname?: string): boolean => {
    const isFrosty = Boolean(pathname && /\/wakeup/.test(pathname));
    return isFrosty;
};

export const getNavTheme = (pathname?: string): NavTheme => {
    const isFrosty = getFrosty(pathname);
    let ueH4Font = 'Fira_Mono_Regular,Brutal_Light,monospace';
    switch (getLocale()) {
        case 'ja':
            ueH4Font = 'Noto Sans JP, Noto-Regular, sans-serif';
            break;
        case 'ko':
            ueH4Font = 'Noto Sans KR, Noto-Regular, sans-serif';
            break;
        case 'zh-CN':
            ueH4Font = 'Noto Sans SC, Noto-Regular, sans-serif';
            break;
        default:
            break;
    }

    const palette = {
        text: {
            primary: '#ffffffde',
            secondary: '#DBDEE3',
            eyebrow: '#ccc',
            primaryHover: '#fff'
        },
        background: {
            default: '#202337',
            paper: '#0E1128'
        },
        border: '#383B50',
        accent: {
            text: '#009DDF',
            background: 'radial-gradient(100% 185.14% at 100% 100%, #34C3FF 0%, #338EEE 100%)',
            backgroundHover: '#338EEE',
            backgroundText: '#000',
            backgroundTextHover: '#000'
        }
    };
    if (isFrosty) {
        palette.text.primaryHover = '#5cd483';
        palette.background = {
            default: '#23744c',
            paper: '#286240'
        };
        palette.border = '#9cffcc14';
        palette.accent.text = '#5cd483';
        palette.accent.background =
            'radial-gradient(100% 185.14% at 100% 100%,#65dea9 0%,#408c6c 100%)';
        palette.accent.backgroundHover = '#408c6c';
    }

    return {
        logo: {
            src: getOptImage({
                url: 'https://cdn2.unrealengine.com/ue-horizontal-logo-cba14ebde1ea.svg',
                width: 440,
                quality: 'high'
            }),
            margin: '0 24px 0 8px',
            width: 222
        },
        forwardIcon: 'arrow-forward',
        palette,
        fonts: {
            default: 'OpenSans,sans-serif,arial',
            heading2: 'Brutal_Light,Open Sans,arial',
            heading3: 'Brutal_Regular,Open Sans,arial',
            heading4: ueH4Font,
            paragraph: 'Brutal_Regular,Open Sans,arial'
        }
    };
};

export const useNavTheme = (pathname: string, isFooter?: boolean): NavTheme => {
    const {color, from, to} = useCurrentAccentColor();
    const navTheme = getNavTheme(pathname);
    const isFrosty = getFrosty(pathname);

    // don't modify the original object, use a clone
    const theme = {
        ...navTheme,
        logo: {
            ...navTheme.logo
        },
        palette: {
            ...navTheme.palette
        }
    } as NavTheme;

    if (color) {
        theme.palette.text.primaryHover = color;
        theme.palette.accent.text = color;
        theme.palette.accent.background = `radial-gradient(100% 185.14% at 100% 100%, ${from} 0%, ${to} 100%)`;
        theme.palette.accent.backgroundHover = to;
    }

    if (isFooter) {
        theme.palette.accent = {
            text: '#009DDF',
            background: '#009DDF',
            backgroundHover: '#338EEE',
            backgroundText: '#000'
        };
        if (isFrosty) {
            theme.palette.accent = {
                text: '#5cd483',
                background: '#5cd483',
                backgroundHover: '#25995e',
                backgroundText: '#000'
            };
        }
    }

    return theme;
};
