import {Params} from 'react-router';
import {eulaKeyContent, eulaKeyUnreal} from '../constants';
import root from 'window-or-global';
export interface EulaTypeParams {
    eulaDesc: string;
}

export const determineEulaType = (
    params: Params,
    rootUrlPattern?: string
): {cmsUrlPattern: string; eulaKey: string; outsideOfEulaService: boolean} => {
    const eulaDesc = params.eulaDesc || '';
    const cmsUrlPattern = `/${rootUrlPattern || 'eula'}/${eulaDesc}`;
    let eulaKey;
    let outsideOfEulaService = false;

    switch (eulaDesc) {
        case 'content':
            eulaKey = eulaKeyContent;
            break;
        case 'unreal':
            eulaKey = eulaKeyUnreal;
            break;
        case 'mhc':
            eulaKey = eulaDesc;
            break;
        case 'showdown':
            eulaKey = eulaDesc;
            outsideOfEulaService = true;
            break;
        case 'lumen-nanite-demo':
            eulaKey = eulaDesc;
            outsideOfEulaService = true;
            break;
        case 'bullet-train':
            eulaKey = eulaDesc;
            outsideOfEulaService = true;
            break;
        default:
            eulaKey = eulaDesc;
            outsideOfEulaService = true;
            break;
    }

    return {cmsUrlPattern, eulaKey, outsideOfEulaService};
};

export const determineEulaRefLocRedirect = (
    params: Params,
    langQuery?: string | null
): {redirectUrlPattern: string; refLocale?: string} => {
    const clientConfig = root.clientEnvConfig || {};
    const eulasWithReference = ['content', 'unreal'];
    const eulaRefDesc = params.refSlug?.split('-')[0];
    const eulaDesc = params.eulaDesc || eulaRefDesc || '';

    const locale = params.locale || '';
    const localeLowerCase = locale.toLowerCase();
    const supportedLocales: string[] = clientConfig.EPIC_SUPPORTED_LOCALES
        ? clientConfig.EPIC_SUPPORTED_LOCALES.split(',')
        : [];

    const supportedRefs = supportedLocales
        .filter((loc) => loc !== 'en-US')
        .map((loc) => loc.toLowerCase());
    const refLocale = params.refSlug?.substring(params.refSlug.indexOf('-') + 1) || '';
    const isSupportedRefLocale = supportedRefs.includes(refLocale);

    let redirectUrlPattern = '';

    if (!eulasWithReference.includes(eulaDesc)) {
        return {redirectUrlPattern, refLocale};
    }

    if (eulaDesc && locale !== 'en-US') {
        redirectUrlPattern = `/eula-reference/${eulaDesc}-${localeLowerCase}`;
    } else if (eulaRefDesc && refLocale !== localeLowerCase && langQuery) {
        if (isSupportedRefLocale) {
            redirectUrlPattern =
                locale === 'en-US'
                    ? `/eula/${eulaRefDesc}`
                    : `/eula-reference/${eulaRefDesc}-${localeLowerCase}`;
        } else if (refLocale && !isSupportedRefLocale) {
            return {redirectUrlPattern, refLocale};
        }
    }

    return {redirectUrlPattern, refLocale};
};
