import React, {useEffect} from 'react';
import {useLocation, useMatch} from 'react-router-dom';
import styled from 'styled-components';

import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData,
    getUrlPattern
} from 'epic-ue-shared';
import {IndustrySelected} from '../../shared';
import {
    CaseStudySection,
    CaseStudySectionProps,
    CommunityGallery,
    CommunityGalleryProps,
    DividedList,
    DividedListProps,
    DownloadSection,
    DownloadSectionProps,
    FeaturesCarouselSection,
    FeaturesCarouselSectionProps,
    GridContainer,
    ImmersiveHeaderSection,
    ImmersiveHeaderSectionProps,
    NewsletterSection,
    NewsletterSectionProps,
    OverviewGridSection,
    OverviewGridSectionProps,
    PromoSection,
    PromoSectionProps,
    SectionIntroWithCards,
    SectionIntroWithCardsProps,
    SplitListSection,
    SplitListSectionProps,
    Subnav,
    SubnavProps,
    SubnavSection,
    SubnavSectionConfigProps,
    TemplateThemeProps,
    VerticalSpacer
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {useSharedValue} from '@epic-core/hooks';
import {getPreStardustPageNode} from '../../utils/stardustPageUtils';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;
interface SubnavSectionProps {
    sectionConfig?: SubnavSectionConfigProps;
}

interface OverviewGridSectionWithPromoProps extends OverviewGridSectionProps {
    promo?: PromoSectionProps;
}

interface DownloadWithNewsletterSectionProps extends SubnavSectionProps {
    download?: DownloadSectionProps;
    newsletter?: NewsletterSectionProps;
}

interface ResourcesSectionProps extends SubnavSectionProps {
    communityGalleryConfig?: CommunityGalleryProps;
    eventsAndNewsConfig: SplitListSectionProps;
    introConfig: SectionIntroWithCardsProps;
    mediaListConfig?: DividedListProps;
    hidden?: boolean;
}

interface IndustryPageData extends PageData {
    themeConfig: TemplateThemeProps;
    header: ImmersiveHeaderSectionProps;
    subnav: SubnavProps;
    overviewGrid: OverviewGridSectionWithPromoProps;
    caseStudy: CaseStudySectionProps;
    features: FeaturesCarouselSectionProps & SubnavSectionProps;
    resources: ResourcesSectionProps;
    download: DownloadWithNewsletterSectionProps;
}

const IndustryPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = getUrlPattern(match);
    let industry;

    if (urlPattern && urlPattern.indexOf('solutions/') > -1) {
        industry = urlPattern.split('solutions/')[1];
    }

    const [industrySelected, setIndustrySelected] = useSharedValue(IndustrySelected);

    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<IndustryPageData>(urlPattern);

    const {
        overviewGrid = {} as OverviewGridSectionWithPromoProps,
        subnav = {} as SubnavProps,
        themeConfig = {} as TemplateThemeProps,
        caseStudy = {} as CaseStudySectionProps,
        header = {} as ImmersiveHeaderSectionProps,
        features = {} as FeaturesCarouselSectionProps & SubnavSectionProps,
        resources = {} as ResourcesSectionProps,
        download = {} as DownloadWithNewsletterSectionProps
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    useEffect(() => {
        if (industry && industry !== industrySelected) setIndustrySelected(industry);
    }, [industry, industrySelected, setIndustrySelected]);

    const node = getPreStardustPageNode({
        theme,
        cmsLoading,
        cmsLoadedEmpty,
        pageData
    });

    if (node) {
        return node;
    }

    const overviewGridSectionConfig = {
        ...overviewGrid?.sectionConfig,
        theme: 'dark',
        accentColor,
        index: 0
    } as SubnavSectionConfigProps;

    const caseStudySectionConfig = {
        ...caseStudy?.sectionConfig,
        theme: 'dark',
        accentColor,
        index: 2
    } as SubnavSectionConfigProps;

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        index: 4,
        itemNumberColorOverride: 'black'
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const {
        communityGalleryConfig = {},
        eventsAndNewsConfig = {} as SplitListSectionProps,
        introConfig = {},
        mediaListConfig = {}
    } = resources;

    const mediaListValid =
        !mediaListConfig.hidden && mediaListConfig.items && mediaListConfig.items.length;

    const comGalCards = communityGalleryConfig.cards || [];
    const communityGalleryConfigValid =
        !communityGalleryConfig.hidden && comGalCards && comGalCards.length;

    const {leftList = {}, rightList = {}} = eventsAndNewsConfig;
    const eventsNewsValid =
        !eventsAndNewsConfig.hidden &&
        leftList.items &&
        leftList.items.length &&
        rightList.items &&
        rightList.items.length;

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled key={industry}>
                {getHelmetForMetaTags(pageData)}
                <ImmersiveHeaderSection {...header} />
                <Subnav {...subnav} accentColor={accentColor} />

                <SubnavSection
                    sectionConfig={overviewGridSectionConfig}
                    hideSectionName
                    hideBottomSectionPadding>
                    <OverviewGridSection
                        {...overviewGrid}
                        sectionConfig={overviewGridSectionConfig}
                        accentColor={accentColor}
                        componentMapperIndex={0}
                    />
                    {overviewGrid?.promo && <VerticalSpacer variant="section" />}
                    <PromoSection accentColor={accentColor} {...overviewGrid?.promo} />
                </SubnavSection>

                <SubnavSection
                    sectionConfig={{
                        ...features?.sectionConfig,
                        accentColor,
                        index: 1
                    }}
                    hideBottomSectionPadding>
                    <FeaturesCarouselSection {...features} accentColor={accentColor} />
                    <VerticalSpacer variant="section" />
                </SubnavSection>

                <SubnavSection
                    sectionConfig={caseStudySectionConfig}
                    hideSectionName
                    hideTopSectionPadding
                    hideBottomSectionPaddingSM={true}>
                    <CaseStudySection
                        {...caseStudy}
                        sectionConfig={caseStudySectionConfig}
                        accentColor={accentColor}
                    />
                </SubnavSection>

                <SubnavSection
                    hideBottomSectionPadding
                    sectionConfig={{
                        ...resources?.sectionConfig,
                        theme: 'light',
                        accentColor,
                        index: 3
                    }}>
                    <SectionIntroWithCards {...introConfig} accentColor={accentColor} />
                    {mediaListValid && (
                        <GridContainer>
                            <VerticalSpacer variant="custom" spacing={12} downSmSpacing={7} />
                            <DividedList
                                {...mediaListConfig}
                                accentColor={accentColor}
                                variant="offset-description"
                            />
                        </GridContainer>
                    )}
                    {communityGalleryConfigValid && (
                        <>
                            <VerticalSpacer variant="layout" />
                            <CommunityGallery
                                {...communityGalleryConfig}
                                accentColor={accentColor}
                            />
                        </>
                    )}
                    {eventsNewsValid && (
                        <>
                            <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                            <SplitListSection {...eventsAndNewsConfig} accentColor={accentColor} />
                        </>
                    )}
                    <VerticalSpacer variant="custom" spacing={12} downSmSpacing={7} />
                </SubnavSection>

                <SubnavSection
                    variant="gradient"
                    hideBottomSectionPadding
                    sectionConfig={downloadSectionConfig}>
                    <DownloadSection
                        {...downloadBlockProps}
                        accentColor={accentColor}
                        variant="standard"
                    />
                    <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                    <NewsletterSection
                        variant="split"
                        {...download?.newsletter}
                        accentColor={accentColor}
                    />
                </SubnavSection>
            </Styled>
        </UeThemeProvider>
    );
};

IndustryPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default IndustryPage;
