import {DefaultSharedStore, createSharedValueKey, SharedValueStoreContext} from '@epic-core/hooks';
import {AppSharedKeys, getSharedContext, setSharedContext} from 'epic-ue-shared';
import {useFeedContentTypes, feedContentTypesKey} from 'epic-ue-feed';
import root from 'window-or-global';
import {GraphQlService} from '@epic-node-services/graphql-public-service';
import {AltAppConfig} from './shared';

export const sharedStore = new DefaultSharedStore();

export async function bootstrapSharedValues(altAppConfig: AltAppConfig): Promise<void> {
    let context = getSharedContext();
    const stores = Object.keys(AppSharedKeys) || [];
    context = new SharedValueStoreContext(sharedStore);

    stores.forEach((store) => {
        AppSharedKeys[store] = createSharedValueKey(store, altAppConfig[store] || {});
        context.set(AppSharedKeys[store], altAppConfig[store] || {});
    });

    const feedStore = altAppConfig?.FeedStore;
    context.set(feedContentTypesKey, feedStore?.contentTypes || []);
    setSharedContext(context);
}

export const useClientData = (): void => {
    useFeedContentTypes();
};

export const configureServices = (): void => {
    const envConfig = root.clientEnvConfig;
    if (envConfig) {
        const services = [GraphQlService];

        for (const service in services) {
            if (!Object.prototype.hasOwnProperty.call(services, service)) continue;
            services[service].configure(envConfig);
        }
    }
};
