import React from 'react';
import {GlobalNavWrapper} from 'epic-ue-header-footer/dist/components';

interface Props {
    disableCtaOnMobilePaths: string[];
    disablePaths: string[];
}

export const Navigation = ({
    disableCtaOnMobilePaths = [],
    disablePaths = []
}: Props): JSX.Element => {
    return (
        <GlobalNavWrapper
            disableCtaOnMobilePaths={disableCtaOnMobilePaths}
            disablePaths={disablePaths}
        />
    );
};
