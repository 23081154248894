import React from 'react';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import {H1, H4, BgMedia} from '../../elements';
import styled from 'styled-components';
import {Credits} from './Header.styles';
import {Header} from './Header';
import {getBowserState} from '@epic-core/common';
import {VideoHeaderProps} from 'epic-ue-shared';
import {BackgroundProps} from 'epic-ue-shared';

const VideoHeaderWrapper = styled.div`
    width: 100%;
    ${(p) => p.theme.breakpoints.down('md')} {
        min-height: 80vh;
    }
    .header-banner {
        height: 250px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    h1 {
        font-size: 2.8em;
        line-height: 1.5em;
    }
    h4 {
        margin-top: -20px;
        font-size: 1.5em;
    }
`;

const VideoHeaderTitle = styled.div`
    max-width: 730px;
    margin: 0 auto;
`;

const VideoContentWrapper = styled.div`
    margin: 50px auto 0;
    width: 880px;
    overflow: hidden;
    .container {
        width: 100%;
    }
    .overlay {
        display: none;
    }
    .titleWrapper {
        padding-top: 0;
        margin-top: 0;
        min-height: 100px;
    }
`;

const BackgroundWrapper = styled.div`
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
`;

export const VideoHeader = ({
    title,
    subtitle,
    desc,
    buttons,
    ytVideo,
    background = {} as any,
    credits
}: VideoHeaderProps): JSX.Element => {
    const youtubeVid = (ytVideo || {}) as BackgroundProps;
    const {mobile, tablet} = getBowserState();

    const showreelProps = {
        title,
        video: youtubeVid
    };

    if (mobile || tablet) {
        return (
            <Header
                title={title}
                height={'90vh'}
                subtitle={subtitle}
                desc={desc}
                type={'default'}
                buttons={buttons}
                showreel={showreelProps}
                background={background}
            />
        );
    }
    return (
        <VideoHeaderWrapper>
            <BackgroundWrapper className="header-banner">
                <BgMedia autoplay={true} background={background} />
                <Credits>{credits || ''}</Credits>
            </BackgroundWrapper>
            <VideoContentWrapper>
                <div className="container">
                    <div className="row">
                        <div className="embed-responsive embed-responsive-16by9">
                            <BackgroundVideo
                                key={`header-${title}`}
                                background={youtubeVid}
                                type={youtubeVid.type}
                                enableRatioHelper={true}
                                play={true}
                                mute={true}
                                controls={true}
                                ytControls={true}
                                playsInline={true}
                            />
                        </div>
                    </div>
                </div>
            </VideoContentWrapper>
            <VideoHeaderTitle className="container">
                <div className="row">
                    <div className="titleWrapper">
                        {title ? (
                            <H1>
                                <div dangerouslySetInnerHTML={{__html: title || ''}} />
                            </H1>
                        ) : (
                            ''
                        )}
                        {subtitle ? <H4>{subtitle}</H4> : ''}
                    </div>
                </div>
            </VideoHeaderTitle>
        </VideoHeaderWrapper>
    );
};
