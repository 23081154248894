import React from 'react';
import styled from 'styled-components';
import {MarkerProps} from './UEMap';

const Table = styled.div<{hidden: boolean}>`
    position: ${p => (p.hidden ? 'absolute' : 'static')};
    width: 100%;
    visibility: ${p => (p.hidden ? 'hidden' : 'visible')};
    top: 0;
    z-index: 1;
    ${p => p.theme.breakpoints.down('sm')} {
        margin: 1em;
    }
    section.head {
        margin: 1em 0 2em;
    }
    ul {
        list-style: none;
        padding: 0;
        overflow: auto;
        height: 100%;
        pointer-events: auto;
        display: flex;
        flex-wrap: wrap;
        li {
            list-style: none;
            padding: 1em;
            &:hover {
                background: ${p => p.theme.ue.colors.cards.overlay.background};
            }
        }
    }
`;

interface Properties {
    items: MarkerProps[];
    title?: string;
    description?: string;
    hidden: boolean;
}

export const TableFallback = ({items = [], title, description, hidden}: Properties): JSX.Element => {
    return (
        <Table hidden={hidden}>
            <div className="container">
                <section className="head">
                    {title ? <h2>{title}</h2> : ''}
                    {description ? <div dangerouslySetInnerHTML={{__html: description}} /> : ''}
                </section>
                <ul className="list">
                    {items.map((el, i) => {
                        const {url, name, email, phone, postcode, street, city} = el;
                        return (
                            <li
                                className="item col-xs-6 col-md-4 col-lg-3 col-xl-2"
                                key={`${name}-${i}`}>
                                <div>
                                    {url ? (
                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                            {name}
                                        </a>
                                    ) : (
                                        <div className="name">{name}</div>
                                    )}
                                </div>
                                {phone ? <div className="field">{phone}</div> : ``}
                                {email ? <div className="field">{email}</div> : ``}
                                {street ? <div className="field">{street}</div> : ``}
                                {postcode ? <div className="field">{postcode}</div> : ``}
                                {city ? <div className="field">{city}</div> : ``}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Table>
    );
};
