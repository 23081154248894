import styled from 'styled-components';
import {downWithOrientation} from '../../../utils';

export const LightboxMediaWrapper = styled.div`
    position: fixed;
    bottom: 1em;
    left: 1em;
    z-index: 5;
    opacity: 1;
    ${p => p.theme.breakpoints.down('xs')} {
        text-align: center;
        bottom: 6em;
        left: 0;
        right: 0;
    }
    .embed-responsive,
    iframe {
        max-height: 890px;
        margin: 0 auto;
    }
`;

export const LightboxMediaVideoWrapper = styled.div`
    &&& {
        .vid {
            position: relative;
            ${p => p.theme.breakpoints.down('md')} {
                max-width: 100%;
            }
        }
        .overlay {
            pointer-events: none;
            display: none;
        }
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: #222;
        padding-top: 5em;
        ${p => p.theme.breakpoints.down('md')} {
            padding-top: 1em;
        }
        z-index: 999;
        .close-btn {
            position: absolute;
            top: 0;
            right: -40px;
            padding: 0;
            border-radius: 0;
            cursor: pointer;
            z-index: 999;
            ${p => p.theme.breakpoints.down('lg')} {
                right: -20px;
            }
            ${p => p.theme.breakpoints.down('md')} {
                right: 15px;
                top: -33px;
            }
            ${downWithOrientation('lg', 'landscape')} {
                top: 18px;
                right: -20px;
            }
            .lines {
                position: relative;
                display: block;
                width: 2.5em;
                height: 2.5em;
                background-color: ${p => p.theme.ue.colors.buttons.primary.background.default};
                transition: background-color 0.3s ease-in-out;
                &:hover {
                    background-color: ${p => p.theme.ue.colors.buttons.primary.background.hover};
                }
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    right: 0;
                    top: 20px;
                    width: 90%;
                    height: 1px;
                    background: #000;
                    ${p => p.theme.breakpoints.down('md')} {
                        top: 15px;
                    }
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
`;
