import React, {useEffect} from 'react';
import styled from 'styled-components';
import {getHelmetForMetaTags, useAccountAODCPolicy} from 'epic-ue-shared';
import {
    DynamicSections,
    ImageHero,
    Subnav,
    SubnavProps,
    TemplateThemeProps,
    VerticalSpacer,
    GeneralLandingPageData,
    GeneralLandingPageSections,
    ImmersiveHeaderSection,
    CmsComponentMapper,
    DynamicHeaderProps
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import root from 'window-or-global';
import {useAccountStore, useCventSignUp} from 'epic-ue-shared';
import {useSearchParams} from 'react-router-dom';
import {getLocale} from '@epic-core/common';
import {UnrealLoading} from 'epic-ue-loading';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
`;
interface Properties {
    pageData: GeneralLandingPageData;
}

const EventStardustPage = ({pageData}: Properties): JSX.Element => {
    const {
        subnav = {} as SubnavProps,
        themeConfig = {} as TemplateThemeProps,
        sections = {} as GeneralLandingPageSections,
        bottomPageVerticalSpacer,
        header
    } = pageData;
    const dynamicHeader = (header as DynamicHeaderProps) || {};
    const {headerOptions} = dynamicHeader;
    const {items = []} = sections;
    const {accentColor, theme} = themeConfig;
    const {account, loading: accountLoading, loaded} = useAccountStore();
    const isLoggedIn = account?.isLoggedIn || false;
    const {result: accountPolicy} = useAccountAODCPolicy();
    const [searchParams] = useSearchParams();
    const cvent = searchParams.get('cvent') || searchParams.get('e') || '';
    const target = searchParams.get('TARGET');
    const {redirectUrl, loading: cventSingUpLoading} = useCventSignUp(
        cvent,
        isLoggedIn,
        accountPolicy?.AODCFailed === false,
        target
    );
    useSetCurrentAccentColor(accentColor);

    if (loaded && !accountLoading && !isLoggedIn && cvent) {
        root.location.href = `/${getLocale()}/auth?state=${encodeURIComponent(root.location.href)}`;
    }

    useEffect(() => {
        if (redirectUrl && accountPolicy?.AODCFailed === false) {
            const link = root.document.createElement('a');
            link.referrerPolicy = 'no-referrer';
            link.rel = 'noreferrer';
            link.href = redirectUrl;
            link.click();
        } else if (accountPolicy?.AODCFailed === true && cvent) {
            root.location.href = `/${getLocale()}/feature-unavailable`;
        }
    }, [accountPolicy?.AODCFailed, redirectUrl, cvent]);

    if (cventSingUpLoading) {
        return <UnrealLoading />;
    }

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            <Styled className={theme}>
                {getHelmetForMetaTags(pageData)}
                <CmsComponentMapper
                    dynamicPageDataArray={headerOptions}
                    elementMapping={{
                        imageHero: <ImageHero accentColor={accentColor} />,
                        immersiveHeader: <ImmersiveHeaderSection accentColor={accentColor} />
                    }}
                />
                <Subnav {...subnav} accentColor={accentColor} />
                <DynamicSections theme={theme} items={items} accentColor={accentColor} />
                {bottomPageVerticalSpacer && <VerticalSpacer variant="section" />}
            </Styled>
        </UeThemeProvider>
    );
};

export default EventStardustPage;
