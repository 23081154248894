const addForwardSlash = (url: string, elementIndex: number): string => {
    if (elementIndex === -1) {
        return url.slice(elementIndex) === '/' ? url : `${url}/`;
    } else if (elementIndex === 0) {
        return url.slice(elementIndex) === '/' ? url : `/${url}`;
    }
    // return home by default
    return '/';
};

export {addForwardSlash};
