import React from 'react';
import pt from 'prop-types';
import RecordingRow from './RecordingRow';
import root from 'window-or-global';
import debounce from 'lodash.debounce';
import {H2, H4} from 'epic-ue-components';
import Message from '../../message/Message';
import {
    RecordingsRow,
    RecordingGroupHeading,
    SeeAllButton,
    FeaturedRecordings,
    RecordingsSection,
    RecordingItemWrapper
} from './Recording.styles';
import {VideoPlayer} from './VideoPlayer';
import {getBowserState} from '@epic-core/common';
import {withLocalization} from '@epic-mw/localization';

class Recordings extends React.PureComponent {
    static propTypes = {
        title: pt.string,
        desc: pt.string,
        nowPlayingCopy: pt.string,
        btnLabel: pt.string,
        seeAllLabel: pt.string,
        pinLabel: pt.string,
        collapseLabel: pt.string,
        featuredRecordings: pt.oneOfType([pt.object, pt.array]),
        recordingList: pt.array,
        getMessage: pt.func,
        isPopoutPlayerDisabled: pt.bool
    };

    state = {
        showAllIndex: null,
        showAllClicked: false,
        pinPlayer: false,
        footerVisible: false,
        activeVideo: null,
        isDirty: false
    };

    componentDidMount() {
        root.addEventListener('touchmove', debounce(this.handleScroll, 100, {leading: true}));
        root.addEventListener('scroll', debounce(this.handleScroll, 100, {leading: true}));
        this.handleScroll();
    }

    componentWillUnmount() {
        root.removeEventListener('touchmove', debounce(this.handleScroll, 100, {leading: true}));
        root.removeEventListener('scroll', debounce(this.handleScroll, 100, {leading: true}));
    }

    static getDerivedStateFromProps(props, state) {
        const {featuredRecordings, recordingList} = props;
        const activeVideo = state.activeVideo;
        if (activeVideo) return state;
        // If user hasn't clicked on a thumbnail yet and no main video has been selected in CMS,
        // Just use the first one available

        if (featuredRecordings && featuredRecordings.title !== undefined) {
            return {
                activeVideo: featuredRecordings
            };
        } else if (recordingList && recordingList.length) {
            for (let i = 0; i < recordingList.length; i++) {
                const recordingsWrapper = recordingList[i];
                const recordings = recordingsWrapper.recordings || [];
                for (let j = 0; j < recordings.length; j++) {
                    const recording = recordings[j];
                    if (recording.initial) {
                        return {
                            activeVideo: recording
                        };
                    }
                }
            }

            const firstRecordingsWrapper = recordingList[0];
            if (
                firstRecordingsWrapper &&
                firstRecordingsWrapper.recordings &&
                firstRecordingsWrapper.recordings[0]
            ) {
                return {
                    activeVideo: firstRecordingsWrapper.recordings[0]
                };
            } else if (
                featuredRecordings &&
                Array.isArray(featuredRecordings) &&
                featuredRecordings.length
            ) {
                return {
                    activeVideo: featuredRecordings[0]
                };
            }
        }

        return state;
    }

    handleScroll = (e) => {
        const bounds = this.mainPlayerRef && this.mainPlayerRef.getBoundingClientRect();
        if (root && bounds && !this.props.isPopoutPlayerDisabled) {
            const innerHeight = root.innerHeight;
            const playerTop = Math.ceil(bounds.top);
            const playerHeight = Math.floor(bounds.height);
            const scrollOffset = 800;
            const playerOffset = playerTop + playerHeight + scrollOffset;

            if (playerOffset < innerHeight) {
                this.setState({
                    pinPlayer: true
                });
            } else if (playerOffset > innerHeight) {
                this.setState({
                    pinPlayer: false
                });
            }

            this.handleFooterOffset();
        }
    };

    handleFooterOffset() {
        const innerHeight = root.innerHeight;
        const footer = root.document.getElementById('egf');
        if (footer) {
            const footerBounds = footer.getBoundingClientRect();
            const footerTop = Math.ceil(footerBounds.top);
            if (footerTop - 250 < innerHeight) {
                this.setState({
                    footerVisible: true
                });
            }
            if (footerTop > innerHeight) {
                this.setState({
                    footerVisible: false
                });
            }
        }
    }

    showAll(showIndex) {
        const {recordingList} = this.props;
        const showAllClicked = this.state.showAllClicked;
        recordingList.map((recordings, index) => {
            if (showIndex === index) {
                this.setState({
                    showAllIndex: index,
                    showAllClicked: !showAllClicked
                });
            }
        });
    }

    playVideo = (recording) => {
        const {mobile, tablet} = getBowserState();
        if (mobile || tablet) {
            root.document.getElementById('videoPlayerWrapper').scrollIntoView({
                behavior: 'smooth'
            });
        }

        this.setState({
            activeVideo: recording,
            isDirty: true
        });
    };

    render() {
        const {title, desc, recordingList, featuredRecordings, getMessage} = this.props;
        const pinLabelText = getMessage('epic.event.explainer');
        const nowPlayingText = getMessage('epic.event.playing');
        const activeVideo = this.state.activeVideo;
        let mainVideo = null;
        const pinPlayer = this.state.pinPlayer;
        const footerVisible = this.state.footerVisible;
        const {mobile, tablet} = getBowserState();

        let FeaturedItems = '';
        if (
            featuredRecordings &&
            Array.isArray(featuredRecordings) &&
            featuredRecordings.length === 2
        ) {
            FeaturedItems = featuredRecordings.map((recording, index) => {
                const i = index;
                return (
                    <RecordingItemWrapper
                        className="recording-item-wrapper col-xs-12 col-sm-6 col-md-6"
                        key={`feature-${i}-${recording.title}`}>
                        <RecordingsRow
                            activeVideo={activeVideo}
                            featured={true}
                            recording={recording}
                            hasClicked={this.playVideo}
                        />
                    </RecordingItemWrapper>
                );
            });
        }

        let recordingRows = [];
        if (recordingList !== undefined && recordingList.length > 0) {
            recordingRows = recordingList.map((recordingsWrapper, index) => {
                const i = index;
                const recordings = recordingsWrapper.recordings || [];
                const showIndex = this.state.showAllIndex;
                const clicked = this.state.showAllClicked;
                const recordingAmount = recordings.length;
                if (recordings !== undefined && recordings.length > 0) {
                    recordings.map((recording, index) => {
                        if (recording.initial) {
                            mainVideo = recording;
                        }
                    });
                }

                let buttonLabel = <Message code="epic.event.seeall" />;
                if (clicked && showIndex === index) {
                    buttonLabel = <Message code="epic.event.collapse" />;
                }

                return (
                    <div key={`c-${recordingsWrapper.title}-${i}`} className="col-xs-12">
                        <RecordingsRow>
                            <RecordingGroupHeading>
                                <H4>{recordingsWrapper.title}</H4>
                                {recordingAmount > 3 ? (
                                    <div className="show-button-wrapper">
                                        <SeeAllButton
                                            role="presentation"
                                            onClick={() => this.showAll(index)}
                                            className="see-all-btn">
                                            {buttonLabel}
                                        </SeeAllButton>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </RecordingGroupHeading>
                            <RecordingRow
                                activeVideo={activeVideo}
                                mobile={mobile}
                                tablet={tablet}
                                recordings={recordings}
                                index={index}
                                clicked={clicked}
                                hasClicked={this.playVideo}
                                showAll={showIndex}
                            />
                        </RecordingsRow>
                    </div>
                );
            });
        }

        if (activeVideo) {
            mainVideo = activeVideo;
        }

        const dirty = this.state.isDirty || false;
        return (
            <RecordingsSection className="RecordingsSection">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <H2>{title}</H2>
                            <div
                                className="sectionDescription"
                                dangerouslySetInnerHTML={{__html: desc || ''}}
                            />
                            <div
                                id="videoPlayerWrapper"
                                ref={(c) => {
                                    this.mainPlayerRef = c;
                                }}>
                                {mainVideo && (
                                    <VideoPlayer
                                        nowPlayingCopy={nowPlayingText}
                                        pinLabel={pinLabelText}
                                        pinPlayer={pinPlayer}
                                        footerVisible={footerVisible}
                                        dirty={dirty}
                                        mute={!dirty}
                                        mobile={mobile}
                                        tablet={tablet}
                                        mainVideo={mainVideo}
                                    />
                                )}
                            </div>

                            {FeaturedItems && FeaturedItems.length && (
                                <FeaturedRecordings className="row">
                                    {FeaturedItems}
                                </FeaturedRecordings>
                            )}
                            <RecordingsRow classname="row">{recordingRows}</RecordingsRow>
                        </div>
                    </div>
                </div>
            </RecordingsSection>
        );
    }
}

export default withLocalization(Recordings);
