import React from 'react';
import PropTypes from 'prop-types';
import SpeakerCard from './SpeakerCard';
import {SpeakerWrapper} from './Speakers.styles';

/**
 * Speaker Panel
 *
 * This Panel will loop through all of the speakers in the scheduleItems array and
 * return the /events/speakerCard for each
 */
const SpeakersPanel = (props) => (
    <SpeakerWrapper className="block-white">
        <div className="block-title">{props.speakerHeadline}</div>
        <div className="container block-speakers">
            {props.scheduleItems.length > 0 ? (
                props.scheduleItems.map((item, index) => (
                    <SpeakerCard
                        key={item.headline}
                        presenterName={item.presenterName}
                        headline={item.headline}
                        room={item.room}
                        brief={item.brief}
                        speakerImageURL={item.speakerImageURL}
                        alt={item.alt}
                        hideFromSchedule={item.hideFromSchedule}
                    />
                ))
            ) : (
                <div />
            )}
        </div>
    </SpeakerWrapper>
);

SpeakersPanel.propTypes = {
    speakerHeadline: PropTypes.string,
    scheduleItems: PropTypes.array
};

export default SpeakersPanel;
