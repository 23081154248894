import React, {useEffect} from 'react';

import {useCmsData, IndustrySelected} from '../shared';

import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import {getMetaTags, getHelmetForMetaTags} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import {
    Page,
    Header,
    Section,
    SectionWrapper,
    Features,
    Button,
    CaseStudies
} from 'epic-ue-components';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {useLocalization} from '@epic-mw/localization';
import {Helmet} from 'react-helmet';
import {useSharedValue} from '@epic-core/hooks';
import {titleAppendUnrealEngine} from 'epic-ue-shared';
import {
    FeaturesProps,
    getMessageFnType,
    NewsletterData,
    PageData,
    SectionProps,
    SectionsProps,
    UELHeaderProps
} from 'epic-ue-shared';
import {Showcase} from 'epic-ue-components';
import NewsEventsAndArticles from '../components/feed/NewsEventsAndArticles';
import ErrorPage from './ErrorPage';

const rootPageSlug = '/industry';

const Industry = styled.div`
    section {
        background: transparent;
        border: 0;
        padding: 25px 0 25px 0;
        min-height: 10vh;
        h3 {
            line-height: 1.3em;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            .copy-section,
            .stacked-section {
                padding: 50px;
            }
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 0;
        }
        .news-header {
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 3em 0 0;
            }
        }
        .news {
            padding-bottom: 5em;
            .btn:not(.blank) {
                opacity: 1;
                transform: translate3d(0, 0, 1);
            }
            .feed-view {
                .container {
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        width: auto;
                    }
                }
            }
        }
        .see-more-wrapper {
            margin: 0 auto;
            text-align: center;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 0 auto 40px;
            }
        }
    }

    .border-top {
        .hr {
            padding: 2rem 0;
            border-width: 1px 0 0;
            border-style: solid;
            border-image: linear-gradient(
                    90deg,
                    rgba(230, 230, 230, 0),
                    #333 50%,
                    rgba(230, 230, 230, 0) 100%
                )
                5%;
            max-width: 71.25rem;
            margin: 0 auto;
            display: block;
        }
    }

    .sections > section:first-child {
        padding: 50px 0 0;
    }

    .sections {
        @media only screen and (min-width: 1600px) {
            .container {
                width: 1430px;
            }
        }

        @media (min-width: 1600px) {
            .container {
                max-width: 1400px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .engagement-section {
        margin-top: -50px;
        max-width: initial;
        h2 {
            font-family: Brutal_Light, Open Sans, arial;
            font-size: 2.2em;
        }
        .form-group div div {
            min-height: unset;
        }
        svg {
            right: 9px;
        }
    }

    ${(p) => p.theme.breakpoints.down('sm')} {
        .engagement-section {
            margin-top: 50px;
        }
    }
`;

const HeaderWrap = styled.div`
    .header {
        h2 {
            text-transform: uppercase;
        }
    }
    .copy {
        line-height: 1.7em;
    }

    .simple-link-wrap {
        height: 50px;
        display: inline-block;
        padding: 15px 0;
        margin: 5px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 0;
        }
    }
`;
interface Properties {
    cmsUrlPattern?: string;
}

interface IndustryPageData extends PageData {
    newsTag?: string;
    newsFilter?: string;
    newsHeader?: string;
    header?: UELHeaderProps;
    topSections?: SectionsProps;
    sections?: SectionProps[];
    features?: IndustryPageFeaturesProps;
    showcase?: Showcase;
    newsletterData?: NewsletterData;
    bottomSections?: SectionsProps;
    metaTagOverrides?: IndustryPageMetaTagOverrides;
}

interface IndustryPageFeaturesProps {
    href?: string;
    title?: string;
    desc?: string;
    label?: string;
    features?: FeaturesProps[];
}

interface IndustryPageMetaTagOverrides {
    title?: string;
    desc: string;
    shareImage: string;
}

const IndustryPage = ({cmsUrlPattern}: Properties): JSX.Element => {
    const [industrySelected, setIndustrySelected] = useSharedValue(IndustrySelected);
    const params = useParams();
    const industry = params.industry;
    const finalUrlPattern = cmsUrlPattern || `${rootPageSlug}/${industry}`;
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<IndustryPageData>(finalUrlPattern);

    const getMessage = useLocalization() as getMessageFnType;

    useEffect(() => {
        if (industry && industry !== industrySelected) setIndustrySelected(industry);
    }, [industry, industrySelected]);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const header = pageData.header || ({} as UELHeaderProps);
    const topSections = pageData.topSections || ({} as SectionsProps);
    const topSectionsList = topSections.sections || [];
    const showcase = pageData.showcase || ({} as Showcase);
    const showcases = showcase.showcases || ([] as Showcase[]);

    const features = pageData.features || {};
    const featureTitle = features.title || '';
    const featureDesc = features.desc || '';
    const featureDetailHref = features.href || '';
    const featureDetailLabel = features.label || '';
    const featureList = features.features || [];
    const bottomSections = pageData.bottomSections || ({} as SectionsProps);
    const bottomSectionsList = bottomSections.sections || [];
    const metaTagOverrides = pageData.metaTagOverrides || ({} as IndustryPageMetaTagOverrides);

    let topSectionList: (JSX.Element | undefined)[] = [<div key="empty-top-section" />];
    if (topSectionsList && topSectionsList.length >= 1) {
        topSectionList = topSectionsList.map((section, index) => {
            const i = index;
            let even = false;
            if (i % 2 === 0) {
                even = true;
            }
            if (section) {
                return (
                    <div
                        className={` ${i > 0 && !section.hideBorder ? 'border-top' : ''}`}
                        key={`s-${i}-${section.title}`}>
                        {section.type ? (
                            <span className={` ${i > 0 && !section.hideBorder ? 'hr' : ''}`} />
                        ) : (
                            ''
                        )}
                        <Section {...section} even={even} />
                    </div>
                );
            }
        });
    }

    let bottomSectionElems: JSX.Element | (JSX.Element | undefined)[] = <div />;
    if (bottomSectionsList && bottomSectionsList.length >= 1) {
        bottomSectionElems = bottomSectionsList.map((section, index) => {
            const i = index;
            let even = false;
            if (i % 2 === 0) {
                even = true;
            }
            if (section) {
                return (
                    <div
                        className={` ${i > 0 && !section.hideBorder ? 'border-top' : ''}`}
                        key={`s-${i}-${section.title}`}>
                        <span className={` ${i > 0 && !section.hideBorder ? 'hr' : ''}`} />
                        <Section {...section} even={even} />
                    </div>
                );
            }
        });
    }

    let seeMoreFeaturesButton = <div />;
    if (features && featureDetailHref && featureDetailLabel) {
        seeMoreFeaturesButton = (
            <div>
                <div className="see-more-wrapper">
                    <Button
                        as="a"
                        id={`see-more-features`}
                        target={'_blank'}
                        href={featureDetailHref}
                        type="secondary">
                        {featureDetailLabel}
                    </Button>
                </div>
            </div>
        );
    }

    const title = titleAppendUnrealEngine(metaTagOverrides.title || pageData._title || '');

    const metaTags = getMetaTags(
        getMessage,
        title,
        metaTagOverrides.desc,
        metaTagOverrides.shareImage
    );

    return (
        <Page>
            <Industry key={finalUrlPattern}>
                {getHelmetForMetaTags(pageData)}
                <Helmet meta={metaTags} title={title} />

                <HeaderWrap>
                    <Header
                        title={header.title}
                        height={header.height}
                        subtitle={header.subtitle}
                        tag={header.tag}
                        desc={header.desc}
                        type={header.type}
                        origin="industry"
                        showreel={header.showreel}
                        buttons={header.buttons || []}
                        vcenter={header.vcenter || false}
                        background={header.background || {}}
                    />
                </HeaderWrap>
                <div className="sections">
                    <SectionWrapper>{topSectionList}</SectionWrapper>
                    <CaseStudies
                        showcases={showcases}
                        url={showcase.btnUrl}
                        title={showcase.title}
                        label={showcase.btnLabel}
                    />
                    {featureList && featureList.length > 1 ? (
                        <SectionWrapper>
                            <Features
                                features={featureList}
                                title={featureTitle}
                                desc={featureDesc}
                            />
                            {seeMoreFeaturesButton}
                        </SectionWrapper>
                    ) : (
                        <div />
                    )}
                    {bottomSectionsList && bottomSectionsList.length >= 1 ? (
                        <SectionWrapper>{bottomSectionElems}</SectionWrapper>
                    ) : (
                        ''
                    )}
                    <NewsEventsAndArticles
                        newsTag={pageData.newsTag}
                        newsFilter={pageData.newsFilter}
                        newsHeader={pageData.newsHeader}
                    />
                </div>
            </Industry>
        </Page>
    );
};

IndustryPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default IndustryPage;
