import styled, {keyframes} from 'styled-components';

const gesture = keyframes`
    0% {
        opacity: 0;
        transform: translateX(100px) rotate(30deg);
    }
    40%{
        opacity: 1;
    }
    75% {
        opacity: 1;
        transform: translateX(0) rotate(-10deg);
    }
    100% {
        opacity: 0;
        transform: translateX(-75px) rotate(-20deg);
    }
`;

export const GridWrapper = styled.div`
    position: relative;
    margin: 0 auto;

    &#eventGrid .cellContent {
        * {
            text-align: left;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .copy {
        margin: 20px 0 40px;
        text-align: center;
    }

    .btn-wrapper {
        text-align: center;
        padding: 40px 0;
        background-color: ${(p) => p.theme.palette.background.paper};
    }

    .grid-container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 20px 10px;
        @media only screen and (min-width: 1400px) and (max-width: 1600px) {
            max-width: 1200px;
        }
    }

    .grid-tabs {
        list-style: none;
        margin: 0 0 20px 0;
        padding: 0;
        margin: 0px auto 0;
        text-align: center;

        ${(p) => p.theme.breakpoints.down('sm')} {
            margin-bottom: 10px;
        }

        li {
            display: inline-block;
            &.active {
                border-top: 2px solid ${(p) => p.theme.palette.background.paperpop};
                background-color: ${(p) => p.theme.palette.background.paper};
                ${(p) => p.theme.breakpoints.down('sm')} {
                    border: 2px solid #666;
                }
            }
            span {
                display: block;
                padding: 15px 30px;
                cursor: pointer;
                font-size: 1.5em;

                ${(p) => p.theme.breakpoints.down('lg')} {
                    font-size: 1.3em;
                }

                ${(p) => p.theme.breakpoints.down('md')} {
                    font-size: 1.1em;
                    padding: 10px 15px;
                }
            }
        }
    }

    .grid-item-list {
        padding: 35px 0;
        ${(p) => p.theme.breakpoints.down('md')} {
            margin-top: 0;
            padding: 0;
        }

        .gesture-helper {
            width: 100%;
            height: 150px;
            text-align: center;
            opacity: 0;
            animation: ${gesture} 2s infinite 2s;

            @media (prefers-reduced-motion: reduce) {
                animation: none;
            }

            margin-top: 10px;
            i {
                font-size: 4em;
                color: #5d5d5d;
            }
        }

        @media screen and (min-width: 400px) and (orientation: landscape) {
            .gesture-helper {
                display: none;
            }
        }
    }

    ${(p) => p.theme.breakpoints.down('lg')} {
        width: 100%;
        overflow: hidden;
        .grid-item-table {
            overflow-x: auto;
        }
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
        width: auto;
        max-width: 800px;
        overflow: hidden;
    }
`;

export const GridTable = styled.div<{
    columnLength: number;
    gap: number;
    gridTemplateColumns: string;
}>`
    display: -ms-grid;
    display: grid;
    ${(p) => p.theme.breakpoints.up('md')} {
        justify-items: stretch;
        -ms-grid-columns: ${(p) => (p.gridTemplateColumns ? p.gridTemplateColumns : 'auto')};
        grid-template-columns: ${(p) => (p.gridTemplateColumns ? p.gridTemplateColumns : 'auto')};
        grid-gap: ${(p) => p.gap}px;
        grid-auto-rows: minmax(2em, auto);
    }
    ${(p) => p.theme.breakpoints.down('md')} {
        grid-column: 1 / -1;
        grid-gap: ${(p) => p.gap}px;
        grid-template-columns: auto;
        grid-template-rows: minmax(40px, 1fr);
        padding: 0;
        min-width: ${(p) => p.columnLength * 250}px;
        scroll-snap-type: x proximity;
    }

    h3,
    h4,
    h5 {
        margin: 0.1em 0;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 18px;
    }
`;

export const Cell = styled.div<{row: number; col: number}>`
    grid-area: main;
    &:not(.isHeader) {
        background: ${(p) => p.theme.palette.background.paper};
    }
    -ms-grid-column: ${(p) => p.col};
    grid-column: ${(p) => p.col};
    -ms-grid-row: ${(p) => p.row};
    grid-row: ${(p) => p.row};

    ${(p) => p.theme.breakpoints.down('md')} {
        scroll-snap-align: center;
        padding: calc(var(--gutter) / 2 * 1.5);
    }
`;

export const CellContent = styled.div`
    padding: 1em 2em;
    text-align: left;
    vertical-align: middle;
    height: 100%;
`;

export const CellHeader = styled.div`
    padding: 13px 27px;
    text-align: left;
    vertical-align: middle;
    height: 100%;
    font-weight: bold;
    font-size: 1.25em;
    color: ${(p) => p.theme.palette.text.primary};
    position: relative;
`;
