import React from 'react';
import {H2} from '../../elements/';
import {SectionWrapper} from '../sections/Section.styles';
import {CollapseItem} from '../../elements';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import {CollapseListProps} from 'epic-ue-shared';

export const CollapseWrapper = styled.div`
    padding: 0 0 10rem 0;
    ${p => p.theme.breakpoints.up('md')} {
        min-height: 600px;
    }
    h2 {
        text-align: center;
        position: relative;
        margin-left: auto !important;
        margin-right: auto !important;
    }
`;

export const ListContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

export const CollapseList = ({title, items}: CollapseListProps): JSX.Element => {
    const [activeTab, setActiveTab] = React.useState('item-0');
    const [inView, setInView] = React.useState(false);
    const onClick = React.useCallback(
        e => {
            const clickedTab = e.currentTarget;
            const {id} = clickedTab;
            setActiveTab(activeTab === id ? '' : id);
        },
        [activeTab, setActiveTab]
    );
    const visChange = React.useCallback(
        isVisible => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    let CollapseItems: (JSX.Element | undefined)[] = [<div key="collapseList-empty" />];
    if (items && items.length > 0) {
        CollapseItems = items.map((item, index) => {
            const key = `collapse-${index}`;
            const {title, desc} = item;
            const activeItem = activeTab === key;
            return (
                <CollapseItem
                    key={key}
                    title={title}
                    desc={desc}
                    onClick={onClick}
                    id={key}
                    active={activeItem}
                />
            );
        });
    }

    return (
        <VisibilitySensor onChange={visChange} active={!inView} partialVisibility minTopValue={100}>
            <SectionWrapper>
                <div className="container">
                    {title ? <H2>{title}</H2> : ''}
                    <div className="row">
                        <ListContainer>{CollapseItems}</ListContainer>
                    </div>
                </div>
            </SectionWrapper>
        </VisibilitySensor>
    );
};
