import React from 'react';
import PropTypes from 'prop-types';

export default class Html extends React.PureComponent {
    static propTypes = {
        html: PropTypes.string
    };

    render() {
        const html = this.props.html || '';
        return <div className="html" dangerouslySetInnerHTML={{__html: html}} />;
    }
}
