import React from 'react';

import {useCmsData} from '../shared';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, Section, SectionWrapper, Features, Button} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import ErrorPage from './ErrorPage';
import {FeaturesProps, PageData, SectionsProps} from 'epic-ue-shared';

const Release = styled.div`
    section {
        background: transparent;
        border: 0;
        padding: 25px 0 25px 0;
        min-height: 10vh;
        h3 {
            line-height: 1.3em;
        }
        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 50px;
        }
        ${(p) => p.theme.breakpoints.down('sm')} {
            padding: 0;
        }
        .news {
            padding-bottom: 6em;
            .btn:not(.blank) {
                opacity: 1;
                transform: translate3d(0, 0, 1);
            }
            .feed-view {
                .container {
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
                        width: auto;
                    }
                }
            }
        }
        .see-more-wrapper {
            margin: 0 auto;
            text-align: center;
            ${(p) => p.theme.breakpoints.down('sm')} {
                margin: 0 auto 40px;
            }
        }
    }

    .border-top {
        .hr {
            padding: 2rem 0;
            border-width: 1px 0 0;
            border-style: solid;
            border-image: linear-gradient(
                    90deg,
                    rgba(230, 230, 230, 0),
                    #333 50%,
                    rgba(230, 230, 230, 0) 100%
                )
                5%;
            max-width: 71.25rem;
            margin: 0 auto;
            display: block;
        }
    }

    .sections > section:first-child {
        padding: 50px 0 0;
    }

    @media only screen and (min-width: 1600px) {
        .container {
            width: 1430px;
        }
    }

    @media (min-width: 1600px) {
        .container {
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

const Header = styled.div`
    margin-top: 1em;
    text-align: center;

    .h2,
    .desc {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        ${(p) => p.theme.breakpoints.down('lg')} {
            width: 100%;
        }
    }

    .h2 {
        margin-bottom: 1.5em;
    }

    .desc {
        margin-bottom: 4em;
        line-height: 1.4em;
        span {
            line-height: 1.4em;
        }
        font-size: 20px;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin: 2em 0;
            font-size: 16px;
        }
    }
`;

const ReleaseSections = styled.div`
    &&& {
        .card-copy a {
            text-decoration: none;
        }
    }

    margin-bottom: 2em;
`;

const cmsUrlPattern = '/release';

interface IndustryPagePageData extends PageData {
    title?: string;
    desc: string;
    sections?: SectionsProps;
    features: {
        title?: string;
        desc?: string;
        href?: string;
        label?: string;
        features: FeaturesProps[];
    };
}

const ReleasePage = ():JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<IndustryPagePageData>(cmsUrlPattern);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const {title, desc} = pageData;
    const sectionWrap = pageData.sections || ({} as SectionsProps);
    const sections = sectionWrap.sections || [];

    const features = pageData.features || [];
    const featureTitle = features.title || '';
    const featureDesc = features.desc || '';
    const featureDetailHref = features.href || '';
    const featureDetailLabel = features.label || '';
    const featureList = features.features || [];

    let sectionList: (JSX.Element | undefined)[] = [<div key="empty-top-section" />];
    if (sections && sections.length >= 1) {
        sectionList = sections.map((section, index) => {
            const i = index;
            let even = false;
            if (i % 2 === 0) {
                even = true;
            }
            if (section) {
                return (
                    <div
                        className={` ${i > 0 && !section.hideBorder ? 'border-top' : ''}`}
                        key={`s-${i}-${section.title}`}>
                        {section.type ? (
                            <span className={` ${i > 0 && !section.hideBorder ? 'hr' : ''}`} />
                        ) : (
                            ''
                        )}
                        <Section {...section} even={even} />
                    </div>
                );
            }
        });
    }

    let seeMoreFeaturesButton = <div />;
    if (features && featureDetailHref && featureDetailLabel) {
        seeMoreFeaturesButton = (
            <div className="container">
                <div className="row see-more-wrapper">
                    <Button
                        as="a"
                        id={`see-more-features`}
                        target={'_blank'}
                        href={featureDetailHref}
                        type="secondary">
                        {featureDetailLabel}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <Page>
            <Release key={cmsUrlPattern}>
                {getHelmetForMetaTags(pageData)}

                <Header className="container">
                    <h1 className="h2">{title}</h1>
                    <div className="desc" dangerouslySetInnerHTML={{__html: desc}} />
                </Header>

                <ReleaseSections className="sections">
                    <SectionWrapper>{sectionList}</SectionWrapper>
                    {featureList && featureList.length > 1 ? (
                        <SectionWrapper>
                            <Features
                                features={featureList}
                                title={featureTitle}
                                desc={featureDesc}
                            />
                            {seeMoreFeaturesButton}
                        </SectionWrapper>
                    ) : (
                        <div />
                    )}
                </ReleaseSections>
            </Release>
        </Page>
    );
};

ReleasePage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default ReleasePage;
