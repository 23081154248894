import {useState, useEffect, useMemo} from 'react';
import {useSharedValue, createSharedValueKey} from '@epic-core/hooks';
import {feedApi} from './feed.api';
import {
    getFiltersFromUrl,
    objectsDiffer,
    getPageFromParsedUrl,
    processFeedResponse
} from '../utils';
import {AltLocPageData, useCmsData, AppSharedKeys, isBlogTemplate} from 'epic-ue-shared';
import {getLocale} from '@epic-core/common';
import {getNoTrailingSlash} from '@epic-mw/common-utils';
import {FeedStoreProps, FilterStore} from '../types';
import parse from 'url-parse';
import {isError} from '@epic-mw/error-types';

export const feedLatestStoriesKey = createSharedValueKey('feedLatestStories', []);

interface FeedParams {
    pathname?: string;
    search?: string;
    types?: FilterStore;
    tags?: FilterStore;
    patternsToSkip?: string[];
}

interface FeedLoadingResponse {
    loading: boolean;
    error?: string;
}

interface FeedLatestStoriesResponse extends FeedLoadingResponse {
    latestStories: any[];
}

interface FeedResponse extends FeedLoadingResponse {
    feed: FeedStoreProps;
    getNextPage: any;
}
interface FeedStoreItemResponse {
    isBlogDetails: any;
    cmsLoading: boolean;
    cmsLoadedEmpty?: boolean;
    feedItem: any;
    source: string;
    urlPattern: any;
}

export const useFeedLatestStories = (): FeedLatestStoriesResponse => {
    const [loadingState, setLoadingState] = useState<
        FeedLoadingResponse & {
            loaded: boolean;
        }
    >({loading: false, loaded: false, error: ''});

    const [latestStories, setLatestStories] = useSharedValue(feedLatestStoriesKey);

    useEffect(() => {
        if (
            (latestStories && latestStories.length && !loadingState.error) ||
            loadingState.loading
        ) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = (await feedApi.getLatestStories()) || {};
                const latestStories = data.latestStories || [];
                setLatestStories(latestStories);
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to latest feed stories data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [latestStories, loadingState]);

    return useMemo(() => {
        return {
            latestStories,
            ...loadingState
        };
    }, [latestStories.length, loadingState.loading, loadingState.loaded]);
};

export const useFeed = ({
    pathname,
    search,
    types,
    tags,
    patternsToSkip
}: FeedParams): FeedResponse => {
    const parsedUrl = parse((pathname as any) + search, true);
    const requestedPageNum = getPageFromParsedUrl(parsedUrl);

    const [loadingState, setLoadingState] = useState<
        FeedLoadingResponse & {
            loadingNext?: boolean;
        }
    >({loading: false, error: ''});

    const [feed, setFeed] = useSharedValue<FeedStoreProps>(AppSharedKeys.FeedStore);
    const currentFilters = feed.filters || {};
    const items = feed.items || [];
    const paging = feed.paging || {};
    const currentPageNum = paging.page || 1;
    const initialized = !!feed.pageModel && !!feed.pageModel._metaTags;

    let finalTypes = types || {};
    let finalTags = tags || {};
    if (pathname) {
        const derivedFilters = getFiltersFromUrl(pathname) || {};
        if (derivedFilters.types) {
            finalTypes = derivedFilters.types;
        }
        if (derivedFilters.tags) {
            finalTags = derivedFilters.tags;
        }
    }

    let diff = false;
    if (
        objectsDiffer(currentFilters.tags, finalTags) ||
        objectsDiffer(currentFilters.types, finalTypes)
    ) {
        diff = true;
    }

    if (requestedPageNum !== currentPageNum) diff = true;

    useEffect(() => {
        if ((initialized && !diff) || loadingState.loading || loadingState.error) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true});
                const data =
                    (await feedApi.filterFeed(
                        finalTypes,
                        finalTags,
                        requestedPageNum || 1,
                        initialized,
                        patternsToSkip
                    )) || {};
                const processedData: FeedStoreProps = processFeedResponse(data, feed);
                setFeed(processedData);
                setLoadingState({loading: false});
            } catch (ex) {
                console.error('Failed to latest feed data', ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, error});
            }
        }
        fetchData();
    }, [items, diff, finalTypes, finalTags, paging, initialized, patternsToSkip, loadingState]);

    const loading = loadingState.loading || diff || !initialized;
    return useMemo(() => {
        return {
            feed,
            loading,
            error: loadingState.error
        } as FeedResponse;
    }, [items, finalTypes, finalTags, loading, loadingState.loadingNext, paging, currentPageNum]);
};

export const useFeedStoreItem = (cmsUrlPattern: string): FeedStoreItemResponse => {
    const validUrlPattern = !!cmsUrlPattern;
    const urlPattern = cmsUrlPattern.length > 1 ? getNoTrailingSlash(cmsUrlPattern) : cmsUrlPattern;
    const [feed] = useSharedValue(AppSharedKeys.FeedStore);
    const items = feed.items || [];
    let feedItem;
    let source = '';
    items.forEach((item) => {
        if (
            (!feedItem && item._urlPattern === urlPattern) ||
            item._urlPattern === `/${getLocale()}${urlPattern}` ||
            item._hasAltLocPageData
        ) {
            feedItem = item;
            source = 'feed';
        }
    });

    const {
        pageData,
        cmsLoading,
        cmsLoadedEmpty
    }: {
        pageData: AltLocPageData;
        cmsLoading: boolean;
        cmsLoadedEmpty?: boolean;
    } = useCmsData(!feedItem ? urlPattern || '' : '');
    if (
        (pageData && pageData._urlPattern === urlPattern) ||
        pageData._urlPattern === `/${getLocale()}${urlPattern}` ||
        pageData._hasAltLocPageData
    ) {
        feedItem = pageData;
        source = 'cms';
    }

    const isBlogDetails = isBlogTemplate(feedItem);

    return {
        feedItem,
        source,
        isBlogDetails,
        urlPattern,
        cmsLoading: validUrlPattern && cmsLoading,
        cmsLoadedEmpty: validUrlPattern && cmsLoadedEmpty
    };
};
