import styled from 'styled-components';
import {BACKGROUND_COLOR, TEXT_COLOR} from '../../constants';

export const ShareIconsWrapper = styled.div`
    .blog-social {
        position: absolute;
        top: 11.5em;
        margin-left: -6em;
        z-index: 998;
        opacity: 1;

        ${(p) => p.theme.breakpoints.down('lg')} {
            margin-left: 0;
            bottom: 60px;
            top: auto;
            background: #f6f7f9;
            border-top: 1px solid rgba(${BACKGROUND_COLOR}, 0.1);
            left: 0;
            right: 0;
            transform: translateY(0);
            text-align: center;
            position: fixed;
            padding: 0.5em 0;
            transition: transform 0.3s ease-in-out;
            height: 60px;
        }

        &.top {
            top: 15.2em;
            opacity: 1;

            ${(p) => p.theme.breakpoints.down('lg')} {
                top: auto;
            }
        }

        &.bottom {
            top: auto;
            bottom: 0;
        }

        ${(p) => p.theme.breakpoints.down('lg')} {
            transform: translateY(100%);
        }

        &.fixed {
            position: fixed;

            ${(p) => p.theme.breakpoints.down('lg')} {
                bottom: 60px;
            }
        }

        .social-count {
            text-align: center;
            font-size: 0.85em;
            font-weight: bold;
            vertical-align: middle;

            ${(p) => p.theme.breakpoints.down('lg')} {
                display: inline-block;
                margin-right: 0.25em;
                margin-top: 0.75em;
            }

            .social-count-subtext {
                font-weight: normal;
            }

            .count {
                display: block;
                font-weight: bold;

                ${(p) => p.theme.breakpoints.down('lg')} {
                    display: inline-block;
                    margin-right: 0.25em;
                }

                span {
                    padding-right: 0.5em;
                }

                .social-count-subtext {
                    font-weight: normal;
                }
            }
        }

        i {
            color: ${TEXT_COLOR};
            display: block;
            width: 2em;
            height: 2em;
            border-radius: 50%;
            opacity: 1;
            padding: 0.5em;
            cursor: pointer;
            margin: 0.5em auto;
            backface-visibility: hidden;
            transition: opacity 0.3s ease-in-out;

            ${(p) => p.theme.breakpoints.down('lg')} {
                display: inline-block;
                margin: 0.5em;
            }

            &.facebook {
                background-color: #236293;
            }

            &.twitter {
                background-color: #38ace0;
            }

            &.google-plus {
                background-color: #d6492b;
            }

            &.vk {
                background-color: #456890;
            }

            &.reddit {
                background-color: rgb(255, 133, 88);
            }

            &.tumblr {
                background-color: #36465d;
            }

            &.linkedin {
                background-color: #0077b5;
            }

            &.weibo {
                background-color: #f7671d;
            }

            &.wechat {
                background-color: #10cf1f;
            }

            &:hover {
                opacity: 0.8;
            }

            .wechat-share {
                position: relative;
                display: inline;
            }

            .qrcode-block {
                background-color: ${TEXT_COLOR};
                display: none;
                position: absolute;
                bottom: 0;
                left: 3em;

                canvas {
                    padding: 6px 6px 0;
                }
            }
        }

        &:hover {
            .qrcode-block {
                display: block;
            }
        }
    }
`;
