import styled, {keyframes} from 'styled-components';

export const gradientBG = keyframes`
    0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const bobbing = keyframes`
	0% {
		transform: translate3d(0, 0, 0);
	}
    50% {
		transform: translate3d(25px,-25px, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
`;

export const GradientOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    bottom: 0;
    z-index: 0;
    span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background: linear-gradient(180deg, rgba(0, 212, 255, 0) 0%, rgba(0, 0, 0, 0.33) 100%);
    }
`;

export const AnimatedBgwrapper = styled.div`
    &:not(.is-bg-media) {
        width: 100%;
        height: 60vh;
        @media screen and (max-height: 700px) {
            height: 600px;
        }
        @media screen and (min-height: 1200px) and (max-width: 1100px) {
            height: 600px;
        }
        ${(p) => p.theme.breakpoints.up('xxl')} {
            height: 50vh;
        }
        margin-top: 50px;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 500px;
            margin-top: 0;
        }
        * {
            text-align: center;
        }
    }

    &.is-bg-media {
        width: 100%;
        height: 100%;
    }
`;

export const Decals = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(-45deg, #ee7752, #871c59, #026bc4, #23d5ab);
    background-size: 400% 400%;
    animation: ${gradientBG} 10s ease infinite;

    @media (prefers-reduced-motion: reduce) {
        animation: none;
    }

    .circle,
    .cross,
    .circle-border,
    .dots {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.1);
        animation: ${bobbing} 10s ease infinite;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }

        display: block;
    }

    .circle-border,
    .circle {
        width: 150px;
        height: 150px;
        border-radius: 400px;
        &.blur {
            filter: blur(15px);
            transform: translate3d(0, 0, 0);
        }
    }

    .circle-border {
        width: 300px;
        height: 300px;
        background: transparent;
        border: 2px solid rgba(255, 255, 255, 0.1);
    }

    .cross {
        width: 50px;
        height: 50px;
        background: transparent;
        &:before,
        &:after {
            position: absolute;
            left: 25px;
            content: ' ';
            height: 50px;
            width: 2px;
            background-color: rgba(255, 255, 255, 0.1);
        }
        &:after {
            transform: rotate(90deg);
        }
    }

    .dots {
        i {
            width: 10px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.1);
            position: absolute;
            top: 0;
            border-radius: 400px;
            &:nth-child(0) {
                top: 0;
                left: 0;
            }
            &:nth-child(1) {
                top: 15px;
                left: 0;
            }
            &:nth-child(2) {
                top: 15px;
                left: 15px;
            }
            &:nth-child(3) {
                top: 30px;
                left: 0;
            }
            &:nth-child(4) {
                top: 30px;
                left: 15px;
            }
            &:nth-child(5) {
                top: 30px;
                left: 30px;
            }
        }
    }

    > div:nth-child(0) {
        left: 200px;
        bottom: 50px;
        animation-duration: 10s;
    }

    > div:nth-child(1) {
        left: 80px;
        bottom: 50px;
        animation-duration: 8s;
    }

    > div:nth-child(2) {
        left: auto;
        right: 50px;
        bottom: 50px;
        animation-duration: 6s;
        ${(p) => p.theme.breakpoints.down('sm')} {
            display: none;
        }
    }

    > div:nth-child(3) {
        left: 175px;
        bottom: 20px;
        animation-duration: 11.5s;
    }

    > div:nth-child(4) {
        left: 20px;
        bottom: 200px;
        animation-duration: 7.3s;
        ${(p) => p.theme.breakpoints.down('sm')} {
            display: none;
        }
    }

    > div:nth-child(5) {
        left: auto;
        right: 75px;
        bottom: 100px;
        animation-duration: 8s;
    }

    > div:nth-child(6) {
        left: 200px;
        bottom: 50px;
        animation-duration: 9.6s;
    }

    > div:nth-child(7) {
        left: auto;
        right: 150px;
        bottom: 50px;
        animation-duration: 6.5s;
    }

    > div:nth-child(8) {
        left: 300px;
        bottom: 150px;
        animation-duration: 5.5s;
    }

    > div:nth-child(9) {
        left: 200px;
        bottom: 50px;
        animation-duration: 10s;
    }

    > div:nth-child(10) {
        left: 50px;
        bottom: 20px;
        animation-duration: 9s;
    }

    > div:nth-child(11) {
        left: 100px;
        bottom: 150px;
        animation-duration: 7.3s;
    }

    > div:nth-child(12) {
        right: 200px;
        left: auto;
        bottom: 50px;
        animation-duration: 4s;
    }
    > div:nth-child(13) {
        right: 100px;
        left: auto;
        bottom: 400px;
        width: 200px;
        height: 200px;
        animation-duration: 3.7s;
    }
`;
export const AnimatedContent = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .row {
        align-items: center;
        justify-content: center;
        ${(p) => p.theme.breakpoints.down('sm')} {
            text-align: center;
        }
        h2,
        .copy {
            color: ${(p) => p.theme.palette.common.white};
        }
        .copy {
            margin: 10px auto;
        }

        h2,
        .copy {
            text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
        }
        .btn {
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        }
    }
`;
