import React from 'react';
import {getBowserState} from '@epic-core/common';
import {ContentContainer} from '../layout/ContentContainer';
import {BlogPostProps} from 'epic-ue-shared';
import {BlogContentGridRowWrapper} from './BlogContentGridRow.styles';

const placeholderImg = 'https://cdn2.unrealengine.com/placeholder-375x275-fb892cccc7d0.jpg';

const getImage = (post) => {
    if (post && typeof post.feedImages === 'object') {
        const {mobile, tablet} = getBowserState();
        let img = post.feedImages.desktopImage;
        if (mobile && post.feedImages.mobileImage) img = post.feedImages.mobileImage;
        if (tablet && post.feedImages.tabletImage) img = post.feedImages.tabletImage;
        return img || placeholderImg;
    }
    if (post && typeof post.article === 'object') {
        return post.article.feedImg || placeholderImg;
    }
    if (post && post.image) {
        return post.image;
    }
    return placeholderImg;
};

interface BlogContentGridRowProps {
    posts: BlogPostProps[];
    maxRowSize: number;
    routerRootUrl: string;
    linkUrlPrefix?: string;
}

/**
 * @implements {ContentContainer}
 */
const BlogContentGridRow = ({
    maxRowSize,
    posts,
    routerRootUrl,
    linkUrlPrefix
}: BlogContentGridRowProps): JSX.Element => {
    const gridNum = parseInt(((12 / maxRowSize) as unknown) as string, 10);

    const gridItems = posts.map((post, index) => {
        const slotStyle = {
            backgroundImage: `url("${getImage(post)}")`
        };
        const classNames = `activity-item col-xs-${gridNum} content-toss-item`;
        const uniqueKey = (post && post._id) || Math.random() * 100;

        return (
            <ContentContainer
                post={post}
                key={uniqueKey}
                enableAutoShortDescription={true}
                descriptionLimit={75}
                appendClassToParent={classNames}
                routerRootUrl={routerRootUrl}
                linkUrlPrefix={linkUrlPrefix}
                includeImage={true}
                slotStyle={slotStyle}
            />
        );
    });

    return (
        <BlogContentGridRowWrapper className="container activityGridRow content-grid-row">
            <div className="row match-heights content-toss-container">
                <div className="content-toss">{gridItems}</div>
            </div>
        </BlogContentGridRowWrapper>
    );
};

export default BlogContentGridRow;
