import React, {useCallback} from 'react';
import {getBowserState, generateRoutePath} from '@epic-core/common';
import {useNavigate} from 'react-router';
import {useFeedContentTypes} from '../../shared';
import {MatchingTag} from '../tag/MatchingTag';
import styled from 'styled-components';
import {FeedItemProps} from '../../types';

const getLocalizedTagMatch = (props) => {
    const tagMatches = props.tagMatch;
    if (tagMatches && tagMatches.length) {
        return tagMatches.map((match) => {
            let t = match;
            const lower = match.toLowerCase();
            const localizedTags = props.item.localizedTags;
            if (localizedTags && typeof localizedTags === 'object') {
                Object.keys(localizedTags).forEach((locTag) => {
                    const cleanLocTag = locTag.replace(/^\s+|\s+$|"/g, '').toLowerCase();
                    if (cleanLocTag && cleanLocTag === lower) {
                        t = localizedTags[locTag];
                    }
                });
            }
            if (t.length === 2) t = t.toUpperCase();
            return t;
        });
    }
    return '';
};

interface Properties {
    tagMatch: boolean | string[];
    item: FeedItemProps;
    disableClick?: boolean;
    children?: React.ReactNode;
}

const ContentTypeWrapper = styled.span`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.clickable {
        cursor: pointer;
    }

    & > div {
        margin-right: 10px;
        margin-bottom: 10px;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        border-radius: 20px;
    }
`;

const FeedContentType = styled.div`
    padding: 5px 10px;
    text-transform: uppercase;
    opacity: 1;
    color: #fff;
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity 0.1s ease-in-out;
    user-select: none;
    text-align: center;

    ${(p) => p.theme.breakpoints.down('lg')} {
        padding: 5px;
        font-size: 0.75em;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 4px;
        font-size: 0.7em;
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 4px;
        font-size: 0.65em;
    }
    &:hover {
        opacity: 0.85;
    }
    &:hover,
    &:active,
    &:focus {
        outline: none;
        border: 0;
        box-shadow: none;
        color: #fff;
    }
`;

const ContentType = (props: Properties): JSX.Element => {
    const {contentTypes = []} = useFeedContentTypes() || {};
    const item = props.item || {};
    const slug = item._feedSlug || '';
    const label = item._feedTypeLabel || '';
    const {disableClick} = props;
    const noClick = disableClick;
    const navigate = useNavigate();
    const onClick = useCallback(
        (e) => {
            if (noClick) return;
            e.preventDefault();
            e.stopPropagation();
            const target = e.target || {};
            const className = target.className || '';
            if (className.indexOf('matching-tag') > -1) return; //clicks on the tag we should ignore

            if (slug === 'trending') return;
            navigate(generateRoutePath(`/feed/${slug || 'all'}`));
        },
        [slug, navigate, noClick]
    );

    const tagMatches = getLocalizedTagMatch(props);
    const {mobile, tablet} = getBowserState();

    let color = '#10b4f5';

    if (contentTypes && contentTypes.length) {
        contentTypes.forEach((type) => {
            if (type.hexColor && type.slug === slug) {
                color = type.hexColor;
            }
        });
    }

    const styles = {
        backgroundColor: color,
        maxWidth: '150px'
    };

    const className = !noClick ? 'clickable' : '';

    return (
        <ContentTypeWrapper role="presentation" onClick={onClick} className={className}>
            <FeedContentType className={`feed-content-type ${slug}`} style={styles}>
                {label}
                <div className="label-bg" />
            </FeedContentType>
            {!mobile && !tablet && tagMatches && tagMatches.length
                ? tagMatches.map((tagMatch) => (
                      <MatchingTag key={tagMatch} tag={tagMatch} navigate={navigate} />
                  ))
                : ''}
            {props.children}
        </ContentTypeWrapper>
    );
};

export const ContentTypeLabel = ContentType;
