import React from 'react';
import PropTypes from 'prop-types';
import App from './App';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';
import {theme} from './appTheme';
import {Footer} from 'epic-ue-header-footer/src/components';
import parse from 'url-parse';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';
import {extractSlugLocale, withLocalization} from '@epic-mw/localization';
import {UeThemeProvider} from 'epic-ue-theme';
import {BackgroundVideoStyles} from './components/video/BackgroundVideo.styles';
import 'epic-ue-ui/dist/polyfills';
import {createGlobalStyle} from 'styled-components';
import {NonProdFeatures} from './NonProdFeatures';
import {errorFetcher} from 'epic-ue-shared/dist/shared/error.fetch';
import {UnrealUIAppContext} from './UnrealUIAppContext';
import {setLocaleClassOnBody, initBowser, setUserAgent} from '@epic-mw/deprecated-utils';
import {Navigation} from './Navigation';

const GlobalStyle = createGlobalStyle`
    html, body {
        background-color: ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.primary};
        margin: 0;
        font-family: OpenSans,sans-serif,arial;
        -webkit-font-smoothing: antialiased;
        -webkit-hyphenate-character: '-';
        &.ko {
            word-wrap: break-word;
            word-break: keep-all;
        }
    }
    .ue-react {
        background-color: ${(p) => p.theme.palette.background.default};
        color: ${(p) => p.theme.palette.text.primary};
        font-family: ${(p) => p.theme.ue.fonts.brutal.regular};
    }

`;

export const TransparentNav = createGlobalStyle`
    .ue-react #egh {
        &.search-open {
            #nav {
                background: ${(p) => p.theme.palette.neutral.concrete};
            }
        }
        #nav {
            transition: background 0.2s ${(p) => p.theme.easing};
            background: transparent;
            &:hover, &.search-open {
                background: ${(p) => p.theme.palette.neutral.concrete};
            }

            ${(p) => p.theme.breakpoints.down('md')} {
                background-color: ${(p) => p.theme.palette.neutral.concrete};
            }
        }
    }
`;

const sendAnalytics = (props) => {
    const {pathname} = parse(root.location.href);
    const {pageSlug} = extractSlugLocale(pathname);
    const nav = root._epicGamesNav || {};
    const browser = nav.browser || {};
    const os = browser.os || {};
    const innerBrowser = browser.browser || {};

    emitEvent({
        eventAction: 'client.load',
        interactionType: 'init',
        eventLabel: pageSlug
    });
    if (innerBrowser.name) {
        emitEvent({
            eventAction: 'client.browser',
            interactionType: 'init',
            eventLabel: `${innerBrowser.name} ${innerBrowser.version} | ${os.name}`
        });
    }
    root.addEventListener('beforeunload', () => {
        emitEvent({
            eventAction: 'client.unload',
            interactionType: 'exit',
            eventLabel: Date.now() - root.unrealEngine_start
        });
    });
};

class Entry extends React.Component {
    static propTypes = {
        supportedLocales: PropTypes.array,
        locale: PropTypes.string
    };

    componentDidMount() {
        initBowser();
        setUserAgent();
        setLocaleClassOnBody(this.props.locale);
        sendAnalytics(this.props);

        const jssStyles = root.document.querySelector('#jss-server-side-mui');
        if (jssStyles && jssStyles.parentNode) {
            try {
                jssStyles.parentNode.removeChild(jssStyles);
            } catch (e) {
                console.error(
                    'Entry componentDidMount() failed to remove the child node of #jss-server-side-mui',
                    e.message
                );
            }
        }
    }

    componentDidCatch(error, info) {
        console.log('componentDidCatch', info);
        console.error('Entry componentDidCatch() caught an error');
        console.error(error);

        emitEvent({
            eventAction: 'client.error',
            eventLabel: error
        });

        const errorToReport = error.message;
        errorFetcher.sendReport({errorToReport, errorInfo: info}).then(() => {
            setTimeout(() => {
                root.location.href = `/${getLocale()}/error-500?e=entrydidcatch`;
            }, 500);
        });
    }

    render() {
        const clientEnvConfig = root.clientEnvConfig || {};
        const epicEnv = clientEnvConfig.EPIC_ENV;
        const disableHeaderFooterPaths = ['/free-download/'];
        const disableCtaOnMobilePaths = ['/download'];

        return (
            <UeThemeProvider theme={theme}>
                <GlobalStyle />
                <BackgroundVideoStyles />

                <NonProdFeatures epicEnv={epicEnv} />

                <Navigation disablePaths={disableHeaderFooterPaths} disableCtaOnMobilePaths={disableCtaOnMobilePaths} />
                <UnrealUIAppContext>
                    <App />
                </UnrealUIAppContext>
                <Footer {...this.props} disablePaths={disableHeaderFooterPaths} />
            </UeThemeProvider>
        );
    }
}

export default withLocalization(Entry);
