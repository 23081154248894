import React, {useEffect, useCallback, useState} from 'react';
import {SimpleLink} from 'epic-ue-components';
import {getImageUrl, getTitle, getTagMatch, getShort, getSlugPath} from '../../../utils';
import VisibilitySensor from 'react-visibility-sensor';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import root from 'window-or-global';
import {getOptImage} from 'epic-ue-shared';
import {useLocalization} from '@epic-mw/localization';
import {FeedItemProps} from '../../../types';
import {TopFeedContainer} from './TopFeedItem.styles';
import {FeedHeading} from '../common';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';

interface Props {
    item: FeedItemProps;
}

export const TopFeedItem = (props: Props): JSX.Element => {
    const item = props.item || {};
    const [y, setY] = useState(0);
    const [mobile, setMobile] = useState(false);
    const [parallaxImage, setParallaxImage] = useState<HTMLDivElement | null>(null);
    const [inView, setInView] = React.useState(false);
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    const parallaxImageRef = useCallback((node) => {
        if (node !== null) {
            setParallaxImage(node);
        }
    }, []);

    const scroll = useCallback(() => {
        const scrollTop = root.pageYOffset || root.document.documentElement.scrollTop;
        if (parallaxImage && typeof parallaxImage.getBoundingClientRect === 'function') {
            const box = parallaxImage.getBoundingClientRect();
            const yPos = Math.round(scrollTop / 2);
            if (scrollTop < box.height) {
                setY(yPos);
            }
        }
    }, [parallaxImage, setY]);

    const resize = useCallback(() => {
        const ww =
            root.innerWidth ||
            root.document.documentElement.clientWidth ||
            root.document.body.clientWidth;
        if (ww <= 480 && !mobile) {
            setMobile(true);
        } else if (ww > 480 && mobile) {
            setMobile(false);
        }
    }, [mobile, setMobile]);

    useEffect(() => {
        root.addEventListener('resize', resize);
        root.addEventListener('scroll', scroll);
        return () => {
            root.removeEventListener('resize', resize);
            root.removeEventListener('scroll', scroll);
        };
    }, []);

    const getMessage = useLocalization();
    const {_slug} = item;
    const title = getTitle(item);
    const short = getShort(item);
    const _feedSlug = item._feedSlug || '';
    const imageUrl = getImageUrl(item, true);
    const matchTag = getTagMatch(item);
    const linkPath = getSlugPath(item);
    const bkgrdStyle = {
        backgroundImage: `url(${getOptImage({url: imageUrl, width: 1300})})`,
        transform: `translateY(${y}px)`,
        MsTransform: `translateY(${y}px)`,
        WebkitTransform: `translateY(${y}px)`
    };

    //there are no results in the feed
    if (!title && !_slug) {
        return (
            <TopFeedContainer className="top-feed-item feed-item no-results">
                <h2>{getMessage('epic.feed.no.results.header')}</h2>
                <p>{getMessage('epic.feed.no.results.content')}</p>
                <p>
                    <a href="/feed">{getMessage('epic.feed.reset.filters')}</a>
                </p>
            </TopFeedContainer>
        );
    }

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <FeedItemDefaultsWrapper>
                <TopFeedContainer className={`top-feed-item feed-item ${_feedSlug}`}>
                    <div className="container-image">
                        <SimpleLink href={linkPath} id={`feed-${_feedSlug}`} title={title}>
                            <div
                                ref={parallaxImageRef}
                                style={bkgrdStyle}
                                className="feature-image"
                            />
                            <div className="container-image-bg" />
                        </SimpleLink>
                    </div>
                    <div className="content">
                        <ContentTypeLabel item={item} tagMatch={matchTag} />
                        <div className="title">
                            <FeedHeading item={item} linkPath={linkPath} title={title} />
                        </div>
                        <div className="short" dangerouslySetInnerHTML={{__html: short || ''}} />
                    </div>
                    <div className="overflow-gradient" />
                </TopFeedContainer>
            </FeedItemDefaultsWrapper>
        </VisibilitySensor>
    );
};
