import styled from 'styled-components';

export const ArticleViewWrapper = styled.div`
    padding: 0;
    min-height: 60vh;
    .article-view.loading {
        min-height: unset;
    }
    &.loc-ar {
        direction: rtl;
    }

    .news > .news-main {
        position: relative;
        float: left;
        padding-left: 10px;
        width: 66.66667%;
        padding-right: 30px;
    }

    .transition-appear {
        opacity: 0.01;
    }

    .transition-appear.transition-appear-active {
        opacity: 1;
        transition: opacity 500ms ease-in-out;
    }

    /* BlogContentGridRowstyles */
    && .activityGridRow .activity-item {
        .short {
            display: block;
        }
        .headline a {
            color: initial;
        }
    }
`;
