import root from 'window-or-global';
import '@epic-core/common/src/trimPolyfill';
import {getBlogPost} from './BlogDetailUtils';
import {getLocale} from '@epic-core/common';
import {BlogDetailProps, BlogPostProps} from 'epic-ue-shared';

export interface BlogTag {
    name?: string;
    itemProp?: string;
    property?: string;
    content?: string;
}

/**
 * Concat '#' to the words
 * @example
 * "ue4, epicGames" => '#ue4, #epicGames'
 * @param  {string} defaultConfig twitter hashtag field from CMS
 * @return {string} formated version
 */
const generateTwitterTags = (defaultConfig: string): string => {
    let hashTags: string[] | string = '';
    if (defaultConfig) {
        hashTags = defaultConfig.split(',').map((tag) => {
            return tag.trim().replace(/\s/g, '');
        });
        hashTags.unshift('');
        hashTags = hashTags.join(' #').trim();
    }

    return hashTags;
};

const generateKeywords = (blog: BlogDetailProps, twitterDefaultTags: string): string => {
    const keywords: string[] = [];
    const post = getBlogPost(blog) as BlogPostProps;
    if (post.tags && post.tags.length) {
        const tagArray = post.tags.split(',');
        tagArray.forEach((tag) => {
            if (tag) {
                const trimmed = tag.trim();
                if (trimmed) {
                    keywords.push(trimmed);
                }
            }
        });
    }
    if (twitterDefaultTags) {
        twitterDefaultTags.split(',').forEach((tag) => {
            if (tag) {
                const trimmed = tag.trim();
                if (trimmed) {
                    keywords.push(trimmed);
                }
            }
        });
    }
    return keywords.join(',');
};

const generateDesc = (blog: BlogDetailProps): string => {
    const post = getBlogPost(blog) as BlogPostProps;
    let shortDesc = '';

    if (blog.metaTagDesc) {
        shortDesc = blog.metaTagDesc;
    }

    if (!shortDesc && post.short) {
        shortDesc = post.short.replace(/(<([^>]+)>)/gi, '');
    }
    return shortDesc;
};

const generateTitle = (blog: BlogDetailProps, overrideMetaTitle?: string): string => {
    let title: string | undefined = overrideMetaTitle;

    if (!title) {
        const metaTags = blog._metaTags || null;
        const titleMatches = metaTags ? metaTags.match(/<title>(.*?)<\/title>/gim) : [];
        if (titleMatches && titleMatches.length) {
            title = titleMatches[0].replace('<title>', '').replace('</title>', '');
        }
    }

    if (!title && blog.metaTagTitle) {
        title = blog.metaTagTitle;
    }

    if (!title) {
        title = blog._title;
        const locale = getLocale();
        if (locale !== 'en-US') {
            const post = getBlogPost(blog) as BlogPostProps;
            if (post && post.title) {
                title = post.title;
            }
        }
    }

    if (root.document && title) {
        root.document.title = title;
    }

    return title as string;
};

const getTagsFromModel = (blog: BlogDetailProps): BlogTag[] => {
    const metaTags = blog._metaTags || null;
    const tagMatches = metaTags ? metaTags.match(/<meta(.*?)>/gim) : [];

    const tags: BlogTag[] = [];
    if (tagMatches && tagMatches.length) {
        tagMatches.forEach((t) => {
            const tag: BlogTag = {};

            let name: string | RegExpMatchArray | null = t.match(/name="(.*?)"/);
            if (name && name[1]) {
                name = name[1];
                tag.name = name;
            }

            let property: string | RegExpMatchArray | null = t.match(/property="(.*?)"/);
            if (property && property[1]) {
                property = property[1];
                tag.property = property;
            }

            let content: string | RegExpMatchArray | null = t.match(/content="(.*?)"/);
            if (content && content[1]) {
                content = content[1];
                tag.content = content;
            }

            tags.push(tag);
        });
    }

    return tags;
};

const getMetaTags = (
    locale: string,
    blog: BlogDetailProps,
    twitterDefaultTags: string,
    overrideMetaTitle?: string
): BlogTag[] => {
    const shortDesc = generateDesc(blog);
    const title = generateTitle(blog, overrideMetaTitle);
    const hashTags = generateTwitterTags(twitterDefaultTags);
    const post = getBlogPost(blog) as BlogPostProps;
    const shareImage = post.shareImage || post.image || post.trendingImage;
    const keywords = generateKeywords(blog, twitterDefaultTags);
    const htmlFieldMetaTags = getTagsFromModel(blog);

    const meta = [
        {itemProp: 'name', content: title},
        {property: 'og:title', content: title},
        {name: 'twitter:title', content: title},

        {itemProp: 'description', content: shortDesc},
        {name: 'twitter:description', content: shortDesc},
        {name: 'description', content: shortDesc},
        {property: 'og:description', content: shortDesc},

        {itemProp: 'image', content: shareImage},
        {name: 'twitter:image', content: shareImage},
        {property: 'og:image', content: shareImage},

        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:hashTags', content: hashTags},
        {property: 'og:type', content: 'article'},
        {property: 'og:locale', content: locale ? locale.replace('-', '_') : locale},
        {name: 'keywords', content: keywords}
    ];

    return htmlFieldMetaTags.concat(meta);
};

/**
 * Returns the first occurrance of each meta tag based on itemProp, name, and property keys
 * @param  {Array} meta tag array
 * @return {Array} meta tag array without duplicate keys
 */
const getUniqueMetaData = (meta: BlogTag[]): BlogTag[] => {
    const uniqueTags: BlogTag[] = [];
    const itemProps = {};
    const names = {};
    const properties = {};

    if (meta && meta.length) {
        meta.forEach((m) => {
            if (m.itemProp) {
                if (!itemProps[m.itemProp]) {
                    uniqueTags.push(m);
                    itemProps[m.itemProp] = true;
                }
            } else if (m.name) {
                if (!names[m.name]) {
                    uniqueTags.push(m);
                    names[m.name] = true;
                }
            } else if (m.property) {
                if (!properties[m.property]) {
                    uniqueTags.push(m);
                    properties[m.property] = true;
                }
            }
        });
    }
    return uniqueTags;
};

export {getMetaTags, generateTwitterTags, getTagsFromModel, getUniqueMetaData, generateTitle};
