import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';
import {getCardImage} from '../../../../utils/enterpriseUtils';

export default class RowType1 extends Row {
    static propTypes = {
        items: PropTypes.array,
        firstIsFeatured: PropTypes.bool,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        const items = this.props.items || [];

        let type = getCardImage(items[0]) ? 'horizontal' : 'small-block';
        if (this.props.firstIsFeatured) {
            type = 'featured';
        }

        return (
            <div
                className="grid-news-row type-1"
                ref={(c) => {
                    this.gridRowRef = c;
                }}>
                <Card {...this.props} {...this.state} highlight={items[0]} type={type} />
            </div>
        );
    }
}
