import React from 'react';
import PropTypes from 'prop-types';
import Card from './Card';
import Row from './Row';

export default class RowType4b extends Row {
    constructor(props) {
        super(props);
        this.orderedItems = props.bestFitToRow([1, 1, 2, 2], props.items);
    }

    static propTypes = {
        items: PropTypes.array,
        bestFitToRow: PropTypes.func,
        locale: PropTypes.string,
        titleFitText: PropTypes.object,
        textFitText: PropTypes.object,
        adjustBreakpoints: PropTypes.bool
    };

    render() {
        return (
            <div
                className="grid-news-row type-4b"
                ref={(c) => {
                    this.gridRowRef = c;
                }}>
                <div className="grid-news-cell grid-news-cell-column w66">
                    <div className="grid-news-cell-inner block">
                        <Card
                            {...this.props}
                            {...this.state}
                            highlight={this.orderedItems[0]}
                            type="block"
                        />
                        <Card
                            {...this.props}
                            {...this.state}
                            highlight={this.orderedItems[1]}
                            type="block"
                        />
                    </div>
                    <Card
                        {...this.props}
                        {...this.state}
                        highlight={this.orderedItems[2]}
                        type="horizontal"
                    />
                </div>
                <Card
                    {...this.props}
                    {...this.state}
                    highlight={this.orderedItems[3]}
                    type="vertical"
                />
            </div>
        );
    }
}
