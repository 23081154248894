import React from 'react';
import root from 'window-or-global';
import {getLocale} from '@epic-core/common';
import {useAccountStore} from 'epic-ue-shared';
import {UnrealLoading} from 'epic-ue-loading';
import {FieldGroupProps, GenericMap, UEForm} from './index';
import {SalesforceSimpleFormWrapper} from './SalesforceSimpleForm.styles';
import {AccentColorOptions, useCurrentAccentColor} from 'epic-ue-theme';
import {CenteredTextBlock, CenteredTextBlockProps, GridContainer, VerticalSpacer} from 'epic-ue-ui';

export interface SalesforceSimpleFormProps {
    centeredTextBlock?: CenteredTextBlockProps;
    form?: any;
    formId?: string;
    successRedirectUrl?: string;
    requireAuthentication?: boolean;
    formType?: string;
    customEndpointUrl?: string;
    loadingNode?: React.ReactNode;
    noVerticalSpacer?: boolean;
    messages?: {[key: string]: string | undefined};
    initalValues?: GenericMap;
    fieldAdjustments?: any;
    preSubmitMutateFn?: (fieldGroup: FieldGroupProps, values: GenericMap) => GenericMap;
    postSubmitFn?: (response: GenericMap) => void;
    forceGlobalEmailOptIn?: boolean;
}

export const SalesforceSimpleForm = (props: SalesforceSimpleFormProps): JSX.Element => {
    const {
        centeredTextBlock,
        form = {},
        formId = '',
        successRedirectUrl = '',
        requireAuthentication = false,
        formType,
        customEndpointUrl,
        loadingNode,
        noVerticalSpacer,
        messages,
        initalValues,
        fieldAdjustments,
        preSubmitMutateFn,
        postSubmitFn
    } = props;

    const {name} = useCurrentAccentColor();
    const accentColor = (name as AccentColorOptions) || 'sky';

    const {account, loading, loaded} = useAccountStore();
    const isLoggedIn = account.isLoggedIn;
    if (requireAuthentication && loaded && !loading && !isLoggedIn) {
        root.location.href = `/${getLocale()}/auth?state=${root.location.href}`;
    }
    const accountLoading = !loaded || loading;

    if (accountLoading) {
        return <UnrealLoading fillViewHeight={false} />;
    }

    const mutableForm =
        typeof form.asMutable === 'function' ? form.asMutable({deep: true}) : form || {};
    const fieldGroups = mutableForm.fieldGroups || [];
    const formMessages = mutableForm.messages || {};

    const redirectToSuccessUrl = () => {
        setTimeout(() => {
            if (root && root.location && successRedirectUrl) {
                root.location.href = successRedirectUrl;
            }
        }, 5000);
    };

    const onPostSubmit = (result: GenericMap) => {
        if (successRedirectUrl) {
            if (result && result.success) {
                redirectToSuccessUrl();
            } else {
                setTimeout(() => {
                    root && root.window && root.window.location.reload();
                }, 5000);
            }
        } else if (postSubmitFn && typeof postSubmitFn === 'function') {
            postSubmitFn(result);
        }
    };

    let finalFieldAdjustments: any;
    if (fieldAdjustments) {
        finalFieldAdjustments = fieldAdjustments;
    } else {
        finalFieldAdjustments = {
            CountryCode: {
                onSelectChange({selection, setValue}) {
                    setValue('country', (selection && selection.label) || '');
                }
            }
        };
    }

    let finalPreSubmitMutateFn:
        | ((fieldGroup: FieldGroupProps, values: GenericMap) => GenericMap)
        | undefined = undefined;
    if (preSubmitMutateFn) {
        finalPreSubmitMutateFn = (fieldGroups, finalValues) =>
            preSubmitMutateFn(fieldGroups, finalValues);
    }

    return (
        <SalesforceSimpleFormWrapper accentColor={accentColor}>
            <GridContainer>
                {centeredTextBlock && (
                    <>
                        <CenteredTextBlock {...centeredTextBlock} />
                        {!noVerticalSpacer && <VerticalSpacer variant="heading" />}
                    </>
                )}
                <div className="marketo-simple-form">
                    <UEForm
                        fieldGroups={fieldGroups}
                        account={{
                            email: account.email,
                            id: account.id
                        }}
                        initalValues={initalValues}
                        fieldAdjustments={finalFieldAdjustments}
                        formId={formId}
                        formType={formType}
                        customEndpointUrl={customEndpointUrl}
                        messages={Object.assign({}, formMessages, messages || {})}
                        preSubmitMutateFn={finalPreSubmitMutateFn}
                        loadingNode={
                            loadingNode || <UnrealLoading fillViewHeight={false} maxHeight={200} />
                        }
                        postSubmitFn={onPostSubmit}
                    />
                </div>
            </GridContainer>
        </SalesforceSimpleFormWrapper>
    );
};
