import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import {ProjectSlideWrapper} from './ProjectSlide.styles';

export default class ProjectSlide extends React.PureComponent {
    static propTypes = {
        slide: PropTypes.object
    };

    state = {
        inView: false
    };

    onVideoChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                inView: true
            });
        }
    };

    render() {
        const {title, desc, credit, image} = this.props.slide;

        const imgStyle = {
            background: `url(${image}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '100%',
            width: '100%'
        };
        return (
            <VisibilitySensor
                onChange={this.onVideoChange}
                partialVisibility
                scrollCheck={true}
                intervalDelay={250}
                scrollThrottle={10}>
                <ProjectSlideWrapper
                    className={`project-slide ${this.state.inView ? 'runAnimation' : ''}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-5 col-md-6">
                                <div className="slide-content">
                                    <h3>{title}</h3>
                                    <div
                                        className="paragraph"
                                        dangerouslySetInnerHTML={{__html: desc}}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-4 col-md-5">
                                <div className="img-wrapper">
                                    <div className="imageBg" style={imgStyle} />
                                    <span className="credit">{credit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ProjectSlideWrapper>
            </VisibilitySensor>
        );
    }
}
