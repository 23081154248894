import React, {useEffect} from 'react';

import {getHelmetForMetaTags} from 'epic-ue-shared';
import {Page, Header, Sections, Grid, SimpleSocialSharing, Bios} from 'epic-ue-components';
import VideoOverlay from '../components/events/VideoOverlay';
import Recordings from '../components/events/recordings/Recordings';
import {SpotlightWrapper} from 'epic-ue-spotlight/src/pages/SpotlightPage.styles';
import {UeThemeProvider, ueDarkTheme} from 'epic-ue-theme';
import {Agenda, EventSpotlightWrapper} from './EventSpotlightPage.styles';
import {VerticalSpacer} from 'epic-ue-ui';

interface Properties {
    pageData: any;
}

export const EventSpotlightPage = ({pageData}: Properties): JSX.Element => {
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [play, setPlay] = React.useState(false);
    const [video, setVideo] = React.useState(false);
    const [title, setTitle] = React.useState(false);
    const handleVideoOverlay = React.useCallback(
        (play, video, title) => {
            setPlay(play);
            setVideo(video);
            setTitle(title);
        },
        [play, video, title]
    );

    const header = pageData.header || {};
    const topSections = pageData.topSectioms || {};
    const topSectionsList = topSections.sections || [];
    const bottomSections = pageData.bottomSections || {};
    const bottomSectionsList = bottomSections.sections || [];
    const middleSections = pageData.middleSections || {};
    const middleSectionsList = middleSections.sections || [];
    const altBottomSection = pageData.altBottomSection || {};
    const altBottomSectionList = altBottomSection.sections || [];
    const altTopSection = pageData.altTopSection || {};
    const altTopSectionList = altTopSection.sections || [];
    const recordings = pageData.recordings || {};
    const recordingList = recordings.recordingList || [];
    const article = pageData.article || {};
    const newsletterData = pageData.newsletterData || {};
    const showNewsletterCard = newsletterData.enable;
    const speakers = pageData.speakers || {};
    const speakerItems = speakers.speakers || [];
    const grid = pageData.agenda || {};
    const gridItems = grid.items || [];
    const short = article.short || '';
    const social = article.social || {};
    const tweet = social.tweet || short || '';
    const type = header.type || 'default';

    useEffect(() => {
        setHasLoaded(true);
    }, [hasLoaded, setHasLoaded]);

    let offsetHeight = '40vh';
    if (header.type === 'video') {
        offsetHeight = '85vh';
    }

    if (header.height && header.type !== 'video') {
        const hHeight = header.height;
        const heightText = hHeight.match(/\d/g);
        if (heightText) {
            const heightNum = heightText.join('');
            if (heightNum) {
                const value = parseInt(heightNum);
                const newOffset = value + 10;
                if (newOffset) {
                    offsetHeight = `${newOffset}vh`;
                }
            }
        }
    }

    const pageTitle = header.title || pageData._title || '';
    let videoOverlay: JSX.Element | undefined;
    if (play) {
        videoOverlay = (
            <VideoOverlay
                play={play}
                toggleVideo={handleVideoOverlay}
                video={video}
                title={title}
            />
        );
    }

    return (
        <UeThemeProvider theme={ueDarkTheme}>
            <Page>
                {getHelmetForMetaTags(pageData)}
                <EventSpotlightWrapper id="eventSpotlightWrapper" headerType={header.type}>
                    <SpotlightWrapper
                        className={`header-type-${type} ${hasLoaded ? 'hasLoaded' : ''} ${
                            showNewsletterCard ? 'has-newsletter-card' : ''
                        }`}>
                        <SimpleSocialSharing
                            title={pageTitle}
                            tweet={tweet}
                            fixedScrollDisplayOffsetPx={800}
                            offsetHeight={offsetHeight}
                        />
                        <div id="spotlight-article">
                            <Header
                                key={pageTitle}
                                {...header}
                                title={pageTitle}
                                height={header.height || 'auto'}
                                subtitle={header.subtitle || ''}
                                tag={header.tag}
                                desc={header.desc || ''}
                                type={header.type || 'default'}
                                origin="spotlight"
                                showreel={header.showreel}
                                buttons={header.buttons || []}
                                vcenter={header.vcenter || false}
                                background={header.background || {}}
                            />
                            <div className="event-content">
                                {topSections &&
                                    topSectionsList &&
                                    topSectionsList.length > 0 &&
                                    !topSections.isHidden && (
                                        <Sections sections={topSectionsList} />
                                    )}
                                {altTopSection &&
                                    altTopSectionList &&
                                    altTopSectionList.length > 0 &&
                                    !altTopSection.isHidden && (
                                        <>
                                            <VerticalSpacer variant="custom" spacing={7} downSmSpacing={5} />
                                            <Sections sections={altTopSectionList} />
                                        </>
                                    )}
                                {gridItems && gridItems.length > 0 && !grid.isHidden && (
                                    <Agenda>
                                        <Grid items={gridItems || {}} {...grid} />
                                    </Agenda>
                                )}
                                {middleSections &&
                                    middleSectionsList &&
                                    middleSectionsList.length > 0 &&
                                    !middleSections.isHidden && (
                                        <Sections sections={middleSectionsList} />
                                    )}

                                {speakers &&
                                    speakerItems &&
                                    speakerItems.length > 0 &&
                                    !speakers.isHidden && (
                                        <Bios title={speakers.title || ''} bios={speakerItems} />
                                    )}
                                {recordings && recordingList && recordingList.length > 0 && (
                                    <Recordings {...recordings} />
                                )}
                                {bottomSections &&
                                    bottomSectionsList &&
                                    bottomSectionsList.length > 0 &&
                                    !bottomSections.isHidden && (
                                        <Sections sections={bottomSectionsList} />
                                    )}
                                {altBottomSection &&
                                    altBottomSectionList &&
                                    altBottomSectionList.length > 0 &&
                                    !altBottomSection.isHidden && (
                                        <Sections sections={altBottomSectionList} />
                                    )}
                            </div>
                            {videoOverlay}
                        </div>
                    </SpotlightWrapper>
                </EventSpotlightWrapper>
            </Page>
        </UeThemeProvider>
    );
};
