import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {trunc} from 'epic-ue-shared';
import {FeedImage, FeedHeading} from '../common';
import {LogoBox} from './elements/LogoBox';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';

export const MediumSpotlightWrapper = styled.div`
    position: relative;
    overflow: hidden;
    .feed-card {
        background: ${(p) => p.theme.palette.background.default};
    }
    h2 {
        margin-top: 0;
    }

    .feed-image-wrapper {
        height: 320px;
        position: relative;
        overflow: hidden;
        .image-wrapper {
            width: 1024px;
            height: 320px;
            max-height: 320px;
            background: ${(p) => p.theme.palette.background.paper};
            ${(p) => p.theme.breakpoints.down('md')} {
                width: 800px;
            }
            img {
                height: 100%;
            }
        }
    }

    .desc-content {
        padding: 20px;
    }
`;

interface Properties {
    item: FeedItemProps;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const MediumSpotlightCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const _feedSlug = item._feedSlug || props.title || '';

    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';

    const truncTitle = trunc(title, 75);

    return (
        <FeedItemDefaultsWrapper>
            <MediumSpotlightWrapper className="feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <Grid
                        container
                        justifyContent="center"
                        spacing={0}
                        className="feed-image-wrapper">
                        <FeedImage item={item} linkPath={linkPath} title={title} />
                        <LogoBox item={item} />
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <div className="content">
                                <ContentTypeLabel item={item} tagMatch={tagMatch} />
                                <FeedHeading item={item} linkPath={linkPath} title={truncTitle} />
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </MediumSpotlightWrapper>
        </FeedItemDefaultsWrapper>
    );
};
