import React from 'react';
import styled from 'styled-components';
import {useLocation, useMatch} from 'react-router-dom';
import {
    AppSharedKeys,
    cmsApi,
    getHelmetForMetaTags,
    getUrlPattern,
    InitSharedValueResponse,
    PageData,
    RouteMatchOrUrlParse,
    useCmsData,
    useMultiCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {
    AboveFoldScrollIndicator,
    AnimateOnViewWrap,
    FAQSection,
    FAQSectionProps,
    FAQAccordionItemProps,
    GridContainer,
    HiddenSSR,
    ImageHero,
    ImageHeroProps,
    MultiCard,
    MultiCardProps,
    RichTextSpan,
    Spotlight,
    TemplateThemeProps,
    VerticalSpacer
} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useBreakpointDown} from 'epic-ue-theme';
import Grid from '@material-ui/core/Grid';
import {extractSlugLocale} from '@epic-mw/localization';
import {getNoTrailingSlash} from '@epic-mw/common-utils';
import parse from 'url-parse';
import root from 'window-or-global';
import {UnrealLoading} from 'epic-ue-loading';

const Styled = styled.div`
    position: relative;
    overflow: hidden;
    background: ${(p) => p.theme.palette.background.default};
    .ue-rich-text.faq-description {
        position: relative;
        color: ${(p) => p.theme.palette.text.primary};
        top: -24px;
        ${(p) => p.theme.breakpoints.up('md')} {
            top: -48px;
        }
    }

    .hero-copy {
        padding-top: 10vh;
    }
    .ue-image-hero {
        min-height: 0;
        background: transparent;
    }
`;

export interface FaqChildPageData extends PageData {
    qa: {
        qaList?: QaListItem[];
    };
}

export interface QaSection {
    title: string;
    childPageSlug?: string;
    qaList?: QaListItem[];
    isTopResult?: boolean;
}
export interface QaListItem {
    question: string;
    answer: string;
}

interface FAQStardustPageData extends PageData {
    header?: ImageHeroProps;
    themeConfig: TemplateThemeProps;
    multicard?: MultiCardProps;
    qa: {
        qaSections: QaSection[];
    };
    headerEyebrow?: string;
    title?: string;
    description?: string;
    closeAllText?: string;
    openAllText?: string;

    //FAQ Search props
    resultsTitle?: string;
    noResultsDescription?: string;
    noResultsTitle?: string;
    searchLabelText?: string;
}

interface Properties {
    cmsUrlPattern?: string;
}

const getSlug = (pathname = '') => {
    if (pathname) {
        const {pageSlug} = extractSlugLocale(pathname, '');
        return pageSlug ? getNoTrailingSlash(pageSlug) : '/faq';
    }

    return '/faq';
};

const getChildCmsUrlPattern = (cmsUrlPattern, childPageSlug) => `${cmsUrlPattern}/${childPageSlug}`;
const getChildCmsPatterns = (pageSlug: string, pageData: FAQStardustPageData) => {
    return pageData?.qa?.qaSections?.reduce<string[]>((acc, faqSection) => {
        const childCmsUrlPattern: string = getChildCmsUrlPattern(
            pageSlug,
            faqSection.childPageSlug
        );
        if (faqSection.childPageSlug && !acc.includes(childCmsUrlPattern)) {
            acc.push(childCmsUrlPattern);
        }
        return acc;
    }, []);
};

const mergeParentWithChildData = (
    cmsUrlPattern: string,
    pageData: FAQStardustPageData,
    childPageData: any
) => {
    const merged = Object.assign({}, pageData);
    const qa = merged?.qa;
    const qaSections = qa?.qaSections || [];

    qaSections.forEach((section: QaSection) => {
        if (section.childPageSlug) {
            const childCmsUrlPattern: string = getChildCmsUrlPattern(
                cmsUrlPattern,
                section.childPageSlug
            );
            const childPageContent = childPageData[childCmsUrlPattern];
            const childQaList = (childPageContent as FaqChildPageData)?.qa?.qaList || [];

            if (childQaList.length) {
                section.qaList = [...childQaList];
            }
        }
    });

    return merged;
};

const translateFAQFormat = (oldFormat) => {
    //Convert data to format accepted by epic-ue-ui FAQSection
    const faq = {} as FAQSectionProps;
    faq.items = [];

    const qa = oldFormat?.qa;
    const qaSections = qa?.qaSections || [];

    qaSections.forEach((qaSection) => {
        const {title = '', qaList = [], childPageSlug, id, isTopResult = false} = qaSection;
        const faqItem = {} as FAQAccordionItemProps;
        faqItem.title = title;
        faqItem.items = [];
        faqItem.childPageSlug = childPageSlug;
        faqItem.id = id;
        faqItem.isTopResult = isTopResult;

        qaList.forEach(({question = '', answer = '', isTopResult = false}) => {
            const faqQuestion = {} as FAQAccordionItemProps;
            faqQuestion.title = question;
            faqQuestion.description = answer;
            faqQuestion.isTopResult = isTopResult;

            faqItem.items && faqItem.items.push(faqQuestion);
        });

        faq.items && faq.items.push(faqItem);
    });

    return faq;
};

const FAQStardustPage = ({cmsUrlPattern = ''}: Properties): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const urlPattern = cmsUrlPattern || getUrlPattern(match);
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<FAQStardustPageData>(urlPattern);
    const childCmsPatterns = getChildCmsPatterns(urlPattern, pageData) || [];

    const hasChildData = childCmsPatterns && childCmsPatterns.length;

    const {pageData: childPageData, cmsLoading: childDataLoading} = useMultiCmsData(
        childCmsPatterns,
        []
    );

    const url = parse(root.location.href, true);
    const queryParams: {active?: string} = url.query;
    const active = queryParams.active || url.hash.replace('#', '');
    const initialScrollTo = active;

    const {
        header = {} as ImageHeroProps,
        themeConfig = {} as TemplateThemeProps,
        multicard = {} as MultiCardProps,
        headerEyebrow = 'Support' as string,
        title = '' as string,
        description = '' as string,
        closeAllText = '' as string,
        openAllText = '' as string,
        noResultsDescription = '' as string,
        noResultsTitle = '' as string,
        searchLabelText = '' as string,
        resultsTitle = '' as string
    } = pageData;

    const {accentColor, theme} = themeConfig;

    const isSm = useBreakpointDown('sm');

    if (cmsLoading || (hasChildData && childDataLoading)) {
        return (
            <UeThemeProvider theme={theme === 'dark' ? ueDarkTheme : ueLightTheme}>
                <Styled>
                    <UnrealLoading />
                </Styled>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    let faq: FAQSectionProps;
    if (hasChildData) {
        const mergedData = mergeParentWithChildData(urlPattern, pageData, childPageData);
        faq = translateFAQFormat(mergedData);
    } else {
        faq = translateFAQFormat(pageData);
    }

    const {cards = []} = multicard;
    const {text = {}} = cards[0] || {};
    const hasMultiCardData = text.title ? true : false;

    return (
        <UeThemeProvider theme={theme === 'dark' ? ueDarkTheme : ueLightTheme}>
            <Styled>
                {getHelmetForMetaTags(pageData)}
                <Spotlight
                    variant="medium"
                    offsetRatioDown={[0.1, 0.2]}
                    accentColor={accentColor}
                    optMaxWidth={400}>
                    <Spotlight
                        accentColor={accentColor}
                        variant="medium"
                        shape="circular"
                        offsetRatio={[0.68, -0.01]}
                        disable={isSm}>
                        {header && (header.title || header.description) ? (
                            <>
                                <ImageHero {...header} />
                                <HiddenSSR smDown>
                                    <AboveFoldScrollIndicator translateY={0} />
                                </HiddenSSR>
                            </>
                        ) : (
                            <>
                                <ImageHero eyebrow={{text: headerEyebrow}} title={title} />
                                <GridContainer>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        spacing={3}>
                                        <Grid item xs={12} md={8}>
                                            <AnimateOnViewWrap delay={0.9} translateY={10}>
                                                <RichTextSpan
                                                    className="faq-description"
                                                    richText={description}
                                                    accentColor={accentColor}
                                                />
                                            </AnimateOnViewWrap>
                                        </Grid>
                                    </Grid>
                                </GridContainer>
                                <HiddenSSR smDown>
                                    <AboveFoldScrollIndicator translateY={0} />
                                    <VerticalSpacer variant="custom" spacing={15} />
                                </HiddenSSR>
                            </>
                        )}
                    </Spotlight>
                </Spotlight>
                <Spotlight
                    accentColor={accentColor}
                    variant="medium"
                    shape="circular"
                    offsetRatio={[-0.1, 0.0]}
                    disable={isSm}>
                    {hasMultiCardData ? (
                        <>
                            <VerticalSpacer variant="section" />
                            <VerticalSpacer variant="section" />
                            <MultiCard {...multicard} accentColor={accentColor || 'sky'} />
                            <VerticalSpacer variant="section" />
                        </>
                    ) : (
                        ''
                    )}
                </Spotlight>
                <Spotlight
                    accentColor={accentColor}
                    variant="medium"
                    shape="circular"
                    offsetRatio={[0.75, 0.12]}
                    disable={isSm}>
                    <Spotlight
                        accentColor={accentColor}
                        variant="medium"
                        shape="circular"
                        offsetRatio={[0.0, 0.97]}
                        disable={isSm}>
                        <FAQSection
                            {...faq}
                            accentColor={accentColor || 'sky'}
                            titlesSidebar={true}
                            closeAllText={closeAllText}
                            openAllText={openAllText}
                            initialScrollTo={initialScrollTo}
                            afterSectionVerticalSpacerProvided={true}
                            resultsTitle={resultsTitle}
                            noResultsDescription={noResultsDescription}
                            noResultsTitle={noResultsTitle}
                            searchLabelText={searchLabelText}
                        />
                    </Spotlight>
                </Spotlight>
            </Styled>
        </UeThemeProvider>
    );
};

FAQStardustPage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const pageSlug = getSlug(match?.pathname);

    const pageData: FAQStardustPageData = await cmsApi.getPageData(pageSlug);
    const childCmsPatterns = getChildCmsPatterns(pageSlug, pageData);
    const {slugs = {}} = await cmsApi.getBulkPageData({slugs: childCmsPatterns});

    const childFaqPreloadData = Object.keys(slugs).map((childPageSlug) => {
        return {
            key: AppSharedKeys.CmsStore,
            value: {
                [childPageSlug]: slugs[childPageSlug] || {}
            }
        };
    });

    const preloadArray = [
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [pageSlug]: pageData || {}
            }
        },
        ...childFaqPreloadData
    ];

    return preloadArray;
};

export default FAQStardustPage;
