import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const Resource = styled.li<{index: number}>`
    list-style: none;
    text-align: left;
    border-bottom: ${(p) => p.theme.palette.background.paperpop} 1px solid;
    position: relative;
    margin-bottom: 20px;

    ${(p) => p.theme.breakpoints.down('md')} {
        height: auto;
        min-height: 100px;
        position: relative;
        overflow: hidden;
        h3 {
            margin: 20px 0 10px 0 !important;
        }
    }

    border-radius: 12px;
    overflow: hidden;
    height: 150px;
    background-color: ${(p) => p.theme.palette.background.paper};
    opacity: 0;
    transform: translate(0, 50px);
    transition: all 0.3s ease-in-out ${(p) => p.index}ms;
    a {
        width: 100%;
        height: 100%;
        padding: 20px 15px 15px;
        display: block;
    }
    &:hover {
        background-color: ${(p) => p.theme.palette.background.paperpop};
    }
    &.inView {
        opacity: 1;
        transform: translate(0, 0);
    }
    &.category-presentations span,
    &.category-books-and-guides span,
    &.category-documentations span {
        background-color: #ccc;
        color: #fff;
        padding: 5px;
        font-size: 0.75em;
    }
    &.category-presentations span {
        background-color: #eb4d55;
    }
    &.category-books-and-guides span {
        background-color: #8cba51;
    }
    &.category-documentations span {
        background-color: #333366;
    }
    h3 {
        text-align: left;
        font-size: 1em;
        margin: 5px 0 0 0;
    }
    .sub-title {
        color: #ccc;
        font-size: 0.875em;
    }
`;

interface Partner {
    title: string;
    subtitle: string;
    link: string;
    region: string;
}

interface Properties {
    partner: Partner;
    uniqueId: number;
    index: number;
    category: string;
}

export const ResourceItem = ({partner, index, category}: Properties): JSX.Element => {
    const region = partner.region;
    let resourceType = '';
    if (region) {
        resourceType = region.replace(/\s+/g, '-').toLowerCase();
    }
    const [inView, setInView] = React.useState(false);
    const visChange = React.useCallback(
        (isVisible) => {
            if (isVisible) {
                setInView(true);
            }
        },
        [inView, setInView]
    );

    return (
        <VisibilitySensor
            onChange={visChange}
            partialVisibility
            scrollCheck={true}
            intervalDelay={250}
            scrollThrottle={100}>
            <Resource
                index={index}
                className={`col-xs-12 category-${resourceType} ${inView ? 'inView' : ''}`}
                data-type={category}>
                <a
                    href={partner.link}
                    target="_blank"
                    className="fade-in-link"
                    rel="noopener noreferrer">
                    <div className="text-container">
                        <span>{region}</span>
                        <h3 className="title">{partner.title}</h3>
                        <p className="sub-title">{partner.subtitle}</p>
                    </div>
                </a>
            </Resource>
        </VisibilitySensor>
    );
};
