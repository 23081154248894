import styled from 'styled-components';

export const BioAvatar = styled.div`
    display: block;
    width: 150px;
    height: 150px;
    ${(p) => p.theme.breakpoints.down('xs')} {
        width: 100px;
        height: 100px;
    }
    margin: 20px auto;
    border-radius: 200px;
    position: relative;
    overflow: hidden;
    > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: grayscale(100%);
    }
`;
export const BioContent = styled.div`
    background: ${(p) => p.theme.palette.background.paper};
    padding: 15px;
    border-radius: 4px;
    transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

export const BioName = styled.div`
    height: auto;
    padding-bottom: 5px;
    color: ${(p) => p.theme.palette.text.primary};
    text-align: left;
    font-size: 1.2em;
`;

export const BioPosition = styled.div`
    text-align: left;
    font-size: 0.9em;
`;

export const BioEmployer = styled.div`
    text-align: left;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 0.5em;
`;

export const BioShort = styled.div`
    margin: 20px 0;
    pointer-events: none;
    font-size: 0.8em;
    line-height: 1.8em;
`;

export const BioContainer = styled.div`
    z-index: 2;
    ${(p) => p.theme.breakpoints.up('xs')} {
        ${BioShort} {
            &:not(.bio-modal) {
                display: none !important;
            }
        }
    }
`;

export const BioGrid = styled.div`
    &.open {
        z-index: 8;
        ${(p) => p.theme.breakpoints.up('xs')} {
            ${BioShort} {
                opacity: 0;
                height: 0;
            }
        }
    }
    &.hasDesc {
        cursor: pointer;
    }

    &:not(.open) {
        .bio-content {
            * {
                text-align: center;
            }
        }
    }
    &.handheld {
        ${BioShort} {
            opacity: 0;
            height: 0;
            transition: all 0.125s cubic-bezier(0.645, 0.045, 0.355, 1);
            &.open {
                opacity: 1;
                height: auto;
            }
        }
        &.open {
            ${BioContent} {
                height: auto;
                background: #333;
            }
        }
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        &:nth-child(even) {
            .bioModal.open {
                transform: translate(-40%, -400px);
            }
        }
    }
`;

export const BioModal = styled.div`
    width: 110%;
    ${(p) => p.theme.breakpoints.down('xs')} {
        width: 140%;
    }
    height: 0;
    pointer-events: none;
    opacity: 0;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transform: translate(-10%, -460px);
    ${(p) => p.theme.breakpoints.down('xs')} {
        transform: translate(-10%, -400px);
    }
    position: absolute;
    z-index: -1;
    &.open {
        pointer-events: all;
        z-index: 88;
        opacity: 1;
        height: auto;

        ${BioContent} {
            box-shadow: 1px 6px 10px 3px rgba(0, 0, 0, 0.5);
            background-color: ${(p) => p.theme.palette.background.paper};
            ${(p) => p.theme.breakpoints.down('xs')} {
                box-shadow: none;
            }
            * {
                pointer-events: none;
            }
        }

        ${BioPosition} {
            line-height: 1.2em;
        }

        ${BioShort} {
            opacity: 1;
            height: auto;
        }
    }
`;

export const BioWrapper = styled.div`
    position: relative;
    margin-bottom: 40px;
    ${BioContent} {
        height: 400px;
        ${(p) => p.theme.breakpoints.down('xs')} {
            height: 300px;
        }
    }
`;
