import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {generateRoutePath} from '@epic-core/common';

import {useLocalization} from '@epic-mw/localization';
import {getMessageFnType} from 'epic-ue-shared';
import {GridReusableRow} from 'epic-ue-feed';

import {SectionWrapper, Button, H2} from 'epic-ue-components';

import {useFeedItems} from '../../shared';
import { UnrealLoading } from 'epic-ue-loading';

interface Properties {
    newsTag?: string;
    newsFilter?: string;
    newsHeader?: string;
}

const NewsEventsAndArticles = (props: Properties): JSX.Element | null => {
    const {newsTag = undefined, newsFilter = 'all', newsHeader = ''} = props;
    const navigate = useNavigate();

    const tags = {};
    if (newsTag) {
        const parts = newsTag.split(',');
        if (parts && parts.length) {
            parts.forEach((part) => {
                tags[part] = true;
            });
        } else {
            tags[newsTag] = true;
        }
    }

    const getMessage = useLocalization() as getMessageFnType;
    const {items: feedItems, loading: feedItemsLoading} = useFeedItems({tags}, !newsTag);
    const newsCallback = useCallback(
        (e) => {
            if (e.button && e.button > 0) return; //FF middle mouse

            if (newsTag) {
                navigate(generateRoutePath(`/feed/${newsFilter}/${newsTag.replace(/,/, '+')}`));
            }
        },
        [newsFilter, newsTag]
    );

    if (!newsTag) return null;

    const regularFeedItems: any[] = [];

    if (feedItems) {
        feedItems.map((feedItem) => {
            if (feedItem._feedSlug !== 'spotlights') {
                regularFeedItems.push(feedItem);
            }
        });
    }

    return (
        <>
            <SectionWrapper>
                <div className="news-header">
                    {newsHeader ? <H2 className="centered">{newsHeader}</H2> : ''}
                </div>
                <div className="news container-fluid">
                    {feedItemsLoading ? (
                        <UnrealLoading fillViewHeight={false} />
                    ) : (
                        <GridReusableRow items={regularFeedItems} />
                    )}
                    <div className="text-center">
                        <Button type="secondary" id="homepage-more-news" onClick={newsCallback}>
                            {getMessage('epic.enterprise.landing.seeall.news.text')}
                        </Button>
                    </div>
                </div>
            </SectionWrapper>
        </>
    );
};

export default NewsEventsAndArticles;
