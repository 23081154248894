import React from 'react';
import {
    getHelmetForMetaTags,
    PageData,
    useCmsData,
    getInitialCmsPageSharedValues
} from 'epic-ue-shared';
import { UnrealLoading } from 'epic-ue-loading';
import ErrorPage from '../ErrorPage';
import { UeThemeProvider, ueDarkTheme, useSetCurrentAccentColor, ueLightTheme } from 'epic-ue-theme';
import {
    NewsletterSection,
    NewsletterSectionProps,
    SubnavSection,
    SubnavSectionConfigProps,
    TemplateThemeProps,
    ImageHero,
    ImageHeroProps,
    ArticleCopy,
    ArticleCopyProps,
    VerticalSpacer
} from 'epic-ue-ui';

const cmsUrlPattern = '/newsletter';

export interface NewsletterPageData extends PageData {
    themeConfig?: TemplateThemeProps;
    header?: ImageHeroProps;
    newsletter?: NewsletterSectionProps;
    articleCopy?: ArticleCopyProps;
}

const NewsletterPage = (): JSX.Element => {
    const { cmsLoading, cmsLoadedEmpty, pageData } = useCmsData<NewsletterPageData>(cmsUrlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        newsletter = {} as NewsletterSectionProps,
        header = {} as ImageHeroProps,
        articleCopy = {} as ArticleCopyProps
    } = pageData;

    const { accentColor, theme } = themeConfig;
    useSetCurrentAccentColor(accentColor);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const sectionConfig = {
        theme,
        accentColor,
        index: null
    } as SubnavSectionConfigProps;

    return (
        <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
            {getHelmetForMetaTags(pageData)}
            <>
                <ImageHero {...header} />
                <VerticalSpacer variant={pageData?.header?.image ? 'heading' : 'section'} />
                <ArticleCopy {...articleCopy} />
                <SubnavSection variant="" hideBottomSectionPadding sectionConfig={sectionConfig}>
                    <NewsletterSection variant="split" {...newsletter} accentColor={accentColor} />
                </SubnavSection>
            </>
        </UeThemeProvider>
    );
};
NewsletterPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default NewsletterPage;
