import React from 'react';
import {MapPanel, MapPanelInner} from './UEMapPanel.styles';

type UEMapPanelAlign = 'left' | 'right' | 'top' | 'bottom';
type UEMapPanelVariant = 'contained' | 'gradient';

interface UEMapProps {
    align: UEMapPanelAlign;
    children: any;
    variant?: UEMapPanelVariant;
}

export const UEMapPanel = ({align, children, variant}: UEMapProps): JSX.Element => {
    return (
        <MapPanel className={`${align} ${variant ? variant : ''}`}>
            <MapPanelInner className={variant}>{children}</MapPanelInner>
        </MapPanel>
    );
};
