import styled from 'styled-components';

export const TagsWrapper = styled.div`
    margin: 0;
    .tag-link {
        .tag {
            color: #000;
            font-family: ${(p) => p.theme.ue.fonts.brutal.medium};
            display: inline-block;
            text-transform: capitalize;
            font-size: 0.875em;
            padding-right: 0;
            margin: 0 0 0 0;
            transition: color 0.3s ease-in-out;
            background-color: #e8e8e8;
            border-radius: 2px;
            padding: 0.25em 0.75em;

            &:hover {
                color: #28bcf6;
            }
        }
    }
    a:not(:last-child) {
        .tag {
            margin: 0 0.5em 0.5em 0;
            padding: 0.25em 0.75em;
        }
    }
`;
