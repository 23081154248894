import styled, {css} from 'styled-components';

interface Props {
    childLength: number;
}

function createCSS({childLength}: Props) {
    let styles = '';

    for (let i = 1; i <= childLength; i++) {
        let transitionSpeed = i / 5;
        if (childLength > 6) {
            transitionSpeed = i / 8;
        }
        if (childLength > 10) {
            transitionSpeed = i / 10;
        }
        if (childLength > 15) {
            transitionSpeed = i / 15;
        }
        styles += `
            &:nth-of-type(${i}){
                transition-delay: ${transitionSpeed}s
            }`;
    }
    return css`
        ${styles}
    `;
}

export const StaggerWrapper = styled.div<{childLength: number}>`
    li,
    > div {
        opacity: 0;
        transform: translateX(-100px);
        transition: opacity 0.35s ease-in-out, transform 0.3s ease-out;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin: 10px 0 0 0;
        }
    }
    &.inView.hasLoaded {
        li,
        > div {
            opacity: 1;
            transform: translateX(0);
            ${createCSS}
        }
    }
`;
