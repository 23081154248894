import React from 'react';
import {CaseStudy} from './CaseStudy';

export const CaseStudyList = ({showcases}: { showcases: any}): JSX.Element => {
    let showcaseList: JSX.Element = <div key="showcases-empty" />;
    if (showcases && showcases.length > 0) {
        showcaseList = showcases.map((showcase, index) => {
            const logo = showcase.logo || '';
            const title = showcase.title || '';
            const summary = showcase.summary || {};
            const type = showcase.type || 'fullWidth';
            const showreel = showcase.showreel || {};
            const background = showcase.background || {};
            const backgroundTwo = showcase.backgroundTwo || {};
            const detailPageBtnLabel = showcase.detailPageBtnLabel || '';
            const showreelBtnLabel = showcase.showreelBtnLabel || '';
            const url = showcase.href || '';
            return (
                <CaseStudy
                    key={`study-${index}-${title}`}
                    title={title}
                    {...showcase}
                    logo={logo}
                    type={type}
                    summary={summary}
                    detailPageBtnLabel={detailPageBtnLabel}
                    showreelBtnLabel={showreelBtnLabel}
                    showreel={showreel}
                    href={url}
                    background={background}
                    backgroundTwo={backgroundTwo}
                />
            );
        });
    }

    return showcaseList;
};
