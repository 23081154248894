import styled from 'styled-components';

const SectionCopy = styled.div`
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
        padding: 20px 0;
    }
    ${(p) => p.theme.breakpoints.down('sm')} {
        font-size: 0.875em;
        max-width: 85%;
        margin: 0 auto;
        span {
            font-size: 1.5em !important;
            em {
                font-size: 1em !important;
            }
        }
    }
    ${(p) => p.theme.breakpoints.down('xs')} {
        padding: 20px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        font-size: 0.875em;
        max-width: 85%;
        margin: 0 auto;
    }
`;

const SectionContent = styled.div`
    width: 50%;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: top;
    color: #000;
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
        padding: 20px 45px;
    }
    * {
        text-align: left;
    }
    h3,
    .btn-wrapper {
        ${(p) => p.theme.breakpoints.down('xs')} {
            text-align: center;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
            text-align: left;
        }
    }
`;

const SectionImage = styled.div`
    height: 450px;
    display: inline-block;
    position: relative;
    width: 50%;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    ${(p) => p.theme.breakpoints.down('md')} {
        width: 100%;
        height: 450px;
        margin: 0;
        display: block;
    }
`;

export {SectionCopy, SectionContent, SectionImage};
