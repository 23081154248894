import styled from 'styled-components';

export const LatestListWrapper = styled.div`
    .latest-item {
        color: ${(p) => p.theme.palette.text.primary};
        padding: 0 15px;
        margin: 0 180px 0;
        ${(p) => p.theme.breakpoints.down('lg')} {
            margin: 0;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            margin: 0 80px;
        }
        .h1 {
            margin: 0;
            background: ${(p) => p.theme.palette.background.default};
            display: inline-block;
            z-index: 2;
            padding-right: 20px;
            position: relative;
            font-size: 1.2em;
            ${(p) => p.theme.breakpoints.down('md')} {
                font-size: 1em;
            }
            text-transform: uppercase;
        }
        .header-wrapper {
            width: 100%;
            position: relative;
            overflow: hidden;
            margin-bottom: 20px;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: ${(p) => p.theme.palette.background.paper};
                top: 11px;
                left: 0;
            }
        }
        ul.latest-list-wrapper {
            position: relative;
            overflow: hidden;
            padding: 0;
            margin: 0;
            opacity: 0;
            transform: translateY(50px);
            &.latest-inView {
                transform: translateY(0);
                opacity: 1;
                transition: transform 0.4s ease-out, opacity 0.4s ease-out;
            }
            li {
                padding: 0;
                margin: 0;
            }
        }
    }
`;
