import React from 'react';
import styled from 'styled-components';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import {useCmsData} from '../../shared';
import ErrorPage from '../ErrorPage';
import {getLocale} from '@epic-core/common';
import {UnrealLoading} from 'epic-ue-loading';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import {
    SearchHeader,
    SearchPageData,
    SearchResults,
    useRouterFns,
    useSearchItems
} from 'epic-ue-search';
import parse from 'url-parse';
import root from 'window-or-global';
import {useNavigate} from 'react-router-dom';
import {UeThemeProvider, ueDarkTheme, useBreakpointDown, ueGradientColors} from 'epic-ue-theme';
import Helmet from 'react-helmet';
import {NewsletterSection, Spotlight} from 'epic-ue-ui';

const SearchWrap = styled.div<{gradientFrom?: string; gradientTo?: string}>`
    .spotlight-wrapper {
        overflow-x: clip;
    }

    && .rc-pagination-item-active {
        border-image-slice: 1;
        border-width: 2px;
        border-image-source: linear-gradient(
            to left,
            ${(p) => p.gradientFrom},
            ${(p) => p.gradientTo}
        );
    }
`;

const SearchContent = styled.div`
    min-height: 80vh;
`;

const cmsUrlPattern = '/search';

const SearchPage = (): JSX.Element => {
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<SearchPageData>(cmsUrlPattern);

    const isSm = useBreakpointDown('sm');

    const navigate = useNavigate();
    const {routeNavigate} = useRouterFns(navigate);

    const parsedUrl = parse(root.location.href, true);
    const queryKeyword = String(parsedUrl.query.keyword || '');
    const filter = String(parsedUrl.query.filter || '');

    let overrideLocaleWithDefault;
    if (filter) {
        /*When CMS epicSearchFilter defaultLocale checked default to en-US locale */
        overrideLocaleWithDefault = '';
        pageData?.epicSearchFilter?.searchFilters?.forEach((searchFilter) => {
            if (searchFilter.filterName === filter) {
                overrideLocaleWithDefault = searchFilter?.defaultLocale;
            }
        });
    }

    const locale = overrideLocaleWithDefault ? 'en-US' : getLocale();

    const {pages, total, isLoading} = useSearchItems(locale);

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const epicSearchFilter = pageData?.epicSearchFilter;
    const accentColor = 'sky';

    const gradient = ueGradientColors[accentColor];
    const {from, to} = gradient;

    return (
        <SearchWrap gradientFrom={from} gradientTo={to}>
            {getHelmetForMetaTags(pageData)}

            <Helmet meta={[{name: 'robots', content: 'noindex,nofollow'}]} />
            <UeThemeProvider theme={ueDarkTheme}>
                <SearchContent>
                    <Spotlight
                        accentColor={accentColor}
                        variant="big"
                        shape="circular"
                        offsetRatio={[0.6, -0.4]}
                        customColors={['#34A3F5', '#CF6FCB']}
                        disable={isSm}>
                        <SearchHeader
                            isLoading={isLoading}
                            routeNavigate={routeNavigate}
                            enableCustomFilter={epicSearchFilter?.enableCustomFilter}
                            queryUrl={parsedUrl.query}
                            searchFilters={epicSearchFilter?.searchFilters}
                            total={total}
                            pageTitle={pageData?.pageTitle}
                            description={pageData?.description}
                            noResultsBlock={pageData?.noResultsBlock}
                            resultsLabel={pageData?.resultsLabel}
                            searchPlaceholder={pageData?.searchPlaceholder}
                        />
                    </Spotlight>

                    <SearchResults
                        accentColor={'sky'}
                        isLoading={isLoading}
                        loadingIcon={<UnrealLoading />}
                        pages={pages}
                        total={total}
                        searchKeyword={queryKeyword}
                        filter={filter}
                    />
                </SearchContent>
            </UeThemeProvider>

            <NewsletterSection
                variant="split"
                {...pageData?.newsletter}
                accentColor={accentColor}
            />
        </SearchWrap>
    );
};

SearchPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default SearchPage;
