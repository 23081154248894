import {useState, useEffect, useCallback} from 'react';
import {createSharedValueKey, useSharedValue} from '@epic-core/hooks';
import {eulaApi, EulaAcceptedRes} from './eula.api';
import {eulaKeyContent, eulaKeyUnreal} from '../constants';
import {useAccountLoginRequired, AppSharedKeys, useAccountId} from 'epic-ue-shared';
import {emitEvent} from 'epic-ue-shared/dist/utils/analyticsUtils';
import {IndustrySelected} from './shared.values';
import {isError} from '@epic-mw/error-types';

const defaultAcceptedResponse: EulaAcceptedRes = {
    version: 0,
    locale: '',
    accepted: false,
    key: ''
};

const eulaAcceptedStoreKey = createSharedValueKey('EulaAcceptedStore', {
    [eulaKeyContent]: defaultAcceptedResponse,
    [eulaKeyUnreal]: defaultAcceptedResponse
});

export interface EulaContentResponse {
    agentUserName: string;
    body: string;
    createdTimestamp: string;
    custom: boolean;
    eulaLocale: string;
    id: string;
    key: string;
    lastModifiedTimestamp: string;
    locale: string;
    revision: number;
    status: string;
    title: string;
    url: string;
    version: number;
    accepted?: boolean;
}

export interface EulaContent {
    eula: EulaContentResponse;
    loading: boolean;
    loaded: boolean;
    error?: string;
}

interface AcceptResponse {
    key: string;
    eulaLocale: string;
    accepted: boolean;
    version: number;
}

interface EulaAccepted {
    accepted: boolean;
    version: number;
    key: string;
    loadingAccepted: boolean;
    loadedAccepted: boolean;
    acceptedError?: string;
}

interface EulaResponseAccepted {
    eulaContent: EulaContent;
    eulaAccepted: EulaAccepted;
}

export const useEulaContent = (key: string): EulaContent => {
    const [loadingState, setLoadingState] = useState<{
        loading: boolean;
        loaded: boolean;
        error?: string;
    }>({loading: false, loaded: false, error: ''});

    const [eulaContentStore, setEulaStore] = useSharedValue(AppSharedKeys.EulaStore);
    const eula: EulaContentResponse = eulaContentStore[key] || {};

    useEffect(() => {
        if ((eula.key && !loadingState.error) || loadingState.loading) {
            return;
        }
        async function fetchData() {
            try {
                setLoadingState({loading: true, loaded: false});
                const data = await eulaApi.getEulaData(key);
                setEulaStore(Object.assign({}, eulaContentStore, {[key]: data}));
                setLoadingState({loading: false, loaded: true});
            } catch (ex) {
                console.error('Failed to fetch eula data for key', key, ex);
                const error = (isError(ex) && ex.message) || '';
                setLoadingState({loading: false, loaded: true, error});
            }
        }
        fetchData();
    }, [eula && eula.key]);

    return {
        eula,
        ...loadingState
    };
};

let loadingEulaAccepted = false;
export const useEulaAccepted = (key: string): EulaAccepted => {
    const {accountLoading} = useAccountLoginRequired();

    const [loadingState, setLoadingState] = useState<{
        loadingAccepted: boolean;
        loadedAccepted: boolean;
        acceptedError?: string;
    }>({loadingAccepted: false, loadedAccepted: false, acceptedError: ''});

    const [eulaAcceptedStore, setEulaAcceptedStore] = useSharedValue(eulaAcceptedStoreKey);
    const acceptedResponse: EulaAcceptedRes = eulaAcceptedStore[key] || {};
    const {version, accepted} = acceptedResponse;
    const accountId = useAccountId();

    useEffect(() => {
        if (
            !accountId ||
            (!key && !loadingState.acceptedError) ||
            loadingEulaAccepted ||
            accepted ||
            loadingState.acceptedError
        ) {
            return;
        }
        async function fetchData() {
            try {
                loadingEulaAccepted = true;
                setLoadingState({loadingAccepted: true, loadedAccepted: false});
                const data: AcceptResponse = (await eulaApi.getEulaAccepted(key, accountId)) || {};
                setLoadingState({loadingAccepted: false, loadedAccepted: true});
                setEulaAcceptedStore(Object.assign({}, eulaAcceptedStore, {[key]: data}));
                loadingEulaAccepted = false;
            } catch (ex) {
                console.error('Failed to fetch eula accepted for key', key, ex);
                const acceptedError = (isError(ex) && ex.message) || '';
                setLoadingState({
                    loadingAccepted: false,
                    loadedAccepted: true,
                    acceptedError
                });
                loadingEulaAccepted = false;
            }
        }
        fetchData();
    }, [key, !accepted, accountId, loadingState, version]);

    return {
        accepted,
        version,
        key,
        loadingAccepted:
            loadingState.loadingAccepted || accountLoading || !!(accepted === undefined && key),
        loadedAccepted: loadingState.loadedAccepted,
        acceptedError: loadingState.acceptedError
    };
};

export const useEulaContentAndAccepted = (key: string): EulaResponseAccepted => {
    const eulaContent = useEulaContent(key);
    const eulaAccepted = useEulaAccepted(key);

    return {
        eulaContent,
        eulaAccepted
    };
};

export const useEulaAcceptance = (
    key: string,
    version: number
): {
    acceptCallback: () => Promise<void>;
    acceptComplete: boolean;
    accepted: boolean;
    acceptError?: string;
    version: number;
    accepting: boolean;
} => {
    useAccountLoginRequired();
    const [industrySelected] = useSharedValue(IndustrySelected);

    const [loadingState, setLoadingState] = useState<{
        accepting: boolean;
        acceptComplete: boolean;
        acceptError?: string;
    }>({accepting: false, acceptComplete: false, acceptError: ''});

    const [eulaAcceptedStore, setEulaAcceptedStore] = useSharedValue(eulaAcceptedStoreKey);
    const acceptedResponse: EulaAcceptedRes = eulaAcceptedStore[key] || {};
    const {accepted} = acceptedResponse;
    const accountId = useAccountId();

    const acceptCallback = useCallback(async () => {
        try {
            setLoadingState({accepting: true, acceptComplete: false});
            const data: AcceptResponse = (await eulaApi.acceptEula(key, accountId, version)) || {};
            setLoadingState({accepting: false, acceptComplete: true});
            setEulaAcceptedStore(Object.assign({}, eulaAcceptedStore, {[key]: data}));
            if (data && data.accepted) {
                emitEvent({
                    eventAction: 'client.eula.accept',
                    eventLabel: key,
                    eventValue: industrySelected
                });
            }
        } catch (ex) {
            console.error('Failed to accept eula for key', key, ex);
            const acceptError = (isError(ex) && ex.message) || '';
            setLoadingState({accepting: false, acceptComplete: true, acceptError});
        }
    }, [
        eulaAcceptedStore,
        setEulaAcceptedStore,
        loadingState,
        setLoadingState,
        key,
        accountId,
        version
    ]);

    return {
        accepted,
        version,
        ...loadingState,
        acceptCallback
    };
};
