import styled from 'styled-components';

export const ParagonPageWrapper = styled.div`
    background: white;

    .paragon-section {
        min-height: 800px;
        height: 100vh;
        width: 100%;
        color: #fff;
        -webkit-transition: all 0.5s ease;
        -o-transition: all ease 0.5s;
        transition: all 0.5s ease;
        position: relative;
        overflow: hidden;
    }

    .paragon-section .background,
    .paragon-section .background-mobile {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .paragon-section .container-fluid {
        padding: 0 15%;
    }

    @media only screen and (max-width: 1439px) {
        .paragon-section .container-fluid {
            padding: 0 10%;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section .container-fluid {
            padding: 0;
        }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .paragon-section .container-fluid {
            padding: 0 5%;
        }
    }

    .paragon-section .container-fluid,
    .paragon-section .row,
    .paragon-section .row > div {
        height: 100%;
    }

    .paragon-section h1 {
        font-size: 5em;
        font-family: Brutal_Bold, sans-serif;
    }

    @media only screen and (min-width: 1440px) {
        .paragon-section h1 {
            font-size: 4em;
        }
    }

    @media only screen and (max-width: 1439px) {
        .paragon-section h1 {
            font-size: 4em;
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section h1 {
            font-size: 3em;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section h1 {
            font-size: 2em;
        }
    }

    .paragon-section .h2 {
        font-size: 4em;
        font-family: Brutal_Light, sans-serif;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section .h2 {
            font-size: 3em;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section .h2 {
            font-size: 2em;
        }
    }

    .paragon-section .content {
        position: absolute;
        bottom: 100px;
        padding: 2em;
        border-radius: 5px;
        background-color: transparent;
        -webkit-transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: background-color 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    @media only screen and (max-width: 991px) {
        .paragon-section .content {
            bottom: 40px;
        }
    }

    .paragon-section .content .desc,
    .paragon-section .content a {
        opacity: 0;
    }

    .paragon-section.inViewport .background,
    .paragon-section.inViewport .background-mobile {
        -webkit-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.inViewport .background,
        .paragon-section.inViewport .background-mobile {
            background-position: -200px 0;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.inViewport .background,
        .paragon-section.inViewport .background-mobile {
            background-position: -400px 0;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.inViewport .background,
        .paragon-section.inViewport .background-mobile {
            background-size: cover;
            background-position: 0 0;
        }
    }

    @media only screen and (device-max-width: 1200px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        .paragon-section.inViewport .background,
        .paragon-section.inViewport .background-mobile {
            background-position: 0 0;
            background-size: cover;
        }
    }

    .paragon-section.inViewport .desc,
    .paragon-section.inViewport a {
        opacity: 1;
        -webkit-transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: opacity 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-top {
        background-color: #000;
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-top {
            height: 110vh;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-top {
            height: 1000px;
        }
    }

    @media only screen and (max-width: 767px) and (orientation: landscape) {
        .paragon-section.section-top {
            height: 700px;
        }
    }

    .paragon-section.section-top h1 {
        -webkit-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        transform: translateX(-50px);
        opacity: 0;
    }

    .paragon-section.section-top .background,
    .paragon-section.section-top .background-mobile {
        left: 100px;
    }

    .paragon-section.section-top .content {
        left: 0;
        max-width: 800px;
        bottom: 200px;
    }

    @media only screen and (min-width: 1440px) {
        .paragon-section.section-top .content {
            bottom: 150px;
            @media (max-height: 950px) {
                bottom: 0px;
            }
        }
    }

    @media only screen and (max-width: 1439px) {
        .paragon-section.section-top .content {
            bottom: 100px;
            @media (max-height: 950px) {
                bottom: 0px;
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-top .content {
            position: relative;
            margin: 30% auto 0;
            bottom: 0;
            text-align: center;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-top .content {
            position: absolute;
            bottom: 30%;
            left: 0;
            max-width: 100%;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-top .content {
            bottom: auto;
            top: 0;
        }
    }

    @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
        .paragon-section.section-top .content {
            margin-top: 60%;
        }
    }

    .paragon-section.section-top .content .desc {
        margin-top: 40px;
        max-width: 700px;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-top .content .desc {
            max-width: 100%;
        }
    }

    .paragon-section.section-top .content a.btn {
        margin-top: 60px;
        font-size: 0.875em;
        padding: 1em;
    }

    .paragon-section.section-top.inViewport .content {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .paragon-section.section-top.inViewport .background,
    .paragon-section.section-top.inViewport .background-mobile {
        left: 0;
    }

    @media only screen and (device-max-width: 768px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
        .paragon-section.section-top.inViewport .background,
        .paragon-section.section-top.inViewport .background-mobile {
            background-size: cover;
            background-position: 0 0;
        }
    }

    @media only screen and (device-max-width: 992px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
        .paragon-section.section-top.inViewport .background,
        .paragon-section.section-top.inViewport .background-mobile {
            background-position: 0 0;
            background-size: cover;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-top.inViewport .background,
        .paragon-section.section-top.inViewport .background-mobile {
            background-position: 0 0;
        }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .paragon-section.section-top.inViewport .background,
        .paragon-section.section-top.inViewport .background-mobile {
            background-position: 0 0;
        }
    }

    .paragon-section.section-top.inViewport h1 {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        -webkit-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-right {
        height: 75vh;
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-right {
            height: 100vh;
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right {
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            height: 1000px;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-right {
            height: 1200px;
        }
    }

    @media only screen and (max-width: 767px) and (orientation: landscape) {
        .paragon-section.section-right {
            height: 800px;
        }
    }

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        .paragon-section.section-right {
            height: 150vh;
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right .container-fluid {
            -webkit-order: 1;
            -ms-flex-order: 1;
            order: 1;
            width: 100%;
            height: 400px;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-right .container-fluid {
            height: 510px;
        }
    }

    @media only screen and (max-width: 767px) and (orientation: landscape) {
        .paragon-section.section-right .container-fluid {
            height: 400px;
        }
    }

    .paragon-section.section-right .background,
    .paragon-section.section-right .background-mobile {
        left: 100px;
    }

    @media only screen and (max-width: 1439px) {
        .paragon-section.section-right .background,
        .paragon-section.section-right .background-mobile {
            background-position: -200px 0;
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right .background,
        .paragon-section.section-right .background-mobile {
            -webkit-order: 2;
            -ms-flex-order: 2;
            order: 2;
            position: relative;
            width: 100%;
            bottom: auto;
            height: 600px;
            background-position: 0 0;
        }
    }

    @media only screen and (max-width: 767px) and (orientation: landscape) {
        .paragon-section.section-right .background,
        .paragon-section.section-right .background-mobile {
            height: 400px;
        }
    }

    .paragon-section.section-right .content {
        right: 0;
        max-width: 800px;
        color: #000;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right .content {
            position: relative;
            width: 100%;
            max-width: 100%;
            margin-top: 150px;
        }
    }

    @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        .paragon-section.section-right .content {
            bottom: auto;
            top: 40px;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-right .content {
            margin-top: 75px;
        }
    }

    .paragon-section.section-right .content .desc {
        margin-top: 40px;
        max-width: 500px;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
        opacity: 0;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right .content .desc {
            max-width: 100%;
        }
    }

    .paragon-section.section-right .content .h2 {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
    }

    .paragon-section.section-right.inViewport .background,
    .paragon-section.section-right.inViewport .background-mobile {
        left: 0;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-right.inViewport .background,
        .paragon-section.section-right.inViewport .background-mobile {
            left: 0;
            bottom: -300px;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-right.inViewport .background,
        .paragon-section.section-right.inViewport .background-mobile {
            top: auto;
            bottom: -50px;
            background-position: 0 0;
        }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .paragon-section.section-right.inViewport .background,
        .paragon-section.section-right.inViewport .background-mobile {
            top: auto;
            bottom: -80px;
            background-position: 0 0;
        }
    }

    @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
        .paragon-section.section-right.inViewport .background,
        .paragon-section.section-right.inViewport .background-mobile {
            background-position: 0 450px;
        }
    }

    .paragon-section.section-right.inViewport .desc {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-right.inViewport .h2 {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-form div.h2 {
            padding-top: 60px;
        }
    }

    .paragon-section.section-form a.btn {
        margin-top: 2em;
        margin-bottom: 5em;
    }

    .paragon-section.section-left {
        height: 90vh;
    }

    .paragon-section.section-left .content {
        left: 0;
        max-width: 800px;
    }

    .paragon-section.section-left .content .desc,
    .paragon-section.section-left .content .h2 {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
    }

    .paragon-section.section-left .content .desc {
        margin-top: 40px;
        max-width: 500px;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-left .background,
        .paragon-section.section-left .background-mobile {
            background-size: cover;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-left .background,
        .paragon-section.section-left .background-mobile {
            background-position: -500px 0;
        }
    }

    .paragon-section.section-left.inViewport .content {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .paragon-section.section-left.inViewport .background,
    .paragon-section.section-left.inViewport .background-mobile {
        background-position: 0 0;
    }

    .paragon-section.section-left.inViewport .desc {
        -webkit-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-left.inViewport .desc,
    .paragon-section.section-left.inViewport .h2 {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }

    .paragon-section.section-left.inViewport .h2 {
        -webkit-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-form {
        height: 75vh;
        background-position: 0 -200px;
        overflow: hidden;
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-form {
            height: 650px;
            min-height: 650px;
        }
    }

    .paragon-section.section-form .h2 {
        padding-top: 100px;
        font-size: 2.5em;
        font-family: Brutal_Bold, sans-serif;
        opacity: 0;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
    }

    .paragon-section.section-form .content {
        position: relative;
        bottom: auto;
        text-align: center;
    }

    .paragon-section.section-form .content .desc {
        margin-top: 40px;
        max-width: 100%;
        opacity: 0;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
    }

    .paragon-section.section-form .content .paragon-submit-form {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
        transform: translateY(40px);
        margin: 50px 0;
    }

    .paragon-section.section-form .content .paragon-submit-form input.form-control {
        color: #fff;
    }

    .paragon-section.section-form .content .paragon-submit-form .disabled.submitted {
        cursor: auto;
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-form .content .paragon-submit-form {
            width: 80%;
            margin: 50px auto;
        }
        .paragon-section.section-form .content .paragon-submit-form .container {
            width: 100%;
        }
    }

    @media only screen and (max-width: 1439px) {
        .paragon-section.section-form .background,
        .paragon-section.section-form .background-mobile {
            background-position: center 0;
        }
    }

    @media only screen and (max-width: 1199px) {
        .paragon-section.section-form .background,
        .paragon-section.section-form .background-mobile {
            background-size: cover;
        }
    }

    @media only screen and (max-width: 991px) {
        .paragon-section.section-form .background,
        .paragon-section.section-form .background-mobile {
            background-position: center 0;
        }
    }

    @media only screen and (max-width: 767px) {
        .paragon-section.section-form .background,
        .paragon-section.section-form .background-mobile {
            background-position: 50%;
        }
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .paragon-section.section-form .background,
        .paragon-section.section-form .background-mobile {
            background-position: 50%;
        }
    }

    .paragon-section.section-form.inViewport .desc,
    .paragon-section.section-form.inViewport .paragon-submit-form {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .paragon-section.section-form.inViewport .h2 {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        -o-transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        transition: all 1.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    @media only screen and (max-width: 991px) {
        .paragon-section {
            height: auto;
            padding-bottom: 50px;
        }
    }
`;
