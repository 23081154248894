import axios, {AxiosResponse} from 'axios';
import {isAxiosError, isError} from '@epic-mw/error-types';
import qs from 'querystringify';
import { SearchError, SearchRes } from '../types/search.request.types';



class SearchFetch {
    public async getSearchResults(requestStr?: string): Promise<SearchRes | undefined> {
        const request: Request = JSON.parse(requestStr || '{}');
        const queryParams = qs.stringify(request);

        try {
            const response: AxiosResponse<SearchRes> = await axios(`/api/search?${queryParams}`);
            return response.data;
        } catch (e) {
            console.error(
                'getSearchResults() failed to load',
                queryParams && isError(e) && e.message
            );
            if (isAxiosError(e)) {
                const searchError: SearchError = new Error(e.message);
                searchError.info = isAxiosError(e) && e.response?.data;
                searchError.status = e.response?.status;
                throw searchError;
            }
        }
        return undefined;
    }
}

export const searchFetcher = new SearchFetch();
