import styled from 'styled-components';
import {PageData} from '@epic-mw/types';
import React from 'react';
import {ArticleSectionSelection, HeroBasicProps} from 'epic-ue-ui';

import {TemplateThemeProps} from 'epic-ue-ui';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import {
    CmsDynamicArray,
    DownloadSection,
    ImageProps,
    NewsletterSection,
    SubnavSection,
    SubnavSectionConfigProps,
    VerticalSpacer,
    ArticleSectionSelectionProps
} from 'epic-ue-ui';
import {AnimateOnViewWrap, ImageHero} from 'epic-ue-ui';
import {getHelmetForMetaTags} from 'epic-ue-shared';
import Helmet from 'react-helmet';
import SimilarBlogs, {SimilarBlogsProps} from '../similarblogs/SimilarBlogs';
import {DownloadWithNewsletterSectionProps} from '../../pages/stardust/ExplainersStardustPage';
import {useLocalization} from '@epic-mw/localization';
import {ImageAndTextCardLogoProps} from 'epic-ue-ui';
import {ArticleTags} from 'epic-ue-ui';

export interface NewsLandingConfigLargeProps {
    sticky?: boolean;
    image?: ImageProps;
    title?: string;
    buttonLabel?: string;
    logo?: ImageAndTextCardLogoProps;
}
export interface NewsLandingConfigSmallProps extends NewsLandingConfigLargeProps {
    description?: string;
}

export type NewsLandingConfigOptions = NewsLandingConfigLargeProps | NewsLandingConfigSmallProps;
export type NewsLandingConfigOptionsArray = CmsDynamicArray<NewsLandingConfigOptions>;

export interface ArticleDetailPageData extends PageData {
    header?: HeroBasicProps;
    newsLandingConfig?: {
        options?: NewsLandingConfigOptionsArray;
    };
    content?: ArticleSectionSelectionProps;
    themeConfig?: TemplateThemeProps;
    title?: string;
    download: DownloadWithNewsletterSectionProps;
    similarBlogs?: SimilarBlogsProps;
    tags?: string;
    disableTagComponent?: boolean;
}

const ArticleDetailContent = styled.div`
    .article-section {
        z-index: 2;
        position: relative;
    }
`;

interface Props {
    pageData: ArticleDetailPageData;
    locale: string;
}

export default function ArticleDetailStardust({pageData, locale}: Props): JSX.Element {
    const pageDefaultedToEnglish = pageData._locale === 'en-US' && locale && locale !== 'en-US';
    const getMessage = useLocalization();

    const {
        themeConfig = {} as TemplateThemeProps,
        download = {} as DownloadWithNewsletterSectionProps,
        similarBlogs = {} as SimilarBlogsProps,
        disableTagComponent
    } = pageData;

    const {accentColor, theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const ueTheme = theme === 'light' ? ueLightTheme : ueDarkTheme;
    const header = pageData?.header || {};

    const downloadSectionConfig = {
        ...download?.sectionConfig,
        theme: 'light',
        accentColor,
        itemNumberColorOverride: 'black',
        index: null
    } as SubnavSectionConfigProps;

    const downloadBlockProps = {
        ...download?.download
    };

    const isBlankDownload = !downloadBlockProps?.cards || !downloadBlockProps?.cards?.length;

    const tags = similarBlogs.tags || pageData.tags || '';
    const tagParts = tags.split(',');
    const firstTag = tagParts[0] || '';
    let defaultSeeAll = '';
    if (firstTag) {
        defaultSeeAll = `/feed/all/${firstTag}`;
    }

    const newsletterEl = (
        <NewsletterSection
            variant={download.newsletter?.variant || 'small-split'}
            {...download?.newsletter}
            privacyPolicyLinkName={
                download?.newsletter?.privacyPolicyLinkName ||
                getMessage('epic.footer.privacy_policy')
            }
            title={download?.newsletter?.title || getMessage('epic.ue.newsletter.title')}
            industryPlaceholder={
                download?.newsletter?.industryPlaceholder ||
                getMessage('epic.ue.newsletter.select.label')
            }
            accentColor={accentColor}
        />
    );

    const showTags = !disableTagComponent && pageData.tags;
    const showSimilarBlogs = !similarBlogs?.disable;

    return (
        <>
            {getHelmetForMetaTags(pageData)}
            {pageDefaultedToEnglish && (
                <Helmet
                    meta={[
                        {
                            property: 'og:locale',
                            content: pageData._locale
                        },
                        {
                            name: 'robots',
                            content: 'noindex, nofollow'
                        }
                    ]}
                />
            )}
            <UeThemeProvider theme={ueTheme}>
                <ArticleDetailContent className={`article-detail ${theme}`}>
                    <ImageHero
                        smallDescription={header?.dateStr}
                        showScrollIndicator
                        showSocialIcons
                        thin
                        alignment="centerXY"
                        image={header?.image?.src}
                        eyebrow={header?.eyebrow}
                        title={header?.title}
                        description={header?.description}
                        caption={header?.caption?.text}
                        buttonText={header?.button1?.label}
                        href={header?.button1?.href}
                        secondaryButtonText={header?.button2?.label}
                        secondaryHref={header?.button2?.href}
                    />
                    <VerticalSpacer variant="section" />
                    <AnimateOnViewWrap delay={0.3} translateY={10}>
                        <div className="article-section">
                            <ArticleSectionSelection
                                {...pageData.content}
                                tags={pageData.tags}
                                accentColor={accentColor}
                            />
                        </div>
                    </AnimateOnViewWrap>

                    <VerticalSpacer variant="feature" />

                    {showTags && <ArticleTags tags={pageData.tags} urlPattern="/feed/all/" />}

                    {showSimilarBlogs && (
                        <>
                            {showTags && <VerticalSpacer variant="feature" />}
                            <SimilarBlogs
                                {...similarBlogs}
                                tags={similarBlogs.tags || pageData.tags}
                                seeAllHref={similarBlogs?.seeAllHref || defaultSeeAll}
                            />
                        </>
                    )}

                    {showTags || showSimilarBlogs ? <VerticalSpacer variant="feature" /> : ''}

                    {isBlankDownload ? (
                        newsletterEl
                    ) : (
                        <SubnavSection
                            variant="gradient"
                            hideBottomSectionPadding
                            sectionConfig={downloadSectionConfig}>
                            <DownloadSection
                                {...downloadBlockProps}
                                accentColor={accentColor}
                                variant="standard"
                            />
                            <VerticalSpacer variant="custom" spacing={16} downSmSpacing={7} />
                            {newsletterEl}
                        </SubnavSection>
                    )}
                </ArticleDetailContent>
            </UeThemeProvider>
        </>
    );
}
