import { UnrealLoading } from 'epic-ue-loading';
import { useAccountStore } from 'epic-ue-shared';
import { Typography, VerticalSpacer, Button } from 'epic-ue-ui';
import React from 'react';
import { eulaKeyUnreal } from '../../constants';
import { useEulaAccepted } from '../../shared';
import styled from 'styled-components';
import root from 'window-or-global';
import parse from 'url-parse';

const Styled = styled.div`
    text-align: center;
    width: 100%;
`;

interface Props {
    requireLogin?: string;
    login?: string;
    requireEula?: string;
    viewAccept?: string;
}

export const LinuxAccountCheck = ({requireLogin, login, requireEula, viewAccept}: Props):JSX.Element | null => {
    const {account, loading: accountLoading, loaded: accountLoaded} = useAccountStore();
    const {accepted, loadingAccepted} = useEulaAccepted(eulaKeyUnreal);

    if (accountLoading || !accountLoaded || loadingAccepted) {
        return <UnrealLoading fillViewHeight={false} />;
    }

    const isLoggedIn = account.isLoggedIn || false;
    const parsedUrl = parse(root.location.href);
    const origin = parsedUrl.origin || root.location.origin;

    if (!isLoggedIn) {
        return (
            <Styled className="linux-account-check not-logged-in">
                <Typography variant="h3">{requireLogin || 'You must be logged in to view builds.'}</Typography>
                <VerticalSpacer variant="heading" />
                <Button
                    id="linux-login"
                    type="primary"
                    href={`${origin}/login?state=/linux`}
                >
                    {login || 'Login'}
                </Button>
            </Styled>
        );
    }

    if (!accepted) {
        return (
            <Styled className="linux-account-check not-accepted">
                <Typography variant="h3">{requireEula || 'You must agree to the Unreal Engine EULA to download.'}</Typography>
                <VerticalSpacer variant="heading" />
                <Button
                    id="linux-accept"
                    type="primary"
                    href={`${origin}/eulacheck?state=/linux`}
                >
                    {viewAccept || 'View and Accept'}
                </Button>
            </Styled>
        );
    }

    return null;
}
