import React from 'react';
import {Features} from './Features';
import {Button} from '../../elements';
import {SectionWrapper} from '../../layout/sections/Section.styles';
import styled from 'styled-components';
import {FeaturesProps} from 'epic-ue-shared';

export interface FeatureListProps {
    features: FeaturesProps[];
    title?: string;
    desc?: string;
    label?: string;
    href?: string;
    type?: 'inverted' | undefined;
    center?: boolean;
}

const FeatureListWrapper = styled.div`
    .studyTitle > h2 {
        text-align: center;
    }
    > div:first-child {
        margin-top: 50px;
    }

    .see-more-wrapper {
        text-align: center;
        .btn {
            margin: 40px auto 0;
        }
        #see-more-features.btn {
            ${(p) => p.theme.breakpoints.down('md')} {
                margin: 0 auto 60px;
            }
        }
    }

    .features-copy .copy {
        text-align: center;
        margin: 1em auto 2em;
    }

    .feature-container {
        &:empty {
            display: none;
        }
        .gradient {
            border-top: 1px solid #444546;
            background: linear-gradient(180deg, #151617 0%, rgba(21, 22, 23, 0.0001) 100%);
        }
        h1,
        h2 {
            text-align: center;
            position: relative;
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
`;

export const FeatureList = ({
    features = [],
    title,
    desc,
    label,
    href
}: FeatureListProps): JSX.Element => {
    const featureTitle = title || '';
    const featureDesc = desc || '';
    const featureDetailHref = href || '';
    const featureDetailLabel = label || '';
    const featureList = features || [];
    let seeMoreButton = <div />;

    if (featureDetailLabel && featureDetailHref) {
        seeMoreButton = (
            <div className="container">
                <div className="row see-more-wrapper">
                    <Button
                        as="a"
                        target={'_blank'}
                        id={`see-more-features`}
                        href={featureDetailHref}
                        type="secondary">
                        {featureDetailLabel}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <FeatureListWrapper className="features">
            {featureList && featureList.length > 1 ? (
                <SectionWrapper>
                    <Features features={featureList} title={featureTitle} desc={featureDesc} />
                    {seeMoreButton}
                </SectionWrapper>
            ) : (
                <div />
            )}
        </FeatureListWrapper>
    );
};
