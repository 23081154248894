import styled from 'styled-components';

export const GalleryWrapper = styled.div`
    .container,
    .row {
        padding: 0;
    }
    .gallery-container {
        margin: 0 auto;
        max-width: 1024px;
        margin-bottom: 40px;

        .gallery-wrapper {
            .container {
                margin: 0 auto;
            }
        }
    }

    .gallery-desc {
        .gallery-container {
            max-width: 1200px;

            .image-wrapper,
            .content-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 60%;
                height: 450px;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    width: 100%;
                    height: auto;
                    position: relative;
                    overflow: hidden;
                }

                > div {
                    width: 100%;
                    height: 100%;
                }
            }

            .image-wrapper {
                ${(p) => p.theme.breakpoints.down('lg')} {
                    height: 100%;
                }
            }

            .content-wrapper {
                width: 40%;
                ${(p) => p.theme.breakpoints.down('lg')} {
                    width: 100%;
                    height: auto;
                }
                left: auto;
                right: 0;
                padding: 20px 40px;
                h4 {
                    font-size: 1.5em;
                }
                .content {
                    height: 175px;
                    overflow: hidden;
                }
            }

            .carouselGallery.desc {
                ${(p) => p.theme.breakpoints.down('lg')} {
                    height: auto;
                    .image-wrapper {
                        height: 400px;
                    }
                    .slides ul {
                        height: 750px;
                    }
                }
                ${(p) => p.theme.breakpoints.down('sm')} {
                    height: auto;
                    .image-wrapper {
                        height: 200px;
                    }
                    .slides ul {
                        height: 600px;
                    }
                }
            }

            .carouselGallery {
                .slides ul {
                    width: 100%;
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                        width: 75%;
                    }
                }

                ul#carousel-slider {
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                        height: 800px !important;
                    }
                    padding-top: 0 !important;
                }

                .cg-arrows {
                    top: 25%;
                    ${(p) => p.theme.breakpoints.up('lg')} {
                        right: 40px;
                        top: 375px;
                    }
                }

                .icon-arrow-left {
                    ${(p) => p.theme.breakpoints.up('lg')} {
                        left: auto;
                        right: 100px;
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                        left: 40px;
                        right: auto;
                    }
                }
            }
        }
    }

    .gallery-full {
        .gallery-container {
            max-width: 100%;
            .carouselGallery {
                .slides ul {
                    height: 95vh;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        height: 200px;
                    }
                    width: 100%;
                }
                .cg-arrows {
                    color: #fff;
                    opacity: 1;
                    &.icon-arrow-left {
                        left: 20px;
                    }
                    &.icon-arrow-right {
                        right: 20px;
                    }
                }
            }
        }
    }

    .carouselGallery {
        margin: 40px auto 0;
        &.hasArrows {
            .slides {
                ul {
                    height: 500px;
                    ${(p) => p.theme.breakpoints.down('md')} {
                        height: 200px;
                    }
                    width: 100%;
                    margin: 0 auto;
                }
            }
        }
        .slides {
            ul {
                height: 400px;
                margin: 0 auto;
            }
        }
    }
`;
