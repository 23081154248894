import {SSRHandlerContext} from '@epic-core/ssr-common';
import React, {createContext} from 'react';

export const HttpContext = createContext<SSRHandlerContext | null>(null);

type ProviderProps = {
    children?: React.ReactChild;
    context: SSRHandlerContext;
};

export const HttpProvider = ({children, context}: ProviderProps): JSX.Element => {
    return <HttpContext.Provider value={context}>{children}</HttpContext.Provider>;
};
