import styled from 'styled-components';

export const TopFeedContainer = styled.div`
    &.feed-item {
        min-height: 60px;
        margin-bottom: 32px;
        &.top-feed-item {
            position: relative;
            overflow: hidden;
            background: transparent;
            height: 100%;
            ${(p) => p.theme.breakpoints.up('xxl')} {
                padding: 0;
                margin-bottom: 32px;
            }
            .content {
                position: absolute;
                bottom: 25px;
                left: 20px;
                ${(p) => p.theme.breakpoints.up('xxl')} {
                    left: 40px;
                }
                ${(p) => p.theme.breakpoints.down('md')} {
                    left: 5px;
                    bottom: 30px;
                    padding: 20px 10px;
                }
                ${(p) => p.theme.breakpoints.down('xs')} {
                    bottom: unset;
                    top: 0;
                    padding: 20px 10px;
                    overflow: hidden;
                }
                z-index: 2;
                background: transparent;
                min-height: 20px;
                .short {
                    opacity: 1;
                    max-height: initial;
                    transition: max-height 1.5s ease-in, opacity 1s ease-in;
                }
            }
            .overflow-gradient {
                ${(p) => p.theme.breakpoints.down('sm')} {
                    position: absolute;
                    bottom: 0px;
                    height: 100px;
                    z-index: 2;
                    width: 100%;
                    background: linear-gradient(transparent,#000);
                }
            }

            .type {
                margin-bottom: 10px;
                opacity: 1;
            }
            .title {
                h2 {
                    font-size: 3em;
                    text-transform: initial;
                    line-height: 1.2em;
                    ${(p) => p.theme.breakpoints.down('xxl')} {
                        font-size: 2em;
                        line-height: 1em;
                    }
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 1.5em;
                    }
                }
            }
            .container-image-bg {
                position: relative;
                height: 100%;
                padding-bottom: 56.25%;
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 50%,
                    rgba(0, 0, 0, 0.75) 80%,
                    rgba(0, 0, 0, 0.85) 90%,
                    rgba(0, 0, 0, 0.95) 100%
                );
            }
            .container-image {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
                height: 550px;
                transition: transform 350ms ease-in-out, box-shadow 350ms ease-in-out,
                    opacity 350ms ease-in-out;
                background: transparent;
                padding-bottom: 56.25%;
                height: 1px;
                width: 101%;
                .feature-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-position: center 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin: 0 auto;
                    z-index: 0;
                    opacity: 1;
                }
            }
            &:hover {
                .container-image {
                    opacity: 0.96;
                    box-shadow: inset 0 0 8em 1em rgba(0, 0, 0, 0.2);
                    transform: scale3d(1.02, 1.02, 1.02);
                }
                .short {
                    opacity: 1;
                }
            }
        }

        &.no-results {
            height: 350px !important;
            background-color: ${(p) => p.theme.palette.background.paper};
            background-size: cover;
            padding: 60px;

            h2 {
                font-size: 4em;
                color: ${(p) => p.theme.palette.text.primary};
                margin-top: 0;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 1.5em;
                }
            }
            p {
                color: ${(p) => p.theme.palette.text.primary};
                font-size: 1.1em;
                ${(p) => p.theme.breakpoints.down('sm')} {
                    font-size: 0.875em;
                }
            }
        }
    }
`;
