import React from 'react';
import PropTypes from 'prop-types';
import CustomerTalkCard from './CustomerTalkCard';
import SlickWrapper from 'epic-react-common/src/components/SlickWrapper';
import {getBowserState} from '@epic-core/common';
import {CustomerTalksWrapper} from './CustomerTalks.styles';

export default class CustomerTalksPanel extends React.PureComponent {
    static propTypes = {
        headline: PropTypes.string,
        talks: PropTypes.array,
        locale: PropTypes.string
    };

    render() {
        const {mobile, tablet} = getBowserState();

        let slidesToShow = 3;
        let slidesToScroll = 3;
        if (tablet) {
            slidesToShow = 2;
            slidesToScroll = 2;
        } else if (mobile) {
            slidesToShow = 1;
            slidesToScroll = 1;
        }

        const talks = this.props.talks || [];
        const settings = {
            adaptiveHeight: true,
            infinite: true,
            slidesToShow,
            dots: true,
            arrows: (talks.length && talks.length > 3 && !mobile) || false,
            swipeToSlide: true,
            swipe: true,
            touchMove: true,
            slidesToScroll,
            centerMode: mobile || false
        };

        const {headline, locale} = this.props;

        const cards = talks.map((talk, index) => {
            const dateStr = typeof talk.date === 'object' ? talk.date.toString() : talk.date || '';
            return (
                <CustomerTalkCard
                    key={talk.headline}
                    headline={talk.headline}
                    body={talk.body}
                    richBody={talk.richBody}
                    image={talk.imageURL}
                    alt={talk.alt}
                    date={dateStr}
                    presenterName={talk.presenterName}
                    room={talk.room}
                    targetURL={talk.targetURL}
                    tags={talk.tags}
                    locale={locale}
                />
            );
        });

        return (
            <CustomerTalksWrapper className="block-white">
                <div className="block-title">{headline}</div>
                {cards.length > 0 && (
                    <SlickWrapper className="container block-talks" options={settings}>
                        {cards}
                    </SlickWrapper>
                )}
            </CustomerTalksWrapper>
        );
    }
}
