import styled from 'styled-components';

export const ArticleDetailsWrapper = styled.div<{offsetHeight?: string}>`
    width: 230px;
    position: absolute;
    left: 100px;
    top: ${(p) => (p.offsetHeight ? p.offsetHeight : '70vh')};
    @media screen and (max-width: 1400px) {
        position: relative;
        overflow: hidden;
        max-width: 730px;
        margin: 0 auto 20px;
        width: 100%;
        min-height: 150px;
        top: 0;
        padding: 10px 0;
        left: auto;
        .badge-wrapper {
            .badge-logo {
                float: left;
                margin-right: 15px;
                margin-bottom: 30px;
                margin-top: 10px;
            }
            h4 {
                margin-top: 0;
            }
            .badge-logo > img {
                width: 100px;
                margin: 0;
            }
            .badge-content {
                padding: 10px 0;
            }
        }
    }

    &.isBio {
        position: relative;
        overflow: hidden;
        max-width: 730px;
        margin: 0 auto 20px;
        width: 100%;
        min-height: 150px;
        top: 0;
        padding: 10px 0;
        left: auto;
        .badge-wrapper {
            .badge-logo {
                float: left;
                margin-right: 15px;
                margin-bottom: 30px;
                margin-top: 10px;
            }
            h4 {
                margin-top: 0;
            }
            .badge-logo > img {
                width: 100px;
                margin: 0;
            }
            .badge-content {
                padding: 10px 0;
            }
        }
    }
    border-radius: 2px;
    z-index: 8;
    &:not(:empty) {
        background-color: ${(p) => p.theme.palette.background.paper};
        padding: 20px;
    }
    &:empty {
        display: none;
    }
    .badge-wrapper {
        list-style: none;
        margin: 0;
        padding: 0;
        color: #ccc;
        img {
            width: 190px;
            margin: 0 auto 0px;
        }
        strong,
        span {
            display: block;
            color: #f5f5f5;
        }
        .badge-content {
            font-size: 0.875em;
            h4 {
                font-size: 1.5em;
                margin-bottom: 15px;
            }
        }
    }
`;
