import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
    BioAvatar,
    BioContent,
    BioName,
    BioPosition,
    BioEmployer,
    BioShort,
    BioContainer,
    BioModal,
    BioWrapper,
    BioGrid
} from './BioCard.styles';
import {BioCardProps} from 'epic-ue-shared';
import {BgImg, SimpleLink} from '../';
import root from 'window-or-global';
import {getBowserState} from '@epic-core/common';

let listenerActive = false;

export const Bio = ({
    title,
    position,
    avatar,
    name,
    company,
    url,
    gridClasses,
    label,
    desc
}: BioCardProps): JSX.Element => {
    const [bioOpen, setBioOpen] = useState(false);
    const {mobile, tablet} = getBowserState();
    const bioRef = useRef<HTMLDivElement>(null);
    const bioContentRef = useRef<HTMLDivElement>(null);
    const bioClick = useCallback(
        e => {
            e.preventDefault();

            if (desc) {
                setBioOpen(!bioOpen);
            }
        },
        [bioOpen, setBioOpen]
    );

    const bodyClick = useCallback(
        e => {
            if (bioOpen) {
                if (bioContentRef.current === e.target || bioRef.current === e.target) {
                    return;
                }
                setBioOpen(false);
            }
        },
        [setBioOpen, bioOpen]
    );

    useEffect(() => {
        if (bioOpen && !listenerActive) {
            root.document.addEventListener('mousedown', bodyClick, false);
            listenerActive = true;
        } else if (!bioOpen && listenerActive) {
            root.document.removeEventListener('mousedown', bodyClick, false);
            listenerActive = false;
        }
        return () => {
            root.document.removeEventListener('mousedown', bodyClick, false);
            listenerActive = false;
        };
    }, [bioOpen, listenerActive, bodyClick, root]);

    const bioElem = (
        <div>
            {avatar && (
                <BioAvatar>
                    <BgImg img={avatar} w={800} />
                </BioAvatar>
            )}
            {name && <BioName className="card-title">{name}</BioName>}
            {position && (
                <BioPosition>
                    <span>{position}</span>
                </BioPosition>
            )}
            {company && (
                <BioEmployer>
                    <span>{company}</span>
                </BioEmployer>
            )}
        </div>
    );

    let link;
    if (url && label) {
        link = <SimpleLink href={url} title={label} id={`bio-${title}`} />;
    }

    return (
        <BioGrid
            className={`${gridClasses} ${desc ? 'hasDesc' : 'noDesc'} ${bioOpen ? 'open' : ''}`}>
            <BioWrapper className={`${mobile || tablet ? 'handheld' : ''}`}>
                <BioContainer
                    className="bio-card"
                    ref={bioRef}
                    onClick={bioClick}
                    role="presentation">
                    <BioContent className="bio-content" ref={bioContentRef}>
                        {bioElem}
                        <div>
                            <span>{link}</span>
                        </div>
                    </BioContent>
                </BioContainer>
            </BioWrapper>

            <BioModal className={`${bioOpen ? 'open' : ''} bioModal`}>
                <BioContent className="bio-content bio-modal" ref={bioContentRef}>
                    {bioElem}
                    <BioShort>
                        <div>
                            <div
                                className="bio-short-copy"
                                dangerouslySetInnerHTML={{__html: desc || ''}}
                            />
                        </div>
                    </BioShort>
                </BioContent>
            </BioModal>
        </BioGrid>
    );
};
