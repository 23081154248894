import React from 'react';
import {UnrealUIContext} from 'epic-ue-shared';
import {Link, useNavigate} from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}

export const UnrealUIAppContext = ({children}: Props):JSX.Element => {
    const navigate = useNavigate();
    const pushable = {
        push: navigate
    };

    return (
        <UnrealUIContext.Provider
            value={{
                RouteComponent: Link,
                router: pushable
            }}>
            {children}
        </UnrealUIContext.Provider>
    );
}
