import React, {useState, useEffect, useCallback} from 'react';
import {UnrealEngineNavigation} from '@wf-components/navigation-react';
import {
    NavSearchEvent,
    NavLinkClickEvent,
    NavCtaClickEvent,
    NavPropertyLogoClickEvent,
    NavCustomAccountLink
} from '@wf-components/navigation-consumer';
import {generateRoutePath, getLocale} from '@epic-core/common';
import {useAccountStore} from 'epic-ue-shared';
import {useLocation, useNavigate} from 'react-router-dom';
import root from 'window-or-global';
import {useEnterpriseSeats} from '../shared';
import {NavBannerBar} from './NavBannerBar';
import {isPathDisabled} from '../shared/navUtils';

interface Props {
    disableCtaOnMobilePaths?: string[];
    disablePaths?: string[];
}

const educationPattern = RegExp(
    '/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})/(students|academic-admins|educators)/?$'
);
const feedPattern = RegExp('/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){{0,2})/feed/?');
const newsPattern = RegExp(
    '/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})/(news|blog|feed|developer-interviews|tech-blog|events|spotlights)/?'
);

const solutionsPattern = RegExp('/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})/solutions/?');
const virtualProductionPattern = RegExp(
    '/([a-zA-Z0-9]{2}(?:[-_][a-zA-Z0-9]{2,4}){0,2})/virtual-production'
);

const determineActiveKey = (pathname: string): string => {
    if (pathname.includes('/education') || educationPattern.test(pathname)) {
        return 'learn-parent';
    } else if (feedPattern.test(pathname) || newsPattern.test(pathname)) {
        return 'news-parent';
    } else if (solutionsPattern.test(pathname) || virtualProductionPattern.test(pathname)) {
        return 'solutions-parent';
    }
    return '';
};

export const GlobalNavWrapper = ({
    disableCtaOnMobilePaths,
    disablePaths
}: Props): JSX.Element | null => {
    const {account} = useAccountStore();
    const location = useLocation();
    const pathname = location.pathname;
    const locale = getLocale();
    const navigate = useNavigate();
    const clientConfig = root.clientEnvConfig || {};
    const serverUrl = clientConfig.EPIC_SERVER_URL;

    const [accountState, setAccountState] = useState('');

    useEffect(() => {
        if (window.location.href) {
            try {
                const url = new URL(window.location.href);
                if (url) {
                    url.searchParams.append('state', pathname);
                    url.searchParams.append('lang', locale.replace(/-/, '_'));
                    setAccountState(url.searchParams.toString());
                }
            } catch (e) {
                console.log('Failed to parse href', e);
            }
        }
    }, [pathname]);

    const {hasAccess: hasManagementAccess, title: manageSeatTitle} = useEnterpriseSeats();
    const customAccountLinks: NavCustomAccountLink[] = [];
    if (hasManagementAccess && manageSeatTitle) {
        customAccountLinks.push({
            id: 'custom-enterprise-management',
            locale,
            label: manageSeatTitle,
            key: 'enterpriseManagement',
            hrefTemplate: `${serverUrl}/enterprise-management?lang=${locale}`
        });
    }

    const navLinkOnClick = useCallback(
        (e: CustomEvent<NavLinkClickEvent | NavPropertyLogoClickEvent>) => {
            const link = e.detail?.link;
            if (link && link?.owner === 'mw-ue' && link.relativePath) {
                e.detail.originalEvent.preventDefault();
                navigate(generateRoutePath(link.relativePath));
            }
        },
        [navigate, generateRoutePath]
    );

    const navOnSearch = useCallback(
        (e: CustomEvent<NavSearchEvent>) => {
            if (e.detail.value) {
                navigate(generateRoutePath(`/search?keyword=${e.detail.value}`));
            }
        },
        [navigate, generateRoutePath]
    );

    const ctaOnClick = useCallback(
        (e: CustomEvent<NavCtaClickEvent>) => {
            if (e.detail) {
                e.detail.originalEvent?.preventDefault();
                const link = e.detail?.link;
                navigate(generateRoutePath(`${link?.relativePath || '/download'}?start=true`));
            }
        },
        [navigate, generateRoutePath]
    );

    const [navBannerEnabled, setNavBannerEnabled] = useState(false);

    const disable = isPathDisabled(pathname, disablePaths);
    if (disable) return null;

    const disableCtaOnMobile = isPathDisabled(pathname, disableCtaOnMobilePaths);

    return (
        <>
            <NavBannerBar
                navBannerEnabled={navBannerEnabled}
                setNavBannerEnabled={setNavBannerEnabled}
            />
            <UnrealEngineNavigation
                domain={serverUrl}
                locale={locale}
                isLoggedIn={account.isLoggedIn}
                displayName={account.displayName}
                propLogoOnClick={navLinkOnClick}
                navLinkOnClick={navLinkOnClick}
                navOnSearch={navOnSearch}
                ctaOnClick={ctaOnClick}
                customAccountLinks={customAccountLinks}
                signInLinkHref={`/login${accountState ? `?${accountState}` : ''}`}
                signOutLinkHref={`/logout${accountState ? `?${accountState}` : ''}`}
                disableCtaButtonOnMobile={disableCtaOnMobile}
                topOffsetPixels={navBannerEnabled ? 30 : 0}
                forceActiveMainLinkKey={determineActiveKey(pathname)}
                skipNavContentId="ue-app"
            />
        </>
    );
};
