import React from 'react';

import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import Agenda from '../components/events/agenda/Agenda';
import Recordings from '../components/events/recordings/Recordings';
import Section from '../components/events/section/SectionSelection';
import parse from 'url-parse';
import root from 'window-or-global';
import EventBlog from '../components/events/blog/EventBlog';
import LeadIn from '../components/events/leadIn/LeadIn';
import VideoOverlay from '../components/events/VideoOverlay';
import Speakers from '../components/events/Speakers';
import {HeaderLeft} from '../components/events/Header/JumboHeaderLeft';
import {Event} from 'epic-ue-shared';
import {
    AdSectionProps,
    AgendaProps,
    BlogProps,
    CtaBlockData,
    CtaBlockDataProps,
    EngagementProps,
    HeaderProps,
    LeadInProps,
    NewsletterProps,
    RecordingsProps,
    SectionProps,
    SpeakersWrapperProps
} from 'epic-ue-shared';
import {FeedFilterProps, FeedStoreProps} from 'epic-ue-feed/src';
import {UeThemeProvider, ueLightTheme} from 'epic-ue-theme';

const isOptionalSectionContentValid = (opSec) => {
    if (!opSec) return false;
    if (opSec.title) return true;
    if (opSec.copy) return true;

    const media = opSec.media || {};
    return !!(media.type === 'image' && media.content && media.content.length);
};

const EventDetailViewWrapper = styled.div<{primaryColor: string | null}>`
    background: ${(p) => p.theme.palette.background.default};

    a.btn-primary {
        background-color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
    }
    a.btn-primary,
    a.btn-secondary {
        color: #fff;
        border-color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
        ${(p) => p.theme.breakpoints.down('xs')} {
            font-size: 0.875em;
        }
        &:active,
        &:hover {
            transition: filter ease 0.2s;
            background-color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
            filter: brightness(130%);
        }
    }

    a.btn-secondary {
        margin-left: 0;
    }

    h2 {
        text-align: center;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 1.5em;
        }
    }

    .end-of-feed-container {
        display: none;
    }

    .see-all-btn {
        border-color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
        color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
        &:hover {
            color: #fff !important;
            background-color: ${(p) => (p.primaryColor ? p.primaryColor : '#0aaff1')}!important;
        }
    }

    .card-wrapper {
        height: 250px;
    }

    .engage-section.newsletter button {
        border-color: ${(p) => p.theme.palette.neutral.stone};
    }

    .engage-section-border {
        border-color: ${(p) => p.theme.palette.neutral.stone};
    }
`;

const GreyBg = styled.div`
    background: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.text.primary};
`;

const DarkBg = styled.div`
    & div {
        background: #0e1128;
        color: ${(p) => p.theme.palette.common.white};
    }

    & .feed-item,
    & .feed-card,
    & .feed-item .content,
    & .feed-item .inner {
        background: #0e1128;
        color: ${(p) => p.theme.palette.common.white};
    }

    & .simple-link-wrap,
    & .feed-item h2 {
        color: ${(p) => p.theme.palette.common.white};
    }

    & div.title, div.title div {
        background: unset;
    }
`;

const AdditionalSectionHeader = styled.div`
    margin: 40px 0 20px;
    padding: 0 1em;
    h2 {
        font-family: Brutal_Light;
        font-size: 3em;
        text-transform: uppercase;
        ${(p) => p.theme.breakpoints.down('sm')} {
            font-size: 2em;
        }
    }
`;

export interface FeedStore {
    loading: boolean;
    paging: any;
    counts: any;
    contentTypes: any[];
    rows: any[];
}

interface Properties {
    feedStore: FeedStoreProps;
    currentFilters: FeedFilterProps;
    event: Event;
    getNextPage: any;
    feedLoading: boolean;
}

export const EventPage = ({
    feedStore,
    currentFilters,
    event,
    getNextPage,
    feedLoading
}: Properties): JSX.Element => {
    const [play, setPlay] = React.useState(false);
    const [video, setVideo] = React.useState(false);
    const [title, setTitle] = React.useState(false);
    const handleVideoOverlay = React.useCallback(
        (play, video, title) => {
            setPlay(play);
            setVideo(video);
            setTitle(title);
        },
        [play, video, title]
    );

    const pageData = event || {};
    if (!pageData) {
        return <UnrealLoading />;
    }
    const primaryColor = pageData.primaryColor || null;
    const passed = pageData.passed || false;
    const header = pageData.header || ({} as HeaderProps);
    const speakers = pageData.speakers || ({} as SpeakersWrapperProps);
    const sections = pageData.sections || ({} as AdSectionProps);
    const recordings = pageData.recordings || ({} as RecordingsProps);
    const opSec1 = pageData.opSec1 || ({} as SectionProps);
    const opSec2 = pageData.opSec2 || ({} as SectionProps);
    const blog = pageData.blog || ({} as BlogProps);
    const showBlog = !blog.hidden && blog.tags && true;

    const leadIn = pageData.leadIn || ({} as LeadInProps);
    const closer = pageData.closer || ({} as SectionProps);
    const agenda = pageData.agenda || ({} as AgendaProps);
    const sectionListing = sections.sections || [];

    let opSec1Elem = <div />;
    const opSec1Valid = isOptionalSectionContentValid(opSec1);
    if (opSec1 && !opSec1.hidden && opSec1Valid) {
        opSec1Elem = <Section {...opSec1} even={true} passClick={handleVideoOverlay} />;
    }

    let opSec2Elem = <div />;
    const opSec2Valid = isOptionalSectionContentValid(opSec2);
    if (opSec2 && !opSec2.hidden && opSec2Valid) {
        opSec2Elem = <Section {...opSec2} even={true} passClick={handleVideoOverlay} />;
    }

    let videoOverlay: JSX.Element | undefined;
    if (play) {
        videoOverlay = (
            <VideoOverlay
                play={play}
                toggleVideo={handleVideoOverlay}
                video={video}
                title={title}
            />
        );
    }

    let sectionElems: JSX.Element[] | JSX.Element | undefined;
    if (sectionListing && sectionListing.length === 1 && !sections.hide) {
        sectionElems = <Section {...sectionListing[0]} passClick={handleVideoOverlay} />;
    } else if (sectionListing !== undefined && sectionListing.length > 1 && !sections.hide) {
        sectionElems = sectionListing.map((section, index) => {
            const i = index;
            let even = false;
            if (i % 2 === 0) {
                even = true;
            }
            if (section.hide || section.hidden) {
                return <div key={`s-${i}-${section.title}`} />;
            }
            return (
                <Section
                    key={`s-${i}-${section.title}`}
                    {...section}
                    even={even}
                    passClick={handleVideoOverlay}
                />
            );
        });
    }

    let sectionTitle: JSX.Element | undefined;
    if (sections.title && !sections.hide) {
        sectionTitle = (
            <AdditionalSectionHeader>
                <div className="container">
                    <div className="row">
                        <h2>{sections.title}</h2>
                    </div>
                </div>
            </AdditionalSectionHeader>
        );
    }

    const showLeadIn = !!(leadIn && !leadIn.hidden);
    const showAgenda = !!(agenda && !agenda.hidden);
    const speakerItems = speakers.speakers || [];
    const showSpeakers = !!(speakers && speakerItems && speakerItems.length && !speakers.hidden);
    const showCloser = !!(closer && !closer.hidden);

    const location = root.location.href;
    const urlParse = parse(location, true);
    const query = urlParse.query;

    const {engagement = {} as EngagementProps} = pageData;
    const {
        newsletter = {} as NewsletterProps,
        ctaBlockData = {} as CtaBlockDataProps,
        hidden
    } = engagement;
    const {staticIndustry} = newsletter;
    const rows = feedStore.rows || [];

    return (
        <UeThemeProvider theme={ueLightTheme}>
            <EventDetailViewWrapper primaryColor={primaryColor}>
                <HeaderLeft {...header} />
                <div>
                    {showLeadIn ? <LeadIn {...leadIn} /> : ''}
                    {opSec1Elem}
                    {showAgenda ? (
                        <Agenda {...agenda} query={query} btnPrimaryColor={primaryColor} />
                    ) : (
                        ''
                    )}
                    {passed ? <Recordings {...recordings} /> : ''}
                    {opSec2Elem}
                    {showSpeakers ? (
                        <GreyBg>
                            <Speakers {...speakers} />
                        </GreyBg>
                    ) : (
                        ''
                    )}
                    {sectionTitle}
                    {sectionElems}
                    {rows.length && showBlog ? (
                        <DarkBg>
                            <EventBlog
                                feedStore={feedStore}
                                getNextPage={getNextPage}
                                currentFilters={currentFilters}
                                title={blog.title}
                                loading={feedLoading}
                            />
                        </DarkBg>
                    ) : (
                        ''
                    )}
                    {showCloser ? <Section {...closer} /> : ''}
                    {videoOverlay}
                </div>
            </EventDetailViewWrapper>
        </UeThemeProvider>
    );
};

export default EventPage;
