import axios, {AxiosResponse} from 'axios';
import {getOrigin} from 'epic-ue-shared';

const url = () => {
    const origin = getOrigin();

    return {
        FEED: {
            FEED_ITEMS: (locale) => `${origin}/api/v2/${locale}/feeditems`
        }
    };
};

class BlogApi {
    public async getRecent(locale: string, numItems: number): Promise<any> {
        const api = url().FEED.FEED_ITEMS(locale);
        try {
            const response: AxiosResponse<any> = await axios.post(api, {
                numItems
            });
            return response.data;
        } catch (e) {
            console.error('getRecent() failed to load Blog api');
            throw e;
        }
    }
}

export const blogApi = new BlogApi();
