import styled from 'styled-components';

export const H1 = styled.h1`
    font-family: ${(p) => p.theme.fonts.brutal.light};
    font-weight: 300;
`;

export const H2 = styled.h2`
    font-family: ${(p) => p.theme.fonts.brutal.light};
    font-weight: 300;
`;

export const H3 = styled.h3`
    font-family: ${(p) => p.theme.fonts.brutal.regular};
    font-weight: 400;
`;

export const H4 = styled.h4`
    font-family: ${(p) => p.theme.fonts.brutal.medium};
    font-weight: 500;
`;

export const H5 = styled.h5`
    font-family: ${(p) => p.theme.fonts.brutal.medium};
    font-weight: 500;
`;

export const H6 = styled.h6`
    font-family: ${(p) => p.theme.fonts.brutal.medium};
    font-weight: 500;
`;

export const Heading = styled.div`
    h1,
    h2,
    h3 {
        margin-bottom: 20px;
    }
    * {
        text-align: center;
    }
    p {
        margin: 0 auto 40px;
        max-width: 750px;
    }
`;
