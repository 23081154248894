import React from 'react';
import {getImageUrl} from '../../../utils';
import {getOptImage} from 'epic-ue-shared';
import {getBowserState} from '@epic-core/common';
import {FeedItemProps} from '../../../types';
import {SimpleLink} from 'epic-ue-components';

import styled from 'styled-components';

const FeedImageWrapper = styled.div`
    .image-wrapper {
        position: relative;
        max-height: 320px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            object-position: 50% 50%;
            width: 100%;
            height: 0;
            max-height: 320px;
            border: 0;
            outline: none;
        }
    }
`;

interface Properties {
    item: FeedItemProps;
    title: string;
    linkPath: string;
}

export const FeedImage = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const imageUrl = getImageUrl(item);
    const {mobile, tablet} = getBowserState();
    const {_feedSlug} = item;
    const linkPath = props.linkPath || '';
    const title = props.title || '';

    let imageSize = 1400;
    if (mobile) {
        imageSize = 500;
    } else if (tablet) {
        imageSize = 800;
    }

    return (
        <FeedImageWrapper>
            <SimpleLink href={linkPath} id={`feed-${_feedSlug}`} title={title}>
                <div className="image-wrapper">
                    <img
                        alt={title}
                        className="image"
                        src={getOptImage({url: imageUrl, width: imageSize})}
                        loading="lazy"
                    />
                </div>
            </SimpleLink>
        </FeedImageWrapper>
    );
};
