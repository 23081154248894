import styled from 'styled-components';

export const Agenda = styled.div`
    padding: 30px 0 50px;
    margin: 40px 0;
    h2 {
        text-align: center;
    }
`;

export const EventSpotlightWrapper = styled.div<{headerType: string}>`
    &#eventSpotlightWrapper {
        .header {
            margin-bottom: 50px;

            video {
                position: relative;
                top: 40%;
                transform: translateY(-50%);
            }

            ${(p) => (p.headerType === 'video' ? `div:only-child {padding-bottom: 56.25%;}` : '')};

            .title-wrap h1 {
                text-align: center;
            }
        }

        .hasLoaded .header.header-centered .container {
            h1 {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    line-height: 1em;
                    font-size: 5em;
                }
            }
            h2 {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    font-size: 2.5em;
                }
            }
        }
        .hasLoaded .header.header-default .container {
            h1 {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    max-width: 800px;
                    line-height: 1em;
                    font-size: 5em;
                }
            }

            h2 {
                ${(p) => p.theme.breakpoints.up('lg')} {
                    margin: 20px 0;
                    font-size: 2.5em;
                }
            }

            ${(p) => p.theme.breakpoints.down('md')} {
                h1 {
                    font-size: 4em;
                    line-height: 1em;
                    text-align: center;
                }
                h2 {
                    font-size: 2.5em;
                    text-align: center;
                }
            }
        }

        .slick-arrow.slick-disabled {
            display: none;
        }

        .slick-arrow.slick-next:before,
        .slick-arrow.slick-prev:before {
            color: #ccc;
        }

        #spotlight-article {
            .header-default {
                .headerContentWrapper {
                    text-align: center;
                    .header-bottom-content {
                        .copy {
                            margin-left: 0;
                        }
                        ${(p) => p.theme.breakpoints.down('sm')} {
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
            }
            .event-content hr {
                background-color: ${(p) => p.theme.palette.common.white};
                opacity: 0.2;
            }
        }
    }

    .card-wrapper {
        .title {
            line-height: 1.4;
        }
    }

    .RecordingsSection {
        background: none;
        .sectionDescription {
            text-align: center;
            font-size: 1.25em;

            ${(p) => p.theme.breakpoints.up('lg')} {
                max-width: 60%;
                margin: 0 auto;
                padding-bottom: 24px;
            }
        }
    }

    .features-title {
        text-align: center;
    }

    .top-section-type-background {
        .engagement-section {
            margin-top: -50px;
            max-width: initial;
            h2 {
                font-family: Brutal_Light, Open Sans, arial;
                font-size: 2.2em;
            }
            .form-group div div {
                min-height: unset;
            }
            svg {
                right: 9px;
            }
        }
    }
`;
