import React from 'react';
import {H2, H4} from '../../elements/';
import styled from 'styled-components';
import {getOptImage, InterviewProps} from 'epic-ue-shared';

const InterViewWrapper = styled.div`
    .title-row {
        padding: 0 20px;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            padding-left: 276px;
        }
    }
`;

const IntervieweeWrapper = styled.div`
    width: 200px;
    background-color: ${p => p.theme.ue.colors.cards.default.background};
    padding: 20px;
    float: right;
    position: relative;

    ${p => p.theme.breakpoints.down('lg')} {
        width: 100%;
        height: auto;
        margin: 40px 0;
        .no-avatar,
        .avatar,
        .details {
            display: inline-block;
        }

        .details {
            width: 70%;
            margin-left: 25px;
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
                width: 90%;
                margin: 0;
            }
        }
    }

    ${p => p.theme.breakpoints.down('sm')} {
        .no-avatar,
        .avatar,
        .details {
            text-align: center;
            display: block;
            h4,
            div,
            p {
                text-align: center !important;
            }
        }
        .details {
            width: 100%;
            margin: 15px 0;
        }
    }

    .no-avatar,
    .avatar {
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
        margin: 0 auto;
        text-align: center;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            width: 100%;
        }
        img {
            display: block;
            height: 100%;
            border-radius: 200px;
            margin: auto;
        }
    }

    .no-avatar {
        i {
            font-size: 6em;
            color: #3d3d3d;
        }
    }
    .details {
        h4 {
            font-size: 1em;
            margin-bottom: 10px !important;
        }
        div {
            font-size: 0.875em;
        }
    }
`;

const QuestionsWrapper = styled.div`
    &.copy {
        ${p => p.theme.breakpoints.down('md')} {
            width: 100%;
        }
    }

    ul {
        margin: 0;
        padding: 0 15px;
    }

    li {
        list-style: none;
    }
    .question {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .answer {
        margin-bottom: 25px;
    }
`;

export const Interview = ({title, interviewee, questions}: InterviewProps): JSX.Element => {
    const name = interviewee.name || '';
    const desc = interviewee.desc || '';
    const avatar = interviewee.image || '';

    let qAndA: (JSX.Element | undefined)[] = [<div key="qAndAList" />];
    qAndA = questions.map(interview => {
        if (interview && interview.question) {
            const question = interview.question || '';
            const answer = interview.answer || '';
            if (question) {
                return (
                    <li key={`interview-${question}`}>
                        <div className="question">{question}</div>
                        <div className="answer" dangerouslySetInnerHTML={{__html: answer}} />
                    </li>
                );
            }
        }
    });

    return (
        <section className="interview-wrapper">
            <InterViewWrapper>
                <div className="container">
                    <div className="row title-row">{title ? <H2>{title}</H2> : ''}</div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-md-3">
                            {avatar || name ? (
                                <IntervieweeWrapper className={`${avatar ? 'hasAvatar' : ''}`}>
                                    {avatar ? (
                                        <div className="avatar">
                                            <img
                                                src={getOptImage({url: avatar, width: 150})}
                                                alt={name}
                                                className="ue-lightbox-img-ignore"
                                            />
                                        </div>
                                    ) : (
                                        <div className="no-avatar">
                                            <i className="icon-quote-open"/>
                                        </div>
                                    )}
                                    <div className="details">
                                        <H4 className="interviewee">{name}</H4>
                                        {desc ? (
                                            <div dangerouslySetInnerHTML={{__html: desc || ''}} />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </IntervieweeWrapper>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="interview-copy-wrapper col-xs-12 col-md-6">
                            <QuestionsWrapper className="copy">
                                <ul>{qAndA}</ul>
                            </QuestionsWrapper>
                        </div>
                        <div className="hidden-xs hidden-sm col-md-3"/>
                    </div>
                </div>
            </InterViewWrapper>
        </section>
    );
};
