import React from 'react';
import {ContentTypeLabel} from '../../label/ContentTypeLabel';
import {FeedHeading} from '../common';
import {FeedItemProps} from '../../../types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {getOptImage} from 'epic-ue-shared';
import styled from 'styled-components';
import {getImageUrl} from '../../../utils';
import {SimpleLink} from 'epic-ue-components';
import {FeedItemDefaultsWrapper} from '../common/FeedItem.default.styles';

export const SmallSpotlightWrapper = styled.div`
    .small-spotlight {
        position: relative;
    }

    .image {
        height: 375px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        transition: transform 350ms ease-in-out, box-shadow 350ms ease-in-out;
    }

    .overlay {
        color: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        vertical-align: bottom;
        z-index: 4;
        background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.65) 90%);
    }
`;

interface Properties {
    item: FeedItemProps;
    title: string;
    tagMatch: boolean | string[];
    linkPath: string;
}

export const SmallSpotlightCard = (props: Properties): JSX.Element => {
    const item = props.item || {};
    const _feedSlug = item._feedSlug || props.title || '';

    const title = props.title || '';
    const tagMatch = props.tagMatch || false;
    const linkPath = props.linkPath || '';

    const imageUrl = getImageUrl(item);
    const bkImageStyle = imageUrl
        ? {backgroundImage: `url(${getOptImage({url: imageUrl, width: 500})})`}
        : {};

    return (
        <FeedItemDefaultsWrapper>
            <SmallSpotlightWrapper className="feed-item">
                <Paper className={`feed-card ${_feedSlug}`} elevation={0}>
                    <Grid container justifyContent="center" spacing={0}>
                        <Grid item xs>
                            <div className="small-spotlight">
                                <SimpleLink href={linkPath} id={`feed-${_feedSlug}`} title={title}>
                                    <div className="image-wrapper">
                                        <div className="image" style={bkImageStyle} />
                                    </div>
                                </SimpleLink>
                                <div className="overlay content">
                                    <ContentTypeLabel item={item} tagMatch={tagMatch} />
                                    <FeedHeading item={item} linkPath={linkPath} title={title} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </SmallSpotlightWrapper>
        </FeedItemDefaultsWrapper>
    );
};
