import {useEffect, useMemo} from 'react';
import {useSharedValue, createSharedValueKey} from '@epic-core/hooks';
import {feedApi} from './feed.api';
import {ContentType} from '../types';
import {isError} from '@epic-mw/error-types';

export const feedContentTypesKey = createSharedValueKey<ContentType[]>('feedContentTypes', []);
const loadingState = {
    loading: false,
    loaded: false,
    error: ''
};
export const useFeedContentTypes = (): {contentTypes: ContentType[]} => {
    const [contentTypes, setContentTypes] = useSharedValue(feedContentTypesKey);

    useEffect(() => {
        if (
            (contentTypes && contentTypes.length && !loadingState.error) ||
            loadingState.loading ||
            loadingState.loaded
        ) {
            return;
        }

        async function fetchData() {
            try {
                loadingState.loading = true;
                const fetchedData: ContentType[] = (await feedApi.getFeedContentTypes()) || [];
                setContentTypes(fetchedData);
                loadingState.loading = false;
                loadingState.loaded = true;
            } catch (ex) {
                console.error('Failed to fetch data', ex);
                loadingState.loading = false;
                loadingState.loaded = true;
                loadingState.error = (isError(ex) && ex.message) || '';
            }
        }
        fetchData();
    }, [contentTypes, loadingState]);

    return useMemo(() => {
        return {
            contentTypes
        };
    }, [loadingState.loading, loadingState.loaded, contentTypes.length]);
};
