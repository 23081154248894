import React from 'react';
import {Content} from './BlankContent.styles';

interface Properties {
    slug: string;
    content: string;
}

export const BlankContent = ({slug, content}: Properties): JSX.Element => {
    return (
        <Content
            className={`cms-page-content ${slug} container`}
            dangerouslySetInnerHTML={{__html: content}}
        />
    );
};
