import React from 'react';
import {getInitialCmsPageSharedValues} from 'epic-ue-shared';
import GeneralLandingStardustPage from './GeneralLandingStardustPage';

const cmsUrlPattern = '/education/educators';

interface Properties {}

const EducatorsStardustPage = (props: Properties): JSX.Element => {
    return <GeneralLandingStardustPage {...props} cmsUrlPattern={cmsUrlPattern} />;
};

EducatorsStardustPage.getInitialSharedValues = getInitialCmsPageSharedValues(cmsUrlPattern);

export default EducatorsStardustPage;
