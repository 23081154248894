import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';
import root from 'window-or-global';
import { getLocale } from '@epic-core/common';
import { UnrealLoading } from 'epic-ue-loading';
import {
    getHelmetForMetaTags,
    useCmsData,
    PageData,
    AppSharedKeys,
    cmsApi,
    InitSharedValueResponse,
    RouteMatchOrUrlParse,
    getUrlPattern
} from 'epic-ue-shared';
import { extractSlugLocale } from '@epic-mw/localization';
import { determineEulaRefLocRedirect } from '../utils/eulaUtils';
import { Eula } from './Eula.styles';
import { GridContainer, Typography, VerticalSpacer } from 'epic-ue-ui';
import ErrorPage from './ErrorPage';
import { eulaApi, EulaContent, useEulaContent } from '../shared';
import { eulaKeyUnreal } from '../constants/eula';
import { Redirect } from '../routes/Redirect';

const scrollIntoView = () => {
    setTimeout(() => {
        const id = root.location && root.location.hash && root.location.hash.replace('#', '');
        if (id) {
            const element = root.document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, 100);
};

const defaultedLocales = ['es-ES', 'pt-BR', 'fr', 'de'];
const defaultToEnglishData = (locale: string, eulaData: EulaContent): boolean => {
    if (!locale) {
        return false;
    }
    if (!eulaData || !eulaData.eula || !eulaData.eula.body) {
        return false;
    }
    if (defaultedLocales.indexOf(locale) > -1) {
        return true;
    }
    return false;
};

interface BlankPageData extends PageData {
    content: string;
}

const EulaReferencePage = (): JSX.Element => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const langQuery = searchParams.get('lang');
    const { redirectUrlPattern, refLocale } = determineEulaRefLocRedirect(params, langQuery);
    const location = useLocation();

    const locale = getLocale();
    const eulaData = useEulaContent(eulaKeyUnreal);

    const isDefaultEnglish = defaultToEnglishData(locale, eulaData);
    const isBabelRefLang = false; //defaultedLocales.includes(refLocale || '');

    const { pageSlug } = extractSlugLocale(location.pathname, locale);
    const { cmsLoading, cmsLoadedEmpty, pageData } = useCmsData<BlankPageData>(pageSlug);

    useEffect(() => {
        scrollIntoView();
    }, []);

    if (redirectUrlPattern && redirectUrlPattern !== pageSlug) {
        return (
            <>
                {getHelmetForMetaTags(pageData)}
                <UnrealLoading />
                <Redirect to={redirectUrlPattern} replace />
            </>
        );
    }

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    const useEulaBody = (isDefaultEnglish || isBabelRefLang) && eulaData?.eula?.body;

    const content = useEulaBody ? eulaData?.eula?.body : pageData.content;

    if (!useEulaBody && (cmsLoadedEmpty || !content)) {
        return <ErrorPage status={404} />;
    }

    const pageDefaultedToEnglish =
        isDefaultEnglish || (pageData._locale === 'en-US' && locale && locale !== 'en-US');

    return (
        <Eula>
            {getHelmetForMetaTags(pageData)}
            {pageDefaultedToEnglish && (
                <Helmet
                    meta={[
                        {
                            property: 'og:locale',
                            content: refLocale || locale || pageData._locale
                        },
                        {
                            name: 'robots',
                            content: 'noindex, nofollow'
                        }
                    ]}
                />
            )}
            <GridContainer>
                <VerticalSpacer variant="heading" />
                {useEulaBody && (
                    <>
                        <Typography variant="h1">{eulaData?.eula.title}</Typography>
                        <VerticalSpacer variant="heading" />
                    </>
                )}
                <Typography variant="p" color="secondary" caption>
                    {content}
                </Typography>
                <VerticalSpacer variant="section" />
            </GridContainer>
        </Eula>
    );
};

EulaReferencePage.getInitialSharedValues = async (
    match: RouteMatchOrUrlParse
): Promise<Array<InitSharedValueResponse>> => {
    const urlPattern = getUrlPattern(match);

    const promises: Promise<any>[] = [
        await cmsApi.getPageData(urlPattern),
        await eulaApi.getEulaData(eulaKeyUnreal)
    ];

    const responses = await Promise.all(promises);

    return [
        {
            key: AppSharedKeys.EulaStore,
            value: {
                [eulaKeyUnreal]: responses[1] || {}
            }
        },
        {
            key: AppSharedKeys.CmsStore,
            value: {
                [urlPattern]: responses[0] || {}
            }
        }
    ];
};

export default EulaReferencePage;
