import React from 'react';
import ReactCompareImage from 'react-compare-image';
import styled from 'styled-components';
import {ImageCompareProps} from 'epic-ue-shared';
import {getOptImage} from 'epic-ue-shared';

const ImageCompareWrapper = styled.div`
    margin: 10px auto;
    ${p => p.theme.breakpoints.up('md')} {
        margin: 40px auto;
    }

    .compare-caption {
        width: 100%;
        text-align: center;
        margin: 20px 0;
        position: relative;
        overflow: hidden;
    }
`;

export const ImageCompare = ({title, hover, leftImage, rightImage, caption, imgWidth = 1300}: ImageCompareProps): JSX.Element | null => {
    if (!leftImage || !rightImage) {
        return null;
    }
    return (
        <ImageCompareWrapper>
            <div className="row">
                {title ? <div className="col-xs-12">{title}</div> : ''}
                <div className="col-xs-12">
                    <ReactCompareImage
                        hover={hover}
                        leftImage={getOptImage({url: leftImage, width: (imgWidth ? imgWidth : 1300)})}
                        rightImage={getOptImage({url: rightImage, width: (imgWidth ? imgWidth : 1300)})}
                    />
                </div>
            </div>
            {caption ? (
                <div className="compare-caption">
                    <i>{caption}</i>
                </div>
            ) : (
                ''
            )}
        </ImageCompareWrapper>
    );
};
