import React from 'react';
import { PageData, useCmsData } from 'epic-ue-shared';
import { getHelmetForMetaTags } from 'epic-ue-shared';
import { MarkerProps, UEMap, UEMapPanel, UEMapOverlay } from 'epic-ue-map';
import { Page } from 'epic-ue-components';
import { UnrealLoading } from 'epic-ue-loading';
import { UEMapMarkerTooltipConfig } from 'epic-ue-map/src';
import ErrorPage from '../../pages/ErrorPage';


interface Map {
    id: string;
    items: MarkerProps[];
    markerIcon: string;
    minZoom: number;
    maxZoom: number;
    clusterMarkers: boolean;
    clusterIcon: string;
    clusterThreshold: number;
}

interface MapPageData extends PageData {
    title: string;
    desc: string;
    map: Map;
}

interface CMSMapProps {
    cmsUrlPattern: string;
    markerTooltipConfig: UEMapMarkerTooltipConfig;
}

export const CMSMap = ({ cmsUrlPattern, markerTooltipConfig }: CMSMapProps): JSX.Element => {
    const { cmsLoading, cmsLoadedEmpty, pageData = {} as MapPageData } = useCmsData<MapPageData>(
        cmsUrlPattern
    );

    if (cmsLoading) {
        return <UnrealLoading />;
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const mapData = pageData.map || {};
    const { title, desc } = pageData;
    const id = mapData.id || '';
    const markerIcon = mapData.markerIcon || '';
    const minZoom = mapData.minZoom || 2;
    const maxZoom = mapData.maxZoom || 12;
    const clusterMarkers = mapData.clusterMarkers || false;
    const clusterIcon = mapData.clusterIcon || '';
    const clusterThreshold = mapData.clusterThreshold || 5;
    const markers = mapData.items || [];
    const items = markers.map(({ coordinates, ..._meta }) => {
        return {
            coordinates,
            _meta
        };
    });

    return (
        <Page>
            <>
                {getHelmetForMetaTags(pageData)}
                <UEMap
                    id={id}
                    markerIcon={markerIcon}
                    clusterMarkers={clusterMarkers}
                    clusterIcon={clusterIcon}
                    minZoom={minZoom}
                    maxZoom={maxZoom}
                    clusterThreshold={clusterThreshold}
                    items={items}
                    markerTooltipConfig={markerTooltipConfig}>
                    <UEMapOverlay>
                        <UEMapPanel variant="gradient" align="bottom">
                            <h2>{title}</h2>
                            {desc ? <div dangerouslySetInnerHTML={{ __html: desc }} /> : ''}
                        </UEMapPanel>
                    </UEMapOverlay>
                </UEMap>
            </>
        </Page>
    );
};
