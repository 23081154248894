import {useCallback} from 'react';
import {generateSearchURL} from '../utils/search.uils';

interface SearchRouteFns {
    routeNavigate: (
        queryUrl?: {filter?: string; keyword?: string},
        searchKeyword?: string,
        filterName?: string
    ) => void;
}

// We want this to be either a react-router instance or a next-router instance without adding the dependencies
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useRouterFns = (routerNavigate: any): SearchRouteFns => {
    const routeNavigate = useCallback(
        (
            queryUrl?: {filter?: string; keyword?: string},
            searchKeyword?: string,
            filterName?: string
        ) => {
            if (routerNavigate.push) {
                //next router
                routerNavigate.push(
                    {
                        query: {
                            ...routerNavigate.query,
                            keyword: searchKeyword,
                            filter: filterName
                        }
                    },
                    undefined,
                    {
                        shallow: true
                    }
                );
            } else {
                //react-router-dom useNavigate
                routerNavigate(generateSearchURL(queryUrl, filterName, searchKeyword));
            }
        },
        [routerNavigate]
    );
    return {
        routeNavigate
    };
};
