import React, {useEffect} from 'react';
import styled from 'styled-components';
import root from 'window-or-global';
import {useLocation} from 'react-router-dom';
import {
    getHelmetForMetaTags,
    getInitialCmsPageSharedValues,
    PageData,
    useCmsData
} from 'epic-ue-shared';
import ErrorPage from '../ErrorPage';
import {
    FAQSection,
    FAQSectionProps,
    UeLicenses,
    UeLicensesProps,
    NewsletterSection,
    NewsletterSectionProps,
    SimpleHeader,
    SimpleHeaderProps,
    Spotlight,
    SubnavSection,
    TemplateThemeProps,
    VerticalSpacer,
    CenteredTextBlock,
    CenteredTextBlockProps
} from 'epic-ue-ui';
import {UnrealLoading} from 'epic-ue-loading';
import {UeThemeProvider, ueLightTheme, ueDarkTheme, useSetCurrentAccentColor} from 'epic-ue-theme';
import throttle from 'lodash.throttle';

const LicensePageWrapper = styled.div`
    position: relative;
    overflow: hidden;
    color: ${(p) => p.theme.palette.text.secondary};
`;

interface LicensePageData extends PageData {
    themeConfig: TemplateThemeProps;
    header?: SimpleHeaderProps;
    licenses?: UeLicensesProps;
    faq?: FAQSectionProps;
    textBlock?: CenteredTextBlockProps;
    newsletter?: NewsletterSectionProps;
}

const urlPattern = '/license';

const LicensePage = (): JSX.Element => {
    const {hash} = useLocation();
    const {cmsLoading, cmsLoadedEmpty, pageData} = useCmsData<LicensePageData>(urlPattern);

    const {
        themeConfig = {} as TemplateThemeProps,
        header = {} as SimpleHeaderProps,
        licenses = {} as UeLicensesProps,
        faq = {} as FAQSectionProps,
        newsletter = {} as NewsletterSectionProps,
        textBlock = {} as CenteredTextBlockProps
    } = pageData;

    const {accentColor = 'sky', theme} = themeConfig;
    useSetCurrentAccentColor(accentColor);

    const scrollToContactUs = () => {
        if (root.__server_side_render) return;
        let el = root.document.getElementById('contact-us');
        if (el) {
            let offsetTop = 0;
            while (el) {
                offsetTop += el.offsetTop;
                el = el.offsetParent;
            }
            offsetTop += -100;
            root.scrollTopSkip = location.pathname; //prevent ScrollToTop from resetting position for this page
            root.scrollTo({left: 0, top: offsetTop, behavior: 'smooth'});
        }
    };

    const throttleScroll = throttle(scrollToContactUs, 250, {leading: false});

    useEffect(() => {
        if (hash && hash === '#contact-us-form') {
            //allow time for form to render before scrolling to it
            setTimeout(() => {
                throttleScroll();
            }, 250);
        }
    }, [hash, throttleScroll]);

    if (cmsLoading) {
        return (
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <LicensePageWrapper>
                    <UnrealLoading />
                </LicensePageWrapper>
            </UeThemeProvider>
        );
    }

    if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    return (
        <>
            {getHelmetForMetaTags(pageData)}
            <UeThemeProvider theme={theme === 'light' ? ueLightTheme : ueDarkTheme}>
                <LicensePageWrapper>
                    <Spotlight
                        customColors={['#34A3F5', '#CF6FCB']}
                        height={1076}
                        width={1076}
                        offsetRatio={[0.6, -0.15]}>
                        <SimpleHeader
                            {...header}
                            showScrollIndicator
                            accentColor={accentColor}
                            minAnimateDelay={0.5}
                        />
                    </Spotlight>
                    <VerticalSpacer variant="custom" spacing={18} downSmSpacing={0} />
                    <Spotlight
                        customColors={['#B959F3', '#3676B0']}
                        height={967}
                        width={967}
                        offsetRatio={[0.6, 0.75]}>
                        <UeLicenses
                            {...licenses}
                            onClick={scrollToContactUs}
                            accentColor={accentColor}
                        />
                    </Spotlight>
                    <VerticalSpacer variant="section" />
                    <CenteredTextBlock id="contact-us" {...textBlock} />
                    <VerticalSpacer variant="custom" spacing={15} downSmSpacing={10} />
                    <SubnavSection hideBottomSectionPadding sectionConfig={{theme: 'light'}}>
                        <FAQSection {...faq} accentColor={accentColor} />
                        <NewsletterSection {...newsletter} accentColor={accentColor} />
                    </SubnavSection>
                </LicensePageWrapper>
            </UeThemeProvider>
        </>
    );
};

LicensePage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default LicensePage;
