export function convertCSV(csvString: string, hasHeaders: boolean): {
    data: string[][];
    header: string[][];
    columnLength: number;
    rowLength: number;
} {
    if (!csvString || !csvString.length) throw new Error('Invalid csv string');

    const rows = csvString.split(/\r\n|\r|\n/g);

    const header: string[][] = [];
    const data: string[][] = [];

    rows.forEach((row, i) => {
        const cells = row.split('\t');
        if (cells && cells.length) {
            const dataRow: string[] = [];
            cells.forEach(cell => {
                dataRow.push(cell.trim());
            });
            if (hasHeaders && i === 0) {
                header.push(dataRow);
            } else {
                data.push(dataRow);
            }
        }
    });

    const firstRow = data[0] || [];
    const columnLength = firstRow.length;

    return {data, header, columnLength, rowLength: data.length};
}
