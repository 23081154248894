import React from 'react';
import {Section} from './Section';
import {GalleryProps, SectionProps} from 'epic-ue-shared';

interface SectionsProps {
    sections?: SectionProps[];
}

export const Sections = ({sections}: SectionsProps): JSX.Element => {
    return (
        <div className="sections">
            {sections &&
                sections.length > 0 &&
                sections.map((section, index) => {
                    const i = index;
                    let even = false;

                    const gallery = section.gallery || ({} as GalleryProps);
                    const slides = gallery.slides || [];
                    const compare = section.compare || {};
                    if (section.isHidden) {
                        return;
                    }

                    if (i % 2 === 0) {
                        even = true;
                    }
                    if (
                        (section && section !== undefined) ||
                        (section && slides) ||
                        (section && compare)
                    ) {
                        return (
                            <div
                                className={`${i > 0 && !section.hideBorder ? 'border-top' : ''}`}
                                key={`s-${i}-${section.title}`}>
                                {section.type && i > 0 && !section.hideBorder ? (
                                    <span className="hr" />
                                ) : (
                                    ''
                                )}
                                <Section key={`section-${index}`} {...section} even={even} />
                            </div>
                        );
                    }
                })}
        </div>
    );
};
