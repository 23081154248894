import styled from 'styled-components';

export const CaseStudyDouble = styled.div`
    &.type-double {
        height: auto;
        ${(p) => p.theme.breakpoints.down('sm')} {
            margin-bottom: 40px;
        }
        img {
            max-height: 100px;
        }
        &:not(.handheld) {
            h4 {
                margin-bottom: 20px;
            }
            .copy {
                font-size: 1em;
            }
            .container {
                position: relative;
                background-color: #191919;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(25, 25, 25, 1) 100%);
                ${(p) => p.theme.breakpoints.up('md')} {
                    padding-bottom: 350px;
                }
                > .row:first-child {
                    ${(p) => p.theme.breakpoints.down('md')} {
                        min-height: 300px;
                    }
                }
                > .row:last-child {
                    height: 400px;
                    width: 100%;
                    ${(p) => p.theme.breakpoints.up('md')} {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin: 0;
                    }
                    ${(p) => p.theme.breakpoints.down('md')} {
                        height: auto;
                    }
                    > div:last-child,
                    > div:first-child {
                        ${(p) => p.theme.breakpoints.up('md')} {
                            position: absolute;
                            height: 400px;
                            bottom: 0;
                            left: 0;
                            width: 50%;
                        }
                        ${(p) => p.theme.breakpoints.down('md')} {
                            width: 100%;
                        }
                    }
                    > div:first-child {
                        .bottom-content {
                            ${(p) => p.theme.breakpoints.down('xl')} {
                                bottom: 120px;
                            }
                            ${(p) => p.theme.breakpoints.up('xl')} {
                                bottom: 50px;
                            }
                        }
                    }
                    > div:last-child {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .row > div,
            .bgMedia {
                height: 400px;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: auto;
                }
                .content {
                    padding: 0 40px 40px 40px;
                    position: relative;
                    overflow: hidden;
                    height: 100%;
                    h3 {
                        font-size: 2em;
                    }
                    .bottom-content {
                        ${(p) => p.theme.breakpoints.up('md')} {
                            left: 40px;
                        }
                        .btn:not(.btn-blank):not(.btn-showcase) {
                            min-width: 190px;
                            padding: 10px 35px;
                        }
                    }
                }
            }
            .bgMedia {
                height: 0px;
                padding-top: 56.25%;
                ${(p) => p.theme.breakpoints.down('md')} {
                    height: 300px;
                }
            }
            .btn-container {
                > a,
                div,
                button {
                    ${(p) => p.theme.breakpoints.down('xl')} {
                        clear: both;
                        float: left;
                        margin-left: 0 !important;
                        padding-left: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }

        &.handheld {
            * {
                text-align: center;
            }
            .bottom-content {
                position: relative;
                left: 0;
                bottom: 0;
                margin: 40px 0;
                .button-container {
                    margin: 0 auto;
                }
            }
            .handheld-image-wrapper {
                position: relative;
                overflow: hidden;
                height: 300px;
                .bgMedia {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                    &.active {
                        opacity: 1;
                    }
                }
                .image-nav {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    height: 45px;
                    width: 100px;
                    background: #111;
                    z-index: 3;
                    i {
                        font-size: 1em;
                        color: #f5f5f5;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        position: absolute;
                        bottom: 5px;
                        &.prev {
                            transform: rotate(-90deg);
                            right: 70px;
                        }
                        &.next {
                            transform: rotate(90deg);
                            right: 20px;
                        }
                    }
                }
            }
        }
    }
`;
