import React, {useEffect} from 'react';
import {LogoWrapper, LogoItem} from './Logos.styles';
import {getOptImage} from 'epic-ue-shared';
import {StaggerWrapper} from '../../animations';

interface LogoProps {
    title: string;
    image: string;
    paddingTopBottom?: string;
    paddingLeftRight?: string;
}

export interface LogosProps {
    logos: LogoProps[];
    inView?: boolean;
}

export const Logos = ({logos, inView}: LogosProps): JSX.Element => {
    let LogoElems = <ul />;
    let LogoElemItems: JSX.Element[];
    const [hasLoaded, setHasLoaded] = React.useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, [hasLoaded, setHasLoaded]);

    if (logos) {
        LogoElemItems = logos.map((logo, index) => {
            const paddingTopBottom = logo.paddingTopBottom || '';
            const paddingLeftRight = logo.paddingLeftRight || '';
            return (
                <LogoItem
                    key={`logo-${logo.title}-${index}`}
                    paddingTopBottom={paddingTopBottom}
                    className="logo-list-item no-img-index"
                    paddingLeftRight={paddingLeftRight}>
                    <img
                        className="ue-lightbox-img-ignore"
                        src={getOptImage({url: logo.image, width: 200})}
                        alt={logo.title}
                        loading="lazy"
                    />
                </LogoItem>
            );
        });

        LogoElems = <ul>{LogoElemItems}</ul>;
    }
    return (
        <LogoWrapper className="container">
            <StaggerWrapper
                childLength={logos.length}
                className={`row ${hasLoaded ? 'hasLoaded' : ''} ${inView ? 'inView' : ''}`}>
                <div className="col-xs-12">{LogoElems}</div>
            </StaggerWrapper>
        </LogoWrapper>
    );
};
