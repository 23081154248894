import React from 'react';
import parse from 'url-parse';
import root from 'window-or-global';
import styled from 'styled-components';
import {GridDebug} from 'epic-ue-ui/dist/components/elements/grid/GridDebug';

interface NonProdFeaturesProps {
    epicEnv: string;
}

interface DebugFlags {
    [key: string]: React.ReactNode;
}

const debugFlags: DebugFlags = {
    grid: <GridDebug key="grid" />
};

export const DevBar = styled.div`
    display: block;
    position: fixed;
    top: 0px;
    width: 100%;
    background: red;
    z-index: 99999;
    height: 2px;
`;

export const NonProdFeatures = ({epicEnv}: NonProdFeaturesProps): JSX.Element | null => {
    const isProd = epicEnv === 'prod' || epicEnv === 'production';
    if (isProd) return null;

    const parsedUrl = parse(root.location.href, true);
    const query = parsedUrl.query || {};

    const elements: React.ReactNode[] = [];
    if (query.debug) {
        for (const key in debugFlags) {
            if (query[key] && debugFlags[key]) {
                elements.push(debugFlags[key]);
            }
        }
    }

    return (
        <>
            <DevBar className="environment" title={epicEnv} />
            {elements}
        </>
    );
};
