import React, {useRef, useState, useCallback} from 'react';
import styled from 'styled-components';
import {Swipeable} from 'react-swipeable';
import {getDataAttribute} from '@epic-core/common';
import {BgMedia} from '../media/BgMedia';
import {BackgroundProps, SlideProps} from 'epic-ue-shared';
import { SimpleLink } from '../link/SimpleLink';

export interface CarouselGalleryProps {
    slides: SlideProps[];
    imgClass?: string;
}

const CarouselGalleryWrapper = styled.div`
    position: relative;
    width: 100%;
    margin: 40px auto;
    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .slides {
        position: relative;
        overflow: hidden;
        ul {
            width: 100%;
            height: 0;
            padding-top: 56.25%;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            li {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity 0.3s ease;

                .image-title {
                    position: absolute;
                    bottom: 25px;
                    right: 0;
                    font-size: 1.1em;
                    width: 100%;
                    text-align: center;
                    ${(p) => p.theme.breakpoints.down('sm')} {
                        font-size: 0.75em;
                    }
                    color: hsla(0, 0%, 100%, 0.6);
                    pointer-events: none;
                    z-index: 34;
                }
                .image-gradient {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 30%;
                    background: linear-gradient(
                        180deg,
                        rgba(0, 212, 255, 0) 0%,
                        rgba(0, 0, 0, 0.7) 100%
                    );
                    z-index: 32;
                }
                &.active {
                    opacity: 1;
                    z-index: 4;
                }
                > div {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                    height: 100%;
                }

                .media-credits {
                    ${(p) => p.theme.breakpoints.down('md')} {
                        display: block !important;
                    }
                }
            }
        }
    }

    .simple {
        cursor: default;
        &.pointer {
            cursor: pointer;
        }
    }

    .cg-dots {
        text-align: center;
        height: 100px;
        width: 100%;
        margin-top: 0;
        ${(p) => p.theme.breakpoints.down('lg')} {
            margin-top: 20px;
        }
        li {
            display: inline-block;
            background: #3d3d3d;
            width: 10px;
            height: 10px;
            border-radius: 999px;
            margin-right: 10px;
            &:not(.active) {
                cursor: pointer;
            }
            &.active {
                transition: all 0.3s ease;
                background: #fff;
            }
        }
    }

    .cg-arrows {
        position: absolute;
        top: 50%;
        ${(p) => p.theme.breakpoints.up('xl')} {
            top: 51%;
        }
        display: block;
        cursor: pointer;
        opacity: 0.75;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 3em;
        color: #ccc;
        z-index: 9;
        transition: opacity 0.3s ease;
        &:hover {
            opacity: 1;
        }

        &.icon-arrow-left,
        &.icon-arrow-right {
            opacity: 1;
            text-shadow: rgba(0, 0, 0, 0.9) 0px 3px 5px;
        }

        &.icon-arrow-right {
            right: 0;
            left: auto;
            ${(p) => p.theme.breakpoints.down('lg')} {
                left: auto;
                right: 1em;
            }
        }

        &.icon-arrow-left {
            right: auto;
            left: 0;
            ${(p) => p.theme.breakpoints.down('lg')} {
                right: auto;
                left: 1em;
            }
        }
    }
`;

export const CarouselGallery = ({
    slides
}: CarouselGalleryProps): JSX.Element => {
    const slideList: (JSX.Element | undefined)[] = [];
    const dotList: (JSX.Element | undefined)[] = [];
    const slidesList = useRef<HTMLUListElement>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const directionHandler = useCallback(
        (e) => {
            if (e && slides) {
                e.stopPropagation();
                let current = activeIndex;

                const dotIndex = getDataAttribute(e.currentTarget, 'index');
                    const parseDotIndex = parseInt(dotIndex);
                    current = parseDotIndex;
                setActiveIndex(current);
            }
        },
        [setActiveIndex, activeIndex, slides]
    );

    const onSwiped = React.useCallback(
        (direction) => {
            if (slides) {
                const slidesLength = slides.length;
                let current = activeIndex;

                if (direction === 'left') {
                    current = current + 1;
                    if (current >= slidesLength) current = 0;
                } else if (direction === 'right') {
                    current = current - 1;
                    if (current < 0) current = slidesLength - 1;
                }

                setActiveIndex(current);
            }
        },
        [setActiveIndex, activeIndex, slides]
    );

    if (slides) {
        slides.map((slide, index) => {
            const sideData: BackgroundProps = {
                type: 'image',
                image: slide.image,
                credits: slide.credits
            };

            const href = slide.href || '';

            slideList.push(
                <li
                    role="presentation"
                    data-index={index}
                    title={slide.title || ''}
                    className={`cg-slide ${index === activeIndex ? 'active' : ''}`}
                    key={`cg-slide-${index}`}>
                    <SimpleLink href={href} className={href ? 'pointer' : ''}>
                        {slide.title ? <span className="image-title">{slide.title}</span> : ''}
                        {slide.title ? <span className="image-gradient" /> : ''}
                        <BgMedia background={sideData} imgMaxWidth={1200} disableLightbox />
                    </SimpleLink>
                </li>
            );

            dotList.push(
                <li
                    role="presentation"
                    data-index={index}
                    data-type={'dot'}
                    title={slide.title || ''}
                    className={`cg-dot ${index === activeIndex ? 'active' : ''}`}
                    onClick={directionHandler}
                    key={`dot-${index}`}
                />
            );
        });
    }

    return (
        <CarouselGalleryWrapper
            className={`carouselGallery hasDots`}>
            <div className="slides">
                <ul ref={slidesList} id="carousel-slider">
                    <Swipeable
                        preventDefaultTouchmoveEvent
                        onSwipedLeft={() => onSwiped('left')}
                        onSwipedRight={() => onSwiped('right')}>
                        {slideList}
                    </Swipeable>
                </ul>
            </div>
            <div className="cg-dots">
                <ul>{dotList}</ul>
            </div>
        </CarouselGalleryWrapper>
    );
};
