import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';

import {
    getHelmetForMetaTags,
    titleAppendUnrealEngine,
    getInitialCmsPageSharedValues,
    getMetaTags,
    getOptImage,
    PageData,
    useTreeDataCmsData
} from 'epic-ue-shared';
import {generateRoutePath} from '@epic-core/common';
import {Page, H1, Button, SimpleSocialSharing} from 'epic-ue-components';
import {UnrealLoading} from 'epic-ue-loading';
import styled from 'styled-components';
import {useLocalization} from '@epic-mw/localization';
import {useLocation, useMatch, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import root from 'window-or-global';
import {getCmsUrlPattern as getRootUrlPattern} from './TrainingPage';
import {getMessageFnType} from 'epic-ue-shared';
import ErrorPage from './ErrorPage';

const TrainingDetail = styled.div`
    color: #e2e2e2;
    .container-fluid {
        padding: 0;
    }

    .social-sharing-wrapper {
        top: 35vh;
    }

    .header-content {
        position: relative;
        height: 100%;
        .row,
        .row > div {
            height: 100%;
            padding: 0;
        }
    }

    .content {
        background: ${(p) => p.theme.palette.background.default};
        color: #e2e2e2;
        padding: 2em 4em 5em 4em;

        ${(p) => p.theme.breakpoints.down('md')} {
            padding: 0 1em 3em 1em;
        }
    }

    small {
        font-size: 14px;
    }
`;

const Header = styled.div`
    position: relative;
`;

const HeaderInner = styled.div`
    position: relative;

    ${(p) => p.theme.breakpoints.up('lg')} {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 120px;
            z-index: 1;
        }
    }
`;

const HeaderContent = styled.div`
    padding: 1em 2em 3em 2em;

    h1 {
        font-size: 3em;
        line-height: 1.1;
        margin: 0.5em 0 0.25em 0;
    }

    ${(p) => p.theme.breakpoints.up('lg')} {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 140px;

        a {
            display: none;
        }
    }
`;

const ImgWrap = styled.div`
    height: 100%;
    padding: 0;
    position: relative;
`;

const Badge = styled.div<{img: string}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => getOptImage({url: p.img, width: 300})});
    background-position: 50% 50%;
    height: 120px;
    width: 120px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    opacity: 0.94;
    z-index: 1;
`;

const Title = styled(H1)`
    text-align: left;
    font-weight: 600;
    color: #e2e2e2;

    ${(p) => p.theme.breakpoints.up('xl')} {
        text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    }
`;

const P = styled.div`
    font-size: 16px;
`;

const CourseImage = styled.div<{img: string; opacity: number}>`
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(p) => getOptImage({url: p.img, width: 1400})});
    background-position: 50% 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: ${(p) => p.opacity};

    &:after {
        z-index: 2;
        content: '';
        position: absolute;
        height: 300px;
        ${(p) => p.theme.breakpoints.down('md')} {
            height: 80vh;
        }
        width: 100%;
        bottom: 0px;
        background: linear-gradient(0deg, rgb(15, 17, 41) 0%, rgba(0, 0, 0, 0) 100%);
        left: 0px;
    }
`;

const ButtonWrap = styled.div`
    border-radius: 6px;
    text-align: center;
    margin: 1em 0;
    padding: 1em 1em 2em;
    background-color: ${(p) => p.theme.palette.background.paper};

    .btn-primary {
        margin: 4px;
        max-width: 100%;
    }

    ${(p) => p.theme.breakpoints.up('lg')} {
        padding: 2em 2em 3em;
        margin: 0;
        order: 2;
    }

    .course-info {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;
        li {
            text-align: left;
            margin-bottom: 25px;
            b {
                display: block;
            }
        }
    }
`;

const ContentMenuLink = styled.div`
    display: none;
    margin: 0 1em 1em 1em;

    ${(p) => p.theme.breakpoints.up('lg')} {
        display: block;
        color: ${(p) => p.theme.palette.text.secondary};
        margin-left: 0;
        a {
            color: ${(p) => p.theme.palette.text.secondary};
            margin-left: 0;
            &:hover,
            &:focus,
            &:active {
                color: ${(p) => p.theme.palette.text.primary};
            }

            i {
                font-size: 1.1em;
            }
        }
    }
`;

const ContentInner = styled.div`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.breakpoints.up('lg')} {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const TagWrapper = styled.div``;

const Tag = styled.div`
    display: inline-block;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    letter-spacing: 1px;
    margin-bottom: 8px;
    margin-right: 5px;
    background-color: ${(p) => p.theme.palette.background.paper};
    color: ${(p) => p.theme.palette.text.primary};
    padding: 0 8px;
    cursor: pointer;
    &:hover {
        background-color: ${(p) => p.theme.palette.background.default};
    }
`;

const CourseContent = styled.div`
    padding-right: 1em;
    order: 2;

    h3 {
        font-size: 1.8em;
        margin-bottom: 0;
    }

    ${(p) => p.theme.breakpoints.up('lg')} {
        order: 1;

        h3 {
            &:first-child {
                margin-top: 0;
            }
        }
    }
`;
interface TrainingDetailPageData extends PageData {
    class: {
        content: string;
        title: string;
        label?: string;
        url?: string;
        release?: string;
        author?: string;
        amount?: string;
        length?: string;
        tag?: string;
        secLabel?: string;
        secUrl?: string;
        thirdLabel?: string;
        thirdUrl?: string;
        fourthLabel?: string;
        fourthUrl?: string;
        short: string;
        image: TrainingDetailPageImage;
        badge: string;
        metaTagOverrides?: TrainingDetailPageMetaTagOverrides;
        amountSuffix?: string;
    };
    header?: {
        title?: string;
    };
}

interface TrainingDetailPageImage {
    desktopImage: string;
    opacity: number;
}

interface TrainingDetailPageMetaTagOverrides {
    title: string;
    desc: string;
    shareImage: string;
}

const TrainingDetailPage = (): JSX.Element => {
    const {pathname} = useLocation();
    const match = useMatch(pathname);
    const rootUrlPattern = getRootUrlPattern(match);
    const params = useParams();
    const titleParam = params.title || '';
    const {
        cmsLoading,
        cmsLoadedEmpty,
        pageData = {} as TrainingDetailPageData
    } = useTreeDataCmsData<TrainingDetailPageData>(rootUrlPattern, titleParam);
    const klass = pageData.class || {};
    const {
        content,
        title,
        label,
        url,
        release,
        author,
        amount,
        length,
        tag,
        secLabel,
        secUrl,
        thirdLabel,
        thirdUrl,
        fourthLabel,
        fourthUrl,
        short,
        image = {} as TrainingDetailPageImage,
        badge,
        metaTagOverrides = {} as TrainingDetailPageMetaTagOverrides,
        amountSuffix
    } = klass;
    const header = pageData.header || {};

    const showButtons = url || secUrl;
    const cmsUrlPatternNoSlash = rootUrlPattern.replace(/\//g, '');
    const img = image.desktopImage;
    const getMessage = useLocalization() as getMessageFnType;

    const redirectPageToListing = (tag) => {
        let url = rootUrlPattern;
        if (tag) {
            url += `?tags=${tag.toLowerCase()}`;
        }
        root.location.href = generateRoutePath(url);
    };

    const onTagClick = useCallback((e) => {
        const target = e.currentTarget || {};
        const tagItem = target.innerHTML || '';
        redirectPageToListing(tagItem);
    }, []);

    let courseLength = '';
    if (length) {
        const hours = Math.floor(parseInt(length) / 60);
        const minutes = parseInt(length) % 60;
        const moreThan60Min = hours && hours > 0;
        const isMoreThanTwoHours = hours && hours > 1;
        const isOneHour = hours && hours === 1;

        let hourSuffix = '';
        if (isOneHour) {
            hourSuffix = getMessage('epic.ue.courses.hour');
        } else if (isMoreThanTwoHours) {
            hourSuffix = getMessage('epic.ue.courses.hours');
        }

        const hoursStr = `${moreThan60Min ? `${hours} ${hourSuffix}` : ''}`;
        const minutesStr = `${minutes} ${getMessage('epic.ue.courses.minutes')}`;
        courseLength = `${hoursStr ? `${hoursStr} ` : ''}${minutesStr}`;
    }

    let releaseDate = '';
    if (release) {
        const date = new Date(release);
        const monthInt = date.getMonth() + 1;
        let month = monthInt.toString();
        if (monthInt < 10) {
            month = `0${month}`;
        }
        releaseDate = `${month}-${date.getFullYear()}`;
    }

    let {opacity} = image;
    if (!opacity || opacity <= 0 || opacity > 1) {
        opacity = 0.7;
    }

    if (cmsLoading) {
        return <UnrealLoading />;
    } else if (cmsLoadedEmpty) {
        return <ErrorPage status={404} />;
    }

    const metaTags = getMetaTags(
        getMessage,
        titleAppendUnrealEngine(metaTagOverrides.title || title),
        metaTagOverrides.desc || short,
        metaTagOverrides.shareImage || img
    );

    const socialTitle = header.title || pageData._title || title || '';

    const MenuLink = (
        <Link to={generateRoutePath(rootUrlPattern)}>
            <small>
                <i className="icon-arrow-left" />{' '}
                {getMessage(`epic.training.${cmsUrlPatternNoSlash}`)}
            </small>
        </Link>
    );

    const tagList: any = [];

    if (tag) {
        const selectedTags = tag.split(',');
        for (let i = 0; i < selectedTags.length; i++) {
            const tagItem = selectedTags[i];
            if (tagItem) {
                tagList.push(
                    <Tag key={`tag-item-${i}`} onClick={onTagClick}>
                        {tagItem}
                    </Tag>
                );
            }
        }
    }

    return (
        <Page>
            <TrainingDetail id="trainingDetail">
                {getHelmetForMetaTags(pageData)}
                <Helmet
                    meta={metaTags}
                    title={titleAppendUnrealEngine(metaTagOverrides.title || title)}
                />
                <SimpleSocialSharing
                    title={socialTitle}
                    tweet={''}
                    fixedScrollDisplayOffsetPx={100}
                />
                <div className="container-fluid">
                    <Header>
                        <CourseImage img={img} opacity={opacity} />

                        <div className="header-content container">
                            <div className="row">
                                <HeaderInner className="col-xs-12">
                                    <ImgWrap className="embed-responsive embed-responsive-16by9">
                                        <Badge img={badge} />
                                    </ImgWrap>
                                    <HeaderContent>
                                        {MenuLink}
                                        <Title>{title}</Title>
                                        <TagWrapper>{tagList}</TagWrapper>
                                        <P>{short}</P>
                                    </HeaderContent>
                                </HeaderInner>
                            </div>
                        </div>
                    </Header>
                </div>
                <div className="content container">
                    <ContentMenuLink>{MenuLink}</ContentMenuLink>
                    <ContentInner>
                        <CourseContent dangerouslySetInnerHTML={{__html: content}} />

                        <ButtonWrap>
                            <ul className="course-info">
                                {courseLength ? (
                                    <li>
                                        <b>{getMessage('epic.ue.courses.length')}</b>
                                        <span>{courseLength}</span>
                                    </li>
                                ) : null}
                                {amount ? (
                                    <li>
                                        <b>{getMessage('epic.ue.courses.amount')}</b>
                                        <span>
                                            {amount} {amountSuffix || getMessage('epic.ue.courses')}
                                        </span>
                                    </li>
                                ) : null}
                                {author ? (
                                    <li>
                                        <b>{getMessage('epic.ue.courses.author')}</b>
                                        <span>{author}</span>
                                    </li>
                                ) : null}
                                {release ? (
                                    <li>
                                        <b>{getMessage('epic.ue.courses.release')}</b>
                                        <span>{releaseDate}</span>
                                    </li>
                                ) : null}
                            </ul>

                            {showButtons ? (
                                <div className="button-wrapper">
                                    {url ? (
                                        <Button
                                            type="primary"
                                            target="_blank"
                                            id="startCourse"
                                            href={url}>
                                            {label || 'Start the course - Login Required'}
                                        </Button>
                                    ) : null}
                                    {secUrl ? (
                                        <Button
                                            type="primary"
                                            id="startCourseSecUrl"
                                            className="sec-btn"
                                            target="_blank"
                                            href={secUrl}>
                                            {secLabel || 'Start the course - Login Required'}
                                        </Button>
                                    ) : null}
                                    {thirdUrl ? (
                                        <Button
                                            type="primary"
                                            id="startCourseThirdUrl"
                                            className="third-btn"
                                            target="_blank"
                                            href={thirdUrl}>
                                            {thirdLabel || 'Start the course - Login Required'}
                                        </Button>
                                    ) : null}
                                    {fourthUrl ? (
                                        <Button
                                            type="primary"
                                            id="startCourseFourthUrl"
                                            className="fourth-btn"
                                            target="_blank"
                                            href={fourthUrl}>
                                            {fourthLabel || 'Start the course - Login Required'}
                                        </Button>
                                    ) : null}
                                </div>
                            ) : null}
                        </ButtonWrap>
                    </ContentInner>
                </div>
            </TrainingDetail>
        </Page>
    );
};

TrainingDetailPage.getInitialSharedValues = getInitialCmsPageSharedValues();

export default TrainingDetailPage;
