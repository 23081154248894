import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'epic-ue-components';
import BackgroundVideo from 'epic-react-common/src/components/BackgroundVideo';
import VisibilitySensor from 'react-visibility-sensor';
import {MegaHeaderWrapper} from './MegaHeader.styles';

export default class Header extends React.PureComponent {
    static propTypes = {
        background: PropTypes.object,
        data: PropTypes.object,
        tablet: PropTypes.bool,
        mobile: PropTypes.bool,
        ctaOnClick: PropTypes.func
    };

    state = {
        show: false,
        videoInView: false
    };

    onVideoChange = (isVisible) => {
        if (isVisible) {
            this.setState({
                videoInView: isVisible
            });
        } else if (!isVisible && this.state.videoInView) {
            this.setState({videoInView: isVisible});
        }
    };

    onClick = (e) => {
        const ctaOnClick = this.props.ctaOnClick;
        if (ctaOnClick && typeof ctaOnClick === 'function') {
            return ctaOnClick(e);
        }
    };

    render() {
        const {background = {}, tablet, mobile, data = {}} = this.props;
        const {subTitle, propTitle, title, buttonLabel, buttonLink, credits} = data;

        return (
            <MegaHeaderWrapper
                className={`grant-header ${this.state.videoInView ? 'runAnimation' : ''} ${
                    mobile ? 'mobile' : ''
                } ${tablet ? 'tablet' : ''}`}>
                <div className={`background-type ${background.type}`}>
                    <div className="background-wrapper">
                        <VisibilitySensor
                            onChange={this.onVideoChange}
                            partialVisibility
                            scrollCheck={true}
                            intervalDelay={250}
                            scrollThrottle={10}>
                            <BackgroundVideo
                                background={background}
                                enableRatioHelper={true}
                                play={this.state.videoInView && !this.state.show}
                                mute={true}
                                mobile={mobile}
                                tablet={tablet}
                            />
                        </VisibilitySensor>
                    </div>

                    <div className="container-fluid content-wrapper">
                        <h1 className="title uppercase">
                            <span>{propTitle}</span> {title}
                        </h1>
                        <h2 className="sub-title">{subTitle}</h2>
                        <div className="intro-desc-section">
                            <div className="intro-links-wrapper">
                                {buttonLink && buttonLabel && (
                                    <Button type="primary" href={buttonLink} onClick={this.onClick}>
                                        {buttonLabel}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="credits">{credits}</div>
                </div>
            </MegaHeaderWrapper>
        );
    }
}
